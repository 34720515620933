import React from "react"
import { navigatetoUrl } from "../../../utils/helper/helper"
import { useHistory } from "react-router-dom"
import { Grid } from "@material-ui/core"
import PencilSvgIcon from "assets/images/svgIcons/PencilSvgIcon"
import colors from "assets/colors"


interface Iprops {
    image: any
    name: string
    email: string
    type: string
    individualRate: string
}


const TutorReviewHeader = ({
    image,
    name,
    email,
    type,
    individualRate,
}: Iprops): JSX.Element => {
    const history = useHistory()

    const editButton = (type: string) => {
        switch (type) {
            case "info":
                return (
                    <span
                        className="edit_button"
                        onClick={() => navigatetoUrl(history, "/tutor-myprofile")}
                    >
                        <PencilSvgIcon width="20" height="20" iconColor={colors.whiteSolid} />
                    </span>
                )
            case "rates":
                return (
                    <span
                        className="edit_button"
                        onClick={() => navigatetoUrl(history, "/tutor-cancellation-policy")}
                    >
                        <PencilSvgIcon width="20" height="20" iconColor={colors.whiteSolid} />
                    </span>
                )
            case "type":
                return (
                    <span
                        className="edit_button"
                        onClick={() => navigatetoUrl(history, "/tutor-advisor-application")}
                    >
                        <PencilSvgIcon width="20" height="20" iconColor={colors.whiteSolid} />
                    </span>
                )
            default:
                break
        }
    }

    return (
        <>
            <div className="tutor-review-content">
                <div className="basic-info">
                    <div className="img-container">
                        <img className="profile-pic" style={{ background: "url(" + image + ")", backgroundSize: "cover" }} alt="" title="" /* onClick={():void => navigatetoUrl(history, "/tutor-myprofile")} */ />
                    </div>
                    <Grid container className="tutor-info hidden-xs">
                        <Grid item xs={6}>
                            <div>
                                <div className="info_edit_block">
                                    <h2>{name}</h2>
                                    {editButton("info")}
                                </div>
                                <p className="email">{email}</p>
                                <div>
                                    <div className="info_edit_block">
                                        <h3>Type</h3>
                                        {editButton("type")}
                                    </div>
                                    <p>{type === "TUTORING" ? "Tutoring" : type === "ADVISING" ? "Advising" : "Tutoring & Advising"}</p>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <div className="rates_block">
                                <div className="info_edit_block">
                                    <h3>Rates</h3>
                                    {editButton("rates")}
                                </div>
                                <p>Individual Session Rate (per hour):  ${individualRate}</p>
                            </div>
                            <div className="visible-xs">
                                <div className="info_edit_block">{editButton("info")}</div>
                                <div className="info_edit_block flex-column">
                                    <h2>{name}</h2>
                                    <p className="email">{email}</p>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                    <div className="info_mobile_block basic-info-text">
                        <div className="info">
                            <p className="heading">{name}</p>
                            <p>{email}</p>
                        </div>
                        <div className="button">{editButton("info")}</div>
                    </div>
                </div>
                <div className="info_mobile_block  no-padding">
                    <div className="info">
                        <p className="heading">Type</p>
                        <p>{type === "TUTORING" ? "Tutoring" : type === "ADVISING" ? "Advising" : "Tutoring & Advising"}</p>
                    </div>
                    <div className="button">
                        <span
                            className="edit_button"
                            onClick={() => navigatetoUrl(history, "/tutor-advisor-application")}
                        >
                            <PencilSvgIcon width="20" height="20" iconColor={colors.whiteSolid} />
                        </span>
                    </div>
                </div>
                <div className="info_mobile_block  no-padding">
                    <div className="info">
                        <p className="heading">Rates</p>
                        <p>
                            Individual Session Rate (per hour):$
                            {individualRate}
                        </p>
                    </div>
                    <div className="button">{editButton("rates")}</div>
                </div>
            </div>
        </>
    )
}

export default TutorReviewHeader
