import React from "react";
import { useSelector } from "react-redux";
import { StudentBookingPopupContainer } from "./student/StudentBookingPopupContainer";
import { TeacherBookingPopupContainer } from "./teacher/TeacherBookingPopupContainer";

export function BookingPopupContainer(props: any) {
    const userType = useSelector((s: any) => s?.auth?.userAuthData?.userType);
    console.log(`checking userType: `, userType)
    if (!userType) {
        return <></>;
    }
    return userType === "STUDENT" ? <StudentBookingPopupContainer /> : <TeacherBookingPopupContainer />
}