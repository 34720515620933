import React from "react"

export const Note = ({ title, content }: { title: string, content: string }) => {
    return (
        <div className="custom-note">
            <p className="note-title">{title}</p>
            <p className="note-content">{content}</p>
        </div>
    )
}
