const initialState = {
    showFeedbackBanner: false,
    details: {
        tutorInfo: {
            userId: "",
            profilePic: {downloadURL: ""}
        },
        sessionInfo: {}
    }
}

const feedBackReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case "SHOW_FEEDBACK_BANNER":
            return {
                showFeedbackBanner: true,
                details: action.payload,
            }
        case "CLOSE_FEEDBACK_BANNER":
            return {
                showFeedbackBanner: false,
            }
        default:
            return state
    }
}

export default feedBackReducer
