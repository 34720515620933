import { PaymentConstant, RapidBookingConstant } from "../constant"

const InitialState = {
    isAvailable: false,
    loading: false
}

const rapidBookingReducer = (state = InitialState, action: any) => {
    switch (action.type) {
        case RapidBookingConstant.SET_RAPID_BOOKING_AVAILABILITY:
            state = {
                ...state,
                ...action.payload,
            }
            break;
        case RapidBookingConstant.SET_RAPID_BOOKING_LOADING_STATUS:
            state = {
                ...state,
                ...action.payload
            };
            break;
        default:
            break;
    }
    return state;
}

export default rapidBookingReducer