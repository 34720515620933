import React, { useCallback, useEffect, useState } from "react";
import { BookingPending, PendingType, PENDING_STATUS, useBookingPending } from "../../../../pendings";
import { TutorWhenStdAcceptRequest } from "./TutorWhenStdAcceptRequest";
import { useHistory } from "react-router-dom";
import defaultAvatar from "assets/images/default-avatar.png";
import { BOOKING_REQUEST_STATUS_CODES } from "store/reducers/sessions-reducers/sessionReducer";
import { SLOT_STATUSES } from "store/constant";
import moment from "moment";

export default function TutorWhenStdAcceptRequestContainer() {
    const bookingPending: BookingPending = useBookingPending();
    const history = useHistory();
    const [isShown, setIsShown] = useState(false);
    const [data, setData] = useState({
        studentName: "",
        studentPicUrl: defaultAvatar,
        teacherPicUrl: defaultAvatar,
        sessions: [],
        initialSessions: []
    });

    const [currentPendingItem, setCurrentPendingItem] = useState<PendingType | undefined>(undefined);

    const updateBookingData = (bookingRequest: any) => {
        const sessions = bookingRequest.slots.filter((s: any) => s.status === SLOT_STATUSES.REQUESTED);
        const sIds = sessions.map((s: any) => s.id);
        const initialSessions = bookingRequest.initialSlots.filter((s: any) => sIds.includes(s.id));
        setData({
            studentName: bookingRequest.studentName,
            sessions,
            initialSessions,
            studentPicUrl: bookingRequest.studentProfilePicture || defaultAvatar,
            teacherPicUrl: bookingRequest.teacherProfilePicture || defaultAvatar
        });
        setIsShown(true);
    }

    const handleOneBookingPendingItem = (pendingItem: PendingType) => {
        const bookingRequest = pendingItem.data;
        updateBookingData(bookingRequest);
        setCurrentPendingItem(pendingItem);
    }

    const validateThePendingItem = (pendingItem: PendingType): boolean => {
        if (pendingItem.status === PENDING_STATUS.UPDATED &&
            pendingItem.data.status === BOOKING_REQUEST_STATUS_CODES.WAITING_FOR_TEACHER_CONFIRMATION &&
            pendingItem.data.modifiedBy === pendingItem.data.studentId &&
            moment().utc().diff(moment(pendingItem.data.creationTime._seconds * 1000).utc(), "minutes") <= 12*60 &&
            moment().utc().diff(moment(pendingItem.data.modifiedTime._seconds * 1000).utc(), "hours") < 24
        ) {
            return true;
        }
        return false;
    }

    useEffect(() => {
        bookingPending.ref.once("value", (snapshot) => {
            if (!snapshot.exists()) {
                return;
            }
            const pendingItems: PendingType[] = Object.values(snapshot.val());
            if (!pendingItems) {
                return;
            }
            const filteredItems = pendingItems.filter(pi => validateThePendingItem(pi));
            const sortedItems = filteredItems.sort(({ data: b1 }, { data: b2 }) => {
                return b1.modifiedTime._seconds < b2.modifiedTime._seconds ? 1 : b1.modifiedTime._seconds > b2.modifiedTime._seconds ? -1 : 0;
            });
            if (sortedItems.length < 1) {
                return;
            }
            //get latest
            const pendingItem = sortedItems[0];
            handleOneBookingPendingItem(pendingItem);
        }).catch(console.error)

        bookingPending.ref.on("child_changed", (snapshot) => {
            if (!snapshot.exists()) {
                return;
            }
            const pendingItem: PendingType = snapshot.val();
            if (pendingItem && validateThePendingItem(pendingItem)) {
                handleOneBookingPendingItem(pendingItem);
            }
        })
    }, [bookingPending]);

    const onClose = useCallback(() => {
        currentPendingItem && bookingPending.resolveItem(currentPendingItem.id);
        setIsShown(false);
    }, [currentPendingItem, isShown, data]);

    const onGoto = useCallback(() => {
        onClose();
        history && history.push('/panel/session')
    }, [isShown, data, currentPendingItem]);

    const onSearch = useCallback(() => {
        onClose();
        history && history.push('/panel/search')
    }, [isShown, data, currentPendingItem])

    return <TutorWhenStdAcceptRequest {...{
        ...data,
        onGoto,
        isShown,
        onClose,
        onSearch
    }} />
}