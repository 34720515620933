import { toastConstant } from "../constant"

const initialState = {
    show: false,
    message: "",
    isSuccess: false,
}

const toastReducer = (state = initialState, action: any): object => {
    switch (action.type) {
        case toastConstant.TOAST_SHOW:
            return {
                show: true,
                message: action.toastInfo.message,
                isSuccess: action.toastInfo.isSuccess,
            }
        case toastConstant.TOAST_HIDE:
            return {
                show: false,
            }
        default:
            return state
    }
}

export default toastReducer
