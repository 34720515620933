import React, { useEffect, useState } from "react"
import Button from "../button/Button"
import { Grid } from "@material-ui/core"
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import { navigatetoUrl } from "utils/helper/helper";


const OneOnOneSession = (): JSX.Element => {
    const { t } = useTranslation();
    const history= useHistory();
    const [platform,setPlatform] =useState<any>(undefined);
    
    useEffect(() => {
     try{
        const platfromcheck = localStorage.getItem('platform')
        setPlatform(platfromcheck)
     }
     catch(e){
         setPlatform(false)
     }
        
    }, [])
    return (
        <>
            <Grid container className="one-on-one-session">
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="justify-center">
                    <p className="text-center">{t('ask-one-on-one-session')}</p>
                </Grid>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="justify-center">
                    <Button
                        title={t("lets-go")}
                        clickAction={() => {
                            
                            if(!platform)
                            navigatetoUrl(history, "/panel/search/")
                            else{
                            localStorage.setItem('searchtab',JSON.stringify(true));
                            navigatetoUrl(history, "/panel/lessons#search")
                            }
                        }}
                        className="btn"
                    />
                </Grid>
            </Grid>
        </>
    )
}

export default OneOnOneSession
