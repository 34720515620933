import React from "react"
import Button from "../../components/button/Button"
import { useHistory } from "react-router-dom"
import LoaderSpinner from "../../components/loader/LoaderSpinner"
import { useTranslation } from 'react-i18next';



const SubscriptionItems = ({ products }: { products: any | undefined }): JSX.Element => {
    let history = useHistory()
    const { t } = useTranslation()

    return (
        <>
            {(products && products.length > 0) ?
                products.map((item: any) =>
                    <div className="subscription-option-cards">
                        {item.prices.map((subItem: any,index:number) =>
                            <div>
                                <div className="card-view">
                                    <p className="subscription-card-title">{subItem.nickname}</p>
                                    <p className="subscription-card-price">${subItem.unit_amount_decimal ? subItem.unit_amount / 100 : subItem.unit_amount}/{subItem.metadata?.period}</p>
                                    <p className="subscription-card-text">
                                        {subItem.metadata.description}
                                    </p>
                                    <Button
                                        title={t("subscribe")}
                                        clickAction={() => {
                                            let urlName = subItem.id
                                            history.push("subscribe/" + urlName)
                                        }}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                ) : <LoaderSpinner />}
        </>
    )
}

export default SubscriptionItems