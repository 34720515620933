import { Container, Grid } from "@material-ui/core"
import React, { useState, useEffect } from "react"
import ReactMarkdown from "react-markdown"
import { OptionCardView } from "../../../components/card/CardView"
import Modal from "./CollegeCouselingModal"
import OnboardingBackground from "../../../components/on-boarding/onboarding-background/OnboardingBackground"
import RoundedButton from "../../../components/rounded-button/RoundedButton"
import contentfulClient from "../../../utils/config/contentful"
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons"
import Button from "../../../components/button/Button"
import { navigatetoUrl } from "../../../utils/helper/helper"
import { useHistory } from "react-router-dom"
import VisibilityIcon from "@material-ui/icons/Visibility"
import { CollegeCounselingCardIcon } from "./CollegeCounselingIcon"
import { useTranslation } from 'react-i18next';
interface Iprops {
    history: any
}
const CollegeCounsellingContent = (): JSX.Element => {
    const {t} = useTranslation()
    const [lessons, setLessons] = useState<any[]>()
    const history = useHistory()
    useEffect(() => {
        contentfulClient
            .getEntries({ content_type: "lessonFlexible", include: 0 })
            .then((entries: any): void => {
                setLessons(
                    entries.items?.sort((a: any, b: any) => {
                        return (
                            a.fields.lessonTitle.split(":")[0].split("Lesson ")[1] -
                            b.fields.lessonTitle.split(":")[0].split("Lesson ")[1]
                        )
                    }),
                )
            })
    }, [])
    const [lessonShowing, setLesson] = useState(1)
    const [isPreviewing, setPreviewStatus] = useState(false)
    const [currentLesson, setCurrentLesson] = useState<any>({
        fields: {
            lessonTitle: "",
            description: "",
        },
    })

    useEffect(() => {
        let cl = { fields: { lessonTitle: "Demo 01: Demo", description: "None" } }
        if (lessons && lessons[0].fields) {
            cl = lessons.find((item) => {
                return item.fields.lessonTitle.split(":")[0].split("Lesson ")[1] == lessonShowing
            })
        }
        setCurrentLesson(() => {
            const allParas = cl.fields?.description?.split("\n\n") || []
            cl.fields.description = allParas.splice(0, Math.min(allParas.length, 5)).join("\n\n")
            return cl
        })
    }, [lessonShowing])
    return (
        <div className="college-lessons">
            <OnboardingBackground />

            <div className="collegeCounsellingContent">
                <Container >
                <div className="signup-back-btn college-counseling-back-btn">
                            <RoundedButton
                                onPress={() => {
                                    navigatetoUrl(history, "/looking-for")
                                }}
                                iconProps={{
                                    icon: faChevronLeft,
                                    style: {
                                        color: "#262ffa",
                                        alignSelf: "center",
                                        marginRight: "2px",
                                    },
                                }}
                                className="book-back-button"
                                active={true}
                            />
                        </div>
                    <div className="text-container p-0" style={{padding:"0 !important"}}>


                        <div className="heading">{t('college-counselling-guide')}</div>
                        <div className="sub-heading">
                            {"Preview some of the lessons featured in your subscription"}
                        </div>
                        <div style={{ marginTop: "32px" }}>
                            <Button clickAction={() => { navigatetoUrl(history, '/lessonPaymentPlans') }} title='Subscribe Now'></Button>
                        </div>
                    </div>
                </Container>

                <Container className="collageCounseling-cards-tab-view">
                    <Grid container direction="row" className="lesson-cards-holder" alignItems="center">
                        {lessons ? (
                            <>
                                {lessons?.map((lesson) => (
                                    <>
                                        <Grid
                                            item
                                            xs={12}
                                            md={6}
                                            lg={4}
                                            container
                                            direction="row"
                                            justify="center"
                                            alignItems="flex-end"
                                            className="grid-lesson-box"
                                        >
                                            <Grid item>
                                                <OptionCardView key={1}>
                                                    <Grid
                                                        container
                                                        direction="row"
                                                        justify="flex-start"
                                                        alignItems="flex-end"
                                                        className="content collegeCounseling-card-position"

                                                    >
                                                        <Grid
                                                            item
                                                            xs={9}
                                                            className="text-container "
                                                        >
                                                            <div className="collageCounseling-icon-div">
                                                                <CollegeCounselingCardIcon />
                                                            </div>
                                                            <div className="CollegeCardheading">
                                                                {" "}
                                                                Lesson{" "}
                                                                {
                                                                    lesson.fields.lessonTitle
                                                                        .split(":")[0]
                                                                        .split("Lesson ")[1]
                                                                }
                                                            </div>
                                                            <div className="CollegeCard-sub-heading">
                                                                {
                                                                    lesson.fields.lessonTitle.split(
                                                                        ": ",
                                                                    )[1]
                                                                }
                                                            </div>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={3}
                                                            container
                                                            justify="space-around"
                                                            alignItems="flex-end"
                                                        >
                                                            <Grid
                                                                item
                                                                xs
                                                                className="preview content"
                                                                onClick={() => {
                                                                    setLesson(
                                                                        lesson.fields.lessonTitle
                                                                            .split(":")[0]
                                                                            .split("Lesson ")[1],
                                                                    )
                                                                    
                                                                    setPreviewStatus(true)
                                                                }}
                                                            >
                                                                <VisibilityIcon className="preview-icon"></VisibilityIcon>
                                                                <p className="preview-label">
                                                                    <u>Preview</u>
                                                                </p>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </OptionCardView>
                                            </Grid>
                                        </Grid>
                                    </>
                                ))}
                                {/* <Grid
                                    item
                                    xs={4}
                                    container
                                    direction="row"
                                    justify="center"
                                    alignItems="flex-end"
                                    className="grid-lesson-box"
                                ></Grid> */}
                            </>
                        ) : (
                            <></>
                        )}
                    </Grid>
                </Container>
                <div className="view-more">
                    <Modal
                        visible={isPreviewing}
                        onExit={() => {
                            setPreviewStatus(false)
                        }}
                        footerButtons={[
                            {
                                title: "Subscribe Now",
                                clickAction: (e: any) => {
                                    navigatetoUrl(history, '/lessonPaymentPlans')
                                },
                                className: "view-subscription-options-button",
                            },
                        ]}
                    >
                        <p className="lesson-preview-label">Lesson Preview</p>
                        <p className="lesson-preview-title">Lesson {lessonShowing}:</p>
                        <p className="lesson-preview-title">
                            {currentLesson.fields.lessonTitle.split(":")[1]}
                        </p>
                        <div className="lesson-description-container">
                            <ReactMarkdown className="lesson-description">
                                {currentLesson.fields.description}
                            </ReactMarkdown>
                        </div>
                    </Modal>
                </div>
            </div>
        </div>
    )
}

export default CollegeCounsellingContent