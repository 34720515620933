import { call, put, all, takeLatest, select } from "redux-saga/effects"
import {
    lessonsConstant,
    moduleConstant
} from "../constant"
import {
    getLessons
} from "../../utils/services/lessonsServices"



function* fetchLessons() {
    try {
        const LessonsData = yield call(getLessons)
        yield put({ type: lessonsConstant.LESSONS_SUCCESS, payload: LessonsData })
    } catch (error) {
        console.log(error)
    }
}

function* findLessonById(props: any) {
    const {history, lessonId} = props.payload;

    let lessons = yield select((state) => state.lessons.lessons)
    if (lessons.length == 0) {
        try {
            lessons = yield call(getLessons)
        } catch (error) { 
            console.log(error)
        }
    }
    try {
        const findLesson = (element: any) => element.sys.id == lessonId
        let lessonIndex = lessons.findIndex(findLesson)
        if (lessonIndex == -1) {
            history.push("/panel/lessons")
        } else {   
            yield put({ type: moduleConstant.MODULE_REQUEST, payload: {lessonId: lessons[lessonIndex].sys.id} }) 
            yield put({ type: lessonsConstant.FIND_LESSONS_SUCCESS, payload: { lessons: lessons, lessonIndex: lessonIndex, lesson: lessons[lessonIndex] } })
        }
    } catch (error) {
        console.log(error)
    }

}

export default all([
    takeLatest(lessonsConstant.LESSONS_FIND_REQUEST, findLessonById),
    takeLatest(lessonsConstant.LESSONS_REQUEST, fetchLessons),
])
