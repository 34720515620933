/* ADVISING LIST */
const counsellingList = [
    { id: '4b0d2420-5a52-4d1a-a028-d2944664aed1', name: 'College Counseling ' },

    { id: '21d32b2b-40dc-4e2f-a8dd-666548fd57a4', name: 'Admissions: Boarding School' },
    { id: '4e95c7d7-6552-43dc-a328-62a7f14e27fb', name: 'Admissions: Elementary School' },
    { id: '07ca74cd-8a0c-4ae9-8ed1-af9ed9a3a6dd', name: 'Admissions: Gifted & Talented Programs' },
    { id: '944b3ba0-fa6f-42c5-91c6-158e0ac88494', name: 'Admissions: Gifted & Talented Programs - Elementary Schools' },
    { id: 'aed66333-c0b4-4449-9728-0a8a7a3d0ddb', name: 'Admissions: Gifted & Talented Programs - Middle Schools' },
    { id: '6d913b99-efd8-4d38-83c3-0380ad082670', name: 'Admissions: Gifted & Talented Programs - High Schools' },
    { id: '88f0449f-7a6b-4377-ac32-17d4899fc603', name: 'Admissions: High School' },
    { id: 'b2f2d714-ff50-4fda-9a20-5f1f6c1b4ec1', name: 'Admissions: Homeschool' },
    { id: '2c78b5aa-0d93-4f6c-8093-e092fe1cb180', name: 'Admissions: International Boarding Schools' },
    { id: 'feeb0231-bf06-42a6-a8da-1cdf37a97e9a', name: 'Admissions: Middle School' },
    { id: 'e37adc74-9b44-41cb-b4b7-a27ed1c39a98', name: 'Admissions: Nursery School' },
    { id: '8eb843ed-ce1a-4e95-9ae0-fe1e0cca41ac', name: 'Admissions: Pre-K/Kindergarten' },
    { id: 'dbba3ad0-4121-485c-ab20-77262cb239a3', name: 'Admissions: Private School' },
    { id: '6f29d50b-5d40-487c-ae39-449c5db8e2b3', name: 'Admissions: Public School' },
    { id: 'c761c8da-146b-493f-9948-19ef71cfd3df', name: 'Admissions: Therapeutic Boarding Schools' },

    { id: '336f9d21-aee0-48b3-b913-ca56fd5477b6', name: 'Applying to UK Schools' },

    { id: '2c23c1dc-1fc1-4663-b934-d61859a762b0', name: 'Art Portfolio Review' },

    { id: 'ac8786e3-6f0b-4b4f-aee8-05058516b0ba', name: 'Athletic Recruiting' },

    { id: 'a4dea9c9-a17a-4f9d-b28c-a6b13b82ebb3', name: 'Business School' },
	
    { id: 'b121cde4-0feb-40de-b3ac-ee8a561a5812', name: 'Business School (MBA)' },

    { id: 'f600481b-22ef-4633-b4ff-d5c247d6f091', name: 'Business 2+2' },

    { id: '3edc3287-24a4-4677-bea8-4e422caa95b2', name: 'Career Advising' },

    { id: '1a09d4ea-f773-470b-9883-6f713c6d2058', name: 'Career Counseling' },

    { id: 'e554399a-7beb-4307-9911-4019c4f36436', name: 'Dental School (DDS)' },
	
    { id: '12995a51-c370-4460-bce8-8cd305b52e3e', name: 'Executive Functioning Coaching' },

    { id: '174027db-a337-460e-96b4-ab549f55ae31', name: 'Extracurricular Advising' },

    { id: 'c6877808-585c-48e2-baf1-2bba14059b5f', name: 'Financial Aid/Scholarships' },

    { id: '9336505e-17c5-4cb0-91f7-ae3db61274e3', name: 'Fine Arts & Design ' },

    { id: 'a00a2697-e314-452f-b37e-78dd21f9ad9f', name: 'Flex-Med' },

    { id: '8ad4bdb5-992b-46ec-a80b-62fe3768db95', name: 'Gap Year' },

    { id: '98e01697-6e10-4d2b-bdab-40290fe280c4', name: 'HBCUs' },

    { id: '9b63b83e-b389-4cc5-bdd5-2cfd4eb80231', name: 'Homeschooled Applicants' },

	{ id: 'fc81c03b-8542-4fb3-9b41-f3c0a215f9ac', name: 'International Admissions Counseling' },

    { id: 'c7f31fb4-6573-46c8-a808-67f8d2d60f8f', name: 'International Applicants' },

    { id: '9af3132a-b3cf-48b9-9182-4d36a0a10754', name: 'International Students' },

    { id: 'd124ba69-e9e7-40a7-a42f-f11aa95edd61', name: 'Internship Counseling' },

    { id: '68588cc0-87cf-4f6f-817b-07d80b3bd00f', name: 'Interview Preparation' },

    { id: 'ff419f3e-0cad-4e6a-915d-5ab62f0c676d', name: 'Ivy League ' },

    { id: '31b8af69-9ad1-4ba1-b85c-6050db5710c8', name: 'Joint Degree Programs' },
	
    { id: 'e15004d2-340b-47d8-a6df-d7c10df26580', name: 'Journalism (MA)' },
	
    { id: 'de372663-e831-42a1-9e2e-95831b388594', name: 'Law School' },

    { id: '212d793f-a4f1-4971-954b-add466b8c62f', name: 'Law School (JD)' },

    { id: '3d78eb56-7609-4366-a90a-6f2ea8ec7cd7', name: 'Learning Differences' },

    { id: '3183c2f6-fabb-4382-9307-99ed1547bb72', name: 'Liberal Arts Colleges' },

    { id: 'db551536-e15d-4e9e-a87c-41ba08bc8024', name: 'Masters Programs' },
	
    { id: '4ef494ac-3c11-4f3d-9cbe-ff8296535715', name: 'Master of Arts' },
    { id: '9ade822e-178b-4879-bf80-959a441e722e', name: 'Master of Fine Arts' },
    { id: 'e2d3c09c-cbd8-4936-bd97-b87c36cbde07', name: 'Master of Engineering' },
    { id: '8ecbac8b-3ad2-4895-b7fc-96f70af92940', name: 'Master of Education' },
    { id: 'e4ef8aff-4363-4213-95db-6e83ce7edc80', name: 'Master of Science' },

    { id: '92f88b80-4aa2-476f-a7a0-c2dece262db0', name: 'Medical Post-Baccalaureate' },

    { id: '16e8540e-2d63-4e97-9c10-ba92b9ce2d0e', name: 'Medical School' },

    { id: 'bcb3a1c1-6fb8-45e0-b41a-1088708c0181', name: 'Military Academies' },
	
    { id: 'c911c107-97c8-4584-8ae1-f207736577d1', name: 'MSW' },

    { id: '19b8adea-dd2c-4b1f-811c-69ab79a5a4df', name: 'M. Phil' },

    { id: '7ed61def-aeca-43dd-a929-0104783c81a3', name: 'M. Litt' },
        
    { id: '31829f35-77da-4314-90ee-21d1238bd1d5', name: 'Nursing School' },

    { id: 'a26984e9-5cde-4f5a-9eb4-2deffe4ad20b', name: 'Online College Degrees' },

    { id: 'd09deb98-cbc2-4afd-9dff-fc8105a0b97f', name: 'Performing Arts ' },

    { id: '6f370705-0ede-4918-a569-7dc34f0726a2', name: 'Performing and Visual Arts' },

    { id: '8eb8ce82-2328-4daf-aca6-89373354b734', name: 'Personal Statement/College Essay' },

    { id: 'ea3c550d-896f-4f98-abba-6ecfa34bbc7e', name: 'Ph.D. Programs' },
	
    { id: '67498441-d7e5-4bbd-b305-acb83ca0416e', name: 'Psychology (PsyD,PhD)' },

    { id: '699b8344-82d9-4c84-8a87-abb8e2c5db3f', name: 'Public Policy (MPP)' },

    { id: '1f5c66b2-15ff-4086-b163-9293ca41db98', name: 'Public Speaking Coaching' },

    { id: '400cf5a3-0f43-473b-9616-fc4d39d74b82', name: 'Research Universities' },

    { id: 'd953c12b-af0c-4e2e-9500-fdd9362da5fd', name: 'Resume Coaching' },

    { id: '3ea828df-e18d-4c28-9c2c-fa04aade2f49', name: 'Special Needs Students' },

    { id: 'b0b09611-1df7-4855-a88d-06b8dcd292fb', name: 'Standardized Test Advising' },

    { id: 'ed562908-5b1c-4eac-a2c4-8a0d7fa2edd5', name: 'Summer Programs' },
    { id: 'b3ea48a5-127c-4146-b03a-92a78de012d5', name: 'Summer Programs: Performing Arts' },
    { id: '6b92ca76-c68b-4ebd-beb0-928aa0a7b06b', name: 'Summer Programs: Fine Arts' },
    { id: '86094544-3a6d-4bbf-98dd-9fb931e7a8f1', name: 'Summer Programs: Leadership' },
    { id: 'b2e544f7-5874-4ecf-beb1-608b29275864', name: 'Summer Programs: Academic' },
    { id: '6496e178-39b2-4a7a-b6fa-4e778b9b92ce', name: 'Summer Programs: Community Service' },

    { id: '5de20ac2-b23b-44bf-87f0-b377a70e669d', name: 'Therapeutic Boarding Schools' },

    { id: 'e8bcb9e3-937e-45f3-84d1-7cf0b4f3e32f', name: 'Transfer Student' },

    { id: '8d4e2f2f-32c9-41d7-b176-13beec2d27a8', name: 'Transfer Students' },

    { id: '0a37d497-d241-4edf-88d6-a98886b7d0a9', name: 'Undergraduate Business' },

    { id: '5a1f1ce5-7e95-4cb8-8aee-43eea3224e4a', name: 'Undergraduate Engineering' },

    { id: '3c00ea7d-7717-40ab-ac1b-19267108f5ea', name: 'Undergraduate Medical' },

    { id: '437c5c36-3987-4f1b-a491-578928532355', name: 'Undocumented Students' },

    { id: 'fb8bca28-5054-4b51-a385-df817cf60f92', name: 'Veterinary School' },

    { id: '111618a6-d966-417c-9b21-3c4c9dd3c519', name: 'Vocational School' },

    { id: 'b2ec3d2f-84d8-4929-b3ce-4d8222bc22c8', name: 'Women’s Colleges' },

    { id: '180cac69-7afa-4a14-a895-32b898e57d86', name: 'Writing Portfolio' },
];

export default counsellingList
