import React from "react"
import LessonsCard from "../../../../../components/lesson/LessonsCard"

const LessonSubscribeHeader = (): JSX.Element => {
    return (
        <>
            <h2 style={{ paddingLeft: 0 }} className="checkout-header">
                {"Checkout"}
            </h2>
            <LessonsCard />
            <div className="header d-flex"></div>
        </>
    )
}

export default LessonSubscribeHeader