import React, { useCallback, useEffect, useMemo, useState } from "react";
import { SLOT_STATUSES } from "../../../../store/constant";
import moment, { ISO_8601 } from "moment";
import ChangeTimeModal from "../ui-elements/ChangeTimeModal";
import Button from "../../../../components/button/Button";
import { Badge } from "reactstrap";

interface SlotType {
    startTime: string,
    endTime: string,
    status: string,
    sessionLength: number,
    id: string
    isRescheduleRequest?: boolean
}


function isSlotInPast(slot: SlotType) {
    return moment(slot.startTime, ISO_8601).diff(new Date(), "seconds") <= 0;
}

function shouldHideAcceptButton(slot: SlotType, previousSlot: SlotType) {
    return [SLOT_STATUSES.CANCELLED, SLOT_STATUSES.ACCEPTED]
        .includes(previousSlot.status) || isSlotInPast(previousSlot);
    // || (previousSlot.status === SLOT_STATUSES.REQUESTED && slot.status === SLOT_STATUSES.SUGGESTED) ||
    // (previousSlot.status === SLOT_STATUSES.SUGGESTED && slot.status === SLOT_STATUSES.REQUESTED)
}

function shouldHideCancelButton(slot: SlotType, previousSlot: SlotType) {
    return [SLOT_STATUSES.CANCELLED, SLOT_STATUSES.ACCEPTED]
        .includes(previousSlot.status);
}

function shouldHideSuggestTimeButton(slot: SlotType, previousSlot: SlotType) {
    return ([SLOT_STATUSES.CANCELLED, SLOT_STATUSES.ACCEPTED]
        .includes(previousSlot.status));
}


const badgeColorMapping = {
    "ACCEPTED": "success",
    "BOOKED": "success",
    "HELD": "success",
    "CANCELLED": "danger",
    "SUGGESTED": "primary",
    "REQUESTED": "primary"
} as { [status: string]: string };
const getBadge = (slotStatus: string) => {
    const color: string = badgeColorMapping[slotStatus];
    return <Badge color={color}>{slotStatus}</Badge>;
};

const renderSlotEventDetails = (slot: SlotType, previosSlot: SlotType, initialSlot: SlotType, setShowModal: Function, updateSlotInState: Function, isEditable: boolean = false, isRescheduleRequest = false) => {
    const { startTime, endTime, status: previousStatus } = previosSlot;
    const startTimeM = moment(startTime);
    const endTimeM = moment(endTime);
    const slotStartTime = moment(slot.startTime);
    const slotEndTime = moment(slot.endTime);
    return <>
        <div className="view-request-content">
            <div>
                <h6 className="view-request-h6">Request</h6>
                <p>{getBadge(previousStatus)}</p>
                <p>{startTimeM.format("ddd, MMM Do YYYY")}</p>
                <p>{startTimeM.format("hh:mm A")} {endTimeM.diff(startTimeM, "minutes")} minutes</p>
                {isEditable === true && <p>

                </p>
                }
            </div>
            <div>

                {(slotStartTime.isSame(startTimeM) && slotEndTime.isSame(endTimeM)) ?
                    <></> :

                    <>
                        <h6 className="view-request-h6">Suggested Time</h6>
                        <p>{slotStartTime.format("ddd, MMM Do YYYY")}</p>
                        <p>{slotStartTime.format("hh:mm A")} {slotEndTime.diff(moment(slot.startTime), "minutes")} minutes</p>
                    </>
                }
            </div>
        </div>
        {isEditable ?
            <div className="py-2">
                {shouldHideAcceptButton(slot, previosSlot) === false && <Button
                    title={"Accept"}
                    type={"button"}
                    clickAction={(e: any) => {
                        updateSlotInState({
                            ...previosSlot,
                            status: SLOT_STATUSES.ACCEPTED,
                        })
                    }}
                    isOutlineStyle={slot.status !== SLOT_STATUSES.ACCEPTED}
                    // isloading={buttonLoading}
                    spanStyle={{ marginRight: "10px" }}
                    className="suggest-page-btn"
                    style={{ width: "140px", height: "30px", paddingLeft: "10px", paddingRight: "10px" }}
                />}
                {shouldHideCancelButton(slot, previosSlot) === false && <Button
                    title={"Reject"}
                    type={"button"}
                    clickAction={(e: any) => {
                        updateSlotInState({
                            ...previosSlot,
                            status: SLOT_STATUSES.CANCELLED,
                        })
                    }}
                    isOutlineStyle={slot.status !== SLOT_STATUSES.CANCELLED}
                    // isloading={buttonLoading}
                    spanStyle={{ marginRight: "10px" }}
                    className="suggest-page-btn"
                    style={{ width: "140px", height: "30px", paddingLeft: "10px", paddingRight: "10px" }}
                />}
                {shouldHideSuggestTimeButton(slot, previosSlot) === false && !isRescheduleRequest && <Button
                    title={"Suggest Time"}
                    type={"button"}
                    clickAction={(e: any) => setShowModal(true)}
                    isOutlineStyle={![SLOT_STATUSES.SUGGESTED, SLOT_STATUSES.REQUESTED].includes(slot.status)}
                    spanStyle={{ marginRight: "10px" }}

                    // isloading={buttonLoading}
                    className="suggest-btn "
                    style={{ width: "auto", height: "30px", paddingLeft: "10px", paddingRight: "10px" }}
                />}
            </div> : <></>}
    </>
}


export default function Slot({ slot, previousSlot, initialSlot, isStudent, bookingStatus, updateSlotInState, isEditable = false, isRescheduleRequest = false }: { slot: SlotType, previousSlot: SlotType, initialSlot: any, isStudent: boolean, bookingStatus: number, updateSlotInState: Function, isEditable: boolean, isRescheduleRequest: boolean }) {
    const [showModal, setShowModal] = useState(false);
    useEffect(() => { }, [slot.status]);
    return (
        <>
            <div className="session-request-list-item" key={`${slot.id}-key`}>
                {renderSlotEventDetails(slot, previousSlot, initialSlot, setShowModal, updateSlotInState, isEditable, isRescheduleRequest)}
                {/* <div className="session-request-list-options">
                </div> */}
            </div>
            <ChangeTimeModal
                onsetsuggest={({ suggestedDateTime, sessionLength }: any) => {
                    const utc = moment(suggestedDateTime).utc();
                    if (utc.isSame(moment(slot.startTime)) && sessionLength === slot.sessionLength) {
                        //if new date and time and length is same as before then dont do anything
                        return;
                    }
                    updateSlotInState({
                        ...slot,
                        startTime: utc.toISOString(),
                        endTime: utc.add(sessionLength, "minutes").toISOString(),
                        status: isStudent === true ? SLOT_STATUSES.REQUESTED : SLOT_STATUSES.SUGGESTED,
                        sessionLength
                    })
                }}
                slot={{
                    suggestedDateTime: slot.startTime,
                    sessionLength: slot.sessionLength
                }}
                show={showModal}
                close={() => setShowModal(false)}
            />
        </>
    )
}