import { Action } from "../../libs/redux/redux"
import { toastConstant } from "../constant"

interface ToastProps {
    show: boolean
    message: string
    isSuccess: boolean
}

export const showToast = (toastInfo: ToastProps): any => {
    return { type: toastConstant.TOAST_SHOW, toastInfo }
}

export const hideToast = (toastInfo: ToastProps): object => {
    return { type: toastConstant.TOAST_SHOW, toastInfo }
}
