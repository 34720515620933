import React, { useCallback, useEffect, useState } from "react"
import { BookingPending, PendingType, useBookingPending } from "../../../../pendings"
import { StdTutorDoesntProcessMyRequest } from "./StdTutorDoesntProcessMyRequest"
import { useHistory } from "react-router-dom"
import defaultAvatar from "assets/images/default-avatar.png"
import { BOOKING_REQUEST_STATUS_CODES } from "store/reducers/sessions-reducers/sessionReducer"
import moment from "moment"
import { MAX_PENDING_TIME_FOR_BOOKING } from "utils/constant/BookingConstant"
import firebase from "firebase"
export default function StdTutorDoesntProcessMyRequestContainer() {
    const bookingPending: BookingPending = useBookingPending()
    const history = useHistory()
    const [isShown, setIsShown] = useState(false)
    const [data, setData] = useState({
        teacherName: "",
        studentPicUrl: defaultAvatar,
        teacherPicUrl: defaultAvatar,
    })

    const [currentPendingItem, setCurrentPendingItem] = useState<PendingType | undefined>(undefined)

    const updateBookingData = (bookingRequest: any) => {
        setData({
            teacherName: bookingRequest.teacherName,
            studentPicUrl: bookingRequest.studentProfilePicture || defaultAvatar,
            teacherPicUrl: bookingRequest.teacherProfilePicture || defaultAvatar,
        })
        setIsShown(true)
    }

    const handleOneBookingPendingItem = (pendingItem: PendingType) => {
        const bookingRequest = pendingItem.data
        updateBookingData(bookingRequest)
        setCurrentPendingItem(pendingItem)
    }

    const validateThePendingItem = (pendingItem: PendingType): boolean => {
        const creationTime = moment(pendingItem.data.creationTime._seconds * 1000).utc()
        const currentTime = moment().utc()
        const modifiedTime = moment(pendingItem.data.modifiedTime._seconds * 1000).utc()
        if (
            pendingItem.resolvedBy !== pendingItem.data.studentId &&
            currentTime.diff(creationTime, "seconds") > MAX_PENDING_TIME_FOR_BOOKING &&
            [BOOKING_REQUEST_STATUS_CODES.WAITING_FOR_TEACHER_CONFIRMATION].includes(
                pendingItem.data.status,
            ) &&
            currentTime.diff(modifiedTime, "hours") <= 24
        ) {
            return true
        }
        return false
    }

    useEffect(() => {
        bookingPending.ref
            .once("value", (snapshot) => {
                if (!snapshot.exists()) {
                    return
                }
                const pendingItems: PendingType[] = Object.values(snapshot.val())
                if (!pendingItems) {
                    return
                }
                const filteredItems = pendingItems.filter((pi) => validateThePendingItem(pi))
                const sortedItems = filteredItems.sort(({ data: b1 }, { data: b2 }) => {
                    return b1.modifiedTime._seconds < b2.modifiedTime._seconds
                        ? 1
                        : b1.modifiedTime._seconds > b2.modifiedTime._seconds
                            ? -1
                            : 0
                })
                if (sortedItems.length < 1) {
                    return
                }
                //get latest
                const pendingItem = sortedItems[0]
                handleOneBookingPendingItem(pendingItem)
            })
            .catch(console.error);

        const pendingList = (snapshot: firebase.database.DataSnapshot) => {
            if (!snapshot.exists()) {
                return
            }
            const pendingItem: PendingType = snapshot.val()
            if (pendingItem && validateThePendingItem(pendingItem)) {
                handleOneBookingPendingItem(pendingItem)
            }
        }

        bookingPending.ref.on("child_changed", pendingList);
        return () => bookingPending.ref.off("child_changed", pendingList);
    }, [bookingPending])

    const onClose = useCallback(() => {
        currentPendingItem && bookingPending.resolveItem(currentPendingItem.id)
        setIsShown(false)
    }, [currentPendingItem, isShown, data])

    const onGoto = useCallback(() => {
        onClose()
        history && history.push("/panel/search")
    }, [isShown, data, currentPendingItem])

    return (
        <StdTutorDoesntProcessMyRequest
            {...{
                ...data,
                onGoto,
                isShown,
                onClose,
            }}
        />
    )
}
