import firebase from "firebase"

enum ActionTypes {
    GET_MODULE = "GET_MODULE"
}


export const getModule = (lessonId: string) => {
    const getModule = firebase.functions().httpsCallable("lessons");
    return getModule({ actionType: ActionTypes.GET_MODULE, lessonId })
        .then((result: any) => { 
            const data = {
                lessonId: result.data.sys.id,
                lessonVideo: result.data.fields.lessonVideo,
                modules: result.data.fields.modules
            }
            return data;
        }).catch((err: any) => err);
}