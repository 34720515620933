import React from "react"
import interfaceConstant from "../../../utils/constant/interfaceConstant"

class DownArrowSvgIcon extends React.PureComponent<interfaceConstant.SvgIconProps> {
    render(): JSX.Element {
        const { width, height, iconColor, ...otherProps } = this.props
        return (
            <svg width={width} height={height} viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.994 5.169a1.45 1.45 0 0 0 0 2.022l6.505 6.636c.553.564 1.45.564 2.002 0l6.505-6.636a1.45 1.45 0 0 0 0-2.022 1.383 1.383 0 0 0-1.983 0L9.5 10.803 3.977 5.17a1.383 1.383 0 0 0-1.983 0z" fill={iconColor} fillRule="evenodd" />
            </svg>
        )
    }
}

export default DownArrowSvgIcon