import { subscriptionConstant } from "../constant";

export const cancelSubscription = (props: any) => {
    return { type: subscriptionConstant.CANCEL_SUBSCRIPTION, payload: props };
}

export const cancelSubscriptionSuccess = (props: any) => {
    return { type: subscriptionConstant.CANCEL_SUBSCRIPTION_SUCCESS, payload: props };
}

