import React, { useState, useEffect } from "react"
import moment from "moment"
import { formatDate, DateFormats } from "../../../../../utils/helper/date-utils"
import colors from "../../../../../assets/colors/index"
import { KeyboardDatePicker } from "@material-ui/pickers"
import Dialog from "@material-ui/core/Dialog"
import { makeStyles } from "@material-ui/core/styles"

import { exit } from "../../../../../components/icons/Icons"
import { quarterTimeOptions } from "../../../../../utils/helper/date-utils"
import Button from "../../../../../components/button/Button"
import { useFormik } from "formik"
import * as Yup from "yup"
import SimpleDropdown from "../../../../../components/dropdown/SimpleDropdown"
import StudentTutorAvatar from "../../../../../components/student-tutor-avatar/StudentTutorAvatar"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { getUpcomingSessionsOfMultipleUsers } from "store/actions/sessionsActions"
import { sessionType } from "utils/hooks/useSessionExtension"
import { sendRescheduleRequest } from "store/actions/sessionsActions"

interface Mprops {
    show: boolean
    close: () => void
    slot: any
    studentPicUrl: string,
    teacherPicUrl: string,
    teacherId: string,
    studentId: string,
    sessionId: string
}

const sessionLengthOptions: Array<{ name: number; id: number }> = []
let value = 0
let index = 0
const step = 15
while (value < 240) {
    index++
    value += step
    sessionLengthOptions.push({ name: value, id: index })
}

const useStyles = makeStyles({
    root: {
        borderRadius: "20px 0px 20px 0px",
        maxWidth: "940px",
        width: "100%",
        padding: "90px 61px 100px",
        overflow: "visible",
        maxHeight: "707px"
    },
})

const useStylesInput = makeStyles({
    root: {
        border: "0px",
        height: "65px",
        boxShadow: `0 10px 10px 0 ${colors.blackRussianOp}`,
        width: "100%",
        padding: "10px",
    },
})

const StdTutorRescheduleSession = ({ show, close, slot, studentPicUrl, teacherPicUrl, studentId, teacherId, sessionId }: Mprops): JSX.Element => {


    const [isPickerOpen, setIsPickerOpen] = useState(false);
    const [unavailableSlots, setUnavailableSlots] = useState<string[]>([]);
    const [timeOptionList, setTimeOptionList] = useState([]);

    const { upcomingSessions } = useSelector((state: any) => state.rescheduling);
    const { rescheduleData } = useSelector((state: any) => state.session);

    const dispatch = useDispatch();

    const classes = useStyles();
    const { t } = useTranslation();
    const inputClasses = useStylesInput();

    const formik = useFormik({
        initialValues: {
            suggestedDate: "",
            suggestedTime: "",
        },
        validationSchema: Yup.object().shape({
            suggestedDate: Yup.date().required("Please Provide a date"),
            suggestedTime: Yup.string().required("Please select a time"),
        }),
        onSubmit: (values) => {
            const { suggestedDate, suggestedTime } = values;
            const setTime = handleSetTime(suggestedDate, suggestedTime);
            dispatch(sendRescheduleRequest({ sessionId, newStartTime: setTime }));
        },
    })

    useEffect(() => {
        updateTimeList();
    }, [formik.values.suggestedDate])

    useEffect(() => {
        dispatch(getUpcomingSessionsOfMultipleUsers([studentId, teacherId]));
    }, [teacherId, studentId])

    useEffect(() => {
        setUnavailableSlots(convertSessionsIntoQuarterlySlots(upcomingSessions));
    }, [upcomingSessions])

    useEffect(() => {
        if (Object.keys(slot).length) {
            const formattedDate = slot.suggestedDateTime
                ? formatDate(slot.suggestedDateTime, DateFormats.LONG_DATE)
                : formatDate(slot.date, DateFormats.LONG_DATE)
            handleDateUpdate(formattedDate)
            slot.suggestedDateTime
                ? handleSuggestedTimeChange(
                    formatDate(slot.suggestedDateTime, DateFormats.TIME_IN_MERIDIAN),
                )
                : handleSuggestedTimeChange("")
        }
    }, [])


    const handleSetTime = (sugdate: any, sugtime: any): string => {
        if (sugdate <= 0 || sugtime === "") {
            return "";
        }
        const date = moment(sugdate);
        const time = moment(sugtime, "hh:mm A");
        date.set({ hour: time.get("hour"), minute: time.get("minute") });
        return moment.utc(date).toISOString();
    }

    const updateTimeList = () => {
        const timeOptionsArray = quarterTimeOptions();
        const timeOptionsArrayFiltered = timeOptionsArray.filter((item: any) => {
            const selectedDate = moment(`${formik.values.suggestedDate} ${item}`);
            return selectedDate.isAfter(moment().add(1, "hours").format('LLL')) && !unavailableSlots.includes(selectedDate.toISOString());
        })
        setTimeOptionList(timeOptionsArrayFiltered.map((item: any, index: number) => ({
            id: `${index}_`,
            name: item,
        })))
    }

    const handleDateUpdate = (date: any) => {
        const formatted = moment(date).isValid() ? formatDate(date, DateFormats.LONG_DATE) : date;
        formik.setFieldValue("suggestedDate", formatted, true);
    }

    const handleSuggestedTimeChange = (time: any) => {
        formik.setFieldValue("suggestedTime", time, true);
    }

    const convertSessionsIntoQuarterlySlots = (sessions: sessionType[]) => {
        let unavailableSlots: Array<string> = [];
        sessions.forEach((session) => {
            for (let i = 0; i < session.sessionLength / 15; i++) {
                unavailableSlots.push(moment(session.startTime).add(i * 15, "minutes").toISOString());
                unavailableSlots.push(moment(session.startTime).subtract(i * 15, "minutes").toISOString());
            }
        })
        return unavailableSlots;
    }

    return (
        <Dialog className="reschedule-modal tutor-student-reschedule-modal" fullWidth maxWidth='lg' open={show} onClose={close} classes={{ paper: classes.root }}>
            <img alt="close" src={exit} className="session-modal-exit" onClick={close} />
            <div className="reschedule-time-modal">
                <div className="modal-student-reschedule">
                    <div className="imgs_div">
                        <StudentTutorAvatar
                            size="100"
                            student={studentPicUrl}
                            tutor={teacherPicUrl}
                        />
                    </div>
                </div>
                <h2>{t("reschedule-session")}</h2>
                <p> <span>{t("previous-booking-cancel")}</span><br></br>
                    {t("dont-want-to-lose")}
                </p>
                <form onSubmit={formik.handleSubmit}>
                    <div className="session-time-selector-wrapper reschedule-session-time-selector-wrapper">
                        <div className="session-time-selector-input">
                            <label className="input-label" style={{color:'#2d3234'}}>When</label>
                            <KeyboardDatePicker
                                autoOk
                                clearable
                                inputVariant="standard"
                                format={`${DateFormats.LONG_DATE}`}
                                variant="inline"
                                className="datepicker-root"
                                placeholder={`${formatDate(
                                    moment().toString(),
                                    DateFormats.LONG_DATE,
                                )}`}
                                value={formik.values.suggestedDate}
                                minDate={moment()}
                                InputProps={{
                                    classes: { root: inputClasses.root },
                                    disableUnderline: true,
                                    readOnly: true
                                }}
                                InputAdornmentProps={{ position: "end" }}
                                InputLabelProps={{ disableAnimation: true, shrink: false }}
                                helperText={
                                    formik.touched.suggestedDate &&
                                    formik.errors.suggestedDate &&
                                    "Please provide a valid date"
                                }
                                error={
                                    formik.touched.suggestedDate && formik.errors.suggestedDate
                                        ? true
                                        : undefined
                                }
                                open={isPickerOpen}
                                onClose={() => setIsPickerOpen(false)}
                                onChange={(e: any) => handleDateUpdate(e)}
                                onClick={() => setIsPickerOpen(true)}
                            />
                        </div>
                        <div className="reschedule-time-selector-input time">
                            <SimpleDropdown
                                title="Starts At"
                                optionsList={timeOptionList}
                                placeholder="Select a time"
                                fieldDetails={{
                                    error: "Please select a time",
                                    value: timeOptionList.filter(
                                        (item: any) => item.name === formik.values.suggestedTime,
                                    )[0]
                                        ? timeOptionList.filter(
                                            (item: any) =>
                                                item.name === formik.values.suggestedTime,
                                        )[0]
                                        : formik.values.suggestedTime,
                                }}
                                setProps={(p: any) => handleSuggestedTimeChange(p.value.name)}
                                isShowError={
                                    formik.touched.suggestedTime && formik.errors.suggestedTime
                                        ? true
                                        : false
                                }
                            />
                        </div>
                    </div>
                    <Button
                        title="Send"
                        clickAction={() => { }}
                        isloading={rescheduleData.isLoading}
                        isDisabled={rescheduleData.isLoading}
                        style={{ margin: "0 auto", display: "block" }}
                    />
                </form>
            </div>
        </Dialog>
    )
}

export default StdTutorRescheduleSession
