import { SLOT_STATUSES } from "../../../store/constant";
import { SlotType } from "./RequestListItem";
import { updateBookingSlots, acceptBooking, cancelBooking } from "../../../store/actions/sessionsActions";

export interface actionCreatorWithName {
    name: string,
    actionCreator: (bookingId: string, data: any) => object
}


export const getCancelActionCreator = () => cancelBooking;
export const getAcceptActionCreator = () => acceptBooking;
export const getUpdateActionCreator = () => updateBookingSlots;
export const getCancelActionName = (isStudent: boolean) => `${isStudent === true ? "Cancel" : "Reject"}`;
export const getAcceptActionName = (isStudent: boolean) => `Accept`;
export const getUpdateActionName = (isStudent: boolean) => `Request Changes`;

export const getFinalBookingActionWithName = (slots: SlotType[], previousSlots: SlotType[], isStudent: boolean) => {



    if (shouldCancelOnly(slots, previousSlots) === true) {
        return { name: getCancelActionName(isStudent), actionCreator: cancelBooking };
    } else if (shouldAcceptOnly_Simple(slots, previousSlots) === true) {
        return { name: getAcceptActionName(isStudent), actionCreator: acceptBooking };
    } else if (shouldUpdateOnly(slots, previousSlots) === true) {
        return { name: getUpdateActionName(isStudent), actionCreator: updateBookingSlots }
    } else {
        return { name: "Error", actionCreator: (bookingId: string, data: any) => ({}) }
    }

}

export function shouldUpdateOnly(slots: SlotType[], previousSlots: SlotType[]): boolean {

    return true;
}

export function shouldCancelOnly(slots: SlotType[], previousSlots: SlotType[]): boolean {
    //if all the slots are canceled
    const allCanceled = slots.reduce((allFlag: boolean, slot: SlotType) => {
        return allFlag && (slot.status === SLOT_STATUSES.CANCELLED);
    }, true);
    return allCanceled;
}


export function shouldAcceptOnly_Simple(slots: SlotType[], previousSlots: SlotType[]): boolean {
     //if all the slots are accepted
     const allAccepted = slots.reduce((allFlag: boolean, slot: SlotType) => {
        return allFlag && (slot.status === SLOT_STATUSES.ACCEPTED);
    }, true);

    //if all the slots are in final statuses
    return allAccepted || slots.reduce((allFlag: boolean, slot: SlotType) => {
        return allFlag && ([SLOT_STATUSES.CANCELLED, SLOT_STATUSES.ACCEPTED].includes(slot.status));
    }, true);
}

export function shouldAcceptOnly(slots: SlotType[], previousSlots: SlotType[]): boolean {
    //if all the slots are accepted
    const allAccepted = slots.reduce((allFlag: boolean, slot: SlotType) => {
        return allFlag && (slot.status === SLOT_STATUSES.ACCEPTED);
    }, true);

    //if slots and previous slots statuses are same and previous slots have final statuses (canceled or accepted or both)
    const bothSameAndFinal = slots.reduce((isSame: boolean, slot: SlotType, i: number) => {
        const previousSlot = previousSlots.filter(s => s.id === slot.id).pop();
        return isSame && (slot.status === previousSlot?.status) && [SLOT_STATUSES.CANCELLED, SLOT_STATUSES.ACCEPTED].includes(slot.status);
    }, true);

    if (allAccepted || bothSameAndFinal) {
        return true;
    }

    //now filter out the slots in both which have same statuses and those statuses are final
    const sameSlotsWithFinalStatuses = slots.reduce((sameSlots: SlotType[], slot: SlotType) => {
        const prevSlot = previousSlots.filter(s => (s.id === slot.id && s.status === slot.status)).pop();
        if (!prevSlot) {
            return sameSlots;
        }
        if ([SLOT_STATUSES.ACCEPTED, SLOT_STATUSES.CANCELLED].includes(slot.status)) {
            sameSlots.push(slot);
        }
        return sameSlots;
    }, []);

    const sameSlotsIdsWithFinalStatuses = sameSlotsWithFinalStatuses.map(s => s.id);

    const diffSlots = slots.filter(s => !sameSlotsIdsWithFinalStatuses.includes(s.id))
    const diffPreviousSlots = previousSlots.filter(s => !sameSlotsIdsWithFinalStatuses.includes(s.id))

    //if current slots have final statuses
    const currentHaveFinal = diffSlots.reduce((isCurrentFinal: boolean, slot: SlotType) => {
        return isCurrentFinal && [SLOT_STATUSES.CANCELLED, SLOT_STATUSES.ACCEPTED].includes(slot.status)
    }, true);
    //now check each current status with previous slot status to
    //to tell whether accept is allowed or not
    return currentHaveFinal && diffPreviousSlots.reduce((isAcceptAllowed: boolean, prevSlot: SlotType) => {
        const currentSlot = diffSlots.filter(s => s.id === prevSlot.id).pop();
        if (!currentSlot) {
            return false;
        }
        if ([SLOT_STATUSES.REQUESTED, SLOT_STATUSES.SUGGESTED].includes(prevSlot.status)) {
            if (currentSlot.status === SLOT_STATUSES.ACCEPTED) {
                return isAcceptAllowed;
            }
        }
        return false;
    }, true);


}