import React from "react"
import AppleSvgIcon from "../../assets/images/svgIcons/AppleSvgIcon"
import AppleHalfSvgIcon from "../../assets/images/svgIcons/AppleHalfSvgIcon"
import colors from "../../assets/colors"

import "./rating-component.scss"

interface RatingsProps {
    obtainedRating: number
    fillColor?: string
    blankColor?: string
    iconSize?: string
}

class RatingsComponent extends React.PureComponent<RatingsProps> {
    renderIcon = (itemIndex: number): JSX.Element => {
        const { iconSize, obtainedRating, fillColor, blankColor } = this.props
        const elementNumber = itemIndex + 1

        const fillIconColor = fillColor ? fillColor : colors.battleshipGrey
        const blankIconColor = blankColor ? blankColor : colors.paleGrey

        const toBeFilled =
            elementNumber <= obtainedRating || elementNumber <= Math.ceil(obtainedRating)
        const iconColor = toBeFilled ? fillIconColor : blankIconColor

        const tobeHalfFilled =
            elementNumber > Math.floor(obtainedRating) &&
            elementNumber <= Math.ceil(obtainedRating) &&
            toBeFilled

        return (
            <div className="rating-icon" key={itemIndex}>
                {tobeHalfFilled ? (
                    <AppleHalfSvgIcon
                        key={elementNumber}
                        height={iconSize}
                        width={iconSize}
                        iconColor={iconColor}
                    />
                ) : (
                    <AppleSvgIcon
                        key={elementNumber}
                        height={iconSize}
                        width={iconSize}
                        iconColor={iconColor}
                    />
                )}
            </div>
        )
    }

    render(): JSX.Element {
        return (
            <div
                className="rating-container"
            >
                {[...Array(5)].map((_, index) => this.renderIcon(index))}
            </div>
        )
    }
}

export default RatingsComponent
