import React, { useEffect } from "react"
import LessonContent from "../../../components/lesson/LessonContent"
import { useSelector, useDispatch } from "react-redux"
import { findLessonById } from "../../../store/actions/lessonActions"
import LoaderSpinner from "../../../components/loader/LoaderSpinner"
import { useHistory } from "react-router-dom"
import { navigatetoUrl } from "../../../utils/helper/helper"


const LessonPage = (props: any) => {
    const { lessonId } = props.match.params
    const lessons = useSelector((state: any): any => state.lessons.lessons ? state.lessons.lessons : [])
    const lessonIndex = useSelector((state: any): any => state.lessons.lessonIndex)
    const dispatch = useDispatch()
    let history = useHistory()
    useEffect(() => {
        dispatch(findLessonById({ lessonId, history }))
        return () => {
            if (history.action === "POP") {
                if(props.location.state){
                    const { prevLessonId, prevModuleId } = props.location.state.params
                    if (prevModuleId && prevLessonId) {
                        navigatetoUrl(history, `/panel/lessons/${prevLessonId}/module/${prevModuleId}`)
                    }
                }
            }
        }
    }, [lessonId])
    return (
        <>
            {lessons.length > 0 && lessons[lessonIndex] ?
                <LessonContent lessons={lessons} lessonIndex={lessonIndex} />
                :
                <LoaderSpinner />
            }
        </>
    )
}

export default LessonPage
