import { combineReducers } from "../../../libs/reducers/utils"
import { createReducer } from "../../../libs/redux"
import { tutorSearchStateType } from "../store"
import {
    fetchTeachersAction,
    setSelectedTutor,
    changeProgressStatusAction,
    filterTeachersByGoalsAndObjectivesAction,
    resetAllFiltersAction,
    filterTeacherBySubjectsAction,
    filterTeacherByGenderAction,
    filterTeachersByAvailabilityAction,
    filterTeachersBySelectAllAvailabilityAction,
    filterTeacherByBackgroundCheckAction,
    changeSortByTypeAction,
    changeSortTypeDirectionAction,
    filterTeacherByMyFavoritesAction,
    filterTeacherByEducationAction,
    filterTeachersByHoursRateAction,
} from "../actions/actions"

export const HourRates = {
    MIN_RATE: 2,
    MAX_RATE: 500,
}

let selectedTutor: any = localStorage.getItem("selectedTutor")
selectedTutor = JSON.parse(selectedTutor)

export const tutorSearchInitState: tutorSearchStateType = {
    showProgress: true,
    teachers: [],
    goalAndObjectives: "TUTORING_AND_ADVISING",
    subjects: [],
    hourRate: { min: HourRates.MIN_RATE, max: HourRates.MAX_RATE },
    gender: "ALL",
    availability: {
        0: [0, 1, 2, 3],
        1: [0, 1, 2, 3],
        2: [0, 1, 2, 3],
        3: [0, 1, 2, 3],
        4: [0, 1, 2, 3],
        5: [0, 1, 2, 3],
        6: [0, 1, 2, 3],
    },
    selectAllAvailability: true,
    education: "ALL",
    backgroundCheck: false,
    sortByType: "RATING",
    sortTypeAscending: true,
    myFavoritesOnly: false,
    selectedTutor: selectedTutor ? selectedTutor : {
        firstName: "",
        lastName: "",
        userId: "",
        teacherResume: {
            type: "",
            uri: "",
            name: "",
            size: 0,
            documentName: "",
            offlinePath: "",
        },
        oneToOneSessionRate: 0,
        groupSessionRate: 0,
        teacherCounsellingSubjects: [],
        teacherCertificateDetails: {
            certificationName: "",
            issuingOrganization: "",
            issueDate: "",
            expirationDate: "",
            credentialId: "",
            credentialUrl: "",
        },
        teacherBiography: "",
        teacherTutoringStandardizedTests: [],
        teacherTutoringSubjects: [],
        teacherEducationQualification: {
            id: "OTHER",
            name: "",
        },
        teacherWeeklyAvailability: {},
        teacherSchoolDetails: [],
        freeCancellationOfAdvanceScheduledSessionHours: "",
        freeCancellationBeforeHours: 0,
        minutesTutoring: 0,
        // userType: "STUDENT",
        profilePic: {
            storagePath: "",
            downloadURL: "",
        },
        gender: "",
        hoursTutored: "",
        backgroundCheckVerified: false,
        totalReviews: 0,
        teacherType: "",
        subjects: [],
        avgRating: "",
        reviews: [],
        defaultTimeZone: ""
    },
}

// Tutor Search Reducers
const setTeachersDataReducer = createReducer(tutorSearchInitState.teachers, {
    [fetchTeachersAction.type]: (state, action) => {
        const { payload } = action
        return payload || state
    },
})

const setSelectedTutorReducer = createReducer(tutorSearchInitState.selectedTutor, {
    [setSelectedTutor.type]: (state, action) => {
        const { payload } = action

        localStorage.setItem("selectedTutor", JSON.stringify(payload))
        return payload || state
    },
})

const setProgressStatus = createReducer(tutorSearchInitState.showProgress, {
    [changeProgressStatusAction.type]: (state, action) => {
        const { payload } = action
        return payload
    },
})

const setGoalAndObjectiveFilterReducer = createReducer(tutorSearchInitState.goalAndObjectives, {
    [filterTeachersByGoalsAndObjectivesAction.type]: (state, action) => {
        const { payload } = action
        return payload || state
    },
    [resetAllFiltersAction.type]: (_state, _action) => {
        return tutorSearchInitState.goalAndObjectives
    },
})

const setSubjectFilterReducer = createReducer(tutorSearchInitState.subjects, {
    [filterTeacherBySubjectsAction.type]: (state, action) => {
        const { payload } = action
        return payload || state
    },
    [resetAllFiltersAction.type]: (_state, _action) => {
        return tutorSearchInitState.subjects
    },
})

const setHourRateReducer = createReducer(tutorSearchInitState.hourRate, {
    [filterTeachersByHoursRateAction.type]: (state, action) => {
        const { payload } = action
        return payload || state
    },
    [resetAllFiltersAction.type]: (_state, _action) => {
        return tutorSearchInitState.hourRate
    },
})

const setGenderFilterReducer = createReducer(tutorSearchInitState.gender, {
    [filterTeacherByGenderAction.type]: (state, action) => {
        const { payload } = action
        return payload || state
    },
    [resetAllFiltersAction.type]: (_state, _action) => {
        return tutorSearchInitState.gender
    },
})

const setAvailabilityReducer = createReducer(tutorSearchInitState.availability, {
    [filterTeachersByAvailabilityAction.type]: (state, action) => {
        const { payload } = action
        return payload || state
    },
    [filterTeachersBySelectAllAvailabilityAction.type]: (state, action) => {
        const { payload } = action
        return payload
            ? tutorSearchInitState.availability
            : { 0: [], 1: [], 2: [], 3: [], 4: [], 5: [], 6: [] }
    },
    [resetAllFiltersAction.type]: (_state, _action) => {
        return tutorSearchInitState.availability
    },
})

const setSelectAllAvailabilityReducer = createReducer(tutorSearchInitState.selectAllAvailability, {
    [filterTeachersBySelectAllAvailabilityAction.type]: (state, action) => {
        const { payload } = action
        return payload
    },
    [filterTeachersByAvailabilityAction.type]: (state, action) => {
        const { payload } = action
        return Object.values(payload).every(
            (weeklyAvailability: any) => weeklyAvailability.length === 4,
        )
            ? true
            : false
    },
    [resetAllFiltersAction.type]: (_state, _action) => {
        return tutorSearchInitState.selectAllAvailability
    },
})

const setEducationReducer = createReducer(tutorSearchInitState.education, {
    [filterTeacherByEducationAction.type]: (state, action) => {
        const { payload } = action
        return payload || state
    },
    [resetAllFiltersAction.type]: (_state, _action) => {
        return tutorSearchInitState.education
    },
})

const setBackgroundCheckReducer = createReducer(tutorSearchInitState.backgroundCheck, {
    [filterTeacherByBackgroundCheckAction.type]: (state, action) => {
        const { payload } = action
        return payload
    },
    [resetAllFiltersAction.type]: (_state, _action) => {
        return tutorSearchInitState.backgroundCheck
    },
})

const setSortByTypeReducer = createReducer(tutorSearchInitState.sortByType, {
    [changeSortByTypeAction.type]: (state, action) => {
        const { payload } = action
        return payload || state
    },
    [resetAllFiltersAction.type]: (_state, _action) => {
        return tutorSearchInitState.sortByType
    },
})

const setSortTypeDirectionReducer = createReducer(tutorSearchInitState.sortTypeAscending, {
    [changeSortTypeDirectionAction.type]: (state, action) => {
        const { payload } = action
        return payload
    },
    [resetAllFiltersAction.type]: (_state, _action) => {
        return tutorSearchInitState.sortTypeAscending
    },
})

const setMyFavoritesOnlyReducer = createReducer(tutorSearchInitState.myFavoritesOnly, {
    [filterTeacherByMyFavoritesAction.type]: (state, action) => {
        const { payload } = action
        return payload
    },
    [resetAllFiltersAction.type]: (_state, _action) => {
        return tutorSearchInitState.myFavoritesOnly
    },
})

const tutorSearchReducer: any = combineReducers({
    showProgress: setProgressStatus,
    selectedTutor: setSelectedTutorReducer,
    teachers: setTeachersDataReducer,
    goalAndObjectives: setGoalAndObjectiveFilterReducer,
    subjects: setSubjectFilterReducer,
    hourRate: setHourRateReducer,
    gender: setGenderFilterReducer,
    availability: setAvailabilityReducer,
    selectAllAvailability: setSelectAllAvailabilityReducer,
    education: setEducationReducer,
    backgroundCheck: setBackgroundCheckReducer,
    sortByType: setSortByTypeReducer,
    sortTypeAscending: setSortTypeDirectionReducer,
    myFavoritesOnly: setMyFavoritesOnlyReducer,
})

export default tutorSearchReducer
