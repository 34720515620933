import React from "react";
import Modal from "components/modal_/Modal";
import './reschedulingPopups.scss';
import Button from "components/button/Button";
import settingIcon from "assets/images/signUpsetting.png"
import moment from "moment";
import { StdTutorAcceptRequests } from "popups/booking/student/popups/StdTutorAcceptRequest";

export function StudentAcceptsRescheduleRequest(props: StdTutorAcceptRequests) {
    return <div className="tutor-student-reschedule-modal">
        <Modal
            visible={props.isShown}
            onExit={props.onClose}
        >
            <div className="modal-student-accept-reschedule">
                <img src={settingIcon} alt="tutor images" />
                <h3>Request Successfully Accepted</h3>

                <div className="grey_div">
                    <h4>{moment(props.sessions[0].startTime).format("dddd, MMMM DD, YYYY")} at {moment(props.sessions[0].startTime).format("hh:mm A")}</h4>
                </div>
                <div className="text-center pt-5">
                    <Button className="accept-reschedule-btn" title="Go to My Sessions" clickAction={props.onGoto} isOutlineStyle={true} />
                </div>
            </div>

        </Modal>
    </div>
}