import React from "react"
import interfaceConstant from "../../../utils/constant/interfaceConstant"

class RatherNotSayGenderSvgIcon extends React.PureComponent<interfaceConstant.SvgIconProps> {
    render(): JSX.Element {
        const { width, height, ...otherProps } = this.props
        return (
            <svg width={width} height={height} viewBox="0 0 32 32" version="1.1" {...otherProps}>
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <circle id="Oval" fill="#686C80" cx="16" cy="16" r="7"></circle>
                </g>
            </svg>
        )
    }
}

export default RatherNotSayGenderSvgIcon
