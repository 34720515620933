import React from "react"
import { Snackbar } from "@material-ui/core"
import MuiAlert from "@material-ui/lab/Alert"

interface Iprops {
    openSnackBar: boolean
    setOpenSnackBar: Function
    type: string | "error" | "warning" | "info" | "success"
    message: any,
    autoHideDuration?: number|null
}

const MessageAlert = ({ openSnackBar, setOpenSnackBar, type, message, autoHideDuration }: Iprops): JSX.Element => {
    if (autoHideDuration === undefined) {
        autoHideDuration = 6000;
    }
    const Alert = (props: any): JSX.Element => {
        return <MuiAlert elevation={6} variant="filled" {...props} />
    }
    return (
        <Snackbar
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
            }}
            open={openSnackBar}
            autoHideDuration={autoHideDuration}
            onClose={() => setOpenSnackBar(false)}
        >
            <Alert severity={type} onClose={() => setOpenSnackBar(false)}>{message}</Alert>
        </Snackbar>
    )
}

export default MessageAlert
