import { call, put, all, takeLatest, select } from "redux-saga/effects"
import { PaymentConstant, authLogInConstant } from "../constant"
import { addCardDetails, getPlans, subscribePlan, deleteCardDetails } from "../../utils/services/paymentServices"
import { getUserWithDetails } from "../../utils/services/userServices"
export const getUser = (state: any) => state.auth.userAuthData
const delay = (ms: number): any => new Promise((res) => setTimeout(res, ms))


function* addCard(action: any) {
    try {
        const payload = yield addCardDetails(action.payload.token, action.payload.customerId)
        if (payload.data.message.statusCode && payload.data.message.statusCode === 402) {
            throw new Error("Card Declined")
        }
        if (payload.data.message.statusCode && payload.data.message.statusCode >= 400) {
            throw new Error("Failed to Add Card ")
        } else {
            yield put({ type: PaymentConstant.CARD_DETAILS_ADDED, payload })
            yield delay(4500)
            yield put({ type: PaymentConstant.HIDE_TOAST_CONSTANT })
        }
    } catch (error) {
        yield put({ type: PaymentConstant.ADD_CARD_FAILED, error })
        yield delay(4500)
        yield put({ type: PaymentConstant.HIDE_TOAST_CONSTANT })
    }
}
function* deleteCard(action: any) {
    try {
        const payload = yield deleteCardDetails(action.payload.cardId, action.payload.customerId, action.payload.userId)
        if (payload.data.status === 'success') {
            yield put({ type: PaymentConstant.DELETE_CARD_SUCCESS, payload });
            yield delay(4500);
            yield put({ type: PaymentConstant.HIDE_TOAST_CONSTANT });
        }
        if (payload.data.status === 'fail') yield put({ type: PaymentConstant.DELETE_CARD_FAILURE, payload })

    } catch (error) {
    }
}
function* fetchPlans() {
    try {
        const plansData = yield call(getPlans)
        yield put({ type: PaymentConstant.GET_LESSONS_PLANS, payload: plansData })
    } catch (error) {
        console.log(error)
    }
}
function* findPlansByName(props: any) {
    const history = props.payload.history;
    const plans = yield select((state) => state.payment.plans)

    var plan = { productId: false }
    if (plans.length == 0) {
        try {
            const plansData = yield call(getPlans)
            yield put({ type: PaymentConstant.GET_LESSONS_PLANS, payload: plansData })
            plansData.map((planObj: any) => {
                planObj.prices.map((subItem: any) => {
                    if (subItem.id == props.payload.subType) {
                        plan = {
                            productId: planObj.id,
                            ...subItem
                        }
                        return subItem.id == props.payload.subType
                    }
                })
            })
            if (plan.productId) {
                yield put({ type: PaymentConstant.FIND_PLAN_SUCCESS, payload: { plan: plan } })
            } else {
                yield put({ type: PaymentConstant.FIND_PLAN_FAILURE });
            }
        } catch (error) {
            console.log(error)
        }
    } else {
        plans.map((planObj: any) => {
            planObj.prices.map((subItem: any) => {
                if (subItem.id == props.payload.subType) {
                    plan = {
                        productId: planObj.id,
                        ...subItem
                    }
                    return subItem.id == props.payload.subType
                }
            })
        })
        if (plan.productId) {
            yield put({ type: PaymentConstant.FIND_PLAN_SUCCESS, payload: { plan: plan } })
        } else {
            yield put({ type: PaymentConstant.FIND_PLAN_FAILURE });
        }
    }
}

function* paymentMethod(action: any) {
    try {
        const paymentData = yield call(subscribePlan, action.payload?.data)

        if (paymentData.code == "02") {
            const userId = yield select((state) => state.auth?.userAuthData?.userId)
            const userDetails = yield getUserWithDetails(userId)
            yield put({ type: authLogInConstant.LOGIN_SUCCESS, user: userDetails })

            yield put({ type: PaymentConstant.ALREADY_SUBSCRIBED, payload: true })
        } else if (paymentData.code == "01") {
            const userId = yield select((state) => state.auth?.userAuthData?.userId)
            const userDetails = yield getUserWithDetails(userId)
            yield put({ type: authLogInConstant.LOGIN_SUCCESS, user: userDetails })
            yield put({ type: PaymentConstant.SUBSCRIPTION_REQUEST_SUCCESS })
        } else {
            alert(paymentData?.message)
        }
    } catch (error) {
        console.log(error)
    }
}

export default all([
    takeLatest(PaymentConstant.ADD_CARD_DETAILS, addCard),
    takeLatest(PaymentConstant.DELETE_CARD, deleteCard),
    takeLatest(PaymentConstant.REQUEST_GET_LESSONS_PLANS, fetchPlans),
    takeLatest(PaymentConstant.FIND_PLANS_BY_PLAN_NAME, findPlansByName),
    takeLatest(PaymentConstant.CALL_PAYMENT_METHOD, paymentMethod),
])
