import React, { useMemo, useState } from "react"
import { BOOKING_REQUEST_STATUS_CODES } from "../../../../store/reducers/sessions-reducers/sessionReducer"
import "./BookingPaymentStatus.scss"
import { Launch } from "@material-ui/icons"
type IProps = {
    userType: string,
    invoicePaymentUrl?: string,
    status: BOOKING_REQUEST_STATUS_CODES,
}

export const BookingPaymentStatus = ({ status, userType, invoicePaymentUrl }: IProps) => {
    const isStudent = userType.toUpperCase() === "STUDENT"
    const statusToBgColorMapping = useMemo(() => {
        return {
            [BOOKING_REQUEST_STATUS_CODES.PAYMENT_ACTION_REQUIRED]: isStudent
                ? "text-danger"
                : "status-secondary",
            [BOOKING_REQUEST_STATUS_CODES.PAYMENT_FAILED]: isStudent
                ? "text-danger"
                : "status-secondary",
            [BOOKING_REQUEST_STATUS_CODES.PAYMENT_PROCESSING]: "status-secondary",
            [BOOKING_REQUEST_STATUS_CODES.ACCEPTED]: "status-primary",
            [BOOKING_REQUEST_STATUS_CODES.REJECTED]: "status-primary",
            [BOOKING_REQUEST_STATUS_CODES.CANCELLED]: "status-primary",
            [BOOKING_REQUEST_STATUS_CODES.CONFIRMED]: "status-primary",
            [BOOKING_REQUEST_STATUS_CODES.WAITING_FOR_STUDENT_CONFIRMATION]: "status-secondary",
            [BOOKING_REQUEST_STATUS_CODES.WAITING_FOR_TEACHER_CONFIRMATION]: "status-secondary",
        }
    }, [isStudent])
    const statusToTextMapping = useMemo(() => {
        return {
            [BOOKING_REQUEST_STATUS_CODES.PAYMENT_ACTION_REQUIRED]: {
                text: isStudent ? "Payment Action Required" : "Waiting For Payment",
                tooltip: "",
            },
            [BOOKING_REQUEST_STATUS_CODES.PAYMENT_FAILED]: {
                text: isStudent ? "Payment Failed" : "Waiting For Payment",
                tooltip: "",
            },
            [BOOKING_REQUEST_STATUS_CODES.PAYMENT_PROCESSING]: {
                text: isStudent ? "Processing Payment" : "Waiting For Payment",
                tooltip: "",
            },
            [BOOKING_REQUEST_STATUS_CODES.ACCEPTED]: {
                text: "Accepted",
                tooltip: "",
            },
            [BOOKING_REQUEST_STATUS_CODES.REJECTED]: {
                text: "Rejected",
                tooltip: "",
            },
            [BOOKING_REQUEST_STATUS_CODES.CANCELLED]: {
                text: "Canceled",
                tooltip: "",
            },
            [BOOKING_REQUEST_STATUS_CODES.CONFIRMED]: {
                text: "Confirmed",
                tooltip: "",
            },
            [BOOKING_REQUEST_STATUS_CODES.WAITING_FOR_STUDENT_CONFIRMATION]: {
                text: "Waiting for Student",
                tooltip: "",
            },
            [BOOKING_REQUEST_STATUS_CODES.WAITING_FOR_TEACHER_CONFIRMATION]: {
                text: "Waiting for Teacher",
                tooltip: "",
            },
        }
    }, [isStudent])
    const showLinkToInvoice = useMemo(() => {
        const isPaymentManual = [
            BOOKING_REQUEST_STATUS_CODES.PAYMENT_ACTION_REQUIRED,
            BOOKING_REQUEST_STATUS_CODES.PAYMENT_FAILED,
        ].includes(status)
        return isStudent && isPaymentManual
    }, [status, isStudent])
    const bgColor = statusToBgColorMapping[status]
    const textAndToolTip: { text: string, tooltip: string } = statusToTextMapping[status]
    return (
        <>
            <h6>Status</h6>
            <span className={`badge rounded-pill ${bgColor}`}>{textAndToolTip.text}</span>
            <br />
            {showLinkToInvoice === true && (
                <a
                    href={invoicePaymentUrl || "#"}
                    className="badge rounded-pill"
                    target={invoicePaymentUrl ? "_blank" : "_self"}
                >
                    Pay Now! <Launch fontSize={"inherit"} />{" "}
                </a>
            )}
        </>
    )
}
