import { EpicBoardConstant } from "../constant"

const initialState = {
    isLoading: false,
    isError: "",
    epicBoard: "",
    currentlyBoard: 0,
}

const epicBoardReducer = (state = initialState, action: any): object => {
    switch (action.type) {
        case EpicBoardConstant.EPIC_BOARD_UPDATE:
            return {
                ...state,
                epicBoard: action.action.epicBoard,
            }
        case EpicBoardConstant.EPIC_BOARD_CHANGE_TAB:
            return {
                ...state,
                currentlyBoard: action.action.currentlyBoard,
            }
        case EpicBoardConstant.EPIC_BOARD_TUTOR_INFO:
            return {
                ...state,
                tutorInfo: action.action.tutorInfo,
            }
        case EpicBoardConstant.EPIC_BOARD_UPDATE_SESSION_INFO:
            return {
                ...state,
                sessionInfo: action.action.sessionInfo,
            }
        default:
            return state
    }
}

export default epicBoardReducer
