import * as React from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "components/button/Button";
import { useEffect, useState } from "react";
import { verifyHideTeacher } from "pages/admin/components/users/apis";
import MessageAlert from "components/message-alert/MessageAlert";
import { User } from "pages/admin/components/users/types";

const teacherOption = ["delete", "verify", "changeTimeZone"];
const studentOptions = ["delete"];

const PositionedMenu = ({ data, updateData }: { data: User, updateData: Function }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [options, setOptions] = useState<string[]>(teacherOption);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [message, setMessage] = useState({
    message: "",
    type: "",
  });
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (data.type === "STUDENT" || data.type === "Admin") {
      setOptions(studentOptions);
    }
    setAnchorEl(event.currentTarget);
  };
  const handleVerify = async () => {
    setAnchorEl(null);
    if (data.type === "TEACHER") {
      setMessage({
        message: "Updating Data",
        type: "warning",
      });
      setOpenSnackBar(true);
      const status = await verifyHideTeacher(
        data.id,
        !data.verified
      );
      const update= await updateData(data);
      setMessage({
        message: "Verification Status has been updated",
        type: "success",
      });
      setOpenSnackBar(true);
    }
  };
  const handleClose = async () => {
    setAnchorEl(null);
  };
  return (
    <div>
      <Button
        aria-controls={open ? "demo-positioned-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        title="Options"
        clickAction={handleClick}
        style={{ width: "125px", fontSize: "14px" }}
      />

      <Menu
        id="demo-positioned-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem
          style={{ minWidth: "130px", textAlign: "center" }}
          onClick={handleVerify}
        >
          {data?.type === "TEACHER"
            ? data?.verified
              ? "Hide"
              : "Verify"
            : "No Option Available"}
        </MenuItem>
      </Menu>
      <MessageAlert
        openSnackBar={openSnackBar}
        setOpenSnackBar={setOpenSnackBar}
        type={message.type}
        message={message.message}
      />
    </div>
  );
};
export default PositionedMenu;
