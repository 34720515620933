import React from "react"
import interfaceConstant from "../../../utils/constant/interfaceConstant"

class HomeSvgIcon extends React.PureComponent<interfaceConstant.SvgIconProps> {
    render(): JSX.Element {
        const { width, height, iconColor, ...otherProps } = this.props
        return (
            <svg width={width} height={height} {...otherProps}>
                <path
                    d="M31 13.192L16 2 1 13.367l1.146 1.554 2.196-1.613V28.58c0 1.073.87 1.943 1.943 1.943h19.43c1.073 0 1.943-.87 1.943-1.943V13.036l2.234 1.671L31 13.192zM25.715 28.58H6.285V11.832L16 4.429l9.715 7.189V28.58z"
                    fill={iconColor}
                    fillRule="evenodd"
                ></path>
            </svg>
        )
    }
}

export default HomeSvgIcon
