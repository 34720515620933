import React from "react";
import Modal from "components/modal_/Modal";
import './reschedulingPopups.scss';
import Button from "components/button/Button";
import reviewIcon from "assets/images/reviewSubmited.svg"
interface TutorSentRescheduleRequest {
    onGotoMySession: () => void,
    rescheduleTime: string,
    rescheduleDate:string,
    isShown: boolean,
    onClose: () => void
}
export function TutorSentRescheduleRequest(props: TutorSentRescheduleRequest) {
    return <div className="tutor-student-reschedule-modal">
          <Modal
        visible={props.isShown}
        onExit={props.onClose}
    >
        <div className="modal-student-accept-reschedule">
            <img src={reviewIcon} alt="tutor images" />
            <h3>Request Successfully Sent</h3>

            <div className="grey_div">
                <h4>{props.rescheduleDate} at {props.rescheduleTime}</h4>
            </div>
            <div className="text-center pt-5">
                <Button className="accept-reschedule-btn" title="Go to Dashboard" clickAction={props.onGotoMySession} isOutlineStyle={true} />
            </div>
        </div>

    </Modal>
    </div>
}