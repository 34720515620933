import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next";
import firebase from "firebase";
import Button from "components/button/Button";
import { InputBox } from "components/input-field/InputFields";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons"
import { Link, useHistory } from "react-router-dom";
import { navigatetoUrl } from "utils/helper/helper";


const ResetPassword = ({ oobCode }: { oobCode: string }): JSX.Element => {

    const { t } = useTranslation();
    const history = useHistory();

    const [email, setEmail] = useState<string>("");
    const [newPassword, setNewPassword] = useState<string>("");
    const [passwordFieldType, setPasswordFieldType] = useState<"password" | "text">("password");
    const [resetPasswordResponse, setResetPasswordResponse] = useState<string>("");
    const [response, setResponse] = useState<"success" | "error" | "inprogress">("inprogress");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isPasswordSet, setIsPasswordSet] = useState<boolean>(false);


    const resetPassword = async () => {
        setResetPasswordResponse("");
        setIsLoading(true);
        if (newPassword.length < 6) {
            setResetPasswordResponse("Must be at least 6 character");
            setIsLoading(false);
            return;
        }
        firebase.auth().confirmPasswordReset(oobCode, newPassword).then((res) => {
            setIsLoading(false);
            setIsPasswordSet(true);
        }).catch(err => {
            setIsLoading(false);
            setResetPasswordResponse(err.message);
        })
    }

    const verifyPasswordResetCode = async () => {
        try {
            const email = await firebase.auth().verifyPasswordResetCode(oobCode);
            setEmail(email);
            setResponse("success");
        } catch (err) {
            setResponse("error");
        }
    }

    useEffect(() => {
        verifyPasswordResetCode();
    }, []);

    return (
        <>
            <div className="auth-redirect">
                <div className="card-container">
                    {response === "success" && !isPasswordSet &&
                        <>
                            <p className="title">Reset your password</p>
                            <p>for {email}</p>
                            <InputBox
                                type={passwordFieldType}
                                className={"resume-page-input password-input"}
                                title=""
                                placeholder={"New password"}
                                inputProps={{
                                    name: "password",
                                    value: newPassword,
                                    onChange: (e: any,) => setNewPassword(e.target.value),
                                }}
                                error={resetPasswordResponse}
                                iconProps={{
                                    icon: passwordFieldType === "password" ? faEye : faEyeSlash,
                                    onClick: () => setPasswordFieldType(passwordFieldType === "password" ? "text" : "password")
                                }}
                            />
                            <Button
                                className="button"
                                clickAction={resetPassword}
                                title={"Save"}
                                isDisabled={isLoading}
                                isloading={isLoading}
                            />
                        </>
                    }
                    {response === "error" &&
                        <>
                            <p className="title">Try resetting your password again</p>
                            <p>Your request to reset your password has expired or the link has already been used</p>
                            <Link to="/sign-in">
                                <u>{t("sign-in")}?</u>
                            </Link>
                        </>
                    }
                    {isPasswordSet &&
                        <>
                            <p className="title">Password changed</p>
                            <p>You can now sign in with your new password</p>
                            <Button
                                className="button"
                                clickAction={() => {
                                    navigatetoUrl(history, "/sign-in")
                                }}
                                title={t("go-to-login-page")}
                            />
                        </>
                    }
                </div>
            </div>
        </>
    )
}

export default ResetPassword

