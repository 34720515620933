import React from "react"
import { navigatetoUrl } from "../../../../utils/helper/helper"
import { useHistory } from "react-router-dom";
import colors from "assets/colors";
import BackArrowSvgIcon from "assets/images/svgIcons/BackArrowSvgIcon";
import UnderLinedTabs from "components/tabs/underline-tabs/UnderlinedTabs";



const teacherTabsConstants = {
    MYTUTORS: "My Tutors",
    MYADVISORS: "My Advisors"
}

const teacherTabs = {
    [teacherTabsConstants.MYTUTORS]: "tutors",
    [teacherTabsConstants.MYADVISORS]: "advisors"
}

const TutorsAdvisorsHeader = (): JSX.Element => {
    const history = useHistory()

    return (
        <>
            <div>
                <div className="display-inline-flex">
                    <p className="title">My Tutors and Advisors</p>
                </div>
                <span className="back-arrow display-inline-flex" onClick={() => navigatetoUrl(history, "/panel/dashboard")}>
                    <BackArrowSvgIcon iconColor={colors.vividBlue} width="32" height="32" />
                </span>
            </div>
            <UnderLinedTabs
                expandFull={true}
                labels={Object.keys(teacherTabs)}
                selectedLabel={window.location.href.includes("tutors") ? "My Tutors" : "My Advisors"}
                onLabelClick={(tab) => { navigatetoUrl(history, "/panel/dashboard/" + teacherTabs[tab]) }}
            />
        </>
    )
}
export default TutorsAdvisorsHeader
