import {
    StudentDashboardConstants
} from "../constant"

const initialState = {
    upcomingSessions: {
        data: [],
        isLoading: false
    },
    tutors: {
        data: [],
        isLoading: false
    },
    advisors: {
        data: [],
        isLoading: false
    }
}

const studentDashboardReducer = (state = initialState, action: any): object => {
    switch (action.type) {
        case StudentDashboardConstants.SET_STUDENT_UPCOMING_SESSIONS:
            state.upcomingSessions.data = action.payload.data || state.upcomingSessions.data;
            state.upcomingSessions.isLoading = !!action.payload.isLoading;
            state = {
                ...state,
            }
            break;
        case StudentDashboardConstants.SET_STUDENT_TUTORS:
            state.tutors.data = action.payload.data || state.tutors.data;
            state.tutors.isLoading = !!action.payload.isLoading;
            state = {
                ...state,
            }
            break;
        case StudentDashboardConstants.SET_STUDENT_ADVISORS:
            state.advisors.data = action.payload.data || state.advisors.data;
            state.advisors.isLoading = !!action.payload.isLoading;
            state = {
                ...state,
            }
            break;
        default:
            break;
    }
    return state;
}

export default studentDashboardReducer
