const initialState = {
    isloading: false,
}

const buttonReducer = (state = initialState, action: any): object => {
    switch (action.type) {
        case "BUTTON_START_LOADING":
            return {
                isloading: true,
            }
        case "BUTTON_STOP_LOADING":
            return {
                isloading: false,
            }
        default:
            return state
    }
}

export default buttonReducer
