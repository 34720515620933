import StudentDashboardPage from "../pages/home/dashboard/StudentDashboardPage"
import MessagePage from "../pages/home/message/_MessagePage"
import HomeSvgIcon from "../assets/images/svgIcons/HomeSvgIcon"
import SearchSvgIcon from "../assets/images/svgIcons/SearchSvgIcon"
import CalendarSvgIcon from "../assets/images/svgIcons/CalendarSvgIcon"
import PersonSvgIcon from "../assets/images/svgIcons/PersonSvgIcon"
import HelpSvgIcon from "../assets/images/svgIcons/HelpSvgIcon"
import LessonsSvgIcon from "../assets/images/svgIcons/LessonsSvgIcon"
import MySessionPage from "../pages/home/my-session/MySessionPage"
import interfaceConstant from "../utils/constant/interfaceConstant"
import { applicationConstant } from "../utils/constant/applicationConstant"
import UserProfilePage from "../pages/home/my-account/UserProfilePage"
import TutorSearchRoute from "../pages/home/tutor-search/TutorSearchRoute"
import HelpPage from "../pages/home/help/HelpPage"
import LessonRoutes from "../pages/home/lesson-content/LessonRoutes"
import MessageIcon from "components/message-icon/messageIcon"

const routeUrl: interfaceConstant.IrouteUrl = applicationConstant.routeUrl
const PanelRoutesForStudent: interfaceConstant.IpanelRoute[] = [
    {
        path: routeUrl.DASHBOARD_PAGE,
        name: "Dashboard",
        icon: HomeSvgIcon,
        component: StudentDashboardPage,
        layout: routeUrl.PANEL_ROUTE,
    },
    {
        path: routeUrl.SEARCH_PAGE,
        name: "Tutor Search",
        icon: SearchSvgIcon,
        component: TutorSearchRoute,
        layout: routeUrl.PANEL_ROUTE,
    },
    {
        path: routeUrl.SESSION_PAGE,
        name: "My Sessions",
        icon: CalendarSvgIcon,
        component: MySessionPage,
        layout: routeUrl.PANEL_ROUTE,
    },
    {
        path: routeUrl.MESSAGE_PAGE,
        name: "Message Center",
        icon: MessageIcon,
        component: MessagePage,
        layout: routeUrl.PANEL_ROUTE,
    },
    {
        path: routeUrl.LESSONS_PAGE,
        name: "College Counseling",
        icon: LessonsSvgIcon,
        component: LessonRoutes,
        layout: routeUrl.PANEL_ROUTE,
    },
    {
        path: routeUrl.USER_PROFILE_PAGE,
        name: "My Profile",
        icon: PersonSvgIcon,
        component: UserProfilePage,
        layout: routeUrl.PANEL_ROUTE,
    },
    {
        path: routeUrl.HELP_PAGE,
        name: "Support",
        icon: HelpSvgIcon,
        component: HelpPage,
        layout: routeUrl.PANEL_ROUTE,
    },
]

export default PanelRoutesForStudent
