import React, { useEffect, useState } from "react"
import { useParams, useHistory, Link } from "react-router-dom"
import Grid from "@material-ui/core/Grid"
import Button from "../../../components/button/Button"
import LessonsCard from "../../../components/lesson/LessonsCard"
import { externalLinks } from "../../../utils/constant/externalLinks"
import { useSelector } from "react-redux"
import ShareaSale from "../../../components/AffilateMarketing/ShareaSale"
const LessonSubscriptionConfirmedPage = (props: any) => {
    const { unit_amount, nickname, promoCodeDetails } = props.location.state.params
    const history = useHistory()
    const subscriptionType = { term: nickname, price: unit_amount / 100 }
    const [netPrice, setNetPrice] = useState<number>(subscriptionType.price);
    const [totalDiscount, setTotalDiscount] = useState<number>(0);
    const subId =useSelector((state: any) => state.auth.userAuthData?.subscription?.id);
    useEffect(() => {
        const totalPrice = subscriptionType.price;
        let discount = promoCodeDetails?.amount_off ? promoCodeDetails.amount_off / 100 : promoCodeDetails?.percent_off ? totalPrice * promoCodeDetails?.percent_off / 100 : 0;
        discount = discount > totalPrice ? totalPrice : discount;
        setTotalDiscount(Number(discount.toFixed(2)));
        const discountedPrice = Number((totalPrice - discount).toFixed(2));
        setNetPrice(discountedPrice);
    }, [subscriptionType, promoCodeDetails])

    return (
        <Grid container>
            <Grid className="m-auto" xl={6} lg={8} md={8} sm={10} xs={10} >
                <div className="confirmed-container">
                    <LessonsCard />
                    <p className="payment-confirmed-heading p-38 p-mango">
                        Your payment was confirmed!
                    </p>
                    <Grid container className="payment-row">
                        <Grid xs={6} className="p-18 p-battleship access-label">
                            <p>
                                {subscriptionType.term}
                            </p>
                        </Grid>
                        <Grid item xs={6} className="p-18 p-battleship price-label">
                            <p>
                                <b>${subscriptionType.price}</b>
                            </p>
                        </Grid>
                        {totalDiscount > 0 &&
                            <>
                                <Grid xs={6} className="p-18 p-battleship access-label">
                                    <p>
                                        Coupon <b>"{promoCodeDetails.promoCode}"</b> Applied
                                    </p>
                                </Grid>
                                <Grid item xs={6} className="p-18 p-battleship price-label">
                                    <p>
                                        -${totalDiscount}
                                    </p>
                                </Grid>
                            </>
                        }
                        <Grid item xs={12}>
                            <hr />
                        </Grid>
                        <Grid xs={6} className="p-18 p-battleship access-label">
                            <p className="p-mango">
                                Total (USD)
                            </p>
                        </Grid>
                        <Grid item xs={6} className="p-18 p-battleship price-label">
                            <p>
                                <b>${netPrice}</b>
                            </p>
                        </Grid>
                    </Grid>
                    <hr />
                    <div>
                        <a href={externalLinks.CLIENT_SUPPORT}>Client support</a>
                        <a style={{ float: "right" }} href={externalLinks.CONTACT_TCD}>
                            Contact TCD
                        </a>
                    </div>
                    <br />
                    <div>
                        <a href={externalLinks.VISIT_HELP_CENTER}>Contact us for help</a>
                    </div>
                    <div className="button-container" style={{ paddingTop: "60px" }}>
                        <Button title="Go to Lessons" clickAction={() => history.push("/panel/lessons")} />
                    </div>
                </div>
            </Grid>
            <ShareaSale/>
        </Grid>
    )
}

export default LessonSubscriptionConfirmedPage
