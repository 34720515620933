import { call, put, all, takeLatest } from "redux-saga/effects"
import { setRapidBookingAvailability, setRapidBookingLoadingStatus } from "../actions/rapidBookingActions"
import { RapidBookingConstant } from "../constant";
import { getRapidBookingAvailabilityService, updateRapidBookingAvailabilityService } from "../../utils/services/userServices";
import { showToast } from "../actions/toastAction";

function* getRapidBookingAvailability(action: any): any {
    try {
        yield put(setRapidBookingLoadingStatus(true));
        const RapidBooking = yield call(getRapidBookingAvailabilityService, action.payload?.userId)
        yield put(setRapidBookingAvailability(RapidBooking?.status || false));
        yield put(setRapidBookingLoadingStatus(false));
    } catch (error) {
        console.log(error);
        yield put(setRapidBookingLoadingStatus(false));
        yield put(showToast({
            show: true,
            message: "Error: " + error?.message,
            isSuccess: false
        }));
    }
}

function* updateRapidBookingAvailability(action: any): any {
    try {
        yield put(setRapidBookingLoadingStatus(true));
        const isAvailable = yield call(updateRapidBookingAvailabilityService, action.payload?.userId, !!(action.payload?.isAvailable))
        yield put(setRapidBookingAvailability(isAvailable));
        yield put(setRapidBookingLoadingStatus(false));
        yield put(showToast({
            show: true,
            message: `Rapid Booking ${isAvailable === true ? "On" : "Off"}`,
            isSuccess: isAvailable
        }));
    } catch (error) {
        console.log(error);
        yield put(setRapidBookingLoadingStatus(false));
        yield put(showToast({
            show: true,
            message: "Error: " + error.message,
            isSuccess: false
        }));
    }
}

export default all([
    takeLatest(RapidBookingConstant.GET_RAPID_BOOKING_AVAILABILITY, getRapidBookingAvailability),
    takeLatest(RapidBookingConstant.UPDATE_RAPID_BOOKING_AVAILABILITY, updateRapidBookingAvailability),
]);
