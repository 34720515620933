import React, { useEffect, useState } from "react"
import { Grid } from "@material-ui/core"
import SubscriptionItems from "./SubscriptionItems"
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons"
import { navigatetoUrl } from "../../../../utils/helper/helper"
import RoundedButton from "../../../../components/rounded-button/RoundedButton"
import { useHistory } from "react-router-dom"
import OnboardingBackground from "../../../../components/on-boarding/onboarding-background/OnboardingBackground"
import axios from "axios"
import { useDispatch, useSelector } from "react-redux"
import { fetchPlans } from "store/actions/PaymentActions"
import { useTranslation } from 'react-i18next';
import Button from "components/button/Button"
import {useMediaQuery} from "react-responsive"
import TcdSvgLogoMob from "assets/images/svgIcons/tcdSvgLogoMob"
import ColoredTCDlogo from "../../../../assets/images/colored-TCD-logo.png"
import ExitButton from "../../../../assets/images/exit-button-small.png"
import Quotation from "../../../../assets/images/quotation.png"

const LessonOptionsPage = () => {
    const isMobile = useMediaQuery({ query: '(max-device-width: 767px)' });
    const products = useSelector((state: any): any => state.payment.plans)
    const history = useHistory();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    useEffect(() => {
        dispatch(fetchPlans()); 
    }, []);

    return (
        <>
            <OnboardingBackground />
            <div className="top-space subscription-options-onBoarding">
                <Grid container >
                    <Grid item xl={2} lg={1} md={1} sm={12} xs={12}></Grid>
                    <Grid item xl={8} lg={10} md={10} sm={12} xs={12} className="main-content onbaording-subscription-content">
                        <div>
                            {isMobile?
                            <div className="left-space"><TcdSvgLogoMob /></div>:<img src={ColoredTCDlogo} className="left-space color-logo-sigin-screen" />
                            }
                            <img src={ExitButton}  onClick={() => {
                                navigatetoUrl(history, "/college-counseling")
                            }} className="right-space"/>    
                        </div>
                        <div className="signup-back-btn subscription-options-back-btn">
                        </div>
                        <div className="subscription-options">
                            <p className="subscription-heading">{'Choose your subscription'}</p>
                            <p className="subscription-subhead-no-bottom p-18 p-battleship">{t('proven-college-counselors')}</p>
                            <Button className="dashboard-college-counselig-button view-lesson-button" title={"View Lessons"} clickAction={() => {
                                navigatetoUrl(history, "/college-counseling")
                            }} />
                            <SubscriptionItems products={products} />
                            <img className="bottom-space" src={Quotation}/>
                            <p className="subscription-subhead-no-bottom subscription-subhead-left p-18 p-battleship subscription-quote">{t('college-guide')}</p>
                            <p className="subscription-subhead-no-bottom subscription-subhead-left p-18 p-battleship right-name subscription-quote">Jessica B.</p>
                        </div>
                    </Grid>
                    <Grid item xl={2} lg={1} md={1} sm={12} xs={12}></Grid>
                </Grid>
            </div>
        </>
    )
}

export default LessonOptionsPage