import React from "react"

interface Iprops {
    title: any
    name: string
    circle?: any
    inputProps: any
    error?: any
    classNames?: string
}

const CheckBox = ({ title, name, inputProps, error, circle, classNames }: Iprops): JSX.Element => {
    return (
        <div className={`custom-check ${circle && "circle"} ${classNames}`}>
            <label className={error ? "container error" : "container"}>
                {title}
                <input id={name} name={name} type="checkbox" {...inputProps} />
                <span className={error ? "checkmark checkmark-error" : "checkmark"}></span>
            </label>
            {error && <p className="checkmark-error-text {">{error}</p>}
        </div>
    )
}

export default CheckBox


