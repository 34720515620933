import { call, put, all, takeLatest } from "redux-saga/effects"
import {
    createUser,
    confirmPasswordReset,
    applyActionCode,
    checkActionCode,
    signInWithEmailAndPassword,
    sendPasswordResetEmail,
    createUserWithEmailAndPassword,
    signInWithCustomToken,
} from "../../utils/helper/firebase"
import {
    authLogInConstant,
    authLogOutConstant,
    modalConstant,
    forgotConstant,
    confirmEmail,
    authSignUpConstant,
    toastConstant,
    resetPassword,
    buttonConstants,
    TutorEducationConstant,
    LogoutConstant,
} from "../constant"
import { navigatetoUrl } from "../../utils/helper/helper"
import { createStudentThroughBackend, getUserWithDetails } from "../../utils/services/userServices"
import * as crashLogger from "../../utils/services/crashLogger"
function* login(action: any) {
    const { email, password } = action.user
    try {
        const result = yield call(signInWithEmailAndPassword, email, password)
        const userAuthData = result.user

        if (!userAuthData.emailVerified) {
            throw new SyntaxError("Please check your email to verify your account.")
        }
        const userDetails = yield getUserWithDetails(userAuthData.uid)
        yield put({ type: authLogInConstant.LOGIN_SUCCESS, user: userDetails })
    } catch (error) {
        let message
        switch (error.code) {
            case "auth/wrong-password":
                message = "Invalid password, please try again"
                break
            case "auth/user-not-found":
                message = "You need to Signup to proceed"
                break
            default:
                message = error.message
                crashLogger.log(`Login: ${email}`, error)
                break
        }
        yield put({ type: authLogInConstant.LOGIN_FAILURE, user: { message: message } })
        yield put({
            type: toastConstant.TOAST_SHOW,
            toastInfo: {
                show: true,
                message: `${message}`,
                isSuccess: false,
            },
        })
    }
}
function* loginWithCustomToken(userData: any) {
    const token = userData.user
    try {
        const result = yield call(signInWithCustomToken, token)
        const userAuthData = result.user
        if (!userAuthData.emailVerified) {
            throw new SyntaxError("Please check your email to verify your account.")
        }
        const userDetails = yield getUserWithDetails(userAuthData.uid)
        yield put({ type: authLogInConstant.LOGIN_SUCCESS, user: userDetails })
    } catch (error) {
        let message
        switch (error.code) {
            case "auth/wrong-password":
                message = "Invalid password, please try again"
                break
            case "auth/user-not-found":
                message = "You need to Signup to proceed"
                break
            default:
                message = error.message
                crashLogger.log(`Login Custom Token: ${token}`, error)
        }
        yield put({ type: authLogInConstant.LOGIN_FAILURE, user: { message: message } })
        yield put({
            type: toastConstant.TOAST_SHOW,
            toastInfo: {
                show: true,
                message: `${message}`,
                isSuccess: false,
            },
        })
    }
}
function* signup(action: any) {
    const userInfo = action.user
    const { email, password } = action.user
    let userId: string | undefined = undefined
    try {
        const result = yield call(createUserWithEmailAndPassword, email, password)
        const userAuth = result.user
        userId = userAuth.uid

        yield createUser(userInfo, userAuth)
        yield put({
            type: modalConstant.MODAL_SHOW,
            modalInfo: {
                show: true,
                title: "Your account is almost set up!",
                text: "Please check your email to verify your account",
            },
        })
        yield put({ type: authSignUpConstant.SIGNUP_SUCCESS })
    } catch (error) {
        crashLogger.log(`Signup : ${email}`, error)
        if (userId) {
            console.log("Error while creating user: ", error)
            try {
                const isSuccess = yield createStudentThroughBackend(userId, userInfo)
                if (!isSuccess) {
                    throw new Error(
                        "Unknown error. Cannot setup your account right. Please try again.",
                    )
                }
                yield put({
                    type: modalConstant.MODAL_SHOW,
                    modalInfo: {
                        show: true,
                        title: "Your account is almost set up!",
                        text: "Please check your email to verify your account",
                    },
                })
                yield put({ type: authSignUpConstant.SIGNUP_SUCCESS })
            } catch (e) {
                crashLogger.log(`Signup (retrying): ${email}`, e)
                //TO-DO: remove from the authentication
                yield put({
                    type: modalConstant.MODAL_SHOW,
                    modalInfo: {
                        show: true,
                        title: "Error",
                        text: e.toString(),
                    },
                })
                yield put({ type: authSignUpConstant.SIGNUP_FAILURE })
            }
        } else {
            yield put({
                type: modalConstant.MODAL_SHOW,
                modalInfo: {
                    show: true,
                    title: "Error",
                    text: error.toString(),
                },
            })
            yield put({ type: authSignUpConstant.SIGNUP_FAILURE })
        }
    }
}

function* logOut() {
    yield localStorage.removeItem("user")
    yield localStorage.removeItem("user-type")
    yield put({ type: "CLEAR_SESSIONS" })
    yield put({ type: TutorEducationConstant.CLEAR_TUTOR_EDUCATION })
    yield put({ type: LogoutConstant.CLEAR_DATA })
    yield put({ type: authLogOutConstant.LOGOUT_SUCCESS, user: {} })
}

function* forgotPassword(action: any) {
    yield put({ type: buttonConstants.BUTTON_START })
    const { email } = action.forgotPassword
    try {
        yield call(sendPasswordResetEmail, email)
        yield put({
            type: toastConstant.TOAST_SHOW,
            toastInfo: {
                show: true,
                message: "Password reset instructions have been sent to the email address you have registered.",
                isSuccess: true,
            },
        })
        yield put({ type: buttonConstants.BUTTON_STOP })
    } catch (error) {
        let message
        switch (error.code) {
            case "auth/user-not-found":
                message = "You need to Signup to proceed"
                break
            default:
                message = error.message
                crashLogger.log(`Forgot Password : ${email}`, error)
        }
        yield put({
            type: toastConstant.TOAST_SHOW,
            toastInfo: {
                show: true,
                message: message,
                isSuccess: false,
            },
        })
        yield put({ type: buttonConstants.BUTTON_STOP })
    }
}

function* verifyEmail(action: any) {
    const { code } = action
    try {
        yield call(applyActionCode, code)
        yield put({
            type: confirmEmail.CONFIRM_SUCCESS,
            message: "Your e-mail has been verified. You can now sign in with your new account",
        })
    } catch (error) {
        crashLogger.log(`Verify Email : ${code}`, error)
        yield put({
            type: confirmEmail.CONFIRM_FAILURE,
            message: error.message,
            isError: true,
        })
    }
}

function* verifyCode(action: any) {
    const { code, history } = action.action
    try {
        yield call(checkActionCode, code)
    } catch (error) {
        yield put({
            type: modalConstant.MODAL_SHOW,
            modalInfo: {
                show: true,
                title: "Error",
                text: error.message,
            },
        })
    }
}

function* updatePassword(action: any) {
    yield put({ type: buttonConstants.BUTTON_START })
    const { code, newPassword, history } = action.updatePassword
    try {
        yield call(confirmPasswordReset, code, newPassword)
        navigatetoUrl(history, "/sign-in")
        yield put({
            type: toastConstant.TOAST_SHOW,
            toastInfo: {
                show: true,
                message: "Password successfully changed.",
                isSuccess: true,
            },
        })
        yield put({ type: buttonConstants.BUTTON_STOP })
    } catch (error) {
        crashLogger.log(`Update Password : ${code}`, error)
        yield put({
            type: toastConstant.TOAST_SHOW,
            toastInfo: {
                show: true,
                message: error.message,
                isSuccess: false,
            },
        })
        yield put({ type: buttonConstants.BUTTON_STOP })
    }
}

export default all([
    takeLatest(authLogInConstant.LOGIN_REQUEST, login),
    takeLatest(authSignUpConstant.SIGNUP_REQUEST, signup),
    takeLatest(forgotConstant.FORGOT_REQUEST, forgotPassword),
    takeLatest(authLogOutConstant.LOGOUT_REQUEST, logOut),
    takeLatest(resetPassword.RESET_REQUEST, forgotPassword),
    takeLatest("USER_UPDATE_PASSWORD", updatePassword),
    takeLatest("USER_VERIFY_EMAIL", verifyEmail),
    takeLatest("USER_VERIFY_CODE", verifyCode),
    takeLatest(authLogInConstant.LOGIN_WITH_CUSTOM_TOKEN, loginWithCustomToken),
])
