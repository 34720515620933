import React, { useCallback, useEffect, useState } from "react";
import { BookingPending, PendingType, PENDING_STATUS, useBookingPending } from "../../../../pendings";
import { StdWhenTutorAcceptSomeOfMyRequest } from "./StdWhenTutorAcceptSomeOfMyRequest";
import { useHistory } from "react-router-dom";
import defaultAvatar from "assets/images/default-avatar.png";
import { BOOKING_REQUEST_STATUS_CODES } from "store/reducers/sessions-reducers/sessionReducer";
import { SLOT_STATUSES } from "store/constant";
import moment from "moment";
import firebase from "firebase";

export default function StdWhenTutorAcceptSomeOfMyRequestContainer() {
    const bookingPending: BookingPending = useBookingPending();
    const history = useHistory();
    const [isShown, setIsShown] = useState(false);
    const [data, setData] = useState({
        teacherName: "",
        studentPicUrl: defaultAvatar,
        teacherPicUrl: defaultAvatar,
        sessions: [],
    });

    const [currentPendingItem, setCurrentPendingItem] = useState<PendingType | undefined>(undefined);

    const updateBookingData = (bookingRequest: any) => {
        const slots = bookingRequest.slots;
        setData({
            teacherName: bookingRequest.teacherName,
            sessions: slots,
            studentPicUrl: bookingRequest.studentProfilePicture || defaultAvatar,
            teacherPicUrl: bookingRequest.teacherProfilePicture || defaultAvatar
        });
        setIsShown(true);
    }

    const handleOneBookingPendingItem = (pendingItem: PendingType) => {
        const bookingRequest = pendingItem.data;
        updateBookingData(bookingRequest);
        setCurrentPendingItem(pendingItem);
    }

    const validateThePendingItem = (pendingItem: PendingType): boolean => {
        if (pendingItem.status === PENDING_STATUS.UPDATED &&
            moment().utc().diff(moment(pendingItem.data.creationTime._seconds * 1000).utc(), "minutes") <= 12 * 60 &&
            [BOOKING_REQUEST_STATUS_CODES.ACCEPTED, BOOKING_REQUEST_STATUS_CODES.CONFIRMED].includes(pendingItem.data.status) &&
            pendingItem.data.modifiedBy === pendingItem.data.teacherId && //when tutor accepts it
            !pendingItem.data.slots.reduce((allAccepted: boolean, s: any) => allAccepted && [SLOT_STATUSES.ACCEPTED, SLOT_STATUSES.BOOKED].includes(s.status), true) &&
            !pendingItem.data.slots.reduce((allCanceled: boolean, s: any) => allCanceled && [SLOT_STATUSES.CANCELLED].includes(s.status), true) &&
            pendingItem.data.slots.reduce((allFinal: boolean, s: any) => allFinal && [SLOT_STATUSES.ACCEPTED, SLOT_STATUSES.BOOKED, SLOT_STATUSES.CANCELLED].includes(s.status), true) &&
            moment().utc().diff(moment(pendingItem.data.modifiedTime._seconds * 1000).utc(), "hours") < 24
        ) {
            return true;
        }
        return false;
    }

    useEffect(() => {
        bookingPending.ref.once("value", (snapshot) => {
            if (!snapshot.exists()) {
                return;
            }
            const pendingItems: PendingType[] = Object.values(snapshot.val());
            if (!pendingItems) {
                return;
            }
            const filteredItems = pendingItems.filter(pi => validateThePendingItem(pi));
            const sortedItems = filteredItems.sort(({ data: b1 }, { data: b2 }) => {
                return b1.modifiedTime._seconds < b2.modifiedTime._seconds ? 1 : b1.modifiedTime._seconds > b2.modifiedTime._seconds ? -1 : 0;
            });
            if (sortedItems.length < 1) {
                return;
            }
            //get latest
            const pendingItem = sortedItems[0];
            handleOneBookingPendingItem(pendingItem);
        }).catch(console.error)

        const pendingList = (snapshot: firebase.database.DataSnapshot) => {
            if (!snapshot.exists()) {
                return;
            }
            const pendingItem: PendingType = snapshot.val();
            if (pendingItem && validateThePendingItem(pendingItem)) {
                handleOneBookingPendingItem(pendingItem);
            }
        }
        
        bookingPending.ref.on("child_changed", pendingList);
        return () => bookingPending.ref.off("child_changed", pendingList);
    }, [bookingPending]);

    const onClose = useCallback(() => {
        currentPendingItem && bookingPending.resolveItem(currentPendingItem.id);
        setIsShown(false);
    }, [currentPendingItem, isShown, data]);

    const onGoto = useCallback(() => {
        onClose();
        history && history.push('/panel/dashboard')
    }, [isShown, data, currentPendingItem])

    const onSearch = useCallback(() => {
        onClose();
        history && history.push('/panel/search')
    }, [isShown, data, currentPendingItem])

    return <StdWhenTutorAcceptSomeOfMyRequest {...{
        ...data,
        onGoto,
        isShown,
        onClose,
        onSearch,
    }} />
}