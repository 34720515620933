import React from "react"
import { useTranslation } from 'react-i18next';


const FilterHeader = ({ clearAll }: { clearAll: any }): JSX.Element => {
    const { t } = useTranslation();
    return (
        <div className="filter-panel-header">
            <label className="page-header">{t("search")}</label>
            <label className="clear-label" onClick={clearAll} >
                {t("clear-all")}
            </label>
        </div>
    )
}

export default FilterHeader

