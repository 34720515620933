import firebase from "firebase"
import { firebaseAuth, firestore } from "../../../../utils/helper/firebase"
import { User, Teacher } from "./types"

enum ActionTypes {
    VERIFY_HIDE_TEACHER = "VERIFY_HIDE_TEACHER"
}
async function postData(method: string, url: string, data: any, token: string) {

    // Default options are marked with *
    const response = await fetch(url, {
        method: method, // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json',
            'ID-TOKEN': token
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(data) // body data type must match "Content-Type" header
    });
    return response.json(); // parses JSON response into native JavaScript objects
}

export const listofTeachers = async () => {
    const snapshot = await firestore.collection("teachers").where("verified","==",false).get();
    const teachersData = snapshot.docs.filter(doc => {
        try {
            return doc.data()
        }
        catch (e) {
            console.log(e);
            return false;
        }
    }
    ).map(doc => {
        try {
            return {
                backgroundCheck: doc.get("backgroundCheck") as string,
                rating: doc.get("rating") as string,
                id: doc.get("id") as string,
                oneToOneSessionRate: doc.get("oneToOneSessionRate") as string,
                rapidBooking: doc.get("rapidBooking") as string,
                verified: doc.get("verified") as string,
                teacherType: doc.get("teacherType") as string,
            } as Teacher;
        }
        catch (e) {
            console.log(e);
        }
    }
    )
    return teachersData;
}


export const listUsers = async () => {
    const snapshot = await firestore.collection("users").orderBy("creationTime", "desc").get();
    return snapshot.docs.filter(doc => {
        try {
            return doc.get("email") !== "deleted@theclassroomdoor.com" && (doc.get("creationTime") as firebase.firestore.Timestamp)?.toDate()
        }
        catch (e) {
            console.log(e);
            return false;
        }
    }
    ).map(doc => {
        try {
            return {
                firstName: doc.get("firstName") as string,
                lastName: doc.get("lastName") as string,
                id: doc.get("userId") as string,
                email: doc.get("email") as string,
                type: doc.get("userType") as string,
                createdAt: (doc.get("creationTime") as firebase.firestore.Timestamp)?.toDate(),
                defaultTimeZone: doc.get("defaultTimeZone") as string
            } as User;
        } catch (e) {
            console.log(e);
        }
    })

}
export const getUsersAlldata = async () => {
    const usersData: any[] = await listUsers();
    const teacherData: any[] = await listofTeachers();
    if(teacherData.length){
    const newDataTeachers = await teacherData.reduce((previousValue, currentValue) => {
        return {
            ...previousValue,
            [currentValue.id]: { ...currentValue }
        }
    }, {})
    
    let usersArray: any = [];
    const TeacherswithStatus = await usersData.map((userData: any) => {

        if (userData.type === "TEACHER") {
            if(newDataTeachers[userData.id]){
            usersArray.push({ ...userData, ...newDataTeachers[userData.id] });
            }
            else
            {
                usersArray.push({ ...userData,verified :true });
            }
        }
        else {
            usersArray.push({ ...userData });
        }
    })
    return usersArray;
}
else{
    return usersData;
}

}

export const verifyHideTeacher = async (Id: string, status: boolean) => {
    const currentUser = await firebaseAuth.currentUser?.getIdToken() || "";
    return postData('POST', `${process.env.REACT_APP_FIREBASE_PUBLIC_URL}/adminapi/teachers/verifyHideTeacher`, { teacherId: Id, verifiedStatus: status }, currentUser)
        .then(data => {
            return data; // JSON data parsed by `data.json()` call
        })
        .then((result: any) => result?.data)
        .catch((error: any) => console.log(error))
}


