import React, { useCallback, useEffect, useState } from "react";
import { RescheduleRequestSent } from "./RescheduleRequestSent";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { SessionConstant } from "store/constant";
import moment from "moment";

export default function RescheduleRequestSentContainer() {

    const history = useHistory();
    const [isShown, setIsShown] = useState(false);
    const { isSuccess, startTime } = useSelector((state: any) => state.session.rescheduleData);
    const dispatch = useDispatch();

    useEffect(() => {
        setIsShown(isSuccess);
    }, [isSuccess])
    const onClose = useCallback(() => {
        dispatch({ type: SessionConstant.RESCHEDULE_REQUEST_SENT })
    }, [isShown]);

    const onGoto = useCallback(() => {
        onClose();
        history && history.push('/panel/dashboard')
    }, [isShown]);

    const onSearch = useCallback(() => {
        onClose();
        history && history.push('/panel/search')
    }, [isShown])

    return <RescheduleRequestSent {...{
        onGoto,
        rescheduleDate: moment(startTime).format("dddd, MMMM DD, YYYY"),
        rescheduleTime: moment(startTime).format("hh:mm A"),
        isShown,
        onClose,
        onSearch
    }} />
}
