import React from "react"
import interfaceConstant from "../../../utils/constant/interfaceConstant"

class AppleSvgIcon extends React.PureComponent<interfaceConstant.SvgIconProps> {
    render(): JSX.Element {
        const { width, height, iconColor, ...otherProps } = this.props
        return (
            <svg width={width} height={height} viewBox="0 0 17 21" {...otherProps}>
                <g fill={iconColor} fillRule="evenodd">
                    <path d="M8.018 6.608C.185 2.511.001 9.994.001 9.994c-.051 5.82 2.409 8.603 3.986 9.86 1.464 1.164 2.401 1.306 3.03.76.496-.433.73-.463 1.002-.463.607 0 .535.175 1.002.462.755.462 1.473.555 3.007-.77 1.534-1.324 4.06-4.154 4.009-10.003 0 0-.042-7.659-8.019-3.232z" />
                    <path d="M10.535 2.122c.076-.032-1.349.105-2.81 1.553 0 0-.353-2.49 2.498-3.106 2.04-.441 3.28-.239 3.28-.565 0-.132.592 2.916-1.72 3.67-2.31.756-3.723.583-4.372 1.554-.056.085.05-1.806 3.124-3.106z" />
                </g>
            </svg>
        )
    }
}

export default AppleSvgIcon
