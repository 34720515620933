import React, { useEffect, useState } from "react"
import { Modal } from "@material-ui/core"
import Button from "../button/Button"
import { CloseIcon, unRead } from "../icons/Icons"
import { useTranslation } from "react-i18next"

type IProps = {
    show: boolean,
    message: string,
    availableExtensions: number[],
    teacherName: string,
    onClose: () => void,
    onChoose: (extensionTime: number) => void,
}

export function AvailableTimeExtensionsPopup({
    show,
    message,
    availableExtensions,
    teacherName,
    onClose,
    onChoose,
}: IProps) {
    const { t } = useTranslation()
    const [open, setOpen] = useState(!!show)
    useEffect(() => {
        setOpen(show)
    }, [show])
    return (
        // <div>
        <Modal
            open={open}
            onClose={() => {
                setOpen(false)
                onClose()
            }}
            className="add-card extension-modal custom-modal"
        >
            <div className="custom-modal-body extention-modal-body">
                <div className="custom-modal-content privacy-modal">
                    <div className={`close-modal-button`}>
                        <CloseIcon
                            onClick={() => {
                                setOpen(false)
                                onClose()
                            }}
                        />
                    </div>
                    <>
                        <br />
                        <br />
                        <h3>{t("your-session-is-about-to-expire")}</h3>
                        <h4 className="Color-class-mango "> {message}</h4>
                        <h2>
                            {" "}
                            {t("want-to-ask")} {teacherName} {t("to-extend-this")}
                        </h2>
                        <br />
                        <div className="Center-Buttonst ">
                            {availableExtensions.map((extension) => (
                                <span className="extensionButtonSpacing">
                                    <Button
                                        className="buttonwidth"
                                        clickAction={(): void => {
                                            onChoose(extension)
                                            setOpen(false)
                                        }}
                                        title={`+ ${extension} min`}
                                    ></Button>
                                </span>
                            ))}
                        </div>
                        <br />
                        <Button
                            className="button"
                            clickAction={() => {
                                setOpen(false)
                                onClose()
                            }}
                            title=" No, Thanks!"
                            isOutlineStyle
                        ></Button>
                        <br />
                        <br />
                        <p>{t("tutor-hourly-rate")}</p>
                        <br />
                        <br />
                    </>
                </div>
            </div>
        </Modal>
    )
}
