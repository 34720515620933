import React from "react"
import { SERVER_URL } from "../../../utils/constant/api/api-config"
import {
    DateFormats,
    formatDate,
} from "../../../utils/helper/date-utils"
import { Avatar, Grid } from "@material-ui/core"
import Button from "../../button/Button"
import useSessionAndZoom from "utils/hooks/useSessionAndZoom";


type relativeTime = "past" | "present" | "future"

interface Iprops {
    id: string
    teacherHourlyRate: number
    studentName: string
    bookingRequestThreadObjectId: string
    bookingRequestSlotId: string
    teacherId: string
    creationTime: object
    roomId: string
    studentId: string
    sessionLength: number
    startTime: string
    teacherName: string
    modifiedTime: object
    status: number
    bookingId: string
    sessionType: string
    teacherGroupSessionRate: number
    subjects: any[]
    navigatetoEpicboard: Function
    extensionTime?: number
}

const StudentDetailCard = ({
    id: sessionId,
    studentId,
    studentName,
    teacherName,
    teacherId,
    startTime,
    sessionLength,
    extensionTime,
    subjects,
    bookingId,
}: Iprops): JSX.Element => {

    const {
        setDataAndEnterZoom,
        loading,
        isJoinEnabled
    } = useSessionAndZoom({
        teacherName,
        studentName,
        startTime,
        sessionLength,
        extensionTime,
        sessionId,
        bookingId,
        teacherId,
        studentId
    })
    const EnterZoom = () => {
        return setDataAndEnterZoom().catch(console.error);
    }

    return (
        <Grid container className="student-detail-card-container">
            <Grid item xs={1} className="columns" style={{ margin: "auto" }}>
                <div className="student-card-profile-pic">
                    <Avatar src={`${SERVER_URL}/userImage?userId=${studentId}`} className="avatar" />
                </div>
            </Grid>

            <Grid item md={4} xs={11} className="columns" style={{ margin: "auto" }}>
                <div className="student-name-subject">
                    <span className="session-student-name">{studentName}</span>
                    <br />{" "}
                    <span className="session-subject" style={{paddingRight:"20px",display:"block"}}>
                            {subjects?.map((subject: any) => (
                                <span >{subjects.length === 1? subject:subject+", "}</span>
                            ))}
                    </span>
                </div>
            </Grid>
            <Grid item md={4} xs={7} className="columns" style={{ margin: "auto" }}>
                <p className="session-label">{"Upcoming Session"}</p>
                {!isJoinEnabled ? (
                    <div className="student-session-datetime">{`${formatDate(
                        startTime,
                        DateFormats.LONG_DATE,
                    )} at ${formatDate(startTime, DateFormats.TIME_IN_MERIDIAN)}`}</div>
                ) : (
                        <div className="current-session-label">Now!</div>
                    )}
            </Grid>
            <Grid item md={3} xs={5} className="columns" style={{ margin: "auto" }}>
                <div className="student-card-right-col-button">
                    <Button
                        title="Join Now"
                        clickAction={() => EnterZoom()}
                        isloading={loading}
                        isDisabled={!isJoinEnabled}
                    />
                </div>
            </Grid>
        </Grid>

    )
}

export default StudentDetailCard
