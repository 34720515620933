import React, { useEffect, useContext } from "react"
import TutorImageBlock from "./ui-elements/TutorImageBlock"
import TitleLabel from "../../../components/title-label/TitleLabel"
import DescriptionLabel from "../../../components/description-label/DescriptionLabel"
import AvailabilityBlock from "./ui-elements/AvailabilityBlock"
import PersonalInfo from "./ui-elements/PersonalInfo"
import EducationBackground from "./ui-elements/EducationBackground"
import OtherDetails from "./ui-elements/OtherDetails"
import RatingBlock from "./ui-elements/RatingBlock"
import { navigatetoUrl } from "../../../utils/helper/helper"
import { TutorSearchStoreContext, selectedTutorSelector } from "../../../store/tutor-search/store"
import { GenderTypes } from "../../../store/types/tutorSearchTypes"
import { useDispatch, useSelector } from "react-redux"
import { setSubject, setSessionRequest, setActiveSlot } from "../../../store/actions/BookingActions"

import subjectsList from "../../../utils/constant/subjectsList" //tutoring
import counsellingList from "../../../utils/constant/counsellingList" //advising
import standardizedTestList from "../../../utils/constant/standardizedTestList"

import { SubjectType } from "../../../store/types/tutorSearchTypes"
import BookingCalendarSvgIcon from "../../../assets/images/svgIcons/BookingCalendarSvgIcon"
import colors from "../../../assets/colors"
import StarSvgIcon from "../../../assets/images/svgIcons/StarSvgIcon"
import { toggleFavorites } from "../../../store/actions/userActions"
import { concat } from "lodash"
import { useState } from "react"
import { useHistory } from "react-router-dom"
// import useAvgRating from "../../../utils/hooks/useAvgRating"

interface Props {
    profileView: Function
}

const extractSubjectsAndTests = (
    ids: string[],
): { subjects: SubjectType[]; tests: SubjectType[]; advisingTests: SubjectType[] } => {
    const subjects = subjectsList.filter((subjectItem: any) => ids.includes(subjectItem.id))
    const advisingTests = counsellingList.filter((testItem: any) => ids.includes(testItem.id))
    const tests = standardizedTestList.filter((testItem: any) => ids.includes(testItem.id))
    return { subjects, tests, advisingTests }
}
const TutorProfilePreview = ({profileView} :Props) => {
    const [InnerWidth ,setInnerWidth]=useState(window.innerWidth)
    const history =useHistory();
    const selectedSubject = useSelector((st: any) => st.booking.options.subject)
    const dispatch = useDispatch()
    const { userAuthData } = useSelector((state: any) => state.auth)
    const tutorDetails = userAuthData
    const allSubjectIds = tutorDetails.subjects
    const { subjects, tests, advisingTests } = extractSubjectsAndTests(allSubjectIds)
    extractSubjectsAndTests(allSubjectIds)
    useEffect(() => {
        const tutorSubjects = concat(subjects, tests, advisingTests)
        const newSubjectList = selectedSubject ? selectedSubject.filter((subject: any) => {
            return tutorSubjects.find((tutorSubject: any) => {
                if (tutorSubject.id === subject.id) {
                    return true
                }
            })
        }) : [];
        dispatch(setSubject({ subject: newSubjectList }))
        const tutorDetails = userAuthData
        if (tutorDetails.userType === 'STUDENT') {
            navigatetoUrl(history, "/panel/account/")
        }
    }, [])

    useEffect(() => {
        dispatch(setSessionRequest({ sessionRequests: [] }))
        dispatch(setActiveSlot({ activeSlots: [] }))
    })



    return userAuthData.userType === 'STUDENT' ? (
        <div>Redirecting...</div>
    ) : (
        <div className="row tutor-profile">
            <div className="col-md-5 col-sm-12 profile-left-block">
                <div>
                    <TutorImageBlock
                        firstName={userAuthData.firstName}
                        lastName={userAuthData.lastName}
                        imageURL={userAuthData.profilePic.downloadURL}
                        rating={userAuthData.avgRating}
                        profileView={profileView}
                    />
                    <div className="time-schedule">
                        {userAuthData.teacherType === "TUTORING_AND_ADVISING" && (
                            <>
                                <TitleLabel title="Subjects" />
                                <div className="margin-b-54">
                                    <DescriptionLabel
                                        description={
                                            subjects.length !== 0
                                                ? subjects.map((subject) => subject.name).join(", ")
                                                : "N/A"
                                        }
                                    />
                                </div>
                                <TitleLabel title="Standardized Tests" />
                                <div className="margin-b-54">
                                    <DescriptionLabel
                                        description={
                                            tests.length !== 0
                                                ? tests.map((test) => test.name).join(", ")
                                                : "N/A"
                                        }
                                    />
                                </div>
                                <TitleLabel title="Advising Specialities" />
                                <div className="margin-b-54">
                                    <DescriptionLabel
                                        description={
                                            advisingTests.length !== 0
                                                ? advisingTests
                                                    .map((subject) => subject.name)
                                                    .join(", ")
                                                : "N/A"
                                        }
                                    />
                                </div>
                            </>
                        )}
                        {userAuthData.teacherType === "TUTORING" && (
                            <>
                                <TitleLabel title="Subjects" />
                                <div className="margin-b-54">
                                    <DescriptionLabel
                                        description={
                                            subjects.length !== 0
                                                ? subjects.map((subject) => subject.name).join(", ")
                                                : "N/A"
                                        }
                                    />
                                </div>
                                <TitleLabel title="Standardized Tests" />
                                <div className="margin-b-54">
                                    <DescriptionLabel
                                        description={
                                            tests.length !== 0
                                                ? tests.map((test) => test.name).join(", ")
                                                : "N/A"
                                        }
                                    />
                                </div>
                            </>
                        )}
                        {userAuthData.teacherType === "ADVISING" && (
                            <>
                                <TitleLabel title="Advising Specialities" />
                                <div className="margin-b-54">
                                    <DescriptionLabel
                                        description={
                                            advisingTests.length !== 0
                                                ? advisingTests
                                                    .map((subject) => subject.name)
                                                    .join(", ")
                                                : "N/A"
                                        }
                                    />
                                </div>
                            </>
                        )}

                        <div className="margin-b-17">
                            <TitleLabel title="Availability" />
                        </div>
                        <AvailabilityBlock
                            availabilityData={
                                userAuthData.teacherWeeklyAvailability
                            }
                        />
                    </div>
                </div>
            </div>
            {/* <div className="col-md-6"  style ={{maxWidth :'55%'}}> */}
            <div className="col-md-7 col-sm-12">
                <div className="profile-detail">
                    {/* <button
                        type="button"
                        className="orange-button btn btn-outline-danger"
                        onClick={() => {
                            const oldFavorites = [...userAuthData.favorites]
                            const updatedFavorites = oldFavorites.includes(
                                selectedTutorSelector(state).userId,
                            )
                                ? oldFavorites.filter(
                                    (id) => id !== selectedTutorSelector(state).userId,
                                )
                                : [...oldFavorites, selectedTutorSelector(state).userId]
                            dispatch(toggleFavorites({ payload: updatedFavorites }))
                        }}
                    > */}
                        {/* <div className="favorites-button-content">
                            <StarSvgIcon width="30px" height="30px" iconColor={colors.mango} />
                            <div style={{ paddingTop: "2px" }}>
                                {userAuthData.favorites?.includes(
                                    selectedTutorSelector(state).userId,
                                )
                                    ? "Remove from Favorites"
                                    : "Add to Favorites"}
                            </div>
                        </div> */}
                    {/* </button> */}
                    <div className="about">
                        <div className="margin-b-10">
                            <TitleLabel title="About" />
                        </div>
                        <DescriptionLabel
                            description={userAuthData.teacherBiography}
                        />
                    </div>
                    <PersonalInfo
                        gender={GenderTypes[userAuthData.gender]}
                        hoursTutoring={userAuthData.minutesTutoring}
                        rate={userAuthData.oneToOneSessionRate}
                    />
                    <EducationBackground data={userAuthData.teacherSchoolDetails} />
                    <OtherDetails
                        data={userAuthData.teacherSchoolDetails}
                        education={userAuthData.teacherEducationQualification.name}
                        certification={
                            userAuthData.teacherCertificateDetails.certificationName
                        }
                        cancellationNotice={
                            userAuthData.freeCancellationBeforeHours
                        }
                    />

                    <RatingBlock />
                </div>
            </div>
        </div>
    )
}
export default TutorProfilePreview
