

export enum Genders {
    ALL = "All",
    MALE = "Male",
    FEMALE = "Female",
    NON_BINARY = "Non-Binary",
    RATHER_NOT_SAY = "Rather not say",
}

export const GenderOptions = [
    { id: "ALL", name: "All" },
    { id: "MALE", name: "Male" },
    { id: "FEMALE", name: "Female" },
    { id: "RATHER_NOT_SAY", name: "Rather Not Say" },
    { id: "OTHER", name: "Other" },
]

const genderList = GenderOptions

export type GenderFieldType = keyof typeof Genders

export default genderList
