const colors = {
    blackSolid: "#000000",
    whiteSolid: "#FFFFFF",
    curiousBlue: "#2590BF",
    seashellSolid: "#F1F1F1",
    silver: "#CCCCCC",
    orange: "#f28334",
    chambray: "#3B5998",
    punch: "#DD4B39",
    twitterColor: "#1DA1F2",
    mineShaft: "#262626",
    monza: "#B00020",
    vividBlue: "#262ffa",
    blue: "#262FFA",
    dodgerBlue: "#3ebfff",
    mango: "#f9a722",
    darkMango: "#f28334",
    darkGrey: "#2d3234",
    battleshipGrey: "#686c80",
    paleGrey: "#dddee8",
    white: "#f6f6f6",
    lightWhite:"#f6f6f6",
    coral: "#ef5350",
    macaroniAndCheese: "#f1be43",
    greenishTeal: "#36d174",
    lightUrple: "#a577ed",
    paleGreyTwo: "#f3f5fb",
    paleGreyThree: "#efeff3",
    blackRussianOp: "#0000000d",
    cancelRed:'#ff5924'
}
export default colors
