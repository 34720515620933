import React, { useState } from "react"
import Modal from "../../../../components/modal_/Modal"

import "./submit-success-modal.scss"
import HelpPageModalSvgIcon from "../../../../assets/images/svgIcons/HelpPageModalSvgIcon"
interface Props {
    onExitAction: any
    show: boolean
}

const SubmitSuccessModal = (props: Props): JSX.Element => {
    const { onExitAction, show } = props
    return (
        <Modal onExit={onExitAction} visible={show}>
            <div className="modal-container">
                <HelpPageModalSvgIcon height={"100px"} width={"100px"} />
                <div className="header">Help request submitted.</div>
                <div className="sub-header">We will get back to you as soon as possible</div>
            </div>
        </Modal>
    )
}

export default SubmitSuccessModal
