import React, { useState, useEffect } from "react"
import { refType } from "./LessonContent"
import { Grid, Container } from "@material-ui/core"
import LessonHeader from "./LessonHeader"
import LessonDescription from "./LessonDescription"
import References from "./References"
import LessonNavigation from "./LessonNavigation"
import OneOnOneSession from "./OneOnOneSession"
import LessonExitModal from "./LessonExitModal"
import { useHistory } from "react-router-dom"
import { navigatetoUrl } from "../../utils/helper/helper"


interface moduleType {
    title: string
    content: string
    listContentTitle?: string
    listContent?: string
    tip?: string
    otherLessons: refType[]
    typeform?: string
}

const ModuleContent = ({ modules, moduleId, lessonId }: { modules: any, moduleId: any, lessonId: any }): JSX.Element => {
    const [module, setModule] = useState<moduleType>()
    const [isExiting, setExiting] = useState<boolean>(false)
    const [currentIndex, setCurrentIndex] = useState<any>(0)
    let history = useHistory()
    useEffect(() => {
        const findModule = (element: any) => element.sys.id == moduleId
        let moduleIndex = modules.findIndex(findModule)
        if (moduleIndex != -1) {
            setModule(modules[moduleIndex].fields)
        }
        setCurrentIndex(moduleIndex)
    }, [moduleId])

    useEffect(() => {
        document.body.scrollTo(0, 0);
    }, [])


    const previousModule = () => {
        if (currentIndex > 0) {
            navigateToModule(currentIndex - 1)
        }
    }
    const nextModule = () => {
        if (currentIndex < modules.length - 1) {
            navigateToModule(currentIndex + 1)
        }
    }
    const navigateToModule = (newIndex: any) => {
        setModule(modules[newIndex].fields)
        setCurrentIndex(newIndex)
        history.push("/panel/lessons/" + lessonId + "/module/" + modules[newIndex].sys.id)
        document.body.scrollTo(0, 0);
    }
    return (
        <>
            <LessonExitModal isExiting={isExiting} setExiting={setExiting} />
            <Grid container className="lesson-flexible">
                <Container maxWidth={false} className='lesson-header'>
                    <LessonHeader lessonTitle={module?.title} setExiting={setExiting} />
                </Container>
                <Grid container className="lesson-content">
                    <Grid item xl={1} lg={1} md={1} sm={12} xs={12}></Grid>
                    <Grid item xl={10} lg={10} md={10} sm={12} xs={12} className="main-content">
                        <LessonDescription description={module?.content} />
                        <p className="heading">{module?.listContentTitle}</p>
                        <LessonDescription description={module?.listContent} />
                        <References otherLessons={module?.otherLessons} moduleId={moduleId} lessonId={lessonId} />
                    </Grid>
                    <Grid item xl={1} lg={1} md={1} sm={12} xs={12}></Grid>
                </Grid>
                <OneOnOneSession />
                <Grid container className="lesson-navigation">
                    <Grid item xl={1} lg={1} md={1} sm={12} xs={12}></Grid>
                    <Grid item xl={10} lg={10} md={10} sm={12} xs={12} className="main-content">
                        <LessonNavigation total={modules.length} index={currentIndex} previous={previousModule} next={nextModule} page="module" />
                    </Grid>
                    <Grid item xl={1} lg={1} md={1} sm={12} xs={12}></Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default ModuleContent
