import React, { useState, useEffect } from "react"
import OnboardingBackground from "../../../components/on-boarding/onboarding-background/OnboardingBackground"

import { InputBox } from "../../../components/input-field/InputFields"
import { getFile } from "../../../utils/helper/helper"
import ButtonUpdated from "../../../components/button/ButtonUpdated"
import SubmitSuccessModal from "./ui-elements/SubmitSuccessModal"
import { HelpTopics } from "../../../utils/constant/helpTopics"
import { useFormik } from "formik"
import * as Yup from "yup"
import "./help-page.scss"
import SimpleDropdown from "../../../components/dropdown/SimpleDropdown"
import { useMediaQuery } from "react-responsive"
import { sendEmailToAdmin } from "utils/services/userServices"
import { useSelector } from "react-redux"

const HelpPage = (): JSX.Element => {
    const isDesktopOrLaptop=useMediaQuery({query: '(min-device-width: 1224px)'})
    const { userAuthData } = useSelector((state: any) => state.auth)
    const [supportFormSubmittedSuccessModal, setSupportModalSuccessVisibility] = useState(false)
    const [errorFile, setFileError] = useState(false)
    const [supportSubject, setSupportSubject] = useState({
        error: "Required",
        value: { name: "", id: "" },
    })
    const [attachmentFile, setAttachmentFile] = useState<{ files: any[]; names: string[] }>({
        files: [],
        names: [],
    })
    const [formValid, setFormValid] = useState(true)


    const handleSubmitSupportReport = (formikValues: any): void => {
        // attachmentFile.name === "" ? setFileError(true) : setFileError(false)
        supportSubject.value.id === "" ? setFormValid(false) : setFormValid(true)
        formikValues.submitForm()
    }

    const handleAttachmentFile = (files: any[]) => {
        if (files.some((file) => file === null || file.size > 10000000)) {
            setFileError(true)
            return
        }
        try {
            const allFiles = files.map((file) => file)
            const fileNames = files.map((file) => file.name)
            setAttachmentFile({ files: allFiles, names: fileNames })
            setFileError(false)
        } catch (error) {
            console.log(error)
        }
    }

    const formik = useFormik({
        initialValues: {
            supportSubject: null,
            attachmentFile: null,
            Message: '',
        },
        validationSchema: Yup.object({
            supportSubject: Yup.string().nullable().required("Required"),
            Message: Yup.string().nullable().min(100).required("Required"),
        }),
        onSubmit: async () => {
            const formValid = true
            setSupportModalSuccessVisibility(true)
            const messageNow =
                '<h1> Hi Admin, </h1>'+'<p>'+formik.values.Message+ '</p>' +'<h2> Regards,</h2>'+ '<h3>'+userAuthData.firstName + ' ' + userAuthData.lastName+ '</h3>' +  '<h3>'+userAuthData.email+'</h3>'+'<h3>'+'User type : '+userAuthData.userType +'</h3>'
            
            await sendEmailToAdmin(supportSubject.value.name,messageNow);

        },
    })

    useEffect(() => {
        supportSubject.value.name !== "" &&
            formik.setFieldValue("supportSubject", supportSubject, true)
    }, [supportSubject])

    const handleChange = (e: any) => {
        const { name, value } = e.target

        switch (name) {
            case "Message":
                formik.setFieldValue("Message", value, true)
                break
        }
    }
    return (
        <div className="help-page">
            <SubmitSuccessModal
                onExitAction={(): void => setSupportModalSuccessVisibility(false)}
                show={supportFormSubmittedSuccessModal}
            />
            <OnboardingBackground />
            <div className="page-header">How can we help?</div>
            <div className="input-container">
                <SimpleDropdown
                    title={"Subject"}
                    placeholder="Subject"
                    optionsList={HelpTopics}
                    fieldDetails={supportSubject}
                    setProps={setSupportSubject}
                    isShowError={!formValid}
                />
            </div>
            <div className="input-container message">
                <InputBox
                    type={"textarea"}
                    textAreaRows={"7"}
                    title={"Message"}
                    placeholder={"Type your message"}
                    inputProps={{
                        name: "Message",
                        value: formik.values.Message,
                        onChange: handleChange,
                        onBlur: formik.handleBlur,
                    }}
                    error={
                        formik.touched.Message && formik.errors.Message
                            ? formik.errors.Message
                            : null
                    }
                />
            </div>
            {/* <div className="wrap">
                <label className="input-label">Attachments</label>
                <label
                    className={`form-file-picker ${errorFile && "error-input-file"}`}
                    htmlFor="upload_button"
                >
                    <span> Add file</span>

                    <input
                        type="file"
                        multiple
                        id="upload_button"
                        accept=".doc,.docx,.pdf,.jpg,.png,.mp4,.avi"
                        value=""
                        onChange={(e: any) => handleAttachmentFile(Object.values(e.target.files))}
                    />
                </label>
                {attachmentFile.files.length > 0 && (
                    <div className="info-file">
                        <span>
                            {attachmentFile.files.length > 0
                                ? attachmentFile.names.join(", ")
                                : null}
                        </span>
                        <span className="navigation" onClick={(): void => handleAttachmentFile([])}>
                            Delete File
                        </span>
                    </div>
                )}
            </div> */}
            <div className="submit-button">
                <ButtonUpdated
                    title="Submit"
                    textStyle={ isDesktopOrLaptop ? { fontSize: "1.2vw"}: {fontSize:"14px"}}
                    buttonStyle={{ height: "100%", width: "100%" }}
                    clickAction={(): void => handleSubmitSupportReport(formik)}
                />
            </div>
        </div>
    )
}

export default HelpPage
