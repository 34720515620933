import React, { memo, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useHistory } from "react-router-dom"
import { Switch } from "react-router-dom"
import DashboardNav from "../../../../components/dashboard/DashboardNav"
import NoResultsCard from "../../../../components/dashboard/NoResultsCard"
import SessionDetailCard from "../../../../components/dashboard/detail-cards/SessionDetailCard"
import HorizontalScrollContainer from "../../../../components/dashboard/HorizontalScrollContainer"
import { orderSessionsByDate } from "../../../../utils/helper/session-utils"
import { getUpcomingBookings } from "../../../../utils/services/sessionServices"
import InterfaceConstant from "../../../../utils/constant/interfaceConstant"
import { applicationConstant } from "../../../../utils/constant/applicationConstant"
import { navigatetoUrl } from "../../../../utils/helper/helper"
import LoaderSpinner from "../../../../components/loader/LoaderSpinner"
import { getStudentUpcomingSessions } from "store/actions/studentDashboardActions";
import NaviagationCard from "../components/NaviagationCard"
import { Grid } from "@material-ui/core"
import DashboardCalendar from "./dashboard-calendar"
import NoSessionCard from "../components/NoSessionCard"
import BackArrowSvgIcon from "assets/images/svgIcons/BackArrowSvgIcon"
import colors from "assets/colors"
import LessonCard from "../components/LessonCard"


const TutorAdvisorIcon = ({ tutors, advisors }: { tutors: any, advisors: any }): JSX.Element => {
    let teachers = tutors.concat(advisors)
    return (
        <>
            {teachers[0] && <div className="round-div float-left" style={{ backgroundColor: "#4274fa" }}>{teachers[0].teacherName[0]}</div>}
            {teachers[1] && <div className="round-div float-left" style={{ backgroundColor: "#40befc", right: '10px' }}>{teachers[1].teacherName[0]}</div>}
            {teachers[2] && <div className="round-div float-left" style={{ backgroundColor: "#a577ed", right: '20px' }}>{teachers[2].teacherName[0]}</div>}
        </>
    )
}

const SessionView = (): JSX.Element => {
    const history = useHistory()
    const userId = useSelector((state: any) => state?.auth?.userAuthData?.userId);
    const routeUrl: InterfaceConstant.IrouteUrl = applicationConstant.routeUrl
    const dashboardLabel: InterfaceConstant.IdashboardLabel = applicationConstant.dashboardLabel
    const advisors = useSelector((state: any) => state.studentDashboard.advisors.data);
    const advisorsLoading = useSelector((state: any) => state.studentDashboard.advisors.isLoading);
    const tutors = useSelector((state: any) => state.studentDashboard.tutors.data);
    const tutorsLoading = useSelector((state: any) => state.studentDashboard.tutors.isLoading);
    const sessions = useSelector((state: any) => state.studentDashboard.upcomingSessions.data);
    const localLoading = useSelector((state: any) => state.studentDashboard.upcomingSessions.isLoading);;
    const dataOk = !useSelector((state: any) => state.studentDashboard.upcomingSessions.isLoading);
    const dispatch = useDispatch();

    useEffect(() => {
        userId && dispatch(getStudentUpcomingSessions({ userId }))
    }, [userId]);

    const navigatetoEpicboard = () => {

        navigatetoUrl(history, routeUrl.EPIC_BOARD_PAGE)
    }
    return (
        <>
            <div className="lg-screen">
                <Grid container>
                    <Grid item xl={4} lg={4} md={6} sm={12} xs={12} className="dashboard-left-panel">
                        <DashboardCalendar />
                    </Grid>
                    <Grid
                        item
                        id="right-col"
                        xl={8} lg={8} md={6} sm={12} xs={12}
                        className=""
                    >
                        <div>
                            {sessions.length > 0 && (
                                <>
                                    <p className="upcoming-title">Upcoming Sessions</p>
                                    <HorizontalScrollContainer
                                        height={245}
                                        scrollAmount={341}
                                    >
                                        {sessions.map((session: any) => (
                                            <SessionDetailCard
                                                {...session}
                                                navigatetoEpicboard={navigatetoEpicboard}
                                            />
                                        ))}
                                    </HorizontalScrollContainer>
                                </>
                            )}

                            {localLoading && (
                                <div className="text-center mt-5">
                                    <LoaderSpinner />
                                </div>
                            )}

                            {dataOk && sessions.length === 0 && (
                                <NoSessionCard
                                    titleText={dashboardLabel.NO_UPCOMING_CARD_TITLE}
                                    subtitleText={dashboardLabel.NO_TUTORS_CARD_SUBTITLE_2}
                                    buttonText={dashboardLabel.NO_TUTORS_CARD_BUTTON_TEXT}
                                    buttonAction={() => navigatetoUrl(history, "/panel/search")}
                                />
                            )}
                        </div>
                        <Grid container className="tutors-advisor-container">
                            <Grid item xl={6} lg={6} className="card-container">
                                {!tutorsLoading && <NaviagationCard 
                                title={tutors.length === 0 ? "Book a Tutor" : "My Tutors"} 
                                subtitle={tutors.length === 0 ? "You have no Tutors yet but there are 1000+ available!" : tutors.length === 1 ?  `You have ${tutors.length} Tutor!`:  `You have ${tutors.length} Tutors!`} 
                                noResult={tutors.length}
                                noTutors={tutors.length===0}
                                oneTutor={tutors.length===1}
                                url={tutors.length === 0 ? "/panel/search/" : "/panel/dashboard/tutors"} 
                                buttonText={tutors.length === 0 ? "Search and Book" : "Go to My Tutors"} type="tutors" />}
                            </Grid>
                            <Grid item xl={6} lg={6} className="card-container">
                                {!advisorsLoading && <NaviagationCard 
                                title={advisors.length === 0 ? "Book an Advisor" : "My Advisors"} 
                                subtitle={advisors.length === 0 ? "You have no Advisors yet but there are 1000+ available!" : advisors.length===1 ? `You have ${advisors.length} Advisor!`: `You have ${advisors.length} Advisors!`} 
                                noResult={advisors.length}
                                noAdvisors={advisors.length===0}
                                oneAdvisor={advisors.length===1}
                                url={advisors.length === 0 ?"/panel/search":"/panel/dashboard/advisors"} 
                                buttonText={advisors.length === 0 ? "Search and Book" : "Go to My Advisors"} type="advisors" />}
                            </Grid>
                        </Grid>
                        <LessonCard />
                    </Grid>
                </Grid>
            </div>
            <div className="md-screen">
                <div>
                    <div className="display-inline-flex">
                        <p className="title">Upcoming Sessions</p>
                    </div>
                    <span className="back-arrow display-inline-flex" onClick={() => navigatetoUrl(history, "/panel/dashboard")}>
                        <BackArrowSvgIcon iconColor={colors.darkMango} width="32" height="32" />
                    </span>
                </div>

                <div className="total-count">
                    <p>Total: {sessions.length}</p>
                </div>
                {sessions.map((session: any) => (
                    <SessionDetailCard
                        {...session}
                        navigatetoEpicboard={navigatetoEpicboard}
                    />
                ))}
            </div>

        </>
    )
}

export default memo(SessionView)
