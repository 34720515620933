import React, { memo, useCallback } from "react"
import Grid from "@material-ui/core/Grid"
import RatingAppleIcon from "../rating-apple-icon/RatingAppleIcon"
import { CardView } from "../card/CardView"
import { navigatetoUrl, returnIndex } from "../../utils/helper/helper"
import RatingsComponent from "../ratings/RatingsComponent"
import { useSelector } from "react-redux"
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { useHistory } from "react-router-dom"
import colors from "assets/colors"
import {
    DateFormats,
    formatDate,
    isBetweenInterval,
    isToday,
} from "../../utils/helper/date-utils"


type studentDataType = {
    firstName: string
    lastName: string
    profilePicUrl: string
    sessionDatetime: string
    reltime: string
}[]

interface Props {
    pendingRequests: number
    totalMinutesWorked: number
    totalEarning: number
    nextPeriodEarning: number
    currentPeriodEarning: number
    studentData: studentDataType
    upcomigSessionCount: number
    nextSession: any
    totalReviews: number
}

const DashIndicator = ({
    pendingRequests,
    totalMinutesWorked,
    totalEarning,
    nextPeriodEarning,
    currentPeriodEarning,
    totalReviews,
    upcomigSessionCount,
    nextSession
}: Props): JSX.Element => {
    const { userAuthData } = useSelector((state: any) => state.auth)
    const history = useHistory();
    const getHoursTutoring = useCallback((): number => {
        if (!totalMinutesWorked) return 0
        return totalMinutesWorked / 60;
    }, [totalMinutesWorked])
    const getTotalEarningInDollar = useCallback((): number => {
        if (!totalEarning) return 0
        return totalEarning / 100;
    }, [totalEarning]);
    const getNextPeriodEarningInDollar = useCallback((): number => {
        if (!nextPeriodEarning) return 0
        return nextPeriodEarning / 100;
    }, [nextPeriodEarning]);
    const getCurrentPeriodEarningInDollar = useCallback((): number => {
        if (!currentPeriodEarning) return 0
        return currentPeriodEarning / 100;
    }, [currentPeriodEarning]);

    return (
        <Grid container>
            <Grid item lg={4} md={12} sm={6} xs={6} className="upcoming-sessions">
                <CardView style={{ position: "relative", marginRight: "10px" }}>
                    <p className="tutor-detail-card-head">Upcoming Sessions</p>
                    <div className="tutor-pending-requests-container">
                        <p >{nextSession ? <span className="request-counter">{upcomigSessionCount}</span> : <span style={{fontSize:"14px"}}>You have no sessions yet</span>}</p>
                    </div>
                    <div className="bottom-content">
                        <div className="nav-footer">
                            <div className="display-flex session-detail">
                                <div className="inline-block session-detail-text-container">
                                   {nextSession?
                                    <div>
                                         <p className="session-detail-text" style={{
                                        color: colors.battleshipGrey,
                                        marginBottom: "0px"
                                    }}>Next Session</p>
                                    <p className="session-detail-text" style={{
                                        color: colors.darkMango,
                                        marginBottom: "0px",
                                        maxWidth: "90%"
                                    }}>{new Date().toISOString() < nextSession ? `${formatDate(
                                        nextSession,
                                        DateFormats.LONG_DATE,
                                    )} at ${formatDate(nextSession, DateFormats.TIME_IN_MERIDIAN)}` : nextSession  ? "Now" : ""}</p>
                                    </div>
                                    :
                                    <div></div>
                                    }
                                </div>
                            </div>
                            <div className="goto-arrow"
                                style={{
                                    paddingRight: "30px",
                                }}
                                onClick={() => {
                                    navigatetoUrl(history, "/panel/session")
                                }}><ArrowForwardIosIcon></ArrowForwardIosIcon>
                            </div>
                        </div>
                    </div>

                </CardView>
            </Grid>
            <Grid item lg={4} md={12} sm={6} xs={6}>
                <CardView style={{ position: "relative", marginRight: "10px" }}>
                    <p className="tutor-detail-card-head">Requests to accept</p>
                    <div className="tutor-pending-requests-container">
                        <p className="request-counter">{pendingRequests}</p>
                    </div>
                    <div className="goto-arrow" onClick={() => {
                        navigatetoUrl(history, "/panel/session")
                    }}><ArrowForwardIosIcon></ArrowForwardIosIcon></div>
                </CardView>
            </Grid>
            <Grid item lg={4} md={12} sm={6} xs={6}>
                <CardView style={{ position: "relative", marginLeft: "5px", marginRight: "5px" }}>
                    <p className="tutor-detail-card-head">Current Pay Period</p>
                    <p className="message-para">(completed sessions to date)</p>
                    <div className="tutor-earning-container">
                        <p className="request-counter-top-align overflow-auto" >${Math.round(getCurrentPeriodEarningInDollar()*100)/100}</p>
                    </div>
                    <p className="tutor-detail-card-head-no-margin">Previous Pay Period</p>
                    <div className="tutor-earning-container">
                        <p className="request-counter overflow-auto" >${userAuthData.lastTransaction?.amount ? userAuthData.lastTransaction?.amount / 100 : 0}</p>
                    </div>
                    <p className="tutor-detail-card-head-no-margin">Est. Earnings Next Pay Period</p>
                    <p className="message-para">(scheduled sessions to date)</p>
                    <div className="tutor-earning-container">
                        <p className="request-counter-top-align overflow-auto" >${Math.round(getNextPeriodEarningInDollar()*100)/100}</p>
                    </div>
                    <p className="TD-para">*please refer to the Terms & Conditions for more specific information</p>
                    <div className="goto-arrow" onClick={() => {
                        navigatetoUrl(history, "/panel/account#payments.paymentsDetails")
                    }}><ArrowForwardIosIcon></ArrowForwardIosIcon></div>
                </CardView>
            </Grid>
            <Grid item lg={4} md={12} sm={6} xs={6}>
                <CardView style={{ position: "relative", marginLeft: "10px" }}>
                    <p className="tutor-detail-card-head">My Rating</p>
                    <div className="tutor-rating-container">
                        <p className="tutor-dashboard-number-rating-container">
                            <span className="tutor-dashboard-number-rating">
                                {parseFloat(userAuthData.avgRating)}
                            </span>
                            / 5
                        </p>
                        <p className="tutor-dashboard-rating-text-counter">
                            {totalReviews} reviews
                        </p>
                        <div className="tutor-dashboard-apple-rating">
                            <RatingsComponent
                                obtainedRating={parseFloat(userAuthData.avgRating)}
                                iconSize={"25px"}
                                fillColor={colors.darkMango}
                            />
                        </div>
                    </div>
                </CardView>
            </Grid>
        </Grid>
    )
}
export default memo(DashIndicator)
