import React, { Fragment, useState } from "react"
import { timestampToTimeString } from "../../utils/helper/date-utils"
import ChatModalView from "./ChatModalView"
import ClipLoader from "react-spinners/ClipLoader"
import { urlify } from "../../utils/helper/helper"
import DefaultAvatar from "../../assets/images/default-avatar.png"
import colors from "assets/colors"
interface Iprops {
    isSender: boolean
    messageObject: any
}

const MessageBlock = ({ isSender, messageObject }: Iprops) => {
    const [openModal, setOpenModal] = useState(false)
    const [modalUrl, setModalUrl] = useState("")

    const renderTextMessage = (textMessage: string) => {
        return (
            <div
                className="message"
                dangerouslySetInnerHTML={{ __html: urlify(textMessage) }}
            ></div>
        )
    }

    const openModalView = (openFlag: boolean, imageMessageUrl: string) => {
        setOpenModal(openFlag)
        setModalUrl(imageMessageUrl)
    }

    const renderImageMessage = (imageMessageUrl: string, isUploading: any) => {
        return (
            <div
                style={{ height: "15vh", width: "15vh", cursor: "pointer" }}
                onClick={() => {
                    openModalView(true, imageMessageUrl)
                }}
            >
                {isUploading ? (
                    <div className={"image-loader-container"}>
                        <div className="image-loader">
                            <ClipLoader color={colors.darkMango} />
                        </div>
                    </div>
                ) : null}
                <img
                    src={imageMessageUrl}
                    style={{
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        height: "100%",
                        width: "100%",
                    }}
                />
            </div>
        )
    }

    const returnMessage = (currentMessageObject: any) => {
        if (currentMessageObject.image && currentMessageObject.text) {
            return (
                <Fragment>
                    {renderTextMessage && renderTextMessage(currentMessageObject.text)}
                    {renderImageMessage &&
                        renderImageMessage(
                            currentMessageObject.image,
                            currentMessageObject.isUploading,
                        )}
                </Fragment>
            )
        } else if (currentMessageObject.image) {
            return (
                renderImageMessage &&
                renderImageMessage(currentMessageObject.image, currentMessageObject.isUploading)
            )
        } else {
            return renderTextMessage && renderTextMessage(currentMessageObject.text)
        }
    }

    return (
        <Fragment>
            <div>
                <div
                    {...messageObject}
                    className={`chat-block ${isSender ? "send-chat-block" : ""}`}
                >
                    <div className="icon-block">
                        <div className="icon">
                            <img
                                className="user-image"
                                src={(messageObject.currentMessage?.user.avatar)? messageObject.currentMessage.user.avatar:DefaultAvatar }
                                alt=""
                            ></img>
                        </div>
                        <div className="user-detail">
                            <div className="user-name">
                                {isSender ? "You" : messageObject.currentMessage?.user.name}
                            </div>
                            <div className="time">
                                {timestampToTimeString(messageObject.currentMessage?.timestamp)}
                            </div>
                        </div>
                    </div>
                    {returnMessage && returnMessage(messageObject.currentMessage)}
                </div>
            </div>
            <ChatModalView openModal={openModal} url={modalUrl} openModalView={openModalView} />
        </Fragment>
    )
}

export default MessageBlock
