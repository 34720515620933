import { put, all, takeLeading } from "redux-saga/effects"
import { showToast } from "../actions/toastAction"
import { getConnectedPeople, getSession } from "utils/helper/firebase";
import { getUpcomingBookings } from "utils/services/sessionServices"
import { EPICBOARD_SESSION_STATUS_CODES, StudentDashboardConstants } from "../constant";
import { applicationConstant } from "utils/constant/applicationConstant";
import { setStudentAdvisors, setStudentTutors, setStudentUpcomingSessions } from "store/actions/studentDashboardActions";
import { orderAndFilterUpcomingSessions, orderSessionsByDate } from "utils/helper/session-utils"

function* getStudentUpcomingSessions(action: any) {
    try {
        yield put(setStudentUpcomingSessions({
            data: [],
            isLoading: true
        }));
        const data = yield getUpcomingBookings()
        if (data?.data?.sessions && Array.isArray(data?.data?.sessions)) {
            yield put(setStudentUpcomingSessions({
                data: orderAndFilterUpcomingSessions(data?.data?.sessions || []),
                isLoading: false
            }));
        }
    } catch (error) {
        console.log("Error while getting upcoming sessions: ", error);
        yield put(showToast({ show: true, isSuccess: false, message: error.message }))
    }
}

function* getStudentTutors(action: any) {
    try {
        yield put(setStudentTutors({
            isLoading: true
        }));
        const { userId }: { userId: string } = action.payload
        const data = yield fetchConnectedUsers(userId, "tutor");
        if (data && Array.isArray(data)) {
            yield put(setStudentTutors({
                data,
                isLoading: false
            }));

        }
    } catch (error) {
        console.log("Error while getting tutors: ", error);
        yield put(showToast({ show: true, isSuccess: false, message: error.message }))
    }
}

function* getStudentAdvisors(action: any) {
    try {
        yield put(setStudentAdvisors({
            isLoading: true
        }));
        const { userId }: { userId: string } = action.payload
        const data = yield fetchConnectedUsers(userId, "advisor");
        if (data && Array.isArray(data)) {
            yield put(setStudentAdvisors({
                data,
                isLoading: false
            }));
        }
    } catch (error) {
        console.log("Error while getting advisors: ", error);
        yield put(showToast({ show: true, isSuccess: false, message: error.message }))
    }
}

/**
 * 
 * @param userId 
 */
async function fetchConnectedUsers(userId: string, type = "tutor") {
    const teacherTypeKeys = applicationConstant.teacherTypeKeys;
    const filterTypes = type === "tutor" ? [teacherTypeKeys.TUTORING, teacherTypeKeys.TUTORING_AND_ADVISING] : [teacherTypeKeys.ADVISING, teacherTypeKeys.TUTORING_AND_ADVISING];
    return await getConnectedPeople(userId)
        .then(async (data: Array<any>) => {
            data = data.filter(t => filterTypes.includes(t.type) && t?.verified !== false);
            data = await Promise.all(data.map(async t => {
                const allSessions: Array<any> = await Promise.all(t.sessions.map(async (s: any) => {
                    return await getSession(s.sessionId);
                }));
                const lastSession = allSessions.filter((s: any) => s.status !== EPICBOARD_SESSION_STATUS_CODES.CANCELLED)
                    .sort(({ startTime: t1 }: any, { startTime: t2 }: any) => {
                        return t1 > t2 ? 1 : t1 < t2 ? -1 : 0;
                    }).pop();

                return lastSession && {
                    teacherId: t.userId,
                    teacherName: `${t.firstName} ${t.lastName}`,
                    startTime: lastSession?.startTime,
                    subjects: lastSession?.subjects
                };
            }));
            data = data.filter(d => d);
            return data;
        })
}

export default all([
    takeLeading(StudentDashboardConstants.GET_STUDENT_UPCOMING_SESSIONS, getStudentUpcomingSessions),
    takeLeading(StudentDashboardConstants.GET_STUDENT_TUTORS, getStudentTutors),
    takeLeading(StudentDashboardConstants.GET_STUDENT_ADVISORS, getStudentAdvisors),
])
