import colors from "assets/colors"
import React from "react"
export const CollegeCounselingCardIcon=()=>{
    return(
            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
                <g fill="none" fillRule="evenodd">
                    <g>
                        <g>
                            <g>
                                <path fill={colors.darkMango} d="M25 0C11.193 0 0 11.193 0 25s11.193 25 25 25 25-11.193 25-25S38.807 0 25 0z" transform="translate(-1179 -1215) translate(212 430) translate(967 785)"/>
                                <g>
                                    <g>
                                        <g>
                                            <path fill="#FFF" d="M21.228 13.286h-.482V1.898h.482c.265 0 .483-.213.483-.474s-.218-.475-.483-.475h-9.89V.475c0-.261-.217-.475-.483-.475-.265 0-.482.214-.482.475v.474H.483C.216.949 0 1.163 0 1.424c0 .26.217.474.482.474h.483v11.388H.482c-.265 0-.482.214-.482.475 0 .26.217.474.482.474h7.286l-2.196 6.501c-.072.237.049.522.314.593.048.024.096.024.145.024.193 0 .386-.119.458-.332l2.292-6.786h1.592v6.643c0 .261.217.475.482.475.266 0 .483-.214.483-.475v-6.643h1.592l2.291 6.786c.073.19.266.332.459.332.048 0 .096 0 .145-.024.24-.071.386-.356.313-.593l-2.195-6.5h7.285c.265 0 .483-.214.483-.475s-.218-.475-.483-.475z" transform="translate(-1179 -1215) translate(212 430) translate(967 785) translate(14 16) translate(.274 .27)"/>
                                            <path fill={colors.darkMango} d="M4.323 5.65c.207 0 .376.2.376.447v3.808c0 .246-.169.448-.376.448-.206 0-.376-.202-.376-.448V6.097c0-.246.17-.448.376-.448zM6.58 6.992c.207 0 .376.202.376.448v2.464c0 .246-.17.448-.376.448-.207 0-.376-.202-.376-.448V7.441c0-.246.17-.448.376-.448zM8.835 4.53c.206 0 .376.202.376.448v4.928c0 .246-.17.448-.376.448-.207 0-.376-.202-.376-.448V4.977c0-.246.169-.448.376-.448z" transform="translate(-1179 -1215) translate(212 430) translate(967 785) translate(14 16) translate(.274 .27) rotate(90 6.579 7.441)"/>
                                            <path fill="#262FFA" fillRule="nonzero" d="M20.723 10.353c-1.999 0-3.618 1.594-3.618 3.56 0 1.965 1.621 3.558 3.62 3.558 1.998-.001 3.618-1.595 3.617-3.56-.003-1.965-1.622-3.556-3.62-3.558z" transform="translate(-1179 -1215) translate(212 430) translate(967 785) translate(14 16) translate(.274 .27)"/>
                                            <path fill="#F6F6F6" fillRule="nonzero" d="M22.697 13.55l-1.98 1.874c-.07.067-.166.105-.266.105s-.197-.038-.267-.105l-1.214-1.149c-.095-.09-.132-.222-.097-.345.034-.123.136-.22.266-.252.13-.033.27.002.365.092l.947.896 1.712-1.62c.147-.14.386-.14.534 0 .147.14.147.365 0 .505z" transform="translate(-1179 -1215) translate(212 430) translate(967 785) translate(14 16) translate(.274 .27)"/>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
    )
}