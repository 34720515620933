import React, { Fragment } from "react"
import { OnBoardingHeader } from "../../../components/on-boarding/OnboardingHeader"
import { OptionCardView } from "../../../components/card/CardView"

interface Props {
    heading: string
    subHeading: string
    options: any
    onOptionPress: any
    backNavigation?: any
    bottomOptionComponent?: any
    teacherType: string | undefined
}
const TeachingOptionsComponent = ({
    heading,
    subHeading,
    options,
    onOptionPress,
    backNavigation,
    bottomOptionComponent,
    teacherType,
}: Props): JSX.Element => {
    const renderOptions = (): JSX.Element => {
        return options.map((obj: any) => (
            <OptionCardView>
                <div
                    className="content"
                    onClick={() => onOptionPress(obj.key)}
                    style={{ opacity: teacherType && obj.key !== teacherType ? "0.5" : "1" }}
                >
                    <img alt="" src={obj.icon}></img>
                    <div className="text-container">
                        <div className="heading">{obj.title}</div>
                        <div className="sub-heading">{obj.subtitle}</div>
                    </div>
                </div>
            </OptionCardView>
        ))
    }
    return (
        <Fragment>
            <div className="option-screen-component">
                <OnBoardingHeader heading={heading} subHeading={subHeading} reverse={true} />
                <div className="multiple-cards">{renderOptions()}</div>
                {bottomOptionComponent && bottomOptionComponent()}
                {backNavigation && backNavigation()}
            </div>
        </Fragment>
    )
}

export default TeachingOptionsComponent
