import { put, all, takeLatest, select } from "redux-saga/effects"
import {
    moduleConstant
} from "../constant"
import {
    getModule
} from "../../utils/services/moduleServices"

function* fetchModule(action: any) {
    try {
        let modules = yield select((state) => state.modules.modules)
        const { lessonId } = action.payload;
        const index = modules.findIndex((module: any) => {
            return module.lessonId === lessonId
        })
        if (index > -1) {
            const module = modules.splice(index, 1);
            modules.unshift(module[0]);
        } else {
            modules.unshift(yield getModule(lessonId));
            modules.splice(5, 1);
        }
        yield put({
            type: moduleConstant.MODULE_SUCCESS, payload: modules
        })
    } catch (error) {
        console.log(error)
    }
}


export default all([
    takeLatest(moduleConstant.MODULE_REQUEST, fetchModule),
])
