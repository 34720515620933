import React, { Fragment, useRef, useState } from "react"
import NavigationButtons from "../../../components/on-boarding/navigation-buttons/NavigationButtons"
import { navigatetoUrl } from "../../../utils/helper/helper"
import { OnBoardingHeader } from "../../../components/on-boarding/OnboardingHeader"
import { useSelector, useDispatch } from "react-redux"
import { showToast } from "../../../store/actions/toastAction"
import { toastConstant } from "../../../store/constant"
import ToastNotification from "../../../components/modal/ToastNotification"
import { setTeacherAvailableSlot } from "../../../store/actions/TutorSignUpActions"
import { applicationConstant } from "../../../utils/constant/applicationConstant"
import InterfaceConstant from "../../../utils/constant/interfaceConstant"
import _ from "lodash"
import { weekDay } from "../../../utils/constant/yearList"
import ProgressBar from "../../../components/progress-bar/ProgressBar"
import RapidBookSvgIcon from "assets/images/svgIcons/RapidBookSvgIcon"
import colors from "assets/colors"
import TutorAvailabilitySlots from "./components/TutorAvailabilitySlots"


interface Iprops {
    history: any
}

export const NO_OF_SLOTS = 48;
export const INTERVAL = 0.5;

const routeUrl: InterfaceConstant.IrouteUrl = applicationConstant.routeUrl
const tutorAvailabilityLable: InterfaceConstant.ItutorAvailabilityLable =
    applicationConstant.tutorAvailabilityLable

const TutorAvailabilityPage = (props: Iprops): JSX.Element => {

    const { teacherWeeklyAvailability } = useSelector((state: any) => state.tutorAvailability)
    const { message, show, isSuccess } = useSelector((state: any) => state.toast)

    const dispatch = useDispatch();

    const nextNavigation = (): void => {
        const isSlotSelected = _.every(
            teacherWeeklyAvailability,
            (item) => _.isEmpty(item) === true,
        )
        if (isSlotSelected) {
            dispatch(
                showToast({
                    show: true,
                    message: tutorAvailabilityLable.ERROR,
                    isSuccess: false,
                }),
            )
            return
        }
        navigatetoUrl(props.history, routeUrl.TUTOR_RATES_PAGE)
    }

    const prevNavigation = (): void => {
        navigatetoUrl(props.history, routeUrl.TUTOR_EDUCATION_PAGE)
    }


    return (
        <Fragment>
            <ProgressBar at="49.8" theme="darkMango" />
            <div>
                <div className="tutor-availability z-10">
                    <div className="tutor-availability-container">
                        <OnBoardingHeader
                            heading={tutorAvailabilityLable.HEADING}
                            subHeading={tutorAvailabilityLable.SUB_HEADING}
                            reverse={true}
                        />
                        <TutorAvailabilitySlots />
                        <div className="details">{tutorAvailabilityLable.DESCRIPTION}</div>
                        <ToastNotification
                            message={message}
                            isopen={show}
                            isOpenAction={() => dispatch({ type: toastConstant.TOAST_HIDE })}
                            styleClass={isSuccess ? "success-toast" : "error-toast"}
                        />
                    </div>
                    <div className="onboarding-actions slot-actions">
                        <NavigationButtons
                            previousClick={prevNavigation}
                            nextClick={nextNavigation}
                        />
                    </div>
                </div>
            </div>
        </Fragment >
    )
}

export default TutorAvailabilityPage
