import { Pending, PENDING_TYPE, PENDING_STATUS } from "./Pending"

export class ReviewPending extends Pending {
    /**
     *
     * @param userId
     */
    constructor(userId: string) {
        super(userId)
        this.initialize()
    }

    initialize() {
        if (this.userId) {
            this.ref = this.database.child(PENDING_TYPE.REVIEW).child(this.userId)
        }
    }
    
    resolveItem(itemId: string) {
        if (this.ref) {
            this.ref.child(itemId).child('status').set(PENDING_STATUS.RESOLVED).catch(e => console.log("Error while resolving review item: ", e))    
        }
    }
}
