import React, { Fragment, useRef, useState } from "react"
import TabSelection from "../../../../components/tab-selection/TabSelection"
import { useSelector, useDispatch } from "react-redux"
import { setTeacherAvailableSlot } from "../../../../store/actions/TutorSignUpActions"
import _ from "lodash"
import { weekDay } from "../../../../utils/constant/yearList"
import { Grid } from "@material-ui/core"
import Checkbox from "components/input-field/CheckBox"
import DownArrowSvgIcon from "assets/images/svgIcons/DownArrowSvgIcon"
import colors from "assets/colors"

interface Option {
    name: string
    id: number,
}

export const NO_OF_SLOTS = 48;
export const INTERVAL = 0.5;
export const NO_OF_DAYS = 7;

const TutorAvailabilitySlots = (): JSX.Element => {

    const [selectedDayIndex, setSelectedDayIndex] = useState(0);
    const { teacherWeeklyAvailability } = useSelector((state: any) => state.tutorAvailability)
    const slotsRef = useRef<HTMLInputElement>(null);

    const dispatch = useDispatch();

    const updateSlots = (selectedSlot: any): void => {
        dispatch(
            setTeacherAvailableSlot({
                teacherWeeklyAvailability: selectedSlot,
            }),
        )
    }

    const selectAllSlots = (e: React.MouseEvent<HTMLElement>) => {
        const index = weekDay.find((val, index) => {
            return teacherWeeklyAvailability[index].length !== NO_OF_SLOTS
        })
        if (index) {
            updateSlots({
                0: Array.from(Array(NO_OF_SLOTS).keys()),
                1: Array.from(Array(NO_OF_SLOTS).keys()),
                2: Array.from(Array(NO_OF_SLOTS).keys()),
                3: Array.from(Array(NO_OF_SLOTS).keys()),
                4: Array.from(Array(NO_OF_SLOTS).keys()),
                5: Array.from(Array(NO_OF_SLOTS).keys()),
                6: Array.from(Array(NO_OF_SLOTS).keys())
            })

        } else {
            updateSlots({
                0: [],
                1: [],
                2: [],
                3: [],
                4: [],
                5: [],
                6: []
            })
        }
    }

    const selectAllSlotsOfADay = (e: React.MouseEvent<HTMLElement>, index: number) => {
        if (teacherWeeklyAvailability[index].length !== NO_OF_SLOTS) {
            updateSlots({
                ...teacherWeeklyAvailability,
                [index]: Array.from(Array(NO_OF_SLOTS).keys())
            })
        } else {
            updateSlots({
                ...teacherWeeklyAvailability,
                [index]: []
            })
        }
    }

    return (
        <>
            <div className="slots-content">
                <Checkbox
                    name={"Select all slots"}
                    title={"Select all slots"}
                    inputProps={{
                        onClick: (e: React.MouseEvent<HTMLElement>) => selectAllSlots(e),
                        checked: !weekDay.find((val, index) => {
                            return teacherWeeklyAvailability[index].length !== NO_OF_SLOTS
                        })
                    }}
                />
                <Grid container>
                    <Grid item xs={2} sm={12} md={1} className="day-checks">
                        {weekDay.map((option: Option, index: number) => (
                            <Checkbox
                                key={option.name}
                                name={option.name}
                                title={option.name}
                                inputProps={{
                                    onClick: (e: React.MouseEvent<HTMLElement>) => selectAllSlotsOfADay(e, index),
                                    checked: teacherWeeklyAvailability[index].length === NO_OF_SLOTS
                                }}
                            />
                        ))}
                    </Grid>
                    <Grid item xs={10} sm={12} md={11} className="all-slots">
                        {weekDay.map((option: Option) => (
                            <Fragment>
                                <div style={{ display: "flex" }}>
                                    <TabSelection
                                        updateSlots={updateSlots}
                                        value={option.id}
                                        selectedValue={teacherWeeklyAvailability}
                                        noOfSlots={NO_OF_SLOTS}
                                    />
                                </div>
                            </Fragment>
                        ))}
                    </Grid>
                </Grid>
            </div>
            <div className="slots-content-mobile">
                <Grid container className="content">
                    <Grid item xs={12} className="day-titles">
                        <div className="wrapper">
                            {weekDay.map((option: Option, index: number) => (
                                <div className={`title ${selectedDayIndex === index ? 'selected' : ''}`} onClick={() => setSelectedDayIndex(index)}>
                                    <p>{option.name}</p>
                                </div>
                            ))}
                        </div>
                    </Grid>
                    <Grid item xs={12} className="select-all" >
                        <Checkbox
                            name={"Select all slots"}
                            title={"Select all slots"}
                            inputProps={{
                                onClick: (e: React.MouseEvent<HTMLElement>) => selectAllSlotsOfADay(e, selectedDayIndex),
                                checked: teacherWeeklyAvailability[selectedDayIndex].length === NO_OF_SLOTS
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} ref={slotsRef} className="slots" >
                        <TabSelection
                            updateSlots={updateSlots}
                            value={selectedDayIndex}
                            selectedValue={teacherWeeklyAvailability}
                            noOfSlots={NO_OF_SLOTS}
                        />
                    </Grid>
                    <span
                        className="down-svg"
                        onClick={() => {
                            slotsRef.current?.scroll({ top: slotsRef.current.scrollTop + slotsRef.current.clientHeight });
                        }}>
                        <DownArrowSvgIcon iconColor={colors.whiteSolid} width="19" height="19" />
                    </span>
                </Grid>
            </div>
        </>
    )
}

export default TutorAvailabilitySlots
