import React, { useCallback, useState } from "react"
import { useFormik } from "formik"
import * as Yup from "yup"
import { Link } from "react-router-dom"
import Button from "../../../components/button/Button"
import { applicationConstant } from "../../../utils/constant/applicationConstant"
import InterfaceConstant from "../../../utils/constant/interfaceConstant"
import { InputBox } from "../../../components/input-field/InputFields"
import { useDispatch } from "react-redux"
import { loginSuccess } from "../../../store/actions/userActions"
import { useSnackbar } from "notistack";
import { firebaseAuth } from "utils/helper/firebase"
import { getUserWithDetails } from "utils/services/userServices"
import * as crashLogger from "utils/services/crashLogger"
import { Button as MButton } from "@material-ui/core";
interface User {
    email: string
    password: string
}

const singInLabel: InterfaceConstant.IappLabels = applicationConstant.appLabels
const signInErrorLabel: InterfaceConstant.IappErrorLabels = applicationConstant.appErrorLabels

// eslint-disable-next-line no-undef
const SignInForm = (): JSX.Element => {
    const [isLoading, setLoading] = useState(false);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const signIn = useCallback(async (email: string, password: string) => {
        return await firebaseAuth.signInWithEmailAndPassword(email, password).then(async (result) => {
            if (!result.user?.emailVerified) {
                throw {
                    code: "auth/email-not-verified",
                    message: "Your email is not verified. Please verify your account first."
                }
            }
            return await getUserWithDetails(result?.user?.uid);
        });
    }, []);

    const formik = useFormik({
        initialValues: {
            email: "",
            password: "",
        },
        validationSchema: Yup.object({
            email: Yup.string()
                .email(signInErrorLabel.GLOBAL_EMAIL_INVALID)
                .required(signInErrorLabel.GLOBAL_REQUIRED),
            password: Yup.string()
                .min(6, signInErrorLabel.GLOBAL_PASSWORD_REGEX_ERROR)
                .required(signInErrorLabel.GLOBAL_REQUIRED),
        }),
        onSubmit: (values: User) => {
            setLoading(true);
            signIn(values.email, values.password).then(data => {
                dispatch(loginSuccess(data));
                setLoading(false);
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            }).catch((error: any) => {
                setLoading(false);
                let message = <p></p>;
                switch (error.code) {
                    case "auth/wrong-password":
                        message = <p>Invalid password. Please try again!</p>
                        break
                    case "auth/user-not-found":
                        message = <p>You need to signup to proceed</p>
                        break
                    case "auth/email-not-verified":
                        message = <span>{error.message}</span>
                        return enqueueSnackbar(message, {
                            variant: "warning",
                            persist: true,
                            action: (key) => {
                                return <>
                                    <MButton size="medium" color="inherit" onClick={() => {
                                        closeSnackbar(key);
                                        firebaseAuth.currentUser?.sendEmailVerification().then(() => {
                                            enqueueSnackbar(`A verification email is sent to ${values.email}. Please check your inbox.`, {
                                                variant: "success",
                                                autoHideDuration: 10000
                                            })
                                        }).catch(e => {
                                            enqueueSnackbar(e.message || e.name || "Unknown error", {
                                                variant: "error",
                                                autoHideDuration: 10000
                                            })
                                        })
                                    }}><u>Send Email Now</u></MButton>
                                    <MButton size="medium" color="inherit" onClick={() => closeSnackbar(key)}>Close</MButton>
                                </>
                            }
                        })
                    default:
                        message = error.message
                        crashLogger.log(`Login: ${values.email}`, error)
                        break
                }
                enqueueSnackbar(message, {
                    variant: "error",
                    autoHideDuration: 10000
                });
            });
        },
    })

    return (
        <form className="login-flow-form" onSubmit={formik.handleSubmit}>
            <InputBox
                type={"email"}
                title={singInLabel.GLOBAL_EMAIL}
                placeholder={singInLabel.EMAIL_PLACEHOLDER}
                inputProps={formik.getFieldProps("email")}
                error={formik.touched.email && formik.errors.email ? formik.errors.email : null}
            />
            <InputBox
                type={"password"}
                title={singInLabel.GLOBAL_PASSWORD}
                placeholder={singInLabel.PASSWORD_PLACEHOLDER}
                inputProps={formik.getFieldProps("password")}
                error={
                    formik.touched.password && formik.errors.password
                        ? formik.errors.password
                        : null
                }
            />
            <u>
                <Link to="/reset-password">Forgot your password?</Link>
            </u>

            <Button
                title={"Sign In"}
                clickAction={undefined}
                isDisabled={isLoading}
                isloading={isLoading}
            />

            <div>We recommend downloading the <a target="_blank" href="https://www.google.com/chrome/">Google Chrome</a> browser and using it while on our platform, as other browsers do not currently support all functions of our platform.</div>
        </form>
    )
}

export default SignInForm
