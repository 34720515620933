import React, { useState, useEffect } from "react"
import { applicationConstant } from "../../../../utils/constant/applicationConstant"
import InterfaceConstant from "../../../../utils/constant/interfaceConstant"
import { useHistory, useLocation, useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { Grid } from "@material-ui/core"
import RoundedButton from "../../../../components/rounded-button/RoundedButton"
import { navigatetoUrl } from "../../../../utils/helper/helper"
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons"
import LessonSubscribeHeader from "./subscription-confirm-components/LessonSubscribeHeader"
import LessonSubscribeSubtotal from "./subscription-confirm-components/LessonSubscribeSubtotal"
import LessonSubscribeCancelation from "./subscription-confirm-components/LessonSubscribeCanceation"
import LessonSubscribeFormWeb from "./subscription-confirm-components/subscription-confirm-grey-sect/LessonSubscribeFormWeb"
import { findPlansByName } from "store/actions/PaymentActions"
import PromoCode, { promoCodeDetailsType } from "pages/home/booking/components/PromoCode"
import { constructProductName } from "utils/helper/promo-code"

const LessonSubscribePage = (props: any): JSX.Element => {

    const { subType } = useParams()
    const { plan, isLoading, planNotFound } = useSelector((state: any) => state.payment)
    const productName = constructProductName(plan)
    const [dontRenew, setDontRenew] = useState(false)
    const [promoCodeDetails, setPromoCodeDetails] = useState<promoCodeDetailsType | undefined>(undefined)
    const [canGoBack, setCanGoBack] = useState(true);

    const location = useLocation();

    const getPrice = plan
        ? plan.unit_amount_decimal
            ? plan.unit_amount / 100
            : plan.unit_amount
        : ""
    const subscriptionType = { term: plan?.nickname, price: getPrice }
    const [netPrice, setNetPrice] = useState<number>(subscriptionType.price)
    const [applyingPromo, setApplyingPromo] = useState<boolean>(false)

    const subscribed = true
    let history = useHistory()

    const dispatch = useDispatch()
    const bookingLabel: InterfaceConstant.IbookingLabel = applicationConstant.bookingLabel

    useEffect(() => {
        dispatch(findPlansByName({ subType, history }));
        if (location.search.toLowerCase().includes("website")) {
            setCanGoBack(false);
        }
    }, [])

    useEffect(() => {
        if (planNotFound) {
            navigatetoUrl(history, "/lessonPaymentPlans");
        }
    }, [planNotFound])

    useEffect(() => {
        const totalPrice: number = subscriptionType.price
        const discount = promoCodeDetails?.amount_off
            ? promoCodeDetails.amount_off / 100
            : promoCodeDetails?.percent_off
                ? (totalPrice * promoCodeDetails?.percent_off) / 100
                : 0
        let discountedPrice = totalPrice - discount
        discountedPrice = discountedPrice >= 0 ? discountedPrice : 0
        discountedPrice = Number(discountedPrice.toFixed(2))
        setNetPrice(discountedPrice)
    }, [promoCodeDetails, subscriptionType])

    return (
        <>
            {!isLoading && (
                <Grid container className="subscription-payment onBoarding-subscription-confirm">
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <div className="white-section">
                            <div className="checkout-div">
                                {canGoBack &&
                                    <div className="signup-back-btn">
                                        <RoundedButton
                                            onPress={() => {
                                                navigatetoUrl(history, "/lessonPaymentPlans")
                                            }}
                                            iconProps={{
                                                icon: faChevronLeft,
                                                style: {
                                                    color: "#262ffa",
                                                    alignSelf: "center",
                                                    marginRight: "2px",
                                                },
                                            }}
                                            className="book-back-button"
                                            active={true}
                                        />
                                    </div>
                                }
                                <div>
                                    <h1>Checkout</h1>
                                </div>
                            </div>
                            <div className="subscription-confirm-static">
                                <LessonSubscribeHeader />
                                <LessonSubscribeSubtotal
                                    subscriptionType={subscriptionType}
                                    bookingLabel={bookingLabel}
                                    promoCodeDetails={promoCodeDetails}
                                    setPromoCodeDetails={setPromoCodeDetails}
                                    price={netPrice}
                                />
                                <div>
                                    <PromoCode
                                        product={productName}
                                        setPromoCodeDetails={setPromoCodeDetails}
                                        promoCodeDetails={promoCodeDetails}
                                        setApplying={setApplyingPromo}
                                    />
                                </div>
                                <LessonSubscribeCancelation />
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <div className="gray-section">
                            <LessonSubscribeFormWeb
                                plan={plan}
                                dontRenew={dontRenew}
                                promoCodeId={promoCodeDetails?.id}
                                applyingPromo={applyingPromo}
                            />
                        </div>
                    </Grid>
                </Grid>
            )}
        </>
    )
}

export default LessonSubscribePage
