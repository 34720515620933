import { combineReducers as reducerCombiner } from "redux"

export type Action<T> = {
    type: string
    payload: T
}

export type ActionCreator<T> = (payload: T) => Action<T>

export type ActionCreatorWithType<T> = ActionCreator<T> & {
    type: string
}

export function makeActionCreator<T>(type: string): ActionCreatorWithType<T> {
    const actionCreator: ActionCreatorWithType<T> = (payload) => {
        return {
            type,
            payload,
        }
    }
    actionCreator.type = type
    return actionCreator
}

export type AsyncActionCreatorWithType<S, C, E, U, F, R> = {
    type: string
    start: ActionCreatorWithType<S>
    cancel: ActionCreatorWithType<C>
    ended: ActionCreatorWithType<E>
    success: ActionCreatorWithType<U>
    failure: ActionCreatorWithType<F>
    reset: ActionCreatorWithType<R>
}
export function makeAsyncActionCreators<S, C, E, U, F, R>(
    type: string,
): AsyncActionCreatorWithType<S, C, E, U, F, R> {
    return {
        start: makeActionCreator<S>(`${type}__START`),
        cancel: makeActionCreator<C>(`${type}__CANCEL`),
        ended: makeActionCreator<E>(`${type}__ENDED`),
        success: makeActionCreator<U>(`${type}__SUCCESS`),
        failure: makeActionCreator<F>(`${type}__FAILURE`),
        reset: makeActionCreator<R>(`${type}__RESET`),
        type,
    }
}

export type Reducer<S> = (state: S, action: Action<any>) => S
export type ActionHandlers<S> = {
    [actionType: string]: Reducer<S>
}

export function createReducer<S>(initialState: S, actionHandlers: ActionHandlers<S>): Reducer<S> {
    return (state = initialState, action): S => {
        let newState = state
        if (`${action.type}` in actionHandlers) {
            newState = actionHandlers[action.type](state, action)
        } else if ("*" in actionHandlers) {
            newState = actionHandlers["*"](state, action)
        }
        return newState
    }
}

export const combineReducers = reducerCombiner
