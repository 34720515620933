import React, { useState } from "react"
import { useFormik } from "formik"
import { useDispatch, useSelector } from "react-redux"
import * as Yup from "yup"
import { signupRequest } from "../../../store/actions/userActions"
import Button from "../../../components/button/Button"
import CheckBox from "../../../components/input-field/CheckBox"
import { applicationConstant } from "../../../utils/constant/applicationConstant"
import InterfaceConstant from "../../../utils/constant/interfaceConstant"
import { InputBox } from "../../../components/input-field/InputFields"
import { useHistory, useLocation } from "react-router-dom"
import { navigatetoUrl, targetForRedirection } from "utils/helper/helper"
import { useTranslation } from 'react-i18next';
import { externalLinks } from "utils/constant/externalLinks"
import PhoneNumberInput from "components/phone-number-input/PhoneNumberInput"
import parsePhoneNumber, { isValidPhoneNumber, NationalNumber } from "libphonenumber-js"
import { useSnackbar } from "notistack"


interface User {
    firstName: string
    lastName: string
    email: string
    password: string
    acceptedTerms: boolean
    optInSMS: boolean
}

const singUpLabel: InterfaceConstant.IappLabels = applicationConstant.appLabels
const signUpErrorLabel: InterfaceConstant.IappErrorLabels = applicationConstant.appErrorLabels
const regexVariables: InterfaceConstant.IregexExpression = applicationConstant.regexExpression
const nameFormatter = async (firstName: string, lastName: string) => {

    let lastName1: any = lastName.split(" ");
    lastName1 = lastName1.map((word: string) => {
        return word && word[0].toUpperCase() + word.substring(1);
    }).join(" ");
    const nlastName = lastName1.trim()
        .split(/ +/)
        .join(" ");
    let firstName1: any = firstName.split(" ");
    firstName1 = firstName1.map((word: string) => {
        return word && word[0].toUpperCase() + word.substring(1);
    }).join(" ");
    const nfirstName = firstName1.trim()
        .split(/ +/)
        .join(" ");
    const StudentName = {
        firstName: nfirstName,
        lastName: nlastName
    }
    return StudentName;
}
const SignupForm = (): JSX.Element => {

    const [phoneNumber, setPhoneNumber] = useState<{
        number: "" | NationalNumber,
        countryCode: string,
        countryPrefix: string
    }>({
        number: "",
        countryCode: "",
        countryPrefix: ""
    })
    const loading = useSelector((state: any) => state.signup.isloading)
    const options = useSelector((state: any) => state.signup.options)
    const [checkboxError, setCheckboxError] = useState("")
    const [isPhoneNumberEmpty, setIsPhoneNumberEmpty] = useState<boolean>(true)
    const history = useHistory()
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()


    const location = useLocation()

    const urlParams = new URLSearchParams(location.search)
    const redirectTo = urlParams.get("redirectTo")

    const formik = useFormik({
        initialValues: {
            firstName: "",
            lastName: "",
            email: "",
            password: "",
            acceptedTerms: false,
            optInSMS: true, // by default sms is opt out
        },
        validationSchema: Yup.object({
            firstName: Yup.string()
                .matches(/^[\\a-zA-Zp{L}.'-]+$/, 'English alphabets and .\'- are allowed only')
                .required(signUpErrorLabel.GLOBAL_REQUIRED),
            lastName: Yup.string()
                .matches(/^[\\a-zA-Zp{L} .'-]*$/, 'Blank space, English alphabets and .\'- are allowed only')
                .required(signUpErrorLabel.GLOBAL_REQUIRED),
            email: Yup.string()
                .matches(regexVariables.EMAIL_VALIDATION, signUpErrorLabel.GLOBAL_EMAIL_INVALID)
                .required(signUpErrorLabel.GLOBAL_REQUIRED),
            password: Yup.string()
                .min(6, signUpErrorLabel.GLOBAL_PASSWORD_REGEX_ERROR)
                .max(64, signUpErrorLabel.GLOBAL_PASSWORD_REGEX_ERROR)
                .required(signUpErrorLabel.GLOBAL_REQUIRED),
            acceptedTerms: Yup.boolean().oneOf([true], "You must agree to terms and conditions"),
        }),
        onSubmit: (values: User) => {
            if (!isValidPhoneNumber(phoneNumber.countryCode + phoneNumber.number) && (!isPhoneNumberEmpty || formik.getFieldProps("optInSMS").value)) {
                enqueueSnackbar("Please enter a valid Phone Number.", {
                    variant: "error",
                    autoHideDuration: 10000
                });
                return;
            }
            const { email, password, lastName, firstName, optInSMS } = values
            nameFormatter(firstName, lastName).then((studentName: any) => {
                const lastName = studentName.lastName;
                const firstName = studentName.firstName;

                dispatch(signupRequest({
                    email, password, firstName, lastName, notificationSubscriptions: { sms: optInSMS }, phoneNumber, options, createRedirectTo: redirectTo
                }))

            }).catch(console.error)

        },
    })

    const handlePhoneNumber = (value: string) => {
        const phoneNumber = parsePhoneNumber(value);
        if (!value.split(" ")[0].includes("0") && (phoneNumber?.nationalNumber === undefined)) {
            setIsPhoneNumberEmpty(true)
        } else {
            setIsPhoneNumberEmpty(false)
        }
        const { countryCallingCode = '1', nationalNumber = '', country = 'US' } = phoneNumber || {};
        setPhoneNumber({ countryCode: "+" + countryCallingCode, number: nationalNumber, countryPrefix: country?.toUpperCase() });
    }

    const handleChange = (e: any) => {
        const { name, value } = e.target
        switch (name) {
            case "firstName":
                formik.setFieldValue("firstName", value && value[0].toUpperCase() + value.substring(1), true)
                break
            case "lastName":
                let splitStr = value.toLowerCase().split(" ");
                splitStr = splitStr.map((word: string) => {
                    return word.charAt(0).toUpperCase() + word.substring(1);
                });
                const _lastName = splitStr.join(" ");
                formik.setFieldValue("lastName", _lastName, true)
                break
        }
    }

    return (
        <form className="sign-up-form" onSubmit={formik.handleSubmit}>
            <div className="first-line">
                <InputBox
                    className="user-name"
                    type={"text"}
                    title={singUpLabel.GLOBAL_FIRST_NAME}
                    placeholder={singUpLabel.FIRST_NAME_PLACEHOLDER}
                    inputProps={{
                        disabled: loading,
                        name: "firstName",
                        value: formik.values.firstName,
                        onChange: (e: any) => handleChange(e),
                        onBlur: formik.handleBlur,
                    }}
                    error={
                        formik.touched.firstName && formik.errors.firstName
                            ? formik.errors.firstName
                            : null
                    }
                />
                <InputBox
                    className="user-name"
                    type={"text"}
                    title={singUpLabel.GLOBAL_LAST_NAME}
                    placeholder={singUpLabel.LAST_NAME_PLACEHOLDER}
                    inputProps={{
                        disabled: loading,
                        name: "lastName",
                        value: formik.values.lastName,
                        onChange: (e: any) => handleChange(e),
                        onBlur: formik.handleBlur,
                    }}
                    error={
                        formik.touched.lastName && formik.errors.lastName
                            ? formik.errors.lastName
                            : null
                    }
                />

            </div>
            <InputBox
                type={"email"}
                title={singUpLabel.GLOBAL_EMAIL}
                placeholder={singUpLabel.EMAIL_PLACEHOLDER}
                inputProps={formik.getFieldProps("email")}
                error={formik.touched.email && formik.errors.email ? formik.errors.email : null}
            />
            <div className="phone-number-wrapper">
                <PhoneNumberInput
                    className="phone-number"
                    defaultCountry={'us'}
                    onChange={handlePhoneNumber}
                    preferredCountries={['us']}
                    disableAreaCodes={true}
                    countryCodeEditable={true}
                    title="Phone Number"
                    variant="outlined"
                    inputProps={{
                        style: {
                            "height": "30px"
                        }
                    }}
                    value={phoneNumber?.countryCode + phoneNumber?.number}
                />
            </div>
            <InputBox
                type={"password"}
                title={singUpLabel.GLOBAL_PASSWORD}
                placeholder={singUpLabel.PASSWORD_PLACEHOLDER}
                inputProps={formik.getFieldProps("password")}
                error={
                    formik.touched.password && formik.errors.password
                        ? formik.errors.password
                        : null
                }
            />
            <CheckBox
                name="acceptedTerms"
                inputProps={formik.getFieldProps("acceptedTerms")}
                title={<p>{t("terms-condition-1")}<a href={externalLinks.TERMS_OF_USE} target={targetForRedirection()}>{t("terms-condition-2")}</a>.</p>}
                error={
                    formik.touched.acceptedTerms && formik.errors.acceptedTerms
                        ? formik.errors.acceptedTerms
                        : null
                }
            />

            <CheckBox
                name="optInSMS"
                classNames="subscription-optins"
                inputProps={{ checked: formik.getFieldProps("optInSMS").value, onChange: formik.getFieldProps("optInSMS").onChange }}
                title={<p>I agree to receive SMS/Text Messages for notifications about my account – this is highly recommended to ensure you remain up-to-date on your pending requests, cancelations, etc. Local rates may apply.</p>}
            />

            <Button
                title={"Register"}
                clickAction={undefined}
                isDisabled={loading}
                isloading={loading}
            />
            <p className="switch-login-link" style={{ paddingTop: "30px" }}> Already have an account?  <u onClick={() => { navigatetoUrl(history, "/sign-in") }}> Sign In </u></p>
        </form>
    )
}

export default SignupForm
