import React, { useEffect, useState } from "react"
import { getUserFullName, getUserProfilePicture } from "../../utils/services/userServices"
import DefaultAvatar from "../../assets/images/default-avatar.png"
import { useSelector } from "react-redux";

const getUserFullNameData = async (userId: string) => {
    return await getUserFullName(userId).then((val) => {
        return val;
    })
}
const ChatUserProfile = ({ presenterIds }: { presenterIds: Array<string> }) => {
    const { userAuthData } = useSelector((state: any) => state.auth)
    const [userProfileURLs, setUserProfileURLs] = useState<any[]>([])
    const [currentURL, setCurrentURL] = useState<any>(null)
    const [userType, setUserType] = useState<number>(0)
    const [fullName, setFullName] = useState<any>(null);

    useEffect(() => {
        if (presenterIds) {
            presenterIds.map(async (e: string) => {
                await getUserProfilePicture(e).then((val) => {
                    setCurrentURL(val)
                })
            })
        }
    }, [presenterIds])

    useEffect(() => {
        if (presenterIds) {
            getUserFullNameData(presenterIds[userType]).then((val) => {
                setFullName(val)
            }).catch(console.error)
        }
    }, [userType])
    useEffect(() => {
        if (currentURL !== null) setUserProfileURLs([...userProfileURLs, currentURL])
        if (userAuthData.userType == 'TEACHER') { setUserType(1) }
    }, [currentURL])

    return (
        <div style={{ display: "flex" }}>
            <div className="chatUserProfile">
                <img className="UserImage" src={userProfileURLs[userType] ? userProfileURLs[userType] : DefaultAvatar} ></img>
                <p className="bottom-left"> {fullName} </p>
            </div>
        </div>
    )
}

export default ChatUserProfile
