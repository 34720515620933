import React from "react"
import { useTranslation } from 'react-i18next';
import SimpleDropdown from "../../../../../components/dropdown/SimpleDropdown"
import { educationLevel } from "../../../../../../src/utils/constant/educationLists"



const EducationFilter = ({ education, setSelectedEducation }: { education: any, setSelectedEducation: any }): JSX.Element => {
    const { t } = useTranslation();
    return (
        <div className="tutor-search-filter">
            <SimpleDropdown
                title={t("education")}
                placeholder={t("select-education")}
                optionsList={educationLevel}
                fieldDetails={{
                    value: education
                }}
                setProps={setSelectedEducation}
            />
        </div>
    )
}

export default EducationFilter

