import { Grid } from "@material-ui/core";
import { InfoIcon } from "components/icons/Icons";
import React, { Fragment, useEffect, useState } from "react"
import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux";

const Wallet = (): JSX.Element => {
    const { t } = useTranslation();
    const { stripeCustomer } = useSelector((state: any) => state.auth.userAuthData)
    return (
        <div>
            {((Math.abs(stripeCustomer?.balance))/100 > 0) ?
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="wallet">
                    <Grid item xl={3} lg={3} md={3} sm={12} xs={12} className="balance">
                        <div>
                            <p>{t("wallet-balance")}</p>
                            <h2> ${(Math.abs(stripeCustomer?.balance)) / 100}</h2>
                        </div>
                    </Grid>
                    <Grid item xl={9} lg={9} md={9} sm={12} xs={12} className="margin-auto description">
                        <InfoIcon color="#fff" className="circle" titleAccess={t('rapid-book-info')}></InfoIcon>
                        <p>{t("wallet-balance-desc")}</p>
                    </Grid>
                </Grid>
                : (<></>)
            }
        </div>
    )
}
export default Wallet