import React from "react"
import { newStudent,newTutor,counsellingApple } from "../../../components/icons/Icons"
import OptionScreenComponent from "../../../components/option-card-component/OptionScreenComponent"
import { navigatetoUrl } from "../../../utils/helper/helper"
import OnboardingBackground from "../../../components/on-boarding/onboarding-background/OnboardingBackground"
import ProgressBar from "../../../components/progress-bar/ProgressBar"
import Button from "components/button/Button"

const LookingForPageOptions = [
    {
        key: "STUDENT",
        title: "Student",
        subtitle: "",
        icon: newStudent,
    },
    {
        key: "TUTOR/ADVISOR",
        title: "Tutor / Advisor",
        subtitle: "",
        icon: newTutor,
    },
]
interface Iprops {
    history: any
}
export default class MyRole extends React.Component<Iprops> {
    handleOptionPress = (selectedOption: string) => {
        switch (selectedOption) {
            case "STUDENT":
                navigatetoUrl(this.props.history, "/looking-for")
                break
            case "TUTOR/ADVISOR":
                navigatetoUrl(this.props.history, "/tutor-advisor-application")
                break
            default:
                break
        }
    }
    render(): JSX.Element {
        return (
            <>
                <OnboardingBackground />

                <div className="i-am">

                    <ProgressBar at="20" theme="darkMango" />
                    <OptionScreenComponent
                        heading={"I’m new to The Classroom Door and"}
                        subHeading={"I Am..."}
                        options={LookingForPageOptions}
                        onOptionPress={this.handleOptionPress}
                    />
                  <div className="registration-option-onboarding">
                        <div className="onBoarding-registered">
                        <h2>Already  Registered</h2>
                        <p>If you’re registered at The Classroom Door, proceed to sign in.</p>
                        <div className="btn-to-sign">
                        <Button className="btn-to-sign-style" title="Sign In" clickAction={()=>navigatetoUrl(this.props.history, "/sign-in")} />
                    </div>
                    </div>
                </div>
                </div>
            </>
        )
    }
}
