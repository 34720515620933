import React from "react"
import { Container, Row, Col } from "reactstrap"
import { useSelector, useDispatch } from "react-redux"
import ProgressBar from "../../../components/progress-bar/ProgressBar"
import OnboardingBackground from "../../../components/on-boarding/onboarding-background/OnboardingBackground"
import { OnBoardingHeader } from "../../../components/on-boarding/OnboardingHeader"
import TutorAdvisorEducationForm from "./TutorAdvisorEducationForm"
import Modal from "../../../components/modal_/Modal"
import { modalConstant } from "../../../store/constant"
import SignUpsettingIcon from "../../../assets/images/signUpsetting.png"
interface Iprops {
    history: any
}

const TutorAdvisorEducationPage = (props: Iprops) => {
    const modalInfo = useSelector((state: any) => state.modal)
    const dispatch = useDispatch()
    const onExitModal = (): void => {
        dispatch({ type: modalConstant.MODAL_HIDE })
    }
    return (
        <>
            <Modal
                title={modalInfo.title}
                visible={modalInfo.show}
                icon={modalInfo.icon}
                subtitle={modalInfo.text}
                onExit={onExitModal}
            />
            <ProgressBar at="33.2" theme="darkMango" />
            <OnboardingBackground />
           
                <div className="educational-background-page-subcontainer">
                    <Container>
                        <Row>
                            <Col xs="12">
                                <OnBoardingHeader subHeading={"Education"} heading="" />
                            </Col>
                        </Row>
                        <TutorAdvisorEducationForm />
                        
                    </Container>
                </div>
            
        </>
    )
}

export default TutorAdvisorEducationPage
