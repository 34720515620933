import React, { useState, useEffect } from "react"
import { Grid } from "@material-ui/core"
import UserProfileForm from "./components/UserProfileForm"
import { useDispatch, useSelector } from "react-redux"
import { firebaseAuth, functions, getStudentInformation } from "../../../utils/helper/firebase"
import LoaderSpinner from "../../../components/loader/LoaderSpinner"
import { logOutRequest, signupInformation } from "../../../store/actions/userActions"
import { school } from "../../../utils/constant/educationLists"
import SwitchTabComponent from "../../../components/tabs/switch-tab/SwitchTabComponent"
import SubscriptionTile from "./components/SubscriptionTile"
import Payments from "./components/Payments/Payments"
import { useTranslation } from "react-i18next"
import { cancelSubscription } from "../../../store/actions/subscriptionActions"
import { DeleteAccount } from "components/delete-account"
import { useHistory } from "react-router-dom"

const TabTypes = {
    PROILE: "Profile",
    PAYMENTS: "Payment Methods",
    SUBSCRIPTION: "Subscription",
}

const UserProfilePage = (): JSX.Element => {
    const dispatch = useDispatch()
    const history = useHistory()
    const { userAuthData } = useSelector((state: any) => state.auth)
    const [optionsOpen, setOptionsOpen] = useState(false)
    const [tabValue, setTabValue] = useState("Profile")
    const [dataUpdated, setDataUpdated] = useState(false)
    const [pageLoading, setPageLoading] = useState(true)
    const [dataOk, setDataOk] = useState(false)
    const handleChange = (newValue: string) => {
        setTabValue(newValue)
    }
    const { t } = useTranslation()
    const cancelSubscriptionController = (subscriptionId: any) => {
        dispatch(cancelSubscription({ subscriptionId }))
    }
    useEffect(() => {
        getStudentInformation(userAuthData.userId)
            .then((data: any) => {
                const filtered = school.filter(
                    (item: any) => item.id === data?.studentCurrentGrade,
                )[0]
                const defaultValue = {
                    name: "",
                    id: "",
                }
                const gradeFiltered = data?.studentCurrentGrade
                    ? filtered !== undefined
                        ? filtered
                        : defaultValue
                    : defaultValue

                const StudentOptions = {
                    userLookingFor: data?.studentLookingFor,
                    studentAdvisingTopics: data?.studentAdvisingTopics,
                    studentTutoringSubjects: data?.studentTutoringSubjects,
                    studentCurrentGrade: gradeFiltered,
                    firstName: userAuthData.firstName,
                    lastName: userAuthData.lastName,
                    gender: userAuthData.gender,
                    dateOfBirth: userAuthData.dateOfBirth,
                    currentSchool: data?.currentSchool,
                    myGoal: data?.myGoal,
                    biography: data?.biography,
                    profilePic: userAuthData.profilePic,
                }

                dispatch(signupInformation(StudentOptions))
                setDataOk(true)
                setPageLoading(false)
            })
            .catch(console.error)
    }, [dataUpdated])

    return (
        <>
            <Grid container className="my-account-page student-account">
                {/* <Grid item xl={2} lg={1} md={1}></Grid> */}
                <Grid item xl={12} lg={12} md={12} style={{ margin: "auto", padding: "0" }}>
                    <Grid container direction="row" justify="space-between" alignItems="flex-start">
                        <Grid item className="account-head">
                            <h1>{t("my-account")}</h1>
                        </Grid>
                        <Grid item className="switch-tab-design my-account-tabs-container">
                            <SwitchTabComponent
                                title=""
                                options={Object.values(TabTypes)}
                                selectedOption={tabValue}
                                onTabClick={handleChange}
                            />
                        </Grid>
                    </Grid>
                    <Grid container className="my-page-content">
                        {!dataOk && pageLoading ? (
                            <LoaderSpinner />
                        ) : (
                            tabValue === TabTypes.PROILE && (
                                <UserProfileForm
                                    handleAvatarClick={setOptionsOpen}
                                    optionsOpen={optionsOpen}
                                    setDataUpdated={setDataUpdated}
                                />
                            )
                        )}
                        {tabValue === TabTypes.SUBSCRIPTION && (
                            <>
                                <Grid
                                    item
                                    xl={12}
                                    lg={12}
                                    md={12}
                                    sm={12}
                                    xs={12}
                                    className="tab-heading"
                                >
                                    <p>{t("subscriptions")}</p>
                                </Grid>
                                {userAuthData?.subscription &&
                                userAuthData?.subscription?.status != "request_canceled" &&
                                userAuthData?.subscription?.status != "pending_request" ? (
                                    <Grid
                                        item
                                        xl={12}
                                        lg={12}
                                        md={12}
                                        sm={12}
                                        xs={12}
                                        className="subscription-tab-content"
                                    >
                                        <SubscriptionTile
                                            cancelSubscription={cancelSubscriptionController}
                                        />
                                    </Grid>
                                ) : (
                                    <p>{t("no-active-subscription")}</p>
                                )}
                            </>
                        )}

                        {tabValue === TabTypes.PAYMENTS && <Payments />}
                    </Grid>
                </Grid>
                {/* <Grid item xl={2} lg={1} md={1}></Grid> */}
            </Grid>
            {/* <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="flex-start"
                className="my-account-page student-account"
            >
                <Grid item className="account-head">
                    <DeleteAccount
                        auth={firebaseAuth}
                        functions={functions}
                        onPostDeletion={() => {
                            dispatch(logOutRequest())
                        }}
                    />
                </Grid>
            </Grid> */}
        </>
    )
}

export default UserProfilePage
