import React, { useState } from "react";
import { InputBox } from "../../../../components/input-field/InputFields";
import Button from "../../../../components/button/Button";

export default function RequestForm({
    comment,
    userName,
    onCancel,
    onSend,
    cancelButtonName,
    sendButtonName,
    buttonLoading,
    isCancelButtonDisabled,
    isSendButtonDisabled,
    hideSendButton
}: any) {
    const [message, setMessage] = useState("");
    return (
        <>
            <div className="session-comment">
                <h6>Comment</h6>
                <p>{comment}</p>
            </div>
            <div className="session-comment-form">
                <h4>Not able to accept?</h4>
                <p>{`Tell ${userName} exactly when you need to meet, or ask for them to propose more options`}</p>
                <form>
                    <InputBox
                        type={"textarea"}
                        title={""}
                        placeholder="Insert Text"
                        inputProps={{ name: "message", onChange: (e: any) => setMessage(e.target.value) }}
                        error={""}
                    />
                    <div className="session-comment-form-buttons" style={{ marginTop: "20px" }}>
                        <Button
                            title={cancelButtonName}
                            clickAction={(e: any) => {
                                e.preventDefault();
                                onCancel(message);
                            }}
                            isOutlineStyle={true}
                            isloading={buttonLoading}
                            isDisabled={isCancelButtonDisabled}
                        />
                        <Button
                            title={sendButtonName}
                            clickAction={(e: any) => {
                                e.preventDefault();
                                onSend({ message });
                            }}
                            isOutlineStyle={true}
                            isloading={buttonLoading}
                            isDisabled={isSendButtonDisabled}
                            style={hideSendButton === true ? { visibility: 'hidden' } : {}}
                        />
                    </div>
                </form>
            </div>
        </>
    )


}