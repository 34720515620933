import React, { useState } from "react"
import { Grid, Chip } from "@material-ui/core"
import UnderLinedTabs from "../../../../../components/tabs/underline-tabs/UnderlinedTabs"
import { useDispatch } from "react-redux"
import BankAccountInfo from "./components/BankAccountInfo";
import PaymentHistory from "./components/PaymentHistory/PaymentHistory";


interface WrapperProps {
    user: any
}

const paymentTabsConstants = {
    BANKINFO: "Bank Account Info",
    DETAILS: "Payment Details"
}

const paymentTabs = {
    [paymentTabsConstants.BANKINFO]: "bankInfo",
    [paymentTabsConstants.DETAILS]: "details"
}

const PaymentsTutor = (props: WrapperProps): JSX.Element => {
    const [activePaymentTab, setActivePaymentTab] = useState(paymentTabsConstants.BANKINFO)
    return (
        <div className="my-page-content">
            <Grid item xl={8} lg={8} md={8} sm={12} xs={12} className="tab-heading">
                <UnderLinedTabs
                    labels={Object.keys(paymentTabs)}
                    selectedLabel={activePaymentTab}
                    onLabelClick={setActivePaymentTab}
                />
            </Grid>
            {activePaymentTab === paymentTabsConstants.BANKINFO &&
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="payment-tab-content">
                    <BankAccountInfo user={props.user} />
                </Grid>
            }
            {activePaymentTab === paymentTabsConstants.DETAILS &&
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="payment-tab-content">
                    <PaymentHistory />
                </Grid>
            }
        </div>
    )
}

export default PaymentsTutor
