import moment from "moment"
import subjectsAdvisingList from "../constant/subjectsAndAdvisingList"
import { MAX_PENDING_TIME_FOR_BOOKING } from "../constant/BookingConstant";
import { EPICBOARD_SESSION_STATUS_CODES } from "store/constant";
import { RESCHEDULE_SESSION_LIMIT_UNIT, STUDENT_RESCHEDULE_TIME_LIMIT, TUTOR_RESCHEDULE_TIME_LIMIT } from "utils/constant/SessionConstant";
export const orderBookingByDate = (bookings: any): any => {
    const orderedBookings = bookings.bookings.sort(
        (a: any, b: any): any => b.creationTime._seconds - a.creationTime._seconds,
    )
    return orderedBookings
}

export const orderSessionsByDate = (sessions: any): any => {
    const minDiff = 0
    const closestSessions: any = []
    const farthestSessions: any = []
    sessions.map((session: any): any => {
        const sessionEndTime = moment(session.startTime).add(session.sessionLength, "minutes").add(session.extensionTime || 0, "minutes");
        const diff = moment().diff(sessionEndTime)
        if (diff < minDiff) {
            closestSessions.push(session)
        } else farthestSessions.push(session)
    })
    closestSessions.sort((a: any, b: any) => moment(a.startTime).diff(moment(b.startTime)))
    farthestSessions.sort((a: any, b: any) => moment(a.startTime).diff(moment(b.startTime)))
    const orderedSessions: any = [...closestSessions, ...farthestSessions]

    return orderedSessions;
}

export const orderAndFilterUpcomingSessions = (sessions: Array<any>) => {
    const upcomingSessionStatuses = [
        EPICBOARD_SESSION_STATUS_CODES.CREATED,
        EPICBOARD_SESSION_STATUS_CODES.STARTED,
        EPICBOARD_SESSION_STATUS_CODES.ACTIVE
    ]
    const orderedSessions = orderSessionsByDate(sessions);
    const cleanedSessions = orderedSessions.filter((session: any) => {
        const sessionEndTime = moment(session.startTime).add(session.sessionLength, "minutes").add(session.extensionTime || 0, "minutes")
        return upcomingSessionStatuses.includes(session.status) && sessionEndTime.isAfter(moment())
    })
    //filter the sessions
    return cleanedSessions.filter((session: any) => {
        return ![EPICBOARD_SESSION_STATUS_CODES.CANCELLED].includes(session.status);
    });
}

export const selectSubject = (subjectId: any): any => {
    return subjectsAdvisingList.map((subject: any) => subject.id === subjectId && subject.name)
}

export const selectStartDateForAvailability = (currentDate: string = moment().utc().toISOString(), newDate: string): string => {
    if (!currentDate || currentDate === "" || currentDate === null) {
        return moment().utc().toISOString()
    }
    if (moment(currentDate).month() !== moment(newDate).month()) {
        return moment(newDate).utc().toISOString()
    }
    return currentDate
}

export const filterNotHeldBookings = (bookings: Array<any>, secondField = "_seconds"): Array<any> => {
    let currentDate = new Date();
    return bookings && bookings.filter(b => !!b).filter(b => {
        let requestedDate = new Date(b.creationTime[secondField] * 1000);
        let finalDateToRespond = new Date(requestedDate.getTime() + (MAX_PENDING_TIME_FOR_BOOKING * 1000));
        return currentDate < finalDateToRespond;
    });
}

export const getSessionLength = ({ sessionLength, extensionTime }: { sessionLength: number, extensionTime?: number }) => {
    return sessionLength + (extensionTime || 0);
}

export const isSessionReschedulable = (userType: string, startTime: string) => {
    let timeLimit = 5;
    if (userType === "STUDENT") {
        timeLimit = STUDENT_RESCHEDULE_TIME_LIMIT;
    } else {
        timeLimit = TUTOR_RESCHEDULE_TIME_LIMIT;
    }
    return moment(startTime).diff(moment().utc(), RESCHEDULE_SESSION_LIMIT_UNIT) > timeLimit;
}
