import { call, put, all, takeLatest, select } from "redux-saga/effects"
import {
    subscriptionConstant,
    authLogInConstant
} from "../constant"
import {
    cancelSubscriptionService
} from "../../utils/services/subscriptionServices"



function* cancelSubscription(props: any) {
    const {subscriptionId} = props.payload;
    try {
        const res = yield cancelSubscriptionService(subscriptionId)
        if(res.status === "success"){
            const userAuthData = yield select((state) => state.auth?.userAuthData)
            const { id, status, current_period_start, current_period_end, cancel_at, canceled_at } = res.data;
            const { metadata, nickname, unit_amount, unit_amount_decimal } = res.data.items.data[0].price;
            userAuthData.subscription = { id, status, current_period_start, current_period_end, cancel_at, canceled_at, metadata, nickname, unit_amount, unit_amount_decimal }
            yield put({ type: authLogInConstant.LOGIN_SUCCESS, user: userAuthData })
            yield put({ type: subscriptionConstant.CANCEL_SUBSCRIPTION_SUCCESS })
        }
    } catch (error) {
        console.log(error)
    }
}

export default all([
    takeLatest(subscriptionConstant.CANCEL_SUBSCRIPTION, cancelSubscription),
])
