import React, { useState } from "react"
import { useMediaQuery } from "react-responsive";
import Grid from "@material-ui/core/Grid"
import { CardView } from "../card/CardView"
import CalendarTodo from "./CalendarTodo"
import Button from "components/button/Button";


type todoItemType = { name: string; date: Date; link?: string }

export type tasksType = {
    overdue: todoItemType[]
    deadlines: todoItemType[]
    upcoming: todoItemType[]
}

interface Iprops {
    firstName: string
    lastName: string
    subtitle: string
    profilePicUrl: string
    tasks: tasksType
    isTutor?: false | boolean
    isVerified?: false | boolean,
}

const UserPanel = ({
    firstName,
    lastName,
    subtitle,
    profilePicUrl,
    tasks,
    isTutor,
    isVerified,
}: Iprops): JSX.Element => {
    const MAX_STRING_LENGTH = 50;
    if (subtitle && subtitle.length > MAX_STRING_LENGTH) {
        subtitle = subtitle.substr(0, MAX_STRING_LENGTH) + " ...";
    }
    const isDesktopOrLaptop = useMediaQuery({ query: '(min-device-width: 1224px)' });

    const tutorName = firstName + ' ' + lastName;
    const studentName = firstName
    const nameLength = 20;
    return (
        <>
            <div className="user-panel-container">
                <CardView className="calender-panel">
                    <img className="user-panel-pic" src={profilePicUrl} />
                    <div className="user-panel-head"
                        style={{
                            backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${profilePicUrl})`,
                            backgroundSize: "100%",
                        }}
                    >
                        <Grid container>
                            <Grid item xs={12}>
                                {isTutor ? (
                                    <p className="user-panel-head-name">
                                        {isDesktopOrLaptop ?
                                            tutorName.length > nameLength ? tutorName.substring(0, nameLength) + '...' : tutorName
                                            : tutorName
                                        }
                                    </p>
                                ) : (
                                    <p className="user-panel-head-name">
                                        <span>Hello, </span>
                                        {isDesktopOrLaptop ?
                                            studentName.length > nameLength ? studentName.substring(0, nameLength) + '...' : studentName
                                            : studentName
                                        }
                                    </p>
                                )}
                            </Grid>
                            <br />
                            <Grid item xs={12}>
                                <p className="user-panel-head-school">{subtitle}</p>
                            </Grid>
                            <br />
                            <Grid item xs={12}>
                                {isTutor && !isVerified ? (
                                    <p className="user-panel-head-background-check-indicator">
                                    </p>
                                ) : (
                                    <></>
                                )}
                            </Grid>
                        </Grid>
                    </div>
                    <div className="user-panel-body">
                        <CalendarTodo tasks={tasks} />
                    </div>
                </CardView>
            </div>
        </>
    )
}

export default UserPanel
