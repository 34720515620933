import { call, put, all, takeLatest } from "redux-saga/effects"
import { createTeacherThroughBackend } from "utils/services/userServices";
import {
    createTutor,
    fileUpload,
    createUserWithEmailAndPassword,
} from "../../utils/helper/firebase"
import { modalConstant, TutorSignUpConstant } from "../constant"

function* testUploadProfilePicture(pictureFile: any) {
    try {
        const profileData = {
            file: pictureFile,
            fileName: "profileImage.jpeg",
            filePath: `/users/test/public/`,
        }
        yield fileUpload(profileData);
    } catch (e) {
        throw new Error("Your profile image cannot be uploaded. There is something wrong with its format. Please, upload another one")
    }
}
function* testUploadResumeFile(resumeFile: any) {
    try {
        const resumeData = {
            file: resumeFile,
            fileName: "resume.pdf",
            filePath: `/users/test/public/`,
        }
        yield fileUpload(resumeData);

    } catch (e) {
        throw new Error("Your resume file cannot be uploaded. There is something wrong with it. Please, upload another one")
    }

}

function* tutorSignup(props: any) {
    const tutorBasicInformation = props.props.tutorInformation.options
    const userData: any = props.props;
    let userId: string | undefined = undefined;
    try {
        if (userData.tutorEducation.options.resumeFile !== "") {
            yield call(
                testUploadResumeFile,
                userData.tutorEducation.options.resumeFile.file
            )
        }


        yield call(
            testUploadProfilePicture,
            userData.tutorInformation.options.profilePicture
        )
        const createLogin = yield call(
            createUserWithEmailAndPassword,
            tutorBasicInformation.email,
            tutorBasicInformation.password,
        )
        const userAuth = createLogin.user
        userId = userAuth.uid;

        const pictureFile = userData.tutorInformation.options.profilePicture

        const profileData = {
            file: pictureFile,
            fileName: "profileImage.jpeg",
            filePath: `/users/${userId}/public/`,
        }

        const pictureDownloadURL = yield fileUpload(profileData).catch(console.error);
        const profilePic = {
            downloadURL: pictureDownloadURL || "",
            storagePath: `/users/${userId}/public/`,
        }


        yield createTutor(profilePic, userData, userAuth);
        yield put({
            type: modalConstant.MODAL_SHOW,
            modalInfo: {
                show: true,
                title: "Your account is almost set up!",
                text: "Please check your email to verify your account",
            },
        })
        yield put({ type: TutorSignUpConstant.TUTOR_SIGNUP_SUCCESS })
    } catch (error) {
        if (userId) { // if athentication is already created then fall back to backend solution
            try {
                const isSuccess = yield createTeacherThroughBackend(userId, userData);
                if (!isSuccess) {
                    throw new Error("Unknown error. Cannot setup your account right. Please try again.")
                }

                yield put({
                    type: modalConstant.MODAL_SHOW,
                    modalInfo: {
                        show: true,
                        title: "Your account is almost set up!",
                        text: "Please check your email to verify your account",
                    },
                })
                yield put({ type: TutorSignUpConstant.TUTOR_SIGNUP_SUCCESS })
            } catch (e) {
                //TODO: remove from the authentication
                yield put({
                    type: modalConstant.MODAL_SHOW,
                    modalInfo: {
                        show: true,
                        title: "Error",
                        text: e.toString(),
                    },
                })
                yield put({ type: TutorSignUpConstant.TUTOR_SIGNUP_FAILURE })
            }
        } else {
            yield put({
                type: modalConstant.MODAL_SHOW,
                modalInfo: {
                    show: true,
                    title: "Error",
                    text: error.toString(),
                },
            })
            yield put({ type: TutorSignUpConstant.TUTOR_SIGNUP_FAILURE })
        }
    }
}

export default all([takeLatest(TutorSignUpConstant.TUTOR_SIGNUP_REQUEST, tutorSignup)])
