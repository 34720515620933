import React from "react"
import ReactMarkdown from "react-markdown"
import { Grid, } from "@material-ui/core"
import { Container } from "reactstrap"
import { targetForRedirection } from "utils/helper/helper"



const LessonDescription = ({ description }: { description: string | undefined }): JSX.Element => {
    return (
        <>
            {description
                ?
                <Grid container>
                    <Grid style={{ maxWidth: "100%" }}>
                        <Container className="description margin-top" >
                            <ReactMarkdown className="text" source={description} linkTarget={targetForRedirection()} />
                        </Container>
                    </Grid>
                </Grid>
                : <></>
            }
        </>
    )
}

export default LessonDescription