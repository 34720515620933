import firebase from "../../utils/config/firebase"
import { bookingActionTypes, teachersActionTypes } from "./callableActions"

type createBookingRequestType = {
    teacherId: string;
    studentId: string;
    sessionRequests: [],
    studentStripeCardId: string;
    studentStripeCustomerId: string;
    subjects: Array<string>,
    cookie?:string
}
type getNonAvailableSlotsType = string[];
type createBookingExtensionRequest = {
    bookingId: string,
    extensionTime: number
    sessionId: string,
    studentStripeCardId: string,
    teacherName: string
}
type getBookingExtensionRequest = {
    bookingId: string,
    sessionId :string
}
export const createNewBookingRequest = async (requestData: createBookingRequestType) => {
    const bookingRequestCallable = firebase.functions().httpsCallable("booking")
    return bookingRequestCallable({
        actionType: bookingActionTypes.CREATE_BOOKING_REQUEST_N,
        ...requestData,
    }).then((data) => data).catch((err) => {})
}
export const createBookingExtensionRequest = async (requestData: createBookingExtensionRequest) => {
    const bookingRequestCallable = firebase.functions().httpsCallable("booking")
    return bookingRequestCallable({
        actionType: bookingActionTypes.CREATE_BOOKING_EXTENSION_REQUEST,
        ...requestData,
    })
        .then((data) => data)
        .catch((err) => {
        })
}
export const getBookingExtensionRequest = async (requestData: getBookingExtensionRequest) => {
    const bookingRequestCallable = firebase.functions().httpsCallable("booking")
    const sdata = bookingRequestCallable({
        actionType: bookingActionTypes.GET_BOOKING_EXTENSION_REQUEST,
        ...requestData,
    })
        .then((data) => data)
        .catch((err) => {
        })
    return sdata;
}

export const getBookingRequestData = async (id: any) => {
    const bookingRequestCallable = firebase.functions().httpsCallable("booking")
    const sdata = bookingRequestCallable({
        actionType: bookingActionTypes.GET_BOOKING_REQUEST_BY_ID,
        id,
    })
        .then((data) => data)
        .catch((err) => {
        })
    return sdata;
}

export const getBookingExtensionTime = async (requestData: getBookingExtensionRequest) => {
    const bookingRequestCallable = firebase.functions().httpsCallable("booking")
    return bookingRequestCallable({
        actionType: bookingActionTypes.GET_BOOKING_EXTENSION_TIME,
        ...requestData,
    })
        .then((data) =>
            data)
        .catch((err) => {
        })
}
export const getNonAvailableSlots = async (userIds: getNonAvailableSlotsType) => {
    const bookingRequestCallable = firebase.functions().httpsCallable("booking")
    const notAvailableSlots = Promise.all(userIds.map(async (userId) => {
        return await bookingRequestCallable({
            actionType: bookingActionTypes.GET_BOOKED_SLOTS,
            userId,
        }).then(data => data.data.slots).catch((err) => { })
    }));
    return (await notAvailableSlots).reduce((acc, curr) => [
        ...acc,
        ...curr
    ], [])
}
