import React, { Fragment, useEffect, useState } from "react"
import CancellationCalanderSvgIcon from "../../../../assets/images/svgIcons/CancellationCalendarSvgIcon"
import BackgroundCheckSvgIcon from "../../../../assets/images/svgIcons/BackgroundCheckSvgIcon"
import GraducationCapSvgIcon from "../../../../assets/images/svgIcons/GraduationCapSvgIcon"

interface Props {
    data: any[]
    education: string
    certification: string
    cancellationNotice: number
}

const OtherDetails = (props: Props): JSX.Element => {
    const { education, certification, cancellationNotice, data } = props
    const [schoolsdegrees,setSchoolsdegrees]=useState("");

    useEffect(() => {
        let schooldetail ="";
        data.map((schoolDetails) => {

            schooldetail = schoolDetails.degreeName + ", "  + schooldetail
            
        })
       const str = schooldetail.substring(0, schooldetail.length - 2);
        setSchoolsdegrees(str);
    }, [])

    return (
        <Fragment>
            <div className="rules-block">
                <div className="item">
                    <div className="icon">
                        <GraducationCapSvgIcon height="30px" width="30px" />
                    </div>
                    <div className="description">
                        <span className="description-title">Degree(s) / Certification(s) :</span>{" "}
                        <span>{schoolsdegrees}</span>
                        {certification.length === 0 ? "" : " with " + certification}
                    </div>
                </div>
                {/* <div className="item">
                    <div className="icon">
                        <CancellationCalanderSvgIcon height="30px" width="30px" />
                    </div>
                    <div className="description">
                        <span className="description-title">Cancelation :</span>{" "}
                        {cancellationNotice} hours notice required
                    </div>
                </div> */}
                {/* <div className="item">
                    <div className="icon">
                        <BackgroundCheckSvgIcon height="30px" width="30px" />
                    </div>
                    <div className="description">
                        <span className="description-title">Background check : </span>Not Verified
                    </div>
                </div> */}
            </div>
        </Fragment>
    )
}
export default OtherDetails
