import firebase from "firebase"
import { paymentActionTypes } from "./callableActions"
import axios from "axios"

enum ActionTypes {
    ADD_CARD_DETAILS = "ATTACH_CARD_TO_STRIPE_CUSTOMER",
    GET_PLANS = "GET_PLANS",
    SUBSCRIBE_PLAN = "SUBSCRIBE_PLAN",
    CAPTURE_BOOKING_EXTENSION_PAYMENT = "CAPTURE_BOOKING_EXTENSION_PAYMENT",
    GET_PAYMENT_HISTORY = "GET_PAYMENT_HISTORY",
    DELETE_CARD = "DELETE_CARD",
    GET_MY_FUTURE_EARNINGS = "GET_MY_FUTURE_EARNINGS",
    GET_NEXT_PERIOD_EARNINGS = "GET_NEXT_PERIOD_EARNINGS",
    GET_CURRENT_PERIOD_EARNINGS = "GET_CURRENT_PERIOD_EARNINGS",
    VERIFY_AND_GET_PROMO_DETAILS = "VERIFY_AND_GET_PROMO_DETAILS",
    FETCH_STRIPE_CONNECTED_ACCOUNT_URL = "FETCH_STRIPE_CONNECTED_ACCOUNT_URL",
    FETCH_STRIPE_CONNECTED_ACCOUNT = "FETCH_STRIPE_CONNECTED_ACCOUNT",
    CREATE_STRIPE_CONNECTED_ACCOUNT_IF_NOT_EXISTS = "CREATE_STRIPE_CONNECTED_ACCOUNT_IF_NOT_EXISTS",
}

/**
 * this function get next pay period earning.
 */
export const getMyNextPeriodEarnings = () => {
    const payments = firebase.functions().httpsCallable("payments")
    return payments({
        actionType: ActionTypes.GET_NEXT_PERIOD_EARNINGS,
    })
        .then((result) => result.data)
        .catch((error: any) => console.log(error))
}

/**
 * this function get current pay period earning.
 */
export const getMyCurrentPeriodEarnings = () => {
    const payments = firebase.functions().httpsCallable("payments")
    return payments({
        actionType: ActionTypes.GET_CURRENT_PERIOD_EARNINGS,
    })
        .then((result) => result.data)
        .catch((error: any) => console.log(error))
}

/**
 * this function get future earning uptill a specified
 * date time in future.
 * @param param0 till an ISO date time string e.g. 2021-04-15T00:00:00
 */
export const getMyFutureEarnings = ({ till }: { till: string }) => {
    const payments = firebase.functions().httpsCallable("payments")
    return payments({
        actionType: ActionTypes.GET_MY_FUTURE_EARNINGS,
        startedBefore: till,
    })
        .then((result) => result.data)
        .catch((error: any) => console.log(error))
}

export const addCardDetails = (token: any, customerId: any) => {
    const addCard = firebase.functions().httpsCallable("payments")
    const cardToken = token
    return addCard({ actionType: ActionTypes.ADD_CARD_DETAILS, cardToken, customerId })
        .then((result) => result)
        .catch((error: any) => console.log(error))
}

export const deleteCardDetails = (cardId: any, customerId: any, userId: any) => {
    const deleteCard = firebase.functions().httpsCallable("payments")
    return deleteCard({ actionType: ActionTypes.DELETE_CARD, cardId, customerId, userId })
        .then((result) => result)
        .catch((error: any) => console.log(error))
}

export const attachTutorToAccount = (tokenObject: any, data: any) => {
    const attachBankToTutorResponse = firebase.functions().httpsCallable("payments")
    return attachBankToTutorResponse({
        actionType: paymentActionTypes.ATTACH_BANK_TO_TUTOR,
        bankToken: tokenObject.token.id,
        countryCode: tokenObject.token.bank_account.country,
        currency: tokenObject.token.bank_account.currency,
        accountHolderType: tokenObject.token.bank_account.account_holder_type,
        ...data,
    })
}
export const getPlans = () => {
    return axios
        .get(`${process.env.REACT_APP_FIREBASE_PUBLIC_URL}/getLessonPlans`, {
            headers: {
                "Content-Type": "application/json",
            },
        })
        .then((result: any) => result.data.data.products)
        .catch((error: any) => console.log(error))
}

export const verifyPromoCodeDetails = (promoCode: string, product: string) => {
    const getPromoCodeDetails = firebase.functions().httpsCallable("promoCode")
    return getPromoCodeDetails({
        actionType: ActionTypes.VERIFY_AND_GET_PROMO_DETAILS,
        promoCode: promoCode,
        productName: product,
    })
        .then((result) => result.data)
        .catch((error: any) => console.log(error))

}

export const subscribePlan = (props: any) => {
    const getLessonPlans = firebase.functions().httpsCallable("subscription")
    return getLessonPlans({
        actionType: ActionTypes.SUBSCRIBE_PLAN,
        paymentMethod: props.card,
        productId: props.product,
        priceId: props.price,
        renew: props.renew,
        promoCodeId: props.promoCodeId,
    })
        .then((result) => result.data)
        .catch((error: any) => console.log(error))
}
export const chargeHeldPayment = (bookingId: any, sessionId: any): any => {
    const bookings = firebase.functions().httpsCallable("payments")
    return bookings({
        actionType: ActionTypes.CAPTURE_BOOKING_EXTENSION_PAYMENT,
        bookingId,
        sessionId,
    })
        .then((result: any) => {
            return result
        })
        .catch((err: any) => {
            console.log("Error updating the booking :", err.message)
        })
}

export const getPaymentHistory = (data: any): any => {
    const paymentHistory = firebase.functions().httpsCallable("payments")
    return paymentHistory({ actionType: ActionTypes.GET_PAYMENT_HISTORY, ...data })
        .then((result: any) => {
            return result.data
        })
        .catch((err: any) => {
            console.log("Error updating the booking :", err.message)
        })
}

export const getStripeAccountLinkUrl = async (): Promise<any> => {
    const paymentHistory = firebase.functions().httpsCallable("payments")
    return await paymentHistory({
        actionType: ActionTypes.FETCH_STRIPE_CONNECTED_ACCOUNT_URL,
    }).then(({ data }) => {
        if (data.status === "success") {
            return data.data
        }
        throw {
            message: data.message || "An unknown error has occurred. Please try again",
            code: data.code,
        }
    })
}

export const getStripeConnectedAccount = async (): Promise<any> => {
    const paymentHistory = firebase.functions().httpsCallable("payments")
    return await paymentHistory({ actionType: ActionTypes.FETCH_STRIPE_CONNECTED_ACCOUNT }).then(
        ({ data }) => {
            if (data.status === "success") {
                return data.data
            }
            throw {
                message: data.message || "An unknown error has occurred. Please try again",
                code: data.code,
            }
        },
    )
}

export const createStripeConnectedAccount = async (countryISOCode: string): Promise<any> => {
    const paymentHistory = firebase.functions().httpsCallable("payments")
    return await paymentHistory({
        actionType: ActionTypes.CREATE_STRIPE_CONNECTED_ACCOUNT_IF_NOT_EXISTS,
        country: countryISOCode,
    }).then(({ data }) => {
        if (data.status === "success") {
            return data.data
        }
        throw {
            message: data.message || "An unknown error has occurred. Please try again",
            code: data.code,
        }
    })
}
