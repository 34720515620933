/* eslint-disable @typescript-eslint/no-empty-function */
import React from "react"
import IconsRejectionLink from "../../assets/images/icons/IconsRejectionLink.png"

interface Iprops {
    student: string
    tutor: string
    size: string
}
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const StudentTutorRejectionAvatar = ({ student, tutor, size }: Iprops) => {
    return (
        <div className="avatarsstr d-flex abc">
            <img
                src={tutor}
                className="img-rounded"
                style={{ width: size+"px", height: size+"px", objectFit: "cover",backgroundColor:"white" }}
            />
            <p className="icon">
                <img src={IconsRejectionLink} alt="rejection_icon" />
            </p>
            <img
                src={student}
                className="img-rounded"
                style={{ width: size+"px", height: size+"px",backgroundColor:"white"}}
            />
        </div>
    )
}

export default StudentTutorRejectionAvatar
