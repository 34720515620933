import React from "react"

import Button from "../../../components/button/Button"
import { backwardIcon, forwardIcon } from "../../icons/Icons"

interface Props {
    previousClick: any
    nextClick: any
    previousLoading?: any
    nextLoading?: any
    previousTitle?: string
    nextTitle?: string
}

const NavigationButtons = ({
    previousClick,
    nextClick,
    nextTitle,
    previousTitle,
    previousLoading,
    nextLoading,
}: Props): JSX.Element => {
    return (
        <div className="button-container" >
            <Button
                title={previousTitle ? previousTitle : "Previous"}
                clickAction={previousClick}
                isDisabled={false}
                isOutlineStyle={true}
                leftSideIcon={backwardIcon}
                isloading={previousLoading}
            />

            <Button
                title={nextTitle ? nextTitle : "Next"}
                clickAction={nextClick}
                isDisabled={false}
                isOutlineStyle={false}
                rightSideIcon={forwardIcon}
                isloading={nextLoading}
            />
        </div>
    )
}

export default NavigationButtons
