import React from "react"
import interfaceConstant from "../../../utils/constant/interfaceConstant"

class AppleHalfSvgIcon extends React.PureComponent<interfaceConstant.SvgIconProps> {
    render(): JSX.Element {
        const { width, height, iconColor, ...otherProps } = this.props
        return (
            <svg width={width} height={height} viewBox="7 5.5 17 21" {...otherProps}>
                <defs>
                    <path
                        d="M8.018 6.608c7.696-4.27 8.006 2.707 8.018 3.205v.413c-.06 5.588-2.508 8.322-4.008 9.618-1.534 1.324-2.252 1.23-3.007.77-.467-.288-.395-.463-1.002-.463-.273 0-.506.03-1.002.462-.629.547-1.566.405-3.03-.76C2.41 18.597-.05 15.814 0 9.994c0 0 .184-7.483 8.017-3.386zm3.766-2.933c-2.311.755-3.724.582-4.373 1.553-.056.085.05-1.806 3.124-3.106.076-.032-1.349.105-2.81 1.553 0 0-.353-2.49 2.498-3.106 2.04-.441 3.28-.239 3.28-.565 0-.132.592 2.916-1.72 3.67z"
                        id="prefix__a"
                    />
                </defs>
                <g transform="translate(8 6)" fill="none" fillRule="evenodd">
                    <mask id="prefix__b" fill="#fff">
                        <use xlinkHref="#prefix__a" />
                    </mask>
                    <use fill={iconColor} xlinkHref="#prefix__a" />
                    <path fill="#DDDEE8" mask="url(#prefix__b)" d="M7 0h10v21H8V5.482H7z" />
                </g>
            </svg>
        )
    }
}

export default AppleHalfSvgIcon
