/* eslint-disable @typescript-eslint/no-empty-function */
import React from "react";
import Modal from "components/modal_/Modal";
import StudentTutorAvatar from "components/student-tutor-avatar/StudentTutorAvatar";
import TutorAvatar from "components/tutor-avatar/TutorAvatar";
import StudentBookingRequest from "components/student-booking-request/StudentBookingRequest";
import moment from "moment";
import Button from "components/button/Button";
import {Note} from "components/tips/Note";
import { useIsPhoneOrTablet } from "utils/hooks/useIsPhoneOrTablet";
import { useTranslation } from "react-i18next";

interface TutorStdRapidBookPendingProps {

    TurnOffRapidBook:()=> void,
    pendingRapidBookSession :any
    onClose: React.Dispatch<React.SetStateAction<boolean>>,
    OnCancelRequest :()=> void,
}

export function TutorStdRapidBookPending(props: TutorStdRapidBookPendingProps) {
    const isPhoneOrTablet = useIsPhoneOrTablet();
    const {pendingRapidBookSession ,OnCancelRequest} =props;
    const { t } = useTranslation();
    const length =pendingRapidBookSession?.length;
    const now = moment(new Date()); 
    const end = moment (pendingRapidBookSession[0].slots[0].startTime);
    const sessionStartTime =  end.diff(now, "minutes");
    return (
        <div className="accepted-requests-modal">
             <Modal
                visible={true}
                onExit={()=>{props.onClose(false)}}
             >
                <div  className= {(length>1)?"model_tutoracceptrequest tutorRapidBookMulitple" : "model_tutoracceptrequest tutorRapidBook"}>
                 <div className ="singlePendingRapidBook">
             {(length>1) ?
             (<div>
                <TutorAvatar
                size="100"
                tutor={pendingRapidBookSession[0].teacherProfilePicture }
                />
                <h2>{t("you-have-multiple")}<span>{t("pending")}</span>{t("rapid-book-requests")}</h2>  
              <div className="scroll">  
                  {props.pendingRapidBookSession.map(
                    (item: any, key: number) =>
                            <StudentBookingRequest 
                                student={item.studentProfilePicture}
                                studentName={item.studentName}
                                size="60"
                                startTime={item.slots[0].startTime}
                                endTime={item.slots[0].endTime} 
                            />      
                         )
                }
                </div> 
              </div>)
             :(
                <>
                    <div className="imgs_div">
                        <StudentTutorAvatar
                            size="100"
                            student={pendingRapidBookSession[0].studentProfilePicture}
                            tutor={pendingRapidBookSession[0].teacherProfilePicture}
                        />
                    </div>
                    
                    <h2>{t("you-have-a")}<span>{t("pending")}</span> {t("rapid-book-request")}</h2>
                    <h4> <span>{t("starting-in")} {sessionStartTime} min:</span><br/>
                     {"Today, "}{moment(pendingRapidBookSession[0].slots[0].startTime).format("MMMM DD, YYYY")}<br/>
                      {moment(pendingRapidBookSession[0].slots[0].startTime).format("hh:mm A")} -  {moment(pendingRapidBookSession[0].slots[0].endTime).format("hh:mm A")} </h4>
                </>
                      )  
            }
            <Button className="rapidBookButton1" title={(length>1)? t("turn-off-rapid-book" ): t("turn-off-rapid-book-request" )} clickAction={()=>{props.TurnOffRapidBook()}} />
            <Button  isOutlineStyle={true}  className="rapidBookButton2" title={t("keep-rapid-book-on")} clickAction={()=>{props.onClose(false)}} />
            <h4> <u><span onClick={()=>{OnCancelRequest()}}>{t("cancel-the")} {(length>1)? "requests":"request"} </span></u></h4>
            </div>
            </div>
    </Modal>
    </div>
    )
}
