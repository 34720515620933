import React, { memo, useRef, useState } from "react"
import { faTimes, faAngleDown } from "@fortawesome/free-solid-svg-icons"
import { useOutsideAlerter } from "../../utils/hooks/useOutsideAlerter"
import { Icon } from "../icons/Icons"

import visaLogo from "../../assets/images/card-logos/visa_logo.png"
import jcb from "../../assets/images/card-logos/jcb_logo.png"
import discover from "../../assets/images/card-logos/discover_logo.jpg"
import amex from "../../assets/images/card-logos/american_express_logo.jpg"
import masterCard from "../../assets/images/card-logos/mastercard_logo.png"
import unionPay from "../../assets/images/card-logos/unionpay.svg"
import dinersCard from "../../assets/images/card-logos/diners_card.svg"
import { useTranslation } from "react-i18next"
import colors from "assets/colors"
interface Props {
    title: string
    showDropdown: boolean
    optionsList: any[]
    closeDropDownAction: Function
    fieldDetails: any
    placeholder?: string
    setProps: Function
    onBlurAction?: Function
    error?: boolean | true
    isDisabled?: boolean
    setAddPaymentModalOpen: Function
}

const ImageDropDown = ({
    title,
    showDropdown,
    optionsList,
    closeDropDownAction,
    fieldDetails,
    setProps,
    error,
    isDisabled,
    onBlurAction,
    placeholder,
    setAddPaymentModalOpen
}: Props): JSX.Element => {
    const { t } = useTranslation();
    const addOption = (option: any): void => {
        setProps({
            value: {
                ...option,
            },
        })
        closeDropDownAction(false)
    }
    const handleAddNew = (): void => {
        closeDropDownAction(false)
    }

    const wrapperRef = useRef(null)
    useOutsideAlerter(wrapperRef, closeDropDownAction)

    const iconProps = {
        type: "text",
        style: { color: colors.darkMango, zIndex: 0, pointerEvents: "none" },
        icon: showDropdown ? faTimes : faAngleDown,
    }

    const getCardNumber = (last4: any) => {
        return [...Array(12)].reduce((acc) => `${acc}*`, "") + last4
    }

    const getCardBrandName = (name: string) => {
        switch (name) {
            case "Visa":
                return <img src={visaLogo} width="5%" />
            case "MasterCard":
                return <img src={masterCard} />
            case "American Express":
                return <img src={amex} width="5%" />
            case "Discover":
                return <img src={discover} width="5%" />
            case "JCB":
                return <img src={jcb} width="5%" />
            case "Diners Club":
                return <img src={dinersCard} width="5%" />
            case "UnionPay":
                return <img src={unionPay} width="5%" />

            default:
                return null
        }
    }
    return (
        <React.Fragment>
            <div className="multi-select-drop-down image-dropdown" ref={wrapperRef}>
                <div className="custom-input">
                    <label className="input-label" htmlFor={title}>
                        {title}
                    </label>
                    <div className="input-container">
                        <div

                            className={`card-select ${isDisabled ? "disabled" : ""}`}
                            onClick={(): void =>
                                isDisabled ? undefined : closeDropDownAction(!showDropdown)
                            }
                            onBlur={(): any => onBlurAction}
                        >
                            {
                                fieldDetails.value ?
                                    (
                                        <>
                                            {getCardBrandName(fieldDetails.value.brand)}
                                            <span>{getCardNumber(fieldDetails.value.last4)}</span>
                                        </>
                                    ) :
                                    (<span>{placeholder}</span>)
                            }
                        </div>
                        {optionsList.length > 0 ? (
                            <Icon className="input-icon" {...iconProps} />
                        ) : null}
                        {error ? <i className={`error-box`}></i> : null}
                    </div>
                    {error && <div className="error-label">{fieldDetails.error}</div>}
                </div>
                {showDropdown && (
                    <div className="dropdown-box-continer">
                        <div className={`dropdown-box paymentdropdown`} style={{ marginTop: "-2.2rem" }}>
                            {optionsList.map((option, i) => (
                                <div
                                    key={`${i}+z+${2 * i}`}
                                    className={`dropdown-item`}
                                    onClick={() => {
                                        addOption(option)
                                    }}
                                >
                                    <div className="card-select">
                                        {getCardBrandName(option.brand)}{" "}
                                        <span>{getCardNumber(option.last4)}</span>
                                    </div>
                                </div>
                            ))}
                            <span
                                className="navigation addnewcard addnewCardIndropdown"
                                onClick={() => {
                                    setAddPaymentModalOpen(true)
                                    closeDropDownAction(!showDropdown)
                                }}
                            >
                                {t('Add-new-card')}
                            </span>
                        </div>
                    </div>
                )}
            </div>
        </React.Fragment>
    )
}

export default memo(ImageDropDown)
