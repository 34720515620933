import firebase from "firebase"

enum ActionTypes {
    GET_ALL_LESSONS = "GET_ALL_LESSONS",
    GET_COLLEGE_ORGANIZER_PDF_LINK ="GET_COLLEGE_ORGANIZER_PDF_LINK"
}

export const getLessons = () => {
    const getLessons = firebase.functions().httpsCallable("lessons");
    return getLessons({ actionType: ActionTypes.GET_ALL_LESSONS })
        .then((result: any) => result.data.items).catch((err: any) => err);
}

export const collegeOrgaizerPdf = () => {
    const getLessons = firebase.functions().httpsCallable("lessons");
    return getLessons({ actionType: ActionTypes.GET_COLLEGE_ORGANIZER_PDF_LINK })
        .then((result: any) => result.data.data).catch((err: any) => err);
}
