import React, { Component, createRef } from "react"
import Button from "../button/Button"
import { PlayIcon, PauseIcon } from "../icons/Icons"

class VideoPlayer extends Component<{ source: string }> {
    videoRef = createRef<HTMLVideoElement>()

    setup = () => {
        if (document.getElementsByClassName("video-player-controls")[0]) {
            document
                .getElementsByClassName("video-player-controls")[0]
                .children[1].children[0].children[0].children[0].setAttribute(
                    "style",
                    "transform: translate(7px, 0px);",
                )
        }
    }

    playPause = () => {
        this.setIcon()
        if (this.videoRef.current?.paused) this.videoRef.current.play().catch(console.error)
        else this.videoRef.current?.pause()
    }

    setIcon = () => {
        if (this.videoRef.current?.paused) {
            document
                .getElementsByClassName("video-player-controls")[0]
                .children[1].setAttribute("hidden", "true")
            document
                .getElementsByClassName("video-player-controls")[0]
                .children[0].removeAttribute("hidden")
        } else {
            document
                .getElementsByClassName("video-player-controls")[0]
                .children[0].setAttribute("hidden", "true")
            document
                .getElementsByClassName("video-player-controls")[0]
                .children[1].removeAttribute("hidden")
        }
    }

    hideControls = () => {
        if (!this.videoRef.current?.paused) {
            document
                .getElementsByClassName("video-player-controls")[0]
                .setAttribute("hidden", "true")
        }
    }

    showControls = () => {
        document.getElementsByClassName("video-player-controls")[0].removeAttribute("hidden")
    }

    render() {
        const { source } = this.props
        this.setup()
        return (
            <div className="video-player">
                <div className="video-player-controls">
                    <Button leftSideIcon={PauseIcon} clickAction={this.playPause} />
                    <Button leftSideIcon={PlayIcon} clickAction={this.playPause} />
                </div>
                <video
                    ref={this.videoRef}
                    onMouseMove={this.showControls}
                    onMouseOut={this.hideControls}
                    src={source}
                    onClick={this.playPause}
                ></video>
            </div>
        )
    }
}

export default VideoPlayer
