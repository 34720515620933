import React from "react"
import { targetForRedirection } from "utils/helper/helper";
import { externalLinks } from "../../../../../../utils/constant/externalLinks";
const TermsandConditions = (): JSX.Element => {
    return (
        <>
         <div className="tcd-footer">
                <p className="tcd-p">
                    <span className="checkbox-label1">
                        {'By submitting, I agree to the'}
                        {" "}
                        <a
                            className="navigation"
                            href={externalLinks.TERMS_OF_USE}
                            target={targetForRedirection()}
                            rel="noopener noreferrer"
                        >
                            {'Terms and Conditions'}
                        </a>
                        {" and "}
                        <a
                            className="navigation"
                            href={externalLinks.CANCELLATION_POLICY}
                            target={targetForRedirection()}
                            rel="noopener noreferrer"
                        >
                            {'Cancellation Policy'}
                        </a>
                        {". "}
                        {'I also agree to pay the total amount shown'}
                        .
                    </span>
    
                </p>


            </div>

        </>
    )
}

export default TermsandConditions