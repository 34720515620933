import firebase from "firebase"
import { StepHandlerType, StepsAndHandlersMapType } from ",,/,,/,,/../../../utils/hooks/useStepwiseExecution";
import { Stripe, StripeCardElement, TokenResult } from "@stripe/stripe-js";
import { SharedState } from "./AllStepsAndHandlers";

const createCardToken: StepHandlerType = async (data: any, sharedState: SharedState, setSharedState) => {
    const { card }: { card?: StripeCardElement } = sharedState.formData;
    const stripe: Stripe | undefined = sharedState.stripe;
    if (card && stripe) {
        try {
            const { token, error }: TokenResult = await stripe.createToken(card);
            if (error) {
                throw error;
            }
            return {
                type: "success",
                data: {
                    token: token?.id,
                    card: token?.card
                }
            };
        } catch (e) {
            throw {
                type: "error",
                message: e.message,
                code: e.code
            };
        }
    }
}

const addCardDetails: StepHandlerType = async (data: any, sharedState: SharedState, setSharedState) => {
    try {
        const addCard = firebase.functions().httpsCallable("payments");
        const { token: cardToken, card } = data.data;
        const { card: resultCard, message: stripeError }: { card?: any, message: string | { raw: { message: string }, code: string } } = await addCard({ actionType: "ATTACH_CARD_TO_STRIPE_CUSTOMER", cardToken, customerId: sharedState.customer?.id }).then(({ data }) => data);
        if (!resultCard && typeof stripeError !== "string") {
            throw {
                type: "error",
                message: stripeError?.raw?.message || stripeError?.code
            };
        }
        setSharedState((ps: any) => {
            return {
                ...ps,
                card: card || resultCard
            };
        });
        return {
            type: "success",
            data: resultCard,
            message: typeof stripeError === "string" && stripeError
        }
    } catch (e) {
        throw {
            type: "error",
            code: e.code,
            message: e.message
        }
    }
}

const subscribePlan: StepHandlerType = async (data: any, sharedState: SharedState, setSharedState) => {
    try {
        const getLessonPlans = firebase.functions().httpsCallable("subscription");
        const result = await getLessonPlans({
            "actionType": "SUBSCRIBE_PLAN",
            "paymentMethod": sharedState.card.id,
            "priceId": sharedState.formData.price,
            "renew": true,
            "promoCodeId": sharedState?.promoCodeId
        }).then(({ data }) => data);
        return {
            type: "success",
            data: result
        }
    } catch (e) {
        throw {
            type: "error",
            code: e.code,
            message: e.message
        }
    }
}

const isSubscriptionSuccess: StepHandlerType = async (data: any, sharedState: SharedState, setSharedState) => {
    let returnData = {
        type: "success",
        message: "Subscription successful"
    };
    if (data.data.code === "02") {
        returnData.message = "Already subscribed"
    } else if (data.data.code === "41") {
        returnData.type = "error";
        returnData.message = data.data.error?.message || "Unknow error has occurred. Please, refresh your page and try again";
    }
    return returnData;
}



/**
 * all these functions depend on each, so you can just
 * random change their order unless you are sure
 * what you are doing
 */
export const stepsAndHandlers = [
    [
        createCardToken,
        addCardDetails
    ], //second step, store card against the customer/user
    [
        subscribePlan
    ], // third step, subscribe to the plan
    [
        isSubscriptionSuccess
    ]
] as StepsAndHandlersMapType;

/**
 * this object contains messages of the above mentioned
 * steps
 */
export const stepsAndMessages = [
    "Verifying card details ...",
    "Subscribing to lessons ...",
    "Verify subscription ..."
];