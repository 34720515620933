import { lessonsConstant } from "../constant"

const initialState = {
    isError: false,
    isloading: false,
    errorMessage: "",
    lessons: [],
    lessonIndex: -1,
}

const lessonReducer = (state = initialState, action: any): object => {
    switch (action.type) {
        case lessonsConstant.LESSONS_REQUEST:
            return {
                isloading: true
            }
        case lessonsConstant.LESSONS_SUCCESS:
            return {
                isloading: false,
                lessons: action.payload
            }
        case lessonsConstant.LESSONS_FIND_REQUEST:
            return {
                ...state,
                isloading: true
            }
        case lessonsConstant.FIND_LESSONS_SUCCESS:
            return {
                ...state,
                isloading: false,
                lessonsLength: action.payload.lessons.length,
                lesson: action.payload.lesson,
                lessonIndex: action.payload.lessonIndex,
                lessons: action.payload.lessons
            }
        case lessonsConstant.NAVIGATE_TO_LESSON:
            return {
                ...state,
                lessonIndex: action.lessonIndex,
            }
        default:
            return state
    }
}

export default lessonReducer
