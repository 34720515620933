import React from "react"
import interfaceConstant from "../../../utils/constant/interfaceConstant"

class SortAscendSvgIcon extends React.PureComponent<interfaceConstant.SvgIconProps> {
    render(): JSX.Element {
        const { width, height, iconColor, ...otherProps } = this.props
        return (
            <svg width={width} height={height} viewBox="0 0 24 24" {...otherProps}>
                <g fill="none" fillRule="evenodd">
                    <path d="M0 0h23.998v23.998H0z" />
                    <path d="M0 0h23.998v23.998H0z" />
                    <path
                        d="M5.30270769,4 C5.66909558,4 5.97105821,4.32725839 5.97105821,4.72433901 L5.97105821,16.8051837 L8.44528303,13.7825741 C8.71183636,13.4936912 9.11363355,13.4553156 9.38018689,13.7095539 C9.64674023,13.9984367 9.68214953,14.4338928 9.44756292,14.7227757 L5.80384764,19.1423629 C5.53090095,19.5079969 5.04844925,19.4525655 4.83353447,19.1423629 L1.15736066,14.7227757 C0.922774076,14.4370908 0.954740784,13.963792 1.22473669,13.7095539 L1.22522848,13.7095539 C1.49178182,13.4553156 1.92554574,13.4936912 2.16013235,13.7825741 L4.63435716,16.8051837 L4.63435716,4.72433901 C4.63435716,4.32725839 4.9363198,4 5.30270769,4 Z M17.9484277,16.6792453 C18.2061606,16.6792453 18.4150943,16.9688724 18.4150943,17.3261456 C18.4150943,17.6834187 18.2061606,17.9730458 17.9484277,17.9730458 L11.881761,17.9730458 C11.6240281,17.9730458 11.4150943,17.6834187 11.4150943,17.3261456 C11.4150943,16.9688724 11.6240281,16.6792453 11.881761,16.6792453 L17.9484277,16.6792453 Z M18.881761,13.0566038 C19.1763128,13.0566038 19.4150943,13.3462309 19.4150943,13.703504 C19.4150943,14.0607772 19.1763128,14.3504043 18.881761,14.3504043 L11.9484277,14.3504043 C11.6538758,14.3504043 11.4150943,14.0607772 11.4150943,13.703504 C11.4150943,13.3462309 11.6538758,13.0566038 11.9484277,13.0566038 L18.881761,13.0566038 Z M18.881761,9.43396227 C19.1763128,9.43396227 19.4150943,9.72358939 19.4150943,10.0808625 C19.4150943,10.4381357 19.1763128,10.7277628 18.881761,10.7277628 L11.9484277,10.7277628 C11.6538758,10.7277628 11.4150943,10.4381357 11.4150943,10.0808625 C11.4150943,9.72358939 11.6538758,9.43396227 11.9484277,9.43396227 L18.881761,9.43396227 Z M21.8912848,5.35849057 C22.1805768,5.35849057 22.4150943,5.64811768 22.4150943,6.00539084 C22.4150943,6.36266399 22.1805768,6.6522911 21.8912848,6.6522911 L11.9389039,6.6522911 C11.6496118,6.6522911 11.4150943,6.36266399 11.4150943,6.00539084 C11.4150943,5.64811768 11.6496118,5.35849057 11.9389039,5.35849057 L21.8912848,5.35849057 Z"
                        fill={iconColor}
                    />
                </g>
            </svg>
        )
    }
}

export default SortAscendSvgIcon
