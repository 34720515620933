import TutorDashboardPage from "../pages/home/dashboard/TutorDashboardPage"
import FolderPage from "../pages/home/folder/FolderPage"
import MessagePage from "../pages/home/message/_MessagePage"
import HomeSvgIcon from "../assets/images/svgIcons/HomeSvgIcon"
import CalendarSvgIcon from "../assets/images/svgIcons/CalendarSvgIcon"
import HelpPage from "../pages/home/help/HelpPage"
import TutorProfilePage from "../pages/home/my-account/TutorProfilePage"
import PersonSvgIcon from "../assets/images/svgIcons/PersonSvgIcon"
import HelpSvgIcon from "../assets/images/svgIcons/HelpSvgIcon"
import MySessionPage from "../pages/home/my-session/MySessionPage"
import interfaceConstant from "../utils/constant/interfaceConstant"
import { applicationConstant } from "../utils/constant/applicationConstant"
import MessageIcon from "components/message-icon/messageIcon"

const routeUrl: interfaceConstant.IrouteUrl = applicationConstant.routeUrl
const PanelRouteForTutor: interfaceConstant.IpanelRoute[] = [
    {
        path: routeUrl.DASHBOARD_PAGE,
        name: "Dashboard",
        icon: HomeSvgIcon,
        component: TutorDashboardPage,
        layout: routeUrl.PANEL_ROUTE,
    },
    {
        path: routeUrl.SESSION_PAGE,
        name: "My Sessions",
        icon: CalendarSvgIcon,
        component: MySessionPage,
        layout: routeUrl.PANEL_ROUTE,
    },
    {
        path: routeUrl.MESSAGE_PAGE,
        name: "Message Center",
        icon: MessageIcon,
        component: MessagePage,
        layout: routeUrl.PANEL_ROUTE,
    },
    {
        path: routeUrl.ACCOUNT_PAGE,
        name: "My Profile",
        icon: PersonSvgIcon,
        component: TutorProfilePage,
        layout: routeUrl.PANEL_ROUTE,
    },
    {
        path: routeUrl.HELP_PAGE,
        name: "Support",
        icon: HelpSvgIcon,
        component: HelpPage,
        layout: routeUrl.PANEL_ROUTE,
    },
]

export default PanelRouteForTutor
