import React, { PureComponent } from "react"
import ClipLoader from "react-spinners/ClipLoader"
import { css } from "@emotion/core"
import colors from "../../assets/colors"

const loaderStyles = css`
    display: block;
    margin: 0 auto;
`

export default class LoaderSpinner extends PureComponent {
    render(): JSX.Element {
        return (
            <div>
                <ClipLoader css={loaderStyles} size={70} color={colors.darkMango} loading={true} />
            </div>
        )
    }
}
