import {
    stepsAndHandlers as userStepsHandlers,
    stepsAndMessages as userStepMessages
} from "./UserStepHandlers";
import {
    stepsAndHandlers as paymentStepsHandlers,
    stepsAndMessages as paymentStepMessages,
} from "./CardStepHandlers";
import {
    stepsAndHandlers as redirectionStepsHandlers,
    stepsAndMessages as redirectionStepMessages,
} from "./RedirectionStepHandlers";

import { Stripe, StripeCardElement } from "@stripe/stripe-js";
import firebase from "firebase";

export type SharedState = {
    authUser?: firebase.User
    formData: {
        firstName: string,
        lastName: string,
        email: string,
        confirmEmail: string,
        password: string,
        card?: StripeCardElement,
        price?: any
    },
    stripe?: Stripe,
    card?: any,
    customer?: any,
    customToken?: string,
    promoCodeId?: string | undefined
};

export const stepHandlers = [
    [],//just empty step
    ...userStepsHandlers,
    ...paymentStepsHandlers,
    ...redirectionStepsHandlers
];

export const stepMessages = [
    "", //label for empty step
    ...userStepMessages,
    ...paymentStepMessages,
    ...redirectionStepMessages
];