import React, { PureComponent } from "react"
import { title } from "process"
import "./switch-tab.scss"

interface Props {
    title?: string
    counts?: any
    options: string[]
    selectedOption: any
    shouldDisplayCount?: any
    onTabClick(label: string): void
}

export default class SwitchTabComponent extends PureComponent<Props> {
    onOptionPress = (option: string): void => {
        const { onTabClick } = this.props
        onTabClick(option)
    }

    renderOption = (option: string, optionIndex: number): JSX.Element => {
        const { selectedOption, counts, shouldDisplayCount } = this.props
        return (
            <div
                key={option}
                className={`${option === selectedOption ? "active-tab" : "tab"}`}
                onClick={() => this.onOptionPress(option)}
                style={option==="Tutoring" || option==="Advising"? {justifyContent: "center",
                textAlign: "center",
                fontSize:"12px",
                width:"175px"}: {
                    
                    paddingLeft: "15px",
                    paddingRight: "15px",
                    justifyContent: "center",
                    textAlign: "center",
                    fontSize:"12px",
                    width:"175px"
                    
                }}
            >
                {(option === "Now" || option ==="Later")? this.renderCustomizedOptions(option ,selectedOption) : option} {shouldDisplayCount && shouldDisplayCount[option] === true ? <span>{counts && counts[option]}</span> : ""}
            </div>
        )
    }

    renderCustomizedOptions = (option :string ,selectedOption :string) => {
        if(option=== "Now")
        {
            return ( 
            <div className="nowTab"> 
            <div style ={option != selectedOption ?  {color :"#262ffa", fontSize :"16px"}: { fontSize :"16px"}}> {"Rapid Book"} </div>
            <div style ={option != selectedOption ?  {color :"#262ffa", fontSize :"13px"}: {fontSize :"13px"}}> {"Start Session "}<span   style ={option != selectedOption ?  {color :"#ff5924"} :{ fontSize :"13px"}}> {"NOW"} </span> </div>
            </div>
            ) 
        }
        else{
            return (
            <div className ="LaterTab">
            <div style ={option != selectedOption ?  {color :"#262ffa", fontSize :"15px"}:{}}> {"Book a Session "}</div>
            <div style ={option != selectedOption ?  {color :"#262ffa",fontSize :"15px"}:{}}> {"for Later"}</div>
            </div>)
        }
    }
    renderSwitchOptions = () => {
        const { options } = this.props
        return options.map((option: string, index: number) => this.renderOption(option, index))
    }
    render() {
        const { title } = this.props
        return (
            <div className="switch-tabs">
                {title && <label className="input-label">{title}</label>}
                <div className="tabs-container">{this.renderSwitchOptions()}</div>
            </div>
        )
    }
}
