import React, { useEffect, useState } from "react"
import { CardView } from "../../card/CardView"
import Button from "../../button/Button"
import { useMediaQuery } from "react-responsive"
import { SERVER_URL } from "../../../utils/constant/api/api-config"
import {
    DateFormats,
    formatDate
} from "../../../utils/helper/date-utils"
import useSessionAndZoom from "utils/hooks/useSessionAndZoom"
import { isSessionReschedulable } from "utils/helper/session-utils"
import { useSelector } from "react-redux"
import StdTutorRescheduleSession from "popups/booking/student/popups/Rescheduling/StdTutorRescheduleSession"
import defaultAvatar from "../../../assets/images/default-avatar.png"

interface Iprops {
    id: string
    teacherHourlyRate: number
    studentName: string
    bookingRequestThreadObjectId: string
    bookingRequestSlotId: string
    teacherId: string
    creationTime: object
    roomId: string
    studentId: string
    sessionLength: number
    startTime: string
    teacherName: string
    modifiedTime: object
    status: number
    bookingId: string
    sessionType: string
    teacherGroupSessionRate: number
    subjects: any
    extensionTime: any
    navigatetoEpicboard: Function
}
interface VendorData {
    newbookingId: any,
    userType: string,
    teacherName: string,
    studentName: string
}

const SessionDetailCard = ({
    id: sessionId,
    teacherId,
    studentId,
    teacherName,
    studentName,
    startTime,
    sessionLength,
    bookingId,
    extensionTime,
    subjects

}: Iprops): JSX.Element => {

    const [showReschedulePopup, setShowReschedulePopup] = useState(false);
    const userType = useSelector((state: any) => state.auth.userAuthData.userType);
    const { isSuccess, isFailure } = useSelector((state: any) => state.session.rescheduleData);
    const teacherNameSplitted = teacherName.split(" ");

    useEffect(() => {
        if (isSuccess || isFailure) {
            setShowReschedulePopup(false);
        }
    }, [isSuccess, isFailure])

    const {
        isJoinEnabled,
        loading,
        setDataAndEnterZoom
    } = useSessionAndZoom({
        sessionId,
        bookingId,
        teacherName,
        studentName,
        startTime,
        sessionLength,
        extensionTime,
        teacherId,
        studentId
    })
    const isAbove1024 = useMediaQuery({ query: '(min-device-width: 1025px)' });
    return (
        <div className="session-detail-card">
            {
                isAbove1024 ?
                    <CardView className="py-1 px-2">
                        <img src={`${SERVER_URL}/userImage?userId=${teacherId}`} style={{ margin: "5px" }} />
                        <div className="card-content ">
                            <p className="tutor-name">
                                {teacherNameSplitted[0] +
                                    " " +
                                    (teacherNameSplitted[1] !== undefined
                                        ? teacherNameSplitted[1].charAt(0) + "."
                                        : "")}
                            </p>
                            <p className="subject-name">
                                {subjects?.map((subject: any) => (
                                    <span>{subjects.length === 1 ? subject : subject + ", "}</span>
                                ))}
                            </p>
                            <p className="session-date">{`${formatDate(
                                startTime,
                                DateFormats.LONG_DATE,
                            )} at ${formatDate(startTime, DateFormats.TIME_IN_MERIDIAN)}`}</p>
                            <p className="session-duration">
                                <span className="duration-label">Duration: </span>
                                <span className="duration-amount">{sessionLength + (extensionTime || 0)} min.</span>

                            </p>
                        </div>
                        {isSessionReschedulable(userType, startTime) ?
                            <Button
                                title="Reschedule"
                                clickAction={() => {
                                    setShowReschedulePopup(true)
                                }}
                                isOutlineStyle
                                isloading={false}
                            />
                            :
                            <Button
                                title="Join Now"
                                clickAction={() => setDataAndEnterZoom().catch(console.error)}
                                isloading={loading}
                                isDisabled={!isJoinEnabled}
                            />
                        }
                    </CardView>
                    :
                    <CardView className="dashboard-upcoming-card-view">
                        <img src={`${SERVER_URL}/userImage?userId=${teacherId}`} />
                        <div className="card-content dashboard-upcoming-card-content">
                            <div className="upcoming-user-div">
                                <p className="tutor-name">
                                    {teacherNameSplitted[0] +
                                        " " +
                                        (teacherNameSplitted[1] !== undefined
                                            ? teacherNameSplitted[1].charAt(0) + "."
                                            : "")}
                                </p>
                                <p className="subject-name">
                                    {subjects?.map((subject: any) => (
                                        <span>{subjects.length === 1 ? subject : subject + ", "}</span>
                                    ))}
                                </p>
                            </div>

                            <div className="upcoming-time-div">
                                <p className="session-date">{`${formatDate(
                                    startTime,
                                    DateFormats.LONG_DATE,
                                )} at ${formatDate(startTime, DateFormats.TIME_IN_MERIDIAN)}`}</p>
                                <p className="session-duration">
                                    <span className="duration-label">Duration: </span>
                                    <span className="duration-amount">{sessionLength + (extensionTime || 0)} min.</span>

                                </p>
                            </div>


                        </div>
                        <Button
                            title="Join Now"
                            clickAction={() => setDataAndEnterZoom().catch(console.error)}
                            isloading={loading}
                            isDisabled={!isJoinEnabled}
                        />
                    </CardView>
            }
            {showReschedulePopup &&
                <StdTutorRescheduleSession
                    show={showReschedulePopup}
                    studentPicUrl={`${SERVER_URL}/userImage?userId=${studentId}` || defaultAvatar}
                    teacherPicUrl={`${SERVER_URL}/userImage?userId=${teacherId}` || defaultAvatar}
                    close={() => setShowReschedulePopup(false)}
                    teacherId={teacherId}
                    studentId={studentId}
                    sessionId={sessionId}
                    slot={{
                        sessionId,
                        teacherId,
                        studentId,
                        teacherName,
                        studentName,
                        startTime,
                        sessionLength,
                        bookingId,
                        extensionTime,
                        subjects
                    }}
                />
            }
        </div>
    )
}

export default SessionDetailCard
