import { SessionConstant } from "../../constant"
import { orderBookingByDate } from "../../../utils/helper/session-utils"

type initialStateType = {
    bookingRequestLoading: boolean
    suggestedTime: any
    suggestedComment: any
    bookingUpdateStatus: any
    bookings: any
    sessions: any
    pastsessions: any
    buttonLoading: boolean
    upcomingSessionsLoading: boolean
    pendingSessionsLoading: boolean
    pastSessionsLoading: boolean
    lastUpdatedSessionStatus: any
    rescheduleData: {
        isLoading: boolean,
        isSuccess: boolean,
        isFailure: boolean
    }
}

const initialState: initialStateType = {
    bookingRequestLoading: true,
    suggestedTime: {},
    suggestedComment: "",
    bookingUpdateStatus: "",
    buttonLoading: false,
    upcomingSessionsLoading: true,
    pendingSessionsLoading: true,
    pastSessionsLoading: true,
    bookings: [],
    sessions: [],
    pastsessions: [],
    lastUpdatedSessionStatus: {},
    rescheduleData: {
        isLoading: false,
        isSuccess: false,
        isFailure: false
    }
}

export enum BOOKING_REQUEST_STATUS_CODES {
    WAITING_FOR_TEACHER_CONFIRMATION = 1,
    WAITING_FOR_STUDENT_CONFIRMATION = 2,
    ACCEPTED = 4,
    CANCELLED = 6,
    REJECTED = 7,
    PAYMENT_PROCESSING = 8,
    PAYMENT_FAILED = 9,
    CONFIRMED = 10,
    PAYMENT_ACTION_REQUIRED = 11
}
export const REVIEW_REQUEST_STATUS_CODE = {

    CREATED: 1,
    UPDATED: 2,
    RESOLVED: 3

}

export const BOOKING_UPDATE_STATUS_CODES = {
    UPDATE_SUCCESS: "Your request was successfully processed",
    UPDATE_FAIL: "There was an issue with the update",
}
export const SESSION_STATUS_CODES = {
    CREATED: 1,
    COMPLETED: 2,
}

const sessionReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case SessionConstant.GET_BOOKING_REQUEST: {
            return { ...state, bookingRequestLoading: true }
        }
        case SessionConstant.PENDING_BOOKINGS_FETCHED: {
            return { ...state, bookings: action.payload, bookingRequestLoading: false }
        }
        case "UPCOMING_BOOKINGS_FETCHED": {
            return { ...state, ...action, upcomingSessionsLoading: false }
        }
        case "PAST_SESSIONS_FETCHED": {
            return { ...state, pastsessions: action.pastsessions, pastSessionsLoading: false }
        }
        case "BOOKINGS_FETCHED": {
            const orderedBookings = orderBookingByDate(action.bookings)
            return { ...state, bookings: orderedBookings, bookingRequestLoading: false }
        }
        case SessionConstant.SET_SUGGESTED_TIME: {
            const { id, requestKey, details } = action.payload
            const currentState = state.suggestedTime[id] || {}
            return {
                ...state,
                suggestedTime: {
                    ...state.suggestedTime,
                    [id]: {
                        ...currentState,
                        [requestKey]: details,
                    },
                },
            }
        }
        case SessionConstant.CHANGE_BOOKING_COMMENT: {
            const { id, comment } = action.payload

            return {
                ...state,
                suggestedComment: {
                    ...state.suggestedComment,
                    [id]: comment,
                },
            }
        }
        case SessionConstant.UPDATE_BOOKING: {
            return { ...state, buttonLoading: true }
        }
        case "BOOKING_WAS_UPDATED": {
            return {
                ...state,
                bookingUpdateStatus: BOOKING_UPDATE_STATUS_CODES.UPDATE_SUCCESS,
                buttonLoading: false,
            }
        }
        case "BOOKING_NOT_UPDATED": {
            return {
                ...state,
                bookingUpdateStatus: BOOKING_UPDATE_STATUS_CODES.UPDATE_FAIL,
                buttonLoading: false,
            }
        }
        case "CLEAR_SESSIONS": {
            return initialState
        }
        case "BOOKING_ACCEPTED_DETAILS": {
            return {
                ...state,
                lastUpdatedSessionStatus: action.bookingUpdated,
            }
        }
        case SessionConstant.GET_UPCOMING_SESSIONS: {
            return {
                ...state,
                upcomingSessionsLoading: true
            }
        }
        case SessionConstant.RESCHEDULE_SESSION: {
            return {
                ...state,
                rescheduleData: {
                    ...state.rescheduleData,
                    startTime: action?.payload.newStartTime,
                    isLoading: true,
                    isSuccess: false,
                    isFailure: false
                }
            }
        }
        case SessionConstant.RESCHEDULE_REQUEST_SUCCESS: {
            return {
                ...state,
                rescheduleData: {
                    ...state.rescheduleData,
                    isLoading: false,
                    isSuccess: true,
                    isFailure: false
                }
            }
        }
        case SessionConstant.RESCHEDULE_REQUEST_FAILURE: {
            return {
                ...state,
                rescheduleData: {
                    ...state.rescheduleData,
                    isLoading: false,
                    isSuccess: false,
                    isFailure: true
                }
            }
        }

        case SessionConstant.RESCHEDULE_REQUEST_SENT: {
            return {
                ...state,
                rescheduleData: {
                    ...state.rescheduleData,
                    isLoading: false,
                    isSuccess: false,
                    isFailure: false
                }
            }
        }
        default:
            return state
    }
}

export default sessionReducer
