import React, { useEffect, useState } from "react"
import DashboardRoutes from "../../../routes/DashboardRoute"
import PrivateRoute from "../../../routes/PrivateRoute"
import { Switch } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { getStudentAdvisors, getStudentTutors, getStudentUpcomingSessions } from "store/actions/studentDashboardActions"
import moment from "moment";
import WalkThrough from "../../../components/walkthrough/walkThrough"


const sectionData = {
    lessons: {
        heading: "In Progress",
        childCount: 3,
        members: [
            { lessonNumber: "01", lessonTitle: "Making Your College List" },
            { lessonNumber: "02", lessonTitle: "Standardized Tests" },
            { lessonNumber: "03", lessonTitle: "Community Service" },
            { lessonNumber: "04", lessonTitle: "Summer Plans" },
            { lessonNumber: "05", lessonTitle: "Other Content" },
            { lessonNumber: "06", lessonTitle: "More Learning" },
        ],
    },
    studyRooms: {
        heading: "Study Rooms",
        childCount: 8,
        members: [
            { roomTitle: "ACT", color: "orange" },
            { roomTitle: "SAT", color: "blue" },
            { roomTitle: "ISEE", color: "green" },
            { roomTitle: "SHSAT", color: "purple" },
            { roomTitle: "SSAT", color: "orange" },
        ],
    },
}

const StudentDashboardPage = (): JSX.Element => {
    const { userAuthData } = useSelector((state: any) => state.auth)
    const sessions = useSelector((state: any) => state.studentDashboard.upcomingSessions.data);
    const [tasks, setTasks] = useState({
        overdue: [] as Array<any>,
        deadlines: [] as Array<any>,
        upcoming: [] as Array<any>,
    });
    const dispatch = useDispatch();
    const getRoutes = (Routes: any) => {
        return Routes.map((prop: any, key: any) => {
            return (
                <PrivateRoute path={prop.layout + prop.path} component={prop.component} key={key} />
            )
        });
    }
    useEffect(() => {
        const userId = userAuthData?.userId;
        if (!userId) {
            return;
        }
        dispatch(getStudentUpcomingSessions({ userId }));
        dispatch(getStudentAdvisors({ userId }))
        dispatch(getStudentTutors({ userId }))
    }, [userAuthData?.userId]);

    useEffect(() => {
        const currentTimeString = (new Date()).toISOString();
        let overdue = [] as Array<any>;
        let upcoming = [] as Array<any>;
        sessions.map((session: any) => {
            const endTime = moment(session.startTime).utc().add(session.sessionLength, "minutes").add(session.extensionTime || 0, "minutes");
            if (currentTimeString > endTime.toISOString()) {
            } else if (endTime.toISOString() > currentTimeString) {
                upcoming.push({
                    name: "Upcoming",
                    date: new Date(session.startTime)
                });
            }
        });
        setTasks({
            overdue,
            upcoming,
            deadlines: []
        });
    }, [sessions]);
    return (
        <>
            <div className="dashboard-container student-dashboard-container">
                <>
                    <WalkThrough />
                    <Switch>{getRoutes(DashboardRoutes)}</Switch>
                </>
            </div>
        </>
    )
}

export default StudentDashboardPage
