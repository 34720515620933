import React, { useEffect, useRef, useState, memo } from "react"
import imageCompression from "browser-image-compression"
import { getFile } from "../../../../utils/helper/helper"
import BeatLoader from "react-spinners/BeatLoader"
import DefaultAvatar from "../../../../assets/images/default-avatar.png"
import { Hidden, Modal } from "@material-ui/core"
import { ExitIcon } from "../../../../components/icons/Icons"
import { Camera } from "../../../../components/camera/Camera"
import { fileUpload } from "../../../../utils/helper/firebase"
import { useSelector, useDispatch } from "react-redux"
import { setTeacherMyprofile } from "../../../../store/actions/TutorSignUpActions"
import { signupInformation } from "../../../../store/actions/userActions"
import { useOutsideAlerter } from "../../../../utils/hooks/useOutsideAlerter"
import AvatarEditor from 'react-avatar-editor'
import Button from "components/button/Button"

interface Props {
    handleAvatarClick: Function
    optionsOpen: boolean
    loading: boolean
    setLoading: Function
    source: "tutor" | "student"
}

const UserProfilePhoto = ({
    handleAvatarClick,
    optionsOpen,
    loading,
    setLoading,
    source
}: Props): JSX.Element => {
    const dispatch = useDispatch()

    const studentData = useSelector((state: any) => state.signup.options)
    const tutorProfileInfo = useSelector((state: any) => state.tutorMyprofile.options)
    const [DeleteFlag, SetDeleteFlag] = useState(true)
    const [UploadFlag, SetUploadFlag] = useState(true)
    const [ZoomImage, SetZoomImage] = useState(1)
    const [PictureFlag, SetPictureFlag] = useState(true)
    const [CropFlag, SetCropFlag] = useState(false)
    const [Editor, SetEditor] = useState<any>()
    const [FilePath, SetFilePath] = useState<any>("")
    const [openModal, openModalView] = useState(false)
    const { userAuthData } = useSelector((state: any) => state.auth)
    const wrapperRef = useRef(null)
    useOutsideAlerter(wrapperRef, handleAvatarClick)
    const handleFileUpload = async (file: any | null, from: "camera" | "chose"): Promise<void> => {
        setLoading(true)
        handleAvatarClick(false)

        const compressedMaxFileSize = {
            maxSizeMB: 1,
        }
        try {
            let compressedFile;
            let profilePicture: any;
            if (from === "chose") {
                compressedFile = await imageCompression(file, compressedMaxFileSize)
                profilePicture = await getFile(compressedFile)
            }
            else {
                profilePicture = file;
            }

            source === "tutor" && dispatch(setTeacherMyprofile({ profilePic: { downloadURL: profilePicture, newPic: true } }))
            source === "student" && dispatch(signupInformation({ profilePic: { downloadURL: profilePicture, newPic: true } }))

        } catch (error) {
            handleAvatarClick(false)
        }
    }
    const onSave = () => {

        urltoFile(Editor.getImageScaledToCanvas().toDataURL(), 'profile', 'image/png')
            .then(async function (file) {

                await handleFileUpload(file, "chose")
            }).catch(console.error);
        SetCropFlag(false);
        SetPictureFlag(true);
        SetDeleteFlag(true);
        SetUploadFlag(true);
    }
    const deletePicture = (): void => {
        dispatch(signupInformation({ profilePic: DefaultAvatar }))
        setLoading(false)
        handleAvatarClick(false)
    }
    function urltoFile(url: string, filename: string, mimeType: any) {
        return (fetch(url)
            .then(function (res) { return res.arrayBuffer(); })
            .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
        );
    }
    const handleScale = (e: any) => {
        const scale = parseFloat(e.target.value)
        SetZoomImage(scale)
    }

    return (
        <>
            <div
                className={`user-avatar ${optionsOpen && "open"}`}
                ref={wrapperRef}
                onClick={(): void => handleAvatarClick(!optionsOpen)}
                style={{
                    backgroundImage: `url(${source === "student" ? studentData?.profilePic?.downloadURL ? studentData?.profilePic?.downloadURL : DefaultAvatar : tutorProfileInfo?.profilePic?.downloadURL})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center center",
                    backgroundRepeat: "no-repeat",
                }}
            >
                {loading && <BeatLoader color="gray" />}
            </div>
            <div className={`avatar-options ${!optionsOpen && "hidden"}`} ref={wrapperRef}>
                <div className="options" style={{ height: source === "tutor" ? "177px" : "266px" }}>
                    <div className="cover">
                        <div className="arrow-left"></div>
                    </div>
                    <div className="list">
                        <ul>
                            {PictureFlag &&
                                <li onClick={(): void => openModalView(true)}>Take a Picture</li>
                            }
                            {UploadFlag &&
                                <li>
                                    <label htmlFor="upload-button">Choose a picture</label>
                                    <input
                                        type="file"
                                        accept="image/*"
                                        id="upload-button"
                                        style={{ display: "none" }}
                                        onChange={(event: any): void => {
                                            SetFilePath(event.target.files[0])
                                            SetCropFlag(true);
                                            SetPictureFlag(false);
                                            SetDeleteFlag(false);
                                            SetUploadFlag(false);
                                            event.target.value = ""
                                        }}
                                    />
                                </li>
                            }
                            {
                                CropFlag &&
                                <li className="canvas-margin">
                                    <AvatarEditor
                                        ref={SetEditor}
                                        image={FilePath}
                                        width={250}
                                        height={250}
                                        border={50}
                                        color={[255, 255, 255, 0.6]} // RGBA
                                        scale={ZoomImage}
                                        rotate={0}
                                    />
                                    <span>Zoom: </span>
                                    <input
                                        name="scale"
                                        type="range"
                                        onChange={handleScale}
                                        min="1"
                                        max="2"
                                        step="0.01"
                                        defaultValue="1"
                                    />
                                    <Button
                                        clickAction={onSave}
                                        title="Save" />
                                </li>
                            }
                            {source === "student" && DeleteFlag && (

                                <li onClick={(): void => deletePicture()}>Delete</li>
                            )}
                        </ul>
                    </div>
                </div>
            </div>
            <Modal
                open={openModal}
                className="take-picture-modal"
                aria-labelledby="Take Picture"
                aria-describedby="Take a picture from your webcam"
            >
                <div className={"media-modal"}>
                    <div className="take-picture-container">
                        <div
                            onClick={(): void => openModalView(false)}
                            className={"close-modal-button"}
                        >
                            <ExitIcon />
                        </div>
                        <h2>Take a photo</h2>
                        <Camera
                            onCapture={(event: any): void => {
                                handleFileUpload(event, "camera").catch(console.error)

                            }}
                            onClear={(): void => deletePicture()}
                            onClose={(): void => openModalView(false)}
                        />
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default memo(UserProfilePhoto)
function imgPath(imgPath: any) {
    throw new Error("Function not implemented.")
}
