import React from "react"
import interfaceConstant from "../../../utils/constant/interfaceConstant"

class NonBinaryGenderSvgIcon extends React.PureComponent<interfaceConstant.SvgIconProps> {
    render(): JSX.Element {
        const { width, height, ...otherProps } = this.props
        return (
            <svg width={width} height={height} viewBox="0 0 32 32" version="1.1" {...otherProps}>
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <path
                        d="M20.9554691,4.71238597 L21.3525898,6.18881086 L23.7213052,5.55675058 L20.5510394,8.71401908 C19.4431214,7.7977743 18.0215267,7.24638966 16.4726341,7.24638966 C14.8360339,7.24638966 13.3419586,7.86267877 12.2096773,8.87294403 L11.9519043,8.63549805 L12.9862149,7.55456577 C13.3859785,7.12840376 13.4127637,6.74293583 13.0665706,6.39816196 C12.7203774,6.05338809 12.348822,6.08392866 11.9519043,6.48978364 L10.917685,7.55456577 L9.24467179,5.92009425 L10.917685,6.18881086 L11.3148057,4.71268927 L5,3 L6.89292384,9.03099066 L8.37512207,8.63549805 L7.88337611,6.72654737 L9.83169389,8.63549805 L8.81575966,9.71612703 C8.41478789,10.1537027 8.38458714,10.5421252 8.72515741,10.8813944 C9.06572769,11.2206636 9.39310606,11.2338207 9.70729254,10.9208656 L10.8659132,9.71612703 L11.1946441,10.02424 C10.4905466,11.0475468 10.0778944,12.2837646 10.0778944,13.6146093 C10.0778944,16.8674148 12.5394946,19.5567039 15.7048877,19.9355155 L15.7048877,23.3454261 L12.3496704,23.3454261 C11.798645,23.3543373 11.5231323,23.6126897 11.5231323,24.1204834 C11.5231323,24.6282771 11.798645,24.879354 12.3496704,24.8737139 L15.7051923,24.8737139 L15.7051923,28.1752319 C15.7049892,28.7250773 15.9608032,29 16.4726341,29 C16.9844651,29 17.240279,28.7250773 17.240076,28.1752319 L17.240076,24.8737139 L20.5510394,24.8737139 C21.1059121,24.8969728 21.3833484,24.6480729 21.3833484,24.1270142 C21.3833484,23.6059555 21.1059121,23.3454261 20.5510394,23.3454261 L17.240076,23.3454261 L17.240076,19.9355155 C20.4051646,19.5567039 22.8670693,16.8671115 22.8670693,13.6146093 C22.8670693,12.1958099 22.3980771,10.8837692 21.6071856,9.82376409 L24.8066873,6.63707627 L24.1720251,8.99668712 L25.6542233,9.39217974 L27.3742676,3 L20.9554691,4.71238597 Z"
                        id="Shape"
                        fill="#686C80"
                        fillRule="nonzero"
                    ></path>
                </g>
            </svg>
        )
    }
}

export default NonBinaryGenderSvgIcon
