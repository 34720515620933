import { call, put, all, takeLatest, takeEvery, takeLeading } from "redux-saga/effects"
import {
    getBookings,
    updateBookingRequest,
    getUpcomingBookings,
    getPastSessions, getPendingBookings,
    updateBookingSlotsService,
    acceptBookingRequestService,
    cancelBookingRequestService,
    getUpcomingSessionsService,
    rescheduleSessionRequestService
} from "../../utils/services/sessionServices"
import { BOOKING_REQUEST_STATUS_CODES, SessionConstant, StudentDashboardConstants } from "../constant"
import { showToast } from "../actions/toastAction"
import { orderBookingByDate, orderSessionsByDate } from "utils/helper/session-utils"
import { rescheduleSessionPayloadType } from "store/types/sessionTypes"
// import { getBookingRequests, getPastSessions } from "../actions/sessionsActions";

const delay = (ms: number): any => new Promise((res) => setTimeout(res, ms)) //temporary fix to delay fetching booking requests

function* getRequestsAsync(action: any) {
    try {
        const bookingsData = yield call(getBookings)
        const bookings = bookingsData.data
        yield put({ type: "BOOKINGS_FETCHED", bookings })
    } catch (error) {
        yield put({ type: "FETCH_FAILED", error })
    }
}

function* getPendingSessionsAsync(action: any) {
    try {
        const pendingSessions = yield call(getPendingBookings)
        yield put({ type: SessionConstant.PENDING_BOOKINGS_FETCHED, payload: pendingSessions })
    } catch (error) {
        yield put({ type: "FETCH_FAILED", error })
    }
}

function* getPastSessionsAsync(action: any) {
    try {
        const pastSessionsData = yield call(getPastSessions)
        const pastsessions = pastSessionsData.data
        yield put({ type: "PAST_SESSIONS_FETCHED", pastsessions })
    } catch (error) {
        yield put({ type: "FETCH_FAILED", error })
    }
}

function* getUpcomingSessions(action: any) {
    try {
        const bookingsData = yield call(getUpcomingBookings)
        const sessions = orderSessionsByDate(bookingsData?.data?.sessions || []);
        yield put({ type: "UPCOMING_BOOKINGS_FETCHED", sessions })
    } catch (error) {
        yield put({ type: "FETCH_FAILED", error })
    }
}

function* getUpcomingSessionsOfMultipleUsers(action: any) {
    try {
        const upcomingSessions = yield getUpcomingSessionsService(action.payload)
        const sessions = upcomingSessions?.data || [];
        yield put({ type: "UPCOMING_SESSION_FETCHED", payload: sessions })
    } catch (error) {
        yield put({ type: "FETCH_FAILED", error })
    }
}

function* updateBooking(action: any) {
    try {
        const { bookingId, data } = action.payload
        const bookingUpdated = yield updateBookingRequest(bookingId, data)
        if (bookingUpdated.data.updatedBookingRequest && bookingUpdated.data.updatedBookingRequest.status) {
            const statusCode: String = bookingUpdated.data.updatedBookingRequest?.status;
            const message: any = BOOKING_REQUEST_STATUS_CODES[+(statusCode)]?.message || "";
            yield put(showToast({
                show: true,
                isSuccess: true,
                message
            }))
        }
        if (
            bookingUpdated.data.updatedBookingRequest &&
            bookingUpdated.data.updatedBookingRequest.id != null
        ) {
            yield put({
                type: "BOOKING_ACCEPTED_DETAILS",
                bookingUpdated: bookingUpdated.data.updatedBookingRequest,
            })
        }
        if (bookingUpdated.data.error) {
            throw new Error(bookingUpdated.data.message)
        }
        if (bookingUpdated.data.message) yield put({ type: "BOOKING_WAS_UPDATED", bookingUpdated })
        yield delay(1500)
        yield put({ type: SessionConstant.GET_BOOKING_REQUEST })
    } catch (error) {
        yield put({ type: "BOOKING_NOT_UPDATED", error })
        yield put(showToast({ show: true, isSuccess: false, message: error.message }))
        // yield put({ type:})
    }
}

function* updateBookingSlots(action: any) {
    try {
        const { bookingId, data } = action.payload
        const { bookingRequest: updatedBooking, error, message } = yield updateBookingSlotsService(bookingId, data);
        if (updatedBooking && updatedBooking.status) {
            const statusCode: String = updatedBooking.status;
            const message: any = BOOKING_REQUEST_STATUS_CODES[+(statusCode)]?.message || "";
            yield put(showToast({
                show: true,
                isSuccess: true,
                message
            }))
        }
        if (error) {
            throw new Error(message)
        }
        yield delay(1500)
        yield put({ type: SessionConstant.GET_PENDING_BOOKINGS })
    } catch (error) {
        yield put({ type: "BOOKING_NOT_UPDATED", error })
        yield put(showToast({ show: true, isSuccess: false, message: error.message }))
        // yield put({ type:})
    }
}

function* acceptBooking(action: any) {
    try {
        const { bookingId, data } = action.payload;
        const acceptBookingResponse = yield acceptBookingRequestService(bookingId, data);
        const { data: updatedBooking, status, message } = acceptBookingResponse?.bookingRequest;
        if (status === "success") {
            const statusCode: String = updatedBooking.status;
            const message: any = BOOKING_REQUEST_STATUS_CODES[+(statusCode)]?.message || "";
            yield put(showToast({
                show: true,
                isSuccess: true,
                message
            }))
        } else {
            yield put(showToast({
                show: true,
                isSuccess: false,
                message
            }))
        }
        yield delay(1500)
        yield put({ type: SessionConstant.GET_PENDING_BOOKINGS })
    } catch (error) {
        yield put({ type: "BOOKING_NOT_UPDATED", error })
        yield put(showToast({ show: true, isSuccess: false, message: error.message }))
        // yield put({ type:})
    }
}
function* cancelBooking(action: any) {
    try {
        const { bookingId, data } = action.payload
        const { bookingRequest: updatedBooking, error, message } = yield cancelBookingRequestService(bookingId, data);
        if (updatedBooking && updatedBooking.status) {
            const statusCode: String = updatedBooking.status;
            const message: any = BOOKING_REQUEST_STATUS_CODES[+(statusCode)]?.message || "";
            yield put(showToast({
                show: true,
                isSuccess: true,
                message
            }))
        }
        if (error) {
            throw new Error(message)
        }
        yield delay(1500)
        yield put({ type: SessionConstant.GET_PENDING_BOOKINGS })
    } catch (error) {
        yield put({ type: "BOOKING_NOT_UPDATED", error })
        yield put(showToast({ show: true, isSuccess: false, message: error.message }))
        // yield put({ type:})
    }
}

function* rescheduleSession(action: { type: string, payload: rescheduleSessionPayloadType }) {
    try {
        const data = yield rescheduleSessionRequestService(action.payload);
        const { status, message, data: rescheduleData } = data;
        if (status === "success") {
            yield put({ type: SessionConstant.RESCHEDULE_REQUEST_SUCCESS });
        } else if (status === "fail") {
            yield put({ type: SessionConstant.RESCHEDULE_REQUEST_FAILURE });
            yield put(showToast({ show: true, isSuccess: false, message: rescheduleData }));
        }
    } catch (error) {
        yield put({ type: SessionConstant.RESCHEDULE_REQUEST_FAILURE });
    }
    yield put({ type: SessionConstant.GET_UPCOMING_SESSIONS });
    yield put({ type: StudentDashboardConstants.GET_STUDENT_UPCOMING_SESSIONS })
}

export default all([
    takeLatest(SessionConstant.GET_BOOKING_REQUEST, getRequestsAsync),
    takeLatest(SessionConstant.GET_PENDING_BOOKINGS, getPendingSessionsAsync),
    takeEvery(SessionConstant.UPDATE_BOOKING, updateBooking),
    takeLatest(SessionConstant.GET_UPCOMING_SESSIONS, getUpcomingSessions),
    takeLatest(SessionConstant.GET_UPCOMING_SESSIONS_N, getUpcomingSessionsOfMultipleUsers),
    takeLatest(SessionConstant.GET_PAST_SESSIONS, getPastSessionsAsync),
    takeLeading(SessionConstant.UPDATE_BOOKING_SLOTS, updateBookingSlots),
    takeLeading(SessionConstant.ACCEPT_BOOKING_REQUEST, acceptBooking),
    takeEvery(SessionConstant.CANCEL_BOOKING_REQUEST, cancelBooking),
    takeEvery(SessionConstant.RESCHEDULE_SESSION, rescheduleSession)
])
