import React, { Fragment } from "react"

interface Props {
    data: any[]
}
const EducationBackground = (props: Props): JSX.Element => {
    const { data } = props
    return (
        <Fragment>
            <div>
                <table className="table">
                    <thead></thead>
                    <tbody>
                        <tr>
                            <td className="table-header border-t-none padding-l-0">Institute</td>
                            <td className="table-header border-t-none">Degree </td>
                            <td className="table-header border-t-none padding-r-0 text-right">
                                Graduation year
                            </td>
                        </tr>
                        {data.map((schoolDetails) => (
                            <tr>
                                <td className="padding-l-0">{schoolDetails.schoolName}</td>
                                <td>{schoolDetails.degreeName}</td>
                                <td className="padding-r-0 text-right">
                                    {schoolDetails.graduationYear}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </Fragment>
    )
}
export default EducationBackground
