import React from "react"
import interfaceConstant from "../../../utils/constant/interfaceConstant"

class PowerSvgIcon extends React.PureComponent<interfaceConstant.SvgIconProps> {
    render(): JSX.Element {
        const { width, height, iconColor, ...otherProps } = this.props
        return (
            <svg width={width} height={height} {...otherProps}>
                <path
                    d="M27.3955567,17.2963646 C27.3982225,22.9735475 23.3239317,27.8329606 17.7333064,28.8205444 C12.1426812,29.8081282 6.65009637,26.6387033 4.70754944,21.3042002 C2.7650025,15.9696971 4.93343229,10.0105315 9.85001704,7.17193913 C10.1496217,6.99837475 10.5060984,6.95153635 10.84037,7.04181407 C11.1746415,7.1320918 11.4590713,7.35202172 11.6305643,7.65281685 C11.9832794,8.27403181 11.7685065,9.06346712 11.1496865,9.42036738 C7.5836844,11.4791997 5.84510939,15.676491 6.91083941,19.6538496 C7.97656943,23.6312082 11.5808652,26.3968817 15.6985298,26.3968817 C19.8161944,26.3968817 23.4204902,23.6312082 24.4862203,19.6538496 C25.5519503,15.676491 23.8133753,11.4791997 20.2473731,9.42036738 C19.8243588,9.19908194 19.5567125,8.76369463 19.5502513,8.28634085 C19.5437902,7.80898707 19.7995542,7.36651519 20.2164243,7.13386156 C20.6332944,6.90120794 21.1441593,6.91582707 21.5470426,7.17193913 C25.1644151,9.26042947 27.3935926,13.1193835 27.3955567,17.2963646 Z M15.6985298,12.0976866 C16.4163175,12.0976866 16.9981993,11.5158047 16.9981993,10.7980171 L16.9981993,4.29966951 C16.9981993,3.58188186 16.4163175,3 15.6985298,3 C14.9807422,3 14.3988603,3.58188186 14.3988603,4.29966951 L14.3988603,10.7980171 C14.3988603,11.5158047 14.9807422,12.0976866 15.6985298,12.0976866 Z"
                    fill={iconColor}
                    fillRule="evenodd"
                />
            </svg>
        )
    }
}

export default PowerSvgIcon
