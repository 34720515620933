import React, { useEffect, useState } from "react"
import { Link, useHistory } from "react-router-dom"
import Grid from "@material-ui/core/Grid"
import LessonListItem from "../../../components/lesson/LessonListItem"
import Button from "../../../components/button/Button"
import { refType } from "../../../components/lesson/LessonContent"
import { useTranslation } from "react-i18next"
import { navigatetoUrl, navigatetoUrlWithProps } from "../../../utils/helper/helper"
import LoaderSpinner from "../../../components/loader/LoaderSpinner"
import { useSelector, useDispatch } from "react-redux"
import { fetchLessons } from "../../../store/actions/lessonActions"
import { collegeOrgaizerPdf } from "utils/services/lessonsServices"
import ShareaSale from "components/AffilateMarketing/ShareaSale"

const lessonsList = (
    isloading: any,
    lessons: any,
    lessonSubscriptionExpiryStatus: any,
    openLesson: any,
) => {
    if (isloading) {
        return <LoaderSpinner />
    } else {
        return lessons?.map((lesson: any, index: number) => (
            <LessonListItem
                key={`${lesson.fields.lessonTitle}-${index}`}
                title={lesson.fields.lessonTitle}
                description={lesson.fields.description}
                lessonSubscriptionExpiryStatus={lessonSubscriptionExpiryStatus}
                lessonId={lesson.sys.id}
                openLesson={openLesson}
                index={index}
            />
        ))
    }
}
const LessonsOverviewPage = () => {
    const dispatch = useDispatch()
    let history = useHistory()
    const lessons = useSelector((state: any): refType[] => state.lessons.lessons)
    const isloading = useSelector((state: any): refType[] => state.lessons.isloading)
    const { lessonSubscriptionExpiryStatus } = useSelector(
        (state: any): any => state.auth.userAuthData,
    )
    const [collegeOrganizerLink, setCollegeOrganizerLink] = useState("")
    const { t } = useTranslation()
    useEffect(() => {
        dispatch(fetchLessons())
        collegeOrgaizerPdf().then((link) => {
            setCollegeOrganizerLink(link)
        })
    }, [])
    const openLesson = (history: any, path: string, index: any) => {
        navigatetoUrlWithProps(history, path, { lessons: lessons, index: index })
    }

    const navigateToCollegeOrganizer = () => {
        window.open(collegeOrganizerLink)
    }

    return (
        <Grid container spacing={0} className="lessons-overview-page">
            <Grid item lg={7} md={12} className="left-panel">
                <p className="overview-head p-mango">{lessonSubscriptionExpiryStatus ? t("product-overview-heading") : t("college-counselling-guide")}</p>

                {lessonSubscriptionExpiryStatus ? (
                    <p className="overview-subhead p-26 p-battleship">
                        {t("product-overview-desc")}
                    </p>
                ) : (
                    <p className="overview-subhead p-26 p-battleship">
                        {t("product-overview-desc2")}
                    </p>
                )}
                <p
                    style={{
                        marginTop: "20px",
                        marginBottom: "20px",
                        color: "#686c80",
                    }}
                >
                    It's never too early to start planning for college! Download{" "}
                    <u
                        style={{ color: "#262ffa" }}
                        onClick={() => {
                            navigateToCollegeOrganizer()
                        }}
                    >
                        The Classroom Door’s College Organizer
                    </u>{" "}
                    to start planning for your future.
                </p>
                <div className="subscription-option-actions">
                {lessonSubscriptionExpiryStatus && (
                    <>
                        <Button
                            className="subscription-option-btn"
                            title={t("subscription-option")}
                            clickAction={() => {
                                navigatetoUrl(history, "/panel/lessons/options")
                            }}
                        />
                        <Button
                            className="subscription-option-btn-small"
                            title="Subscribe Now"
                            clickAction={() => {
                                navigatetoUrl(history, "/panel/lessons/options")
                            }}
                        />
                    </>
                )}
                </div>
                <div className="lessons-list">
                    {lessonsList(isloading, lessons, lessonSubscriptionExpiryStatus, openLesson)}
                </div>
            </Grid>
            <Grid item lg={5} md={12} className="right-panel">
                <p className="p-18 p-white">{t("product-overview-p1")}</p>
                <p className="p-18 p-white">{t("product-overview-p2")}</p>
            </Grid>
            <ShareaSale/>
        </Grid>
    )
}

export default LessonsOverviewPage
