import React, { useState, useEffect } from "react"
import { Container, Grid } from "@material-ui/core"
import PaymentsTutor from "pages/home/my-account/components/PaymentsTutor/PaymentsTutor"
import { useDispatch, useSelector } from "react-redux"
import {
    setTeacherAvailableSlot,
    setTutorCancellationPolicy,
    setTutorRates,
    selectteacherType,
    setTutorEducation,
    setTeacherMyprofile,
} from "../../../store/actions/TutorSignUpActions"
import avatar from "../../../assets/images/default-avatar.png"
import { useHistory } from "react-router-dom"
import TutorCancellationPolicyPage from "../../onboarding/tutor-cancellation-policy/TutorCancellationPolicyPage"
import Button from "../../../components/button/Button"
import {
    getTutorInformation,
    updateTutor,
} from "../../../utils/helper/firebase"
import subjectsList from "../../../utils/constant/subjectsList"
import MessageAlert from "../../../components/message-alert/MessageAlert"
import { applicationConstant } from "../../../utils/constant/applicationConstant"
import InterfaceConstant from "../../../utils/constant/interfaceConstant"
import _ from "lodash"
import standardizedTestList from "../../../utils/constant/standardizedTestList"
import counsellingList from "../../../utils/constant/counsellingList"
import LoaderSpinner from "../../../components/loader/LoaderSpinner"
import { educationLevel as educationList } from "../../../utils/constant/educationLists"
import TutorProfileForm from "./components/TutorProfileForm"
import genderList from "../../../utils/constant/genderList"
import { DateFormats, formatDate } from "../../../utils/helper/date-utils"
import SwitchTabComponent from "components/tabs/switch-tab/SwitchTabComponent"
import TutorProfilePreview from "../tutor-profile/TutorProfilePreview"
import TutorAvailabilitySlots from "pages/onboarding/tutor-availability/components/TutorAvailabilitySlots"
import { getUserWithDetails } from "utils/services/userServices"
import { loginSuccess } from "store/actions/userActions"


const TabTypes = {
    PROILE: "Profile",
    PAYMENTS: "Payments",
    AVAILABILITY_SETTINGS: "Availability Settings",
    RATES: "Rates",
}

const TutorProfilePage = (): JSX.Element => {
    const dispatch = useDispatch()
    const history = useHistory()
    const [openSnackBar, setOpenSnackBar] = useState(false)
    const [tabValue, setTabValue] = useState("Profile")
    const [tutorProfileView, settutorProfileView] = useState(false)
    const { userAuthData } = useSelector((state: any) => state.auth)
    const tutorAvailabilityLable: InterfaceConstant.ItutorAvailabilityLable =
        applicationConstant.tutorAvailabilityLable
    const { teacherWeeklyAvailability } = useSelector((state: any) => state.tutorAvailability)
    const [loading, setLoading] = useState(false)
    const [pageLoading, setPageLoading] = useState(true)
    const [message, setMessage] = useState({
        message: "",
        type: "",
    })
    const [dataOk, setDataOk] = useState(false)
    const [dataUpdated, setDataUpdated] = useState(false)
    const [activeButton, setActiveButton] = useState("profile")
    const [optionsOpen, setOptionsOpen] = useState(false)


    useEffect(() => {
        getTutorInformation(userAuthData.userId)
            .then((data: any) => {
                const TutorCancellationPolicyData = {
                    freeCancellationBeforeHours: data?.freeCancellationBeforeHours,
                    chargesAfterFreeCancellationHours: data?.chargesAfterFreeCancellationHours,
                    advanceScheduledSessionHours: data?.advanceScheduledSessionHours,
                    freeCancellationOfAdvanceScheduledSessionHours:
                        data?.freeCancellationOfAdvanceScheduledSessionHours,
                    chargesAfterFreeAdvanceScheduledSessionCancellationHours:
                        data?.chargesAfterFreeAdvanceScheduledSessionCancellationHours,
                }
                const TutorRates = {
                    individualRate: data?.oneToOneSessionRate,
                }
                const gradeFiltered = data?.teacherEducationQualification
                    ? educationList.filter(
                        (item: any) => item.id === data?.teacherEducationQualification.id,
                    )[0]
                    : {
                        name: "",
                        id: "",
                    }
                const certificationData = data?.teacherCertificateDetails
                const tutorEducationData = {
                    teacherEducationQualification: gradeFiltered,
                    gradeOptions: data?.teacherSchoolDetails,
                    certification: certificationData?.certificationName,
                    issuing: certificationData?.issuingOrganization,
                    issuingDate: certificationData?.issueDate,
                    expirationDate: certificationData?.expirationDate,
                    credentialId: certificationData?.credentialId,
                    credentialUrl: certificationData?.credentialUrl,
                    biography: data?.teacherBiography,
                    teacherResume: data?.teacherResume,
                }
                const subjectFilter = data?.subjects

                const subjectFiltered = subjectsList.filter((item: any) => {
                    if (subjectFilter?.includes(item.id)) {
                        return item
                    }
                })

                const testsFiltered = standardizedTestList.filter((item: any) => {
                    if (subjectFilter?.includes(item.id)) {
                        return item
                    }
                })

                const specializationFiltered = counsellingList.filter((item: any) => {
                    if (subjectFilter?.includes(item.id)) {
                        return item
                    }
                })

                const teacherType = {
                    teacherType: data?.teacherType,
                    value: {
                        subject: subjectFiltered,
                        test: testsFiltered,
                        specialization: specializationFiltered,
                    },
                }
                const teacherAvailability = {
                    teacherWeeklyAvailability: data?.teacherWeeklyAvailability,
                }

                const gender =
                    userAuthData.gender && userAuthData.gender.id
                        ? userAuthData.gender.id
                        : userAuthData.gender

                const genderFiltered = gender
                    ? genderList.filter((item: any) => item.id === gender)[0]
                    : {
                        name: "",
                        id: "",
                    }
                const defaultAvatar = {
                    downloadUrl: avatar,
                }
                const tutorProfileDetails = {
                    userId: userAuthData.userId,
                    firstName: userAuthData.firstName,
                    lastName: userAuthData.lastName,
                    phoneNumber: userAuthData.phoneNumber,
                    gender: { value: genderFiltered },
                    dateOfBirth: userAuthData.dateOfBirth
                        ? formatDate(userAuthData.dateOfBirth, DateFormats.EUA_DATE)
                        : "",
                    profilePic: userAuthData.profilePic ? userAuthData.profilePic : defaultAvatar,
                }
                dispatch(setTeacherMyprofile(tutorProfileDetails))
                dispatch(setTutorEducation(tutorEducationData))
                dispatch(setTeacherAvailableSlot(teacherAvailability))
                dispatch(setTutorCancellationPolicy(TutorCancellationPolicyData))
                dispatch(setTutorRates(TutorRates))
                dispatch(selectteacherType(teacherType))
                //dispatch(setTutorPayment({ teacherPaymentDetails: data?.teacherPaymentDetails }))
                setDataOk(true)
                setPageLoading(false)
            })
            .catch(console.error)
    }, [activeButton, dataUpdated])

    const handleAvailability = async (): Promise<void> => {
        const isSlotEmpty = _.every(teacherWeeklyAvailability, (item) => _.isEmpty(item) === true)

        if (isSlotEmpty) {
            setLoading(false)
            setMessage({
                message: tutorAvailabilityLable.ERROR,
                type: "error",
            })
            setOpenSnackBar(true)
        } else {
            setLoading(true)
            const data = { teacherWeeklyAvailability: teacherWeeklyAvailability }
            try {
                await updateTutor(userAuthData.userId, data)
                setLoading(false)
                setMessage({
                    message: "Availability settings sucessfully updated.",
                    type: "success",
                })
                setOpenSnackBar(true)
                await getUserWithDetails(userAuthData.userId).then((data: any) => {
                    dispatch(loginSuccess(data));
                })
            } catch (error) {
                setLoading(false)
                setMessage({
                    message: "An error occurred: " + error,
                    type: "error",
                })
                setOpenSnackBar(true)
            }
        }
    }
    const renderPage = () => {
        switch (tabValue) {
            case "Profile":
                return (
                    <>
                        <TutorProfileForm
                            setDataUpdated={setDataUpdated}
                            handleAvatarClick={setOptionsOpen}
                            optionsOpen={optionsOpen}
                            tutorPreview={settutorProfileView}
                        />
                    </>
                )
            case "Payments":
                return <PaymentsTutor user={userAuthData} />
            case "Availability Settings":
                return (
                    <>
                        <MessageAlert
                            openSnackBar={openSnackBar}
                            setOpenSnackBar={setOpenSnackBar}
                            type={message.type}
                            message={message.message}
                        />
                        <div className="my-account-wrapper">
                            <div className="tutor-availability z-10">
                                <div className="tutor-availability-container">
                                    <TutorAvailabilitySlots />
                                </div>
                            </div>
                            <Button
                                title={"Save"}
                                isloading={loading}
                                clickAction={(): Promise<void> => handleAvailability()}
                                className="centered-button"
                            />
                        </div>
                    </>
                )
            case "Rates":
                return (
                    <div className="my-account-wrapper">
                        <h3 style={{ textAlign: "center" }}>Rates</h3>
                        <TutorCancellationPolicyPage history={history} isComponent />
                    </div>
                )
        }
    }
    return (
        <>
            {tutorProfileView ? (
                <>
                    <TutorProfilePreview profileView={settutorProfileView} />
                </>
            ) : (
                <>
                    <Container className={`my-account-page tutor-account ${tabValue === 'Availability Settings' ? 'availability-page' : ''}`}>
                        <Grid
                            container
                            direction="row"
                            justify="space-between"
                            alignItems="flex-start"
                            className="header"
                        >
                            <Grid item className="account-head">
                                <h1>My Account</h1>
                            </Grid>
                            <Grid item className="my-account-tabs-container">
                                <SwitchTabComponent
                                    title=""
                                    options={Object.values(TabTypes)}
                                    selectedOption={tabValue}
                                    onTabClick={setTabValue}
                                />
                            </Grid>
                        </Grid>
                        {/* <Grid
                            container
                            direction="row"
                            justify="space-between"
                            alignItems="flex-start"
                        >
                            <Grid item className="account-head">
                                <DeleteAccount
                                    auth={firebaseAuth}
                                    functions={functions}
                                    onPostDeletion={() => {
                                        dispatch(logOutRequest())
                                    }}
                                />
                            </Grid>
                        </Grid> */}
                        {!dataOk && pageLoading ? <LoaderSpinner /> : renderPage()}
                    </Container>
                </>
            )}
        </>
    )
}

export default TutorProfilePage
