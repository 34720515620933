import React from "react"
import { LinkRoundedIcon } from "../icons/Icons"
import moment from "moment";
import DefaultAvatar from "../../assets/images/default-avatar.png";
interface Iprops {
    student: string
    size: string,
    startTime :string,
    endTime :string,
    studentName :string,
}
const StudentBookingRequest = ({ student, size,studentName ,startTime,endTime}: Iprops) => {
    return (
        <div className="student-avatar">
            <img
                src={student ? student: DefaultAvatar}
                className="img-rounded-icon"
                style={{ width: size+"px", height: size+"px", objectFit: "cover" }}
            />
        <div className="text">
        <h4>{studentName}</h4>
        <h4 className="time">Today, {moment(startTime).format("MMMM DD, YYYY")},  {moment(startTime).format("hh:mm A")} -  {moment(endTime).format("hh:mm A")} </h4>
        </div>
        </div>
    )
}

export default StudentBookingRequest
