import React, { useEffect } from "react"
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import FormHelperText from "@material-ui/core/FormHelperText"
import FormControl from "@material-ui/core/FormControl"
import Select from "@material-ui/core/Select"
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown"
import { applicationConstant } from "../../utils/constant/applicationConstant"
import InterfaceConstant from "../../utils/constant/interfaceConstant"

interface Iprops {
    index: number
    title: string
    placeholder: string
    value: any
    optionsList: any
    handleDropDownAction: Function
    inputProps: any
    error: boolean
    name: any
}

interface Ioption {
    name: string | number
    id: string | number
    value?: string | number
}
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
        },
        formControl: {
            width: "100%",
            padding: "0px",
            height: "unset",
            "& .MuiSelect-selectMenu": {
                overflow: "hidden",
                height: "52px",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                display: "flex",
                alignItems: "center",
                paddingLeft: 10,
            },
            "& .MuiSelect-icon": {
                right: "15px",
                fill: theme.palette.primary.main,
            },
            "& .MuiSelect-select": {
                "&:focus": {
                    background: "none",
                },
            },
        },
        select: {
            paddingLeft: "10px",
        },
        MuiInputBase: {
            paddingLeft: "10px",
        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
            fontWeight: applicationConstant.fontSizes.LIGHTER,
        },
        paper: {
            boxShadow: "0 10px 10px 0 #0000000d",
            borderRadius: "0px 0px 8px 8px",
            maxHeight: 300
        },
        menuItem: {
            padding: "1rem",
        },
    }),
)
const SimpleDropdown = ({
    index,
    title,
    placeholder,
    value,
    optionsList,
    handleDropDownAction,
    inputProps,
    error,
    name
}: Iprops): JSX.Element => {
    const classes = useStyles()

    const educationErrorLabel: InterfaceConstant.IappErrorLabels =
        applicationConstant.appErrorLabels

    return (
        <div className="dropdown-wrapper simple-dropdown">
            <label className="input-label" htmlFor={title}>
                {title}
            </label>
            <div className="input">
                <FormControl error={error} fullWidth>
                    <InputLabel disableAnimation shrink={false}>
                        {!value && placeholder}
                    </InputLabel>
                    <Select
                        MenuProps={{
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                            },
                            transformOrigin: {
                                vertical: "top",
                                horizontal: "left",
                            },
                            getContentAnchorEl: null,
                            MenuListProps: {
                                disablePadding: true,
                                dense: true,
                            },
                            PopoverClasses: {
                                paper: classes.paper,
                            },
                        }}
                        disableUnderline
                        IconComponent={KeyboardArrowDownIcon}
                        className={classes.formControl}
                        onChange={(e: any) => handleDropDownAction("graduationYear", index, e)}
                        name={name}
                        value={value}
                        {...inputProps}
                    >
                        {optionsList.map((option: Ioption) => (
                            <MenuItem
                                className={classes.menuItem}
                                value={option.name}
                                selected={option.name === value}
                            >
                                {option.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                {error ? <div className="error" /> : null}
            </div>
            {error ? <FormHelperText>{educationErrorLabel.GLOBAL_REQUIRED}</FormHelperText> : null}
        </div>
    )
}

export default SimpleDropdown
