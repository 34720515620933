import colors from "assets/colors"
import React, { Fragment } from "react"
import BeatLoader from "react-spinners/BeatLoader"

interface Iprops {
    title: string
    clickAction: any
    textStyle?: any
    buttonStyle?: any
    isOutlineStyle?: boolean | false
    isDisabled?: boolean | false
    isloading?: boolean | false
    rightSideIcon?: any | undefined
    leftSideIcon?: any | undefined
}

const ButtonUpdated = ({
    title,
    clickAction,
    textStyle,
    buttonStyle,
    isDisabled,
    isOutlineStyle,
    isloading,
    rightSideIcon,
    leftSideIcon,
}: Iprops): JSX.Element => {
    const renderTitle = (): JSX.Element => {
        return (
            <div style={textStyle}>
                {leftSideIcon ? (
                    <span className="left-side-icon">{leftSideIcon && leftSideIcon()}</span>
                ) : null}
                {title}
                {rightSideIcon ? (
                    <span className="right-side-icon">{rightSideIcon && rightSideIcon()}</span>
                ) : null}
            </div>
        )
    }

    return (
        <div className="button-updated" style={buttonStyle}>
            <button
                type="submit"
                className={`${isOutlineStyle ? "outline-button" : ""}`}
                onClick={clickAction}
                disabled={isDisabled}
            >
                {isloading ? (
                    <span className="button-spinner">
                        <BeatLoader color={isOutlineStyle ? colors.darkMango : "white"} />
                    </span>
                ) : (
                    renderTitle && renderTitle()
                )}
            </button>
        </div>
    )
}
export default ButtonUpdated
