
import React from "react";
import { PendingContext } from "./PendingContext";
import { PendingProvider } from "./PendingProvider";

/**
 * Main Component of Pending where all the child components can access the pending
 * functionalities
 * @param param0.context React.Context
 * @param param0.children child components of react 
 */
export function PendingContainer({ context = PendingContext, userId, children }: { context?: React.Context<any>, userId: string, children?: any }) {
    return <PendingProvider context={context} userId={userId}>
        {children}
    </PendingProvider>
}