import React from "react"
import MuiPhoneNumber, { MaterialUiPhoneNumberProps } from "material-ui-phone-number";

const PhoneNumberInput = (props: MaterialUiPhoneNumberProps & {errorMessage?: string}): JSX.Element => {
    return <>
        <label className="input-label">
            {props.title}
        </label>
        <MuiPhoneNumber
            className="phone-number"
            defaultCountry={'us'}
            preferredCountries={['us']}
            disableAreaCodes={true}
            countryCodeEditable={true}
            title="Phone Number"
            variant="outlined"
            {...props}
        />
        <div className="phone-number-error-label">{props.errorMessage}</div>
    </>
}

export default PhoneNumberInput
