import colors from "assets/colors"
import React, { Fragment } from "react"
import BeatLoader from "react-spinners/BeatLoader"

interface Iprops {
    title?: string | undefined
    clickAction?: any
    isOutlineStyle?: boolean | false
    isDisabled?: boolean | false
    isloading?: boolean | false
    rightSideIcon?: any | undefined
    leftSideIcon?: any | undefined
    style?: any | undefined
    isSmall?: boolean | false
    className?: any,
    type?: "submit" | "button" | "reset"
}

const Button = ({
    title,
    clickAction,
    isDisabled,
    isOutlineStyle,
    isloading,
    rightSideIcon,
    leftSideIcon,
    className,
    style,
    isSmall,
    type = "submit"
}: Iprops): JSX.Element => {
    const renderTitle = (): JSX.Element => {
        return (
            <Fragment>
                {leftSideIcon ? (
                    <span className="left-side-icon">{leftSideIcon && leftSideIcon()}</span>
                ) : null}
                {title}
                {rightSideIcon ? (
                    <span className="right-side-icon">{rightSideIcon && rightSideIcon()}</span>
                ) : null}
            </Fragment>
        )
    }

    return (
        <span className="button">
            <button
                type={type}
                className={`${className} ${
                    isOutlineStyle ? "outline-button" : "no-outline-button"
                } ${title ? "" : "notext-button"} ${isSmall ? "small-button" : ""}`}
                onClick={clickAction}
                disabled={isDisabled}
                style={style}
            >
                {isloading ? (
                    <span className="button-spinner">
                        <BeatLoader color={isOutlineStyle ? colors.darkMango : "white"} />
                    </span>
                ) : (
                    renderTitle && renderTitle()
                )}
            </button>
        </span>
    )
}
export default Button