import SessionView from "../pages/home/dashboard/views/session-view"
import TutorView from "../pages/home/dashboard/views/tutor-view"
import AdvisorView from "../pages/home/dashboard/views/advisor-view"
import DashboardView from "../pages/home/dashboard/views/dashboard-view"


export const layout = "/panel/dashboard/"

const DashboardRoutes = [
    {
        path: "/sessions",
        name: "Upcoming Sessions",
        layout: "/panel/dashboard",
        component: SessionView,
    },
    {
        path: "/tutors",
        name: "My Tutors",
        layout: "/panel/dashboard",
        component: TutorView,
    },
    {
        path: "/advisors",
        name: "My Advisors",
        layout: "/panel/dashboard",
        component: AdvisorView,
    },
    {
        path: "",
        name: "Upcoming Sessions",
        layout: "/panel/dashboard",
        component: DashboardView,
    },
]

export default DashboardRoutes
