import React from "react"
import { CalendarCancelIcon } from "../../../../../components/icons/Icons"

const LessonSubscribeCancelation = (): JSX.Element => {
    return (
        <div className="cancelation d-flex">
            <div className="image">{CalendarCancelIcon(null)}</div>
            <div className="description">
                <p>
                    <span>{"Subscription Policy"}:</span>{" "}{"The payment will be charged to your account at the confirmation of your purchase. Your subscription automatically renews unless you cancel your auto-renewal at least 24-hours before the end of the current period. You can cancel at any time, but this won’t affect the currently active subscription period."}
                </p>
            </div>
        </div>
    )
}
export default LessonSubscribeCancelation