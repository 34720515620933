import React from "react"
import { useSelector } from "react-redux"
import { Route, Redirect, RouteProps } from "react-router-dom"

interface OnboardingRouteProps extends RouteProps {
    component: any
}

const OnboardingRoute = (props: OnboardingRouteProps): JSX.Element => {
    const tutorInfo = useSelector((state: any) => state.tutorSignUp.teacherType)
    const { component: Component, ...rest } = props
    return (
        <Route
            {...rest}
            render={(routeProps) =>
                tutorInfo ? (
                    <Component {...routeProps} />
                ) : (
                    <Redirect
                        to={{
                            pathname: "/my-role",
                            state: { from: routeProps.location },
                        }}
                    />
                )
            }
        />
    )
}
export default OnboardingRoute
