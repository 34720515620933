import React, { useEffect, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus } from "@fortawesome/free-solid-svg-icons"
import { useSelector } from "react-redux"
import { SERVER_URL } from "../../utils/constant/api/api-config"
import { Avatar } from "@material-ui/core"
import {  unRead } from "../../components/icons/Icons"
import { getUserProfilePicture } from "../../utils/services/userServices"
import { ImageAspectRatioTwoTone, ImageSearch } from "@material-ui/icons"
import DefaultAvatar from "../../assets/images/default-avatar.png"
const ChatUsers = (props: any) => {
    const { userId } = useSelector((state: any) => state.auth.userAuthData)
    const { presenterIds } = props
    const epicBoard = useSelector((state: any) => state.epicBoardReducer.epicBoard)
    const [presenters, setPresenters] = useState(epicBoard.presenterIds)
    const [userProfileURLs, setUserProfileURLs] = useState<any[]>([])
    const [currentURL, setCurrentURL] = useState<any>(null)

    useEffect(() => {
        if (presenterIds) {
            presenterIds.map(async (e: string) => {
                await getUserProfilePicture(e).then((val) => {
                    setCurrentURL(val)
                })
            })
        }
    }, [])
    useEffect(() => {
        if (currentURL !== null) setUserProfileURLs([...userProfileURLs, currentURL])
    }, [currentURL])
    return (
        <div className="chat-users">
      
            {presenterIds &&
                presenterIds.map((e: string, index: number) => (
                    <div className="icon-block">
                        <Avatar
                            className="user-image"
                            alt=""
                            src={userProfileURLs[index] ? userProfileURLs[index] : DefaultAvatar}
                        />
                       
                    </div>
                ))}
            {/* </div> */}
        </div>
    )
}

export default ChatUsers
