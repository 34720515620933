import React, { useState } from "react"
import { useSelector } from "react-redux"
import defaultAvatar from "../../assets/images/default-avatar.png"
import SlideMenu from "./SlideMenu"
import MenuHeader from "./MenuHeader"
import {useMediaQuery} from "react-responsive"


const BugerMenu = ({ routes }: { routes: any }): JSX.Element => {
    const { userAuthData } = useSelector((state: any) => state.auth)

    const [firstName, setFirstName] = useState<string>(userAuthData.firstName)
    const [picURL, setPicURL] = useState<string>(userAuthData.profilePic.downloadURL)
    const [school, setSchool] = useState<string>(userAuthData.currentSchool)
    const isNotchScreen = useMediaQuery({ query: '(max-device-width: 767px)' });

    const expand = (event: any) => {
        let slideMenu = document.getElementById('slide-menu')
        if (slideMenu != null) {
            slideMenu.style.opacity = '1'
            slideMenu.style.left = '0px'
        }
        let profileDetails = document.getElementById('profile-details')
        if (profileDetails != null) {
            profileDetails.style.backgroundImage = "linear-gradient( rgba(0, 0, 0, 0.77), rgba(0, 0, 0, 0.77) ), " +
                `url(${picURL ? picURL : defaultAvatar})`
        }
    };

    const collapse = (event: any) => {
        let slideMenu = document.getElementById('slide-menu')
        if (slideMenu != null) {
            slideMenu.style.opacity = '0'
            slideMenu.style.left = '-768px'
        }
    };

    return (
        <>
            <div className="header-menu "
            style={{
                height: isNotchScreen? "75px":"60px"
            
            }}
            >
                <MenuHeader expand={expand} />
                <SlideMenu firstName={firstName} school={school} picURL={picURL} collapse={collapse} routes={routes} />
            </div>

        </>
    )
}

export default BugerMenu
