import React, { Fragment } from "react"
import { Grid } from "@material-ui/core"
import {
    AdvisingAdmission, tutoringCounselling,
    collegeCounsellingOnBoarding
} from "../../../../components/icons/Icons"
import '../component/studentLookingFor.scss'
import { navigatetoUrl } from "utils/helper/helper"
import { useHistory } from "react-router"
import { useTranslation } from 'react-i18next';

const StudentLookingForCards = () => {
    const history = useHistory();
    const {t} = useTranslation();
    return (
        <div className="student-looking-options-component">
            <Grid container md={12} sm={9} xs={12} className="student-looking-options-grid">
                <Grid container lg={8} md={12} sm={12}
                    className="student-looking-grid-card stuent-cards-sing-grid"
                    onClick={() => navigatetoUrl(history, "/sign-up")}>
                    <div className="and-or-div-lg">
                        <div className="and-or">
                            <hr />
                            <span className="and-or-span"> And / or</span>
                        </div>
                    </div>
                    <Grid item md={6} sm={12}>
                        <div className="student-Looking-Card-Content">
                            <img src={tutoringCounselling} alt="" />
                            <div className="student-looking-grid-text">
                                <h1>Tutoring</h1>
                                <p>Learn 1:1 with an Expert Tutor starting at $15/hr.</p>
                            </div>
                        </div>
                        <div className="and-or-div-mob">
                            <div className="and-or">
                                <hr />
                                <span className="and-or-span"> And / or</span>
                            </div>
                        </div>
                    </Grid>
                    <Grid item md={6} sm={12} >
                        <div className="student-Looking-Card-Content">
                            <img src={AdvisingAdmission} alt="" />
                            <div className="student-looking-grid-text">
                                <h1>Admissions Advising</h1>
                                <p>Match with an Admissions Expert (Nursery through Grad School).</p>
                            </div>
                        </div>
                    </Grid>

                </Grid>
                <Grid item lg={4} md={12} sm={12} 
                className="student-looking-grid-card onboarding-college-counseling-grid">
                    <div className="student-Looking-Card-Content">
                        <img src={collegeCounsellingOnBoarding} alt="" />
                        <div className="student-looking-grid-text">
                            <h1>{t('college-counselling-guide')}</h1>
                            <p>World-class admissions content from Arete Educational Consulting experts; Exclusively for TCD starting at $29.</p>
                            <p className="view-lessons-link"  onClick={() => navigatetoUrl(history, "/college-counseling")}>View Lessons</p>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}
export default StudentLookingForCards