import { TutorSignUpConstant, LogoutConstant } from "../../constant"

const initialState = {
    isError: false,
    isloading: false,
    teacherType: "",
    value: {
        subject: "",
        test: "",
        specialization: "",
    },
}

const tutorApplicationInfoReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case TutorSignUpConstant.TUTOR_SIGNUP_REQUEST:
            return {
                ...state,
                isloading: true,
            }
        case TutorSignUpConstant.SET_TUTOR_DATA_SUCCESS:
            return {
                ...state,
                teacherType: action.props.teacherType,
                value: action.props.value,
            }
        case TutorSignUpConstant.TUTOR_SIGNUP_SUCCESS:
            return {
                ...state,
                isError: false,
                isloading: false,
            }
        case TutorSignUpConstant.TUTOR_SIGNUP_FAILURE:
            return {
                ...state,
                isError: true,
                isloading: false,
            }
        case LogoutConstant.CLEAR_DATA:
            return initialState    
        default:
            return state
    }
}

export default tutorApplicationInfoReducer
