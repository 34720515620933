import React from "react"
import { useTranslation } from 'react-i18next';
import ButtonUpdated from "../../../../../components/button/ButtonUpdated"


const SearchButton = ({ handleFilterSearch, isloading,isFilter }: { handleFilterSearch: any, isloading:boolean,isFilter:boolean }): JSX.Element => {
    const { t } = useTranslation();
    return (
        <div className="search-button-container">
            <div className= {isFilter ? "mobile-button-background":""}>
            <ButtonUpdated
                title={t("search")}
                clickAction={handleFilterSearch}
                buttonStyle={{
                    width: "392.7px",
                    height: "50px",
                    display: "flex",
                    justifyContent: "center",
                    marginButtpm:"15px"
                }}
                isloading={isloading}
                isDisabled={isloading}
            />
            </div>
        </div>
    )
}

export default SearchButton

