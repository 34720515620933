import { TutorSignUpConstant, LogoutConstant } from "../../constant"

const initialState = {
    isLoading: false,
    options: {
        email: "",
        firstName: "",
        lastName: "",
        phoneNumber: "",
        password: "",
        profilePicture: {},
    },
}

const tutorMyprofileReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case TutorSignUpConstant.SET_MY_PROFILE_TUTOR_DATA:
            return {
                options: {
                    ...state.options,
                    ...action.props,
                },
            }
        case LogoutConstant.CLEAR_DATA:
            return initialState  
        default:
            return state
    }
}

export default tutorMyprofileReducer
