import React from "react"
import { LinkRoundedIcon } from "../icons/Icons"
import DefaultAvatar from "../../assets/images/default-avatar.png";
interface Iprops {
    tutor: string
    size: string
}
const TutorAvatar = ({ tutor, size }: Iprops) => {
    return (
        <div className ="tutor-avatar">
            <img
                src={tutor ? tutor : DefaultAvatar}
                className="img-rounded-icon"
                style={{ width: size+"px", height: size+"px", objectFit: "cover" }}
            />
            <p className="icon">
                <LinkRoundedIcon />
            </p>
        </div>
    )
}

export default TutorAvatar
