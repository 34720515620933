import React, {Fragment} from "react"

interface IProps {
    title: string
}

const TitleLabel = ({title}: IProps): JSX.Element => {
    
    return (
        <Fragment>
            <div className="title">
                {title}
            </div>
        </Fragment>
    )
}
export default TitleLabel
