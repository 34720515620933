import React from "react"
import interfaceConstant from "../../../utils/constant/interfaceConstant"

class BackArrowSvgIcon extends React.PureComponent<interfaceConstant.SvgIconProps> {
    render(): JSX.Element {
        const { width, height, iconColor, ...otherProps } = this.props
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" {...otherProps}>
                <g fill="none" fillRule="evenodd">
                    <g fill={iconColor}>
                        <path d="M17.47 2.519c-.705-.692-1.848-.692-2.554 0l-8.382 8.216c-.712.699-.712 1.831 0 2.53l8.382 8.216c.706.692 1.85.692 2.555 0 .705-.691.705-1.813 0-2.504L10.354 12l7.117-6.977c.705-.691.705-1.813 0-2.504z" />
                    </g>
                </g>
            </svg>
        )
    }
}

export default BackArrowSvgIcon