import React, { useCallback, useState } from "react"
import { ZoomMtg } from "@zoomus/websdk"
import { joinSessionVendor } from "../../utils/services/vendorService"
import "./zoom.scss"
import LoaderSvgIcon from "../../assets/images/svgIcons/LoaderSvgIcon"
import { useEffect } from "react"
import ChatView from "../chat-view/ChatView"
import { useSelector } from "react-redux"
import Button from "components/button/Button"
import WhiteBoard from "components/WhiteBoard/whiteboard"
import BackArrowSvgIcon from "assets/images/svgIcons/BackArrowSvgIcon"
import ChatSvgIcon from "assets/images/svgIcons/ChatSvgIcon"
import colors from "assets/colors"
import tcdmainlogo from "../../assets/images/tcd-logo-main.svg"

interface zoomConfig {
    apiKey: string;
    apiSecret: string;
    meetingNumber: string;
    leaveUrl: string;
    passWord: string;
    role: number;
    userName: string;
    signature: string;
}
interface returnVendorData {
    meetingNumber: string;
    signature: string;
    apiKey: string;
    passWord: string;
}

const Zoom = ({
    sessionId,
    bookingId,
    platform,
    userName,
    userType,
}: {
    sessionId: string,
    bookingId: string,
    platform?: string,
    userType: string,
    userName: string,
}) => {
    const [whiteBoardlink, setWhiteBoardLink] = useState<string>()
    const [isloading, setIsLoading] = useState<any>(false)
    const [isloadingchat, setIsLoadingChat] = useState<any>(false)
    const epicBoard = useSelector((state: any) => state.epicBoardReducer.epicBoard)
    const [whiteBoardEnable, setWhiteBoardEnable] = useState<boolean>(false)
    const [showChat, setShowChat] = useState<boolean>(false || platform === 'true');

    /**
     * If meeting is not valid leave it
     */
    useEffect(() => {
        if (sessionId && platform !== 'true') {
            startZoomMeeting().catch(console.error)
        }
        return () => {
            if (sessionId && platform !== 'true') {
                ZoomMtg.leaveMeeting({})
            }
        }
    }, [sessionId])

    const startZoomMeeting = useCallback(
        async function createMeeting() {
            setIsLoading(true)
            setIsLoadingChat(false)
            const zoomConfig = await getVendorConfig(sessionId)
            setWhiteBoardLink(process.env.REACT_APP_WHITEBOARD_LINK + zoomConfig.meetingNumber)
            ZoomMtg.setZoomJSLib("https://source.zoom.us/2.17.0/lib", "/av")
            ZoomMtg.preLoadWasm()
            ZoomMtg.prepareWebSDK()
            try {
                await joinMeeting(zoomConfig)
            } catch (e) {
                return (
                    <div>
                        <h1>Unable to join Meeting</h1>
                    </div>
                )
            }
        },
        [sessionId],
    )

    const setShowChatValue = (val: boolean) => {
        if (platform === 'true') {
            setShowChat(true);
        } else {
            setShowChat(val);
        }
    }

    const joinMeeting = async (meetingConfig: zoomConfig) => {
        return new Promise<void>((res, rej) => {
            if (!meetingConfig.signature || meetingConfig.signature === "") {
                try {
                    console.log("Generating signature", meetingConfig);
                    meetingConfig.signature = ZoomMtg.generateSDKSignature({
                        meetingNumber: meetingConfig.meetingNumber,
                        sdkKey: meetingConfig.apiKey,
                        sdkSecret: meetingConfig.apiSecret,
                        role: `${meetingConfig.role}`,
                        success: function (res: any) {
                            console.log("Response for signature", res);
                        }
                    });
                    console.log("Obtained signature", meetingConfig);
                } catch (e) {
                    console.log("Error generating signature!", e);
                }
            }

            ZoomMtg.init({
                debug: true,
                leaveUrl: meetingConfig.leaveUrl,
                isSupportAV: true,
                disableVoIP: false,
                success: function () {
                    console.log("Zoom init success");
                    let joinButtonFound = false;
                    // Here we set a 2 second timer to keep attempting to click on the join button.
                    // We do this because the browser has to request access to the mic and/or the
                    // camera and if we attempt to click join before they have accepted it will not
                    // do anything so we have to keep trying until the join button disappears
                    // indicating the join button finally worked.
                    const joinTimer = setInterval(() => {
                        const joinButton: any = document.getElementById("join-btn")
                       
                        if (joinButton) {
                            console.log("Attempting to click on Join button");
                            joinButtonFound = true;
                            joinButton.click();
                        } else if (joinButtonFound) {
                            console.log("Join button is gone, we can stop trying as the user should have joined now.");
                            clearInterval(joinTimer);
                        }
                    }, 2000);
                    ZoomMtg.join({
                        meetingNumber: meetingConfig.meetingNumber,
                        userName: meetingConfig.userName,
                        signature: meetingConfig.signature,
                        sdkKey: meetingConfig.apiKey,
                        passWord: meetingConfig.passWord,
                        success: () => {
                            console.log("Zoom join success")
                            setTimeout(() => {
                                const startButton: any = document
                                    .getElementsByClassName("join-dialog")
                                    .item(0)
                               
                                if (startButton) {
                                    console.log("Hiding start button");
                                    startButton.style.visibility = "hidden"
                                } else {
                                    console.log("No start button found");
                                }
                            }, 1000)
                            setTimeout(() => {
                                const joinAudio :any =document.getElementsByClassName("join-audio-by-voip__join-btn").item(0);
                                const joinVideo :any =document.getElementsByClassName("send-video-container__btn").item(0);
                                if (joinAudio && !platform){
                                    console.log("Clicking on Join Video button");
                                    joinVideo.click();
                                    console.log("Clicking on Join Audio button");
                                    joinAudio.click();
                                } else {
                                    console.log("No join audio button found");
                                }
                            }, 5000)
                            if (platform) {
                                console.log("Hiding Zoom Meeting root");
                                const zoomMeeting: any = document.getElementById("zmmtg-root")
                                zoomMeeting.style.display = "none"
                            }
                            setIsLoading(false);
                            setIsLoadingChat(true);
                            ZoomMtg.showInviteFunction({
                                show: false,
                            });
                            ZoomMtg.showRecordFunction({
                                show: false,
                            });
                            res();
                            console.log("Zoom done joining");
                        },
                        error: (error: any) => {
                            console.error("Error joining zoom meeting", error)
                            rej(error)
                        },
                    })
                },
                error: (error: any) => {
                    console.error("Error initializing zoom meeting", error)
                    rej(error)
                },
            })
        })
    }

    const roleReturn = useCallback(
        (userType: string) => {
            if (userType.toUpperCase() == "TEACHER") {
                return 1
            } else {
                return 0
            }
        },
        [userType],
    )

    useEffect(() => {
        setShowChatValue(false);
        const whiteboard: any = document.getElementById("Whiteboard");
        const iPadNotSupportedContainer: any = document.getElementById("iPadNotSupportedContainer");
        if (whiteboard) {
            if (whiteBoardEnable) {
                whiteboard.style.display = "block";
                if (iPadNotSupportedContainer && iPadNotSupportedContainer.style) {
                    iPadNotSupportedContainer.style.display = "none";
                }
            } else {
                whiteboard.style.display = "none";
                if (iPadNotSupportedContainer && iPadNotSupportedContainer.style) {
                    iPadNotSupportedContainer.style.display = "block";
                }
            }
        }
    }, [whiteBoardEnable])

    const getVendorConfig = useCallback(
        async function (sessionId: string) {
            const newRole = roleReturn(userType)
            const vendorReturnData: returnVendorData = await joinSessionVendor(newRole, sessionId)
            const displayname = userName
            const zoomConfig = {
                apiKey: vendorReturnData.apiKey,
                apiSecret: vendorReturnData.apiSecret,
                meetingNumber: vendorReturnData.meetingNumber,
                leaveUrl: "/dashboard",
                passWord: vendorReturnData.passWord,
                role: newRole,
                userName: displayname,
                signature: vendorReturnData.signature,
            }

            return zoomConfig
        },
        [userName, userType],
    )

    return (
        <>
            {isloading ? (
                <div className="backDrop">
                    <div className="wrapper">
                        <div className="backgroundWhite"></div>
                        <LoaderSvgIcon />
                    </div>
                </div>
            ) : (
                <div></div>
            )}
            {!isloading &&
                <div>
                    {platform !== 'true' &&
                        <div className={`${platform === 'true' ? 'display-none' : 'top-bar'} `}>
                            {showChat ?
                                <span className="back-arrow" onClick={() => setShowChatValue(false)}>
                                    <BackArrowSvgIcon iconColor={colors.darkMango} width="32" height="32" />
                                </span>
                                :
                                <Button
                                    title={`Go to ${whiteBoardEnable ? "Meeting" : "Whiteboard"}`}
                                    className=""
                                    style={{
                                        "height": "34px",
                                        "borderColor": colors.darkMango,
                                        "width": "180px",
                                        "borderWidth": "1px",
                                        "backgroundColor": "white",
                                        "color": colors.darkMango,
                                        "marginTop": "3px",
                                        "marginBottom": "3px"
                                    }}
                                    clickAction={() => setWhiteBoardEnable(!whiteBoardEnable)}
                                ></Button>
                            }
                            <span className="back-arrow float-right" onClick={() => setShowChatValue(!showChat)}>
                                <ChatSvgIcon iconColor={colors.darkMango} width="36" height="36" />
                            </span>
                        </div>}
                    {showChat &&
                        <div id="mobile-chat-view" className={`${platform === 'true' ? "platform-true" : ""}`}>
                            <ChatView chatId={epicBoard.id} />
                        </div>}
                    {platform !== 'true' &&
                        <>
                            <ChatView chatId={epicBoard.id} />
                            <div id="content">
                                {/* <span className= "ToggleButton" onClick={toggleView}> */}
                                {
                                    // <WhiteboardSvgIcon className="ShapeVideo" width={'400px'} height ={'400px'} iconColor={'#f9a722'}/>
                                    <div className="position">
                                        <Button
                                            title="Meeting"
                                            className={
                                                whiteBoardEnable
                                                    ? "meetingButton meetingButtonshape"
                                                    : "whiteBoardButton meetingButtonshape"
                                            }
                                            clickAction={() => setWhiteBoardEnable(!whiteBoardEnable)}
                                        ></Button>
                                        {((navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 0) || navigator.platform === 'iPad') &&
                                            <span className="ipadNotSupported" id="iPadNotSupportedContainer">Screen Share NOT Available on IPad</span>}
                                        <Button
                                            title="Whiteboard"
                                            className={
                                                whiteBoardEnable
                                                    ? "whiteBoardButton whiteBoardshape"
                                                    : "meetingButton whiteBoardshape"
                                            }
                                            clickAction={() => setWhiteBoardEnable(!whiteBoardEnable)}
                                        ></Button>
                                    </div>
                                }
                                {/* </span> */}
                                <WhiteBoard whiteBoardlink={whiteBoardlink} />
                                {!showChat &&
                                    <img src={tcdmainlogo} />
                                }
                            </div>
                        </>}
                </div>
            }
        </>
    )
}

export default Zoom
