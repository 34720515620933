import React from "react";
import Modal from "components/modal_/Modal";
import StudentTutorRejectionAvatar from "components/student-tutor-rejection-avatar/StudentTutorRejectionAvatar"
import 'popups/booking/student/popups/StdTutorAcceptRequest.scss';
import Button from "components/button/Button";

interface TutorStdDoesntProcessMyRequests {
    studentPicUrl: string,
    teacherPicUrl: string,
    onGoto: () => void,
    studentName: string,
    isShown: boolean,
    onClose: () => void
}

export function TutorStdDoesntProcessMyRequest(props: TutorStdDoesntProcessMyRequests) {
    return <Modal
        visible={props.isShown}
        onExit={props.onClose}
    >
        <div className="model_stddoestnotprocessmurequest">
            <div className="imgs_div">
                <StudentTutorRejectionAvatar
                    size="100"
                    student={
                        props.studentPicUrl
                    }
                    tutor={
                        props.teacherPicUrl
                    }
                />

            </div>

           <div style={{padding:"15px"}}>
           <h2>Sorry, {props.studentName} still hasn’t confirmed.</h2>
            <h4>Your request has been canceled.</h4>
           </div>
            <div className="tutor_reject_session">
                <Button className="" title="Go to Dashboard" isOutlineStyle={true} clickAction={props.onGoto} />
            </div>

        </div>
    </Modal>
}
