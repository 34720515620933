import React from "react"
import { useTranslation } from 'react-i18next';
import SwitchComponent from "../../../../../components/switch/SwitchComponent"



const BackgroundCheckFilter = ({ backgroundCheck, setBackgroundCheck }: { backgroundCheck: any, setBackgroundCheck: any }): JSX.Element => {
    const { t } = useTranslation();
    return (
        <div className="tutor-search-filter">
            <SwitchComponent active={backgroundCheck} onTap={setBackgroundCheck} label={"Background Check Verified"} />
        </div>
    )
}

export default BackgroundCheckFilter

