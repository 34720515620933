
import React, { useState } from "react";


const WhiteBoard = ({ whiteBoardlink }: any) => {


    return <>
    
    <iframe id="Whiteboard" className='whiteBoard'src={whiteBoardlink} title="TCD WhiteBoard"></iframe>
    </>
}

export default WhiteBoard;