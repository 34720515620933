import React, { Fragment } from "react"
import { OnBoardingHeader } from "../on-boarding/OnboardingHeader"
import { OptionCardView } from "../card/CardView"
import Button from "components/button/Button";

interface Props {
    heading: string
    subHeading: string
    options: any
    onOptionPress: any
    backNavigation?: any
    bottomOptionComponent?: any
}
const OptionScreenComponent = ({
    heading,
    subHeading,
    options,
    onOptionPress,
    backNavigation,
    bottomOptionComponent,
}: Props): JSX.Element => {
    const renderOptions = (): JSX.Element => {
        return options.map((obj: any) => (
            <div>
            <OptionCardView key={obj.key}>
            <div className="content" onClick={() => onOptionPress(obj.key)}>
                <img alt="" src={obj.icon}></img>
                <div className="text-container">
                    {obj.title==="Student" || obj.title==="Tutor / Advisor"? <div className="pre-heading">A New</div>:null}
                    <div className="heading">{obj.title}</div>
                    <div className="sub-heading">{obj.subtitle}</div>
                </div>
            </div>
            {
                obj.title==="Student" || obj.title==="Tutor / Advisor"?
                <div className="onbaording-navigate-btn-container">
                    <Button className="navigation-button" title="Join us" clickAction={() => onOptionPress(obj.key)} />
                </div>:null
            }

        </OptionCardView>
       </div>
        ))
    }
    return (
        <Fragment>
            <div className="option-screen-component">
                <OnBoardingHeader heading={heading} subHeading={subHeading} />
                <div className="multiple-cards">{renderOptions()}</div>
                {bottomOptionComponent && bottomOptionComponent()}
                {backNavigation && backNavigation()}
            </div>
        </Fragment>
    )
}

export default OptionScreenComponent
