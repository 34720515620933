import { useContext, useMemo } from "react";
import {
    TutorSearchStoreContext,
    selectedTutorSelector,
} from "store/tutor-search/store";
import { MAX_RAPID_BOOKING_TIME } from "utils/constant/BookingConstant";
import moment, { ISO_8601 } from "moment";

export type RapidBookingType = {
    status: boolean,
    modifiedAt: Date,
    minutes: number,
    offTime: Date,
}

export const useSelectedTutorRapidBookingInfo = (): RapidBookingType => {
    const { state } = useContext(TutorSearchStoreContext);
    const { rapidBooking } = selectedTutorSelector(state);
    const modifiedAt = useMemo(() => moment(rapidBooking?.modifiedAt, ISO_8601), [rapidBooking?.modifiedAt]);
    const formattedRapidBooking = useMemo(() => ({
        status: rapidBooking?.status,
        modifiedAt: modifiedAt.toDate(),
        minutes: rapidBooking?.minutes,
        offTime: moment(modifiedAt).add(MAX_RAPID_BOOKING_TIME, "minutes").toDate()
    } as RapidBookingType), [rapidBooking?.status, rapidBooking?.modifiedAt]);

    return formattedRapidBooking;
}