import { call, put, all, takeLatest, select } from "redux-saga/effects"
import {
    tutorConstant
} from "../constant"
import {
    getTutor
} from "../../utils/services/tutorSearchServices"



function* fetchTutor(props: any) {
    const { teacherId } = props.payload;
    try {
        const response = yield getTutor(teacherId)
        yield put({ type: tutorConstant.REQUEST_TUTOR_SUCCESS, payload: { ...response } })
    } catch (error) {
        yield put({ type: tutorConstant.REQUEST_TUTOR_FAILURE, payload: error })

        console.log(error, "error")
    }
}

export default all([
    takeLatest(tutorConstant.REQUEST_TUTOR, fetchTutor),
])
