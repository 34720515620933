/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useCallback } from "react"
import Modal from "./../SimpleModal"
import StudentTutorAvatar from "../../student-tutor-avatar/StudentTutorAvatar"
import Button from "../../button/Button"
import { Note } from "components/tips/Note"
import { useIsPhoneOrTablet } from "utils/hooks/useIsPhoneOrTablet"
import { getWindowDimensions } from "utils/helper/window-dimesions"
import { useLocation } from "react-router-dom"

interface JoinSession {
    currentUserPicUrl: string;
    withUserPicUrl: string;
    onGoto: () => void;
    withUserName: string;
    isShown: boolean;
    onClose: () => void;
}

export function JoinSession(props: JoinSession) {

    const isPhoneOrTablet = useIsPhoneOrTablet();
    const dimensions = getWindowDimensions() || false;
    const platform = localStorage.getItem("platform") ||false;
    return (
        <div className="join-session-modals">
            <Modal visible={props.isShown} onExit={props.onClose}>
                <div className="model_join_session">
                    <div className="imgs_div">
                        <StudentTutorAvatar
                            size="100"
                            student={props.currentUserPicUrl}
                            tutor={props.withUserPicUrl}
                        />
                    </div>
                    <h2>Join Session with {props.withUserName}</h2>

                {(!platform && dimensions?.width <= 768 && isPhoneOrTablet)?
                   (
                    <>
                    <Note title="Please note" content="Currently, our mobile web versions are undergoing necessary updates. Please use the browser on a computer / desktop while we complete these. We apologize for any inconvenience this may cause!"/>
                    </>
                    ) :
                    (<></>)  

                    }
                    <div className="action-items">
                        <Button className="outlined" title="Cancel" clickAction={props.onClose} />
                        <Button className="" title="Join Session" clickAction={props.onGoto} />
                    </div>
                </div>
            </Modal>
        </div>
    )
}
