/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext } from "react"
import "./tutor-search-list.scss"
import colors from "../../../../assets/colors"
import ButtonUpdated from "../../../../components/button/ButtonUpdated"
import RatingsComponent from "../../../../components/ratings/RatingsComponent"
import StarSvgIcon from "../../../../assets/images/svgIcons/StarSvgIcon"
import ShieldSvgIcon from "../../../../assets/images/svgIcons/ShieldSvgIcon"
import { TutorSearchStoreContext } from "../../../../store/tutor-search/store"
import { navigatetoUrl } from "../../../../utils/helper/helper"
import { setSelectedTutor } from "../../../../store/tutor-search/actions/actions"
import { useSelector, useDispatch } from "react-redux"
import { toggleFavorites } from "../../../../store/actions/userActions"
import subjectsAdvisingList from "../../../../utils/constant/subjectsAndAdvisingList"
import NoSearchResultSvgIcon from "../../../../assets/images/svgIcons/NoSearchResultsIcon"
import useAvgRating from "../../../../utils/hooks/useAvgRating"
import { Avatar, Tooltip } from "@material-ui/core"
import LoaderSpinner from "../../../../components/loader/LoaderSpinner"
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";
import Button from "components/button/Button";
import retry from "../../../../assets/images/retry.png";

interface ItemPros {
    userId: string
    firstName: string
    fistName?: string
    lastName: string
    oneToOneSessionRate: number
    profilePic: any
    subjects: string[]
    avgRating: string
    reviews: any[],
    totalMinutesWorked: number
}
interface ListItemProps {
    item: ItemPros
    history: any
    filteredSubjects: string[]
}

interface Props {
    history: any
    data: any,
    isLoading: boolean,
    setRapidBook: any,
    setRapidBookToggle: any,
    rapidBookToggle: boolean
    rapidBook: boolean
    isError: boolean
    filteredSubjects: string[],
}

const nameLength = 7;
const ListItem = ({ item, history, filteredSubjects }: ListItemProps): JSX.Element => {
    const isDesktopOrLaptop = useMediaQuery({ query: '(min-device-width: 1224px)' });
    const { dispatch } = useContext(TutorSearchStoreContext)
    const { userAuthData } = useSelector((state: any) => state.auth)
    const { firstName, lastName, subjects, profilePic } = item
    const dispatchHook = useDispatch()
    const filteredSubjectNames = (filteredSubjects.map((option: any) => option.name)).sort()
    const subjectString = (subjectsAdvisingList.filter((subject: any) => subjects.includes(subject.id)).map((option) => option.name).sort())
    const filteredSubjectString = subjectString.filter((el: any) => filteredSubjectNames.includes(el))
    const subjectsMerged = [...filteredSubjectString, ...subjectString]
    let countSubjects: number = 0
    function getUnique(array: any){
        var uniqueArray = [];
        for(var value of array){
            if(uniqueArray.indexOf(value) === -1){
                uniqueArray.push(value);
            }
        }
        return uniqueArray;
    }
    const subjectsReorder = getUnique(subjectsMerged)

    return (
        <div className="search-item">
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    /* width: "30%", */
                    alignItems: "center",
                    width:"25%"
                }}
                className="tutor-show-name-rating"
            >
                <div
                    style={{
                        marginRight: "10px",
                        height: "100%",
                        position: "relative",
                    }}
                >
                    <div
                        className="ribbon-container"
                        style={{
                            position: "absolute",
                            marginLeft: "-10px",
                            marginTop: "-10px",
                            zIndex: 1,
                        }}
                    >
                        <ShieldSvgIcon width="30px" height="30px" />
                    </div>

                    <Avatar
                        src={profilePic ? profilePic.downloadURL : ""}
                        style={{
                            width: 55,
                            height: 55,
                            backgroundColor: "lightgrey",
                            borderRadius: "100px",
                            objectFit: "cover",
                            zIndex: 0,
                        }}
                    />
                </div>
                <div>
                    <Tooltip title={firstName + ' ' + lastName} >
                        <div className="tutor-name">
                            {`${firstName ?
                                isDesktopOrLaptop ?
                                    firstName.length > nameLength ? firstName.substring(0, nameLength) + '..' : firstName
                                : firstName
                                : item.fistName} ${lastName ? lastName.charAt(0) : undefined
                            }. `}</div>
                    </Tooltip>
                    <div>
                        <RatingsComponent
                            obtainedRating={item.avgRating ? parseFloat(item.avgRating) : 0}
                            iconSize={"15px"}
                        />
                    </div>
                </div>
            </div>
            <div className="subjects-hours">
                <Tooltip
                    title={subjectsAdvisingList
                        .filter((subject: any) => subjects.includes(subject.id))
                        .map((option) => (option.name ? option.name.trim() : '?'))
                        .join(", ")}
                >
                     <div className="subjects">
                        {filteredSubjectNames
                        .filter((element: any) => subjects.includes(element.id))
                        .map((subject: any) => {
                            return (countSubjects++ >= 2) ?
                            <></>
                            : 
                            <>
                                <span>{countSubjects >= 2 ? ", " : ""}</span>
                                <span className="search-text-bold subject-filtered-exact-match"> {subject ? subject.trim() : ""}</span>
                            </>
                        }).join(", ")}
                        {subjectsReorder.map((subject: any, index: any) => {
                            return (countSubjects++ >= 2) ? 
                            <></>
                            :
                            filteredSubjectNames.includes(subject) ?
                            <>
                                <span>{countSubjects >= 2 ? ", " : ""}</span>
                                <span className="search-text-bold subject-reorder-exact-match"> {subject ? subject.trim() : ""}</span>
                            </>
                            :
                            <>
                                <span>{countSubjects >= 2 ? ", " : ""}</span>
                                {subject.trim().split(" ").map((word:string, index:number) => {
                                    return (filteredSubjectNames.includes(word)) ?
                                        <span className="search-text-bold subject-reorder-partial-match"> {word}</span>
                                        :
                                        <span> {word}</span>
                                })}
                            </>
                        })}
                    </div>
                </Tooltip>
            </div>
            <div className="search-item-tutor-hours">
                <div className="rating-text">
                    <span className="hourly-charge">{`$${item.oneToOneSessionRate}`}</span>
                    <span className="fee-label">/hour</span>
                </div>
            </div>
            <div className="search-item-view-profile">
                <Tooltip title="Favorite" className="favorites-marker">
                    <div
                        className="favorites-marker"
                        onClick={() => {
                            const oldFavorites = [...userAuthData.favorites]
                            const updatedFavorites = oldFavorites.includes(item.userId)
                                ? oldFavorites.filter((id) => id !== item.userId)
                                : [...oldFavorites, item.userId]
                            dispatchHook(toggleFavorites({ payload: updatedFavorites }))
                        }}
                    >
                        <StarSvgIcon
                            width="30px"
                            height="30px"
                            iconColor={
                                userAuthData.favorites?.includes(item.userId)
                                    ? colors.darkMango
                                    : colors.silver
                            }
                        />
                    </div>
                </Tooltip>

                <div
                    style={{
                        width: "100%",
                        height: "50%",
                    }}
                >
                    <div className="search-button-container">
                        <ButtonUpdated
                            title="View Profile"
                            textStyle={{ fontSize: "0.85vw" }}
                            buttonStyle={{ height: "30px", width: "200px" }}
                            clickAction={() => {
                                dispatch(setSelectedTutor.action(item))
                                navigatetoUrl(history, `/panel/search/tutor-profile?teacherId=${item.userId}`)
                            }}
                        />
                    </div>
                </div>
            </div>
        </div >
    )
}
const TutorSearchList = (props: Props): JSX.Element => {
    const { data, history, isLoading, setRapidBook, setRapidBookToggle, rapidBookToggle, rapidBook, isError, filteredSubjects } = props
    const { t } = useTranslation();
    return (
        <div className="search-list">
            {data.length > 0 || isLoading ? (
                data.map((tutor: any) => (
                    <ListItem key={tutor.userId} item={tutor} filteredSubjects={filteredSubjects} history={history} />
                ))
            ) : (
                <>
                    {isError ? (<div>
                        <div className="Our-Rapid-Book-live">
                            <div className="oops">
                                <img src={retry} style={{ width: "180px" }}></img>
                                <h2>{t("oops-something-went-wrong")}</h2>
                                <p> {t("please-check-internet1")}
                                    <a href="mailto:admin@theclassroomdoor.com" className="text-style-1">
                                        {t("admin_email")}
                                    </a>
                                    {t("please-check-internet2")}
                                </p>
                            </div>
                        </div>
                    </div>)
                        :
                        <div>
                            <div className="no-results-container">
                                <NoSearchResultSvgIcon width={"30%"} height={"30%"} />
                                {rapidBook ? (<div className="Our-Rapid-Book-live">
                                    <p>{t("live-our")}<span>{t("live-rapid-book")}</span>{t("live-tutors-in-session")}</p>
                                    <Button className={"reshedule-button"} clickAction={() => { setRapidBook(false); setRapidBookToggle(!rapidBookToggle) }} title={t("schedule-other-tutors")} />
                                </div>) : (<></>)}
                            </div>
                        </div>}
                </>
            )}
            {isLoading && < LoaderSpinner />}
        </div>
    )
}

export default TutorSearchList
