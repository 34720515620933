import { confirmEmail } from "../constant"

const initialState = {
    isError: false,
    message: false,
    finished: false,
}

const confirmEmailReducer = (state = initialState, action: any): object => {
    switch (action.type) {
        case confirmEmail.CONFIRM_SUCCESS:
            return {
                isError: false,
                message: action.message,
                finished: true,
            }
        case confirmEmail.CONFIRM_FAILURE:
            return {
                isError: true,
                message: action.message,
                finished: true,
            }
        default:
            return state
    }
}

export default confirmEmailReducer
