import React, { useCallback, useEffect, useState } from "react"
import { Dialog, DialogActions, DialogContent, DialogTitle, Modal } from "@material-ui/core"
import Button from "../../components/button/Button"

const BrowserNotification = ({ platform }: { platform?: string }) => {
    const [showAlert, setShowAlert] = useState<boolean>(true)
    const browsercheck = useCallback(() => {
        if (!platform) {
            return navigator.userAgent.toLowerCase().indexOf("firefox") > -1
                ? false
                : /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor)
                ? false
                : true
        }
        return false
    }, [platform])
    useEffect(() => {
        setShowAlert(browsercheck())
    }, [])
    return (
        <div>
            <Dialog open={showAlert} onClose={() => setShowAlert(false)}>
                <DialogTitle className="text-warning">Information</DialogTitle>
                <DialogContent className="p-4">
                    <p>Audio and Screen Sharing Functionality is not available for Safari. </p>
                    <p className="text-warning">
                        Kindly use other browsers like FireFox or Chrome.
                    </p>
                </DialogContent>
                <DialogActions style={{ justifyContent: "center", padding: "15px 30px" }}>
                    <Button title={"Ok"} clickAction={() => setShowAlert(false)} />
                </DialogActions>
            </Dialog>
        </div>
    )
}
export default BrowserNotification
