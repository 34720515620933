import React from "react"
import { Container } from "@material-ui/core"
import VideoPlayer from "../video/VideoPlayer"


const LessonVideo = ({ lessonVideo }: { lessonVideo: any }): JSX.Element => {
    return (
        <>
            {lessonVideo
                ? (
                    <div className="lesson-video">
                        <VideoPlayer source={"https:" + lessonVideo?.fields?.file?.url} />
                    </div>

                ) : (
                    <></>
                )
            }
        </>

    )
}

export default LessonVideo