import React from "react"
import Button from "../../../components/button/Button"
import { navigatetoUrl } from "../../../utils/helper/helper"
import ButtonUpdated from "../../../components/button/ButtonUpdated"
import ColoredTCDlogo from "../../../assets/images/colored-TCD-logo.png"
import {useMediaQuery} from "react-responsive"
import TcdSvgLogoMob from "../../../assets/images/svgIcons/tcdSvgLogoMob"


type Props =  {
    history: any
}
const LandingPage=(props:Props)=>  {
    const isMobile = useMediaQuery({ query: '(max-device-width: 767px)' });
        return (
            <div className="landing-page">
                <div className="landing-page-container">
                    <div className="welcome-logo-btn-signin"> 
                    {isMobile?
                    <TcdSvgLogoMob />:<img src={ColoredTCDlogo} className="color-logo-sigin-screen" />

                    }
                    <div className="sign-in-button">
                        <Button
                            title="Sign in"
                            clickAction={() => navigatetoUrl(props.history, "/sign-in")}
                        />
                    </div>
                    </div>
                    <div className="content">
                        <div className="header">
                            <div className="heading">Welcome to</div>
                            <div className="sub-heading">The Classroom Door</div>
                        </div>
                        <hr></hr>
                        <div className="description">
                            <div className="title">Empowering Students & Inspiring Educators</div>
                            <div className="sub-title">
                                Match with the world’s best Tutors / Advisors and access premier college counseling lessons.
                            </div>
                        </div>
                        <div className="start-button">
                        <ButtonUpdated 
                            title="Let's start"
                            clickAction={() => {
                                navigatetoUrl(props.history, "/my-role")
                            }}
                            buttonStyle={{ height: "48px", width: "200px" }}
                        />
                    </div>
                    </div>
          
                </div>
            </div>
        )
    }
export default LandingPage;