import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { fetchPlans } from "../../../store/actions/PaymentActions"
import { Grid } from "@material-ui/core"
import SubscriptionItems from "../../../components/subscription/SubscriptionItems"
import { useTranslation } from 'react-i18next';
import { navigatetoUrl } from "utils/helper/helper"
import { useHistory, useLocation } from "react-router-dom"
import OnboardingBackground from "components/on-boarding/onboarding-background/OnboardingBackground"
import RoundedButton from "components/rounded-button/RoundedButton"
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons"
import Button from "components/button/Button"
import Quotation from '../../../assets/images/quotation.png'

const LessonOptionsPage = () => {

    const dispatch = useDispatch();
    const products = useSelector((state: any): any => state.payment.plans);
    const history = useHistory();
    const location = useLocation();

    const { t } = useTranslation();
    
    useEffect(() => {
        dispatch(fetchPlans())
    }, []);

    return (
        <>
            <OnboardingBackground />
            <div className="top-space-extra subscription-options-onBoarding">
            <Grid container >
                <Grid item xl={2} lg={1} md={1} sm={12} xs={12}></Grid>
                <Grid item xl={8} lg={10} md={10} sm={12} xs={12} className="main-content onbaording-subscription-content">
                    <div className="signup-back-btn subscription-options-back-btn-inapp">
                        <RoundedButton
                            onPress={() => {
                                navigatetoUrl(history, "/panel/lessons")
                            }}
                            iconProps={{
                                icon: faChevronLeft,
                                style: {
                                    color: "#262ffa",
                                    alignSelf: "center",
                                    marginRight: "2px",
                                },
                            }}
                            className="book-back-button book-back-button-left"
                            active={true}
                        />
                    </div>
                    <div className="subscription-options">
                        <p className="subscription-head p-60">{t('choose-subscription')}</p>
                        <p className="subscription-subhead p-18 p-battleship">{t('proven-college-counselors')}</p><br/>
                        <Button className="dashboard-college-counselig-button view-lesson-button" title={"View Lessons"} clickAction={() => {
                            navigatetoUrl(history, "/panel/lessons")
                        }} /><br/>
                        <SubscriptionItems products={products} />
                        <img className="bottom-space" src={Quotation}/>
                        <p className="subscription-subhead subscription-subhead-left p-18 p-battleship subscription-quote">{t('college-guide')}</p>
                        <p className="subscription-subhead-last subscription-subhead-left p-18 p-battleship right-name subscription-quote">Jessica B.</p>
                    </div>
                </Grid>
                <Grid item xl={2} lg={1} md={1} sm={12} xs={12}></Grid>
            </Grid>
            </div>
        </>
    )
}

export default LessonOptionsPage
