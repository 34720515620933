import React from "react"
import { useTranslation } from 'react-i18next';
import SwitchTabComponent from "../../../../../components/tabs/switch-tab/SwitchTabComponent"



const GoalsAndObjectiveFilter = ({ tutorTypes, goalsObjectives, setGoalsObjectives }: { tutorTypes: any, goalsObjectives: any, setGoalsObjectives: any }): JSX.Element => {
    const { t } = useTranslation();
    return (
        <div className="switch-tab tutor-search-filter">
            <SwitchTabComponent
                title={t("what-are-you-looking-for")}
                options={Object.values(tutorTypes)}
                selectedOption={goalsObjectives}
                onTabClick={setGoalsObjectives}
            />
        </div>
    )
}

export default GoalsAndObjectiveFilter

