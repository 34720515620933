import React, { useEffect, useState } from "react"
import {  useHistory } from "react-router-dom"
import UserPanel from "../../../../components/dashboard/UserPanel"
import { useDispatch, useSelector } from "react-redux"
import defaultAvatar from "../../../../assets/images/default-avatar.png"
import { getStudentAdvisors, getStudentTutors, getStudentUpcomingSessions } from "../../../../store/actions/studentDashboardActions"
import { navigatetoUrl } from "../../../../utils/helper/helper"
import moment from "moment";


const DashboardCalendar = (): JSX.Element => {
    const { userAuthData } = useSelector((state: any) => state.auth)
    const sessions = useSelector((state: any) => state.studentDashboard.upcomingSessions.data);
    const [tasks, setTasks] = useState({
        overdue: [] as Array<any>,
        deadlines: [] as Array<any>,
        upcoming: [] as Array<any>,
    });
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        const userId = userAuthData?.userId;
        if (!userId) {
            return;
        }
        dispatch(getStudentUpcomingSessions({ userId }));
        dispatch(getStudentAdvisors({ userId }))
        dispatch(getStudentTutors({ userId }))
    }, [userAuthData?.userId]);

    useEffect(() => {
        const currentTimeString = (new Date()).toISOString();
        let overdue = [] as Array<any>;
        let upcoming = [] as Array<any>;
        sessions.map((session: any) => {
            const endTime = moment(session.startTime).utc().add(session.sessionLength, "minutes").add(session.extensionTime || 0, "minutes");
            if (currentTimeString > endTime.toISOString()) {
            } else if (endTime.toISOString() > currentTimeString) {
                upcoming.push({
                    name: "Upcoming",
                    date: new Date(session.startTime)
                });
            }
        });
        setTasks({
            overdue,
            upcoming,
            deadlines: []
        });
    }, [sessions]);
    return (
        <>
            <UserPanel
                firstName={userAuthData.firstName}
                lastName={userAuthData.lastName}
                subtitle={userAuthData.currentSchool}
                profilePicUrl={
                    userAuthData.profilePic.downloadURL
                        ? userAuthData.profilePic.downloadURL
                        : defaultAvatar
                }
                tasks={tasks}
            />
        </>
    )
}

export default DashboardCalendar
