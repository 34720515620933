import React from "react"
import Modal from "../../../components/modal_/Modal"
import { navigatetoUrl } from "../../../utils/helper/helper"

import { verifyEmail } from "../../../store/actions/userActions"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"

const VerifyEmail = (): JSX.Element => {
    const modalInfo = useSelector((state: any) => state.confirmEmail)
    const history = useHistory()
    const dispatch = useDispatch()

    const windowUrl = window.location.search
    const params: any = new URLSearchParams(windowUrl)
    const code: string = params.get("oobCode")
    const actionMode: string = params.get("mode")

    actionMode === "resetPassword" && navigatetoUrl(history, `/forgot-password?oobCode=${code}`)

    if (!modalInfo.finished) {
        actionMode === "verifyEmail" && dispatch(verifyEmail(code))
    }

    const exitFunction = (): void => {
        window.location.href = "/sing-in"
    }

    return (
        <div>
            <Modal
                title={modalInfo.isError ? "Error" : "Success"}
                visible={modalInfo.finished}
                subtitle={modalInfo.message}
                onExit={exitFunction}
            />
        </div>
    )
}

export default VerifyEmail
