import React from "react"
import { useTranslation } from 'react-i18next';
import { InputBox } from "../../../../../components/input-field/InputFields"
import { faSearch } from "@fortawesome/free-solid-svg-icons"



const NameFilter = ({ tutorName, setTutorName, handleFilterSearch }: { tutorName: string, setTutorName: any, handleFilterSearch:any }): JSX.Element => {
    const { t } = useTranslation();
    return (
        <div className="tutor-search-filter">
            <InputBox
                title={t("tutor-advisor-name")}
                placeholder={t("search-by-name")}
                inputProps={{
                    style: {
                        height: "50px"
                    },
                    value: tutorName,
                    onChange: (event: any) => {
                        setTutorName(event.target.value)
                    },
                    onKeyPress: (event:any) => {
                        if(event.key === 'Enter'){
                            handleFilterSearch()
                          }
                    }
                }}
                type="text"
                iconProps={{
                    icon: faSearch,
                    style: {
                        color: "#f28334",
                        alignSelf: "center",
                        marginRight: "2px",
                        marginTop: "auto",
                        marginBottom: "auto"
                    },
                }}
                error=""
            />
        </div>
    )
}

export default NameFilter

