import React, { useEffect } from "react"
import { useLocation } from "react-router-dom";
import ResetPassword from "./components/ResetPassword"
import EmailVerification from "./components/EmailVerification"


const AuthenticationRedirections = (): JSX.Element => {

    const location = useLocation();
    const urlSearchParams = new URLSearchParams(location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    const { oobCode, mode } = params;


    useEffect(() => {
        if (mode !== "verifyEmail" && mode !== "resetPassword") {
            window.location.href = "https://" + process.env.REACT_APP_FIREBASE_AUTH_DOMAIN + "/__/auth/action" + location.search;
        }
    }, []);

    return (
        <>
            {mode === "verifyEmail" &&
                <EmailVerification oobCode={oobCode} />
            }
            {mode === "resetPassword" &&
                <ResetPassword oobCode={oobCode} />
            }
        </>
    )
}

export default AuthenticationRedirections

