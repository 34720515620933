import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
    faTimes,
    faSearch,
    faAngleRight,
    faAngleLeft,
    faChevronDown,
    faCamera,
    faMinus,
    faPlus,
    faPencilAlt,
    faCalculator,
    faEllipsisV,
    faAngleDown,
    faTrashAlt,
    faLink,
    faPlay,
    faPause,
    faMicrophone,
    faMicrophoneSlash,
    faVideo,
    faVideoSlash,
} from "@fortawesome/free-solid-svg-icons"
import LinkSvgIcon from "../../assets/images/svgIcons/LinkSvgIcon"
import InfoSvgIcon from "../../assets/images/svgIcons/InfoSvgIcon"

//images
export const googleLogo = require("../../assets/images/social-media/gmail.png").default
export const facebookLogo = require("../../assets/images/social-media/facebook.png").default
export const twitterLogo = require("../../assets/images/social-media/twitter.png").default

export const tutoring = require("../../assets/images/tutoring.svg").default
export const exit = require("../../assets/images/exit-buttom.png").default
export const counselling = require("../../assets/images/counseling.svg").default
export const counsellingApple = require("../../assets/images/iconsBrandBra02_1.png").default
export const AdvisingAdmission = require("../../assets/images/LookingAdmissionAdvising.svg").default
export const tutoringCounselling = require("../../assets/images/LookingTutoring.svg").default
export const collegeCounselling =require("../../assets/images/iconsBrandBra05.png").default
export const collegeCounsellingOnBoarding =require("../../assets/images/collegeCounselingGuide.svg").default
export const newUser = require("../../assets/images/Bra-12.svg").default
export const loginUser = require("../../assets/images/Bra-13.svg").default
export const tutorAdvisor =require("../../assets/images/iconsBrandBra25.png").default
export const edit = require("../../assets/images/icons/edit.svg").default
export const editbg = require("../../assets/images/icons/edit-bg.svg").default
export const check = require("../../assets/images/icons/check.svg").default
export const send = require("../../assets/images/send.svg").default
export const globe = require("../../assets/images/icons/globe.svg").default
export const calendar = require("../../assets/images/icons/calendarThin.svg").default
export const calendarCancel = require("../../assets/images/icons/calendarCancel.svg").default
export const hourGlass = require("../../assets/images/hourglass.png").default
export const linkWhite = require("../../assets/images/icons/white-link.svg").default
export const unRead = require("../../assets/images/unRead.svg").default
export const newStudent = require("../../assets/images/newStudent.svg").default
export const newTutor = require("../../assets/images/newTutor.svg").default
export const subsCancelCalender = require("../../assets/images/calender.png").default

export const menuBar = require("../../assets/images/menuButton.svg").default

export const checkIcon = (props: any): JSX.Element => (
    <img src="../../assets/images/icons/check.svg" {...props} />
)
export const LinkRoundedIcon = (props: any): JSX.Element => (
    <span className="rounded-mango">
        <LinkSvgIcon iconColor="#ffffff" {...props} />
    </span>
)


export const InfoIcon = (props: any): JSX.Element => <InfoSvgIcon iconColor="none" {...props} />
export const GlobeIcon = (props: any): JSX.Element => <img src={globe} {...props} />
export const CalendarIcon = (props: any): JSX.Element => <img src={calendar} {...props} />
export const CalendarCancelIcon = (props: any): JSX.Element => (
    <img src={calendarCancel} {...props} />
)
export const HourGlassIcon = (props: any): JSX.Element => (
    <img src={hourGlass} {...props} />
)

//Icons
export const Icon = (props: any): JSX.Element => <FontAwesomeIcon {...props} />

export const ExitIcon = (props: any): JSX.Element => <FontAwesomeIcon icon={faTimes} {...props} />

export const ChevronDownIcon = (props: any): JSX.Element => (
    <FontAwesomeIcon icon={faChevronDown} {...props} />
)

export const SearchIcon = (props: any): JSX.Element => (
    <FontAwesomeIcon icon={faSearch} {...props} />
)

export const CameraIcon = (props: any): JSX.Element => (
    <FontAwesomeIcon icon={faCamera} {...props} />
)

export const forwardIcon = (props: any): JSX.Element => (
    <FontAwesomeIcon icon={faAngleRight} {...props} />
)

export const backwardIcon = (props: any): JSX.Element => (
    <FontAwesomeIcon icon={faAngleLeft} {...props} />
)

export const NavigateIcon = (props: any): JSX.Element => (
    <FontAwesomeIcon icon={faAngleRight} {...props} />
)

export const CalculatorIcon = (props: any): JSX.Element => (
    <FontAwesomeIcon icon={faCalculator} {...props} />
)

export const PencilIcon = (props: any): JSX.Element => (
    <FontAwesomeIcon icon={faPencilAlt} {...props} />
)

export const minusIcon = (props: any): JSX.Element => <FontAwesomeIcon icon={faMinus} {...props} />

export const plusIcon = (props: any): JSX.Element => <FontAwesomeIcon icon={faPlus} {...props} />

export const threeDotIcon = (props: any): JSX.Element => (
    <FontAwesomeIcon icon={faEllipsisV} {...props} />
)

export const trashIcon = (props: any): JSX.Element => (
    <FontAwesomeIcon icon={faTrashAlt} {...props} />
)

export const linkIcon = (props: any): JSX.Element => <FontAwesomeIcon icon={faLink} {...props} />

export const AngleDownIcon = (props: any): JSX.Element => (
    <FontAwesomeIcon icon={faAngleDown} {...props} />
)

export const PlayIcon = (props: any): JSX.Element => <FontAwesomeIcon icon={faPlay} {...props} />

export const PauseIcon = (props: any): JSX.Element => <FontAwesomeIcon icon={faPause} {...props} />

export const CloseIcon = (props: any): JSX.Element => <FontAwesomeIcon icon={faTimes} {...props} />

export const muteIcon = (props: any): JSX.Element => (
    <FontAwesomeIcon icon={faMicrophoneSlash} {...props} />
)

export const unmuteIcon = (props: any): JSX.Element => (
    <FontAwesomeIcon icon={faMicrophone} {...props} />
)

export const videoOnIcon = (props: any): JSX.Element => (
    <FontAwesomeIcon icon={faVideo} {...props} />
)

export const videoOffIcon = (props: any): JSX.Element => (
    <FontAwesomeIcon icon={faVideoSlash} {...props} />
)

export const menuIcon = (props: any): JSX.Element => (
    <img src={menuBar} {...props} />
)