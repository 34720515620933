// Message -> messages
export const messagesActionTypes = {
    GET_USER_MESSAGES: "GET_USER_MESSAGES", // TO CREATE CHAT GROUP BETWEEN USERS
    CREATE_CHAT: "CREATE_CHAT", // TO GET ALL RECENT CHATS OF USERS
}
// Booking -> booking
export const bookingActionTypes = {
    CREATE_BOOKING_REQUEST_N: "CREATE_BOOKING_REQUEST_N", // CREATE BOOKING REQUEST (new endpoint)
    GET_BOOKING_REQUESTS: "GET_BOOKING_REQUESTS", // FETCH BOOKING REQUEST
    GET_BOOKING_REQUEST_BY_ID: "GET_BOOKING_REQUEST_BY_ID", // FETCH BOOKING REQUEST BY ID
    UPDATE_BOOKING_REQUEST: "UPDATE_BOOKING_REQUEST", // UPDATE BOOKING REQUEST
    CHECK_TEACHER_AVAILABILITY: "CHECK_TEACHER_AVAILABILITY", // GET TEACHER NON AVAILABLE SLOTS
    CREATE_BOOKING_EXTENSION_REQUEST :"CREATE_BOOKING_EXTENSION_REQUEST",
    GET_BOOKING_EXTENSION_REQUEST :"GET_BOOKING_EXTENSION_REQUEST",
    GET_BOOKING_EXTENSION_TIME  :"GET_BOOKING_EXTENSION_TIME",
    GET_BOOKED_SLOTS: "GET_BOOKED_SLOTS"
}
// Session -> sessions
export const sessionsActionTypes = {
    GET_ALL_SESSIONS: "GET_ALL_SESSIONS", // FETCH EPICBOARD SESSIONS
    GET_UPCOMING_SESSIONS: "GET_UPCOMING_SESSIONS", // FETCH UPCOMING EPICBOARD SESSIONS LIMITED
    GET_USER_TUTOR_COUNSELOR: "GET_USER_TUTOR_COUNSELOR", // FETCH ALL TUTOR COUNSELORS WITH WHOM USER HAS SESSIONS
    JOIN_EPICBOARD_SESSION: "JOIN_EPICBOARD_SESSION", // FETCH ALL TUTOR COUNSELORS WITH WHOM USER HAS SESSIONS
}
// Student -> students
export const studentsActionTypes = {
    GET_ALL_STUDENTS: "GET_ALL_STUDENTS", // GET ALL STUDENTS
    TOGGLE_FAVORITE: "TOGGLE_FAVORITE", // FOR CHANGING MARK AS FAVORITE STATUS OF TEACHER
}
// Teacher -> teachers
export const teachersActionTypes = {
    GET_TEACHERS: "GET_TEACHERS", // FETCH ALL TEACHERS BY FILTER OR SORT
    GET_TEACHER_BY_ID: "GET_TEACHER_BY_ID", // FETCH DATA FOR SINGLE TEACHER
}
// User -> users
export const usersActionTypes = {
    GET_ALL_USERS: "GET_ALL_USERS", // GET ALL USERS
    GET_ROOM_MEMBERS: "GET_ROOM_MEMBERS", // TO GET ALL USERS THAT BELONGS TO A PARTICULAR ROOM AND ALSO RETURN REMAINING USERS
    GET_USER_IMAGE: "GET_USER_IMAGE", // GET USER IMAGE
    GET_USER_CUSTOM_TOKEN :"GET_USER_CUSTOM_TOKEN", //GET USER CUSTOM TOKEN,
    CREATE_STUDENT_THROUGH_BACKEND: "CREATE_STUDENT_THROUGH_BACKEND",
    CREATE_TEACHER_THROUGH_BACKEND: "CREATE_TEACHER_THROUGH_BACKEND",
    SEND_EMAIL_TO_ADMIN:"SEND_EMAIL_TO_ADMIN",//SEND EMAIL TO ADMIN OF CLASS ROOM DOOR
}

export const paymentActionTypes = {
    ADD_CARD_DETAILS: "ATTACH_CARD_TO_STRIPE_CUSTOMER", // TO ATTACH CARD TO STRIPE CUSTOMER
    ATTACH_BANK_TO_TUTOR: "ATTACH_BANK_TO_TUTOR", // TO ATTACH BANK TO TUTOR
}
