import React, { Component, useEffect, useState } from "react"
import Autocomplete from "@material-ui/lab/Autocomplete"
import TextField from "@material-ui/core/TextField"
import { ExitIcon } from "../icons/Icons"
import { Paper, PaperProps, Popper, PopperProps } from "@material-ui/core"

interface Props {
    title: string
    error: string | null
    placeholder: string
    optionsList: Option[]
    subjectvalue: any
    selectedOption: Option[]
    noOptionsText?: string
    title2? :string
}

interface Option {
    name: string
    id: string | number
}

const MultiSelectDropDown = ({
    title,
    placeholder,
    error,
    optionsList,
    selectedOption,
    subjectvalue,
    noOptionsText,
    title2
}: Props): JSX.Element => {
    const CustomPopper = function (props: PopperProps): JSX.Element {
        return <Popper {...props} />
    }

    const CustomPaper = function (props: PaperProps): JSX.Element {
        return <Paper {...props} elevation={0} />
    }
    return (
        <div className="dropdown-wrapper">
            <label className="input-label" htmlFor={title}>
                {title}
                {title2 ? (<label className="input-label-blue">{title2}</label>):<></>}
            </label>
           
            <div className="input">
                <Autocomplete
                    multiple
                    PopperComponent={CustomPopper}
                    PaperComponent={CustomPaper}
                    options={optionsList}
                    getOptionLabel={(option) => option.name}
                    onChange={(_e, value: any) => {
                        subjectvalue(value)
                    }}
                    value={selectedOption ? selectedOption : []}
                    filterSelectedOptions
                    renderTags={() => undefined}
                    openOnFocus
                    noOptionsText={noOptionsText ? noOptionsText : "No Options"}
                    disablePortal={true}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            InputLabelProps={{
                                disableAnimation: true,
                                shrink: false,
                            }}
                            placeholder={placeholder}
                            variant="standard"
                            className="input-textfield"
                            helperText={error ? error : null}
                            InputProps={{ ...params.InputProps, disableUnderline: true }}
                        />
                    )}
                />
                {error ? <div className="error" /> : null}
            </div>
            <div className="options-box">
                {selectedOption &&
                    selectedOption.map((option: Option) => (
                        <p key={option.id}>
                            <span>{option.name}</span>
                            <span className="option-x-icon">
                                <ExitIcon
                                    onClick={(): void =>
                                        subjectvalue(
                                            selectedOption.filter(
                                                (entry: Option) => entry.id !== option.id,
                                            ),
                                        )
                                    }
                                />
                            </span>

                        </p>
                    ))}
            </div>
        </div>
    )
}

export default MultiSelectDropDown
