import React, { useState } from "react"
import { Modal, Grid, Card } from "@material-ui/core"
import Button from "../button/Button"
import _ from "lodash"
import { CloseIcon } from "../icons/Icons"
import { stripe } from "../../utils/helper/stripe-utils"
import { CardElement, Elements, useElements, useStripe } from "@stripe/react-stripe-js"
import { useSelector } from "react-redux"
import MessageAlert from "components/message-alert/MessageAlert"

interface Iprops {
    open: boolean
    close: Function
    clickAction: Function
    isLoading?: boolean
}
type Card = {
    cardNumber: string
    expiryDate: string
    cvv: string
    cardHolderName: string
    saveCard: boolean
}

const PaymentForm = ({ open, close, clickAction, isLoading }: Iprops): JSX.Element => {

    const { openSnackbar, message, type } = useSelector((state: any) => state.payment)

    const cardDefaultData: Card = {
        cardNumber: "",
        expiryDate: "",
        cvv: "",
        cardHolderName: "",
        saveCard: true,
    }
    const [cardData, setCardData] = useState(cardDefaultData)
    const [cardValue, setCardValue] = useState("Assume Empty")
    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState("")
    const usestripe = useStripe()
    const elements = useElements()

    const handleCloseModal = (): void => {
        setLoading(false)
        setCardValue("Assume Empty")
        setError("")
        close()
    }

    const handleCardChange = (event: any) => {
        if (event.complete) {
            setError("")
            setCardValue("")
        } else if (event.error) {
            setError(event.error.message)
            setCardValue("Card Details error")
        } else if (event.empty) {
            setError("Cannot submit without card details")
        }
    }

    const handleChange = (e: any, type: string): void => {
        switch (type) {
            case "saveCard":
                setCardData({
                    ...cardData,
                    saveCard: !cardData.saveCard,
                })
                break

            default:
                break
        }
    }

    const handleCardSubmit = async (e: any) => {
        e.preventDefault()
        if (!usestripe || !elements || error !== "" || cardValue !== "") {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return
        }
        const card = elements?.getElement(CardElement)
        setLoading(true);
        card &&
            (await usestripe
                ?.createToken(card)
                .then((result) => {
                    const tokenId = result?.token?.id
                    clickAction(tokenId)
                    setCardValue("Assume Empty")
                    setLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                    setLoading(false);
                }))
    }

    const CARD_ELEMENT_OPTIONS = {
        hidePostalCode: true,
        style: {
            base: {
                color: "#32325d",
                fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                fontSmoothing: "antialiased",
                fontSize: "16px",
                "::placeholder": {
                    color: "#aab7c4",
                },
            },
            invalid: {
                color: "#fa755a",
                iconColor: "#fa755a",
            },
        },
    }
    return (
        <>
            <Modal
                open={open}
                onClose={(): void => {
                    handleCloseModal()
                }}
                className="add-card paymet-card-modal  custom-modal"
            >
                <div className="custom-modal-body add-card-modal-body">
                    <div className={`custom-modal-content`}>
                        <div className={`close-modal-button`}>
                            <CloseIcon
                                onClick={(): void => {
                                    handleCloseModal()
                                }}
                            />
                        </div>
                        <>
                            <h6 style={{ textAlign: "center" }}>Add a new Credit Card</h6>
                            <form onSubmit={(e: any) => handleCardSubmit(e)}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <CardElement
                                            className="card-box"
                                            id="card-element"
                                            options={CARD_ELEMENT_OPTIONS}
                                            onChange={(e: any) => handleCardChange(e)}
                                        />
                                        <p className="card-error">{error}</p>
                                    </Grid>
                                    <Grid item xs={12} className="pl-4">
                                        <p>
                                            Card details saved here will be used for future transactions
                                        </p>
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <Button
                                            className="centered-button"
                                            isDisabled={
                                                isLoading || loading || error || cardValue !== "" ? true : false
                                            }
                                            isloading={isLoading || loading}
                                            clickAction={(): void => { }}
                                            title="Submit"
                                        />
                                    </Grid>
                                </Grid>
                            </form>
                        </>
                    </div>
                </div>
            </Modal>
            <MessageAlert
                openSnackBar={openSnackbar}
                message={message}
                setOpenSnackBar={() => { }}
                type={type}
            />
        </>
    )
}

const AddPaymentModal = ({ open, close, clickAction, isLoading }: Iprops): JSX.Element => {
    return (
        <Elements stripe={stripe}>
            <PaymentForm
                open={open}
                close={close}
                clickAction={clickAction}
                isLoading={isLoading}
            />
        </Elements>
    )
}

export default AddPaymentModal
