import colors from "assets/colors"
import React from "react"
import interfaceConstant from "../../../utils/constant/interfaceConstant"

class CollegeCouncelingSvgIcon extends React.PureComponent<interfaceConstant.SvgIconProps> {
    render(): JSX.Element {
        const { width, height, iconColor, ...otherProps } = this.props
        return (
            <svg width={width} height={height} viewBox="0 0 84 72" version="1.1" xmlns="http://www.w3.org/2000/svg" {...otherProps}>
                <title>Group 2</title>
                <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="Dashboard-Empty---Breakpoints" transform="translate(-660.000000, -1260.000000)">
                        <g id="1440" transform="translate(0.000000, 485.000000)">
                            <g id="Group-2" transform="translate(660.000000, 775.000000)">
                                <g id="Group-72" transform="translate(-0.000000, 0.000000)">
                                    <g id="Group" transform="translate(0.447564, 0.631799)">
                                        <path d="M68.953298,43.9717491 L67.3861776,43.9717491 L67.3861776,6.28167844 L68.953298,6.28167844 C69.8152142,6.28167844 70.5204184,5.57498961 70.5204184,4.71125883 C70.5204184,3.84752804 69.8152142,3.14083922 68.953298,3.14083922 L36.8273296,3.14083922 L36.8273296,1.57041961 C36.8273296,0.706688824 36.1221254,0 35.2602092,0 C34.398293,0 33.6930888,0.706688824 33.6930888,1.57041961 L33.6930888,3.14083922 L1.56712041,3.14083922 C0.705204184,3.14083922 0,3.84752804 0,4.71125883 C0,5.57498961 0.705204184,6.28167844 1.56712041,6.28167844 L3.13424082,6.28167844 L3.13424082,43.9717491 L1.56712041,43.9717491 C0.705204184,43.9717491 0,44.6784379 0,45.5421687 C0,46.4058995 0.705204184,47.1125883 1.56712041,47.1125883 L25.2306386,47.1125883 L18.1002407,68.6273369 C17.8651727,69.4125467 18.2569528,70.3547985 19.118869,70.5903614 C19.275581,70.6688824 19.4322931,70.6688824 19.5890051,70.6688824 C20.2158533,70.6688824 20.8427014,70.2762775 21.0777695,69.5695887 L28.5215914,47.1125883 L33.6930888,47.1125883 L33.6930888,69.0984628 C33.6930888,69.9621936 34.398293,70.6688824 35.2602092,70.6688824 C36.1221254,70.6688824 36.8273296,69.9621936 36.8273296,69.0984628 L36.8273296,47.1125883 L41.998827,47.1125883 L49.4426489,69.5695887 C49.677717,70.1977565 50.3045651,70.6688824 50.9314133,70.6688824 C51.0881253,70.6688824 51.2448374,70.6688824 51.4015494,70.5903614 C52.1851096,70.3547985 52.6552457,69.4125467 52.4201777,68.6273369 L45.2897798,47.1125883 L68.953298,47.1125883 C69.8152142,47.1125883 70.5204184,46.4058995 70.5204184,45.5421687 C70.5204184,44.6784379 69.8152142,43.9717491 68.953298,43.9717491 Z" id="Shape" fill={colors.darkMango}></path>
                                        <path d="M14.1040837,18.4524304 C14.7505208,18.4524304 15.279424,19.1591192 15.279424,20.02285 L15.279424,33.3714167 C15.279424,34.2351475 14.7505208,34.9418363 14.1040837,34.9418363 C13.4576465,34.9418363 12.9287434,34.2351475 12.9287434,33.3714167 L12.9287434,20.02285 C12.9287434,19.1591192 13.4576465,18.4524304 14.1040837,18.4524304 Z M21.1561255,23.1636892 C21.8025627,23.1636892 22.3314658,23.8703781 22.3314658,24.7341088 L22.3314658,33.3714167 C22.3314658,34.2351475 21.8025627,34.9418363 21.1561255,34.9418363 C20.5096883,34.9418363 19.9807852,34.2351475 19.9807852,33.3714167 L19.9807852,24.7341088 C19.9807852,23.8703781 20.5096883,23.1636892 21.1561255,23.1636892 Z M28.2081674,14.5263814 C28.8546045,14.5263814 29.3835077,15.2330702 29.3835077,16.096801 L29.3835077,33.3714167 C29.3835077,34.2351475 28.8546045,34.9418363 28.2081674,34.9418363 C27.5617302,34.9418363 27.032827,34.2351475 27.032827,33.3714167 L27.032827,16.096801 C27.032827,15.2330702 27.5617302,14.5263814 28.2081674,14.5263814 Z" id="Combined-Shape" fill="#FFFFFF" transform="translate(21.156126, 24.734109) rotate(-270.000000) translate(-21.156126, -24.734109) "></path>
                                    </g>
                                    <g id="noun_add_1422557" transform="translate(57.997055, 37.787786)">
                                        <path d="M11.7534031,23.5562941 C18.2034413,23.5562941 23.5068061,18.2417644 23.5068061,11.7781471 C23.5068061,5.31452978 18.2034413,0 11.7534031,0 C5.3033648,0 0,5.31452978 0,11.7781471 C0,18.2417644 5.3033648,23.5562941 11.7534031,23.5562941 Z" id="Shape" fill="#262FFA"></path>
                                        <path d="M6.24937041,10.6865139 L10.6640633,10.6865139 L10.6640633,6.26252698 C10.6640633,5.63052884 11.1513995,5.17089384 11.7534031,5.17089384 C12.3554066,5.17089384 12.8427429,5.65925603 12.8427429,6.26252698 L12.8427429,10.6865139 L17.2574357,10.6865139 C17.8881061,10.6865139 18.3467755,11.1748761 18.3467755,11.7781471 C18.3467755,12.381418 17.8594393,12.8697802 17.2574357,12.8697802 L12.8427429,12.8697802 L12.8427429,17.2937672 C12.8427429,17.9257653 12.3554066,18.3854003 11.7534031,18.3854003 C11.1513995,18.3854003 10.6640633,17.8970381 10.6640633,17.2937672 L10.6640633,12.8697802 L6.24937041,12.8697802 C5.6187,12.8697802 5.16003061,12.381418 5.16003061,11.7781471 C5.16003061,11.1748761 5.6187,10.6865139 6.24937041,10.6865139 Z" id="Path" fill="#F6F6F6"></path>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        )
    }
}

export default CollegeCouncelingSvgIcon
