import React from "react";
import Modal from "components/modal_/Modal";
import StudentTutorRejectionAvatar from "components/student-tutor-rejection-avatar/StudentTutorRejectionAvatar"
import './StdTutorAcceptRequest.scss';
import Button from "components/button/Button";
// import { Modal } from "@material-ui/core";
interface StdTutorDoesntProcessMyRequests {
    studentPicUrl: string,
    teacherPicUrl: string,
    onGoto: () => void,
    teacherName: string,
    isShown: boolean,
    onClose: () => void
}

export function StdTutorDoesntProcessMyRequest(props: StdTutorDoesntProcessMyRequests) {
    return <Modal
    visible={props.isShown}
    onExit={props.onClose}
>
        <div className="model_stddoestnotprocessmurequest"> 
            <div className="imgs_div">
                <StudentTutorRejectionAvatar
                    size="100"
                    student={
                        props.studentPicUrl
                    }
                    tutor={
                        props.teacherPicUrl
                    }
                />

            </div>

          <div  style={{padding:"15px"}}>
          <h2>Sorry, {props.teacherName} still hasn’t confirmed.</h2>
            <h4>Your request has been canceled. Please select another tutor.</h4>
          </div>
            <div className="tutor_reject_session">
                <Button className=""  title="Search for a new tutor" isOutlineStyle={true} clickAction={props.onGoto}/>  
            </div>
            
        </div>

        </Modal>
}
