import { SessionConstant } from "../constant";
import { rescheduleSessionPayloadType } from "store/types/sessionTypes"


export const getUpcomingSessions = (props: any): object => {
    return { type: SessionConstant.GET_UPCOMING_SESSIONS, payload: props }
}

export const getUpcomingSessionsOfMultipleUsers = (props: string[]): object => {
    return { type: SessionConstant.GET_UPCOMING_SESSIONS_N, payload: props }
}


export const getPendingBookings = (): object => {
    return { type: SessionConstant.GET_PENDING_BOOKINGS }
}

export const getBookingRequests = () => {
    return { type: SessionConstant.GET_BOOKING_REQUEST }
}

export const getPastSessions = (props: any): object => {
    return { type: SessionConstant.GET_PAST_SESSIONS, payload: props }
}

export const setSuggestedTime = (props: any): object => {
    return { type: SessionConstant.SET_SUGGESTED_TIME, payload: props }
}

export const updateBooking = (bookingId: any, details: any): object => {
    return { type: SessionConstant.UPDATE_BOOKING, payload: { bookingId, details } }
}

export const updateBookingSlots = (bookingId: any, data: any): object => {
    return { type: SessionConstant.UPDATE_BOOKING_SLOTS, payload: { bookingId, data } }
}

export const acceptBooking = (bookingId: any, data: any): object => {
    return { type: SessionConstant.ACCEPT_BOOKING_REQUEST, payload: { bookingId, data } }
}

export const cancelBooking = (bookingId: any, data: any): object => {
    return { type: SessionConstant.CANCEL_BOOKING_REQUEST, payload: { bookingId, data } }
}

export const changeBookingComment = (props: any): object => {
    return { type: SessionConstant.CHANGE_BOOKING_COMMENT, payload: props }
}

export const sendRescheduleRequest = (props: rescheduleSessionPayloadType) => {
    return { type: SessionConstant.RESCHEDULE_SESSION, payload: props }
}
