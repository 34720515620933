import React, { PropsWithChildren, useReducer, useMemo } from "react"
import tutorSearchReducer, { tutorSearchInitState } from "./reducers/reducers"
import {
    TutorDetailType,
    TeacherEducationQualificationTypes,
    GenderTypes,
    TeacherTypes,
} from "../types/tutorSearchTypes"
import { SubjectType } from "../types/tutorSearchTypes"
export enum resultsSortByTypes {
    RATING = "Rating",
    BEST_MATCH = "Best match",
    HOURS_TUTORING = "Hours tutoring",
    HOURLY_RATE = "Hourly rate",
}

export type SlotType = [string, string]
export type AvailabilityType = {
    date: string
    slots: string[]
}

export type teacherDetailsType = object

export type goalAndObjectiveStateType = keyof typeof TeacherTypes
export type subjectStateType = SubjectType
export type hourRateStateType = { min: number; max: number }
export type genderStateType = keyof typeof GenderTypes
export type availabilityStateType = { [key: number]: number[] }
export type educationStateType = keyof typeof TeacherEducationQualificationTypes
export type sortByStateType = keyof typeof resultsSortByTypes

export type tutorSearchStateType = {
    showProgress: boolean
    teachers: teacherDetailsType[]
    goalAndObjectives: goalAndObjectiveStateType
    subjects: Array<subjectStateType>
    hourRate: hourRateStateType
    availability: availabilityStateType
    selectAllAvailability: boolean
    gender: genderStateType
    education: string
    backgroundCheck: boolean
    sortByType: sortByStateType
    sortTypeAscending: boolean
    myFavoritesOnly: boolean
    selectedTutor: TutorDetailType
}

//Selectors For Tutor Search
export const selectedTutorSelector = (state: tutorSearchStateType): TutorDetailType => {
    return state.selectedTutor
}
export const loadingTeachersSelector = (state: tutorSearchStateType): boolean => state.showProgress
export const allTeachersSelector = (state: tutorSearchStateType): object[] => state.teachers
export const goalAndObjectiveSelector = (state: tutorSearchStateType): goalAndObjectiveStateType =>
    state.goalAndObjectives
export const subjectsSelector = (state: tutorSearchStateType): Array<subjectStateType> =>
    state.subjects
export const hourRateSelector = (state: tutorSearchStateType): hourRateStateType => state.hourRate
export const genderSelector = (state: tutorSearchStateType): genderStateType => state.gender
export const availabilitySelector = (state: tutorSearchStateType): availabilityStateType =>
    state.availability
export const selectAllAvailabilitySelector = (state: tutorSearchStateType): boolean =>
    state.selectAllAvailability
export const educationSelector = (state: tutorSearchStateType): string => state.education
export const backgroundCheckSelector = (state: tutorSearchStateType): boolean =>
    state.backgroundCheck
export const sortByTypeSelector = (state: tutorSearchStateType): sortByStateType => state.sortByType
export const sortTypeAscendingSelector = (state: tutorSearchStateType): boolean =>
    state.sortTypeAscending
export const myFavoritesOnlySelector = (state: tutorSearchStateType): boolean =>
    state.myFavoritesOnly

const fallbackDispatch = (): void => {
    console.log("Error: Dispatch Not Triggered")
    throw new Error("Dispatch Failed")
}

const defaultTutorSearchStore = {
    state: tutorSearchInitState,
    dispatch: fallbackDispatch,
}

type TutorSearchStore = {
    state: any
    dispatch: React.Dispatch<any>
}

interface TutorSearchStoreProps {
    initialState: tutorSearchStateType
}

export const TutorSearchStoreContext = React.createContext<TutorSearchStore>(
    defaultTutorSearchStore,
)

const TutorSearchStoreProvider = ({
    children,
    initialState = tutorSearchInitState,
}: PropsWithChildren<TutorSearchStoreProps>): JSX.Element => {
    const [state, dispatch] = useReducer(tutorSearchReducer, initialState)
    const store = useMemo(() => ({ state, dispatch }), [state, dispatch])
    return (
        <TutorSearchStoreContext.Provider value={store}>
            {children}
        </TutorSearchStoreContext.Provider>
    )
}

TutorSearchStoreProvider.defaultProps = {
    initialState: tutorSearchInitState,
}

export default TutorSearchStoreProvider
