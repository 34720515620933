import React from "react"
import interfaceConstant from "../../../utils/constant/interfaceConstant"

class StarSvgIcon extends React.PureComponent<interfaceConstant.SvgIconProps> {
    render(): JSX.Element {
        const { width, height, iconColor, ...otherProps } = this.props
        return (
            <svg width={width} height={height} viewBox="0 0 32 32" {...otherProps}>
                <g fill="none" fillRule="evenodd">
                    <polygon
                        fill={iconColor}
                        transform="translate(15.928203, 15.928203) rotate(-30.000000) translate(-15.928203, -15.928203) "
                        points="15.9282032 20.2739719 10.9839313 23.9282032 12.7613506 17.8547117 7.92820323 14.0396594 13.9709806 13.9402666 15.9282032 7.92820323 17.8854258 13.9402666 23.9282032 14.0396594 19.0950559 17.8547117 20.8724751 23.9282032"
                    ></polygon>
                </g>
            </svg>
        )
    }
}

export default StarSvgIcon
