import React, { useState, useContext, useEffect } from "react"
import { applicationConstant } from "../../../../utils/constant/applicationConstant"
import InterfaceConstant from "../../../../utils/constant/interfaceConstant"
import moment from "moment"
import Button from "../../../../components/button/Button"
import { Grid, Container } from "@material-ui/core"
import { useSelector } from "react-redux"
import { navigatetoUrl } from "../../../../utils/helper/helper"
import { useHistory } from "react-router-dom"
import {
    TutorSearchStoreContext,
    selectedTutorSelector,
} from "../../../../store/tutor-search/store"
import StudentTutorAvatar from "../../../../components/student-tutor-avatar/StudentTutorAvatar"
import DefaultAvatar from "../../../../assets/images/default-avatar.png"
import NonCircumventionAgreementModal from "../resume-confirm/NonCircumventionAgreementModal"
import { externalLinks } from "utils/constant/externalLinks"


const BookingConfirmedPage = (props: any): JSX.Element => {
    const { promoCodeDetails } = props.location.state.params || {};
    const { state } = useContext(TutorSearchStoreContext)
    const history = useHistory()
    const sessionRequests = useSelector((state: any) => state.booking.options.activeSlots)
    const sessionSubject = useSelector((state: any) => state.booking.options.subject)
    const { userAuthData } = useSelector((state: any) => state.auth)
    const bookingLabel: InterfaceConstant.IbookingLabel = applicationConstant.bookingLabel
    const routeUrl: InterfaceConstant.IrouteUrl = applicationConstant.routeUrl
    const [agreementModalOpen, setAgreementModalOpen] = useState(false);
    const [summarySessions, setSummarySessions] = useState({
        slots: [],
        totalDuration: 0,
    });
    const [sessionsCost, setSessionsCost] = useState<number>(0);
    const [serviceCharges, setServiceCharges] = useState<number>(0);
    const [discount, setDiscount] = useState<number>(0);
    const [totalCost, setTotalCost] = useState<number>(0);

    useEffect(() => {
        if (!selectedTutorSelector(state).firstName) {
            navigatetoUrl(history, "/panel/search")
        }
    }, []);

    const getTotalLength = (): number =>
        sessionRequests.reduce(
            (acc: number, currentVal: { startTime: string, endTime: string }) => {
                const diff = moment
                    .duration(
                        moment(new Date(currentVal.endTime)).diff(new Date(currentVal.startTime)),
                    )
                    .asHours()
                return acc + diff
            },
            0,
        )

    useEffect(() => {
        const _sessionsCost = getTotalLength() * selectedTutorSelector(state).oneToOneSessionRate;
        const _servicesCharges = Number((_sessionsCost * 0.08).toFixed(2));
        const _discount = Number((promoCodeDetails?.amount_off ? promoCodeDetails.amount_off / 100 : promoCodeDetails?.percent_off ? (_sessionsCost + _servicesCharges) * promoCodeDetails?.percent_off / 100 : 0).toFixed(2));
        setSessionsCost(_sessionsCost);
        setServiceCharges(_servicesCharges);
        setDiscount(_discount);
        setTotalCost(Number((_sessionsCost + _servicesCharges - _discount).toFixed(2)));
    }, [promoCodeDetails])

    useEffect(() => {
        const summarySessions: any = {
            slots: [],
            totalDuration: 0,
        }
        setSummarySessions(summarySessions)
    }, [])
    return (
        <>
            <Container maxWidth="md">
                <div className="booking-confirmed ">
                    <Grid container direction="row" justify="center" alignItems="center">
                        {/* <Grid item md={5} lg={4}> */}
                        <div className="avatarsforForAlmostThere">

                            <StudentTutorAvatar
                                size="120"
                                student={
                                    userAuthData.profilePic.downloadURL
                                        ? userAuthData.profilePic.downloadURL
                                        : DefaultAvatar
                                }
                                tutor={selectedTutorSelector(state).profilePic.downloadURL}

                            />
                        </div>
                        {/* </Grid> */}
                        <Grid item xs={12} className="text-center">
                            <h1>{bookingLabel.CONFIRMED_PAGE_TITLE}</h1>
                            <p style={{ marginTop: "25px" }}>{bookingLabel.CONFIRMED_PAGE_SUBTITLE}</p>
                            <br />
                            <p>
                                Tutor / Advisor has up to 24 hours to respond. Once your
                                Tutor / Advisor confirms, your session is considered booked. You may
                                cancel your request at any time if your Tutor / Advisor has not confirmed.
                            </p>
                        </Grid>
                        <Grid item xs={12} className="session-data">
                            <Grid
                                container
                                direction="row"
                                justify="space-between"
                                alignItems="center"
                            >
                                <h3> Request(s) </h3>
                                {/* <Button
                                        title={bookingLabel.CONFIRMED_PAGE_BUTTON}
                                        isOutlineStyle
                                        clickAction={() => handleClick()}
                                        className="mb-10"
                                    /> */}

                                <ul className={(sessionRequests.length > 4) ? "multiAvail availability" : "availability"}>
                                    {sessionRequests?.map((session: any) => {
                                        const sessionDuration = moment
                                            .duration(
                                                moment(session.endTime).diff(
                                                    moment(session.startTime),
                                                ),
                                            )
                                            .asHours()
                                        return (
                                            <li>
                                                {moment(session.startTime).format(
                                                    "dddd, MMM D, YYYY",
                                                )}{" "}
                                                at {moment(session.startTime).format("h:mm A")}{" "}
                                                - {sessionDuration}{" "}
                                                {sessionDuration === 1 ? "Hr" : "Hrs"}.

                                            </li>
                                        )
                                    })}
                                </ul>


                                <br />
                            </Grid>
                            <hr />
                            <div style={{ overflow: "hidden", lineHeight: '1rem' }} className="subtotal">

                                <p style={{ float: 'left' }}>{"Subtotal "}</p>

                                <span style={{ float: 'right' }}>
                                    ${selectedTutorSelector(state).oneToOneSessionRate} x{" "}
                                    {getTotalLength()} hours

                                    = ${sessionsCost}
                                </span>

                            </div>
                            <br />
                            <div style={{ overflow: "hidden" }} className="subtotal">
                                <p style={{ float: 'left' }}>{"Service Charge: 8%"}</p>
                                <span style={{ float: 'right' }}>$
                                    {serviceCharges}
                                </span>

                            </div>
                            {discount > 0 ?
                                <div style={{ overflow: "hidden" }} className="subtotal">
                                    <p style={{ float: 'left' }}>Coupon <b>"{promoCodeDetails?.promoCode}"</b> Applied</p>
                                    <span style={{ float: 'right' }}>-${discount}</span>
                                </div>
                                : <></>
                            }
                            <hr />
                            <div style={{ overflow: "hidden" }} className="total">

                                <p style={{ float: 'left' }}>{"Total (USD)"}
                                </p>
                                <p className="number" style={{ float: 'right' }} >$
                                    {totalCost}
                                </p>

                            </div>


                        </Grid>
                        <Grid className="almostAvatarsLinks" item xs={12}>
                            <Grid item className="">

                                <span className="avatarsLinkPad NCA-modal-link" onClick={() => setAgreementModalOpen(true)}>
                                    Non-Circumvention Agreement
                                </span>

                            </Grid>
                            <Grid
                                item

                                className="almostAvatarsLinks-nested"
                                style={{ justifyContent: "flex-end" }}
                            >
                                <a href={externalLinks.CLIENT_SUPPORT} className="avatarsLinkPad">
                                    <span style={{ marginRight: 10 }}>
                                        {bookingLabel.CONFIRMED_PAGE_NAV1}
                                    </span>
                                </a>
                                <a href={externalLinks.CONTACT_TCD} className="avatarsLinkPad"> <span>{bookingLabel.CONFIRMED_PAGE_NAV2}</span></a>
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            xs={8}
                            className="flex-center-center"
                            style={{ paddingBottom: "60px" }}
                        >
                            <Button
                                title={bookingLabel.CONFIRMED_PAGE_BUTTON2}
                                clickAction={() => navigatetoUrl(history, routeUrl.DASHBOARD_PAGE)}
                            />
                        </Grid>
                    </Grid>
                </div>
            </Container>
            <NonCircumventionAgreementModal
                open={agreementModalOpen}
                close={() => setAgreementModalOpen(false)}
            />
        </>
    )
}

export default BookingConfirmedPage
