import HomeSvgIcon from "../assets/images/svgIcons/HomeSvgIcon"
import interfaceConstant from "../utils/constant/interfaceConstant"
import { applicationConstant } from "../utils/constant/applicationConstant"
import { AdminDashboard } from "pages/admin/Dashboard"

const routeUrl: interfaceConstant.IrouteUrl = applicationConstant.routeUrl
const PanelRoutesForAdmin: interfaceConstant.IpanelRoute[] = [
    {
        path: routeUrl.DASHBOARD_PAGE,
        name: "Admin Dashboard",
        icon: HomeSvgIcon,
        component: AdminDashboard,
        layout: routeUrl.ADMIN_PANEL_ROUTE,
    },
]

export default PanelRoutesForAdmin
