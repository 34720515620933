import React, { useEffect } from "react"
import LessonContent from "../../../components/lesson/LessonContent"
import { useSelector, useDispatch } from "react-redux"
import { findLessonById } from "../../../store/actions/lessonActions"
import LoaderSpinner from "../../../components/loader/LoaderSpinner"
import { useHistory } from "react-router-dom"
import ModuleContent from "../../../components/lesson/ModuleContent"


const ModulePage = (props: any) => {
    const { lessonId, moduleId } = props.match.params
    const modules = useSelector((state: any): any => state.modules.modules ? state.modules.modules : [])
    const dispatch = useDispatch()
    let history = useHistory()
    useEffect(() => {
        dispatch(findLessonById({ lessonId, history }))
    }, [moduleId])
    return (
        <>
            {modules[0]?.modules.length > 0 ?
                <ModuleContent modules={modules[0].modules} moduleId={moduleId} lessonId={lessonId}/>
                :
                <LoaderSpinner />
            }
        </>
    )
}

export default ModulePage
