import React from "react"
import {
    backwardIcon
} from "../../../components/icons/Icons"
import Button from "../../../components/button/Button"
import { navigatetoUrl } from "../../../utils/helper/helper"
import OnboardingBackground from "../../../components/on-boarding/onboarding-background/OnboardingBackground"
import ProgressBar from "../../../components/progress-bar/ProgressBar"
import { Container } from "reactstrap"
import StudentLookingForCards from "./component/studentLookingFor"
import { OnBoardingHeader } from "../../../components/on-boarding/OnboardingHeader"
interface Iprops {
    history: any
}

const LookingForPage = ({ history }: Iprops) => {
    return (
        <>
            <OnboardingBackground />
            <div className="looking-for">
                <ProgressBar at="40" theme="darkMango" />
                <Container className="flex-column-space-between">
                    <div className="option-screen-component">
                    <OnBoardingHeader heading={"I’m new to The Classroom Door and"}  subHeading={"I am a student, seeking"} />
                    <StudentLookingForCards />
                    </div>
                    <div className="onboarding-actions" style={{paddingTop:"50px"}}>
                        <div className="onboarding-divider" />
                        <Button
                            title={"Previous"}
                            clickAction={() => navigatetoUrl(history, "/my-role")}
                            isDisabled={false}
                            isOutlineStyle={true}
                            leftSideIcon={backwardIcon}
                        />
                    </div>
                </Container>
            </div>
        </>
    )
}
export default LookingForPage
