import React from "react"
import Button from "../button/Button"
import { useHistory } from "react-router-dom"
import { Grid, Container } from "@material-ui/core"
import { useTranslation } from 'react-i18next';
import { navigatetoUrlWithProps } from "../../utils/helper/helper"


const LessonRelatedModules = ({ lessonId, modules }: { lessonId:any, modules: any }): JSX.Element => {
    let history = useHistory()
    const { t } = useTranslation();
    return (
        <>
            {modules ?
                modules.map((module: any, index: any) =>
                    <>
                        <div className="related-modules">
                            <Grid className="justify-center" container>
                                <Grid className="title justify-center" lg={8} md={8} sm={12} xs={12} item>
                                    <p>
                                        {module?.fields?.title}
                                    </p>
                                </Grid>
                                <Grid className="justify-center" md={4} sm={12} xs={12} item>
                                    <div className="btn-wrapper">
                                        <Button
                                            className="btn float-right"
                                            title={t('go-to-module')}
                                            clickAction={() => {
                                                navigatetoUrlWithProps(history, `/panel/lessons/`+ lessonId+ '/module/' + modules[index].sys.id, {modules: modules, index: index})
                                            }}
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                        </div>

                        <hr />
                    </>
                ) : (
                    <></>
                )
            }
        </>
    )
}

export default LessonRelatedModules