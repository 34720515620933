/* eslint-disable @typescript-eslint/no-empty-function */
import React from "react";
import Modal from "components/modal_/Modal";
import StudentTutorAvatar from "components/student-tutor-avatar/StudentTutorAvatar"
import 'popups/booking/student/popups/StdTutorAcceptRequest.scss';
import Button from "components/button/Button";
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { SLOT_STATUSES } from "store/constant";
import moment from "moment";


interface TutorWhenStdAcceptSomeOfMyRequests {
    studentPicUrl: string,
    teacherPicUrl: string,
    onGoto: () => void,
    studentName: string,
    sessions: {
        startTime: string,
        endTime: string,
        sessionLength: string,
        status: string
    }[]
    isShown: boolean,
    onClose: () => void

}

export function TutorWhenStdAcceptSomeOfMyRequest(props: TutorWhenStdAcceptSomeOfMyRequests) {
    return <Modal
        visible={props.isShown}
        onExit={props.onClose}
    >
        <div className="model_stdwhentutoracceptsomeofmyreuests">
            <div className="imgs_div">
                <StudentTutorAvatar
                    size="100"
                    student={
                        props.studentPicUrl
                    }
                    tutor={
                        props.teacherPicUrl
                    }
                />

            </div>
            <h2>Some of your sessions are booked</h2>
            <h4>{props.studentName} accepted some of your booking requests.</h4>
            <ul>
                {props.sessions.map((session) => {
                    const startTime = moment(session.startTime);
                    if ([SLOT_STATUSES.ACCEPTED, SLOT_STATUSES.BOOKED].includes(session.status)) {
                        return <li><CheckIcon style={{color: "#36d174"}}></CheckIcon> {startTime.format("dddd, MMMM DD, YYYY")} at {startTime.format("hh:mm A")}</li>;
                    }
                    if ([SLOT_STATUSES.CANCELLED].includes(session.status)) {
                        return <li><CloseIcon style={{color: "#ef5350"}}></CloseIcon> {startTime.format("dddd, MMMM DD, YYYY")} at {startTime.format("hh:mm A")}</li>;
                    }
                })}
            </ul>
            <div className="btn_stdwhentutoracceptsomeofmyreuests">
                <Button className="" title="Go to Dashboard" isOutlineStyle={true} clickAction={props.onGoto}/>
                {/* <Button className="" title="Suggest additional times" /> */}
            </div>
            <p>
                If you must reschedule/cancel, please do so in accordance with our<br/>
                <a href="https://theclassroomdoor.com/rescheduling-cancellation-policy/" target="cancellation">rescheduling/cancelation policy</a>
            </p>
        </div>
    </Modal>
}
