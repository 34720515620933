import React from "react"
import interfaceConstant from "../../../utils/constant/interfaceConstant"

class GraducationCapSvgIcon extends React.PureComponent<interfaceConstant.SvgIconProps> {
    render(): JSX.Element {
        const { width, height, ...otherProps } = this.props
        return (
            <svg width={width} height={height} viewBox="0 0 32 32" {...otherProps}>
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <path
                        d="M22.8615067,17.6765861 C22.8922012,17.8333388 22.7965875,18.9886764 22.5746656,21.1425987 L22.5450029,21.4292082 L16.5270839,23.8870363 C16.3949921,23.9623454 16.2633501,24 16.132158,24 C16.0090484,24 15.9042224,23.9788193 15.81768,23.9364579 L15.7372321,23.8870363 L9.72509956,21.4205139 C9.48116632,19.2330025 9.36882556,18.0185629 9.38807728,17.7771952 L9.39133465,17.7512308 L15.6504352,20.157954 C15.7890285,20.1891192 15.9043859,20.253615 16.0464103,20.251329 C16.200972,20.251329 16.3218111,20.2132669 16.5184163,20.1399397 L16.6463473,20.091734 L22.8615067,17.6765861 Z M16.2959497,8.03119759 L16.4389598,8.07019459 L28.8187835,12.6780467 C29.0335586,12.8947026 29.0574225,13.2296055 28.8903752,13.4725386 L28.8187835,13.5589168 L16.4273519,18.169676 C16.1896382,18.2746083 15.9235693,18.2895987 15.6777542,18.214647 L15.5567595,18.169676 L6.999,14.981 L7,21 L6,20.1277423 L5,21 L5.002,14.23 L4.65694273,14.099649 L3.17693566,13.5501953 C2.96723414,13.334476 2.94393397,13.0049031 3.10703515,12.763883 L3.17693566,12.6780467 L15.5683674,8.07019459 C15.8039325,7.9922006 16.0551629,7.9792016 16.2959497,8.03119759 Z"
                        id="Combined-Shape"
                        fill="#686C80"
                    ></path>
                </g>
            </svg>
        )
    }
}

export default GraducationCapSvgIcon
