import React from "react"
import { Container } from "reactstrap"
import TutorMyProfileForm from "./TutorMyProfileForm"
import ProgressBar from "../../../components/progress-bar/ProgressBar"
import OnboardingBackground from "../../../components/on-boarding/onboarding-background/OnboardingBackground"
import { applicationConstant } from "../../../utils/constant/applicationConstant"
import InterfaceConstant from "../../../utils/constant/interfaceConstant"
import { OnBoardingHeader } from "../../../components/on-boarding/OnboardingHeader"
import { facebookLogo, googleLogo, twitterLogo } from "../../../components/icons/Icons"
import { useSelector } from "react-redux"

interface Iprops {
    history: any
}

const tutormyProfileLabel: InterfaceConstant.ItutorProfileLabel =
    applicationConstant.tutormyProfileLabel

const TutorMyProfilePage = (props: Iprops): JSX.Element => {
    const tutorInfo = useSelector((state: any) => state.tutorMyprofile.options)

    return (
        <>
            <OnboardingBackground />
            <ProgressBar at="83" theme="darkMango" />
            <div className="teacher-myprofile">
                <Container className=" pb-5">
                    <OnBoardingHeader
                        reverse={true}
                        heading={""}
                        subHeading={tutormyProfileLabel.HEADING}
                    />
                </Container>
                <TutorMyProfileForm history={props.history} />
            </div>
        </>
    )
}

export default TutorMyProfilePage
