import React from "react"
import { useTranslation } from 'react-i18next';
import { CalendarCancelIcon } from "../../../../components/icons/Icons"



const LessonSubscribeCancelation = (): JSX.Element => {
    const { t } = useTranslation();
    return (
        <div className="cancelation d-flex">
            <div className="image">{CalendarCancelIcon(null)}</div>
            <div className="description">
                <p>
                    <span>{t("subscription-policy")}:</span>{" "}{t("subscription-policy-text")}
                </p>
            </div>
        </div>
    )
}

export default LessonSubscribeCancelation

