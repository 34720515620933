import colors from "assets/colors"
import React from "react"
import interfaceConstant from "../../../utils/constant/interfaceConstant"

class ShieldSvgIcon extends React.PureComponent<interfaceConstant.SvgIconProps> {
    render(): JSX.Element {
        const { width, height } = this.props
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 145 145">
                <g fill="none" fillRule="evenodd">
                    <g>
                        <g fill={colors.darkMango}>
                            <path d="M72.5 0C32.46 0 0 32.46 0 72.5S32.46 145 72.5 145 145 112.54 145 72.5 112.54 0 72.5 0z" />
                        </g>
                        <g>
                            <path fill="#FFF" d="M64.976 42.523c-.146-3.684-.108-7.383-.074-10.965.017-1.61.034-3.217.034-4.823l.004-4.17V21.39c-.009-4.472-.017-9.093.025-13.666C59.08 6.34 53.143 4.902 47.393 3.51 42.698 2.37 37.843 1.2 33.073.063c-.342-.08-.795-.084-1.1-.012-4.687 1.112-9.454 2.267-14.067 3.385C12.052 4.853 6.008 6.313.025 7.718l.017 3.832C.097 21.847.158 32.498.004 42.99c-.108 7.338 2.25 13.723 7.202 19.54C13.264 69.632 21.307 75.193 32.488 80c9.513-4.014 17.059-8.92 23.05-14.99 6.682-6.775 9.77-14.13 9.438-22.487z" transform="translate(40 33)" />
                            <path fill="#262FFA" d="M53.702 27.602c-2.878 3.571-5.765 7.135-8.636 10.71-4.672 5.825-9.323 11.665-14.006 17.481-2.635 3.273-4.755 3.238-7.26-.08-4.143-5.482-8.287-10.962-12.423-16.448-1.398-1.856-1.383-3.769.016-5.026 1.396-1.253 3.404-1.13 5.183.358 3.04 2.546 6.06 5.116 9.094 7.67.487.41 1.012.78 2.01 1.544.392-.61.65-1.25 1.11-1.696 6.39-6.22 12.794-12.427 19.236-18.595.695-.665 1.597-1.298 2.514-1.536 1.521-.395 2.866.184 3.654 1.57.817 1.432.516 2.796-.492 4.048z" transform="translate(40 33)" />
                        </g>
                    </g>
                </g>
            </svg>
        )
    }
}

export default ShieldSvgIcon
