import { PaymentConstant } from "../constant"


const InitialState = {
    history: [],
    isLoading: false,
    error: false,
    message: "",
    hasMore: false,
    estimatedAmount: 0
}

const paymentHistoryReducer = (state = InitialState, action: any) => {
    switch (action.type) {
        case PaymentConstant.FETCH_PAYMENT_HISTORY: {
            return { ...state, isLoading: true, history: action.payload.starting_after ? state.history : [] }
        }
        case PaymentConstant.PAYMENT_HISTORY_REQUEST_SUCCESS: {
            return { ...state, isLoading: false, history: [...state.history, ...action.payload?.data?.data], hasMore: action.payload?.data?.has_more, estimatedAmount: action.payload?.data?.estimatedAmount }
        }
        default:
            return state
    }
}

export default paymentHistoryReducer
