import React, { useEffect, useState } from "react"
import "./App.scss"
import Routes from "./routes/Routes"
import { Button, createTheme, ThemeProvider } from "@material-ui/core"
import colors from "./assets/colors"
import { MuiPickersUtilsProvider } from "@material-ui/pickers"
import moment from "moment"
import MomentUtils from "@date-io/moment"
import DevBanner from "./components/dev-banner/DevBanner"
import { Provider } from "react-redux"
import store from "./store"
import { useClearCache } from "react-clear-cache"
import { SnackbarProvider } from "notistack"
import { ModalProvider } from "react-modal-hook"
import ReactGA from "react-ga4"
import {
    QueryClient,
    QueryClientProvider,
} from 'react-query';
import {firebaseAuth} from "utils/helper/firebase";

const queryClient = new QueryClient()

const breakpointValues = {
    xs: 0,
    sm: 415,
    md: 769,
    lg: 1025,
    xl: 1440,
}
const customTheme = createTheme({
    palette: {
        primary: {
            main: colors.darkMango,
        },
    },
    typography: {
        fontFamily: "Montserrat-Medium",
        fontSize: 14,
    },
    breakpoints: {
        values: breakpointValues,
    },
})

class LocalizedUtils extends MomentUtils {
    getDatePickerHeaderText(date: any) {
        moment.updateLocale("en", {
            monthsShort: [
                "Jan",
                "Feb",
                "Mar",
                "Apr",
                "May",
                "June",
                "July",
                "Aug",
                "Sept",
                "Oct",
                "Nov",
            ],
        })
        return moment(date).format("ll")
    }
}

const App = (): JSX.Element => {
    const [shouldClearCacheNow, setShouldClearCacheNow] = useState(false);
    useEffect(() => {
        const version = localStorage.getItem("APP_VERSION")
        !version && setShouldClearCacheNow(true)
        ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID || '');
    }, [])
    const { isLatestVersion, emptyCacheStorage, latestVersion } = useClearCache()
    useEffect(() => {
        if (!isLatestVersion || shouldClearCacheNow) {
            // if not latest version or there was no latest version string
            emptyCacheStorage().catch(console.error)
            localStorage.clear();
            queryClient.invalidateQueries();
            localStorage.setItem("APP_VERSION", JSON.stringify(latestVersion || ""));
        } //clear cache if cache is not of latest version
    }, [isLatestVersion, shouldClearCacheNow, latestVersion]);
    useEffect(() => {
        let currentUser = firebaseAuth.currentUser;
        const unsubFirebaseAuthListener = firebaseAuth.onAuthStateChanged((user) => {
            if (!user || user?.uid !== currentUser?.uid) {
                queryClient.clear();
                currentUser = user;
                ReactGA.set({
                    userId: user?.uid
                });
            }
        });
        return () => {
            unsubFirebaseAuthListener();
            queryClient.invalidateQueries();
        }
    }, []);
    const notistackRef = React.createRef<any>()
    const onClickDismiss = (key: any) => () => {
        notistackRef.current.closeSnackbar(key)
    }
    return (
        <ThemeProvider theme={customTheme}>
            {/* <NetworkDetector /> */}
            {process.env.REACT_APP_ENV === "dev" ? <DevBanner /> : null}
            <MuiPickersUtilsProvider
                libInstance={moment}
                utils={LocalizedUtils}
                locale={moment.locale()}
            >
                <Provider store={store}>
                    <QueryClientProvider client={queryClient}>
                        <SnackbarProvider
                            ref={notistackRef}
                            action={(key) => (
                                <Button onClick={onClickDismiss(key)} color={"inherit"}>
                                    Close
                                </Button>
                            )}
                        >
                            <ModalProvider>
                                <Routes />
                            </ModalProvider>
                        </SnackbarProvider>
                    </QueryClientProvider>
                </Provider>
            </MuiPickersUtilsProvider >
        </ThemeProvider >
    )
}

export default App
