import React from "react"
import { NavLink } from "react-router-dom"
import PowerSvgIcon from "../../assets/images/svgIcons/PowerSvgIcon"
import { useDispatch } from "react-redux"
import { logOutRequest } from "../../store/actions/userActions"
import LogoWithText from "../../assets/images/svgIcons/LogoWithText"
import { menuIcon } from '../../components/icons/Icons';


const SlideMenu = ({ firstName, school, picURL, collapse, routes }: { firstName: string, school: string, picURL: string, collapse: any, routes: any }): JSX.Element => {
    const dispatch = useDispatch()

    const logOut = (): void => {
        dispatch(logOutRequest())
    }

    return (
        <>
            <div id="slide-menu" className="slide-menu">
                <div className="header">
                    <div className="navbar-logo">
                        <LogoWithText />
                    </div>
                    <div className="close" onClick={collapse}>
                        {menuIcon({ height: "50px", width: "50px"})}
                    </div>
                </div>
                <ul className="nav">
                {routes.map((prop: any, key: number) => {
                    if (!prop.redirect)
                        return (
                            <li key={key} onClick={collapse}>
                                <NavLink
                                    to={prop.layout + prop.path}
                                    className="nav-link"
                                    activeClassName="active"
                                >
                                    <span className="nav-icon">
                                        <prop.icon />
                                    </span>
                                    <span className="nav-title">{prop.name}</span>
                                </NavLink>
                            </li>
                        )
                    return null
                })}
            </ul>
            <div className="logout-wrapper">
                <ul className="nav">
                    <li>
                        <div className="nav-link" onClick={() => logOut()}>
                            <span className="nav-icon">
                                <PowerSvgIcon />
                            </span>
                            <span className="nav-title">Sign out</span>
                        </div>
                    </li>
                </ul>
            </div>
            </div>
        </>
    )
}

export default SlideMenu