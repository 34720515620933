import React from "react"
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { CardView } from "../../../../components/card/CardView"
import { navigatetoUrlWithProps } from "../../../../utils/helper/helper"
import { useHistory } from "react-router-dom";
import Button from "components/button/Button";
import advisorsImage from "../../../../assets/images/advisors_2.png"
import noOrOneTutor from "../../../../assets/images/tutors_1.png"
import noSessionImg from "../../../../assets/images/no-session.svg"

import colors from "assets/colors";
import AddFilledSvgIcon from "assets/images/svgIcons/AddFilledSvgIcon";


const NaviagationCard = ({ title, subtitle, url, buttonText, type, buttonAction, noSessions = true, noResult = true, children, noTutors = true, noAdvisors = true, oneTutor = true, oneAdvisor = true }: { title: string, subtitle?: string, url: string, noSessions?: boolean, buttonText?: string, type?: string, noResult?: boolean, children?: any, buttonAction?: any, noTutors?: boolean, noAdvisors?: boolean, oneTutor?: boolean, oneAdvisor?: boolean }): JSX.Element => {
    const history = useHistory()
    return (
        <div onClick={() => {
            type !== "sessions" && !noSessions && navigatetoUrlWithProps(history, url, { type })
        }}>
            {type === "sessions" && noSessions ?
                <CardView overFlowChildStyle className="navigation-card session-text-center-on-md"
                    style={{ position: "relative" }}
                >
                    <p className="title">{title}</p>
                    <p className="sub-title" style={{
                        color: noResult ? colors.darkMango : colors.battleshipGrey
                    }}>{subtitle}</p>

                    <div className="bottom-content">
                        <div className="nav-footer">
                            <div className="md-screen-image">
                                {type === "sessions" && noSessions && <img src={noSessionImg} className="no-sessions-md-img" />}

                            </div>
                            <div className="goto-arrow">
                                <ArrowForwardIosIcon onClick={() => navigatetoUrlWithProps(history, url, { type })} />
                            </div>
                        </div>
                    </div>


                    <div className="navigation-button-container">
                        <Button className="navigation-button" title={buttonText} clickAction={buttonAction || (() => { navigatetoUrlWithProps(history, url, { type }) })} />
                    </div>
                </CardView>
                : <CardView overFlowChildStyle className="navigation-card"
                    style={{ position: "relative" }}
                >
                    <p className="title">{title}</p>
                    <p className="sub-title" style={{
                        color: noResult ? colors.darkMango : colors.battleshipGrey
                    }}>{subtitle}</p>
                    <div className="lg-screen-image">
                        {type === "tutors" && <img src={noOrOneTutor} />}

                        {type === "advisors" && <img src={advisorsImage} />}
                        {((type === "tutors" && !noTutors) || (type === "advisors" && !noAdvisors)) &&
                            <div className="search-new">
                                <AddFilledSvgIcon iconColor={colors.vividBlue} width="26" height="26" />
                                <p onClick={() => navigatetoUrlWithProps(history, "/panel/search", { type })}>Search for new {type === "tutors" ? "Tutors" : "Advisors"}</p>
                            </div>}
                    </div>
                    <div className="bottom-content">
                        <div className="nav-footer">
                            <div className="md-screen-image">
                                {type === "sessions" && noSessions && <img src={noSessionImg} className="no-sessions-md-img" />}
                                {type === "tutors" && <img src={noOrOneTutor} />}
                                {type === "advisors" && <img src={advisorsImage} />}
                                {((type === "tutors" && !noTutors) || (type === "advisors" && !noAdvisors)) &&
                                    <div className="search-new">
                                        <AddFilledSvgIcon iconColor={colors.vividBlue} width="26" height="26" />
                                        <p onClick={() => navigatetoUrlWithProps(history, "/panel/search", { type })}>Search for new {type === "tutors" ? "Tutors" : "Advisors"}</p>
                                    </div>}
                                {/* {children} */}
                            </div>

                            <div className="goto-arrow">
                                <ArrowForwardIosIcon onClick={() => navigatetoUrlWithProps(history, url, { type })}></ArrowForwardIosIcon>
                            </div>
                        </div>
                    </div>


                    <div className="navigation-button-container">
                        {noSessions && <Button className="navigation-button" title={buttonText} clickAction={() => { navigatetoUrlWithProps(history, url, { type }) }} />}
                    </div>
                </CardView>

            }
        </div>
    )
}
export default NaviagationCard
