/**
 * Max time a booking will remain in accept/reject
 * phase. after this time it will be removed if it is not 
 * final by then
 * Note: time is in seconds.
 */
export const MAX_PENDING_TIME_FOR_BOOKING = 24 * 60 * 60; // 24 hours (in seconds)
export const BOOKING_SLOT_INTERVAL_LENGTHS = [30, 30]; // [ normal, rapid booking]
export const MAX_RAPID_BOOKING_TIME = 3 * 60; // in minutes
export const MAX_NO_OF_AVAILABILITY_SLOTS = 48;
export const AVAILABILITY_SLOT_LENGTH = 30; // in minutes
export const MIN_THRESHOLD = 0

export const BOOKING_EXTENSION_STATUSES = {
	REQUESTED: 'REQUESTED',
	ACCEPTED: 'ACCEPTED',
	CANCELLED: 'CANCELLED',
	CONFIRMED: 'CONFIRMED',
	PAYMENT_PROCESSING: 'PAYMENT_PROCESSING',
	PAYMENT_FAILED: 'PAYMENT_FAILED',
	PAYMENT_ACTION_REQUIRED: 'PAYMENT_ACTION_REQUIRED',
}