import React from "react"
import interfaceConstant from "../../../utils/constant/interfaceConstant"

class LoaderSvgIcon extends React.PureComponent<interfaceConstant.SvgIconProps> {
    render(): JSX.Element {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="131" height="89" viewBox="0 0 131 89">
                <g fill="none" fillRule="evenodd">
                    <g fill="#FFF" fillRule="nonzero">
                        <g>
                            <g>
                                <path
                                    d="M122.31 1.212c3.724 0 6.742 2.984 6.742 6.664v58.596c0 3.68-3.018 6.663-6.742 6.663H98.858c-20.343 0-27.97 13.506-27.97 13.506V15.974c0-14.438 19.07-14.762 27.97-14.762h23.452zm-4.7 39.897c2.108 0 3.814-1.687 3.814-3.768 0-2.083-1.706-3.77-3.814-3.77-2.106 0-3.815 1.687-3.815 3.77 0 2.081 1.709 3.768 3.815 3.768zM59.46 15.299v72.15S51.66 73.66 30.866 73.66H6.893C3.085 73.66 0 70.615 0 66.857V7.03C0 3.272 3.085.227 6.893.227h23.974c9.096 0 28.592.331 28.592 15.072"
                                    transform="translate(-655 -2109) translate(534 1967) translate(121.55 142.8)"
                                />
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        )
    }
}

export default LoaderSvgIcon
