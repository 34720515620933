import React from "react"
import interfaceConstant from "../../../utils/constant/interfaceConstant"

class SearchSvgIcon extends React.PureComponent<interfaceConstant.SvgIconProps> {
    render(): JSX.Element {
        const { width, height, iconColor, ...otherProps } = this.props
        return (
            <svg width={width} height={height} {...otherProps}>
                <path
                    d="M28,26.5270282 L21.1251303,19.6714159 C24.3451938,15.6766825 23.8670726,9.85865173 20.0380466,6.44320699 C16.2090206,3.02776224 10.3743768,3.21488331 6.77206554,6.8686562 C3.16975424,10.5224291 3.06540873,16.35914 6.5348196,20.1393372 C10.0042305,23.9195345 15.8284575,24.3150864 19.7771167,21.0386869 L26.6519863,27.9135565 L28,26.5270282 Z M8.08791247,19.0359237 C5.2608071,15.9416644 5.36837567,11.1708057 8.33206975,8.20711161 C11.2957638,5.24341754 16.0666226,5.13584896 19.1608818,7.96295433 C21.9879872,11.0572136 21.8804186,15.8280724 18.9167246,18.7917664 C15.9530305,21.7554605 11.1821717,21.8630291 8.08791247,19.0359237 L8.08791247,19.0359237 Z"
                    fill={iconColor}
                    fillRule="evenodd"
                />
            </svg>
        )
    }
}

export default SearchSvgIcon
