import React, { useEffect, useState } from "react"
import { BOOKING_REQUEST_STATUS_CODES } from "store/reducers/sessions-reducers/sessionReducer";
import { getUserMetaInfo } from "utils/helper/firebase";
import { updateAffiliate } from "utils/services/userServices";

const ShareaSaleBooking = (props: any) => {
    let data = props?.props;
    const [newSale, setNewSale] = useState<boolean>(false)
    const sessionAmount = ((data?.totalSessionLength / 60) * data?.teacherHourlyRate) || 0;
    const getStudentInfo = async () => {
        await getUserMetaInfo(data?.studentId).then((student) => {
            if (!student?.affiliate?.shareaSale) {
                const affiliate = { shareaSale: true };
                updateAffiliate({ affiliate: affiliate }, data?.studentId).then((data) => {
                    setNewSale(true);
                })
            }
        })
    }
    useEffect(() => {
        if (data?.cookie && data?.status == BOOKING_REQUEST_STATUS_CODES.ACCEPTED) {
            if (data.cookie.includes("clickId")) {
                document.cookie = data.cookie;
                getStudentInfo()
            }
        }
    }, [props])
    return (
        <div>
            {newSale && data.status == BOOKING_REQUEST_STATUS_CODES.ACCEPTED &&
                <img src={`https://www.shareasale.com/sale.cfm?tracking=${data.id}&amount=${sessionAmount}&merchantID=121532&transtype=sale`} width="1" height="1"></img>
            }
        </div>
    )
}
export default ShareaSaleBooking
