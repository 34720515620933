import {
    TutorSignUpConstant,
    TutorRatesConstant,
    TutorCancelPolicyconstant,
    TutorEducationConstant,
} from "../constant"

interface IteacherTypeProps {
    teacherType: string
    value: {}
}

export const tutorSignupRequest = (props: any): object => {
    return { type: TutorSignUpConstant.TUTOR_SIGNUP_REQUEST, props }
}

export const selectteacherType = (props: IteacherTypeProps): object => {
    return { type: TutorSignUpConstant.SET_TUTOR_DATA_SUCCESS, props }
}

export const setTeacherMyprofile = (props: any): object => {
    return { type: TutorSignUpConstant.SET_MY_PROFILE_TUTOR_DATA, props }
}

export const setTeacherAvailableSlot = (props: any): object => {
    return { type: TutorSignUpConstant.SET_TUTOR_AVAILABILITY_SLOT, props }
}

export const setTutorPayment = (props: any): object => {
    return { type: TutorSignUpConstant.SET_TUTOR_PAYMENT_DATA, props }
}
export const setTutorRates = (props: any): object => {
    return { type: TutorRatesConstant.SET_TUTOR_RATE, props }
}

export const setTutorCancellationPolicy = (props: any): object => {
    return { type: TutorCancelPolicyconstant.SET_CANCEL_POLICY, props }
}
export const setTutorEducation = (props: any): object => {
    return { type: TutorEducationConstant.SET_TUTOR_EDUCATION, props }
}
