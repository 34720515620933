
import firebase from "../../utils/config/firebase"
const firestore = firebase.firestore()
export const database = firebase.database()

export const getUserRooms = async (uid: string): Promise<any[]> => {
    const result: any = await firestore.collection("users").doc(uid).collection("rooms").get()
    const rooms: Array<any> = []
    result.forEach((doc: any) => {
        rooms.push(doc.data())
    })
    return rooms
}

interface UserInfo {
    id: string
    name: string
    email: string
}

enum ActionTypes {
    JOIN_EPICBOARD_SESSION = "JOIN_EPICBOARD_SESSION",
    GET_VENDOR_LINK = "GET_VENDOR_LINK"
}

export const getAllUsers = async (): Promise<UserInfo[]> => {
    const userCollection = firestore.collection("users")
    const usersQuery = await userCollection.orderBy("firstName", "asc").get()
    const userIds: Array<UserInfo> = []
    usersQuery.docs.forEach((doc) => {
        const data = doc.data()
        const newUser: UserInfo = {
            id: doc.id,
            name: `${data.firstName} ${data.lastName}`,
            email: data.email,
        }
        userIds.push(newUser)
    })
    return userIds
}

export const getRoomInformation = async (roomId: string): Promise<any> => {
    if (roomId) {
        const roomStateRef = database.ref("epicboard-rooms").child(roomId)
        const stateSnapshot = await roomStateRef.once("value")
        const stateVal = stateSnapshot.val()
        return stateVal
    }
}

export const getSessionInformation = async (sessionId: string): Promise<object | undefined> => {
    if (sessionId) {
        const sessionDoc = firestore.collection("epicboard-sessions").doc(sessionId)
        const stateSnapshot = (await sessionDoc.get()).data()
        return { ...stateSnapshot, sessionId }
    }
}

interface RoomInfoProp {
    name: string
    ownerId: string
    presenterIds: any
}

export const createRoom = async (roomInfo: RoomInfoProp, users: Array<string>): Promise<string> => {
    const newRoomRef = database.ref("rooms").push()
    const roomId: string = newRoomRef.key as string
    const roomMeta = {
        ...roomInfo,
        activeBoard: 0,
        id: roomId,
        createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
    }
    await firestore
        .collection("users")
        .doc(roomInfo.ownerId)
        .collection("rooms")
        .doc(roomId)
        .set({
            ...roomMeta,
        })

    const userRoomRef = firestore.collection("users")
    const roomRef = database.ref("rooms").child(roomId)
    const userRoomInfo = {
        ...roomInfo,
        id: roomId,
        createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
    }

    if (users.length > 0) {
        users.map(async (user) => {
            await roomRef.child("users").child(user).update({ id: user })
            await userRoomRef.doc(user).collection("rooms").doc(roomId).set(userRoomInfo, {
                merge: true,
            })
        })
    }

    await roomRef.child("meta").update(roomMeta)
    return roomId
}

export const getRoom = async (roomId: string) => {
    const roomInfo = await database.ref("rooms").child(roomId)
    let returnData = {}
    roomInfo.on(
        "value",
        function (snapshot: any) {
            returnData = snapshot.val()
        },
        function (errorObject: any) {
            console.log(`Erro: ${errorObject.code}`)
        },
    )
    return returnData
}

export const getUserInformation = async (userId: string) => {
    const userInfo = await firestore.collection("users").doc(userId).get()
    const returnData: any = userInfo.data()
    return {
        id: userId,
        name: `${returnData.firstName} ${returnData.lastName}`,
        email: returnData.email,
    }
}

export const getBookingDetails = async (sessionId: string) => {
    const sessionInfo = await firestore.collection("booking-requests").doc(sessionId).get()
    return sessionInfo.data()
}

export const updatePresenters = (presenterId: string, roomID: string): void => {
    let array: any
    const presentersRef = database.ref("rooms").child(roomID).child("meta").child("presenterIds")
    presentersRef.on("value", (snapshot) => {
        array = snapshot.val()
    })
    const index = array.indexOf(presenterId)
    if (index > -1) {
        array.splice(index, 1)
    } else {
        array.push(presenterId)
    }
    presentersRef.set(array).catch(console.error)
}

export const joinEpicboard = async (sessionId: string): Promise<any> => {
    const joinEpicboard = firebase.functions().httpsCallable("sessions")
    const roomId: any = await joinEpicboard({
        actionType: ActionTypes.JOIN_EPICBOARD_SESSION,
        sessionId,
    })
    if (
        roomId.data.message ===
        "This session is not started yet or completed! Please join on scheduled time."
    ) {
        return { error: roomId.data.message }
    }
    const roomDetails = await getRoomInformation(roomId.data.roomId || sessionId)
    const sessionDetails = await getSessionInformation(sessionId)
    return { ...roomDetails, tutorInfo: roomId.data.tutorUserDetails, sessionInfo: sessionDetails }
}

