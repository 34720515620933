import React, { useState } from "react"
import MiniCard from "../../../../components/mini-card/MiniCard"
import { Grid } from "@material-ui/core"
import Button from "../../../../components/button/Button"
import { plusIcon } from "../../../../components/icons/Icons"
import { useSelector, useDispatch } from "react-redux"
import { applicationConstant } from "../../../../utils/constant/applicationConstant"
import InterfaceConstant from "../../../../utils/constant/interfaceConstant"
import { selectteacherType } from "../../../../store/actions/TutorSignUpActions"

import subjectsList from "../../../../utils/constant/subjectsList"
import Modal from "../../../../components/modal_/Modal"
import { modalConstant } from "../../../../store/constant"
import { showToast } from "../../../../store/actions/toastAction"
import MultiSelectDropDown from "../../../../components/dropdown/MultiSelectDropdown"


export type dropdownListType = {
    id: string
    name: string
}

const TutorAdvisorSubjects = () => {
    const dispatch = useDispatch()
    const tutorAdvisorLabel: InterfaceConstant.ItutorAdvisorLabel =
        applicationConstant.tutorAdvisorLabel
    const tutorAdvisorErrorLable: InterfaceConstant.IappErrorLabels =
        applicationConstant.appErrorLabels
    const tutorInfo = useSelector((state: any) => state.tutorSignUp)
    const teacherTypeKeys: InterfaceConstant.IteacherTypes = applicationConstant.teacherTypeKeys
    const [modal, showModal] = useState(false)
    const [isShowError, setShowError] = useState(false)
    const handleError = (status: boolean): void => {
        if (isShowError !== status) {
            setShowError(status)
        }
    }
    const modalInfo = useSelector((state: any) => state.modal)



    const handledropdownValue = (subject: object, test: object, specialization: object): void => {
        dispatch(
            selectteacherType({
                teacherType: tutorInfo.teacherType,
                value: {
                    subject: subject,
                    test: test,
                    specialization: specialization,
                },
            }),
        )
    }

    const setsubjectvalue = (newOptionsState: dropdownListType[]): void => {
        handledropdownValue(newOptionsState, tutorInfo.value.test, tutorInfo.value.specialization)
    }

    const removeSubject = (id: string): void => {
        if (Object.keys(tutorInfo.value.subject).length === 1) {
            const modalInfo = {
                title: "Select Subject",
                icon: "",
                text: "You need to selectat least one Subject",
            }
            dispatch({ type: modalConstant.MODAL_SHOW, modalInfo })
        } else {
            const filtered = tutorInfo.value.subject.filter((e: dropdownListType) => e.id !== id)
            setsubjectvalue(filtered)
        }
    }
    const nextNavigation = (): void => {
        switch (tutorInfo.teacherType) {
            case teacherTypeKeys.TUTORING:
                if (tutorInfo.value.subject.length === 0) {
                    handleError(true)
                    return
                }
                showModal(false)
                break
            case teacherTypeKeys.ADVISING:

                if (tutorInfo.value.specialization.length === 0) {
                    handleError(true)
                    return
                }
                showModal(false)
                break
            case teacherTypeKeys.TUTORING_AND_ADVISING:
                if (
                    tutorInfo.value.subject.length === 0
                ) {
                    handleError(true)
                    return
                }
                showModal(false)
                break
            default:
                dispatch(
                    showToast({
                        show: true,
                        message: tutorAdvisorErrorLable.GLOBAL_SELECT_ACTIVITY_ERROR,
                        isSuccess: false,
                    }),
                )
                return
        }
        if (!isShowError) {
            showModal(false)
        }
    }

    const ModalContent = (): JSX.Element => {
        return (
            <div className="subjects-tests-modal">
                <h3>Add Subject</h3>
                <MultiSelectDropDown
                    title={tutorAdvisorLabel.SUBJECT_DROPDOWN_TITLE}
                    placeholder={tutorAdvisorLabel.SUBJECT_DROPDOWN_PLACEHOLDER}
                    optionsList={subjectsList}
                    error={
                        isShowError && tutorInfo.value.subject.length === 0
                            ? tutorAdvisorErrorLable.GLOBAL_DROPDOWN_ERROR
                            : ""
                    }

                    subjectvalue={setsubjectvalue}
                    selectedOption={tutorInfo.value.subject}
                />
                <Button type="button" title="Add Subjects" clickAction={(): void => nextNavigation()} />
            </div>
        )
    }
    return (
        <>
            <Grid item xs={12} md={4}>
                <h3>Subjects You Teach</h3>

                {tutorInfo.value.subject && tutorInfo.value.subject.map((e: dropdownListType, index: number) => (
                    <MiniCard key={index} title={e.name} action={(): void => removeSubject(e.id)} tooltip="Remove Subject" />
                ))}

                <div className="actions">
                    <Button title="" type="button" rightSideIcon={plusIcon} clickAction={(): void => showModal(true)} />
                    Add Subjects
                </div>
            </Grid>

            <Modal
                visible={modal}
                onExit={(): void => nextNavigation()}
                title=""
                children={ModalContent()}
            />
        </>
    )
}

export default TutorAdvisorSubjects
