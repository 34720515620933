import React, { useEffect, useState } from "react";
import { useBookingPending, ReviewPending, useReviewPending, PENDING_STATUS, PendingType } from "../../../../pendings";
import { useHistory } from "react-router-dom";
import SessionFeedbackModal from "../../../../pages/home/session-feedback/SessionFeedbackModal"

export function SessionReview(props: any) {
    const reviewPending:any = useReviewPending();
    const [sessionId, setSessionId] = useState<any>();
    const [teacherId, setTeacherId] = useState<any>();
    const [open, setOpen] = useState<boolean>(false);
  
    const history = useHistory();
    //write its logic in this effect
    useEffect(() => {
        reviewPending.ref.on('value',(snapshot:any) => {
            if (!snapshot.exists()) {
                return;
            }
            const nData=snapshot.val();
            const pendingReviewItems :Array<any> = Object.values(nData).filter((pi: any) => pi.status !== PENDING_STATUS.RESOLVED);
                if(pendingReviewItems.length>0){
                const data: any = pendingReviewItems[0].data;
                const currentTime = new Date();
                const endTime = new Date(data.endTime);
                if (endTime < currentTime) {
                    setTeacherId(data.teacherId);
                    setSessionId(data.sessionId);
                    setOpen(true);
                }
            }
            }   
        )
    }, [reviewPending]);

    
    const close = () => {
        setOpen(false);
    }
    const resolve = async (nsessionId :any) =>{
      await reviewPending.resolveItem(nsessionId);
    }
    return (<div>
        <SessionFeedbackModal open={open} close={close} teacherId={teacherId} sessionId={sessionId} resolveStatus= {resolve} />
    </div>)
}
