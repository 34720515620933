import React from 'react';
import interfaceConstant from '../../../utils/constant/interfaceConstant';

class DoubleTickSvgIcon extends React.PureComponent<interfaceConstant.SvgIconProps> {
    render() {
        const { width, height, iconColor, ...otherProps } = this.props
        return (
            <svg width={width} height={height} viewBox="0 0 32 32" {...otherProps}>
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g fill={iconColor} transform="translate(14.000000, 11.000000)">
                        <path d="M9.74583074,0.205393481 C10.0548711,0.5009586 10.0798892,1.00674609 9.80171028,1.33510146 L3.02523969,9.33510146 C2.88702,9.49811385 2.69196244,9.59381735 2.48553899,9.59990146 L2.46568393,9.59990146 C2.26600981,9.59990146 2.07453114,9.51553203 1.93335452,9.36550146 L0.648257569,7.99987644 L1.66186523,6.84161377 L2.43698181,7.63820546 L8.68257616,0.264765463 C8.96075511,-0.063589896 9.43679039,-0.0901716377 9.74583074,0.205393481 Z" />
                    </g>
                    <path
                        d="M11.7598016,20.5999015 C11.5601275,20.5999015 11.3686488,20.515532 11.2274722,20.3655015 L8.21570746,17.1655015 C7.92623697,16.8523167 7.92837887,16.3497874 8.22050715,16.0394011 C8.51263544,15.7290148 8.98560416,15.7267391 9.28036628,16.0343015 L11.7310995,18.6382055 L17.9766938,11.2647655 C18.2548728,10.9364101 18.730908,10.9098284 19.0399484,11.2053935 C19.3489887,11.5009586 19.3740069,12.0067461 19.0958279,12.3351015 L12.3193573,20.3351015 C12.1811377,20.4981139 11.9860801,20.5938174 11.7796566,20.5999015 L11.7598016,20.5999015 Z"
                        fillRule="nonzero"
                        fill={iconColor}
                    />
                </g>
            </svg>
        )
    }
}

export default DoubleTickSvgIcon;
