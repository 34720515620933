import React from "react"

interface Props {
    at: any,
    theme: any,
}

const ProgressBar = ({ at, theme }: Props) => {
  const styles = {
    at: {
      width: `${at}vw`,
      height: "5px",
    }
}
    return (
        <div className="step-bar">
             <div style={styles.at} className={theme}/>
        </div>
    )
}

export default ProgressBar
