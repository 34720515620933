import React from "react"

interface Props {
    hiddenXS?: boolean
}

const OnboardingBackground = ({ hiddenXS }: Props): JSX.Element => {
    return (
       
            <div className={`background-svg ${hiddenXS ? "hidden-xs" : ""}`}></div>
       
    )
}

export default OnboardingBackground
