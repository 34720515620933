import React from "react";
import Modal from "components/modal_/Modal";
import './reschedulingPopups.scss';
import Button from "components/button/Button";
import { oneBookingPopupType } from "../SingleSlotBookingRequested";
import moment from "moment";
import { useDispatch } from "react-redux";
import { acceptBooking, cancelBooking } from "store/actions/sessionsActions";
import { SLOT_STATUSES } from "store/constant";

export function StudentRescheduleRequest(props: oneBookingPopupType) {

    const dispatch = useDispatch();
    let studentNameSplitted = props.studentName.split(' ');

    return <div className="tutor-student-reschedule-modal">
        <Modal
            visible={props.isShown}
            onExit={props.onClose}
        >
            <div className="modal-reschedule-request-receive">
                <img src={props.studentPicUrl} alt="tutor images" />
                <h3>New Reschedule Request from {studentNameSplitted[0] + " " + (studentNameSplitted[1] !== undefined ? studentNameSplitted[1].charAt(0) + "." : "")}</h3>
                <h4>Current Booking: {moment(props.originalStartTime).format("dddd, MMMM DD, YYYY")} at {moment(props.originalStartTime).format("hh:mm A")}</h4>  {/*March 7, 2020 10:00 AM*/}
                <div className="grey_div">
                    <h4>Reschedule request:</h4>
                    <h4> {moment(props.startTime).format("dddd, MMMM DD, YYYY")} at {moment(props.startTime).format("hh:mm A")}</h4>
                </div>
                <div className="reschedule-action-btn">
                    <Button className="reject-reschedule-btn" title="Reject Reschedule" clickAction={() => {
                        dispatch(cancelBooking(props.id, { comment: "", slots: [{ ...props.slots[0], status: SLOT_STATUSES.CANCELLED }] }));
                        props.onClose();
                    }} isOutlineStyle={true} />
                    <Button className="accept-reschedule-btn" title="Accept Reschedule" clickAction={() => {
                        dispatch(acceptBooking(props.id, { comment: "", slots: [{ ...props.slots[0], status: SLOT_STATUSES.ACCEPTED }] }));
                        props.onClose();
                    }} isOutlineStyle={true} />
                </div>
            </div>

        </Modal>
    </div>
} 