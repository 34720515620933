import React, { useEffect, useState } from "react"
import { Grid } from "@material-ui/core"
import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux"
import Modal from "../../../../components/modal_/Modal"
import { Launch } from "@material-ui/icons";
import { targetForRedirection } from "utils/helper/helper";


const SubscriptionTile = ({ cancelSubscription }: { cancelSubscription: any }): JSX.Element => {
    const { userAuthData } = useSelector((state: any) => state.auth)
    const { isloading } = useSelector((state: any) => state.subscriptions)
    const [cancelSubscriptionModal, setCancelSubscriptionModal] = useState<boolean>(false)
    const [daysLeft, setDaysLeft] = useState<number>(0)
    const { t } = useTranslation();
    const { nickname, unit_amount_decimal, unit_amount, current_period_end, id, metadata, status, hosted_url } = userAuthData?.subscription;

    useEffect(() => {
        const currentDate = new Date();
        setDaysLeft(Math.floor((current_period_end - (currentDate.getTime() / 1000)) / 86400));
    }, [userAuthData.subscription])
    useEffect(() => {
        if (!isloading) {
            setCancelSubscriptionModal(false)
        }
    }, [isloading])
    return (
        <>
            {(status === 'canceled' && daysLeft < 0) || status === "incomplete_expired" ? <></>
                :
                <>
                    <Modal
                        visible={cancelSubscriptionModal}
                        onExit={() => setCancelSubscriptionModal(!cancelSubscriptionModal)}
                        buttons={[{ title: t("keep-subscription"), clickAction: () => setCancelSubscriptionModal(false), isOutlineStyle: false }, { title: t("cancel-subscription"), clickAction: () => cancelSubscription(id), isloading, isOutlineStyle: true }]}
                    >
                        <p className="modal-title">You are subscribed to {nickname} to all lessons for {t('usd')}{unit_amount_decimal ? unit_amount / 100 : unit_amount}/{metadata?.period}</p>
                        <h2>Please confirm your subscription</h2>
                    </Modal>
                    <Grid container className="subscription-tile">

                        <Grid item xl={2} lg={2} md={2} sm={12} xs={12} className="margin-auto flex-none-on-tab  flex">
                            <p className="margin-auto subscribtion-card-content text-center subscription-title">{nickname}</p>
                            <p className="margin-auto subscription-description-hidden subscribtion-card-content ">{metadata?.description?.substring(0, 90)}...</p>
                        </Grid>
                        <Grid item xl={3} lg={3} md={3} sm={12} xs={12} className="margin-auto on-tab-hidden flex">
                            <p className="margin-auto subscription-description subscribtion-card-content">{metadata?.description?.substring(0, 75)}...</p>
                        </Grid>

                        <Grid item xl={2} lg={2} md={2} sm={12} xs={12} className="margin-auto  flex subscription-price-day-align">
                            <p className="margin-auto text-center subscription-price subscribtion-card-content px-1">- {t('usd')} {unit_amount_decimal ? unit_amount / 100 : unit_amount}</p>
                            {status === "active" || status === "canceled" ?
                                <p className="text-center  subscription-days px-1"><span className="text-warning">{daysLeft}</span> {t('days-left')}</p>
                                :
                                <p className="text-center  subscription-days px-1"><span className="text-alert">{status}</span> (<a href={hosted_url} target={targetForRedirection()}> Pay Now! <Launch fontSize={"inherit"} /></a>)</p>
                            }
                        </Grid>
                        {status === "active" || status === "canceled" ?
                            < Grid item xl={3} lg={3} md={3} sm={12} xs={12} className="margin-auto flex subscription-days-grid-hide">
                                <p className="margin-auto text-center subscribtion-card-content subscription-days px-1"><span className="text-warning">{daysLeft}</span> {t('days-left')}</p>
                            </Grid>
                            :
                            <Grid item xl={3} lg={3} md={3} sm={12} xs={12} className="margin-auto flex subscription-days-grid-hide">
                                <p className="margin-auto text-center subscribtion-card-content subscription-days px-1"><span className="text-alert">{status}</span> (<a href={hosted_url} target={targetForRedirection()}> Pay Now! <Launch fontSize={"inherit"} /></a>)</p>
                            </Grid>
                        }
                        {status === "canceled" ?
                            <Grid item xl={2} lg={2} md={2} sm={12} xs={12} className="margin-auto  flex">
                                <p className="margin-auto subscribtion-card-content float-right px-1">{t("canceled")}</p>
                            </Grid>
                            :
                            <Grid item xl={2} lg={2} md={2} sm={12} xs={12} className="text-right subscription-cancel-links">
                                <p className="margin-auto float-right subscription-change subscribtion-change-style subscribtion-card-content" onClick={() => setCancelSubscriptionModal(true)}>{t('cancel-subscription')}</p>
                            </Grid>
                        }
                    </Grid>
                </>
            }
        </>
    )
}

export default SubscriptionTile
