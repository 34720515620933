const subjectsList = [

    { id: '8f11822e-16d1-4f07-adb9-f54470fa586f', name: 'Art' },
    { id: 'c38e981b-0184-4d1f-aa0b-fa9d8a51b8f6', name: 'Art: Architecture' },
    { id: '3b1b2a25-4dd5-42b2-9a0e-493ec2f93c09', name: 'Art: Art Theory' },
    { id: '912eaf9b-9df0-4ad9-b029-2975c66e5d0d', name: 'Art: Drawing' },
    { id: '99be1d34-a267-46fd-9d10-3902bb85c46d', name: 'Art: Film Studies' },
    { id: '61dae0b0-328b-4ead-8c16-d9eef95630dd', name: 'Art: Music Composition' },
    { id: 'fd35d563-69bd-4289-9f8b-094de093ffff', name: 'Art: Painting' },
    { id: '283f9654-9761-4ba4-9c2d-710ccb9c00ae', name: 'Art: Photography' },
    { id: '0906ec8b-71f4-40f4-ac87-0a27ddb38715', name: 'Art: Sculpture' },
    { id: 'e07c97a8-bac8-40fd-85e9-6fd459863c24', name: 'Art: Theatre' },

    { id: '32d8b178-415b-4179-a339-e6a36b9baed4', name: 'Astronomy' },

    { id: '4ded8f8c-ba12-400e-8fdd-3aac938d2645', name: 'Biochemistry' },

    { id: '32a10927-307e-4c5b-aaf0-d1d5fdd0c8ca', name: 'Biology' },
    { id: 'f7367de2-2b5c-4e6c-a470-de9530436301', name: 'Biology (9-12)' },
    { id: '03b5d99c-5cb3-11ec-bf63-0242ac130002', name: 'Biology (K-5)' },
    { id: '03b5dbea-5cb3-11ec-bf63-0242ac130002', name: 'Biology (6-8)' },
    { id: '97a205b6-d796-4f33-96cc-b1d634147929', name: 'Biology (College)' },
    { id: 'a05274d7-5870-403e-a906-b849ebfa573f', name: 'Biology (K-12)' },

    { id: 'c13740af-a1ee-486a-89af-6da3f507bbbb', name: 'Business' },

    { id: 'c58465a0-92fb-4378-9e1a-2e1877d6ae0a', name: 'Chemistry' },
    { id: 'cbfc01ef-7601-47f3-ba50-b26d3d67f813', name: 'Chemistry (9-12)' },
    { id: '03b5dd02-5cb3-11ec-bf63-0242ac130002', name: 'Chemistry (K-5)' },
    { id: '03b5ddfc-5cb3-11ec-bf63-0242ac130002', name: 'Chemistry (6-8)' },
    { id: '37fc6698-36ed-4306-a1db-514bba2d80ac', name: 'Chemistry (College)' },
    { id: 'c33db7d2-3525-4f55-b79a-1b7c318fa587', name: 'Chemistry (K-12)' },
    { id: '1c374b06-16b5-4499-b851-be6643aa52e9', name: 'Chemistry (Organic)' },

    { id: '6e6e6063-b65c-4f38-b289-443a28e5f7a7', name: 'Computer Science' },

    { id: 'b0753a6f-1603-48ef-9a8e-107d9e7869cc', name: 'Computer: App Design' },
    { id: '0602e930-a549-4880-a779-96ed85d5e85c', name: 'Computer: ASP.NET' },
    { id: 'ae75642c-de5c-4bc4-bbcb-7ae46965318f', name: 'Computer: Adobe Flash' },
    { id: '54ea396b-c43c-46b4-a08b-28ae4307a46a', name: 'Computer: Adobe Illustrator' },
    { id: '36759d0f-c7dd-4da0-bf3d-eeffe2fd031d', name: 'Computer: Adobe InDesign' },
    { id: '48c35bf3-dfb3-4b4b-9e04-1ae90db121c4', name: 'Computer: Adobe Lightroom' },
    { id: '4efeedbf-824f-4c98-a196-23b2f9b1c183', name: 'Computer: Adobe Photoshop' },
    { id: 'c498295a-9006-41b4-966d-067fa81c5f86', name: 'Computer: Angular' },
    { id: 'd2d784b4-375b-4fee-b47a-9661ce87d53b', name: 'Computer: Animation' },
    { id: 'e1072d1d-24eb-4260-bca8-233207e6fcb2', name: 'Computer: AutoCAD' },
    { id: 'e128f0f4-dac4-4274-8cba-f3b5fc00104a', name: 'Computer: Autodesk Maya' },
    { id: 'c9846208-b30b-4d39-b009-9a0cbd693ffc', name: 'Computer: C' },
    { id: 'faea0f05-5084-4bcc-a41d-69ffdf143abd', name: 'Computer: C#' },
    { id: 'c274ee1a-3404-4725-a44c-139e3a649b00', name: 'Computer: C/C++' },
    { id: '5a151595-74a8-4f28-9cfd-f56706d4aaba', name: 'Computer: COBOL' },
    { id: 'b8a1914e-c2fc-4531-92e4-915349a9b003', name: 'Computer: CSS' },
    { id: 'b1191390-a547-43d5-8809-8f37ea745435', name: 'Computer: Coding' },
    { id: 'ae58bcd2-b315-4ad4-bf9d-c05c857b508a', name: 'Computer: Computer Engineering' },
    { id: 'f3ea5963-c74d-4856-9d39-f5aed98a4d63', name: 'Computer: Computer Gaming (Game Design)' },
    { id: '92d19b8d-bbbd-4264-9c8b-8824debc4a8b', name: 'Computer: Computer Networking' },
    { id: 'fc01b605-7039-457e-a28b-0047324d5dca', name: 'Computer: Computer Programming' },
    { id: 'f3c24b00-c355-4ee9-a391-d9d5e1f9583c', name: 'Computer: DOS' },
    { id: '8f6a1de0-e197-4abb-8bf1-91b63c9149c1', name: 'Computer: Desktop Publishing' },
    { id: '4e0b69de-29d5-4882-a969-e5ae8d075442', name: 'Computer: Dreamweaver' },
    { id: 'dfb93484-41e6-4508-8925-21ee738e9720', name: 'Computer: Electronics' },
    { id: '6b215b23-17e1-4486-9172-481bc7045841', name: 'Computer: Fortran' },
    { id: '41aded99-b777-4ae7-ab71-23b321ed73b6', name: 'Computer: GIS' },
    { id: '0d3e8347-3a81-4197-a979-c8ab4856929c', name: 'Computer: Graphic Design' },
    { id: 'ac4a921c-7b51-4d7b-ad65-2f6916a18502', name: 'Computer: HTML' },
    { id: '40d988cb-c7ea-4091-b477-c716efd28368', name: 'Computer: JQuery' },
    { id: '065928a4-5d1f-4706-bf18-da03bc2c8812', name: 'Computer: Java' },
    { id: '0d97a8f7-729c-40b9-9a73-78ee1c9b037a', name: 'Computer: Javascript' },
    { id: '1a22b985-5d49-4a2d-8bcd-346152dc8ad9', name: 'Computer: Linux' },
    { id: '853e2d7c-75dd-4c62-835b-49e3a1ecc726', name: 'Computer: MATLAB' },
    { id: '6148ef8a-3abe-49f6-b456-e850276327b3', name: 'Computer: Mac' },
    { id: '28dd1718-b23f-4ff2-b6af-63378217dbdb', name: 'Computer: Mathematica' },
    { id: 'b2db1264-bb7a-4dbd-a3b3-c5b818d86db2', name: 'Computer: Microsoft Access' },
    { id: 'ebf5d6a7-21ac-4380-aa86-b8c624e7681a', name: 'Computer: Microsoft Excel' },
    { id: 'f29c45be-bf8e-41e4-a17a-7f42b5b4c29a', name: 'Computer: Microsoft Outlook' },
    { id: '4dab36f1-24d5-4e08-b462-e8cf0ea06487', name: 'Computer: Microsoft PowerPoint' },
    { id: 'ad2a394c-28fd-4002-bc85-ccf90be3ca47', name: 'Computer: Microsoft Publisher' },
    { id: '7beea9eb-1c10-4f47-8a04-1e31f91bcfec', name: 'Computer: Microsoft Windows' },
    { id: 'cdfefd84-4317-4588-85d0-f97e45200bb7', name: 'Computer: Microsoft Word' },
    { id: '5781ce14-7f41-40ba-9585-789e083780c4', name: 'Computer: Oracle' },
    { id: '9516ee27-de37-45c2-ad37-1758cd59f890', name: 'Computer: PHP' },
    { id: '51956e4d-545e-421c-b0a9-7eaf0b03f69d', name: 'Computer: Pascal' },
    { id: '6b130504-eea9-4648-8d49-380fd584ceec', name: 'Computer: Perl' },
    { id: '7c190aa9-a239-4887-b64f-c09b4b7db87c', name: 'Computer: Python' },
    { id: '3fe34c96-c333-42a6-b5ce-38b6f49e1678', name: 'Computer: QuickBooks' },
    { id: 'ad799611-e22d-4b2e-a3b8-d5fa0b6b6283', name: 'Computer: R' },
    { id: '405a58d5-d1b7-43bb-a540-d61b05b62075', name: 'Computer: Revit' },
    { id: '8ce4b8ac-ad59-4d3c-a288-b9ded57b1265', name: 'Computer: Ruby' },
    { id: 'a3a6ed02-881b-438c-b1c7-bdafa88b7509', name: 'Computer: SAS' },
    { id: '75bc26d3-edb6-453d-b907-dd9ec442360d', name: 'Computer: SPSS' },
    { id: 'dc4ad0df-4af5-4361-9263-323444628ed3', name: 'Computer: SQL' },
    { id: 'a0c6e116-dd0d-4686-8497-7088e64b06c6', name: 'Computer: STATA' },
    { id: '54994817-41d6-46c6-a6f9-af88322e394d', name: 'Computer: Sketchup' },
    { id: 'b656a1cc-d183-4e9d-87ff-ea52adb96ace', name: 'Computer: Solidworks' },
    { id: '5c98060c-eb7a-456e-9f3d-c8a67201e2ef', name: 'Computer: Swift' },
    { id: '9eea9e7d-a834-4157-8bef-b36003e601db', name: 'Computer: UNIX' },
    { id: '57efe23a-7d10-426d-a755-1c7587b0f4b8', name: 'Computer: Video Production' },
    { id: '41e438ef-7024-4c44-98c5-d9b33f2b80e4', name: 'Computer: Visual Basic' },
    { id: 'fb9e9fc6-783f-4d3e-9769-2d82eae4e999', name: 'Computer: Web Design' },

    { id: 'aa34bb02-f350-462d-88d5-799e7431da7f', name: 'Design' },

    { id: '4e53c7b9-d138-4e62-b90e-ba1e8abdd216', name: 'Early Childhood School Readiness' },

    { id: '1dfada77-e4b6-4c0c-9013-3d39e5e3ba8e', name: 'Earth Science' },

    { id: '4a2e15c7-a11a-4b83-9558-11c737a305a0', name: 'Economics' },

    { id: 'f6f6a5b4-565f-4a5e-8c15-8b05b6e1d0a1', name: 'Elementary: Common Core' },
    { id: '15411bb1-6831-4a3b-8de1-0c53017c1e5b', name: 'Elementary: ELA' },
    { id: 'cc8abd0c-dbee-4a67-842f-e27a7cf85354', name: 'Elementary: ERB' },
    { id: '9c6b7917-dd13-4a1f-ae04-69d597eb453b', name: 'Elementary: Elementary Math' },
    { id: '03b5f6e8-5cb3-11ec-bf63-0242ac130002', name: 'Elementary: Orton Gillingham' },
    { id: '03b5f7ba-5cb3-11ec-bf63-0242ac130002', name: 'Elementary: Remedial Reading' },
    { id: 'c5788b0d-a3ac-4227-82b8-499274a497dd', name: 'Elementary: Elementary Science' },
    { id: '4e23e68c-4e2e-47b1-b4cf-fec765862b6f', name: 'Elementary: Grammar' },
    { id: '3102962f-3917-46ee-b816-baaa1eb6fc10', name: 'Elementary: Handwriting' },
    { id: '6d4646f2-46bd-4895-b452-c217d132e0d7', name: 'Elementary: Homeschool' },
    { id: '2176ea1b-7aad-4fe6-b87f-e7285f31b717', name: 'Elementary: Phonics' },
    { id: 'd8f45354-f4bd-4c5f-8b9b-408ff8bba99f', name: 'Elementary: Reading' },
    { id: '6b9af701-77c2-424a-8c66-748e16772b54', name: 'Elementary: Reading Comprehension' },
    { id: '841fea64-e09b-4afb-8160-e88a86cd55e4', name: 'Elementary: Singapore Math' },
    { id: 'ee6eaac1-e487-43e7-8da4-1fa805c2acbe', name: 'Elementary: Spelling' },
    { id: 'fc7f6085-bdc3-411a-9468-8086220d7dc3', name: 'Elementary: Study Skills & Organization' },
    { id: '00bdc8d1-41a9-4e49-af97-e7a37e5afb5b', name: 'Elementary: Writing' },

    { id: '95c0cd68-1a44-4976-93b6-7705aaf255a0', name: 'Executive Functioning' },

    { id: 'd96a0cf3-aa35-4af7-af06-8b2d8cec6b9a', name: 'Folklore and Mythology' },

    { id: '3a6344d4-8104-451b-9b6e-a589642abaa4', name: 'Genetics' },

    { id: '23848f9c-f713-4097-90fc-6493d992eadd', name: 'Government' },

    { id: '91b50a35-69f7-488f-8529-ce46bc54090c', name: 'Group Science Classes (K-12)' },

    { id: '34680a85-e2d4-4cf9-bd7a-8ed25e66b32d', name: 'History' },
    { id: '1bc2d2b8-caf9-4d44-a4d8-23312ffbf2cb', name: 'History: African American History' },
    { id: '228520b0-5e5e-4ea5-9629-fa45ceb399dd', name: 'History: African History' },
    { id: 'ebb3b987-e20c-4722-94c3-7a2f915a9a6b', name: 'History: American History' },
    { id: 'ba57df02-2d3c-4cd7-85c1-9daebfa715ed', name: 'History: Anthropology' },
    { id: '42223d11-84c6-4097-8b52-b3367d542430', name: 'History: Archaeology' },
    { id: '0e3ebb00-465d-4f08-9eda-fc89e5096572', name: 'History: Art & Architecture' },
    { id: 'ed8d4dc5-00b7-4a62-a977-6409b2095664?', name: 'History: Art History' },
    { id: 'ed79add3-4fe7-46c4-82c8-b69e252afc8c', name: 'History: Civil Rights' },
    { id: '31cfbaa6-6724-4ac7-a810-7ca5710d1bcb', name: 'History: Classical History' },
    { id: '597e8b71-0b1f-4c59-81b8-5bc005e1a3d2', name: 'History: College-Level History' },
    { id: '03b5fa44-5cb3-11ec-bf63-0242ac130002', name: 'History: Religious Studies' },
    { id: 'd19516b0-d3a8-4d2a-b02f-b349c9207020', name: 'History: Criminal Justice' },
    { id: 'b252e194-3cd0-4509-a7b9-daac0f6c8466', name: 'History: East Asian History' },
    { id: '129fdfc3-8a29-460d-bc01-d781feff8ba5', name: 'History: Elementary Social Studies' },
    { id: '3533c496-c044-4151-a4cb-7a5d1a7407cb', name: 'History: European History' },
    { id: 'a9573bcc-470b-43e8-871f-3585c7612753', name: 'History: Geography' },
    { id: '03a38262-e73c-438d-ae22-df57e92a3db9', name: 'History: Government and Politics' },
    { id: '3d8fd3bb-9c43-4c64-800f-84c306e58151', name: 'History: High School History' },
    { id: 'a18c08c0-7fb5-49d1-99fe-984e7882b5c1', name: 'History: History of Science and Technology' },
    { id: '15f8c8e6-a483-4108-b4c5-4d502714ab5c', name: 'History: Middle Eastern History' },
    { id: 'cf555010-4bed-43d4-a1f8-095ccf57e137', name: 'History: Middle School Social Studies' },
    { id: '5c8fae8a-7cc2-405c-9676-9bd512f4351d', name: 'History: Music History' },
    { id: '27bed7ac-36ce-4654-b293-3cbfb6a7c796', name: 'History: Political Science' },
    { id: '1626dc39-b91c-4b8c-952e-3aea70c66aca', name: 'History: Religion' },
    { id: '80139bfe-d3b1-423f-9fc1-74decfa61638', name: 'History: U.S. History' },
    { id: '4da9c709-34f2-40cb-afaa-0786a8e4d9ea', name: 'History: World History' },

    { id: '470a0d9d-8bc5-4a65-afc1-254e7da873ec', name: 'IB Individuals and Societies' },

    { id: '1a039978-8846-4e34-aee3-d77fd66bfcfd', name: 'IB Language Acquisition' },

    { id: 'acde8fe5-6d05-4f98-a603-53901ab3252d', name: 'IB: Math Analysis and Approaches HL' },
    { id: '49e24d25-d5ca-493c-ab6a-232d965dc753', name: 'IB: Math Analysis and Approaches SL' },
    { id: '23dd6f2f-d40a-4d20-9a96-73cee1043a39', name: 'IB: Applications and Interpretation HL' },
    { id: '0abc13a1-e3dc-4419-ace9-20d19d9b6cd1', name: 'IB: Applications and Interpretation SL' },
    { id: '95d59ccb-b4e6-4489-9565-706c3c8e29ce', name: 'IB: Biology' },
    { id: 'bd303edf-ef3e-4e27-b3e4-c83edb4a74a2', name: 'IB: Business Management' },
    { id: 'ce6ab376-8f6e-41e0-9c81-b1819193519a', name: 'IB: Chemistry' },
    { id: '7908f04e-1f47-4658-bb0c-abfe388c29ea', name: 'IB: Computer Science' },
    { id: '2aecefb9-9560-45f6-b148-221d84538c7d', name: 'IB: Dance' },
    { id: '080929da-f62c-4918-8d18-fe578f504ee6', name: 'IB: Design Technology' },
    { id: '97e3892c-53c7-40a4-99cb-089a7e349975', name: 'IB: Economics' },
    { id: 'b50730be-a281-4deb-bd21-23b7c5eadbee', name: 'IB: Environmental Systems and Societies' },
    { id: '5ab31c0a-ab4f-47eb-9eb2-736767fb5934', name: 'IB: Film' },
    { id: '12d2b93c-0dc6-487c-bcbb-03a51e340258', name: 'IB: French ab initio' },
    { id: '78b1c8d4-7d63-41bd-b472-7bef2c850594', name: 'IB: Geography' },
    { id: '92ee105c-bb9e-47e0-916b-5e57a87f9777', name: 'IB: Global Politics' },
    { id: '41f5b36f-dbcd-481c-b855-7620d8c5c39c', name: 'IB: History' },
    { id: '56937502-6828-4284-9489-0316d8faa2cd', name: 'IB: Information Technology in a Global Society' },
    { id: '92acc8b2-77bd-4a1d-b7fd-8f729376abc3', name: 'IB: Language A ' },
    { id: '496317ca-2472-4da7-bd7e-3308e5ac2d83', name: 'IB: Latin or Classical Greek' },
    { id: '957f50fe-a990-4718-b77b-6edcc869dedc', name: 'IB: Literature and Performance' },
    { id: 'a86e6855-f2ed-4223-9915-10bb512da0b9', name: 'IB: Mandarin ab initio' },
    { id: 'e192f745-577f-4408-811c-f7b5e353c79f', name: 'IB: Music' },
    { id: '3581bb5e-e123-42c3-a080-4c53b667a360', name: 'IB: Philosophy' },
    { id: '3504ddcc-7be8-4b3e-90f0-e1609643ef3f', name: 'IB: Physics' },
    { id: 'dea625e5-38d4-4cc0-97b4-b3e3f68aa6c0', name: 'IB: Psychology' },
    { id: '2dba288f-c1f9-4b83-a4c6-e565aeb0ae0e', name: 'IB: Social and Cultural Anthropology' },
    { id: 'a4609379-4df5-4a15-8851-50b7b4c14d98', name: 'IB: Spanish B SL' },
    { id: '049e21ce-1ed9-433b-92df-d8a14afeb53e', name: 'IB: Spanish ab initio' },
    { id: 'f897c0e0-5df5-4f59-8ef8-0cf403bf53db', name: 'IB: Sports, Exercise and Health Science' },
    { id: 'a4dbc8c0-f676-4d21-bf7f-593af933d5de', name: 'IB: Theatre' },
    { id: '60bea839-7346-4aa3-a703-9fa6ddf41617', name: 'IB: Visual Art' },
    { id: '153424c9-9fb2-4879-8aa5-3048589a4018', name: 'IB: World Religions' },

    { id: 'ee51d8e4-fc76-4db1-9ec9-a628937e886d', name: 'International Relations' },

    { id: '911c62ae-d5d9-425a-b709-3322d9eb6c4a', name: 'International Studies' },

    { id: '5e6cf5c6-60bb-46e2-967e-7bac26c7d7fa', name: 'Kindergarten Readiness' },

    { id: '7dddedf2-7f02-4962-a77d-141d22ec0270', name: 'Language Arts' },

    { id: 'd1914d21-1ea4-49fa-ae8d-f1336940aed3', name: 'Language: American Sign Language' },
    { id: '8000541b-5f5b-4e82-9549-dcbe717b2c31', name: 'Language: Arabic' },
    { id: '6e0e5aa2-8f46-4042-b476-9e7520ce4b0a', name: 'Language: Braille' },
    { id: '1de8cd58-0d69-418b-99e9-11f5dbdad6d6', name: 'Language: Bulgarian' },
    { id: '3e70e8a4-923c-4928-aff5-99955b55bb80', name: 'Language: Cantonese' },
    { id: '364b9478-a1ca-4167-b8de-5695f9fecad8', name: 'Language: Chinese' },
    { id: '34248b47-2d3e-4bb9-931c-d093dae96a54', name: 'Language: Classical Greek' },
    { id: '39ce504b-afc9-4229-aa5e-ee6684c2ec11', name: 'Language: Czech' },
    { id: '0c4621fe-77df-4ed0-85f7-1e779530f0b4', name: 'Language: Dutch' },
    { id: 'be6abc25-c40a-4336-b4c7-3474920e24c5', name: 'Language: ESL/ESOL' },
    { id: '5a483fa5-e05e-46df-ac08-59e03e2b6214', name: 'Language: English' },
    { id: 'e86513f1-3462-4c12-952b-26f471ebb584', name: 'Language: Farsi' },
    { id: 'ef56d9d9-26c7-4fc6-981c-2f84356cc073', name: 'Language: French' },
    { id: '1a059c12-ec29-4204-842e-0f4cd41826a1', name: 'Language: German' },
    { id: 'a95561d6-b17e-4e9e-b266-d0cea8ae584b', name: 'Language: Greek' },
    { id: '07bfd73b-92d7-43f9-9e82-5c554562dcd1', name: 'Language: Hebrew' },
    { id: 'f5d65212-24c8-43ae-afeb-2618be6abcb2', name: 'Language: Hindi' },
    { id: '08cbb316-57b7-4272-918d-c70043f987fe', name: 'Language: Hungarian' },
    { id: 'd019b9e6-4089-4a36-8532-32e7c13b3775', name: 'Language: Indonesian' },
    { id: '62fa7d46-3cd4-442f-a085-f63355d5a72d', name: 'Language: Italian' },
    { id: '702936bd-9dd4-4ebf-8091-bd9a38cebb39', name: 'Language: Japanese' },
    { id: '4bc84d7c-b748-4ca8-81a5-c1401f076867', name: 'Language: Korean' },
    { id: 'd9c4f60e-5c30-48a5-8256-56207477ca95', name: 'Language: Latin' },
    { id: 'f75e6e1f-e770-480a-9646-06dfa45dc865', name: 'Language: Polish' },
    { id: 'bb36360f-e572-4bf9-ba8e-c0a752099820', name: 'Language: Portuguese' },
    { id: 'fedc1e77-ca0a-4b10-848d-afe8ba3c1409', name: 'Language: Romanian' },
    { id: 'f01065e1-7886-48cc-a2da-259e451018be', name: 'Language: Russian' },
    { id: '9116f63b-59ef-4b60-8c45-970adbb28256', name: 'Language: Spanish' },
    { id: 'ecf22de5-7227-4844-a21e-74a03fe98696', name: 'Language: Thai' },
    { id: '91df8c78-3a5f-4f71-adea-278f32defa58', name: 'Language: Turkish' },
    { id: 'b0d85e85-afda-4bed-80f1-f49095403dd0', name: 'Language: Urdu' },
    { id: 'a34ccb0b-aefe-4550-91fc-c16a2879cf37', name: 'Language: Vietnamese' },

    { id: '6dffcf1e-6c67-4853-b2df-82a31b416bb2', name: 'Law/ Legal Studies' },

    { id: '628180d2-4eee-48e4-bb09-d1cebdd36363', name: 'Linguistics' },

    { id: '6375e140-cd2a-486a-b899-08104321d23e', name: 'Math' },
    { id: 'bfe6a8c1-0aeb-4aa9-8925-7b72df787115', name: 'Math (K-8)' },
    { id: 'efa782e1-14db-425a-b5ba-0660c7c887b6', name: 'Math (PreK-6)' },
    { id: 'c2de1bb2-f863-4e9b-affc-2e86a125ef62', name: 'Math (PreK-9)' },
    { id: 'a91a6ef1-8296-4e35-8c95-f184cb1edc2a', name: 'Math: Accounting' },
    { id: '5b90a19a-c847-4581-b583-43c0c5486278', name: 'Math: Actuarial Science' },
    { id: 'd3153259-23e9-40ac-86dc-0acf1a9db496', name: 'Math: Algebra' },
    { id: 'b5935985-76e9-4234-bd79-6b76f10f2d8c', name: 'Math: Algebra 1' },
    { id: '6dc098fa-5487-44bf-9ec9-8b946e76c3b7', name: 'Math: Algebra 2' },
    { id: 'c613dfd4-2454-47be-ac6b-f76853d3b80c', name: 'Math: Applied Mathematics' },
    { id: 'aafa713b-e1bf-41cb-8b08-cc609bc98d47', name: 'Math: Business Math' },
    { id: '1aa4f670-9ffe-4ed5-81e5-e4868e4f5990', name: 'Math: Calculus' },
    { id: '1e5e5120-b216-485f-ba7b-db6d2db750f8', name: 'Math: College-Level Math' },
    { id: '5330ab92-a0f3-4a51-8964-258677a14c37', name: 'Math: Common Core' },
    { id: 'aee3b9a6-f524-4a9b-923e-45fad5c2d698', name: 'Math: Differential Equations' },
    { id: 'b39f2038-f69d-4be6-8bae-bd8f596315c1', name: 'Math: Discrete Math' },
    { id: '48f6c88a-76e6-49bd-888b-13483daaac7a', name: 'Math: Econometrics' },
    { id: 'ac601292-0f92-4061-9b6e-767f445e010f', name: 'Math: Elementary Math' },
    { id: 'a065addf-9eea-48e9-95fe-693c7712c729', name: 'Math: Finance' },
    { id: 'a427a3c3-367a-4b03-bdea-f375ff0218e5', name: 'Math: Finite Math' },
    { id: '212e9890-1279-4379-a581-08cdf197a78c', name: 'Math: Geometry' },
    { id: '75c4d299-bcf0-472f-9a50-4cd3b5a9a053', name: 'Math: High School Math' },
    { id: 'c591d19b-a968-4ea3-b7a0-ff0376dc9aa5', name: 'Math: IB Math HL' },
    { id: '7ee84610-efaa-4bf7-9d45-f798564a2400', name: 'Math: IB Math Sl' },
    { id: '6679b6b9-dac8-4ae0-96f9-619f6fa1aa0e', name: 'Math: Linear Algebra' },
    { id: 'f4718f9e-37d8-4966-ab5f-b31f33019da1', name: 'Math: Logic' },
    { id: '8a972e33-396b-4b4e-90a1-ab6db0f9b409', name: 'Math: Macroeconomics' },
    { id: '18d6a867-3f4a-4308-b5cf-4c9bc5d105cb', name: 'Math: Managerial Accounting' },
    { id: '5e87ceca-3af0-426f-8aa7-292aec9cf307', name: 'Math: Microeconomics' },
    { id: '14c7bc83-c284-4d24-96ef-b62590df75f0', name: 'Math: Microsoft Excel' },
    { id: '9b2a4c5c-7be4-40e7-b21f-b39ba60ca116', name: 'Math: Middle School Math' },
    { id: '33d98e31-8460-454b-b684-1d42607bf049', name: 'Math: ODEs' },
    { id: 'ee43c008-3027-4b75-a0e8-f08c279ebf13', name: 'Math: PDEs' },
    { id: '261353cc-7f9f-483d-bf5d-76d3cf1a1a40', name: 'Math: Pre-Algebra' },
    { id: '0879a47f-c5fd-4b00-9e47-6636f7f92371', name: 'Math: Pre-Calculus' },
    { id: 'cdc4e7ac-e9d0-41b7-a245-c9015faa1a9d', name: 'Math: Probability' },
    { id: '2ad695e6-dba9-4a56-9789-b8fba85ab922', name: 'Math: Statistics' },
    { id: '24794701-c9f6-4e37-bb19-145fa950fd9a', name: 'Math: Trigonometry' },

    { id: '68ab7c37-0fff-481f-9b96-f09533f07113', name: 'Medicine' },

    { id: '3aaec21c-bd95-494f-8c62-526fa4f17872', name: 'Museum Studies' },

    { id: '6364937a-d884-4c57-a6c0-f80cd7c2072e', name: 'Music: Cello' },
    { id: '05f681a9-c108-4e70-a963-3c02c73ad2cd', name: 'Music: Clarinet' },
    { id: 'eab1085b-0444-4a94-a209-3b6c19d3013a', name: 'Music: Trumpet' },
    { id: '110e29f9-526c-46e1-be62-40af4413353f', name: 'Music: Drums' },
    { id: '9ec3872c-08df-480f-8ff2-14d347bea83f', name: 'Music: Flute' },
    { id: '7b66572a-53a2-4b3f-be01-9a4b5631d203', name: 'Music: Guitar' },
    { id: 'e70c18fe-bde6-42bf-bb0c-84c401a8564b', name: 'Music: Keyboard' },
    { id: '4a277dc5-0841-4815-b20a-19d9a2da406e', name: 'Music: Jazz' },
    { id: '517c01a8-366d-4b84-8015-532691d7b1e8', name: 'Music: Musical Theater' },
    { id: 'dfe58cee-c68d-4ae9-89f2-2948a5bc0ac8', name: 'Music: Music Theory' },
    { id: '325230d7-96fa-4e74-a430-748efff23268', name: 'Music: Opera Singing' },
    { id: 'd34e7861-d8f9-48c4-ac98-a955d33fbca3', name: 'Music: Piano' },
    { id: 'ace22df3-7932-4b73-b345-6052c8716174', name: 'Music: Saxophone' },
    { id: 'de85ee16-76d7-4450-a3a6-b42cfabb3eda', name: 'Music: Singing' },
    { id: 'bed831f2-d430-48db-a086-99ee1e667832', name: 'Music: Songwriting' },
    { id: '8d6aa4b5-204a-4dbd-8c6a-0d3b11761acd', name: 'Music: Tambourine' },
    { id: '17263adf-6ca2-439f-b132-a6aff1c5598f', name: 'Music: Tuba' },
    { id: '6d1f99ec-ecc4-4fd0-8ead-da3b8425f67d', name: 'Music: Trombone' },
    { id: '498f03fc-bd08-42ee-bd48-887580d49480', name: 'Music: Ukulele' },
    { id: '5ebb6e80-8d5b-47f6-b834-85cb176086e9', name: 'Music: Violin' },

    { id: '1d7a817e-59e9-421d-86f8-96944f856d5a', name: 'Philosophy' },

    { id: '2bca7f13-537c-4c40-b1d6-d7e936df68c1', name: 'Psychology' },

    { id: '25d21307-f3c9-4082-922c-e78a8d1b8b50', name: 'Public Affairs' },

    { id: 'bc0bd32b-e843-4aeb-9013-c15dafaf6032', name: 'Public Health' },

    { id: '87337289-d65b-49d5-a028-73d0eb8447b1', name: 'Public Policy' },

    { id: '035b320b-5804-41db-9f09-1e95812e4c5c', name: 'Real Estate' },

    { id: '4f56275e-b796-4d3e-8441-16548dafc07f', name: 'Research Support' },

    { id: 'fff32654-de74-440c-b487-d16249c54206', name: 'Science: Agricultural Science' },

    { id: 'a3b59379-c6b5-4edf-86b5-3490d2089e4a', name: 'Science: Anatomy' },

    { id: 'fdeb66c4-d899-43d2-b946-2ea803fb5d98', name: 'Science: Astrophysics' },

    { id: '9dcba7d5-f3d0-45de-9fac-835f95f8859f', name: 'Science: Atmospheric and Oceanic Sciences' },

    { id: 'a0bf60d5-aac1-4dec-b3fa-805c33119b34', name: 'Science: Biomedical Science' },

    { id: '9b46e3da-9bb9-4c03-b24e-ae763fdde03b', name: 'Science: Biostatistics' },

    { id: '73918f0b-71c5-4622-b78e-770428e24612', name: 'Science: Botany' },

    { id: 'a084aade-632a-454f-a0c3-b8f574ee2e98', name: 'Science: Chemical Engineering' },

    { id: '72f154e3-ba17-4b19-9f1e-c426c8b03c6e', name: 'Science: Civil Engineering' },

    { id: '2fb37bdd-bf88-45da-a1e9-9b2f69fe330e', name: 'Science: Cognitive Science' },

    { id: 'c197d6f2-f480-4ae6-9d5f-dae3b3a70909', name: 'Science: College-Level Science' },

    { id: '60427631-7c9c-43e2-b235-0337d6ca5f83', name: 'Science: Data Science' },

    { id: '56fe33f9-4c57-421c-b8e4-10aba69c2638', name: 'Science: Ecology' },

    { id: 'd516e05d-fda2-466d-825f-e1d5fd2fbae0', name: 'Science: Electrical Engineering' },

    { id: 'e59a337b-c0be-43e8-918b-7d5461ab694b', name: 'Science: Environmental Science' },

    { id: '771f52c8-85c3-4a79-b619-8cbe5e2149a1', name: 'Science: Epidemiology' },

    { id: 'e7d102b6-1994-4a9e-8049-16b6f2972bb2', name: 'Science: Forensic Science' },

    { id: 'f6d58219-f0ca-4479-86e3-866e3d2236a7', name: 'Science: Geology' },

    { id: 'c60e6d07-4719-4b7f-9fb6-57a023bdc4e9', name: 'Science: Health and Medicine' },

    { id: 'cc32f607-55c2-4b93-9e2d-2d6c8bea1f9c', name: 'Science: High School Science' },

    { id: 'b6fc0136-f660-4c15-b9d1-e14dacef1db2', name: 'Science: Kinesiology' },

    { id: 'f31f72d0-220f-4462-be59-dcbcc57dea3c', name: 'Science: Mechanical Engineering' },

    { id: '52d560dc-1e90-40ac-8618-20a1cc374a85', name: 'Science: Medical Coding' },

    { id: 'b8c31b9c-ae06-4510-8456-1ebeb99a9046', name: 'Science: Microbiology' },

    { id: 'b2711c43-76d5-4ec9-a5ef-3373b3c2eb61', name: 'Science: Middle School Science' },

    { id: 'b8f0fb4e-379e-4efb-ada2-c2388a9bd93e', name: 'Science: Molecular and Cellular Biology' },

    { id: '45507569-e863-4459-8c2a-71cec6e86e9a', name: 'Science: Neuroscience' },

    { id: '105c01de-a21a-4551-a76d-1fab6392c4b0', name: 'Science: Nursing' },

    { id: '2c828570-0b61-4b26-b6dd-d88f13f94ef4', name: 'Science: Nutrition' },

    { id: '8c7e2122-1d51-47d1-b4b7-dea1de8dd8fd', name: 'Science: Organic Chemistry' },

    { id: 'b986763f-1305-4d57-a918-3011629ab905', name: 'Science: Pharmacology' },

    { id: '1da63cff-f308-4844-90c7-197c295c5537', name: 'Science: Physical Science' },

    { id: '17326d9e-0cf4-422f-b60b-65fc5e698e30', name: 'Science: Physics' },

    { id: '67c9f0cc-8d4e-415a-824d-068d1020889a', name: 'Science: Physiology' },

    { id: '532ffa08-b222-4b81-b46c-f4afecfb2c7a', name: 'Science: Zoology' },

    { id: '7069c1f7-fc11-44f6-97ff-d904bcc615b4', name: 'Social Work' },

    { id: '27bea1a9-afea-4298-83cc-fd564e70aa8b', name: 'Sociology' },

    { id: '03b5fb20-5cb3-11ec-bf63-0242ac130002', name: 'Special Needs: ADD' },
    { id: '2adf8230-c955-4e25-bd1b-fa0051f77795', name: 'Special Needs: ADHD' },
    { id: '1aef8bc5-eb17-416e-b508-5ebb319555b2', name: 'Special Needs: Autism Spectrum Disorder (ASD)' },
    { id: 'a9821191-f600-4683-aecc-b336d0e66bd0', name: 'Special Needs: Dyslexia' },
    { id: 'ffc5199f-b3a7-4a31-955a-9e59b2269987', name: 'Special Needs: Hearing Impaired' },
    { id: '9809d112-c060-47af-86ca-08bc7d608474', name: 'Special Needs: Homeschool Support' },
    { id: '54e81aeb-1c89-494d-8ec6-df284d1e16e2', name: 'Special Needs: Organization & Team Management' },
    { id: 'bde91e5e-1606-4873-86ab-d4af8b7abf4f', name: 'Special Needs: Sign Language' },

    { id: 'f8c7786d-b66e-4e49-a372-c92b101f69f5', name: 'Women and Gender Studies' },

    { id: '03b5dee2-5cb3-11ec-bf63-0242ac130002', name: 'English: Middle School ELA' },
    { id: '03b5e298-5cb3-11ec-bf63-0242ac130002', name: 'English: High School ELA' },

    { id: '03b5e388-5cb3-11ec-bf63-0242ac130002', name: 'College-Level English' },

    { id: '03b5e46e-5cb3-11ec-bf63-0242ac130002', name: 'English: African American Literature' },
    { id: '03b5e540-5cb3-11ec-bf63-0242ac130002', name: 'English: College Essays' },
    { id: '03b5e612-5cb3-11ec-bf63-0242ac130002', name: 'English: Comparative Literature' },
    { id: '03b5e6e4-5cb3-11ec-bf63-0242ac130002', name: 'English: Essay Writing' },
    { id: '03b5e7ac-5cb3-11ec-bf63-0242ac130002', name: 'English: Creative Writing' },
    { id: '03b5e888-5cb3-11ec-bf63-0242ac130002', name: 'English: Grammar' },
    { id: '03b5eb80-5cb3-11ec-bf63-0242ac130002', name: 'English: Handwriting' },
    { id: '03b5ec5c-5cb3-11ec-bf63-0242ac130002', name: 'English: Journalism' },
    { id: '03b5ed2e-5cb3-11ec-bf63-0242ac130002', name: 'English: Literature' },
    { id: '03b5ee00-5cb3-11ec-bf63-0242ac130002', name: 'English: Proofreading' },
    { id: '03b5eed2-5cb3-11ec-bf63-0242ac130002', name: 'English: Phonics' },
    { id: '03b5efa4-5cb3-11ec-bf63-0242ac130002', name: 'English: Public Speaking' },
    { id: '03b5f06c-5cb3-11ec-bf63-0242ac130002', name: 'English: Reading' },
    { id: '03b5f38c-5cb3-11ec-bf63-0242ac130002', name: 'English: Writing' },
    { id: '03b5f47c-5cb3-11ec-bf63-0242ac130002', name: 'English: Shakespeare' },
    { id: '03b5f54e-5cb3-11ec-bf63-0242ac130002', name: 'English: Speechwriting' },
    { id: '03b5f620-5cb3-11ec-bf63-0242ac130002', name: 'English: Vocabulary' },

    { id: '62acab18-84c4-11ec-a8a3-0242ac120002', name: 'Robotics' }
];

const counsellingList = [
    { id: '4b0d2420-5a52-4d1a-a028-d2944664aed1', name: 'College Counseling ' },

    { id: '21d32b2b-40dc-4e2f-a8dd-666548fd57a4', name: 'Admissions: Boarding School' },
    { id: '4e95c7d7-6552-43dc-a328-62a7f14e27fb', name: 'Admissions: Elementary School' },
    { id: '07ca74cd-8a0c-4ae9-8ed1-af9ed9a3a6dd', name: 'Admissions: Gifted & Talented Programs' },
    { id: '944b3ba0-fa6f-42c5-91c6-158e0ac88494', name: 'Admissions: Gifted & Talented Programs - Elementary Schools' },
    { id: 'aed66333-c0b4-4449-9728-0a8a7a3d0ddb', name: 'Admissions: Gifted & Talented Programs - Middle Schools' },
    { id: '6d913b99-efd8-4d38-83c3-0380ad082670', name: 'Admissions: Gifted & Talented Programs - High Schools' },
    { id: '88f0449f-7a6b-4377-ac32-17d4899fc603', name: 'Admissions: High School' },
    { id: 'b2f2d714-ff50-4fda-9a20-5f1f6c1b4ec1', name: 'Admissions: Homeschool' },
    { id: '2c78b5aa-0d93-4f6c-8093-e092fe1cb180', name: 'Admissions: International Boarding Schools' },
    { id: 'feeb0231-bf06-42a6-a8da-1cdf37a97e9a', name: 'Admissions: Middle School' },
    { id: 'e37adc74-9b44-41cb-b4b7-a27ed1c39a98', name: 'Admissions: Nursery School' },
    { id: '8eb843ed-ce1a-4e95-9ae0-fe1e0cca41ac', name: 'Admissions: Pre-K/Kindergarten' },
    { id: 'dbba3ad0-4121-485c-ab20-77262cb239a3', name: 'Admissions: Private School' },
    { id: '6f29d50b-5d40-487c-ae39-449c5db8e2b3', name: 'Admissions: Public School' },
    { id: 'c761c8da-146b-493f-9948-19ef71cfd3df', name: 'Admissions: Therapeutic Boarding Schools' },

    { id: '336f9d21-aee0-48b3-b913-ca56fd5477b6', name: 'Applying to UK Schools' },

    { id: '2c23c1dc-1fc1-4663-b934-d61859a762b0', name: 'Art Portfolio Review' },

    { id: 'ac8786e3-6f0b-4b4f-aee8-05058516b0ba', name: 'Athletic Recruiting' },

    { id: 'a4dea9c9-a17a-4f9d-b28c-a6b13b82ebb3', name: 'Business School' },

    { id: 'b121cde4-0feb-40de-b3ac-ee8a561a5812', name: 'Business School (MBA)' },

    { id: 'f600481b-22ef-4633-b4ff-d5c247d6f091', name: 'Business 2+2' },

    { id: '3edc3287-24a4-4677-bea8-4e422caa95b2', name: 'Career Advising' },

    { id: '1a09d4ea-f773-470b-9883-6f713c6d2058', name: 'Career Counseling' },

    { id: 'e554399a-7beb-4307-9911-4019c4f36436', name: 'Dental School (DDS)' },

    { id: '12995a51-c370-4460-bce8-8cd305b52e3e', name: 'Executive Functioning Coaching' },

    { id: '174027db-a337-460e-96b4-ab549f55ae31', name: 'Extracurricular Advising' },

    { id: 'c6877808-585c-48e2-baf1-2bba14059b5f', name: 'Financial Aid/Scholarships' },

    { id: '9336505e-17c5-4cb0-91f7-ae3db61274e3', name: 'Fine Arts & Design ' },

    { id: 'a00a2697-e314-452f-b37e-78dd21f9ad9f', name: 'Flex-Med' },

    { id: '8ad4bdb5-992b-46ec-a80b-62fe3768db95', name: 'Gap Year' },

    { id: '98e01697-6e10-4d2b-bdab-40290fe280c4', name: 'HBCUs' },

    { id: '9b63b83e-b389-4cc5-bdd5-2cfd4eb80231', name: 'Homeschooled Applicants' },

    { id: 'fc81c03b-8542-4fb3-9b41-f3c0a215f9ac', name: 'International Admissions Counseling' },

    { id: 'c7f31fb4-6573-46c8-a808-67f8d2d60f8f', name: 'International Applicants' },

    { id: '9af3132a-b3cf-48b9-9182-4d36a0a10754', name: 'International Students' },

    { id: 'd124ba69-e9e7-40a7-a42f-f11aa95edd61', name: 'Internship Counseling' },

    { id: '68588cc0-87cf-4f6f-817b-07d80b3bd00f', name: 'Interview Preparation' },

    { id: 'ff419f3e-0cad-4e6a-915d-5ab62f0c676d', name: 'Ivy League ' },

    { id: '31b8af69-9ad1-4ba1-b85c-6050db5710c8', name: 'Joint Degree Programs' },

    { id: 'e15004d2-340b-47d8-a6df-d7c10df26580', name: 'Journalism (MA)' },

    { id: 'de372663-e831-42a1-9e2e-95831b388594', name: 'Law School' },

    { id: '212d793f-a4f1-4971-954b-add466b8c62f', name: 'Law School (JD)' },

    { id: '3d78eb56-7609-4366-a90a-6f2ea8ec7cd7', name: 'Learning Differences' },

    { id: '3183c2f6-fabb-4382-9307-99ed1547bb72', name: 'Liberal Arts Colleges' },

    { id: 'db551536-e15d-4e9e-a87c-41ba08bc8024', name: 'Masters Programs' },

    { id: '4ef494ac-3c11-4f3d-9cbe-ff8296535715', name: 'Master of Arts' },
    { id: '9ade822e-178b-4879-bf80-959a441e722e', name: 'Master of Fine Arts' },
    { id: 'e2d3c09c-cbd8-4936-bd97-b87c36cbde07', name: 'Master of Engineering' },
    { id: '8ecbac8b-3ad2-4895-b7fc-96f70af92940', name: 'Master of Education' },
    { id: 'e4ef8aff-4363-4213-95db-6e83ce7edc80', name: 'Master of Science' },

    { id: '92f88b80-4aa2-476f-a7a0-c2dece262db0', name: 'Medical Post-Baccalaureate' },

    { id: '16e8540e-2d63-4e97-9c10-ba92b9ce2d0e', name: 'Medical School' },

    { id: 'bcb3a1c1-6fb8-45e0-b41a-1088708c0181', name: 'Military Academies' },

    { id: 'c911c107-97c8-4584-8ae1-f207736577d1', name: 'MSW' },

    { id: '19b8adea-dd2c-4b1f-811c-69ab79a5a4df', name: 'M. Phil' },

    { id: '7ed61def-aeca-43dd-a929-0104783c81a3', name: 'M. Litt' },

    { id: '31829f35-77da-4314-90ee-21d1238bd1d5', name: 'Nursing School' },

    { id: 'a26984e9-5cde-4f5a-9eb4-2deffe4ad20b', name: 'Online College Degrees' },

    { id: 'd09deb98-cbc2-4afd-9dff-fc8105a0b97f', name: 'Performing Arts ' },

    { id: '6f370705-0ede-4918-a569-7dc34f0726a2', name: 'Performing and Visual Arts' },

    { id: '8eb8ce82-2328-4daf-aca6-89373354b734', name: 'Personal Statement/College Essay' },

    { id: 'ea3c550d-896f-4f98-abba-6ecfa34bbc7e', name: 'Ph.D. Programs' },

    { id: '67498441-d7e5-4bbd-b305-acb83ca0416e', name: 'Psychology (PsyD,PhD)' },

    { id: '699b8344-82d9-4c84-8a87-abb8e2c5db3f', name: 'Public Policy (MPP)' },

    { id: '1f5c66b2-15ff-4086-b163-9293ca41db98', name: 'Public Speaking Coaching' },

    { id: '400cf5a3-0f43-473b-9616-fc4d39d74b82', name: 'Research Universities' },

    { id: 'd953c12b-af0c-4e2e-9500-fdd9362da5fd', name: 'Resume Coaching' },

    { id: '3ea828df-e18d-4c28-9c2c-fa04aade2f49', name: 'Special Needs Students' },

    { id: 'b0b09611-1df7-4855-a88d-06b8dcd292fb', name: 'Standardized Test Advising' },

    { id: 'ed562908-5b1c-4eac-a2c4-8a0d7fa2edd5', name: 'Summer Programs' },
    { id: 'b3ea48a5-127c-4146-b03a-92a78de012d5', name: 'Summer Programs: Performing Arts' },
    { id: '6b92ca76-c68b-4ebd-beb0-928aa0a7b06b', name: 'Summer Programs: Fine Arts' },
    { id: '86094544-3a6d-4bbf-98dd-9fb931e7a8f1', name: 'Summer Programs: Leadership' },
    { id: 'b2e544f7-5874-4ecf-beb1-608b29275864', name: 'Summer Programs: Academic' },
    { id: '6496e178-39b2-4a7a-b6fa-4e778b9b92ce', name: 'Summer Programs: Community Service' },

    { id: '5de20ac2-b23b-44bf-87f0-b377a70e669d', name: 'Therapeutic Boarding Schools' },

    { id: 'e8bcb9e3-937e-45f3-84d1-7cf0b4f3e32f', name: 'Transfer Student' },

    { id: '8d4e2f2f-32c9-41d7-b176-13beec2d27a8', name: 'Transfer Students' },

    { id: '0a37d497-d241-4edf-88d6-a98886b7d0a9', name: 'Undergraduate Business' },

    { id: '5a1f1ce5-7e95-4cb8-8aee-43eea3224e4a', name: 'Undergraduate Engineering' },

    { id: '3c00ea7d-7717-40ab-ac1b-19267108f5ea', name: 'Undergraduate Medical' },

    { id: '437c5c36-3987-4f1b-a491-578928532355', name: 'Undocumented Students' },

    { id: 'fb8bca28-5054-4b51-a385-df817cf60f92', name: 'Veterinary School' },

    { id: '111618a6-d966-417c-9b21-3c4c9dd3c519', name: 'Vocational School' },

    { id: 'b2ec3d2f-84d8-4929-b3ce-4d8222bc22c8', name: 'Women’s Colleges' },

    { id: '180cac69-7afa-4a14-a895-32b898e57d86', name: 'Writing Portfolio' },
];

const standardizedTestList = [
    { id: '89c8814a-5cd3-11ec-bf63-0242ac130002', name: 'DAT' },
    { id: '89c8838e-5cd3-11ec-bf63-0242ac130002', name: 'DAT: Survey of Natural Sciences' },
    { id: '89c884a6-5cd3-11ec-bf63-0242ac130002', name: 'DAT: Perceptual Ability Test' },
    { id: '89c887d0-5cd3-11ec-bf63-0242ac130002', name: 'DAT: Reading Comprehension' },
    { id: '89c888d4-5cd3-11ec-bf63-0242ac130002', name: 'DAT: Quantitative Reasoning ' },

    { id: 'ec946bc6-6c4b-4df9-8340-c72306a8dac2', name: 'AABL' },

    { id: 'a5c7fb93-b335-4595-83b9-a2ef317a7db9', name: 'ACT' },

    { id: 'd5292f21-5e43-49b2-bb33-c96cd58d4d5d', name: 'ACT (Verbal)' },

    { id: '7ac4179d-7ec9-478b-b6f0-5933f4cf4a2c', name: 'AP Art History' },

    { id: '6fe751d5-760d-49a4-b732-a58928befcd8', name: 'AP Biology' },

    { id: '048a7f3d-ec18-4586-98a6-564952e4593d', name: 'AP Calculus' },

    { id: 'f5e34a2c-00ed-4860-8596-1f1db659cb62', name: 'AP Calculus AB' },

    { id: '58bddc06-03a7-48b3-bd8b-946f92e23c76', name: 'AP Calculus BC' },

    { id: 'd24d2eee-08a8-46b8-a759-5e023d04469c', name: 'AP Chemistry' },

    { id: '5682e0db-4454-42e6-9d07-443feae9c2d6', name: 'AP Chinese Language & Culture' },

    { id: 'f6708e06-2324-492e-a48a-179ca166510f', name: 'AP Comparative Government & Politics' },

    { id: '196c324d-566f-48f8-bcca-7edf0b46f103', name: 'AP Computer Science A' },

    { id: '89e86abd-ab00-4138-b428-8f7e0c87e5e8', name: 'AP Computer Science Principles' },

    { id: '0fc89969-55dc-42d3-81d1-be895a0243a0', name: 'AP English' },

    { id: 'b5dd6a19-82e2-44f3-9285-e2515c6e6062', name: 'AP English Language and Composition' },

    { id: '6baa6f7f-2142-4b04-8592-8d63df1fec77', name: 'AP English Literature and Composition' },

    { id: '44616ca6-c79d-45a1-a0a4-7f128c17b0fc', name: 'AP Environmental Science' },

    { id: 'c1f92b44-a9dc-43be-b016-f90e292907cb', name: 'AP European History' },

    { id: 'c7eea89d-c29d-4e6a-af4d-18a1caf272a1', name: 'AP French Language & Culture' },

    { id: 'ce5874eb-a213-4670-b931-e0da8a6ccb43', name: 'AP German Language & Culture' },

    { id: '3b7fea9d-682a-4f72-9a31-38f179f16cf3', name: 'AP Human Geography' },

    { id: 'c1e69986-505f-4a8a-aed4-5cb366a3dc19', name: 'AP Italian Language & Culture' },

    { id: '4d04162e-8a3b-4848-a1ff-42a0ab9e682f', name: 'AP Japanese Language and Culture' },

    { id: 'c4705c3a-0085-448c-9f46-f2fc2d98199d', name: 'AP Latin' },

    { id: 'b7410ff2-acaf-498d-948f-86f6067f689d', name: 'AP Macroeconomics' },

    { id: '53391627-71f5-492a-b13d-b7eb1376f237', name: 'AP Microeconomics' },

    { id: '2808c7f8-ec08-4d4f-9dcd-c9fd9a5c1f02', name: 'AP Music Theory' },

    { id: 'af7691d0-8fb5-47f5-a575-f38382266ec9', name: 'AP Physics 1: Algebra-Based' },

    { id: '893ee031-9283-462b-a58a-15d3912c878f', name: 'AP Physics 2: Algebra-Based' },

    { id: '99acaf36-38e1-4506-bf50-26361dc10d00', name: 'AP Physics C: Electricity & Magnetism' },

    { id: '2bf353ee-73ba-4206-b951-cd901f534945', name: 'AP Physics C: Mechanics' },

    { id: 'ccc4d225-5402-484e-876c-afc1ff7503ea', name: 'AP Psychology' },

    { id: '31bbd482-29ca-43e8-8c9c-37d117439745', name: 'AP Research' },

    { id: '8299554a-fa5b-4633-a061-6e93e6a5d69d', name: 'AP Seminar' },

    { id: '1fb1dc75-d543-4b4a-a30a-814f94d54e37', name: 'AP Spanish' },

    { id: '27b4d427-eb0b-41e0-a15c-0e129913d94c', name: 'AP Spanish Language & Culture' },

    { id: 'b9455968-b1e9-4faf-abde-e6f14992e37e', name: 'AP Spanish Literature & Culture' },

    { id: 'cd7ea51f-c065-4b93-9034-ea2f999c81b3', name: 'AP Statistics' },

    { id: '23ec693a-a4fc-4e7d-bba1-5b52c7628179', name: 'AP Studio Art: 2-D Design' },

    { id: '9161354c-2328-4e69-8259-774c52699d0d', name: 'AP Studio Art: 3-D Design' },

    { id: '6a1f3949-ccaa-40b6-bde3-bc6b30d8f0be', name: 'AP Studio Art: Drawing' },

    { id: 'cf2c36a1-e168-4989-af92-8cc82c80c3ae', name: 'AP U.S. Government & Politics' },

    { id: 'e6ac676d-286a-42fd-a107-030c28f19fdc', name: 'AP U.S. History' },

    { id: '566c86b1-ea2c-4da2-8fcf-e48a5c24559a', name: 'AP World History' },

    { id: 'c1b71d71-6255-4e0e-a73f-ac9b96c63c7c', name: 'ASVAB' },

    { id: 'aeb3af08-9a23-45bb-8587-34cb8f6c97e8', name: 'CTP' },

    { id: '3b9ea963-5fb8-43a9-85ba-d01d372d0674', name: 'ECAA' },

    { id: 'cf2d450b-b4ac-4575-8fbc-c8621a52b003', name: 'ELAT' },

    { id: 'c22400f6-f2d7-420b-8fc1-c7930df53d84', name: 'ERB' },

    { id: '39720a1e-b154-45b6-aaad-c7ef252dfa20', name: 'GMAT' },

    { id: '8c62728f-dd4c-494b-8451-98ee7ce21dd5', name: 'GRE' },

    { id: '39911b45-abc9-438c-9653-3c59c133582a', name: 'GRE (Math)' },

    { id: 'c7327cf4-c85e-4451-9f80-b30bc1632224', name: 'GRE (Verbal)' },

    { id: '68061d5a-dec4-4fa8-a5fa-99c2c7d2db8c', name: 'High School Regents: English Language Arts' },
    { id: 'b9e06a42-d048-498f-a5d3-2ea619b5c84b', name: 'High School Regents: Algebra 1' },
    { id: '8831a172-cdfc-4f56-aba7-341395f2c096', name: 'High School Regents: Algebra 2' },
    { id: '4b90d160-5949-4439-974a-4cb1bdbaf0a8', name: 'High School Regents: Geometry' },
    { id: '2580baf5-e7bd-47a5-a665-cfd603fdcb21', name: 'High School Regents: US History and Government' },
    { id: 'f82d91f5-362d-4cc2-9213-c02fa6a12722', name: 'High School Regents: Global History and Geography' },
    { id: '4dfcbea4-d98f-41bf-b770-5b6a2dbf26d7', name: 'High School Regents: Chemistry' },
    { id: '5bdd01b6-b554-4c56-9b00-ed045335b5cf', name: 'High School Regents: Earth Science' },
    { id: 'eb7a465b-b856-4835-840e-6723ef177013', name: 'High School Regents: Living Environment' },
    { id: '21457813-4659-4963-9ed5-96339b9e6e9f', name: 'High School Regents: Physics' },
    { id: '9e789308-a74c-4d02-81a3-8ec2c9e7cc90', name: 'High School Regents: French' },
    { id: '68972625-4ec2-4416-ac40-4757e7a958d9', name: 'High School Regents: German' },
    { id: '74442c76-b37d-4d4d-a3eb-c7eeac733dcf', name: 'High School Regents: Hebrew' },
    { id: '470fc428-42ca-4ef2-befe-08af5c61be3b', name: 'High School Regents: Italian' },
    { id: '62984b8b-47cc-40fd-ae79-df8b8f8984ae', name: 'High School Regents: Latin' },
    { id: '38088b0a-f58f-4328-9eb2-ad8b26d27b49', name: 'High School Regents: Spanish' },

    { id: '55723128-9092-472e-8b6e-c885dcc8497b', name: 'History Aptitude Test' },

    { id: 'a1492892-020d-4436-8e70-a548f4b53105', name: 'Hunter Test' },

    { id: '2e0f60c0-34df-419b-9208-c8cd7c7c1c91', name: 'IB Exams' },

    { id: 'bfed0226-fe3c-486b-841b-b8c3a4c0181e', name: 'IB Extended Essay' },

    { id: '6780ffe3-31e3-466c-9b88-28252837fe89', name: 'IB French' },

    { id: '74273d89-2aae-45b8-892d-2f836c25ae77', name: 'IB History' },

    { id: '75f6daa2-54d0-41a7-8c09-a550d05a4bf7', name: 'IB Theory of Knowledge' },

    { id: '841c2336-ccf2-43c3-a555-361cb76a7542', name: 'ISEE: Essay' },

    { id: 'e9e84671-6093-4221-8423-c3dce48e710a', name: 'ISEE (Lower Level)' },

    { id: '8a425fc2-225b-4c3a-b1cb-dedf30cd7857', name: 'ISEE (Math)' },

    { id: 'bc1d588c-288a-49b7-ac6e-00f1135a4876', name: 'ISEE (Middle Level)' },

    { id: 'bd7abfd1-a3b5-4e02-a672-a3b16c94390b', name: 'ISEE (Upper Level)' },

    { id: '7f766cda-8756-49ac-8609-c84023607fdc', name: 'ISEE (Verbal)' },

    { id: '28453544-5d08-4522-9769-430367674499', name: 'KRT' },

    { id: '7365e55f-6637-4798-bd90-9edf97e40a0d', name: 'LNAT' },

    { id: '7329fe3a-0b52-4581-9233-d9f31d9474de', name: 'LSAT' },
    { id: '89c889ba-5cd3-11ec-bf63-0242ac130002', name: 'LSAT: Logical Reasoning' },
    { id: '89c88c62-5cd3-11ec-bf63-0242ac130002', name: 'LSAT: Analytical Reasoning' },
    { id: '89c88d70-5cd3-11ec-bf63-0242ac130002', name: 'LSAT: Reading Comprehension' },
    { id: '89c88e56-5cd3-11ec-bf63-0242ac130002', name: 'LSAT: Writing' },

    { id: '32004191-3069-4722-a239-9244234eaaf0', name: 'MCAT' },
    { id: '89c88f32-5cd3-11ec-bf63-0242ac130002', name: 'MCAT: Biological and Biochemical Foundations of Living Systems' },
    { id: '89c89018-5cd3-11ec-bf63-0242ac130002', name: 'MCAT: Chemical and Physical Foundations of Biological Systems' },
    { id: '89c890f4-5cd3-11ec-bf63-0242ac130002', name: 'MCAT: Psychological, Social, and Biological Foundations of Behavior' },
    { id: '89c89216-5cd3-11ec-bf63-0242ac130002', name: 'MCAT: Critical Analysis and Reasoning Skills' },

    { id: 'd074d339-9bb1-4dfb-8eea-186619b62d86', name: 'National Latin Exam' },

    { id: '1001af2f-4893-4353-b4d1-37fa31bad5c9', name: 'NNAT' },

    { id: '15c5b42f-8a18-40cf-9ff3-72b93859f137', name: 'NY State Regents ELA' },

    { id: '1b34b893-45d6-4f84-ad35-4bcc190eae27', name: 'NY State Regents ELA and Math' },

    { id: '05f28dbe-5907-4a21-9965-6c3e1f432c7e', name: 'NY State Regents ELA and Math (3rd - 4th grade)' },

    { id: '70b9c8be-4aec-4b2e-b77b-1367e9ab2ddb', name: 'NY State Regents ELA and Math (3rd - 5th grades)' },

    { id: '1ce77051-88e0-4285-ad7a-aee0a08e9cb0', name: 'OLSAT' },

    { id: '89af00e9-1d06-4db6-90a6-48d2b31289b9', name: 'PSAT' },

    { id: 'd875995c-f3d5-43e7-a315-08491974eeee', name: 'PSAT: Verbal' },
    { id: 'e8fdc9a3-1072-4e80-958e-40ea945d7266', name: 'PSAT: Math' },
    { id: 'e5dda387-3039-4efa-8364-82ecda018772', name: 'PSAT: Writing' },

    { id: '6d389db8-e560-4d00-b909-5671c9b32675', name: 'SAT' },

    { id: '09454b0a-db6b-4776-bb46-c993232757dd', name: 'SAT (Math)' },

    { id: 'dd813dee-3553-44c0-b8fb-42423a03cd7d', name: 'SAT (Verbal)' },

    { id: 'de462f14-dcf9-43eb-8d8f-a12b63ccb740', name: 'SAT Biology' },

    { id: '588bf7f4-66c0-4acf-9721-3494daaea667', name: 'SAT Chemistry' },

    { id: 'a3a63212-6726-4e5e-b0f0-70dc9f3d59a2', name: 'SAT French' },

    { id: '0fdccceb-1511-477a-81c4-69dbbb7480df', name: 'SAT Italian' },

    { id: '22abec22-c85c-491c-8433-f8c013f27e4d', name: 'SAT Latin' },

    { id: '70e159f4-b67c-402e-999e-4b1dded2aa33', name: 'SAT Literature' },

    { id: '8e35fcc7-76be-4b37-8aaf-1064ec66f8ba', name: 'SAT Math 1 & 2' },

    { id: '9e17b265-d714-4bc2-a8ec-49d46aa10976', name: 'SAT Physics' },

    { id: 'dee20196-f043-4cce-a6b2-7ded32d17d6b', name: 'SAT Spanish' },

    { id: '4df21200-864d-453d-b97e-574799de9083', name: 'SAT US History' },

    { id: '7af67a45-5d48-4ce3-a3a0-6a45bdabbb67', name: 'SAT World History' },

    { id: '5f78f5a2-3f8e-4a40-b311-1a892cec6268', name: 'SAT II: Biology E/M' },

    { id: 'daad5a6f-13f0-422b-abc0-7aeb3e0afcc5', name: 'SAT II: Chemistry' },

    { id: 'ce8e0ae6-6f91-4123-ba81-a972c40cb448', name: 'SAT II: Chinese with Listening' },

    { id: 'd69dcbc1-5e42-40ee-8bf9-37f2876712b7', name: 'SAT II: English' },

    { id: 'ed031a54-8ee4-40cc-9a1f-40094cdcbbab', name: 'SAT II: French' },

    { id: 'dedb9ded-8a08-4413-9167-412f6fb9bfe0', name: 'SAT II: French with Listening' },

    { id: 'dc565020-0bc9-4a77-b9be-2cc76f22087e', name: 'SAT II: German' },

    { id: '7eca9256-31e4-4f3f-be85-b3409bf65e92', name: 'SAT II: German with Listening' },

    { id: '7a13ff7b-205e-4dea-8e0b-11dfbaa385d7', name: 'SAT II: Italian' },

    { id: 'a7f932e9-001c-46dd-8bb5-d36338c27227', name: 'SAT II: Japanese with Listening' },

    { id: '6284d14e-fb7f-4071-bfd6-ff1650c76219', name: 'SAT II: Korean with Listening' },

    { id: '380d2de8-a7cd-471b-b2db-69c591f26c99', name: 'SAT II: Latin' },

    { id: '909e1bdc-a021-4717-bd1b-90f81b36b3fe', name: 'SAT II: Math Level 1' },

    { id: '15d49b25-10c5-47b1-acc0-fff94028e470', name: 'SAT II: Math Level 2' },

    { id: '1b5f2d5e-e70a-4f46-b4a8-9702a6fec954', name: 'SAT II: Modern Hebrew' },

    { id: '316aec59-6f80-4beb-8a80-834085628a69', name: 'SAT II: Physics' },

    { id: '23731c77-6faf-4863-92f9-c5c1086bca66', name: 'SAT II: Spanish' },

    { id: '1ec9305b-9038-4694-a285-c6d58aee7f47', name: 'SAT II: Spanish with Listening' },

    { id: '47665036-7061-43ba-88e2-62e6bfbc928d', name: 'SAT II: U.S. History' },

    { id: '39d6fb3a-e2d1-49b7-b861-a8c6cb402e0c', name: 'SAT II: World History' },

    { id: '8516fdca-1648-49e4-80b3-ed07cfd0e5ab', name: 'SB' },

    { id: '64fc67c4-5175-45a2-862f-9335cf6055f5', name: 'SCAT' },

    { id: '60c5f36c-38ae-4e85-9273-3b4f6102bba0', name: 'SHSAT' },

    { id: 'b14c222a-ee2e-499e-b0a1-f99d6ec53cff', name: 'SSAT' },

    { id: '9a266ad8-3c36-4094-9369-26244c94f9d1', name: 'SSAT (Math)' },

    { id: '48e0d5d1-b875-4511-beec-f213395f57a6', name: 'SSAT (Verbal)' },

    { id: 'eb06d12c-527f-4607-ade0-fd9173cec627', name: 'SSAT (Essay)' },

    { id: '33a2f4f5-7116-4786-bdf0-a8f4ba7d62b8', name: 'Stanford Binet' },

    { id: 'f675816f-7608-4c13-b74f-d3a34e60819b', name: 'TACHS' },

    { id: 'f819b2fb-73f4-42bd-b334-8e0d6c512d76', name: 'Test Prep: ACT (All Sections)' },
    { id: '9b2a8486-2c2b-4522-9c82-16e856ee4026', name: 'Test Prep: ACT Science' },
    { id: '5732e39a-f70c-4061-9d2a-6e1aebb16a64', name: 'Test Prep: AFOQT' },
    { id: '11e41725-3d48-4b48-92bb-4221158a810c', name: 'Test Prep: ASVAB' },
    { id: 'fa2f59f1-7d62-4ee2-a4c3-2aab778b53ca', name: 'Test Prep: Bar Exam' },
    { id: 'a7894602-7403-4b6a-bc49-5fea15f0ba04', name: 'Test Prep: CBEST' },
    { id: '1db1c402-2831-4fd8-9688-07df182d2918', name: 'Test Prep: CFA' },
    { id: 'b144cb02-afb7-4b06-a165-d1ffa1d23f4f', name: 'Test Prep: CLAST' },
    { id: '13fb7370-fb4e-43b1-897e-b3c8c7772644', name: 'Test Prep: ACT (Math)' },
    { id: 'b3adcb84-eba8-4219-8086-1a2ad20975c8', name: 'Test Prep: COOP/HSPT' },
    { id: 'e588dc28-4873-4d5d-9345-ee0685c70171', name: 'Test Prep: Common Core' },
    { id: '67bff7cc-275d-45ab-9c4c-90233eba5c12', name: 'Test Prep: GED' },
    { id: '35f25ed1-5a07-4412-801e-cfd8783dae95', name: 'Test Prep: GMAT' },
    { id: 'bb41295d-1ce3-4fb1-9495-125c4bdc3da3', name: 'Test Prep: GRE' },
    { id: 'beada02e-9b7c-476c-bdba-11274abb47ed', name: 'Test Prep: IELTS' },
    { id: 'd922ea8e-b08c-4aac-97e0-829ea52af860', name: 'Test Prep: ISEE' },
    { id: '1f35f1ef-a39f-44fc-95a3-69ad46a663f1', name: 'Test Prep: LSAT' },
    { id: 'e23ff780-d02b-4f2c-93da-ac00646119cd', name: 'Test Prep: MCAT' },
    { id: '7e2bd503-5d4d-4c97-8100-d763db95cb2e', name: 'Test Prep: MCSA' },
    { id: 'b62767ad-494d-4d51-b00b-d1fcd057780c', name: 'Test Prep: MTLE' },
    { id: '63fcfbbc-7a19-420f-b253-11f486bf70e4', name: 'Test Prep: NCLEX' },
    { id: 'c8a55685-a110-4500-8e30-22f50b9789a5', name: 'Test Prep: Nursing Exams' },
    { id: '70513748-beee-434a-b6b1-2d1054a18d60', name: 'Test Prep: Praxis' },
    { id: '8f93454e-52ce-4b42-9441-4209cd3c0ff7', name: 'Test Prep: Regents' },
    { id: 'ca9b686a-f745-4aaa-ab4d-1da9d050eef3', name: 'Test Prep: SAT (All Sections)' },
    { id: '5d7181f5-3aee-4351-95f8-e062fc494dbf', name: 'Test Prep: SAT Math' },
    { id: 'c64384a0-f8e2-4d89-89f2-7b279b763cac', name: 'Test Prep: SAT Reading' },
    { id: 'd4969ea3-592c-4ab6-85b5-ab16bb8d66de', name: 'Test Prep: SAT Writing' },
    { id: 'cb748213-e7a8-40eb-90e9-52028910dbc9', name: 'Test Prep: SOL' },
    { id: 'c5efd741-ee46-43a1-a1de-3fa4aeb44a29', name: 'Test Prep: SSAT' },
    { id: 'ad53a112-3ed8-4c0c-90a4-2a1c4ce80b00', name: 'Test Prep: STAAR' },
    { id: 'ec6c91a4-0dec-46c8-99f8-5e3b2ebdc735', name: 'Test Prep: Series 63' },
    { id: '02c97c4c-0a67-4190-8d37-92854bf2081c', name: 'Test Prep: Series 7' },
    { id: '07784f19-878f-4dcb-876e-4a58ccd3e469', name: 'Test Prep: TAKS' },
    { id: '6ee7571e-d95a-4761-a994-493f1bafb064', name: 'Test Prep: TEAS' },
    { id: '7d1c8bc4-c2c7-4e1f-bd7c-d7efd2c7c665', name: 'Test Prep: TOEFL' },
    { id: '1f0cd397-d698-454b-a9fd-b2bffb2f3abd', name: 'Test Prep: USCIS' },
    { id: 'd39dc5a0-36de-4d4b-8f88-852ceef0436d', name: 'Test Prep: USMLE' },

    { id: '4d3b342b-e88c-49a1-aaa3-a49fdb735f6a', name: 'TSA' },

    { id: '5ca276de-0359-4028-835f-c1b11bea8003', name: 'WISC' },

    { id: 'f58497e5-31f1-459a-b952-77dd888d58c0', name: 'WPPSI' },
];

export const getAllSubjectList = (subjects: any) => {

    const tutoringSubjectList = getTutoringSubjectList();
    const counsellingSubjectList = getCounsellingSubjectList();
    const standardizedTestsList = getStandardizedTestList();

    const teacherTutoringSubjects = tutoringSubjectList.filter(subItem =>
        subjects.includes(subItem.id),
    );

    const teacherCounsellingSubjects = counsellingSubjectList.filter(subItem =>
        subjects.includes(subItem.id),
    );

    const teacherStandardizedTests = standardizedTestsList.filter(subItem =>
        subjects.includes(subItem.id),
    );

    return [
        ...teacherTutoringSubjects,
        ...teacherCounsellingSubjects,
        ...teacherStandardizedTests,
    ];
};

export const getAllSubjectAndTestList = (subjects: any) => {

    const tutoringSubjectList = getTutoringSubjectList();
    const standardizedTestsList = getStandardizedTestList();
    const teacherTutoringSubjects = tutoringSubjectList.filter(subItem =>
        subjects.includes(subItem.id),
    );
    const teacherStandardizedTests = standardizedTestsList.filter(subItem =>
        subjects.includes(subItem.id),
    );

    return [
        ...teacherTutoringSubjects,
        ...teacherStandardizedTests,
    ];
};

export const getCounsellingSubjectList = () => {
    return counsellingList;
};

export const getTutoringSubjectList = () => {
    return subjectsList;
};

export const getStandardizedTestList = () => {
    return standardizedTestList;
};

export const allSubjectList = () => {
    return [...subjectsList, ...standardizedTestList];
};

export const allSubjecAndSpecialitytList = () => {
    return [...subjectsList, ...counsellingList, ...standardizedTestList];
};
