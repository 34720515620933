import React, { useState, useEffect, useCallback } from "react"
import { GiftedChat, MessageProps } from "react-web-gifted-chat"
import { useDispatch, useSelector } from "react-redux"
import moment from "moment"
import { Chat, ChatEvents, ChatStatuses, MessageEvents, MessageStatus, MessageType } from "tcd-message-center"
import { messageCenterConstant } from "store/constant"
interface Iprops {
    renderBubble: any
    renderBottomToolBar: any
    chatType: string
    handleAttachmentUpload?: any
    whoIsTyping?: any;
    conversation: Chat | undefined
}

const LAST_MESSAGE_LIMIT = 100;

const ChatWindow = ({
    renderBubble,
    renderBottomToolBar,
    chatType,
    handleAttachmentUpload,
    whoIsTyping,
    conversation
}: Iprops): JSX.Element => {

    const { userAuthData } = useSelector((state: any) => state.auth);
    const [textMessage, setTextMessage] = useState("");
    const [messages, setMessages] = useState<any[]>([]);
    const [loadEarlier, setLoadEarlier] = useState(false);
    const [isLoadPreviousMessage, setLoadPreviousMessage] = useState(true);
    const [uploadingMessages, setUploadingMessages] = useState<any[]>([]);
    const [whoIsTypingobject, setwhoIsTypingobject] = useState(whoIsTyping);
    const [chatId, setChatId] = useState<string>("");
    const [roomMembers, setRoomMembers] = useState<Array<string>>([]);
    const [newMessage, setNewMessage] = useState<any>({});
    const [isReady, setIsReady] = useState<boolean>(false);

    const dispatch = useDispatch();


    useEffect(() => {

        conversation?.status === ChatStatuses.READY ? setIsReady(true) : setIsReady(false);

        //conversation is ready listener
        const isReadyListner = () => {
            setIsReady(true);
        }
        conversation?.on(ChatEvents.READY, isReadyListner);

        //new message added to conversation(sending, received) listener
        const newMessageListener = (message: MessageType) => {
            setNewMessage(createMessageForRendering(message, conversation));
        }
        conversation?.on(MessageEvents.NEW, newMessageListener);

        //message updated listener
        const messageUpdated = (message: MessageType) => {
            //removing message from localstorage if sent
            if (message.status !== MessageStatus.SENDING) {
                removePendingMessageFromLocalStorage(message.id);
            }
            setNewMessage(createMessageForRendering(message, conversation));
            dispatch({ type: messageCenterConstant.SORT_CHATS });
        }
        conversation?.on(MessageEvents.UPDATED, messageUpdated);

        return () => {
            //removing listners
            conversation?.off(ChatEvents.READY, isReadyListner);
            conversation?.off(MessageEvents.NEW, newMessageListener);
            conversation?.off(MessageEvents.UPDATED, messageUpdated);
        }
    }, [conversation]);

    useEffect(() => {
        if (isReady) {
            setChatId(conversation?.id || "");
            setRoomMembers([conversation?.sender?.id || '', conversation?.recipient?.id || '']);
            conversation?.getLastMessages({ limit: LAST_MESSAGE_LIMIT }).then(messages => {
                const _messages = messages.map((message) => {
                    return createMessageForRendering(message, conversation);
                });
                setMessages(_messages);
                const pendingMessages = getPendingMessagesFromLocalStorage();
                pendingMessages?.forEach((msg: any) => {
                    const _msg = msg[Object.keys(msg)[0]];
                    if (_msg.conversationId === conversation.id) {
                        removePendingMessageFromLocalStorage(_msg.id);
                        const exist = messages?.some((existingMessage) => {
                            return existingMessage.id === _msg.id;
                        })
                        !exist && sendTextMessage(_msg.text);
                    }
                })
            });

        }
    }, [isReady, conversation])



    useEffect(() => {
        const index = findMessageIndex(newMessage, messages);
        if (index >= 0) {
            setMessages(messages.map((msg) => msg.id === newMessage.id ? newMessage : msg));
        } else {
            setMessages([newMessage, ...messages]);
        }
    }, [newMessage])

    const findMessageIndex = (message: any, messages: any[]) => {
        return messages.findIndex((msg) => message.id === msg.id);
    }

    const getPendingMessagesFromLocalStorage = () => {
        return JSON.parse(window.localStorage.getItem("pending_messages") || JSON.stringify([]));
    }

    const removePendingMessageFromLocalStorage = (id: string) => {
        let pendingMessages = getPendingMessagesFromLocalStorage();
        pendingMessages = pendingMessages.filter((msg: any) => Object.keys(msg)[0] !== id);
        window.localStorage.setItem("pending_messages", JSON.stringify(pendingMessages));
    }

    const createMessageForRendering = (message: MessageType, conversation: Chat | undefined) => {
        return {
            ...message,
            user: {
                id: message.createdBy,
                name: message.createdBy === conversation?.recipient?.id ? conversation.recipient.name : conversation?.sender?.name,
                avatar: (message.createdBy === conversation?.recipient?.id ? conversation.recipient.profilePicture : conversation?.sender?.profilePicture) || ""
            }
        }
    }

    const markMessageAsRead = useCallback((currentMessage: any) => {
        if (userAuthData.userId !== currentMessage?.createdBy && !currentMessage?.seenAt) {
            conversation?.markMessageRead(currentMessage?.id);
        }
    }, [conversation]);

    const createObject = (): object => {
        const timestamp = moment.utc().valueOf()
        const messageId = `${timestamp}-${userAuthData.userId}`
        const messages: any = {
            text: textMessage,
            _id: messageId,
            timestamp: timestamp,
            seenBy: {
                [userAuthData.userId]: true,
            },
            deliveredTo: {
                [userAuthData.userId]: true,
            },
            user: {
                _id: userAuthData.userId,
                name: userAuthData
                    ? `${userAuthData.firstName} ${userAuthData.lastName}`
                    : undefined,
                avatar: userAuthData.profilePic.downloadURL
                    ? userAuthData.profilePic.downloadURL
                    : "",
            },
            senderId: userAuthData.userId,
            image: "",
            video: "",
            attachment: "",
        }
        return messages
    }

    const onSend = async (): Promise<void> => {
        if (textMessage.trim()) {
            await sendTextMessage(textMessage);
        }
    }

    const sendTextMessage = async (message: string) => {
        const messageResponse: MessageType | undefined = await conversation?.send({ text: message });
        if (messageResponse) {
            const messageToSend = createMessageForRendering(messageResponse, conversation);
            setMessages([
                messageToSend,
                ...messages
            ]);
            setTextMessage("");

            //adding message to localstorage
            let pendingMessages = getPendingMessagesFromLocalStorage();
            pendingMessages.push({
                [messageToSend.id]: {
                    ...messageToSend,
                    conversationId: conversation?.id
                }
            });
            window.localStorage.setItem("pending_messages", JSON.stringify(pendingMessages))
        }
    }

    const handleAttachmentUploads = async (file: any): Promise<void> => {
        const imgWithTextMessage = textMessage
        let message = createObject()
        message = {
            ...message,
            attachment: {
                name: file.name,
                type: file.type,
                uri: "",
            },
            isUploading: true,
            text: imgWithTextMessage ? imgWithTextMessage : "",
        }
        setTextMessage("")
        const uploadingMessagesList = [...uploadingMessages]
        uploadingMessagesList.push(message)
        setUploadingMessages(uploadingMessagesList)
    }

    useEffect(() => {
        if (handleAttachmentUpload) {
            handleAttachmentUploads(handleAttachmentUpload).catch(console.error)
        }
    }, [handleAttachmentUpload])

    const handleFileUpload = async (event: any | null): Promise<void> => {
        const file = event.target.files[0]
        const tempUrl = await window.URL.createObjectURL(file)
        const imgWithTextMessage = textMessage
        let message = createObject()
        message = {
            ...message,
            image: tempUrl,
            isUploading: true,
            text: imgWithTextMessage ? imgWithTextMessage : "",
        }
        setTextMessage("")
        const uploadingMessagesList = [...uploadingMessages]
        uploadingMessagesList.push(message)
        setUploadingMessages(uploadingMessagesList)
    }

    const handleMessage = (event: any): boolean => {
        setTextMessage(event.target.value)
        return false
    }

    const renderFooterView = (): JSX.Element | null => {
        setRoomMembers(roomMembers ? roomMembers : [])
        const loggedInUserId = userAuthData.userId
        const typers = whoIsTypingobject
            ? [...whoIsTypingobject].filter((w) => w !== loggedInUserId).filter((t, i) => i < 3)
            : []
        if (!roomMembers.length || !typers.length) {
            if (chatType === chatType) {
                return <div></div>
            } else {
                return null
            }
        }

        const names = roomMembers
            .filter((r: any) => typers && typers.includes(r.userId))
            .map((t: any) => t.firstName || "someone")
            .join()

        return (
            <div>
                {names} {`${typers.length > 1 ? "are" : "is"}`} typing....
            </div>
        )
    }

    return (
        <>
            {isReady &&
                <GiftedChat
                    listViewProps={{
                        showsVerticalScrollIndicator: false,
                        extraData: { whoIsTypingobject },
                    }}
                    loadEarlier={false}
                    onLoadEarlier={(): void => {
                        setLoadEarlier(true)
                    }}
                    messages={messages}
                    onSend={onSend}
                    renderInputToolbar={(props: any) => {
                        return renderBottomToolBar(
                            props,
                            textMessage,
                            handleFileUpload,
                            handleMessage,
                            onSend,
                        )
                    }}
                    renderMessage={(props: MessageProps<any>): React.ReactNode => {
                        const messageBelongsToCurrentUser =
                            userAuthData.userId == props.currentMessage?.createdBy;
                        markMessageAsRead(props.currentMessage);
                        return messageBelongsToCurrentUser
                            ? renderBubble(props, true)
                            : renderBubble(props, false)
                    }}
                    bottomOffset={100}
                    renderFooter={renderFooterView}
                ></GiftedChat>
            }
        </>
    )
}
export default ChatWindow
