import React from "react"
import { Grid } from "@material-ui/core"
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import ExitSvgIcon from '../../assets/images/svgIcons/ExitSvgIcon'
import colors from "../../assets/colors"
import whiteLogo from "../../assets/images/color-backgrounds/white-TCD-logo.png"



const LessonHeader = ({ lessonTitle, setExiting }: { lessonTitle: string | undefined, setExiting: any }): JSX.Element => {
    const { t } = useTranslation();
    const lessonTitleStr=lessonTitle?.split(": ")[1] ? lessonTitle?.split(": ")[1] : lessonTitle;
    const lessonTitleLen=lessonTitleStr?.split(' ').length || 0;
    const maxLessonTitle=15;
    console.log("Length of lesson",lessonTitleStr?.split(' ').length);
    return (
        <>
            {lessonTitle
                ? (<Grid container className="lesson-header-content">
                      <Grid item lg={12} md={12} sm={12} className="lesson-exit lesson-logo">
                          <img 
                          src={whiteLogo} 
                          alt="The Classroom Door"
                          height="70px" />
                        <Button
                            variant="contained"
                            color="primary"
                            className="lesson-button lesson-btn-hide-mob"
                            onClick={() => setExiting(true)}
                            endIcon={<ExitSvgIcon width="18" height="18" iconColor={colors.whiteSolid}/>}
                        >
                            {t('exit-lesson')}
                      </Button>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} className="pb-3">

                        <p className='lesson-title mb-2'>{ lessonTitleLen >= maxLessonTitle? <span className="lesson-title-long-span"> {lessonTitleStr}</span>:<span className="lesson-title-short-span">{lessonTitleStr}</span>}</p>
                        <p className='lesson-copyright lesson-copyright-mob-show'>Copyright &copy; {(new Date().getFullYear())} The Classroom Door LLC; All rights reserved</p>
                    </Grid>
                    <Grid className="m-auto pb-3">
                    <p className='lesson-copyright lesson-copyright-mob-hide'>Copyright &copy; {(new Date().getFullYear())} The Classroom Door LLC; All rights reserved</p>
                    <Button
                            variant="contained"
                            color="primary"
                            className="lesson-button lesson-btn-show-mob"
                            onClick={() => setExiting(true)}
                            endIcon={<ExitSvgIcon width="18" height="18" iconColor={colors.whiteSolid}/>}
                        >
                            {t('exit-lesson')}
                      </Button>
                    </Grid>

                </Grid>)
                : <></>
            }
        </>

    )
}

export default LessonHeader