import React, { useCallback, useEffect, useState } from "react"
import { NavLink } from "react-router-dom"
import { useDispatch } from "react-redux"
import { logOutRequest } from "../../../store/actions/userActions"
import PowerSvgIcon from "../../../assets/images/svgIcons/PowerSvgIcon"
import { ArrowBackIos, ArrowForwardIos } from "@material-ui/icons"
import LogoWithoutText from "../../../assets/images/svgIcons/LogoWithoutText"
import LogoWithText from "../../../assets/images/svgIcons/LogoWithText"

interface Props {
    routes: any;
}

const SideBar = ({ routes }: Props): JSX.Element => {
    const [isSidebarExpanded, setSidebarExpanded] = useState<boolean>(false)
    const dispatch = useDispatch()
    const logOut = useCallback((): void => {
        dispatch(logOutRequest())
    }, [])
    const toggleSidebarExpansion = useCallback(() => {
        setSidebarExpanded((prevValue) => !prevValue)
    }, [])
    useEffect(() => {
        const sidebar = document.getElementsByClassName("sidebar")
        const panelBody = document.getElementsByClassName("panel-body")
    }, [])

    return (
        <div className={isSidebarExpanded ? "sidebar expanded" : "sidebar"}>
            <div className="header">
                <div className="navbar-logo">
                    {isSidebarExpanded === true ? <LogoWithText /> : <LogoWithoutText />}
                </div>
                <div className="navbar-collapse-button" onClick={toggleSidebarExpansion}>
                    <span className="cover">
                        {isSidebarExpanded ? (
                            <ArrowBackIos color="inherit" />
                        ) : (
                            <ArrowForwardIos color="inherit" />
                        )}
                    </span>
                </div>
            </div>
            <ul className="nav">
                {routes.map((prop: any, key: number) => {
                    if (!prop.redirect)
                        return (
                            <li key={key}>
                                <NavLink
                                    to={prop.layout + prop.path}
                                    className="nav-link"
                                    activeClassName="active"
                                >
                                    <span className="nav-icon">
                                        <prop.icon />
                                    </span>
                                    <span className="nav-title">{prop.name}</span>
                                </NavLink>
                            </li>
                        )
                    return null
                })}
            </ul>
            <div className="logout-wrapper">
                <ul className="nav">
                    <li>
                        <div className="nav-link" onClick={() => logOut()}>
                            <span className="nav-icon">
                                <PowerSvgIcon />
                            </span>
                            <span className="nav-title">Sign out</span>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default SideBar
