import React, { Component, createRef } from "react"
import { forwardIcon, backwardIcon, plusIcon } from "../icons/Icons"
import Grid from "@material-ui/core/Grid"
import Button from "../button/Button"

class HorizontalScrollContainer extends Component<{
    height?: number
    children: any
    scrollAmount: number
    button?: false | boolean
    buttonAction?: any
}> {
    scrollDivRef = createRef<HTMLDivElement>()
    innerContent = createRef<HTMLDivElement>()

    scroll = (direction: "right" | "left") => {
        if (this.scrollDivRef.current && this.innerContent.current) {
            const doScroll = (
                direction: string,
                scrollProp: any,
                amount: any,
                counter: number,
            ): void => {
                const frames = 30
                if (counter > frames) {
                    return
                }
                if (direction === "left") {
                    scrollProp.scrollLeft += amount / frames
                } else {
                    scrollProp.scrollLeft -= amount / frames
                }
                setTimeout(doScroll, 1, direction, scrollProp, amount, counter + 1)
            }
            setTimeout(
                doScroll,
                1,
                direction,
                this.scrollDivRef.current,
                this.props.scrollAmount,
                0,
            )
        }
    }
    needsScrolling = () => {
        if (this.innerContent.current && this.scrollDivRef.current) {
            return this.innerContent.current.clientWidth > this.scrollDivRef.current.clientWidth
        }
    }
    render() {
        return (
            <>
                <Grid container>
                    <Grid
                        item
                        xs={1}
                        onClick={() => this.scroll("right")}
                        className="scroll-button-container"
                    >
                        {this.props.button && this.props.buttonAction ? (
                            <div className="row-action-button">
                                <Button
                                    clickAction={this.props.buttonAction}
                                    leftSideIcon={plusIcon}
                                    isSmall
                                />
                            </div>
                        ) : null}
                        <p className="scroll-button">{backwardIcon({})}</p>
                    </Grid>

                    <Grid item xs={10}>
                        <div
                            ref={this.scrollDivRef}
                            className="horizontal-scroll-container"
                            style={this.props.height ? { height: this.props.height } : {}}
                            onClick={() => { }}
                        >
                            <div
                                style={{ display: "inline-block" }}
                                onClick={this.needsScrolling}
                                ref={this.innerContent}
                                className="scroll-content"
                            >
                                <table>
                                    <tbody>
                                        <tr>
                                            {this.props.children.map(
                                                (child: any, index: number) => (
                                                    <td key={index}>{child}</td>
                                                ),
                                            )}
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </Grid>
                    <Grid
                        item
                        xs={1}
                        onClick={() => this.scroll("left")}
                        className="scroll-button-container"
                    >
                        <p className="scroll-button">{forwardIcon({})}</p>
                    </Grid>
                </Grid>
            </>
        )
    }
}
export default HorizontalScrollContainer
