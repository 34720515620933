import React from "react"
import interfaceConstant from "../../../utils/constant/interfaceConstant"

class SendSvgIcon extends React.PureComponent<interfaceConstant.SvgIconProps> {
    render() {
        const { width, height, iconColor, ...otherProps } = this.props
        return (
            <svg width={width} height={height}  {...otherProps}>
                <g id="Icons-/-Base-/-Bas-15" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="noun_send_976415" transform="translate(1.000000, 2.000000)" fill={iconColor}>
                        <path d="M14.6756318,7.94387117 C14.82926,7.74602073 14.8097481,7.46258225 14.6304947,7.28819098 C14.4512414,7.1137997 14.1705564,7.10518386 13.9811843,7.26825987 L5.30059061,14.7405208 L0.716569408,12.9467728 C0.309350808,12.7890945 0.0303152271,12.4057417 0.00231247205,11.9654914 C-0.025690283,11.5252412 0.202477164,11.1088617 0.586360502,10.8996706 L19.6168928,0.113536252 C19.9216425,-0.0525600305 20.2920265,-0.0353734561 20.5804713,0.158248266 C20.8689161,0.351869989 21.0285509,0.690464252 20.9957717,1.03912373 L19.3264268,18.6455541 C19.2925304,19.0116984 19.0898206,19.3401762 18.7795557,19.5317226 C18.4692907,19.723269 18.0878728,19.7554106 17.7505652,19.6184344 L12.1716144,17.4328318 L8.66932869,20.7534613 C8.40512953,21.0032047 8.01934475,21.0698261 7.68822972,20.9228879 C7.35711469,20.7759496 7.14424919,20.4436664 7.14688611,20.07785 L7.14688611,17.4699904 L14.6756318,7.94387117 Z" id="Path"></path>
                    </g>
                </g>
            </svg>
        )
    }
}

export default SendSvgIcon
