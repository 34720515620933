import React from "react"
import { useFormik } from "formik"
import { useDispatch, useSelector } from "react-redux"
import * as Yup from "yup"
import { resetPasswordRequest } from "../../../store/actions/userActions"
import Button from "../../../components/button/Button"
import { applicationConstant } from "../../../utils/constant/applicationConstant"
import InterfaceConstant from "../../../utils/constant/interfaceConstant"
import { InputBox } from "../../../components/input-field/InputFields"

interface User {
    email: string
}
interface Iprops {
    history: any
    isSuccess?: boolean
}

const resetPasswordLabel: InterfaceConstant.IappLabels = applicationConstant.appLabels
const signUpErrorLabel: InterfaceConstant.IappErrorLabels = applicationConstant.appErrorLabels
const regexVariables: InterfaceConstant.IregexExpression = applicationConstant.regexExpression

const ResetPasswordForm = ({ history, isSuccess }: Iprops): JSX.Element => {
    const dispatch = useDispatch()
    const { isloading } = useSelector((state: any) => state.buttonReducer)
    const formik = useFormik({
        initialValues: {
            email: "",
        },
        validationSchema: Yup.object().shape({
            email: Yup.string()
                .matches(regexVariables.EMAIL_VALIDATION, signUpErrorLabel.GLOBAL_EMAIL_INVALID)
                .required(signUpErrorLabel.GLOBAL_REQUIRED),
        }),
        onSubmit: (values: User) => {
            dispatch(resetPasswordRequest(values.email, history))
            formik.resetForm()
        },
    })
    return (
        <form className="login-flow-form" onSubmit={formik.handleSubmit}>
            <InputBox
                type={"email"}
                title={resetPasswordLabel.GLOBAL_EMAIL}
                placeholder={resetPasswordLabel.EMAIL_PLACEHOLDER}
                inputProps={formik.getFieldProps("email")}
                error={formik.touched.email && formik.errors.email ? formik.errors.email : null}
                success={isSuccess ? "Password reset instructions have been sent to the email address you have registered." : ""}
            />
            <Button
                title={"Reset password"}
                clickAction={undefined}
                isDisabled={false}
                isloading={isloading}
            />
        </form>
    )
}

export default ResetPasswordForm
