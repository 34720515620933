import React, { useEffect } from "react"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useDispatch, useSelector } from "react-redux"
import { updatePassword, verifyCode } from "../../../store/actions/userActions"
import Button from "../../../components/button/Button"
import { applicationConstant } from "../../../utils/constant/applicationConstant"
import InterfaceConstant from "../../../utils/constant/interfaceConstant"
import { InputBox } from "../../../components/input-field/InputFields"

interface User {
    newPassword: string
    confirmPassword: string
}

interface Iprops {
    history: any
    code: string
}

const forgotPasswordLabel: InterfaceConstant.IappLabels = applicationConstant.appLabels
const forgotPasswordErrorLabel: InterfaceConstant.IappErrorLabels =
    applicationConstant.appErrorLabels
const ForgotPasswordForm = ({ code, history }: Iprops): JSX.Element => {
    const dispatch = useDispatch()
    const loading = useSelector((state: any) => state.buttonReducer.isloading)

    const formik = useFormik({
        initialValues: {
            newPassword: "",
            confirmPassword: "",
        },
        validationSchema: Yup.object().shape({
            newPassword: Yup.string()
                .min(6, forgotPasswordErrorLabel.GLOBAL_PASSWORD_REGEX_ERROR)
                .required("Required"),
            confirmPassword: Yup.string()
                .oneOf([Yup.ref("newPassword"), null], "Password is not match with new password")
                .required("Required"),
        }),
        onSubmit: (values: User) => {
            const { newPassword } = values
            dispatch(updatePassword({ code, newPassword, history }))
            formik.resetForm()
        },
    })

    useEffect(() => {
        const action = { code, history }
        dispatch(verifyCode(action))
    }, [])

    return (
        <form className="login-flow-form" onSubmit={formik.handleSubmit}>
            <InputBox
                type={"password"}
                title={forgotPasswordLabel.NEW_PASSWORD}
                placeholder={forgotPasswordLabel.NEW_PASSWORD_PLACEHOLDER}
                inputProps={formik.getFieldProps("newPassword")}
                error={
                    formik.touched.newPassword && formik.errors.newPassword
                        ? formik.errors.newPassword
                        : null
                }
            />
            <InputBox
                type={"password"}
                title={forgotPasswordLabel.CONFIRM_NEW_PASSWORD}
                placeholder={forgotPasswordLabel.CONFIRM_NEW_PASSWORD_PLACEHOLDER}
                inputProps={formik.getFieldProps("confirmPassword")}
                error={
                    formik.touched.confirmPassword && formik.errors.confirmPassword
                        ? formik.errors.confirmPassword
                        : null
                }
            />

            <Button
                title={"Reset password"}
                clickAction={undefined}
                isDisabled={false}
                isloading={loading}
            />
        </form>
    )
}

export default ForgotPasswordForm
