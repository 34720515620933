import React from "react"
import { newUser, loginUser } from "../../../components/icons/Icons"
import OptionScreenComponent from "../../../components/option-card-component/OptionScreenComponent"
import { navigatetoUrl } from "../../../utils/helper/helper"
import OnboardingBackground from "../../../components/on-boarding/onboarding-background/OnboardingBackground"
import ProgressBar from "../../../components/progress-bar/ProgressBar"

const LookingForPageOptions = [
    {
        key: "NEW_USER",
        title: "A New User",
        subtitle: "If you’re new to The Classroom Door, click here to get started.",
        icon: newUser,
    },
    {
        key: "EXISTING_USER",
        title: "Already  Registered",
        subtitle: "If you’re registered at The Classroom Door, proceed to sign in.",
        icon: loginUser,
    },
]
interface Iprops {
    history: any
}
export default class IAmPage extends React.Component<Iprops> {
    handleOptionPress = (selectedOption: string) => {
        switch (selectedOption) {
            case "NEW_USER":
                navigatetoUrl(this.props.history, "/my-role")
                break
            case "EXISTING_USER":
                navigatetoUrl(this.props.history, "/sign-in")
                break
            default:
                break
        }
    }
    render(): JSX.Element {
        return (
            <>
                <OnboardingBackground />
                <div className="i-am">
                    <ProgressBar at="20" theme="darkMango" />
                    <OptionScreenComponent
                        heading={""}
                        subHeading={"I am"}
                        options={LookingForPageOptions}
                        onOptionPress={this.handleOptionPress}
                    />
                </div>
            </>
        )
    }
}
