
import React, { useState, useEffect, useMemo } from "react"
import { Grid } from "@material-ui/core"
import { useFormik } from "formik"
import * as Yup from "yup"
import { applicationConstant } from "../../../../utils/constant/applicationConstant"
import InterfaceConstant from "../../../../utils/constant/interfaceConstant"
import { InputBox } from "../../../../components/input-field/InputFields"
import Button from "../../../../components/button/Button"
import UserProfilePhoto from "./UserProfilePhoto"
import { useSelector, useDispatch } from "react-redux"
import MessageAlert from "../../../../components/message-alert/MessageAlert"
import _ from "lodash"
import { DatePicker } from "@material-ui/pickers"
import { DateFormats, formatDate } from "../../../../utils/helper/date-utils"
import { school } from "../../../../utils/constant/educationLists"
import { loginSuccess, signupInformation } from "../../../../store/actions/userActions"
import { fileUpload, updateStudent, updateUser } from "../../../../utils/helper/firebase"
import { getUserWithDetails } from "../../../../utils/services/userServices"
import SimpleDropdown from "../../../../components/dropdown/SimpleDropdown"
import { softTimeZones, timeZoneCountries, timeZoneCountriesType, timeZoneCountryListType } from "utils/constant/softtimezones"
import { timeZoneAlreadyAvailable, timeZoneSoftReturn } from "utils/helper/softTimeZone-utils"
import { defaultTimeZone } from "utils/helper/Timezone"
import PhoneNumberInput from "components/phone-number-input/PhoneNumberInput"
import parsePhoneNumber, { isValidPhoneNumber, NationalNumber } from "libphonenumber-js"
import { useSnackbar } from "notistack"
import { SMSOptIn } from "components/OptIns/SMSOptIn";
interface User {
    firstName: string
    lastName: string
    currentSchool: string
    myGoal: string
    biography: string
    biographyFlag: any
    dateOfBirth: string
    timeZone: string
    phoneNumber: {
        countryCode: string,
        countryPrefix: string,
        number: string | NationalNumber
    }
}

interface Iprops {
    handleAvatarClick: Function
    optionsOpen: boolean
    setDataUpdated: Function
}
interface RootState {
    auth: any
}

const timeZones = softTimeZones
const UserProfileForm = ({
    handleAvatarClick,
    optionsOpen,
    setDataUpdated,
}: Iprops): JSX.Element => {
    const dispatch = useDispatch()
    const { userAuthData } = useSelector((state: RootState) => state.auth)
    const { studentCurrentGrade } = useSelector((state: any) => state.signup.options)
    const studentData = useSelector((state: any) => state.signup.options)
    const [openSnackBar, setOpenSnackBar] = useState(false)
    const [message, setMessage] = useState({
        message: "",
        type: "",
    })
    const [allTimeZones, setAllTimeZones] = useState(timeZoneCountries.reduce((prev: timeZoneCountryListType, curr: timeZoneCountriesType) => {
        return prev.concat(timeZones[curr]);
    }, []))
    const [userSMS, setUserSMS] = useState<boolean>(false)
    const [loading, setLoading] = useState(false)
    const [isPhoneNumberEmpty, setIsPhoneNumberEmpty] = useState<boolean>(true)
    const [notificationStatus, setNotificationStatus] = useState(userAuthData.notificationSubscriptions?.sms)
    const appLabels: InterfaceConstant.IappLabels = applicationConstant.appLabels
    const appErrorLabels: InterfaceConstant.IappErrorLabels = applicationConstant.appErrorLabels

    useEffect(() => {
        setLoading(false)
    }, [studentData.profilePic])

    const [gradeInputProps, setGradeInputProps] = useState({
        error: appErrorLabels.GLOBAL_REQUIRED,
        value: studentCurrentGrade,
    })
    const [timeZoneInputProps, settimeZoneInputProps] = useState({
        error: appErrorLabels.GLOBAL_REQUIRED,
        value: {
            id: '',
            name: '',
            standard: ''
        }
    })
    const [phoneNumber, setPhoneNumber] = useState<{
        number: "" | NationalNumber,
        countryCode: string,
        countryPrefix: string
    }>({
        number: userAuthData?.phoneNumber?.number || "",
        countryCode: userAuthData?.phoneNumber?.countryCode || "+1",
        countryPrefix: userAuthData?.phoneNumber?.countryPrefix || "US"
    });
    const [phoneNumberError, setPhoneNumberError] = useState<string | undefined>();
    const { enqueueSnackbar } = useSnackbar();

    const d = new Date()
    d.setFullYear(d.getFullYear() - 5)
    useEffect(() => {

        if (!timeZoneAlreadyAvailable(defaultTimeZone)) {
            const newTimeZone = timeZoneSoftReturn(defaultTimeZone);
            allTimeZones.push(newTimeZone);
            settimeZoneInputProps({
                error: appErrorLabels.GLOBAL_REQUIRED,
                value: newTimeZone,

            })
        }

    }, [timeZones])

    useEffect(() => {
        if (userAuthData.defaultTimeZone) {
            const newTimeZone: any = timeZoneAlreadyAvailable(userAuthData.defaultTimeZone);
            if (newTimeZone) {
                settimeZoneInputProps({
                    error: appErrorLabels.GLOBAL_REQUIRED,
                    value: newTimeZone
                })
                
        } else {
                const newTimeZone = timeZoneSoftReturn(userAuthData.defaultTimeZone);
                settimeZoneInputProps({
                    error: appErrorLabels.GLOBAL_REQUIRED,
                    value: newTimeZone
                })
            }
        }
    }, [userAuthData])

    useEffect(() => {
        dispatch(signupInformation({ timeZone: timeZoneInputProps }))
    }, [timeZoneInputProps])

    useEffect(() => {
        dispatch(signupInformation({ studentCurrentGrade: gradeInputProps?.value?.id }))
    }, [gradeInputProps])

    useEffect(() => {
        formik.setFieldValue("phoneNumber", phoneNumber);
    }, [phoneNumber]);

    const handlePhoneNumber = (value: string) => {
        const phoneNumber = parsePhoneNumber("" + value);
        if (!value.split(" ")[0].includes("0") && (phoneNumber?.nationalNumber === undefined)) {
            setIsPhoneNumberEmpty(true)
        } else {
            setIsPhoneNumberEmpty(false)
        }
        const { countryCallingCode = userAuthData?.phoneNumber?.countryCode, nationalNumber = '', country = userAuthData?.phoneNumber?.countryPrefix } = phoneNumber || {};
        setPhoneNumber({ countryCode: "+" + countryCallingCode, number: nationalNumber, countryPrefix: country?.toUpperCase() });
    }

    const navigateNextPage = async (formikObject: any) => {

        await formikObject.validateForm()
        const errors = formikObject.errors
        let valuesError = false
        Object.values(errors).map((value) => (value ? (valuesError = true) : ""))
        if (valuesError) {
            setMessage({
                message: "Please Add Correct Details    ",
                type: "error",
            })
            setOpenSnackBar(true)
        }
    }
    const [isShowZoneError, setisShowZoneError] = useState(false)
    const formik = useFormik({
        initialValues: {
            firstName: studentData.firstName,
            lastName: studentData.lastName,
            currentSchool: studentData.currentSchool ? studentData.currentSchool : "",
            myGoal: studentData.myGoal ? studentData.myGoal : "",
            biography: studentData.biography ? studentData.biography : "",
            biographyFlag: false,
            dateOfBirth: studentData.dateOfBirth ? studentData.dateOfBirth : "",
            timeZone: studentData.timeZone,
            phoneNumber: phoneNumber,
        },
        validationSchema: Yup.object().shape({
            firstName: Yup.string()
                .matches(/^[\\a-zA-Zp{L}.'-]+$/, 'English alphabets and .\'- are allowed only')
                .required(appErrorLabels.GLOBAL_NAME_INVALID),
            lastName: Yup.string()
                .matches(/^[\\a-zA-Zp{L} .'-]*$/, 'Blank space, English alphabets and .\'- are allowed only')
                .required(appErrorLabels.GLOBAL_NAME_INVALID),
            biography: Yup.string().when(["biographyFlag"], {
                is: true,
                then: Yup.string().min(100, appErrorLabels.GLOBAL_MIN_LENGTH_BIOGRAPHY),
                otherwise: Yup.string().notRequired(),
            })
        }),
        onSubmit: async (values: User) => {
            if (!isValidPhoneNumber(values.phoneNumber.countryCode + values.phoneNumber.number) && (!isPhoneNumberEmpty || notificationStatus)) {
                const errorMessage = "Please enter a valid Phone Number.";
                setPhoneNumberError(errorMessage);
                enqueueSnackbar(errorMessage, {
                    variant: "error",
                    autoHideDuration: 10000
                });
                return;
            } else {
                setPhoneNumberError(undefined);
            }
            setLoading(true)
            let word = studentData.lastName ? studentData.lastName : null;
            let lastname = word.trim().split(/ +/).join(" ");
            formik.setFieldValue("lastName", lastname, true);

            const profileData = {
                file: studentData.profilePic.downloadURL,
                fileName: "profileImage.jpeg",
                filePath: `/users/${userAuthData.userId}/public/`,
            }

            const data = studentData.profilePic.newPic ? await fileUpload(profileData) : studentData.profilePic.downloadURL;
            const profilePic = {
                downloadURL: data,
                storagePath: `/users/${userAuthData.userId}/public/`,
            }

            const userDBData = {
                firstName: studentData.firstName ? studentData.firstName : null,
                lastName: lastname,
                profilePic: profilePic,
                dateOfBirth: studentData.dateOfBirth ? studentData.dateOfBirth : null,
                phoneNumber: phoneNumber,
                defaultTimeZone: timeZoneInputProps.value?.id ? timeZoneInputProps.value.id : null
            }
            const studentDBData = {
                studentCurrentGrade: studentCurrentGrade ? studentCurrentGrade : null,
                currentSchool: studentData.currentSchool ? studentData.currentSchool : null,
                myGoal: studentData.myGoal ? studentData.myGoal : null,
                biography: studentData.biography ? studentData.biography : null
            }


            try {
                await updateStudent(userAuthData.userId, studentDBData)
                await updateUser(userAuthData.userId, userDBData)
                await getUserWithDetails(userAuthData.userId).then((data: any) => {
                    setLoading(false)
                    setMessage({
                        message: "Profile sucessfully updated.",
                        type: "success",
                    })
                    setOpenSnackBar(true)
                    dispatch(loginSuccess(data))
                    setDataUpdated(true)
                })
            } catch (error) {
                setLoading(false)
                setMessage({
                    message: "An error occurred: " + error,
                    type: "error",
                })
                setOpenSnackBar(true)
            }
        },
    })

    const handleChange = (e: any) => {
        var { name, value } = e.target
        switch (name) {
            case "firstName":
                formik.setFieldValue("firstName", value && value[0].toUpperCase() + value.substring(1), true)
                dispatch(signupInformation({ firstName: value }))
                break
            case "lastName":
                let words = value.split(" ");
                words = words.map((word: string) => {
                    return word && word[0].toUpperCase() + word.substring(1);
                }).join(" ");
                formik.setFieldValue("lastName", words, true)
                dispatch(signupInformation({ lastName: words }))
                break
            case "currentSchool":
                formik.setFieldValue("currentSchool", value, true)
                dispatch(signupInformation({ currentSchool: value }))
                break
            case "myGoal":
                formik.setFieldValue("myGoal", value, true)
                dispatch(signupInformation({ myGoal: value }))
                break
            case "biography":
                formik.setFieldValue("biography", value, true)
                if (value?.length > 0) {
                    formik.setFieldValue("biographyFlag", true, true)
                } else {
                    formik.setFieldValue("biographyFlag", false, true)
                }
                dispatch(signupInformation({ biography: value }))
                break
        }
    }
    const handleDateOfBirth = (e: any): void => {
        const dateFormated = formatDate(e, DateFormats.EUA_DATE)
        formik.setFieldValue("dateOfBirth", dateFormated, true)
        dispatch(signupInformation({ dateOfBirth: dateFormated }))
    }

    return (
        <>
            <MessageAlert
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
                type={message.type}
                message={message.message}
            />
            <form onSubmit={formik.handleSubmit} autoComplete="off" className="account-profile">
                <Grid container direction="row" justify="center" alignContent="flex-start">
                    <Grid item xl={2} lg={2} md={3} sm={12} xs={12} className="user-profile-img">
                        <UserProfilePhoto
                            loading={loading}
                            setLoading={setLoading}
                            source="student"
                            handleAvatarClick={handleAvatarClick}
                            optionsOpen={optionsOpen}
                        />
                    </Grid>
                    <Grid item xl={10} lg={10} md={9} sm={12} xs={12}>
                        <Grid container spacing={3} justify="center" direction="row" alignContent="flex-start">
                            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                <InputBox
                                    type={"text"}
                                    title={appLabels.GLOBAL_FIRST_NAME}
                                    placeholder={appLabels.FIRST_NAME_PLACEHOLDER}
                                    inputProps={{
                                        disabled: loading,
                                        name: "firstName",
                                        value: formik.values.firstName,
                                        onChange: (e: any) => handleChange(e),
                                        onBlur: formik.handleBlur,
                                    }}
                                    error={
                                        formik.touched.firstName && formik.errors.firstName
                                            ? formik.errors.firstName
                                            : null
                                    }
                                />
                            </Grid>
                            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                <InputBox
                                    type={"text"}
                                    title={appLabels.GLOBAL_LAST_NAME}
                                    placeholder={appLabels.LAST_NAME_PLACEHOLDER}
                                    inputProps={{
                                        name: "lastName",
                                        disabled: loading,
                                        value: formik.values.lastName,
                                        onChange: (e: any) => handleChange(e),
                                        onBlur: formik.handleBlur,
                                    }}
                                    error={
                                        formik.touched.lastName && formik.errors.lastName
                                            ? formik.errors.lastName
                                            : null
                                    }
                                />
                            </Grid>
                            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                <div className="phone-number-wrapper">
                                    <PhoneNumberInput
                                        className="phone-number"
                                        defaultCountry={'us'}
                                        onChange={handlePhoneNumber}
                                        preferredCountries={['us']}
                                        disableAreaCodes={true}
                                        countryCodeEditable={true}
                                        title="Phone Number"
                                        variant="outlined"
                                        inputProps={{
                                            style: {
                                                "height": "30px"
                                            }
                                        }}
                                        value={phoneNumber?.countryCode + phoneNumber?.number}
                                        errorMessage={phoneNumberError}
                                    />
                                </div>
                            </Grid>
                            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                <SimpleDropdown
                                    isDisabled={loading}
                                    title={appLabels.GLOBAL_TIMEZONE}
                                    placeholder={appLabels.GLOBAL_TIMEZONE}
                                    optionsList={allTimeZones}
                                    fieldDetails={timeZoneInputProps}
                                    setProps={settimeZoneInputProps}
                                    isShowError={isShowZoneError}
                                />
                            </Grid>
                            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                <div className="datepicker-wrapper p-15">
                                    <label className="input-label">{appLabels.GLOBAL_BIRTH_DATE}</label>
                                    <DatePicker
                                        disabled={loading}
                                        format={DateFormats.EUA_DATE}
                                        autoOk
                                        inputVariant="standard"
                                        variant="dialog"
                                        className="datepicker-root"
                                        value={formik.values.dateOfBirth}
                                        InputProps={{
                                            fullWidth: true,
                                            label: appLabels.GLOBAL_BIRTH_DATE,
                                            placeholder: appLabels.BIRTH_DATE_PLACEHOLDER,
                                            value: formik.values.dateOfBirth,
                                            classes: { root: "mui-datepicker-input" },
                                            disableUnderline: true,
                                            onBlur: formik.handleBlur,
                                        }}
                                        maxDate={d}
                                        InputLabelProps={{
                                            disableAnimation: true,
                                            shrink: false,
                                        }}
                                        helperText={
                                            formik.touched.dateOfBirth &&
                                            formik.errors.dateOfBirth &&
                                            appErrorLabels.GLOBAL_REQUIRED
                                        }
                                        onChange={(e: any) => handleDateOfBirth(e)}
                                        error={
                                            formik.touched.dateOfBirth && formik.errors.dateOfBirth
                                                ? true
                                                : undefined
                                        }
                                    />
                                </div>
                            </Grid>

                            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                <InputBox
                                    type={"text"}
                                    title={appLabels.GLOBAL_GOAL}
                                    placeholder={appLabels.GOAL_PLACEHOLDER}
                                    inputProps={{
                                        disabled: loading,
                                        name: "myGoal",
                                        value: formik.values.myGoal,
                                        onChange: (e: any) => handleChange(e),
                                        onBlur: formik.handleBlur,
                                    }}
                                    error={
                                        formik.touched.myGoal && formik.errors.myGoal
                                            ? formik.errors.myGoal
                                            : null
                                    }
                                />
                            </Grid>

                            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                <InputBox
                                    type={"text"}
                                    title={appLabels.GLOBAL_CURRENT_SCHOOL}
                                    placeholder={appLabels.CURRENT_SCHOOL_PLACEHOLDER}
                                    inputProps={{
                                        disabled: loading,
                                        name: "currentSchool",
                                        value: formik.values.currentSchool,
                                        onChange: (e: any) => handleChange(e),
                                        onBlur: formik.handleBlur,
                                    }}
                                    error={
                                        formik.touched.currentSchool && formik.errors.currentSchool
                                            ? formik.errors.currentSchool
                                            : null
                                    }
                                />
                            </Grid>


                            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                <SimpleDropdown
                                    isDisabled={loading}
                                    title={appLabels.GLOBAL_OPTIONAL_GRADE_LEVEL}
                                    placeholder={appLabels.OPTIONAL_GRADE_LEVEL_PLACEHOLDER}
                                    optionsList={school}
                                    fieldDetails={gradeInputProps}
                                    setProps={setGradeInputProps}
                                    isShowError={false}
                                />
                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>

                <Grid container className="user-profile-revervse-col" direction="row" alignContent="flex-start">
                    <Grid item xs={12} className="about-me">
                        <InputBox
                            type={"textarea"}
                            title={appLabels.GLOBAL_BIOGRAPHY}
                            placeholder={appLabels.GLOBAL_BIOGRAPHY_PLACEHOLDER}
                            inputProps={{
                                disabled: loading,
                                name: "biography",
                                value: formik.values.biography,
                                onChange: handleChange,
                                onBlur: formik.handleBlur,
                            }}
                            error={
                                formik.touched.biography && formik.errors.biography
                                    ? formik.errors.biography
                                    : null
                            }
                        />
                    </Grid>
                </Grid>
                <Grid container direction="row" className="subscription-optins" alignItems="flex-start" justify="flex-start">
                    <Grid item xs={12} className="sms">
                        <SMSOptIn userId={userAuthData.userId} setNotificationStatus={setNotificationStatus} />
                    </Grid>
                </Grid>
                <div className="display-flex">
                    <Button
                        isDisabled={loading}
                        spanStyle={{ margin: "auto" }}
                        title={"Save Profile"}
                        isloading={loading}
                        clickAction={() => navigateNextPage(formik)}
                    />
                </div>

            </form>
        </>
    )
}

export default UserProfileForm