import React from "react"
import interfaceConstant from "../../../utils/constant/interfaceConstant"

class FemaleGenderSvgIcon extends React.PureComponent<interfaceConstant.SvgIconProps> {
    render(): JSX.Element {
        const { width, height, ...otherProps } = this.props
        return (
            <svg width={width} height={height} viewBox="0 0 32 32" version="1.1" {...otherProps}>
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <path
                        d="M13.9042128,9.06656172 C11.362102,11.6087639 11.1360251,15.6444076 13.3784167,18.452418 L10.8478472,20.9724868 L8.4606767,18.5952219 C8.25039135,18.3704842 7.93369343,18.2779742 7.63482377,18.3539835 C7.3359541,18.4299929 7.10257978,18.6623988 7.02625371,18.9600283 C6.94992765,19.2576578 7.04282317,19.5730416 7.2684973,19.7824543 L9.65566784,22.1597193 L7.46813112,24.3381788 C7.24245698,24.5475915 7.14956146,24.8629753 7.22588753,25.1606048 C7.3022136,25.4582343 7.53558792,25.6906402 7.83445758,25.7666496 C8.13332725,25.8426589 8.45002516,25.7501489 8.66031052,25.5254112 L10.8478472,23.3469517 L13.2293943,25.7326168 C13.4396796,25.9573545 13.7563776,26.0498646 14.0552472,25.9738552 C14.3541169,25.8978459 14.5874912,25.6654399 14.6638173,25.3678104 C14.7401433,25.0701809 14.6472478,24.7547972 14.4215737,24.5453844 L12.0372149,22.1737196 L14.5818431,19.6396504 C17.5856657,21.9511662 21.8851299,21.4945213 24.331843,18.6041057 C26.7785561,15.71369 26.5031505,11.4165277 23.7072991,8.85932577 C20.9114477,6.30212389 16.588574,6.39350886 13.9042128,9.06656172 Z"
                        id="Path"
                        fill="#686C80"
                        transform="translate(16.500000, 16.500000) rotate(-45.000000) translate(-16.500000, -16.500000) "
                    ></path>
                </g>
            </svg>
        )
    }
}

export default FemaleGenderSvgIcon
