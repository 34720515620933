import React from "react"
import interfaceConstant from "../../../utils/constant/interfaceConstant"

class SingleTickSvgIcon extends React.PureComponent<interfaceConstant.SvgIconProps> {
    render() {
        const { width, height, iconColor, ...otherProps } = this.props
        return (
            <svg width={width} height={height} viewBox="0 0 32 32" {...otherProps}>
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <path
                        d="M13.7598016,20.5999015 C13.5601275,20.5999015 13.3686488,20.515532 13.2274722,20.3655015 L10.2157075,17.1655015 C9.92623697,16.8523167 9.92837887,16.3497874 10.2205072,16.0394011 C10.5126354,15.7290148 10.9856042,15.7267391 11.2803663,16.0343015 L13.7310995,18.6382055 L19.9766938,11.2647655 C20.2548728,10.9364101 20.730908,10.9098284 21.0399484,11.2053935 C21.3489887,11.5009586 21.3740069,12.0067461 21.0958279,12.3351015 L14.3193573,20.3351015 C14.1811377,20.4981139 13.9860801,20.5938174 13.7796566,20.5999015 L13.7598016,20.5999015 Z"
                        id="Path"
                        fillRule="nonzero"
                        fill={iconColor}
                    />
                </g>
            </svg>
        )
    }
}

export default SingleTickSvgIcon
