import { TutorRatesConstant, LogoutConstant } from "../../constant"

const initialState = {
    individualRate:undefined,
}

const tutorRateReducer = (state = initialState, action: any): object => {
    switch (action.type) {
        case TutorRatesConstant.SET_TUTOR_RATE:
            return {
                individualRate: action.props.individualRate,
            }
        case LogoutConstant.CLEAR_DATA:
            return initialState    
        default:
            return state
    }
}

export default tutorRateReducer
