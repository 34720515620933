import { TutorSignUpConstant, LogoutConstant } from "../../constant"

const initialState = {
    teacherWeeklyAvailability: {
        0: [],
        1: [],
        2: [],
        3: [],
        4: [],
        5: [],
        6: [],
    },
}

const tutorAvailabilityReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case TutorSignUpConstant.SET_TUTOR_AVAILABILITY_SLOT:
            return {
                teacherWeeklyAvailability: action.props.teacherWeeklyAvailability,
            }
        case LogoutConstant.CLEAR_DATA:
            return initialState
        default:
            return state
    }
}

export default tutorAvailabilityReducer
