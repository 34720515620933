import React, { useState, useEffect } from "react";
import moment from "moment";
import "./next-session.scss";

const NextSessionComponent = ({session}: {session?: any}) => {
    const [next, setNext] = useState("Now");
    useEffect(() => {
        if (!session) {
            return;
        }
        const { startTime, sessionLength, extensionTime } = session;
        const st = moment(startTime);
        const et = moment(st).add(sessionLength, "minutes").add(extensionTime, "minutes");
        let nextText = `${st.format("MMMM D")} at ${st.format("hh:mm A")}`
        if (!moment().isBetween(st, et, "seconds", "[]")) {
            setNext(nextText);
        } else {
            setNext("Now")
        }
    }, [session]);

    return session
    ? <div className="next-session">
        <p className="next-session-title">Next session</p>
        <p className="next-session-value">{next}</p>
    </div>
    : <></>
}

export default NextSessionComponent;