import React from "react"
import { useSelector } from "react-redux"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import SignUpPage from "../pages/onboarding/sign-up/SignUpPage"
import SignInPage from "../pages/onboarding/sign-in/SignInPage"
import ForgotPasswordFormPage from "../pages/onboarding/forgot-password/ForgotPasswordPage"
import ResetPasswordPage from "../pages/onboarding/reset-password/ResetPasswordPage"
import LookingForPage from "../pages/onboarding/looking-for/LookingForPage"
import LandingPage from "../pages/onboarding/landing/LandingPage"
import LookingForTutoringPage from "../pages/onboarding/looking-for-tutoring/LookingForTutoringPage"
import EducationalBackgroundPage from "../pages/onboarding/educational-background/EducationalBackgroundPage"
import VerifyEmailPage from "../pages/onboarding/verify-email/VerifyEmailPage"
import IAmPage from "../pages/onboarding/i-am/IAmPage"
import CollegeCouseling from "../pages/onboarding/college-counseling/college-couseling-lessons"
import MyRole from "../pages/onboarding/myRole/myRole"
import TutorReviewPage from "../pages/onboarding/tutor-review/TutorReviewPage"
import TutorMyProfilePage from "../pages/onboarding/tutor-myprofile-page/TutorMyProfilePage"
import TutorAdvisorApplicationPage from "../pages/onboarding/tutor-advisor-application/TutorAdvisorApplicationPage"
import LookingForTutoringAdvisingPage from "../pages/onboarding/looking-for-tutoring-advising/LookingForTutoringAdvisingPage"
import TutorAdvisorEducationPage from "../pages/onboarding/tutor-advisor-education/TutorAdvisorEducationPage"
import TutorAvailabilityPage from "../pages/onboarding/tutor-availability/TutorAvailabilityPage"
import TutorCancellationPolicyPage from "../pages/onboarding/tutor-cancellation-policy/TutorCancellationPolicyPage"
import LessonOptionsPage from "../pages/onboarding/college-counseling/payment-plans/LessonOptionsPage"
import LessonSubscribePage from "../pages/onboarding/college-counseling/subscription-confirm/LessonSubscribePage"
import Panel from "../pages/panel/Panel"

import TutorSearchPage from "../pages/home/tutor-search/TutorSearchRoute"

import MobileRoute from "./MobileRoute"
import PrivateRoute from "./PrivateRoute"
import NotFound from "../pages/NotFound"
import LocalRoute from "./LocalRoute"
import AuthenticationRoute from "./AuthenticationRoute"
import OnboardingRoute from "./OnboardingRoute"
import ScrollToTop from "../components/scroll-to-top/ScrollToTop"
import OnboardingTutorRoute from "./OnboardingTutorRoute"

import interfaceConstant from "../utils/constant/interfaceConstant"
import { applicationConstant } from "../utils/constant/applicationConstant"
import VendorPage from "../pages/meeting/vendorPage"
import { PendingContainer } from "../pendings/index"
import { BookingPopupContainer } from "popups/booking/BookingPopupContainer"
import { ReviewPopupContainer } from "popups/review/ReviewPopupContainer"
import LessonRoutes from "pages/home/lesson-content/LessonRoutes"
import TutorProfilePreview from "pages/home/tutor-profile/TutorProfilePreview"
import { MessageCenterContainer } from "tcd-message-center"
import { firebaseAuth, functions, storage, database } from "utils/helper/firebase"
import MessageCenterListener from "../pages/home/message/MessageCenterListener"
import AuthenticationRedirections from "../pages/authentication/AuthenticationRedirections"
import InspiredPage from "../pages/home/inspired/InspiredPage"
import { UnsubEmailNotification } from "components/unsub-email-notification"
import RefreshStripeAccountLink from "components/RefreshStripeAccountLink/RefreshStripeAccountLink"
import AdminRoute from "./AdminRoute"
import { AdminPanel } from "pages/panel/AdminPanel"
const routeUrl: interfaceConstant.IrouteUrl = applicationConstant.routeUrl
const userTypeKeys: interfaceConstant.IuserTypes = applicationConstant.userTypeKeys
export default function Routes() {
    const { userId, firstName, lastName, email, userType } =
        useSelector((s: any) => s?.auth?.userAuthData) || {}
    return (
        <Router>
            <ScrollToTop />
            <MessageCenterContainer
                {...{
                    userId,
                    firebaseServices: {
                        auth: firebaseAuth,
                        functions,
                        storage,
                        realtime: database,
                    },
                }}
            >
                <MessageCenterListener />
                <PendingContainer userId={userId}>
                    <Switch>
                        <MobileRoute path="/mobile/zoom/" component={VendorPage} />
                        <MobileRoute path="/panel/lessons/mobile/" component={LessonRoutes} />
                        <MobileRoute
                            path={`/panel/lessons/mobile2/`}
                            exact
                            component={LessonRoutes}
                        />

                        <Route path="/search" exact component={TutorSearchPage} />
                        <PrivateRoute path={routeUrl.PANEL_ROUTE} component={Panel} />
                        <PrivateRoute path={`${routeUrl.PANEL_ROUTE}/*`} component={NotFound} />
                        <LocalRoute path="/" exact component={LandingPage} />
                        <LocalRoute path="/inspired" component={InspiredPage} />
                        <LocalRoute
                            path="/forgot-password"
                            exact
                            component={ForgotPasswordFormPage}
                        />
                        <OnboardingRoute path="/sign-up" exact component={SignUpPage} />
                        <OnboardingRoute
                            path="/college-counseling"
                            exact
                            component={CollegeCouseling}
                        />
                        <OnboardingRoute
                            path="/lessonPaymentPlans"
                            exact
                            component={LessonOptionsPage}
                        />
                        <OnboardingRoute
                            path={`/subscribe/:subType`}
                            exact
                            component={LessonSubscribePage}
                        />

                        <AuthenticationRoute
                            path="/auth/action"
                            exact
                            component={AuthenticationRedirections}
                        />
                        <LocalRoute path="/sign-in" exact component={SignInPage} />
                        <LocalRoute path="/reset-password" exact component={ResetPasswordPage} />
                        <LocalRoute path="/looking-for" exact component={LookingForPage} />
                        <OnboardingRoute
                            path={[
                                "/looking-for-tutoring",
                                "/looking-for-advising",
                                "/looking-for-tutoring-and-advising-subjects",
                            ]}
                            exact
                            component={LookingForTutoringPage}
                        />
                        <OnboardingRoute
                            path={["/looking-for-tutoring-and-advising-areas"]}
                            exact
                            component={LookingForTutoringAdvisingPage}
                        />
                        <OnboardingRoute
                            path={[
                                "/looking-for-tutoring-background",
                                "/looking-for-background",
                                "/looking-for-advising-background",
                                "/looking-for-tutoring-and-advising-background",
                            ]}
                            exact
                            component={EducationalBackgroundPage}
                        />
                        <LocalRoute path="/auth-action" exact component={VerifyEmailPage} />
                        <LocalRoute path="/i-am" exact component={IAmPage} />
                        <LocalRoute path="/my-role" exact component={MyRole} />
                        <LocalRoute
                            path="/tutor-advisor-application"
                            exact
                            component={TutorAdvisorApplicationPage}
                        />
                        <OnboardingTutorRoute
                            path="/tutor-advisor-education"
                            exact
                            component={TutorAdvisorEducationPage}
                        />
                        <OnboardingTutorRoute
                            path="/tutor-availability"
                            exact
                            component={TutorAvailabilityPage}
                        />
                        <OnboardingTutorRoute
                            path="/tutor-cancellation-policy"
                            exact
                            component={TutorCancellationPolicyPage}
                        />
                        <OnboardingTutorRoute
                            path="/tutor-myprofile"
                            exact
                            component={TutorMyProfilePage}
                        />
                        <OnboardingTutorRoute
                            path="/tutor-review"
                            exact
                            component={TutorReviewPage}
                        />
                        <PrivateRoute path="/meeting" component={VendorPage} />
                        <PrivateRoute path="/tutorpreview" component={TutorProfilePreview} />
                        <Route
                            path="/unsubscribe/emails"
                            exact
                            component={UnsubEmailNotification}
                        />
                        <PrivateRoute
                            path="/server/accountLink/refresh"
                            exact
                            component={RefreshStripeAccountLink}
                        />
                        <AdminRoute path="/admin/*" component={AdminPanel} />
                        <PrivateRoute path="*" exact component={NotFound} />
                    </Switch>
                    <ReviewPopupContainer />
                    <BookingPopupContainer />
                </PendingContainer>
            </MessageCenterContainer>
        </Router>
    )
}
