import {
    tutorSearchConstant,
} from "../constant"


export const fetchTutors = (props: any) => {
    return { 
        type: tutorSearchConstant.REQUEST_TUTORS ,
        payload: props
    }
}

export const fetchTutorsSuccess = (props: any) => {
    return { 
        type: tutorSearchConstant.REQUEST_TUTORS_SUCCESS ,
        payload: props
    }
    
}
export const fetchTutorsFailure = (props: any) => {
    return { 
        type: tutorSearchConstant.REQUEST_TUTORS_FAILURE ,
        payload: props
    }
}