import { TutorSignUpConstant } from "../../constant"

const initialState = {
    // isLoading: false,
    // options: {
    //     email: "",
    //     firstName: "",
    //     gender: { name: "", id: "" },
    //     lastName: "",
    //     phoneNumber: "",
    //     password: "",
    // },
}

const tutorReviewReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case TutorSignUpConstant.SET_MY_PROFILE_TUTOR_DATA:
            return {
                // options: {
                //     ...state.options,
                //     ...action.props,
                // },
            }
        default:
            return state
    }
}

export default tutorReviewReducer
