import { Grid } from "@material-ui/core"
import React, { memo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import NaviagationCard from "../components/NaviagationCard"
import SessionView from "./session-view"
import DashboardCalendar from "./dashboard-calendar"
import LessonCard from "../components/LessonCard"
import { navigatetoUrl } from "../../../../utils/helper/helper"
import {
    DateFormats,
    formatDate,
} from "../../../../utils/helper/date-utils"
import NextSessionComponent from "./../components/NextSessionComponent";
import ToastNotification from "components/modal/ToastNotification"
import { toastConstant } from "store/constant"



const getSessionDetail = ({ sessions }: any): JSX.Element => {
    const count = sessions.length;
    const currentdate = new Date();

    return (
        <div className="display-flex session-detail">
            <div className="inline-block count">
                <p className="counter">{count}</p>
            </div>
            <div className="inline-block session-detail-text-container">
                <p className="session-detail-text">Next Session</p>
                <p className="session-detail-text">{currentdate.toISOString() < sessions[0] ? `${formatDate(
                    sessions[0]?.startTime,
                    DateFormats.LONG_DATE,
                )} at ${formatDate(sessions[0]?.startTime, DateFormats.TIME_IN_MERIDIAN)}` : "Now"}</p>
            </div>
        </div>
    )
}


const DashboardView = (): JSX.Element => {
    const localLoading = useSelector((state: any) => state.studentDashboard.upcomingSessions.isLoading);;
    const advisors = useSelector((state: any) => state.studentDashboard.advisors.data);
    const advisorsLoading = useSelector((state: any) => state.studentDashboard.advisors.isLoading);
    const tutors = useSelector((state: any) => state.studentDashboard.tutors.data);
    const tutorsLoading = useSelector((state: any) => state.studentDashboard.tutors.isLoading);
    const sessions = useSelector((state: any) => state.studentDashboard.upcomingSessions.data);
    const { message, show, isSuccess } = useSelector((state: any) => state.toast)

    const history = useHistory();
    const dispatch = useDispatch();


    return (
        <>
            <ToastNotification
                message={message}
                isopen={show}
                isOpenAction={() => dispatch({ type: toastConstant.TOAST_HIDE })}
                styleClass={isSuccess ? "success-toast" : "error-toast"}
            />
            <div className="lg-screen">
                <SessionView />
            </div>
            <div className="md-screen">
                <Grid container>
                    <Grid item xl={4} lg={4} md={6} sm={12} xs={12} className="dashboard-left-panel">
                        <DashboardCalendar />
                    </Grid>
                    <Grid
                        item
                        id="right-col"
                        xl={8} lg={8} md={6} sm={12} xs={12}
                        className=""
                    >
                        {!localLoading && <NaviagationCard
                            title="Upcoming Sessions"
                            subtitle={sessions.length === 0 ? "You have no upcoming session yet!" : sessions.length === 1 ? `You have ${sessions.length} session!` : `You have ${sessions.length} sessions!`}
                            url="/panel/dashboard/sessions"
                            noResult={sessions.length}
                            noSessions={sessions.length < 1}
                            type="sessions"
                            buttonText={"Book Now"}
                            buttonAction={() => navigatetoUrl(history, "/panel/search")}
                        >
                            <NextSessionComponent session={(([session]) => {
                                return session;
                            })(sessions)}></NextSessionComponent>

                        </NaviagationCard>}
                        <Grid container className="tutors-advisor-container">
                            <Grid item xl={6} lg={12} md={12} sm={6} xs={12} className="card-container">
                                {!tutorsLoading && <NaviagationCard
                                    title={tutors.length === 0 ? "Book a Tutor" : "My Tutors"}
                                    subtitle={tutors.length === 0 ? "You have no Tutors yet but there are 1000+ available!" : tutors.length === 1 ? `You have ${tutors.length} Tutor!` : `You have ${tutors.length} Tutors!`}
                                    noResult={tutors.length}
                                    url={tutors.length === 0 ? "/panel/search" : "/panel/dashboard/tutors"}
                                    buttonText={tutors.length === 0 ? "Search and Book" : "Go to My Tutors"}
                                    noTutors={tutors.length === 0}
                                    type="tutors" />}
                            </Grid>
                            <Grid item xl={6} lg={12} md={12} sm={6} xs={12} className="card-container advisor-container">
                                {!advisorsLoading && <NaviagationCard
                                    title={advisors.length === 0 ? "Book an Advisor" : "My Advisors"}
                                    subtitle={advisors.length === 0 ? "You have no Advisors yet but there are 1000+ available!" : advisors.length === 1 ? `You have ${advisors.length} Advisor!` : `You have ${advisors.length} Advisors!`}
                                    noResult={advisors.length}
                                    url={advisors.length === 0 ? "/panel/search" : "/panel/dashboard/advisors"}
                                    buttonText={advisors.length === 0 ? "Search and Book" : "Go to My Advisors"}
                                    noAdvisors={advisors.length === 0}
                                    type="advisors" />}
                            </Grid>
                        </Grid>
                        <LessonCard />
                    </Grid>
                </Grid>
            </div>

        </>
    )
}

export default memo(DashboardView)
