import React, { useEffect, useState } from "react"
import _ from "lodash"
import MessageBubble from "../../ui-element/MessageBubble"
import ChatWindow from "../../../../../components/chat-window/_ChatWindow"
import SendSvgIcon from "../../../../../assets/images/svgIcons/SendSvgIcon"
import ReactDOM from "react-dom"
import { Chat, ChatEvents, ChatStatuses } from "tcd-message-center"
import defaultAvatar from "../../../../../assets/images/default-avatar.png"
import colors from "assets/colors"



const UserConversationBlockPortal = ({ conversation }: { conversation: Chat | undefined }) => {
    const [attachmentObj, setAttachmentObj] = useState<any>("")
    const [isReady, setIsReady] = useState(false);

    useEffect(() => {
        if (!conversation) {
            return;
        }
        if (conversation.status === ChatStatuses.READY) {
            setIsReady(true);
        } else {
            conversation.on(ChatEvents.READY, () => {
                setIsReady(true);
            });
        }
    }, [conversation]);

    const returnName = (object: any) => {
        return object?.isGroup
            ? object.groupName
            : object?.recipient?.firstName ? `${object?.recipient?.firstName} ${object?.recipient?.lastName
                .charAt(0)
                .toUpperCase()}.`
                : ""

    }

    const renderChatBlock = (props: any, isSender: boolean): JSX.Element => {
        return (
            props.currentMessage.text &&
            <MessageBubble
                isSender={isSender}
                isGroup={false}
                currentMessage={props.currentMessage}
                chatMeta={[conversation?.recipient, conversation?.sender]}
            />
        )
    }

    const renderToolBar = (
        props: any,
        textMessage: any,
        handleFileUpload: any,
        handleMessage: any,
        onSend: any,
    ) => {
        return (
            <div className="bottom-tool-bar">
                <div className="chat-input-area">

                    <div className="chat-input" style={{ boxShadow: "0 10px 10px 0 #0000000d" }}>
                        <input
                            {...props}
                            type="text"
                            className="form-control"
                            placeholder="Type a message"
                            composerHeight={345}
                            maxComposerHeight={500}
                            isTyping={true}
                            onChange={(e): void => {
                                handleMessage(e);
                            }}
                            value={textMessage}
                            onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>): void => {
                                if (event.key === "Enter") {
                                    onSend()
                                }
                            }}
                        />
                    </div>
                    <div
                        className="camera-icon-block"
                        style={{ marginLeft: "10px", backgroundColor: colors.darkMango }}
                    >
                        <SendSvgIcon
                            iconColor={"white"}
                            height="25px"
                            width="25px"
                            onClick={(): Promise<void> => onSend()}
                        />
                    </div>
                </div>
            </div>
        )
    }

    const handleAttachmentUpload = (event: any) => {
        const file = event.target.files[0]
        setAttachmentObj(file)
        event.target.value = ""

    }

    return ReactDOM.createPortal(
        <div className="chat-container">
            <div
                style={{
                    height: "100%",
                    display: "block",
                }}
            >
                <div className="tool-bar">
                    <div className="message-card-avatar">
                        <img
                            style={{ height: "inherit", width: "inherit" }}
                            src={conversation?.recipient?.profilePicture || defaultAvatar}
                            alt=""
                            onError={(ev) => { ev.currentTarget.src = defaultAvatar }}
                        />
                    </div>
                    <div
                        style={{
                            display: "flex",
                            width: "88%",
                            justifyContent: "space-between",
                        }}
                    >
                        <div style={{ width: "210px" }} className="user-contain">
                            <div className="user-name">{returnName(conversation)}</div>
                        </div>
                    </div>
                </div>

                <div style={{ height: "calc(100% - 90px)" }} className="messages-container-body">
                    <div
                        style={{
                            margin: "auto",

                            height: "calc(100% - 140px)",
                            position: "absolute",
                            width: "100%",
                        }}
                        className="messages-container-body-inner"
                    >
                        <ChatWindow
                            renderBubble={renderChatBlock}
                            renderBottomToolBar={renderToolBar}
                            conversation={conversation}
                            chatType="group-chats"
                        />
                    </div>
                </div>
            </div>
        </div>
        , document.getElementById("conversation-block") as HTMLCanvasElement)
}
export default UserConversationBlockPortal
