import React, { Component } from "react"

interface Props {
    history: any
}

class NotFound extends Component<Props> {
    render(): JSX.Element {
        this.props.history.push("/")

        return <div></div>
    }
}

export default NotFound
