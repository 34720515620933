import React from "react"
import { CardView } from "../../../../components/card/CardView"
import Button from "../../../../components/button/Button"
import NoSessionImage from "../../../../assets/images/no-session.svg"
interface Iprops {
    titleText: string
    subtitleText: string
    buttonText?: string
    buttonAction?: any
    noButton?: boolean
}

const NoSessionCard = ({
    titleText,
    subtitleText,
    buttonText,
    buttonAction,
    noButton
}: Iprops): JSX.Element => {
    return (
        <div className="no-session-card-container">
            <CardView>
                <div className={`no-results-card ${noButton && ""}`}>
                    <p className="title">{titleText}</p>
                    <div style={{
                        width: "100%",
                        textAlign: "center",
                        marginBottom: "15px",
                    }}>
                        <img src={NoSessionImage} />
                    </div>
                    <p className="subtitle">{subtitleText}</p>
                </div>
            </CardView>
            {!noButton && (<Button isOutlineStyle title={buttonText} clickAction={buttonAction} />)}
        </div>
    )
}

export default NoSessionCard
