import React from "react"
import SignInForm from "./SignInForm"
import { applicationConstant } from "../../../utils/constant/applicationConstant"
import InterfaceConstant from "../../../utils/constant/interfaceConstant"
import OnBoardingCard from "../../../components/card/OnBoardingCard"
import ToastNotification from "../../../components/modal/ToastNotification"
import { toastConstant } from "../../../store/constant"
import { useSelector, useDispatch } from "react-redux"
import OnboardingBackground from "../../../components/on-boarding/onboarding-background/OnboardingBackground"

const signInLabels: InterfaceConstant.IappLabels = applicationConstant.appLabels
const SignInPage = (): JSX.Element => {
    const dispatch = useDispatch()
    const { message, show, isSuccess } = useSelector((state: any) => state.toast)
    const renderSignInFrom = (): JSX.Element => {
        return <SignInForm />
    }

    return (
        <>
            <OnboardingBackground hiddenXS />
            <div className="login-flow sign-in mobile-bg">
                <div className="login-flow-container">
                    <ToastNotification
                        message={message}
                        isopen={show}
                        isOpenAction={() => dispatch({ type: toastConstant.TOAST_HIDE })}
                        styleClass={isSuccess ? "success-toast" : "error-toast"}
                    />
                    <OnBoardingCard
                        title={signInLabels.GLOBAL_APP_TITLE}
                        naviagtionDetails={signInLabels.GLOBAL_NAVIGATION_DETAILS_SIGN_UP}
                        naviagtionLink={signInLabels.GLOBAL_NAVIGATION_LINK__SIGN_UP}
                        naviagtionText={signInLabels.GLOBAL_NAVIGATION_TEXT__SIGN_UP}
                        renderForm={renderSignInFrom()}
                    />
                </div>
            </div>
        </>
    )
}

export default SignInPage
