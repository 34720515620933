import Modal from "@material-ui/core/Modal"
import React, { useEffect, useState } from "react"
import { CloseIcon } from "../../components/icons/Icons"
import AwesomeSlider from "react-awesome-slider"
import "react-awesome-slider/dist/styles.css"
import "react-awesome-slider/src/styled/open-animation/open-animation.scss"
import dashboard from "../../assets/images/walkthrough/dashboard.png"
import requestsession from "../../assets/images/walkthrough/requestsession.jpg"
import session from "../../assets/images/walkthrough/session.jpg"
import payment from "../../assets/images/walkthrough/payment.jpg"
import search from "../../assets/images/walkthrough/search.png"
import searchMobile from "../../assets/images/walkthrough/mobile/search.png"
import sessionMobile from "../../assets/images/walkthrough/mobile/session.png"
import paymentMobile from "../../assets/images/walkthrough/mobile/payment.png"
import dashbaordMobile from "../../assets/images/walkthrough/mobile/dashboard.png"
import requestsessionMobile from "../../assets/images/walkthrough/mobile/requestsession.png"
import { useSelector } from "react-redux"
import { useMediaQuery} from "react-responsive"
import { updateWalkThroughMobileService, updateWalkThroughService } from "utils/services/userServices"

type WalkthroughSlideData = {
    heading: string,
    headingMobile: string,
    subHeading: string,
    subHeadingMobile: string,
    imageUrlWeb: string,
    imageUrlMobile: string,
    description?: string,
    descriptionMobile?: string
}

// eslint-disable-next-line no-undef
const Walkthrough = (): JSX.Element => {

    const [open, setOpen] = useState<boolean>(false)
    const { userAuthData } = useSelector((state: any) => state.auth)
    const [InnerWidth] = useState(window.innerWidth)
    const isUnder768 = useMediaQuery({
        query: '(max-device-width: 767px)'
    });
    const walkthroughData = [
        {
            heading: "How to Book Session & Use the Calendar",
            headingMobile:"How to Book Session",
            subHeading: "To book a new session with a Tutor/Advisor  simply click on the 'Book Now' Button. Sessions will appear on your calender.",
            subHeadingMobile:"To book a new session with a Tutor/Advisor simply click on the “Book Now” button. Sessions will appear in your dashboard.",
            imageUrlWeb: dashboard,
            imageUrlMobile: dashbaordMobile
        },
        {
            heading: "How to Search for a Tutor/Advisor",
            headingMobile:"How to Search for a Tutor/Advisor",
            subHeading: `Fill in the applicable filters, click the “Search” button & browse through results to find your perfect match! `,
            subHeadingMobile:`Fill in the applicable filters, click the “Search” button & browse through results to find your perfect match! `,
            imageUrlWeb: search,
            imageUrlMobile: searchMobile
        },
        {
            heading: "Now That I've Found a Tutor/Advisor, How Do I Book a Session?",
            headingMobile:"Now That I've Found a Tutor/Advisor, How Do I Book a Session?",
            subHeading: `Click the "Request Session" button to access the Tutor's/Advisor's availability.`,
            subHeadingMobile: `Click the "Request Session" button to access the Tutor's/Advisor's availability.`,
            imageUrlWeb: requestsession,
            imageUrlMobile: requestsessionMobile
        },
        {
            heading: "Select  Time Slot(s) to Request Session(s)",
            headingMobile: "Select  Time Slot(s) to Request Session(s)",
            subHeading: "Choose subject(s) from the dropdown and select the length and number of sessions you would like to book!",
            subHeadingMobile: "Choose subject(s) from the dropdown and select the length and number of sessions you would like to book!",
            imageUrlWeb: session,
            imageUrlMobile: sessionMobile
        },
        {
            heading: "Payment",
            headingMobile:"Payment",
            subHeading: 'Input your payment information and click "Book Session(s)". Remember, the tutor will need to confirm or change your requested session before it is considered booked.',
            subHeadingMobile: 'Input your payment information and click "Book Session(s)". Remember, the tutor will need to confirm or change your requested session before it is considered booked.',
            description: `*You will only be charged once the Tutor/Advisor confirms your session request!`,
            descriptionMobile: `*You will only be charged once the Tutor/Advisor confirms your session request!`,
            imageUrlWeb: payment,
            imageUrlMobile: paymentMobile
        },
    ]

    const [walkthroughList]: any[] = useState(walkthroughData)


    useEffect(() => {
        let walkthrough; 
        let walkthroughStatus;
        try {
            if (InnerWidth > 761) {
                walkthrough = userAuthData?.webWalkThrough
                walkthroughStatus = localStorage.getItem('WebwalkThrough');
            }
            else {
                walkthrough = userAuthData?.webWalkThroughMobile
                walkthroughStatus = localStorage.getItem('WebwalkThroughMobile');
            }
        }
        catch (e) {
            walkthroughStatus = false;
        }

        if (!walkthrough  && !walkthroughStatus) {
            setOpen(true)
        }

    }, [userAuthData?.webWalkThrough, InnerWidth]);
    const showSteps = () => {
        return Object.keys(walkthroughList).length > 0 ? (
            <AwesomeSlider animation="openAnimation">
                {walkthroughList.map(
                    (item: WalkthroughSlideData, key: number) => (
                        <div key={key}>
                            <h2 className="heading">{isUnder768 ? item.headingMobile : item.heading}</h2>
                            <h5 className="subheading">{isUnder768 ? item.subHeadingMobile : item.subHeading}</h5>
                            { (item.description || item.descriptionMobile) && (
                                <h6 className="subheading">{isUnder768 ? item.descriptionMobile : item.description}</h6> 
                            )}
                            <img src={(InnerWidth > 761) ? item.imageUrlWeb : item.imageUrlMobile} alt="" />
                        </div>
                    )
                )}
            </AwesomeSlider>
        ) : (
                <div></div>
            )

    }


    const closeModal = async (): Promise<void> => {
        setOpen(false);

        if (InnerWidth > 761) {
            localStorage.setItem('WebwalkThrough', JSON.stringify(true));
            await updateWalkThroughService(userAuthData.userId, true);
        }
        else {

            localStorage.setItem('WebwalkThroughMobile', JSON.stringify(true));
            await updateWalkThroughMobileService(userAuthData.userId, true);
        }
    }
    return (
        <Modal
            open={open}
            onClose={(): void => {
                closeModal()
            }}
            className="walkthrough custom-modal"
        >
            <div className="custom-modal-body">
                <div className="custom-modal-content">
                    <div className={`close-modal-button`}>
                        <CloseIcon
                            onClick={(): void => {
                                closeModal()
                            }}
                        />
                    </div>
                    {showSteps()}
                    <a href="#">
                        <div> <p className="skipButtonText"><u onClick={() => { closeModal() }}>Skip</u></p>
                        </div></a>
                </div>
            </div>
        </Modal>
    )
}

export default Walkthrough
