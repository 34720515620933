import React from "react";
import { NewBookingRequestsPending } from "./popups/NewBookingRequestsPending";
import SingleSlotBookingRequestedContainer from "./popups/SingleSlotBookingRequestedContainer";
import TutorStdDoesntProcessMyRequestContainer from "./popups/TutorStdDoesntProcessMyRequestContainer";
import TutorWhenStdAcceptSomeOfMyRequestContainer from "./popups/TutorWhenStdAcceptSomeOfMyRequestContainer";
import TutorStdRejectTimeSuggestionContainer from "./popups/TutorStdRejectTimeSuggestionContainer";
import TutorStdAcceptRequestContainer from "./popups/TutorStdAcceptRequestContainer";
import TutorWhenStdAcceptRequestContainer from "./popups/TutorWhenStdAcceptRequestContainer";
import RapidBookingTurnOffContainer from "./popups/RapidBookTurnOffContainer";
import TutorSentRescheduleRequestContainer from './popups/rescheduling/TutorSentRescheduleRequestContainer'

export function TeacherBookingPopupContainer(props: any) {
    return (<div>
        <NewBookingRequestsPending />
        <TutorWhenStdAcceptSomeOfMyRequestContainer />
        <TutorStdRejectTimeSuggestionContainer />
        <SingleSlotBookingRequestedContainer />
        <TutorStdDoesntProcessMyRequestContainer />
        <TutorStdAcceptRequestContainer />
        <TutorWhenStdAcceptRequestContainer />
        <RapidBookingTurnOffContainer />
        <TutorSentRescheduleRequestContainer />
    </div>)
}