import authReducer from "./authReducer"
import modalReducer from "./modalReducer"
import { combineReducers } from "redux"
import toastReducer from "./toastReducer"
import buttonReducer from "./buttonReducer"
import confirmEmailReducer from "./confirmEmailReducer"
import signupReducer from "./signupReducer"
import tutorApplicationInfoReducer from "./tutor-signup-reducers/tutorApplicationInfoReducer"
import tutorEducationReducer from "./tutor-signup-reducers/tutorEducationReducer"
import tutorMyprofileReducer from "./tutor-signup-reducers/tutorMyprofileReducer"
import tutorAvailabilityReducer from "./tutor-signup-reducers/tutorAvailabilityReducer"
import tutorCancellationPolicyReducer from "./tutor-signup-reducers/tutorCancellationPolicyReducer"
import tutorRateReducer from "./tutor-signup-reducers/tutorRateReducer"
import tutorReviewReducer from "./tutor-signup-reducers/tutorReviewReducer"
import bookingReducer from "./booking-reducers/bookingReducer"
import epicBoardReducer from "./epicBoardReducer"
import lessonReducer from "./lessonReducer"
import sessionReducer from "./sessions-reducers/sessionReducer"
import messageReducer from "./message-reducer/messageReducer"
import tutorPaymentReducer from "./tutor-signup-reducers/tutorPaymentReducer"
import paymentReducer from "./paymentReducer"
import feedBackReducer from "./feedback-reducer/feedbackReducer"
import moduleReducer from "./moduleReducer"
import subscriptionReducer from "./subscriptionReducer"
import tutorSearchReducer from "./tutorSearchReducer"
import tutorReducer from "./tutorReducer"
import paymentHistoryReducer from "./paymentHistoryReducer"
import studentDashboardReducer from "./studentDashboardReducer";
import rapidBookingReducer from "./rapidbookingReducer";
import messageCenterReducer from "./messageCenterReducer";
import reschedulingReducer from "./rescheduling/reschedulingReducer"

const rootReducer = combineReducers({
    auth: authReducer,
    modal: modalReducer,
    confirmEmail: confirmEmailReducer,
    signup: signupReducer,
    toast: toastReducer,
    lessons: lessonReducer,
    buttonReducer: buttonReducer,
    tutorSignUp: tutorApplicationInfoReducer,
    tutorAvailability: tutorAvailabilityReducer,
    tutorEducation: tutorEducationReducer,
    tutorMyprofile: tutorMyprofileReducer,
    tutorCancellationPolicy: tutorCancellationPolicyReducer,
    tutorPaymentReducer: tutorPaymentReducer,
    tutorRate: tutorRateReducer,
    tutorReview: tutorReviewReducer,
    booking: bookingReducer,
    epicBoardReducer: epicBoardReducer,
    session: sessionReducer,
    messageReducer: messageReducer,
    payment: paymentReducer,
    feedback: feedBackReducer,
    modules: moduleReducer,
    subscriptions: subscriptionReducer,
    tutorSearch: tutorSearchReducer,
    tutor: tutorReducer,
    paymentHistory: paymentHistoryReducer,
    studentDashboard: studentDashboardReducer,
    rapidBooking: rapidBookingReducer,
    messageCenter: messageCenterReducer,
    rescheduling: reschedulingReducer
})

export default rootReducer
