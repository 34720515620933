import { tutorSearchConstant } from "../constant"

const defaultWeekday = {
    selected: false,
    className: "my-option",
    selectedClassName: "my-option-selected"
}

export const defaultFilterValues = {
    RATE_FULL_RANGE: [10, 500],
    CURR_UNG_RATE_FULL_RANGE: [10, 35],
    TIME_FULL_RANGE: [0, 24],
    WEEKDAYS: [
        { ...defaultWeekday, text: "S", index: 0, id: "sunday", tooltip: "Sunday" },
        { ...defaultWeekday, text: "M", index: 1, id: "monday", tooltip: "Monday" },
        { ...defaultWeekday, text: "T", index: 2, id: "tuesday", tooltip: "Tuesday" },
        { ...defaultWeekday, text: "W", index: 3, id: "wednesday", tooltip: "Wednesday" },
        { ...defaultWeekday, text: "T", index: 4, id: "thursday", tooltip: "Thursday" },
        { ...defaultWeekday, text: "F", index: 5, id: "friday", tooltip: "Friday" },
        { ...defaultWeekday, text: "S", index: 6, id: "saturday", tooltip: "Saturday" },
    ],
    EDUCATION: { id: "ALL", name: "All", value: 10 },
    TUTOR_NAME: "",
    GOALS_OBJECTIVES: "Both",
    SUBJECTS: [],
    BACKGROUND_CHECK: false,
    RAPID_BOOK: false,
    MY_FAVOURITES: false
}



export const filterInputNames = {
    TUTOR_NAME: "tutorName",
    GOALS_OBJECTIVES: "goalsObjectives",
    RATE_RANGE: "rateRange",
    TIME_RANGE: "timeRange",
    SUBJECTS: "subjects",
    EDUCATION: "education",
    BACKGROUND_CHECK: "backgroundCheck",
    RAPID_BOOK: "rapidBook",
    MY_FAVOURITES: "myFavourites",
    WEEKDAYS: "weekdays"
}

const initialState = {
    isError: false,
    isloading: false,
    errorMessage: "",
    teachers: [],
    showMore: false,
    nextId: undefined,
    filtersUpdated: true,
    filterInputValues: {
        [filterInputNames.TUTOR_NAME]: defaultFilterValues.TUTOR_NAME,
        [filterInputNames.GOALS_OBJECTIVES]: defaultFilterValues.GOALS_OBJECTIVES,
        [filterInputNames.RATE_RANGE]: defaultFilterValues.RATE_FULL_RANGE,
        [filterInputNames.TIME_RANGE]: defaultFilterValues.TIME_FULL_RANGE,
        [filterInputNames.SUBJECTS]: defaultFilterValues.SUBJECTS,
        [filterInputNames.EDUCATION]: defaultFilterValues.EDUCATION,
        [filterInputNames.BACKGROUND_CHECK]: defaultFilterValues.BACKGROUND_CHECK,
        [filterInputNames.RAPID_BOOK]: defaultFilterValues.RAPID_BOOK,
        [filterInputNames.MY_FAVOURITES]: defaultFilterValues.MY_FAVOURITES,
        [filterInputNames.WEEKDAYS]: defaultFilterValues.WEEKDAYS.map((weekday) => {
            return { ...weekday }
        })
    },
    orderBy: [{
        fieldName: "oneToOneSessionRate",
        direction: "asc"
    }]
}

const tutorSearchReducer = (state = initialState, action: any): object => {
    switch (action.type) {
        case tutorSearchConstant.REQUEST_TUTORS:
            return {
                ...state,
                teachers: action.payload?.pagination?.docId ? state.teachers : [],
                isloading: true,
                filterInputValues: action.payload?.pagination?.docId ? state.filterInputValues : { ...action.payload?.filterInputValues },
                orderBy: [...action.payload.orderBy],
                isError: false,
            }
        case tutorSearchConstant.REQUEST_TUTORS_FAILURE:
            return {
                ...state,
                isloading: false,
                isError: true,
            }

        case tutorSearchConstant.REQUEST_TUTORS_SUCCESS:
            const numOfTeachers = action.payload?.teachers?.length
            let showMore = false
            let nextId;
            if (numOfTeachers > action.payload?.limit) {
                showMore = true
                nextId = action.payload?.teachers.pop().userId
            }
            return {
                ...state,
                showMore,
                nextId,
                teachers: [...state.teachers, ...action.payload?.teachers],
                isloading: false,
                filtersUpdated: false,
            }
        case tutorSearchConstant.FILTERS_UPDATED:
            return {
                ...state,
                filtersUpdated: true,
            }
        default:
            return state
    }
}

export default tutorSearchReducer
