import React, { useState, useContext, useEffect } from "react"
import { CalendarIcon, CalendarCancelIcon, HourGlassIcon } from "../../../../components/icons/Icons"
import { applicationConstant } from "../../../../utils/constant/applicationConstant"
import InterfaceConstant from "../../../../utils/constant/interfaceConstant"
import Button from "../../../../components/button/Button"
import MessageAlert from "../../../../components/message-alert/MessageAlert"
import { InputBox } from "../../../../components/input-field/InputFields"
import { navigatetoUrl, navigatetoUrlWithProps, targetForRedirection } from "../../../../utils/helper/helper"
import { useHistory } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { addCardDetails } from "../../../../store/actions/PaymentActions"
import {
    Checkbox,
    Grid,
} from "@material-ui/core"
import { useFormik } from "formik"
import * as Yup from "yup"
import _ from "lodash"
import {
    TutorSearchStoreContext,
    selectedTutorSelector,
} from "../../../../store/tutor-search/store"
import { createNewBookingRequest } from "../../../../utils/services/manageBookingServices"
import AddPaymentModal from "../../../../components/add-card/AddPaymentModal"
import ImageDropDown from "../../../../components/input-field/ImageDropDown"

import TutorReviewsModal from "../../tutor-profile/ui-elements/TutorReviewsModal"
import RatingsComponent from "../../../../components/ratings/RatingsComponent"
import firebase from "firebase"
import PrivacyModal from "./PrivacyModal"
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons"
import moment from "moment"
import { useTranslation } from "react-i18next"
import { externalLinks } from "../../../../utils/constant/externalLinks";
import { useMediaQuery } from "react-responsive";
import { defaultTimeZone } from "../../../../utils/helper/Timezone"
import { updateDefaultTimeZone } from "utils/services/userServices"
import PromoCode, { promoCodeDetailsType } from "../components/PromoCode"
import { PRODUCTS } from "utils/constant/promoCodeConstant";
import { getUpdatedUserMeta } from "utils/services/userServices"
import { PaymentConstant } from "store/constant"
import colors from "assets/colors"
import Wallet from "components/wallet/wallet"


interface User {
    password: string
    card: string
}

const ResumeAndConfirmPage = (): JSX.Element => {
    const { t } = useTranslation();
    const { state } = useContext(TutorSearchStoreContext)
    const { userAuthData } = useSelector((state: any) => state.auth)
    const { payment } = useSelector((state: any) => state)
    const sessionRequests = useSelector((state: any) => state.booking.options.activeSlots)
    const sessionSubjects = useSelector((state: any) => state.booking.options.subject)
    const studentNote = useSelector((state: any) => state.booking.options.studentComment)
    const isRapidBooking = useSelector((state: any) => state.booking.options.rapidBook)
    const [buttonLoading, setButtonLoading] = useState(false)
    const [acceptedTerms, setAcceptedTerms] = useState(false)
    const [acceptedTermsError, setAcceptedTermsError] = useState(false)
    const [receiveMarketingMaterials, setReceiveMarketingMaterials] = useState(true)
    const [summarySessions, setSummarySessions] = useState({
        slots: [],
        totalDuration: 0
    })
    const isDesktopOrLaptop = useMediaQuery({ query: '(min-device-width: 992px)' });
    const [openSnackbar, setOpenSnackbar] = useState(false)
    const [message, setMessage] = useState({
        message: payment.message,
        type: payment.type,
    })
    const [openPasswordSnackBar, setOpenPasswordSnackBar] = useState(false)
    const [privacyModalOpen, setPrivacyModalOpen] = useState(false)
    const [promoCodeDetails, setPromoCodeDetails] = useState<promoCodeDetailsType | undefined>(undefined);
    const [netPrice, setNetPrice] = useState<number>(0);
    const [discount, setDiscount] = useState<number>(0);
    const [applyingPromo, setApplyingPromo] = useState<boolean>(false);


    const routeUrl: InterfaceConstant.IrouteUrl = applicationConstant.routeUrl
    const bookingLabel: InterfaceConstant.IbookingLabel = applicationConstant.bookingLabel
    const signInErrorLabel: InterfaceConstant.IappErrorLabels = applicationConstant.appErrorLabels
    const dispatch = useDispatch()

    const [addPaymentModalOpen, setAddPaymentModalOpen] = useState(false)
    const history = useHistory()
    const cardList = userAuthData.stripeCards ? userAuthData.stripeCards : []
    const [isShowError, setShowError] = useState(false)
    const [password, setPassword] = useState("")
    const [cardOption, setCardOption] = useState({
        error: t('add-a-card'),
        value: cardList[cardList.length - 1],
    })
    const [showDropdown, setShowDropdown] = useState(false)
    const closeDropDown = (status: boolean) => {
        setShowDropdown(status)
    }

    useEffect(() => {
        if (!selectedTutorSelector(state).firstName) {
            navigatetoUrl(history, "/panel/search")
        }
        getUpdatedUserMeta(userAuthData.userId).then(data => {
            dispatch({ type: PaymentConstant.UPDATE_DEFAULT_SOURCE, payload: data?.stripeCustomer?.default_source })
        }).catch(e => e);
    }, []);
    useEffect(() => {
        setCardOption({
            error: t('add-a-card'),
            value: cardList[cardList.length - 1],
        });
    }, [cardList.length])
    useEffect(() => {
        const userId = userAuthData?.userId;
        const userDefaultTimeZone = userAuthData?.defaultTimeZone

        if (userId && !userDefaultTimeZone) {
            updateDefaultTimeZone(userId, defaultTimeZone)
                .catch(e => {
                    console.error("Error while updating student timezone: ", e);
                });
        }
    }, [defaultTimeZone, userAuthData?.userId]);

    const formik = useFormik({
        initialValues: {
            password: "",
            card: "",
        },
        validationSchema: Yup.object().shape({
            password: Yup.string().required(signInErrorLabel.GLOBAL_REQUIRED),
        }),
        onSubmit: async (values: User) => {
            if (!acceptedTerms) {
                !acceptedTerms && setAcceptedTermsError(true)
                return
            }
            if (cardOption.value === undefined ||
                password.length === 0) {
                setShowError(true)
                return
            } else {
                setButtonLoading(true)
                await firebase
                    .auth()
                    .signInWithEmailAndPassword(userAuthData.email, values.password)
                    .then(async () => {
                        const data = {
                            teacherId: selectedTutorSelector(state).userId,
                            studentId: userAuthData.userId,
                            sessionRequests: sessionRequests,
                            studentStripeCardId: cardOption.value.id,
                            studentStripeCustomerId: userAuthData.stripeCustomer.id,
                            subjects: sessionSubjects.map((s: any) => s.name),
                            comment: studentNote || "",
                            rapidBooking: !!isRapidBooking,
                            promoCodeId: promoCodeDetails?.id,
                            cookie :document.cookie
                        }

                        await createNewBookingRequest(data).then((response: any) => {
                            if (response.data.error) {
                                setMessage({ message: response.data.message, type: "error" })
                                setOpenSnackbar(true)
                                return
                            } else {
                                navigatetoUrlWithProps(
                                    history,
                                    "/panel/search/booking-confirmed",
                                    { promoCodeDetails }
                                )
                            }
                        }).catch(console.error)
                        setButtonLoading(false)
                    })
                    .catch(() => {
                        setButtonLoading(false)
                        setOpenPasswordSnackBar(true)
                    })
            }
        },
    })
    const checkBoxChange = (type: string) => {
        if (type === "acceptedTerms") {
            !acceptedTerms && setAcceptedTermsError(false)
            setAcceptedTerms(!acceptedTerms)
        } else {
            setReceiveMarketingMaterials(!receiveMarketingMaterials)
        }
    }
    const [reviewsModalOpen, setReviewsModalOpen] = useState(false)
    const handleCloseModal = () => {
        setReviewsModalOpen(false)
    }
    const handleChange = (e: any) => {
        setPassword(e.target.value)
        formik.setFieldValue("password", e.target.value, true)
    }

    useEffect(() => {
        const totalPrice: number = summarySessions.totalDuration * selectedTutorSelector(state).oneToOneSessionRate;
        let discount = promoCodeDetails?.amount_off ? promoCodeDetails.amount_off / 100 : promoCodeDetails?.percent_off ? totalPrice * promoCodeDetails?.percent_off / 100 : 0;
        discount = discount > totalPrice ? totalPrice : discount;
        const discountedPrice = Number((totalPrice - discount).toFixed(2));
        setNetPrice(discountedPrice);
    }, [summarySessions, promoCodeDetails])

    useEffect(() => {
        const summarySessions: any = {
            slots: [],
            totalDuration: 0
        }
        sessionRequests?.map((session: any) => {
            // dateFormated = "Mon, Nov 30"
            const formatedDate = new Date(session.startTime.slice(-0, -6)).toDateString().slice(-0, -5).replace(" ", ", ")
            const startTime = moment(new Date(session.startTime))
            const endTime = moment(new Date(session.endTime))
            const duration = moment.duration(endTime.diff(startTime))
            let formatedDuration = `${duration.asMinutes()} min`
            if (duration.asMinutes() >= 60) {
                formatedDuration = `${duration.asHours()}h`
            }
            summarySessions?.slots?.push(`${formatedDate}, from ${startTime.format('hh:mm A').replace(" ", "")} to ${endTime.format('hh:mm A').replace(" ", "")} (${formatedDuration})`)
            summarySessions.totalDuration = summarySessions.totalDuration + duration.asHours()

        })

        setSummarySessions(summarySessions)
    }, [sessionRequests, sessionSubjects])

    const Header = (): JSX.Element => {
        return (
            <>
                <MessageAlert
                    openSnackBar={openPasswordSnackBar}
                    setOpenSnackBar={setOpenPasswordSnackBar}
                    type="error"
                    message={`${t('invalid-password')}. ${t('try-again')}`}
                />
                <h2>{bookingLabel.RESUME_TITLE_LEFT}</h2>
                <div className="header d-flex ">
                    <div className="avatar">
                        <img src={selectedTutorSelector(state).profilePic.downloadURL} alt="" />
                    </div>
                    <div className="description">
                        <h3>
                            <span>{bookingLabel.RESUME_HEADER_TUTOR}</span>
                            <span className="tutor-name">
                                {selectedTutorSelector(state).firstName}{" "}
                                {selectedTutorSelector(state).lastName[0]}
                            </span>
                        </h3>
                        <div className="rating d-flex ">
                            <RatingsComponent
                                obtainedRating={parseFloat(selectedTutorSelector(state).avgRating)}
                                iconSize={"15px"}
                            />
                            <p onClick={() => setReviewsModalOpen(true)} style={{ marginLeft: 10 }}>
                                {selectedTutorSelector(state).reviews ? selectedTutorSelector(state).reviews.length : 0} reviews
                            </p>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    const When = (): JSX.Element => {
        return (
            <div className="when">
                <div className="d-flex booking-duration">
                    <div className="duration-info">
                        {HourGlassIcon({
                            height: "35px",
                        })}
                        <span>{t('total-duration', { duration: summarySessions.totalDuration, unit: 'hours' })}</span>
                    </div>
                    {isDesktopOrLaptop ?
                        <Button
                            title={t('button-edit')}
                            clickAction={(): void => navigatetoUrl(history, routeUrl.BOOK_SESSION_PAGE)}
                            isOutlineStyle
                        /> : <div className="session-edit-mob"><span
                            className="edit_button"
                            onClick={() => navigatetoUrl(history, routeUrl.BOOK_SESSION_PAGE)} /></div>}
                </div>
                <div className="d-flex booking-schedule">
                    <div className="d-flex align-items-end">
                        {CalendarIcon({
                            height: "35px",
                        })}
                        <h6>When:</h6>
                    </div>

                    <ul className={(sessionRequests.length > 4) ? "multiAvail availability" : "availability"}>
                        {
                            summarySessions?.slots?.map((session: any) => (
                                <li style={{ fontSize: "" }}>{session}</li>
                            ))
                        }
                    </ul>
                </div>
            </div>
        )
    }

    const Subtotal = (): JSX.Element => {
        return (
            <div>
                <div className="subtotal">
                    <div className="d-flex">
                        <p className="width100">
                            ${selectedTutorSelector(state).oneToOneSessionRate} x{" "}
                            {`${summarySessions.totalDuration} `}hours
                        </p>
                        <p className="width100" style={{ textAlign: "right" }}>
                            <span>{t('total', {
                                preUnit: '$', value: netPrice
                            })}
                            </span>
                            <p className="tcdCut"> Exclusive of 8% Service Charge</p>

                        </p>
                    </div>
                    {promoCodeDetails?.id &&
                        <>
                            <p className="promo-details">
                                Coupon <b>"{promoCodeDetails?.promoCode}"</b> Applied
                            </p>
                            <a href="javascript:;" onClick={() => setPromoCodeDetails(undefined)}>Remove</a>
                        </>
                    }
                </div>
            </div>
        )
    }

    const addStudentCard = (data: any): any => {
        dispatch(addCardDetails(data))
    }

    const Cancelation = (): JSX.Element => {
        return (
            <div className="cancelation d-flex">
                <div className="image">{CalendarCancelIcon({
                    width: "24px",
                    height: "21px",
                })}</div>
                <div className="description">
                    <p>
                        <span> {bookingLabel.RESUME_CANCELATION_POLICY_TITLE}</span>
                        <span>&nbsp;</span>
                        <span>
                            You will receive a credit added to your account if you reschedule/cancel up to 12 hours before your session.
                            Unfortunately, rescheduling/canceling within the 12-hour period will be charged in full.
                            See our full
                            {" "}
                            <a href='https://theclassroomdoor.com/rescheduling-cancellation-policy/' target="cancellation">cancelation policy</a>
                            {" "}
                            for additional details.
                        </span>
                    </p>
                    <p>
                        <a
                            className="navigation"
                            href={externalLinks.CANCELLATION_POLICY}
                            target={targetForRedirection()}
                            rel="noopener noreferrer"
                        >{t('more-details')}</a>
                    </p>
                    <p className="navigation" onClick={() => setPrivacyModalOpen(true)}>{t('term3')}</p>
                </div>
            </div>
        )
    }

    useEffect(() => {
        setMessage({ message: payment.message, type: payment.type })
        payment.message && setAddPaymentModalOpen(false)
    }, [payment])

    const [fieldType, setFieldType] = useState(true)
    const switchFieldType = () => {
        setFieldType(!fieldType)
    }
    return (
        <>
            <AddPaymentModal
                open={addPaymentModalOpen}
                close={(): void => setAddPaymentModalOpen(false)}
                clickAction={(token: any): void =>
                    addStudentCard({ token, customerId: userAuthData.stripeCustomer.id })
                }
                isLoading={payment.isLoading}
            />
            <Grid container className="resume-booking">
                <Grid item xs={12} md={12} lg={6} xl={6}>
                    <div className="section white-section">
                        <Header />
                        <When />
                        <PromoCode product={PRODUCTS.BOOKING} setPromoCodeDetails={setPromoCodeDetails} promoCodeDetails={promoCodeDetails} setApplying={setApplyingPromo} />
                        <Subtotal />
                        <Cancelation />
                    </div>
                </Grid>
                <Grid item xs={12} md={12} lg={6} xl={6}>
                    <div className="section gray-section">
                        <form onSubmit={formik.handleSubmit}>
                            <div className="header">
                                <h2>{bookingLabel.RESUME_TITLE_RIGHT}</h2>
                                <Wallet/>
                                <ImageDropDown
                                    title={t('pay-with')}
                                    showDropdown={showDropdown}
                                    optionsList={[...cardList]?.reverse()}
                                    closeDropDownAction={closeDropDown}
                                    fieldDetails={cardOption ? cardOption : null}
                                    setProps={setCardOption}
                                    error={cardOption.value === undefined ? isShowError : false}
                                    placeholder={t('Add-Card-Details')}
                                    setAddPaymentModalOpen={setAddPaymentModalOpen}
                                />
                                <div className="password-section">
                                    <p className="input-label-p">
                                        {bookingLabel.RESUME_PASSWORD_LABEL}
                                    </p>
                                    <InputBox
                                        type={fieldType ? "password" : "text"}
                                        className={"resume-page-input"}
                                        title={
                                            bookingLabel.RESUME_PASSWORD_SUBLABEL +
                                            userAuthData.email
                                        }
                                        placeholder={bookingLabel.RESUME_PASSWORD_PLACEHOLDER}
                                        inputProps={{
                                            //autoComplete: "current-password",
                                            name: "password",
                                            value: password,
                                            onChange: handleChange,
                                            onBlur: formik.handleBlur,
                                        }}
                                        error={
                                            formik.touched.password && formik.errors.password
                                                ? formik.errors.password
                                                : null
                                        }
                                        iconProps={{

                                            onClick: () => switchFieldType(),
                                            style: { color: colors.darkMango },
                                            icon: fieldType ? faEye : faEyeSlash,
                                        }}
                                    />
                                </div>

                            </div>
                            <div className="tcd-footer">

                                <p className="tcd-p">
                                    <p className="chargePolicy">{t('booking-charges-policy')}</p>
                                    <div className="checkbox-wrapper">
                                        <Checkbox
                                            checked={acceptedTerms}
                                            onChange={() => checkBoxChange("acceptedTerms")}
                                            name="acceptedTerms"
                                            color="secondary"

                                        />
                                        <span className="checkbox-label">
                                            {t('term1')}
                                            {" "}
                                            <a
                                                className="navigation"
                                                href={externalLinks.TERMS_OF_USE}
                                                target={targetForRedirection()}
                                                rel="noopener noreferrer"
                                            >
                                                {t('term2')}
                                            </a>
                                            ,{" "}
                                            <p className="navigation" onClick={() => setPrivacyModalOpen(true)}>
                                                {t('term3')}
                                            </p>
                                            {" "}
                                            {t('term5')}

                                            .
                                        </span>
                                        <small
                                            style={{
                                                display: acceptedTermsError ? "block" : "none",
                                                color: "red",
                                                marginLeft: 15,
                                            }}
                                        >
                                            {t('accept-this')}
                                        </small>
                                    </div>
                                </p>

                                <p>
                                    <div className="checkbox-wrapper">
                                        <Checkbox
                                            checked={receiveMarketingMaterials}
                                            onChange={() => checkBoxChange("receiveMarketingMaterials")}
                                            name="receiveMarketingMaterials"
                                            color="secondary"
                                        />
                                        <span className="checkbox-label">
                                            {t('receive-marketing-material')}
                                        </span>
                                    </div>
                                </p>

                                <Button
                                    title={bookingLabel.RESUME_PAGE_BUTTON}
                                    clickAction={undefined}
                                    isloading={buttonLoading}
                                    isDisabled={buttonLoading || applyingPromo}
                                />
                            </div>
                        </form>
                    </div>
                </Grid>
                <MessageAlert
                    openSnackBar={openSnackbar}
                    setOpenSnackBar={setOpenSnackbar}
                    message={message.message}
                    type={message.type}
                />
            </Grid>
            <TutorReviewsModal
                open={reviewsModalOpen}
                handleCloseModal={(): void => handleCloseModal()}
            />
            <PrivacyModal open={privacyModalOpen} close={() => setPrivacyModalOpen(false)} />
        </>
    )
}

export default ResumeAndConfirmPage