import React, { useEffect, useState } from "react";
import { BookingPending, useBookingPending } from "pendings";
import { BOOKING_REQUEST_STATUS_CODES } from "store/reducers/sessions-reducers/sessionReducer";
import Modal from "components/modal_/Modal";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { filterNotHeldBookings } from "utils/helper/session-utils";
import colors from "assets/colors";

export function NewBookingRequestsPending(props: any) {
    const { t } = useTranslation(["booking"]);
    const bookingPending: BookingPending = useBookingPending();
    const [noOfBookings, setNoOfBookings] = useState(0);
    const [isOnDashboard, setIsOnDashboard] = useState(false);
    const [isShown, setIsShown] = useState(false);
    const history = useHistory();
    const location = useLocation();
    useEffect(() => {
        setIsOnDashboard(location.pathname === "/panel/dashboard")
    }, [location]);
    //write its logic in this effect
    useEffect(() => {
        isOnDashboard && bookingPending.ref.once("value").then((snapshot) => {
            if (!snapshot.exists()) {
                return;
            }
            const bookings = Object.values(snapshot.val()).map((b: any) => b.data);
            const notHeldBookings = filterNotHeldBookings(bookings);
            //get all those bookings which are waiting for teachers response
            const totalPendingBookings = notHeldBookings.filter(b => b.status === BOOKING_REQUEST_STATUS_CODES.WAITING_FOR_TEACHER_CONFIRMATION).length;
            setNoOfBookings(totalPendingBookings);
        }).catch(console.error)
    }, [bookingPending, isOnDashboard]);


    return <Modal
        visible={(noOfBookings > 1 && isOnDashboard) && !isShown}
        title={t("popups.teacher.new_waiting_for_approval")}
        onExit={() => {
            setIsShown(true);
            setNoOfBookings(0)
        }}
        buttons={[{
            title: t('popups.teacher.buttons.goto_my_sessions'),
            clickAction: () => {
                setIsShown(true);
                setNoOfBookings(0);
                history && history.push('/panel/session')
            }
        }]}
    >
        <h2 style={{
            lineHeight: "78px",
            color: colors.darkMango,
            alignContent: "center",
            fontSize: "60px"
        }}>{noOfBookings}</h2>
        <p style={{ color: "#686c80" }}>{t('popups.teacher.new_booking_requests')}</p>
    </Modal>
}
