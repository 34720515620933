import React, { Fragment } from "react"
import { useState } from "react"
import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { Route, Redirect } from "react-router-dom"
import LoaderSvgIcon from "../assets/images/svgIcons/LoaderSvgIcon"
import { loginWithCustomToken } from "../store/actions/userActions"

const MobileRoute = (props: any): JSX.Element => {
    const { component: Component, ...rest } = props
    const [userAuthData, setuserAuthData] = useState<boolean>(false);
    const [redirect, setRedirect] = useState<boolean>(false);
    const [isloading, setIsLoading] = useState<boolean>(true);
    const [token, setToken] = useState<any>(null);
    const dispatch = useDispatch();
    const [timeLeft, setTimeLeft] = useState(20);

    useEffect(() => {
        // exit early when we reach 0
        if (!timeLeft) return;
        // save intervalId to clear the interval when the
        // component re-renders
        const intervalId = setInterval(() => {
            setTimeLeft(timeLeft - 1);
            try {
                const data = localStorage.getItem('UserCustomToken');
                setToken(data)
            }
            catch (e) {
            }
        }, 1000);
        // clear interval on re-render to avoid memory leaks
        return () => clearInterval(intervalId);
        // add timeLeft as a dependency to re-rerun the effect
        // when we update it
    }, [timeLeft]);

    useEffect(() => {
        if (token != null) {
            dispatch(loginWithCustomToken('' + token));
            const id = setTimeout(() => {
                setDataNow();
            }, 10000)
            return () => {
                clearTimeout(id)
            }
        }

    }, [token]);
    const setDataNow = () => {
        try {
            let data = undefined;
            if (props.path == '/mobile/zoom/') {
                data = JSON.parse(localStorage.getItem('vendorData') || '');
            }
            if (props.path == '/panel/lessons/mobile/' || props.path == '/panel/lessons/mobile') {
                data = JSON.parse(localStorage.getItem('lessonData') || '');
            }
            else {
                data = true;
            }
            if (data) {
                setTimeLeft(0);
                setuserAuthData(true);
                setIsLoading(false);
                setRedirect(true);
            }
        }
        catch (e) { }
    }
    if (redirect) {
        return (
            <Route
                {...rest}
                render={(routeProps) =>
                    userAuthData ? (
                        <Fragment>
                            <Component {...routeProps} />
                        </Fragment>
                    ) : (
                        <Fragment>
                            <Redirect
                                to={{
                                    pathname: "/",
                                    state: { from: routeProps.location },
                                }}
                            />
                        </Fragment>
                    )
                }
            />
        )
    }
    else
        return (<div>
            {isloading ? (
                <div className="backDrop">
                    <div className="wrapper">
                        <div className="backgroundWhite"></div>
                        <LoaderSvgIcon />
                    </div>
                </div>
            ) : (<div></div>)}
        </div>)
}
export default MobileRoute
