import React, { useState, Fragment, useEffect } from "react"
import { timestampToDateFormate } from "../../../../utils/helper/date-utils"
import { urlify, arrayEquals, targetForRedirection } from "../../../../utils/helper/helper"
import ChatModalView from "../../../../components/chat-view/ChatModalView"
import { linkIcon as LinkIcon } from "../../../../components/icons/Icons"
import ClipLoader from "react-spinners/ClipLoader"
import DownloadSvgIcon from "../../../../assets/images/svgIcons/DownloadSvgIcon"
import DoubleTickSvgIcon from "../../../../assets/images/svgIcons/DoubleTickSvgIcon"
import SingleTickSvgIcon from "../../../../assets/images/svgIcons/SingleTickSvgIcon"
import { useSelector } from "react-redux"
import colors from "assets/colors"
interface Iprops {
    currentMessage: any
    isSender: boolean
    isGroup: boolean
    chatMeta: any
}
const MessageBubble = ({ currentMessage, isSender, isGroup, chatMeta }: Iprops) => {

    const { userAuthData } = useSelector((state: any) => state.auth)
    const [openModal, setOpenModal] = useState(false)
    const [modalUrl, setModalUrl] = useState("")
    const renderCssClass = isSender ? "chat-bubble" : "receive-chat-bubble"

    const openModalView = (openFlag: boolean, imageMessageUrl: string) => {
        setOpenModal(openFlag)
        setModalUrl(imageMessageUrl)
    }


    const renderSeenDeliveredStatus = (item: any) => {
        if (item.senderId === userAuthData.userId) {
            const { deliveredTo = {}, seenBy = {} } = item
            const isDeliveredToAll = arrayEquals(chatMeta, Object.keys(deliveredTo))
            const isSeenByAll = arrayEquals(chatMeta, Object.keys(seenBy))
            const tickColor = isSeenByAll ? "#3ebfff" : "#686c80"
            if (!isDeliveredToAll && !isSeenByAll) {
                return <SingleTickSvgIcon iconColor="#686c80" height="20px" width="20px" />
            }
            return <DoubleTickSvgIcon iconColor={tickColor} height="20px" width="20px" />
        }
    }

    return (
        <Fragment>
            <div>
                <div className={renderCssClass}>
                    {isGroup && !isSender ? (
                        <div className="user-details">{currentMessage.user.name}</div>
                    ) : null}
                    <div
                        className="user-message"
                        dangerouslySetInnerHTML={{ __html: urlify(currentMessage?.text) }}
                    ></div>
                    {currentMessage.attachment ? (
                        <Fragment>
                            {currentMessage.isUploading ? (
                                <div className={"image-loader-container"}>
                                    <div className="image-loader">
                                        <ClipLoader color={colors.darkMango} />
                                    </div>
                                </div>
                            ) : null}
                            <div className="message-attachment">
                                <a
                                    className="attachment-name row"
                                    href={currentMessage?.attachment.uri}
                                    download={currentMessage?.attachment.name}
                                    target={targetForRedirection()}
                                >
                                    <span className="col-3" style={{ margin: "auto" }}>
                                        <LinkIcon
                                            color={colors.darkMango}
                                            style={{ fontSize: "12px", margin: "0px 10px" }}
                                        />
                                    </span>

                                    <span className="col-6" style={{ wordBreak: "break-word" }}>
                                        {currentMessage?.attachment.name}
                                    </span>
                                    <span className="col-3" style={{ margin: "auto" }}>
                                        <DownloadSvgIcon
                                            iconColor="#686c80"
                                            width="24px"
                                            height="24px"
                                        />
                                    </span>
                                </a>
                            </div>
                        </Fragment>
                    ) : null}
                    {currentMessage?.image ? (
                        <div
                            className="image-message"
                            style={{ height: "20vh", width: "20vh", cursor: "pointer" }}
                            onClick={() => {
                                openModalView(true, currentMessage?.image)
                            }}
                        >
                            {currentMessage.isUploading ? (
                                <div className={"image-loader-container"}>
                                    <div className="image-loader">
                                        <ClipLoader color={colors.darkMango} />
                                    </div>
                                </div>
                            ) : null}
                            <img
                                src={currentMessage?.image}
                                alt=""
                                style={{
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "cover",
                                    backgroundPosition: "center",
                                    height: "100%",
                                    width: "100%",
                                }}
                            />
                        </div>
                    ) : null}
                    <div className="message-time">
                        {<span style={currentMessage?.status === 'sending' ?
                            { color: '#686c80', fontWeight: "bold" }
                            :
                            { color: '#262ffa', fontWeight: "bold" }}>{isSender && currentMessage?.status.charAt(0).toUpperCase() + currentMessage?.status.slice(1)}{currentMessage?.status === 'sending' && '...'} </span>}
                        {timestampToDateFormate(currentMessage?.createdAt)}
                    </div>
                </div>
            </div>
            <ChatModalView openModal={openModal} url={modalUrl} openModalView={openModalView} />
        </Fragment>
    )
}

export default MessageBubble
