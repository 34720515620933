import React, { useState, useEffect, Fragment } from "react"
import { OnBoardingHeader } from "../../../components/on-boarding/OnboardingHeader"
import OnboardingBackground from "../../../components/on-boarding/onboarding-background/OnboardingBackground"
import { Row, Col, Container } from "reactstrap"
import NavigationButtons from "../../../components/on-boarding/navigation-buttons/NavigationButtons"
import OnBoardingUpdateSettingNote from "../../../components/on-boarding/on-bording-update-setting-note/OnBoardingUpdateSettingNote"
import { applicationConstant } from "../../../utils/constant/applicationConstant"
import InterfaceConstant from "../../../utils/constant/interfaceConstant"
import { useSelector, useDispatch } from "react-redux"
import { navigatetoUrl } from "../../../utils/helper/helper"
import {
    setTutorRates
} from "../../../store/actions/TutorSignUpActions"
import _ from "lodash"
import ProgressBar from "../../../components/progress-bar/ProgressBar"
import Button from "../../../components/button/Button"
import MessageAlert from "../../../components/message-alert/MessageAlert"
import { InputBox } from "../../../components/input-field/InputFields"
import { useFormik } from "formik"
import * as Yup from "yup"
import { faDollarSign } from "@fortawesome/free-solid-svg-icons"
import colors from "../../../assets/colors"
import { updateTutor } from "utils/helper/firebase"

interface Iprops {
    history: any
    isComponent?: boolean
}
interface Rates {
    individualRate: number
}
const routeUrl: InterfaceConstant.IrouteUrl = applicationConstant.routeUrl
const cancelPolicyLabel: InterfaceConstant.IcancelPolicyLabel =
    applicationConstant.cancelPolicyLabel

const TutorCancellationPolicyPage = (props: Iprops): JSX.Element => {
    const dispatch = useDispatch()
    const { individualRate } = useSelector((state: any) => state.tutorRate)
    const { teacherEducationQualification } = useSelector((state: any) => state.tutorEducation.options)
    const { userAuthData } = useSelector((state: any) => state.auth)
    const [isShowError, setShowError] = useState(false)
    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState({
        message: "",
        type: "",
    })
    const [openSnackBar, setOpenSnackBar] = useState(false)

    const [rates, setRates] = useState({
        individualRate: individualRate
    })

    const navigatePrevPage = (): void => {
        navigatetoUrl(props.history, routeUrl.TUTOR_AVAILABILITY_PAGE)
        return
    }

    const navigateNextPage = async (formikObject: any): Promise<void | boolean> => {
        const validation = await formikObject.validateForm()
        const values = _.some(formikObject.values, (item) => item === "")
        const errors = formikObject.errors
        let valuesError = false
        Object.values(errors).map((value) => (value ? (valuesError = true) : ""))
        if (values || valuesError || !_.isEmpty(validation)) {
            setShowError(true)
            return false
        } else {
            if (isShowError) {
                setShowError(false)
            }
            navigatetoUrl(props.history, routeUrl.TUTOR_MY_PROFILE_PAGE)
        }
    }
    const saveSettings = async (formikObject: any) => {
        await formikObject.validateForm()
        const values = _.some(formikObject.values, (item) => item === "")
        const errors = formikObject.errors
        let valuesError = false
        Object.values(errors).map((value) => (value ? (valuesError = true) : ""))
        if (values || valuesError) {
            setShowError(true)
            setMessage({
                message: "Please fill all fields correctly to continue",
                type: "error",
            })
            setOpenSnackBar(true)
            return false
        } else {
            setLoading(true)
            const data = {
                oneToOneSessionRate: rates.individualRate,
            }
            try {
                await updateTutor(userAuthData.userId, data)
                setLoading(false)
                setMessage({
                    message: "Rates sucessfully updated.",
                    type: "success",
                })
                setOpenSnackBar(true)
            } catch (error) {
                setLoading(false)
                setMessage({
                    message: "An error occurred: " + error,
                    type: "error",
                })
                setOpenSnackBar(true)
            }
        }
    }

    const formik = useFormik({
        initialValues: {
            individualRate: individualRate ? individualRate : "",
        },
        validationSchema: Yup.object().shape({
            individualRate: Yup.number()
                .integer("Individual rate must be an integer")
                .typeError("Individual rate is required and need to be a number")
                .min(10, "Minimum $10 per hour")
                .max(teacherEducationQualification.value === 2 ? 35 : 500, `Maximum ${teacherEducationQualification.value === 2 ? 35 : 500} per hour rate`)
                .required(cancelPolicyLabel.GLOBAL_REQUIRED),
        }),
        onSubmit: (values: Rates) => {
        },
    })

    const handleChange = (
        event: React.ChangeEvent<HTMLInputElement>,
        type: string,
    ): boolean | void => {
        setRates({
            ...rates,
            individualRate: parseFloat(event.target.value),
        })
        formik.setFieldValue("individualRate", parseFloat(event.target.value), true)
    }

    useEffect(() => {
        dispatch(setTutorRates(rates))
    }, [rates])

    return (
        <Fragment>
            <MessageAlert
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
                type={message.type}
                message={message.message}
            />
            {!props.isComponent && (
                <>
                    <OnboardingBackground />
                    <ProgressBar at="66.4" theme="darkMango" />
                </>
            )}

            <form onSubmit={formik.handleSubmit}>
                <div className="tutor-cancellation-policy z-10">
                    <Container>
                        <div>
                            {!props.isComponent && (
                                <OnBoardingHeader
                                    subHeading={cancelPolicyLabel.RATE_CONTENT_TITLE}
                                />
                            )}
                            <Row>
                                <Col lg="6" md="12" sm="12" style={{ margin: "auto" }}>
                                    <InputBox
                                        type={"masked-input"}
                                        mask="999"
                                        className={"policy-page-input"}
                                        title={
                                            cancelPolicyLabel.INDIVIDUAL_RATE_SESSION_DROPDOWN_TITLE
                                        }
                                        placeholder={
                                            cancelPolicyLabel.INDIVIDUAL_GROUP_DROPDOWN_PLACEHOLDER
                                        }
                                        inputProps={{
                                            name: "individualRate",
                                            value: parseInt(rates.individualRate),
                                            onChange: (
                                                e: React.ChangeEvent<HTMLInputElement>,
                                            ): boolean | void => handleChange(e, "individualRate"),
                                            onBlur: formik.handleBlur,
                                        }}
                                        error={
                                            formik.touched.individualRate &&
                                                formik.errors.individualRate
                                                ? formik.errors.individualRate
                                                : null
                                        }
                                        iconProps={{
                                            type: "number",
                                            style: {
                                                color: colors.battleshipGrey,
                                                zIndex: 0,
                                                pointerEvents: "none",
                                            },
                                            icon: faDollarSign,
                                        }}
                                    />
                                </Col>
                                <Col md="12" sm="12" style={{ textAlign: "center" }}>
                                    <div className="details">
                                        {cancelPolicyLabel.NOTE_FOR_SESSION}
                                    </div>
                                    <div className="details">
                                        {cancelPolicyLabel.SUB_NOTE_FOR_SESSION}
                                    </div>
                                </Col>
                            </Row>

                            <div className="cancel-policy">
                                <p className="header"> Cancelation Policy</p>
                                <p className="description">
                                Student cancelations less than 12 hours before the scheduled session will result in the
                                student being charged for the session, and you will still be paid. If the student's
                                cancelation occurs before the 12 hour window, the student will receive a credit to
                                apply to a future session, and you will not be paid for the session. See our full
                                {" "}
                                <a href="https://theclassroomdoor.com/rescheduling-cancellation-policy/" target="cancellation">cancelation policy</a>
                                {" "}
                                for additional details.
                                </p>
                                <p> Note : This policy is subject to change.</p>
                            </div>
                        </div>
                        {!props.isComponent ? (
                            <div className="onboarding-actions">
                                <div className="onboarding-divider" />
                                <OnBoardingUpdateSettingNote />
                                <NavigationButtons
                                    previousClick={navigatePrevPage}
                                    nextClick={() => navigateNextPage(formik)}
                                />
                            </div>
                        ) : (
                            <Button
                                type="submit"
                                title={"Save"}
                                isloading={loading}
                                clickAction={() => saveSettings(formik)}
                                className="centered-button"
                            />
                        )}
                    </Container>
                </div>
            </form>
        </Fragment>
    )
}

export default TutorCancellationPolicyPage
