import firebase from "../../utils/config/firebase"
enum ActionTypes {
    GET_VENDOR_LINK = "GET_VENDOR_LINK",
}

export const joinSessionVendor = async (userType: number, sessionId: string): Promise<any> => {
    const getVendorData = firebase.functions().httpsCallable("sessions")
    const Vendordataresult: any = await getVendorData({
        actionType: ActionTypes.GET_VENDOR_LINK,
        sessionId,
        userType
    })
    const Vendordata = Vendordataresult.data;
     const newData ={
        apiKey: Vendordata.apiKey,
        apiSecret: Vendordata.apiSecret,
        meetingNumber: Vendordata.meetingNumber,
        passWord: Vendordata.passWord,
        signature: Vendordata.signature
     }
    return newData
   
}
