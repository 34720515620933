import { put, all, takeLatest } from "redux-saga/effects"
import { messageConstant } from "../constant"
import { getMessageList } from "../../utils/services/messaingServices"

function* getAllConversations(action: any) {
    try {
        const messageList: any = yield getMessageList()
        yield put({ type: messageConstant.GET_ALL_CONVERSATION_LIST_SUCCESS, messageList })
    } catch (error) {
        yield put({ type: messageConstant.GET_ALL_CONVERSATION_LIST_FAILURE, error })
    }
}

export default all([
    takeLatest(messageConstant.GET_ALL_CONVERSATION_LIST_REQUEST, getAllConversations),
    takeLatest(messageConstant.GET_ALL_CONVERSATION_LIST_SUCCESS_REQUEST, getAllConversations),
])
