/**
 * Teacher can join a session this many seconds
 * before the actual start time of a session
 */
export const TEACHER_GRACE_START = 5 * 60; // in seconds
/**
 * Student can join a session this many seconds
 * before the actual start time of a session
 */
export const STUDENT_GRACE_START = 5 * 60; // in seconds

export const STUDENT_RESCHEDULE_TIME_LIMIT = 12 * 60;

export const TUTOR_RESCHEDULE_TIME_LIMIT = 12 * 60;

export const RESCHEDULE_SESSION_LIMIT_UNIT = 'minutes';

