import React from "react"
import { makeStyles } from "@material-ui/core"
import Tooltip from "@material-ui/core/Tooltip"
import "./range-slider.scss"
import { CustomSlider } from "./ui-elements/CustomSlider"
import colors from "../../assets/colors"

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        paddingLeft: "5px",
        display: "flex",
        flexDirection: "row",
    },
    margin: {
        height: theme.spacing(3),
    },
    popper: {

        "& .MuiTooltip-tooltipPlacementBottom": {
            color: colors.darkMango,
            boxShadow: "none",
            padding: 0,
            marginBottom: 0,
            marginTop: "10px",
            fontSize: "14px",
            fontWeight: 500,
        }
    }
}))
interface ValueComponentProps {
    children: JSX.Element
    open: boolean
    value: number
}

interface Props {
    title: string
    onRangeChange: (updatedRange: number | number[] | any) => void
    range?: Array<number>
    min: number
    max: number
    minPrefix: string
    minSuffix: string
    maxPrefix: string
    maxSuffix: string
    symbol: string
}

const ValueLabelComponent = (props: ValueComponentProps): JSX.Element => {
    const { children, open, value } = props
    const classes = useStyles()
    return (
        <div>
        <Tooltip
            open={true}
            enterTouchDelay={0}
            placement="bottom"
            title={`${_symbol}${value}`}
            classes={{
                popper: classes.popper
            }}
        >
            {children}
        </Tooltip>

        </div>
    )
}

let _symbol = '';

const RangeSlider = (props: Props): JSX.Element => {
    const { title, range, onRangeChange, min, max, minPrefix, maxPrefix, minSuffix, maxSuffix, symbol } = props
    _symbol = symbol;
    const classes = useStyles()
    return (
        <div className="tutor-search-filter">
            <label className="input-label" style={{ marginBottom: "1rem" ,marginTop:"1rem"}}>{title}</label>
            <div className={classes.root} style={{ alignItems: "center" }}>
                <span className="slider-marker" style={{ marginRight: "15px" }}>
                    {minPrefix}{min}{minSuffix}
                </span>
                <CustomSlider
                    max={max}
                    min={min}
                    value={range}
                    valueLabelDisplay="off"
                    defaultValue={[min, max]}
                    onChange={(_: React.ChangeEvent<{}>, value: number[] | number): void =>
                        onRangeChange(value)
                    }
                />
                <span className="slider-marker" style={{ marginLeft: "15px" }}>
                    {maxPrefix}{max}{maxSuffix}
                </span>

            </div>
            <div className="container range ">
                <div className="row">
                     <span className="col leftRange">
                        ${ range ? range[0] : ''}
                     </span>
                     <span className="col rightRange">
                          ${ range ? range[1] : ''}
                </span>
             </div>
        </div>
    </div>
        
    )
}

export default RangeSlider
