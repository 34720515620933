import React from "react";
import Modal from "components/modal_/Modal";
import StudentTutorRejectionAvatar from "components/student-tutor-rejection-avatar/StudentTutorRejectionAvatar"
import './reschedulingPopups.scss';
import Button from "components/button/Button";
import { useTranslation } from 'react-i18next'

interface StudentRejectRescheduleRequest {
    studentPicUrl: string,
    teacherPicUrl: string,
    studentName: string,
    onGoto: () => void,
    isShown: boolean
    onClose: () => void,
    rescheduleDate:string,
    rescheduleTime:string
}
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function StudentRejectRescheduleRequest(props: StudentRejectRescheduleRequest) {
    let studentNameSplitted=props.studentName.split(' ')
    const { t } = useTranslation();
    return <div className="tutor-student-reschedule-modal">
    <Modal
        visible={props.isShown}
        onExit={props.onClose}
    >
        <div className="modal-student-reject-reschedule">
            <div className="imgs_div">
                <StudentTutorRejectionAvatar
                    size="100"
                    student={
                        props.studentPicUrl
                    }
                    tutor={
                        props.teacherPicUrl
                    }
                />
            </div>
            <h2> {studentNameSplitted[0] + " " +  (studentNameSplitted[1] !== undefined  ? studentNameSplitted[1].charAt(0) + "." : "")} Rejected Your Reschedule Request</h2>
            {/* <h4>Test about the account credit.</h4> */}
            <div className="grey_div">
                <h4>{props.rescheduleDate} at {props.rescheduleTime}</h4>
            </div>
            <div className="text-center pt-5">
                    <Button className="reject-reschedule-req-btn" title={t("go-to-my-session")} clickAction={props.onGoto} isOutlineStyle={true} />
                </div>
        </div>
    </Modal>
    </div>
}
