import { useState, useEffect } from 'react';

export function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}



export const screens = {
    "MOBILE": "mobile",
    "IPAD": "ipad",
    "LAPTOP": "laptop",
    "LARGE": "large"
}

export type dimensionsType = {
    dimensions: {
        width: number,
        height: number,
    }
    screen: string
}

export default function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState<dimensionsType>({
        dimensions: {
            width: -1,
            height: -1,
        },
        screen: ""
    });

    useEffect(() => {
        const handleResize = () => {
            const dimensions = getWindowDimensions();
            const screen = dimensions.width <= 414 ? screens.MOBILE :
                dimensions.width <= 768 ? screens.IPAD :
                    dimensions.width <= 1024 ? screens.LAPTOP : screens.LARGE;
            setWindowDimensions({ dimensions, screen });
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}
