import { useEffect, useCallback, useContext } from "react"
import { getTeacherBookingData } from "utils/services/userServices"
import {
    setSelectedTutor
} from "store/tutor-search/actions/actions";
import {
    TutorSearchStoreContext
} from "store/tutor-search/store";
import { setSessionRequest, setActiveSlot, setRapidBooking, setSubject } from "store/actions/BookingActions";
import { useDispatch } from "react-redux";



const useSetTeacher = () => {
    const defaultDispatch = useDispatch();
    const { dispatch: tutorSearchDispatch } = useContext(TutorSearchStoreContext);
    return useCallback(async (teacherId: string) => {
        try {
            return await getTeacherBookingData(teacherId)
            .then((data: any) => data.data)
            .then(data => tutorSearchDispatch(setSelectedTutor.action(data)))
            .then(() => defaultDispatch(setActiveSlot({activeSlots: []})))
            .then(() => defaultDispatch(setSessionRequest({sessionRequests: []})))
            .then(() => defaultDispatch(setRapidBooking({
                rapidBook: false
            })))
            .then(() => defaultDispatch(setSubject({subject: []})))
            .then(() => true);
        } catch (e) {
            console.log("Error while setting selected teacher in useSetTeacher: ", e);
            return false;
        }
    }, []);
}

export default useSetTeacher
