/* eslint-disable @typescript-eslint/no-empty-function */
import React from "react";
import Modal from "components/modal_/Modal";
import StudentTutorAvatar from "components/student-tutor-avatar/StudentTutorAvatar";
import TutorAvatar from "components/tutor-avatar/TutorAvatar";
import StudentBookingRequest from "components/student-booking-request/StudentBookingRequest";
import moment from "moment";
import Button from "components/button/Button";
import {Note} from "components/tips/Note";
import { useIsPhoneOrTablet } from "utils/hooks/useIsPhoneOrTablet";
import { useTranslation } from "react-i18next";

interface TutorStdRapidBookPendingProps {
    pendingRapidBookSession :any,
    onClose:()=> void,
    cancelSessions:()=> void,
    closePopup :React.Dispatch<React.SetStateAction<boolean>>,
    onCross :()=> void
}

export function TutorStdBookingRequestsCancellation(props: TutorStdRapidBookPendingProps) {
    const {pendingRapidBookSession ,cancelSessions} =props;
    const { t } = useTranslation();
    return (
        <div className="accepted-requests-modal">
             <Modal
                visible={true}
                onExit={()=>{props.onCross()}}
             >
                <div  className= {(pendingRapidBookSession?.length >1)?"model_tutoracceptrequest tutorRapidBookMulitple" : "model_tutoracceptrequest tutorRapidBook"}>
                 <div className ="singlePendingRapidBook">
             {( pendingRapidBookSession?.length>1) ?
             (<div>
                <TutorAvatar
                size="100"
                tutor={pendingRapidBookSession[0].teacherProfilePicture}
                />
                <h2>{t('cancel-rapid-book-request')}</h2>  
              </div>)
             :(
                <>
                    <div className="imgs_div">
                        <StudentTutorAvatar
                            size="100"
                            student={pendingRapidBookSession[0].studentProfilePicture}
                            tutor={pendingRapidBookSession[0].teacherProfilePicture}
                        />
                    </div>
                    <h2>{t("you-are-about-to")}</h2>
                </>)  
            }
            <h4>{t("students-are")} <span>{t("less-likely")}</span> {t("if-you-cancel")}</h4>
            <Button className="rapidBookButton1" title={(pendingRapidBookSession?.length >1)? "Keep the session requests" :"Keep the session request"} clickAction={()=>{props.onClose()}} />
            <h4 onClick={cancelSessions}> <u><span>{t("cancel-the")} {(pendingRapidBookSession?.length >1)? "requests" :"request"} </span></u></h4>
            </div>
            </div>
    </Modal>
    </div>
    )
}
