import React, {Fragment} from "react"

interface IProps {
    description: string
}

const DescriptionLabel = ({description}: IProps): JSX.Element => {
    
    return (
        <Fragment>
            <div className="description">
                {description}
            </div>
        </Fragment>
    )
}
export default DescriptionLabel
