import { Modal, Grid } from "@material-ui/core"
import { CardElement } from "@stripe/react-stripe-js"
import { error } from "console"
import React from "react"
import Button from "../../../../components/button/Button"
import { CloseIcon } from "../../../../components/icons/Icons"
import { useTranslation } from "react-i18next"
interface Iprops {
    open: boolean
    close: Function
}

const PrivacyModal = ({ open, close }: Iprops): JSX.Element => {
    const { t } = useTranslation();
    return (
        <Modal
            open={open}
            onClose={(): void => {
                close()
            }}
            className="add-card custom-modal"
        >
            <div className="custom-modal-body">
                <div className="custom-modal-content-p privacy-modal">
                    <div className={`close-modal-button`}>
                        <CloseIcon
                            onClick={(): void => {
                                close()
                            }}
                        />
                    </div>
                    <>
                        <div className="p-5">
                        <h1>{t('term3')}</h1>
                        <p>{t('privacy-modal-description')}</p>
                        </div>
                    </>
                </div>
            </div>
        </Modal>
    )
}

export default PrivacyModal
