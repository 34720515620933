import LandingPage from "pages/onboarding/landing/LandingPage"
import SignUpPage from "pages/onboarding/sign-up/SignUpPage"
import SignInPage from "../pages/onboarding/sign-in/SignInPage"


export const layout = "/inspired"

const InspiredRoutes = [
    {
        path: "",
        name: "Landing Page",
        layout: layout,
        component: LandingPage,
        exact: true
    },
    {
        path: "/sign-in",
        name: "Sign In",
        layout: layout,
        component: SignInPage,
        exact: true
    },
    {
        path: "/sign-up",
        name: "Sign Up",
        layout: layout,
        component: SignUpPage,
        exact: true
    },
    {
        path: "/*",
        name: "Landing Page",
        layout: layout,
        component: LandingPage,
        redirect: true
    },
]

export default InspiredRoutes
