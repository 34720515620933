import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Tooltip from "@material-ui/core/Tooltip"
import { CustomSlider } from "../../../../../components/slider/ui-elements/CustomSlider"
import colors from "../../../../../../src/assets/colors"
import { useTranslation } from "react-i18next"

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        paddingLeft: "5px",
        display: "flex",
        flexDirection: "row",
    },
    margin: {
        height: theme.spacing(3),
    },
    popper: {

        "& .MuiTooltip-tooltipPlacementBottom": {
            color: colors.darkMango,
            boxShadow: "none",
            padding: 0,
            marginBottom: 0,
            marginTop: "10px",
            fontSize: "14px",
            fontWeight: 500
        }
    }
}))
interface ValueComponentProps {
    children: JSX.Element
    open: boolean
    value: number
}

interface Props {
    onRangeChange: (updatedRange: number | number[] | any) => void
    range?: Array<number>
    min: number
    max: number
    format12Hours: boolean
}

const ValueLabelComponent = (props: ValueComponentProps): JSX.Element => {
    const { children, open, value } = props
    let newTime = JSON.parse(JSON.stringify(value))
    newTime = _format12Hours ? tConvert(newTime) : newTime
    const classes = useStyles()
    return (
        <Tooltip
            open={true}
            enterTouchDelay={0}
            placement="bottom"
            title={`${newTime}`}
            classes={{
                popper: classes.popper
            }}
        >
            {children}
        </Tooltip>
    )
}

const tConvert = (time: any) => {
    if (time == 24) {
        return '11PM+'
    } else {
        time = [time]
        time[1] = +time[0] < 12 ? 'AM' : 'PM';
        time[0] = +time[0] % 12 || 12;
        return time.join('');
    }
}

let _format12Hours = false


const TimeFilter = (props: Props): JSX.Element => {
    const { t } = useTranslation();
    const { range, onRangeChange, min, max, format12Hours } = props;
    _format12Hours = format12Hours
    const classes = useStyles()
    return (
        <div className="tutor-search-filter">
            <label className="input-label" style={{ marginBottom: "1rem", marginTop:"1rem" }}>{t("time-of-day")}</label>
            <div className={classes.root} style={{ alignItems: "center" }}>
                <span className="slider-marker" style={{ marginRight: "15px" }}>
                    {format12Hours ? tConvert(min) : min}
                </span>
                <CustomSlider
                    max={max}
                    min={min}
                    value={range}
                    valueLabelDisplay="off"
                    defaultValue={[min, max]}
                    onChange={(_: React.ChangeEvent<{}>, value: number[] | number): void =>
                        onRangeChange(value)
                    }
                />
                <span className="slider-marker" style={{ marginLeft: "15px" }}>
                    {format12Hours ? tConvert(max) : max}
                </span>
            </div>
            <div className="container range ">
                <div className="row">
                     <div className="col leftRange">
                        { range ? tConvert(range[0]) : ''}
                     </div>
                     <div className="col rightRange">
                          { range ? tConvert(range[1]) : ''}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TimeFilter
