import React, { useMemo, useState } from "react"
import Modal from "../modal_/Modal"
import { CardView } from "../card/CardView"
import ReactMarkdown from "react-markdown"
import { useHistory } from "react-router-dom"
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core/styles';
import Button from "components/button/Button"
import { navigatetoUrl } from "utils/helper/helper"


const LessonListItem = ({
    title,
    description,
    lessonSubscriptionExpiryStatus,
    lessonId,
    openLesson,
    index,
}: {
    title: string
    description: string
    lessonSubscriptionExpiryStatus: boolean
    lessonId: string
    openLesson: any
    index: any
}): JSX.Element => {

    const history = useHistory()
    const [showingList, setShowingList] = useState(false)
    const { t } = useTranslation();
    const theme = useTheme()
    const previewDescription = useMemo(() => {
        const splitDescription = (description || "").split("\n\n");
        return splitDescription.slice(0, Math.min(splitDescription.length, 4)).join("\n\n");
    }, [description]);
    const shouldCardClickable = () => {
        return (window.screen.width < theme.breakpoints.values.md)
    }

    const viewLesson = () => {
        {
            lessonSubscriptionExpiryStatus ?
                setShowingList(!showingList)
                :
                openLesson(history, `/panel/lessons/` + lessonId, index)
        }
    };
    return (
        <div className="lesson-list-item">
            <div className="lesson-tile" onClick={() => {
                shouldCardClickable() && viewLesson()
            }}>
                <CardView>
                    <p>
                        <span className="lesson-id p-blue">{title?.split(":")[0]}</span>
                        <span className="lesson-name p-battleship">{title?.split(":")[1]}</span>
                    </p>
                    <div className="view-more">
                        <Modal
                            visible={showingList}
                            onExit={() => {
                                setShowingList(!showingList)
                            }}
                            showAlways={true}
                        >
                            <p className="lesson-preview-label">{t('lesson-preview')}</p>
                            <p className="lesson-preview-title">
                                {title?.split(":")[0]}:
                            </p>
                            <p className="lesson-preview-title">{title?.split(":")[1]}</p>
                            <div className="lesson-description-container">
                                <ReactMarkdown className="lesson-description">
                                    {previewDescription}
                                </ReactMarkdown>
                            </div>
                            <div className="lesson-preview-actions">
                                <Button title="Subscribe Now" clickAction={() => {
                                    navigatetoUrl(history, "/panel/lessons/options")
                                }} />
                            </div>
                        </Modal>
                        <div className="view-lesson">
                            {lessonSubscriptionExpiryStatus ?
                                <div onClick={() => setShowingList(!showingList)} className="modal-link">
                                    <p className="p-12 p-blue">{t('view-more')}</p>
                                </div>
                                :
                                <div onClick={viewLesson} className="modal-link">
                                    <p className="p-12 p-blue">{t('open-lesson')}</p>
                                </div>
                            }
                        </div>
                    </div>
                </CardView>
            </div>
        </div>
    )
}

export default LessonListItem
