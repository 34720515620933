import { TutorSignUpConstant,LogoutConstant } from "../../constant"

const initialState = {
    teacherPaymentDetails: {
        accountHolderName: "",
        accountNumber: "",
        accountType: "",
        bankCurrency: "",
        bankLocation: "",
        bankName: "",
        routingNumber: "",
    },
}

const tutorPaymentReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case TutorSignUpConstant.SET_TUTOR_PAYMENT_DATA:
            return {
              teacherPaymentDetails: action.props.teacherPaymentDetails,
            }
        case LogoutConstant.CLEAR_DATA:
            return initialState     
        default:
            return state
    }
}

export default tutorPaymentReducer
