export const authLogInConstant = {
    LOGIN_REQUEST: "USER_LOGIN_REQUEST",
    LOGIN_SUCCESS: "USER_LOGIN_SUCCESS",
    LOGIN_FAILURE: "USER_LOGIN_FAILURE",
    LOGIN_UPDATED: "USER_LOGIN_UPDATE",
    LOGIN_WITH_CUSTOM_TOKEN: "LOGIN_WITH_CUSTOM_TOKEN"
}

export const authSignUpConstant = {
    SIGNUP_REQUEST: "USER_SIGNUP_REQUEST",
    SIGNUP_SUCCESS: "USER_SIGNUP_SUCCESS",
    SIGNUP_FAILURE: "USER_SIGNUP_FAILURE",
    SIGNUP_INFORMATION: "USER_SIGNUP_INFORMATION",
}
export const lessonsConstant = {
    LESSONS_FIND_REQUEST: "LESSONS_FIND_REQUEST",
    FIND_LESSONS_SUCCESS: "FIND_LESSONS_SUCCESS",
    LESSONS_REQUEST: "LESSONS_FETCH_REQUEST",
    LESSONS_SUCCESS: "LESSONS_FETCH_SUCCESS",
    NAVIGATE_TO_LESSON: "NAVIGATE_TO_LESSON"
}

export const subscriptionConstant = {
    CANCEL_SUBSCRIPTION: "CANCEL_SUBSCRIPTION",
    CANCEL_SUBSCRIPTION_SUCCESS: "CANCEL_SUBSCRIPTION_SUCCESS",
}

export const moduleConstant = {
    MODULE_REQUEST: "MODULE_FETCH_REQUEST",
    MODULE_SUCCESS: "MODULE_FETCH_SUCCESS",
}

export const authLogOutConstant = {
    LOGOUT_REQUEST: "USER_LOGOUT_REQUEST",
    LOGOUT_SUCCESS: "USER_LOGOUT_SUCCESS",
}

export const modalConstant = {
    MODAL_SHOW: "MODAL_SHOW",
    MODAL_HIDE: "MODAL_HIDE",
}

export const buttonConstant = {
    BUTTON_LOADING: "BUTTON_LOADING",
    BUTTON_LOADED: "BUTTON_LOADED",
}

export const tutorSearchConstant = {
    REQUEST_TUTORS: "REQUEST_TUTORS",
    REQUEST_TUTORS_SUCCESS: "REQUEST_TUTORS_SUCCESS",
    FILTERS_UPDATED: "FILTERS_UPDATED",
    REQUEST_TUTORS_FAILURE: "REQUEST_TUTORS_FAILURE",

}
export const tutorConstant = {
    REQUEST_TUTOR: "REQUEST_TUTOR",
    REQUEST_TUTOR_SUCCESS: "REQUEST_TUTOR_SUCCESS",
    REQUEST_TUTOR_FAILURE: "REQUEST_TUTOR_FAILURE",

}

export const forgotConstant = {
    FORGOT_REQUEST: "USER_FORGOT_REQUEST",
    FORGOT_SUCCESS: "USER_FORGOT_SUCCESS",
    FORGOT_FAILURE: "USER_FORGOT_FAILURE",
}
export const resetPassword = {
    RESET_REQUEST: "USER_RESET_REQUEST",
}
export const updatepassword = {
    UPDATE_REQUEST: "USER_UPDATE_REQUEST",
    UPDATE_SUCCESS: "USER_UPDATE_SUCCESS",
    UPDATE_FAILURE: "USER_UPDATE_FAILURE",
}

export const toastConstant = {
    TOAST_SHOW: "TOAST_SHOW",
    TOAST_HIDE: "TOAST_HIDE",
}

export const buttonConstants = {
    BUTTON_START: "BUTTON_START_LOADING",
    BUTTON_STOP: "BUTTON_STOP_LOADING",
}

export const confirmEmail = {
    CONFIRM_SUCCESS: "EMAIL_CONFIRM_SUCCESS",
    CONFIRM_FAILURE: "EMAIL_CONFIRM_FAILURE",
}

export const TutorSignUpConstant = {
    SET_TUTOR_DATA_REQUEST: "SET_TUTOR_DATA_REQUEST",
    SET_MY_PROFILE_TUTOR_DATA: "SET_MY_PROFILE_TUTOR_DATA",
    SET_TUTOR_DATA_SUCCESS: "SET_TUTOR_DATA_SUCCESS",
    TUTOR_SIGNUP_REQUEST: "TUTOR_SIGNUP_REQUEST",
    TUTOR_SIGNUP_SUCCESS: "TUTOR_SIGNUP_SUCCESS",
    TUTOR_SIGNUP_FAILURE: "TUTOR_SIGNUP_FAILURE",
    SET_TUTOR_AVAILABILITY_SLOT: "SET_TUTOR_AVAILABILITY_SLOT",
    SET_TUTOR_PAYMENT_DATA: "SET_TUTOR_PAYMENT_DATA",
}


export const LogoutConstant = {
    CLEAR_DATA: "CLEAR_DATA",
}

export const TutorRatesConstant = {
    SET_TUTOR_RATE: "SET_TUTOR_RATE",
}
export const TutorCancelPolicyconstant = {
    SET_CANCEL_POLICY: "SET_CANCEL_POLICY",
}
export const TutorEducationConstant = {
    SET_TUTOR_EDUCATION: "SET_TUTOR_EDUCATION",
    CLEAR_TUTOR_EDUCATION: "CLEAR_TUTOR_EDUCATION",
}
export const BookingSessionsConstant = {
    SET_BOOKING_INFO: "SET_BOOKING_INFO",
    SET_WEEK_INFO: "SET_WEEK_INFO",
    SET_SELECTED_SESSIONS: "SET_SELECTED_SESSIONS",
    SET_SESSION_REQUEST: "SET_SESSION_REQUEST",
    SET_SUBJECT: "SET_SUBJECT",
    SET_TEACHER_INFO: "SET_TEACHER_INFO",
    SET_STUDENT_COMMENTS: "SET_STUDENT_COMMENTS",
    SET_RANGE_VALUES: "SET_RANGE_VALUES",
    SET_NON_AVAILABLE_SLOTS: "SET_NON_AVAILABLE_SLOTS",
    SET_ACTIVE_SLOTS: "SET_ACTIVE_SLOTS",
    SET_RAPID_BOOK: "SET_RAPID_BOOK",
}

export const RapidBookingConstant = {
    GET_RAPID_BOOKING_AVAILABILITY: "GET_RAPID_BOOKING_AVAILABILITY",
    SET_RAPID_BOOKING_AVAILABILITY: "SET_RAPID_BOOKING_AVAILABILITY",
    SET_RAPID_BOOKING_LOADING_STATUS: "SET_RAPID_BOOKING_LOADING_STATUS",
    UPDATE_RAPID_BOOKING_AVAILABILITY: "UPDATE_RAPID_BOOKING_AVAILABILITY"
};

export const EpicBoardConstant = {
    EPIC_BOARD_UPDATE: "EPIC_BOARD_UPDATE",
    EPIC_BOARD_CHANGE_TAB: "EPIC_BOARD_CHANGE_TAB",
    EPIC_BOARD_TUTOR_INFO: "EPIC_BOARD_TUTOR_INFO",
    EPIC_BOARD_UPDATE_SESSION_INFO: "EPIC_BOARD_UPDATE_SESSION_INFO",
}

export const PaymentConstant = {
    ADD_CARD_DETAILS: "ADD_CARD_DETAILS",
    CARD_DETAILS_ADDED: "CARD_DETAILS_ADDED",
    HIDE_TOAST_CONSTANT: "HIDE_TOAST_MESSAGE",
    IS_LOADING: 'IS_LOADING',
    ADD_CARD_FAILED: "CARD_DETAIL_FAILURE",
    GET_LESSONS_PLANS: "GET_LESSONS_PLANS",
    CALL_PAYMENT_METHOD: "CALL_PAYMENT_METHOD",
    SUBSCRIPTION_REQUEST_SUCCESS: "SUBSCRIPTION_REQUEST_SUCCESS",
    REQUEST_GET_LESSONS_PLANS: "REQUEST_GET_LESSONS_PLANS",
    FIND_PLANS_BY_PLAN_NAME: "FIND_PLANS_BY_PLAN_NAME",
    FIND_PLAN_SUCCESS: "FIND_PLAN_SUCCESS",
    FIND_PLAN_FAILURE: "FIND_PLAN_FAILURE",
    ALREADY_SUBSCRIBED: "ALREADY_SUBSCRIBED",
    FETCH_PAYMENT_HISTORY: "FETCH_PAYMENT_HISTORY",
    PAYMENT_HISTORY_REQUEST_SUCCESS: "PAYMENT_HISTORY_REQUEST_SUCCESS",
    DELETE_CARD: "DELETE_CARD",
    DELETE_CARD_SUCCESS: "DELETE_CARD_SUCCESS",
    DELETE_CARD_FAILURE: "DELETE_CARD_FAILURE",
    TOGGLE_ADD_CARD_MODAL: "TOGGLE_ADD_CARD_MODAL",
    TOGGLE_DELETE_CARD_MODAL: "TOGGLE_DELETE_CARD_MODAL",
    HIDE_DELETE_CARD_RESPONSE_MODAL: "HIDE_DELETE_CARD_RESPONSE_MODAL",
    RESET_SUBSCRIBED: "RESET_SUBSCRIBED",
    UPDATE_DEFAULT_SOURCE: "UPDATE_DEFAULT_SOURCE"
}

export const SessionConstant = {
    GET_BOOKING_REQUEST: "GET_BOOKING_REQUEST",
    GET_PENDING_BOOKINGS: "GET_PENDING_BOOKINGS",
    PENDING_BOOKINGS_FETCHED: "PENDING_BOOKINGS_FETCHED",
    GET_UPCOMING_SESSIONS: "GET_UPCOMING_SESSIONS",
    GET_PAST_SESSIONS: "GET_PAST_SESSIONS",
    SET_SUGGESTED_TIME: "SET_SUGGESTED_TIME",
    UPDATE_BOOKING: "UPDATE_BOOKING",
    UPDATE_BOOKING_SLOTS: "UPDATE_BOOKING_SLOTS",
    ACCEPT_BOOKING_REQUEST: "ACCEPT_BOOKING_REQUEST",
    CANCEL_BOOKING_REQUEST: "CANCEL_BOOKING_REQUEST",
    CHANGE_BOOKING_COMMENT: "CHANGE_BOOKING_COMMENT",
    GET_UPCOMING_SESSIONS_N: "GET_UPCOMING_SESSIONS_N",
    RESCHEDULE_SESSION: "RESCHEDULE_SESSION",
    RESCHEDULE_REQUEST_SUCCESS: "RESCHEDULE_REQUEST_SUCCESS",
    RESCHEDULE_REQUEST_FAILURE: "RESCHEDULE_REQUEST_FAILURE",
    RESCHEDULE_REQUEST_SENT: "RESCHEDULE_REQUEST_SENT",
}

export const favoritesConstant = {
    TOGGLE_FAVORITES: "TOGGLE_FAVORITES",
}

export const messageConstant = {
    GET_ALL_CONVERSATION_LIST_REQUEST: "GET_ALL_CONVERSATION_LIST_REQUEST",
    GET_ALL_CONVERSATION_LIST_SUCCESS: "GET_ALL_CONVERSATION_LIST_SUCCESS",
    GET_ALL_CONVERSATION_LIST_SUCCESS_REQUEST: "GET_ALL_CONVERSATION_LIST_SUCCESS_REQUEST",
    GET_ALL_CONVERSATION_LIST_FAILURE: "GET_ALL_CONVERSATION_LIST_FAILURE",
}


export const messageCenterConstant = {
    GET_CONNECTED_PEOPLE: "GET_CONNECTED_PEOPLE",
    GET_CONNECTED_PEOPLE_SUCCESS: "GET_CONNECTED_PEOPLE_SUCCESS",
    SET_UNREAD_COUNT: "SET_UNREAD_COUNT",
    REORDER_CHATS: "REORDER_CHATS",
    SORT_CHATS: "SORT_CHATS",
    UPDATE_CHATS: "UPDATE_CHATS",
}


export const SLOT_STATUSES = {
    REQUESTED: 'REQUESTED',
    SUGGESTED: 'SUGGESTED',
    BOOKED: 'BOOKED',
    HELD: 'HELD',
    CANCELLED: 'CANCELLED',
    ACCEPTED: 'ACCEPTED'
};

export const BOOKING_REQUEST_STATUS_CODES = [
    {},
    { label: "WAITING_FOR_TEACHER_CONFIRMATION", message: "Now waiting for teacher's confirmation" },
    { label: "WAITING_FOR_STUDENT_CONFIRMATION", message: "Now waiting for student's confirmation" },
    {},
    { label: "ACCEPTED", message: "Booking Accepted" },
    {},
    { label: "CANCELLED", message: "Booking Canceled" },
    { label: "REJECTED", message: "Booking Rejected" },
    { label: "PAYMENT_PROCESSING", message: "Processing Payment" },
    { label: "PAYMENT_FAILED", message: "Payment Failed" },
    { label: "CONFIRMED", message: "Booking Confirmed" },
];

export const StudentDashboardConstants = {
    GET_STUDENT_UPCOMING_SESSIONS: "GET_STUDENT_UPCOMING_SESSIONS",
    SET_STUDENT_UPCOMING_SESSIONS: "SET_STUDENT_UPCOMING_SESSIONS",
    GET_STUDENT_TUTORS: "GET_STUDENT_TUTORS",
    SET_STUDENT_TUTORS: "SET_STUDENT_TUTORS",
    GET_STUDENT_ADVISORS: "GET_STUDENT_ADVISORS",
    SET_STUDENT_ADVISORS: "SET_STUDENT_ADVISORS",
}

export const EPICBOARD_SESSION_STATUS_CODES = {
    CREATED: 1,
    STARTED: 2,
    ACTIVE: 3,
    ENDED: 4,
    NO_SHOW: 5,
    CANCELLED: 6,
    REQUEST_REJECTED: 7,
    REQUEST_EXPIRED_AT_STUDENT_SIDE: 8,
    REQUEST_EXPIRED_AT_TEACHER_SIDE: 9,
    BOOKING_REQUEST_REJECTED: 10,
    BOOKING_REQUEST_CANCELLED: 11,
};