import firebase from "firebase"
import { getTimestamp } from "../helper/date-utils"
import _ from "lodash"
import { Observable } from "rxjs"
enum ActionTypes {
    GET_USER_MESSAGES = "GET_USER_MESSAGES",
    CREATE_CHAT = "CREATE_CHAT",
}

export const Member = {
    firstName: '',
    lastName: '',
    profilePic: {},
};

export const sendMessageService = (messageId: string, messages: object, chatType?: any, chatId?: any) => {
    return firebase
        .database()
        .ref(`/chats/${chatId}/conversation`)
        .child(messageId)
        .set(messages)
        .catch(function (error) {
            console.error("Error saving message to Database:", error)
        })
}

const getChatStorageRef = (chatId: string, timestamp: number) => {
    return firebase.storage().ref(`/chats/${chatId}/${timestamp}/${timestamp}`)
}

export const uploadPhoto = async (chatId: string, file: any): Promise<string> => {
    const timestamp: number = getTimestamp()
    const storageRef: any = await getChatStorageRef(chatId, timestamp)
    const uploadTask: any = await storageRef.putString(file, "data_url")
    const url = await uploadTask.ref.getDownloadURL()
    return url
}

const handler = (feed: any) => {
    let value: any
    value = feed.val()
    return value
}

export const getChatConversations = (entriesLength: number, chatId?: any) => {
    let lastQuery: any
    const pageSize = 10
    const entries: number = entriesLength
    let isLoadOlderMessage: boolean
    let messages
    return new Observable((observer) => {
        const ref = firebase.database().ref(`/chats/${chatId}/conversation`)

        if (lastQuery) {
            ref.off("value", handler)
        }
        lastQuery = ref.limitToLast(entries + pageSize)
        lastQuery.on("value", (snapshot: any) => {
            const snapshotVal = _.map(snapshot.val(), (entry) => {
                return entry
            })
            if (snapshotVal.length === entries + pageSize) {
                isLoadOlderMessage = true
            } else {
                isLoadOlderMessage = false
            }
            messages = {
                message: snapshotVal,
                isLoadOlderMessage: isLoadOlderMessage,
            }
            observer.next(messages)
        })
    })
}

export const getMessageList = () => {
    const getMessages = firebase.functions().httpsCallable("messages")
    return getMessages({ actionType: ActionTypes.GET_USER_MESSAGES })
        .then((result: any) => {
            return {
                messages: result.data.messages,
                archived: result.data.archived,
            }
        })
        .catch((err: any) => {
            console.log("getMessaingList Error", err.message)
        })
}

export const createChat = (memberIds: any, groupName: string) => {
    const createChat = firebase.functions().httpsCallable("messages")
    return createChat({ actionType: ActionTypes.CREATE_CHAT, memberIds, groupName })
        .then((result: any) => {
            return result
        })
        .catch((err: any) => {
            console.log("getMessaingList Error", err.message)
        })
}

export const getUsers = () => {
    const getUsersList = firebase.functions().httpsCallable("messages")
    return getUsersList({ actionType: "GET_CONNECTED_PEOPLE" })
        .then((result: any) => {
            return result.data
        })
        .catch((err: any) => {
            console.log("getUsersList Error", err.message)
        })
}

export const archiveChatForUser = async (
    chatId: string,
    setType: boolean,
    userId: string,
) => {
    const archiveByRef = await firebase.database()
        .ref(`chats`)
        .child(chatId)
        .child("meta")
        .child("archivedBy")
        .child(userId)
    await archiveByRef.set(setType)
    return chatId
}

export const deleteChatForUser = async (
    chatId: string,
    userId: string,
) => {
    const archiveByRef = await firebase.database()
        .ref(`chats`)
        .child(chatId)
        .child("meta")
        .child("deletedBy")
        .child(userId)
    await archiveByRef.set(true)
    return chatId
}

export const markLastMessageAsUnread = async (chatId: string, userId: string, messageId: string) => {
    const seenByUserRef = await firebase.database()
        .ref(`chats`)
        .child(chatId)
        .child("conversation")
        .child(messageId)
        .child("seenBy")
        .child(userId)
    await seenByUserRef.set(false)
    return true
}

export const unreadMessageCount = async (userId: string, chatRoomIds: any) => {
    const totalCount = chatRoomIds && chatRoomIds.reduce(async (res: any, chatId: any) => {
        const prevCount = await res
        const chatRef =
            await firebase.database()
                .ref(`chats/${`group-chats`}`)
                .child(chatId)
                .child("conversation")
                .orderByKey()
                .limitToLast(1)
                .once("value")
        const lastMessageVal = chatRef.val() || {}
        const lastMessage: any = Object.values(lastMessageVal)[0] || {}

        const seenBy = lastMessage.seenBy || {}
        const count = seenBy[userId]
            ? 0
            : lastMessage.text || lastMessage.image || lastMessage.attachment
                ? 1
                : 0

        return prevCount + count
    }, Promise.resolve(0))

    return totalCount
}

export const changeMessageStatus = async (
    chatId: string,
    userId: string,
    hasSeen: any,
) => {
    const conversationRef = await firebase.database()
        .ref(`chats/${`group-chats`}`)
        .child(chatId)
        .child("conversation")
    const coversations = await conversationRef
        .orderByChild(`seenBy/${userId}`)

    const snapshot = await coversations.once('value');
    const updates: any = {};

    snapshot.forEach((messageSnapshot: any) => {
        const message = messageSnapshot.val();
        const deliveredTo = message.deliveredTo || {};
        const seenBy = message.seenBy || {};

        if (!deliveredTo.userId) {
            updates[`${messageSnapshot.key}/deliveredTo/${userId}`] = true;
        }
        if (!seenBy.userId && hasSeen) {
            updates[`${messageSnapshot.key}/seenBy/${userId}`] = true;
        }
    });

    if (Object.entries(updates).length > 0) {
        await conversationRef.update(updates);
        return true;
    }
    return false;
};

const isChatExits = (chatId: string) => {
    const chatRef =
        firebase.database().ref(`chats`).child(chatId)
    return chatRef.once("value").then((snap) => snap.exists())
}

export const createRoomChat = async (roomId: string) => {
    const isExistsChat = await isChatExits(roomId)
    if (!isExistsChat) {
        const newChatRef = firebase.database().ref(`chats`).child(roomId)
        const chatId = newChatRef.key
        const chatMeta = {
            id: chatId,
            createdAt: firebase.database.ServerValue.TIMESTAMP,
        }
        await firebase.database().ref(`chats`).child(roomId).child("meta").update(chatMeta)
    }
    return roomId
}

type Ref = {
    parent: Ref,
    key: string,
    root: Ref,
};
const getRefPathRelativeToRoot = (ref: Ref) => {
    let path = ref.key;
    let startRef = ref.parent;
    while (startRef && startRef.key !== ref.root.key) {
        path = `${startRef.key}/${path}`;
        startRef = startRef.parent;
    }
    return path;
};

export const changeWhoIsTyping = (chatType: string, chatId: string, userIds: string) => {
    const whoIsTypingRef: any = firebase.database()
        .ref(`chats`)
        .child(chatId)
        .child("meta")
        .child("whoIsTyping")
    const whoIsTypingKey = getRefPathRelativeToRoot(whoIsTypingRef)
    return firebase.database()
        .ref()
        .update({ [whoIsTypingKey]: userIds })
}


export const getChatMeta$ = (chatType: string, chatId: string) => {
    return new Observable(observer => {
        const ref = firebase.database().ref(`chats`).child(chatId).child('meta')
        ref.on('value', (snapshot: any) => {
            const snapshotVal = snapshot.val() || {};
            observer.next(snapshotVal);
        });
    });
};