/* eslint-disable @typescript-eslint/no-empty-function */
import React from "react";
import Modal from "components/modal_/Modal";
import 'popups/booking/student/popups/StdTutorAcceptRequest.scss';
import Button from "components/button/Button";
import { useTranslation } from "react-i18next";
import AlarmIcon from "assets/images/svgIcons/RapidBookIcon";
import timeclock from "assets/images/timeclock.svg";
interface IrapidBookTurnOff {
isShown :boolean;
onClose :()=> void;
turnOnRapidBook:()=> void;
}


export function RapidBookingTurnOff(props: IrapidBookTurnOff) {
    const { t } = useTranslation();
    return <Modal
        visible={props.isShown}
        onExit={()=>{props.onClose()}}
    >
        <div className="model_tutoracceptrequest singlePendingRapidBook">
           <div className = "rapid-book-turn-off-clock"> 
           <img  className="clock" src={timeclock} alt="" />
            </div>
            <h2>{t("rapid-book-off-automatically")}</h2>
            <h4>{t("rapid-book-off-3h")} </h4>
            <Button className="rapidBookButton1" title={t("turn-on-rapid-book" )} clickAction={()=>{props.turnOnRapidBook()}} />
            <Button  isOutlineStyle={true}  className="rapidBookButton2" title={t("keep-rapid-book-off")} clickAction={()=>{props.onClose()}} />
             </div>

    </Modal>
}

