import React from "react"
import interfaceConstant from "../../../utils/constant/interfaceConstant"

class BookingCalendarSvgIcon extends React.PureComponent<interfaceConstant.SvgIconProps> {
    render(): JSX.Element {
        const { width, height, ...otherProps } = this.props
        return (
            <svg width={width} height={height} viewBox="0 0 34 34" version="1.1" {...otherProps}>
                <g id="Artboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="calendar">
                        <circle
                            id="Oval"
                            fill="#FFFFFF"
                            fillRule="nonzero"
                            cx="24.8789063"
                            cy="13.5351562"
                            r="1.2890625"
                        ></circle>
                        <path
                            d="M27.84375,2.578125 L26.1679688,2.578125 L26.1679688,1.2890625 C26.1679688,0.577113281 25.5908555,0 24.8789062,0 C24.166957,0 23.5898438,0.577113281 23.5898438,1.2890625 L23.5898438,2.578125 L17.7246094,2.578125 L17.7246094,1.2890625 C17.7246094,0.577113281 17.1474961,0 16.4355469,0 C15.7235977,0 15.1464844,0.577113281 15.1464844,1.2890625 L15.1464844,2.578125 L9.34570312,2.578125 L9.34570312,1.2890625 C9.34570312,0.577113281 8.76858984,0 8.05664062,0 C7.34469141,0 6.76757812,0.577113281 6.76757812,1.2890625 L6.76757812,2.578125 L5.15625,2.578125 C2.31309375,2.578125 0,4.89121875 0,7.734375 L0,27.84375 C0,30.6869062 2.31309375,33 5.15625,33 L15.0175781,33 C15.7295273,33 16.3066406,32.4228867 16.3066406,31.7109375 C16.3066406,30.9989883 15.7295273,30.421875 15.0175781,30.421875 L5.15625,30.421875 C3.73467188,30.421875 2.578125,29.2653281 2.578125,27.84375 L2.578125,7.734375 C2.578125,6.31279688 3.73467188,5.15625 5.15625,5.15625 L6.76757812,5.15625 L6.76757812,6.4453125 C6.76757812,7.15726172 7.34469141,7.734375 8.05664062,7.734375 C8.76858984,7.734375 9.34570312,7.15726172 9.34570312,6.4453125 L9.34570312,5.15625 L15.1464844,5.15625 L15.1464844,6.4453125 C15.1464844,7.15726172 15.7235977,7.734375 16.4355469,7.734375 C17.1474961,7.734375 17.7246094,7.15726172 17.7246094,6.4453125 L17.7246094,5.15625 L23.5898438,5.15625 L23.5898438,6.4453125 C23.5898438,7.15726172 24.166957,7.734375 24.8789062,7.734375 C25.5908555,7.734375 26.1679688,7.15726172 26.1679688,6.4453125 L26.1679688,5.15625 L27.84375,5.15625 C29.2653281,5.15625 30.421875,6.31279688 30.421875,7.734375 L30.421875,15.0820312 C30.421875,15.7939805 30.9989883,16.3710938 31.7109375,16.3710938 C32.4228867,16.3710938 33,15.7939805 33,15.0820312 L33,7.734375 C33,4.89121875 30.6869062,2.578125 27.84375,2.578125 Z"
                            id="Path"
                            fill="#FFFFFF"
                            fillRule="nonzero"
                        ></path>
                        <path
                            d="M26.5,19 C22.3644628,19 19,22.3644628 19,26.5 C19,30.6355372 22.3644628,34 26.5,34 C30.6355372,34 34,30.6355372 34,26.5 C34,22.3644628 30.6355372,19 26.5,19 Z"
                            id="Shape"
                            fill="#262FFA"
                        ></path>
                        <circle
                            id="Oval"
                            fill="#FFFFFF"
                            fillRule="nonzero"
                            cx="19.2714844"
                            cy="13.5351562"
                            r="1.2890625"
                        ></circle>
                        <circle
                            id="Oval"
                            fill="#FFFFFF"
                            fillRule="nonzero"
                            cx="13.6640625"
                            cy="19.1425781"
                            r="1.2890625"
                        ></circle>
                        <circle
                            id="Oval"
                            fill="#FFFFFF"
                            fillRule="nonzero"
                            cx="8.05664062"
                            cy="13.5351562"
                            r="1.2890625"
                        ></circle>
                        <circle
                            id="Oval"
                            fill="#FFFFFF"
                            fillRule="nonzero"
                            cx="8.05664062"
                            cy="19.1425781"
                            r="1.2890625"
                        ></circle>
                        <circle
                            id="Oval"
                            fill="#FFFFFF"
                            fillRule="nonzero"
                            cx="8.05664062"
                            cy="24.75"
                            r="1.2890625"
                        ></circle>
                        <circle
                            id="Oval"
                            fill="#FFFFFF"
                            fillRule="nonzero"
                            cx="13.6640625"
                            cy="24.75"
                            r="1.2890625"
                        ></circle>
                        <circle
                            id="Oval"
                            fill="#FFFFFF"
                            fillRule="nonzero"
                            cx="13.6640625"
                            cy="13.5351562"
                            r="1.2890625"
                        ></circle>
                        <path
                            d="M26.5,22 C27.0522847,22 27.5,22.370944 27.5,22.8285266 L27.5,25.499 L30.1714734,25.5 C30.629056,25.5 31,25.9477153 31,26.5 C31,27.0522847 30.629056,27.5 30.1714734,27.5 L27.5,27.499 L27.5,30.1714734 C27.5,30.629056 27.0522847,31 26.5,31 C25.9477153,31 25.5,30.629056 25.5,30.1714734 L25.5,27.499 L22.8285266,27.5 C22.370944,27.5 22,27.0522847 22,26.5 C22,25.9477153 22.370944,25.5 22.8285266,25.5 L25.5,25.499 L25.5,22.8285266 C25.5,22.370944 25.9477153,22 26.5,22 Z"
                            id="Path-2"
                            fill="#FFFFFF"
                            fillRule="nonzero"
                            transform="translate(26.500000, 26.500000) rotate(-90.000000) translate(-26.500000, -26.500000) "
                        ></path>
                    </g>
                </g>
            </svg>
        )
    }
}

export default BookingCalendarSvgIcon
