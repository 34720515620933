import React from "react"
import interfaceConstant from "../../../utils/constant/interfaceConstant"

class PersonSvgIcon extends React.PureComponent<interfaceConstant.SvgIconProps> {
    render(): JSX.Element {
        const { width, height, iconColor, ...otherProps } = this.props
        return (
            <svg width={width} height={height} {...otherProps}>
                <path
                    d="M3.73913043,27.4347826 C3.73913043,28.5652174 2,28.5652174 2,27.4347826 C2,22.2173913 6.26086957,17.9565217 11.4782609,17.9565217 L12.173913,17.9565217 C9.73913043,16.5652174 8.08695652,13.9565217 8.08695652,10.9130435 C8.08695652,6.56521739 11.6521739,3 16,3 C20.4347826,3 24,6.56521739 24,10.9130435 C24,13.9565217 22.2608696,16.5652174 19.826087,17.9565217 L20.5217391,17.9565217 C25.7391304,17.9565217 30,22.2173913 30,27.4347826 C30,28.5652174 28.2608696,28.5652174 28.2608696,27.4347826 C28.2608696,23.173913 24.7826087,19.6956522 20.5217391,19.6956522 L11.4782609,19.6956522 C7.2173913,19.6956522 3.73913043,23.173913 3.73913043,27.4347826 Z M16,4.73913043 C12.6086957,4.73913043 9.82608696,7.52173913 9.82608696,10.9130435 C9.82608696,14.3913043 12.6086957,17.173913 16,17.173913 C19.4782609,17.173913 22.2608696,14.3913043 22.2608696,10.9130435 C22.2608696,7.52173913 19.4782609,4.73913043 16,4.73913043 Z"
                    stroke={iconColor}
                    strokeWidth={0.3}
                    fill={iconColor}
                    fillRule="evenodd"
                />
            </svg>
        )
    }
}

export default PersonSvgIcon
