import React, { useEffect } from "react"
import { Card,Grid } from "@material-ui/core"
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux"
import { fetchPaymentHistory } from "../../../../../../store/actions/paymentHistoryActions"
import LoaderSpinner from "components/loader/LoaderSpinner";



const padZero = (number: number) => {
    return /^\d$/.test(number.toString()) ? "0" + number : number
}

const paymentStatuses = [
    { key: "requires_capture", value: "Pending" },
    { key: "requires_payment_method", value: "Requires Payment Method" },
    { key: "requires_confirmation", value: "Requires Confirmation" },
    { key: "requires_action", value: "Requires Action" },
    { key: "canceled", value: "Cancelled" },
    { key: "succeeded", value: "Completed" },
]


const PaymentHistory = (): JSX.Element => {
    const { history, isLoading, hasMore } = useSelector((state: any) => state.paymentHistory)
    const { stripeCustomer } = useSelector((state: any) => state.auth.userAuthData)
    const dispatch = useDispatch()
    const { t } = useTranslation();
    useEffect(() => {
        loadHistory(true)
    }, [])

    const loadHistory = (refresh: boolean) => {
        dispatch(fetchPaymentHistory({
            customer_id: stripeCustomer.id,
            limit: 10,
            starting_after: hasMore && !refresh ? history[history.length - 1].id : undefined
        }))
    }
    const getStatus = (key: string) => {
        const status = paymentStatuses.find((status) => {
            if (status.key === key) {
                return true
            }
        })
        return status
    }

    return (
        <>
            <table className="payment-history-table">
                <tr>
                    <th>{t("date")}</th>
                    <th>{t("card")}</th>
                    <th>{t("amount")}</th>
                    <th>{t("status")}</th>
                    <th>{t("product")}</th>
                </tr>
                {history.map((payment: any) => {
                    return payment?.charges?.data?.map((charge: any) => {
                        const date = new Date(charge.created * 1000);
                        const status: any = getStatus(payment.status)
                        return (
                            <tr>
                                <td>{ padZero(date.getMonth() + 1) + '/' + padZero(date.getDate()) + '/'  + date.getFullYear()}</td>
                                <td><span className="card-type">{charge.payment_method_details.card.network}</span>  *****{charge.payment_method_details.card.last4}</td>
                                <td>- {t('usd')} {status.key === "succeeded" ? (charge.amount_captured / 100).toFixed(2) : (charge.amount / 100).toFixed(2)}</td>
                                <td>{status.value}</td>
                                <td>{payment.description}</td>
                            </tr>
                        )
                    })
                })}
            </table>
            <div className="payment-history-cards-container">

            {history.map((payment: any) => {
                    return payment?.charges?.data?.map((charge: any) => {
                        const date = new Date(charge.created * 1000);
                        const status: any = getStatus(payment.status)
                        return (
                           <div className="card payment-card p-3">

                               <Grid xs={12} className="d-flex py-1">
                                    <Grid xs={3}>
                                    <b>{t("date")}</b>
                                    </Grid>
                                    <Grid xs={9}>
                                    <span className="payment-grey-text">{padZero(date.getMonth() + 1) + '/' + padZero(date.getDate()) + '/'  + date.getFullYear()}</span>
                                    </Grid>
                               </Grid>
                               <Grid xs={12} className="d-flex py-1">
                                    <Grid xs={3}>
                                    <b>{t("card")}</b>
                                    </Grid>
                                    <Grid xs={9}>
                                    <span className="payment-grey-text"><span className="card-type">{charge.payment_method_details.card.network}</span>  *****{charge.payment_method_details.card.last4}</span>
                                    </Grid>
                               </Grid>
                               <Grid xs={12} className="d-flex py-1">
                                    <Grid xs={3}>
                                    <b>{t("amount")}</b>
                                    </Grid>
                                    <Grid xs={9}>
                                    <span className="payment-grey-text"> - {t('usd')} {status.key === "succeeded" ?  (charge.amount_captured / 100).toFixed(2) : (charge.amount / 100).toFixed(2)}</span>
                                    </Grid>
                               </Grid>
                               <Grid xs={12} className="d-flex py-1">
                                    <Grid xs={3}>
                                    <b>{t("status")}</b>
                                    </Grid>
                                    <Grid xs={9}>
                                    <span className="payment-grey-text">{status.value}</span>
                                    </Grid>
                               </Grid>
                               <Grid xs={12} className="d-flex py-1">
                                    <Grid xs={3}>
                                    <b>{t("product")}</b>
                                    </Grid>
                                    <Grid xs={9}>
                                    <span className="payment-orange-text">{payment.description}</span>
                                    </Grid>
                               </Grid>
                           </div>
                         
                                
                        )
                    })
                })}
           </div>
         
            {isLoading ?
                <LoaderSpinner />
                :
                hasMore && <p className="show-more" onClick={() => loadHistory(false)}>{t("show-more")}</p>
            }
        </>
    )
}

export default PaymentHistory
