import Modal from "@material-ui/core/Modal"
import React, { useCallback, useContext, useEffect, useState } from "react"
import { CloseIcon } from "../../../../components/icons/Icons"
import AwesomeSlider from "react-awesome-slider"
import "react-awesome-slider/dist/styles.css"
import "react-awesome-slider/src/styled/open-animation/open-animation.scss"
import RatingsComponent from "../../../../components/ratings/RatingsComponent"
import defaultAvatar from "../../../../assets/images/default-avatar.png"
import colors from "../../../../assets/colors"
import { FormControl, FormControlLabel, Radio, RadioGroup } from "@material-ui/core"
import { useFormik } from "formik"
import * as Yup from "yup"
import { applicationConstant } from "../../../../utils/constant/applicationConstant"
import InterfaceConstant from "../../../../utils/constant/interfaceConstant"
import { InputBox } from "../../../../components/input-field/InputFields"
import Button from "../../../../components/button/Button"
import ok from "../../../../assets/images/ok.svg"
import { getReviews } from "../../../../utils/helper/firebase"
import {
    selectedTutorSelector,
    TutorSearchStoreContext,
} from "../../../../store/tutor-search/store"
import firebase from "firebase"
import moment from "moment"
import { promises } from "dns"
import { useLocation } from "react-router-dom"

interface Iprops {
    open: boolean
    handleCloseModal: Function
}

interface Report {
    report: string
}

const TutorReviewsModal = (props: Iprops): JSX.Element => {
    const { open, handleCloseModal } = props
    const [step, setStep] = useState(1)
    const [isVisible, setIsVisible] = useState(open);
    const [reportValue, setReportValue] = useState(0)
    const [otherReport, setOtherReport] = useState(false)
    const [otherReportValue, setOtherReportValue] = useState("")
    const [reviewList, setReviewList]: any[] = useState([])
    const appErrorLabels: InterfaceConstant.IappErrorLabels = applicationConstant.appErrorLabels
    const { state } = useContext(TutorSearchStoreContext)
    const tutorInfo = selectedTutorSelector(state)
    const location = useLocation()

    const getTutorReviews = async (): Promise<void> => {
        const reviews: any[] = await getReviews(tutorInfo.userId).then(function (querySnapshot: any) {
            return Promise.all(
                querySnapshot.docs.map(async function (doc: any) {
                    return await firebase
                        .firestore()
                        .collection("users")
                        .doc(doc.data().reviewerId)
                        .get()
                        .then((userDoc: any) => {
                            return {
                                ...doc.data(),
                                id: doc.id,
                                reviewerName: userDoc.data().firstName,
                                reviewerProfilePic: userDoc.data().profilePic && userDoc.data().profilePic.downloadURL
                                    ? userDoc.data().profilePic.downloadURL
                                    : defaultAvatar
                            }
                        })
                })
            )
        })

        setReviewList(reviews.sort((a: any, b: any) => {
            return (b.submittedOn.seconds > a.submittedOn.seconds) ? 1 : -1
        }
        ))
    }


    const getQueryParam = useCallback(
        (name: string) => {
            const urlParams = new URLSearchParams(location.search)
            return urlParams.get(name)
        },
        [location.search],
    )

    useEffect(() => {
        getTutorReviews().catch(console.error)
    }, [])

    useEffect(() => {
        if (localStorage.getItem('platform') || getQueryParam("platform")) {
            setIsVisible(false);
        } else {
            setIsVisible(open);
        }
    }, [open]);

    const handleReportReview = (): void => {
        setStep(2)
    }
    const handleReportValueChange = (e: any): void => {
        setReportValue(e.target.value)
        parseInt(e.target.value) === 7 ? setOtherReport(true) : setOtherReport(false)
    }
    const formik = useFormik({
        initialValues: {
            report: otherReportValue,
        },
        validationSchema: Yup.object().shape({
            report: Yup.string()
                .min(100, appErrorLabels.GLOBAL_MIN_100)
                .max(1000, appErrorLabels.GLOBAL_MAX_1000),
        }),
        onSubmit: () => {
            setStep(3)
        },
    })
    const handleChangeReportTextarea = (e: any): void => {
        formik.setFieldValue("report", e.target.value, true)
        setOtherReportValue(e.target.value)
    }

    const showSteps = () => {
        switch (step) {
            case 1:
                return Object.keys(reviewList).length > 0 ? (
                    <AwesomeSlider animation="openAnimation">
                        {reviewList.map(
                            (item: any, key: number) =>
                                !item.isPrivate && (
                                    <div className="review-card" key={key}>
                                        <div className="review-item">
                                            <div className="header">
                                                <div
                                                    className="avatar"
                                                    style={{
                                                        backgroundImage: `url(${item.reviewerProfilePic})`,
                                                    }}
                                                />

                                                <div className="rating">
                                                    <h3>{item.reviewerName} </h3>
                                                    <RatingsComponent
                                                        fillColor={colors.darkMango}
                                                        obtainedRating={parseFloat(item.rating)}
                                                        iconSize={"23px"}
                                                    />
                                                    <h2>{item.reviewTitle}</h2>
                                                </div>
                                            </div>
                                            <div className="content">
                                                <div className={item.reviewText ? "" : "noContent"}>
                                                    <p>{item.reviewText ? item.reviewText : '"No review available"'}</p>
                                                </div>
                                            </div>
                                            <div className="bottom">
                                                <span>
                                                    Review Date and Time | {moment.unix(item.submittedOn.seconds).format("DD MMM YYYY hh:mm a")}
                                                </span>
                                                <span
                                                    className="navigation"
                                                    onClick={(): void => handleReportReview()}
                                                >
                                                    Report this
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                ),
                        )}
                    </AwesomeSlider>
                ) : (
                    <h2 className="no-reviews">No reviews to display</h2>
                )
            case 2:
                return (
                    <div className="report">
                        <form onSubmit={formik.handleSubmit}>
                            <h2>Report a problem</h2>

                            <FormControl component="fieldset">
                                <RadioGroup
                                    aria-label="gender"
                                    name="gender1"
                                    value={reportValue}
                                    onChange={(e: any) => handleReportValueChange(e)}
                                >
                                    <FormControlLabel
                                        value="1"
                                        control={<Radio />}
                                        label="It’s profane, sexually explicit or contains hate speech."
                                    />
                                    <FormControlLabel
                                        value="2"
                                        control={<Radio />}
                                        label="It describes or promotes participations in illegal activities. "
                                    />
                                    <FormControlLabel
                                        value="3"
                                        control={<Radio />}
                                        label="It’s biased or written by someone with an affiliation to the tutor. "
                                    />
                                    <FormControlLabel
                                        value="4"
                                        control={<Radio />}
                                        label="It’s posted in the wrong profile."
                                    />
                                    <FormControlLabel
                                        value="5"
                                        control={<Radio />}
                                        label="It’s duplicated."
                                    />
                                    <FormControlLabel
                                        value="6"
                                        control={<Radio />}
                                        label="It contains false or misleading information."
                                    />
                                    <FormControlLabel
                                        value="7"
                                        control={<Radio />}
                                        label="I want to report something else"
                                    />
                                </RadioGroup>
                                {otherReport && (
                                    <InputBox
                                        type={"textarea"}
                                        title={"Please tell us more about your concern (required)"}
                                        placeholder=""
                                        inputProps={{
                                            name: "report",
                                            value: formik.values.report,
                                            onChange: (e: any): void =>
                                                handleChangeReportTextarea(e),
                                            onBlur: formik.handleBlur,
                                        }}
                                        error={
                                            formik.touched.report && formik.errors.report
                                                ? formik.errors.report
                                                : null
                                        }
                                    />
                                )}
                            </FormControl>
                            <Button
                                className="centered-button"
                                isDisabled={
                                    reportValue === 0 ||
                                    (otherReport && otherReportValue.length === 0)
                                }
                                clickAction={undefined}
                                title="Submit"
                            />
                        </form>
                    </div>
                )
            case 3:
                return (
                    <div className="title ptb-5">
                        <img src={ok} alt="" />
                        <h3>Your report has been submited. </h3>
                    </div>
                )
            default:
                break
        }
    }
    const closeModal = (): void => {
        setStep(1)
        setReportValue(0)
        setOtherReport(false)
        setOtherReportValue("")
        handleCloseModal()
    }
    return (
        <Modal
            open={isVisible}
            onClose={(): void => {
                closeModal()
            }}
            className="tutor-reviews custom-modal"
        >
            <div className="custom-modal-body">
                <div className="custom-modal-content">
                    <div className={`close-modal-button`}>
                        <CloseIcon
                            onClick={(): void => {
                                closeModal()
                            }}
                        />
                    </div>
                    {showSteps()}
                </div>
            </div>
        </Modal>
    )
}

export default TutorReviewsModal
