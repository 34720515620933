import { useFormik } from "formik"
import React, { useState, useEffect } from "react"
import { regexExpression, appErrorLabels } from "../../../../../../../utils/constant/applicationConstant"
import Button from "../../../../../../../components/button/Button"
import { InputBox } from "../../../../../../../components/input-field/InputFields"
import * as Yup from "yup"
import { Grid } from "@material-ui/core"
import TermsandConditions from "../TermsandConditions"
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import useStepwiseExecution from "../../../../../../../utils/hooks/useStepwiseExecution";
import { stepHandlers, stepMessages } from "../AllStepsAndHandlers";
import { navigatetoUrl } from "utils/helper/helper"
import { useHistory } from "react-router-dom"
import { PermDeviceInformation } from "@material-ui/icons"
import ShareaSale from "components/AffilateMarketing/ShareaSale"

const CARD_ELEMENT_OPTIONS = {
    style: {
        base: {
            color: "#32325d",
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: "antialiased",
            fontSize: "16px",
            "::placeholder": {
                color: "#aab7c4",
            },
        },
        invalid: {
            color: "#fa755a",
            iconColor: "#fa755a",
        },
    },
};
interface Iprops {
    continue: Function
}
const LessonSignUp = ({ plan, dontRenew, promoCodeId, applyingPromo }: { plan: { id: string , unit_amount :number}, dontRenew: boolean, promoCodeId: string | undefined, applyingPromo?: boolean }): JSX.Element => {
    const [isPasswordError, setPasswordError] = useState(false);
    const [formData, setFormData] = useState<any>({});
    const [stripeObject, setStripeObject] = useState<any>(undefined);
    const [subscribing, setSubscribing] = useState(false);
    const [progressMessage, setProgressMessage] = useState("");
    const [progressErrorMessage, setProgressErrorMessage] = useState("");
    const [cardError, setCardError] = useState("")
    const [onCardTouch, setOnCardTouch] = useState(false)
    const elements = useElements();
    const stripe = useStripe();
    const history = useHistory();
    useEffect(() => {
        setStripeObject(stripe);
    }, [stripe]);

    const formik = useFormik({
        initialValues: {
            firstName: "",
            lastName: "",
            email: "",
            confirmEmail: "",
            password: ""
        },
        validationSchema: Yup.object({
            firstName: Yup.string()
                .matches(/^[A-Za-z]+$/, 'English alphabets and .\'- are allowed only')
                .required("Required"),
            lastName: Yup.string()
                .matches(/^[\\a-zA-Zp{L} .'-]*$/, 'Blank space, English alphabets and .\'- are allowed only')
                .required("Required"),
            email: Yup.string()
                .matches(regexExpression.EMAIL_VALIDATION, appErrorLabels.GLOBAL_EMAIL_INVALID)
                .required(appErrorLabels.GLOBAL_REQUIRED),
            confirmEmail: Yup.string()
                .equals([Yup.ref("email")], appErrorLabels.GLOBAL_CONFIRM_EMAIL_INVALID)
                .required(appErrorLabels.GLOBAL_REQUIRED),
            password: Yup.string()
                .min(6, appErrorLabels.GLOBAL_PASSWORD_REGEX_ERROR)
                .max(64, appErrorLabels.GLOBAL_PASSWORD_REGEX_ERROR)
                .required(appErrorLabels.GLOBAL_REQUIRED)
        }),

        onSubmit: async (values: any) => {

            let lastname = values.lastName.trim()
                .split(/ +/)
                .join(" ");
            const nvalues = {
                confirmEmail: values.confirmEmail,
                email: values.email,
                firstName: values.firstName,
                lastName: lastname,
                password: values.password
            }

            if (cardError || !onCardTouch) {
                if (!onCardTouch) {
                    setCardError('Please enter valid card details')
                }

                return
            }
            setSubscribing(true);
            const card = elements?.getElement(CardElement);
            setFormData({
                ...nvalues,
                price: plan.id,
                card
            });
        },
    });
    const {
        currentStep,
        isAllDone,
        isSuccess,
        isCompleted,
        isLoading,
        execute,
        next,
        setSharedState,
        sharedState
    } = useStepwiseExecution(0, stepHandlers);
    useEffect(() => {
        setSharedState((ps: any) => {
            return {
                ...ps,
                formData,
                stripe: stripeObject,
                promoCodeId: promoCodeId
            };
        });
    }, [formData, stripeObject, promoCodeId]); // update shared state whenever form data gets changed

    useEffect(() => {
        if (isSuccess && isCompleted) {
            next();
        }
    }, [isLoading, isSuccess, isCompleted]);
    useEffect(() => {

        if (isAllDone && sharedState?.customToken) {
            navigatetoUrl(history, `/panel/lessons?token=${sharedState?.customToken}`)
        }
    }, [isAllDone, sharedState?.customToken]);
    useEffect(() => {
        subscribing && execute().then(d => {
            if (d?.type === "success") {
                d?.message && setProgressMessage(d?.message);
            } else if (d?.type === "error") {
                d?.message && setProgressErrorMessage(d?.message);
            }
        }).catch(e => {
            setProgressErrorMessage(e.message || e.code || "Unknown Error. Please try again")
            setSubscribing(false);
        });
        isAllDone && setSubscribing(false);
    }, [currentStep, subscribing, isAllDone]);

    useEffect(() => {
        setProgressMessage(stepMessages[currentStep])
    }, [currentStep]);
    const spaceheck = (str: string) => {
        if (!str.replace(/\s/g, '').length) {
            if (str.length === 0) {
                return true
            }
            return false
        }
        return true;

    }
    const handleChange = (e: any, type: string) => {
        setPasswordError(false)
        switch (type) {
            case "password":
                formik.setFieldValue("password", e.target.value, true)
                break;
            case "firstName":
                let valuef = e.target.value.trim()
                if (valuef[0] != '-' && valuef[0] != '.' && valuef[0] != "'") {
                    formik.setFieldValue("firstName", valuef && valuef[0].toUpperCase() + valuef.substring(1), true)
                }
                break;
            case "lastName":
                let value = e.target.value
                if (spaceheck(value)) {
                    if (value[value.length - 1] == ' ' && value[value.length - 2] == ' ') {

                        value = value.trim()
                        value = value + ' '
                    }
                    if (value[0] == ' ') {
                        value = value.trim()

                    }
                    if (value[0] != '-' && value[0] != '.' && value[0] != "'") {
                        formik.setFieldValue("lastName", value && value[0].toUpperCase() + value.substring(1), true)
                    }
                }
                break;
            case "email":
                formik.setFieldValue("email", e.target.value, true)
                break;
            case "confirmEmail":
                formik.setFieldValue("confirmEmail", e.target.value, true)
                break;
            case "cardnumber":

                if (e.error || e.empty) {
                    setCardError(e.error.message)
                } else {
                    setCardError('')
                }

        }
    }
    return (
        <div>

            <form onSubmit={formik.handleSubmit}>

                <div className="password-section">
                    <h4 style={{ color: "white", paddingLeft: "10px" }}> Your Details</h4>
                    <Grid container>
                        <Grid item xs={12} sm={6}>
                            <InputBox
                                type={"text"}
                                className={"resume-page-input signup-name-input"}
                                title={
                                    "First Name"
                                }
                                placeholder={""}
                                inputProps={{
                                    name: "firstName",
                                    value: formik.values.firstName,
                                    onChange: (e: any,) => handleChange(e, "firstName"),
                                    onBlur: formik.handleBlur,
                                }}
                                error={
                                    formik.touched.firstName && formik.errors.firstName
                                        ? formik.errors.firstName
                                        : isPasswordError
                                }
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <InputBox
                                type={"text"}
                                className={"resume-page-input signup-name-input"}
                                title={
                                    "Last Name"
                                }
                                placeholder={""}
                                inputProps={{
                                    name: "lastName",
                                    value: formik.values.lastName,
                                    onChange: (e: any,) => handleChange(e, "lastName"),
                                    onBlur: formik.handleBlur,
                                }}
                                error={
                                    formik.touched.lastName && formik.errors.lastName
                                        ? formik.errors.lastName
                                        : isPasswordError
                                }
                            />
                        </Grid>
                        <Grid item xs={12} style={{ paddingTop: "5px" }}>
                            <InputBox
                                type={"email"}
                                className={"resume-page-input"}
                                title={
                                    "Email"
                                }
                                placeholder={""}
                                inputProps={{
                                    name: "email",
                                    value: formik.values.email,
                                    onChange: (e: any,) => handleChange(e, "email"),
                                    onBlur: formik.handleBlur,
                                }}
                                error={
                                    formik.touched.email && formik.errors.email
                                        ? formik.errors.email
                                        : isPasswordError
                                }
                            />
                            <br />
                            <InputBox
                                type={"email"}
                                className={"resume-page-input"}
                                title={
                                    "Confirm Email"
                                }
                                placeholder={""}
                                inputProps={{
                                    name: "confirmEmail",
                                    value: formik.values.confirmEmail,
                                    onChange: (e: any,) => handleChange(e, "confirmEmail"),
                                    onBlur: formik.handleBlur,
                                }}
                                error={
                                    formik.touched.confirmEmail && formik.errors.confirmEmail
                                        ? formik.errors.confirmEmail
                                        : isPasswordError
                                }
                            />
                        </Grid>
                        <Grid item xs={12} style={{ paddingTop: "5px" }}>
                            <InputBox
                                type={"password"}
                                className={"resume-page-input    password-input"}
                                title={
                                    "Password"
                                }
                                placeholder={""}
                                inputProps={{
                                    name: "password",
                                    value: formik.values.password,
                                    onChange: (e: any,) => handleChange(e, "password"),
                                    onBlur: formik.handleBlur,
                                }}
                                error={
                                    formik.touched.password && formik.errors.password
                                        ? formik.errors.password
                                        : isPasswordError
                                }
                            />

                        </Grid>

                        <div className="card-detail-div" style={{ width: "100%", marginTop: "5%" }}>
                            <h4 style={{ color: "white", paddingLeft: "10px" }}>Card Details</h4>
                            <p style={{ marginBottom: "0.5rem", marginLeft: "10px" }}>Credit card number</p>
                            <div style={{ paddingBottom: "15px" }}>
                                <CardElement className="card-box"
                                    id="card-element"
                                    options={CARD_ELEMENT_OPTIONS}
                                    onChange={(e: any,) => handleChange(e, "cardnumber")}

                                    onFocus={() => {
                                        setOnCardTouch(true)
                                        setCardError('Please enter valid card details')
                                    }}
                                />
                                {
                                    (cardError)
                                        ? <p style={{ color: 'red', textAlign: 'right', marginBottom: "0" }}>{cardError}</p>
                                        : ''
                                }
                            </div>
                        </div>
                    </Grid>
                    <TermsandConditions />
                    <div style={{ textAlign: "center" }}>
                        <Button
                            title={'Confirm and Pay'}
                            clickAction={undefined}
                            isloading={subscribing}
                            isDisabled={subscribing || !stripe || applyingPromo}
                        />
                        <p style={{ color: "white", paddingLeft: "10px" }}>{(subscribing || (isCompleted && isSuccess)) && progressMessage}</p>
                        <p style={{ color: "red", paddingLeft: "10px" }}>{isCompleted && !isSuccess && progressErrorMessage}</p>
                    </div>
                </div>
            </form>
        </div >
    )
}

export default LessonSignUp