import React from "react"
import interfaceConstant from "../../../utils/constant/interfaceConstant"

class MessageSvgIcon extends React.PureComponent<interfaceConstant.SvgIconProps> {
    render(): JSX.Element {
        const { width, height, iconColor, ...otherProps } = this.props
        return (
            <svg width={width} height={height} {...otherProps}>
                <path
                    d="M27.2272727,6 L4.77272727,6 C3.79367703,6 3,6.82959002 3,7.85294118 L3,25.1470588 C3,26.17041 3.79367703,27 4.77272727,27 L27.2272727,27 C28.206323,27 29,26.17041 29,25.1470588 L29,7.85294118 C29,6.82959002 28.206323,6 27.2272727,6 Z M27.2272727,23.8376471 L22.5354545,18.9335294 C22.1862157,18.5933802 21.6419885,18.603417 21.304447,18.9562319 C20.9669054,19.3090468 20.9573032,19.8778997 21.2827273,20.2429412 L25.9745455,25.1470588 L6.02545455,25.1470588 L10.7172727,20.2429412 C11.0426968,19.8778997 11.0330946,19.3090468 10.695553,18.9562319 C10.3580115,18.603417 9.81378426,18.5933802 9.46454545,18.9335294 L4.77272727,23.8376471 L4.77272727,11.9479412 L15.4090909,20.3294118 C15.7242424,20.5764706 16.1575758,20.5764706 16.4727273,20.3294118 L27.2154545,11.9417647 L27.2272727,23.8376471 Z M27.2272727,7.85294118 L27.2272727,9.63176471 L15.9586364,18.4332353 L4.77272727,9.63176471 L4.77272727,7.85294118 L27.2272727,7.85294118 Z"
                    fill={iconColor}
                    fillRule="evenodd"
                />
            </svg>
        )
    }
}

export default MessageSvgIcon
