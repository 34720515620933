import { modalConstant } from "../constant"

const initialState = {
    show: false,
    icon: "",
    title: "",
    text: "",
}

const modalReducer = (state = initialState, action: any): object => {
    switch (action.type) {
        case modalConstant.MODAL_SHOW:
            return {
                show: true,
                icon: action.modalInfo.icon,
                title: action.modalInfo.title,
                text: action.modalInfo.text,
            }
        case modalConstant.MODAL_HIDE:
            return {
                show: false,
                icon: "",
                title: "",
                text: "",
            }
        default:
            return state
    }
}

export default modalReducer
