import React, { useState, useEffect, useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import Button from "../../../components/button/Button"
import RequestForm from "./components/RequestForm"
import Slot from "./components/Slot"
import ToastNotification from "../../../components/modal/ToastNotification"
import { SLOT_STATUSES, toastConstant } from "../../../store/constant"
import { showToast } from "../../../store/actions/toastAction"
import { useTranslation } from "react-i18next"
import { BOOKING_REQUEST_STATUS_CODES } from "../../../store/reducers/sessions-reducers/sessionReducer"
import {
    getFinalBookingActionWithName,
    actionCreatorWithName,
    getCancelActionCreator,
    getCancelActionName,
} from "./FinalBookingAction"
import { DialogTitle } from "@material-ui/core"
export interface SlotType {
    id: string;
    startTime: string;
    endTime: string;
    status: string;
    sessionLength: number;
}
interface Iprops {
    initialSlots: Array<SlotType>;
    slots: Array<SlotType>;
    id: string;
    userType: string;
    creationTime: Date;
    modifiedTime: Date;
    sessionType: string;
    status: number;
    studentId: string;
    studentName: string;
    subjects: string[];
    teacherGroupSessionRate: number;
    teacherHourlyRate: number;
    teacherId: string;
    teacherName: string;
    totalSessionLength: number;
    comment: string;
    buttonLoading?: boolean;
    isRescheduleRequest?: boolean;
}

const RequestListItem = (props: Iprops): any => {
    const { t } = useTranslation(["booking"])
    const [showAlert, setShowAlert] = useState(false)
    const { initialSlots, id, status, studentName, teacherName, comment, buttonLoading, isRescheduleRequest = false } = props
    const [slots, setSlots] = useState(props.slots)
    const [previosSlots] = useState(props.slots)
    const [previousSlotsMap] = useState(
        previosSlots.reduce((m: { [slotId: string]: SlotType }, s) => {
            m[s.id] = s
            return m
        }, {}),
    )
    const [finalBookingAction, setFinalBookingAction] = useState<actionCreatorWithName>()
    const [isLoading, setLoading] = useState(!!buttonLoading)
    const [newComment, setNewComment] = useState("");

    const dispatch = useDispatch()
    const { message, show, isSuccess } = useSelector((state: any) => state.toast)
    useEffect(() => setLoading(false), [props])
    const isStudent = () => {
        const { userType } = props
        return userType === "STUDENT"
    }
    useEffect(() => {
        setFinalBookingAction(getFinalBookingActionWithName(slots, previosSlots, isStudent()))
    }, [slots, isStudent()])

    const canSend = useCallback((): boolean => {
        if (isStudent() === true) {
            //all the slots should not be in SUGGESTED statuse
            return slots.reduce((canUserSend: boolean, slot: SlotType) => {
                const prevSlot = previousSlotsMap[slot.id]
                const isSameWithPrevAndNotFinal =
                    prevSlot?.startTime === slot.startTime &&
                    prevSlot.sessionLength === slot.sessionLength &&
                    [SLOT_STATUSES.REQUESTED, SLOT_STATUSES.SUGGESTED].includes(slot.status)
                return (
                    canUserSend &&
                    ![SLOT_STATUSES.SUGGESTED].includes(slot.status) &&
                    !isSameWithPrevAndNotFinal
                )
            }, true)
        } else {
            //all the slots should not be in REQUESTED statuse
            return slots.reduce((canUserSend: boolean, slot: SlotType) => {
                const prevSlot = previousSlotsMap[slot.id]
                const isSameWithPrevAndNotFinal =
                    prevSlot?.startTime === slot.startTime &&
                    prevSlot.sessionLength === slot.sessionLength &&
                    [SLOT_STATUSES.REQUESTED, SLOT_STATUSES.SUGGESTED].includes(slot.status)
                return (
                    canUserSend &&
                    ![SLOT_STATUSES.REQUESTED].includes(slot.status) &&
                    !isSameWithPrevAndNotFinal
                )
            }, true)
        }
    }, [props.userType, slots])

    const canUserPerformAction = (): boolean => {
        if (isStudent() === true) {
            return status === BOOKING_REQUEST_STATUS_CODES.WAITING_FOR_STUDENT_CONFIRMATION
        } else {
            return status === BOOKING_REQUEST_STATUS_CODES.WAITING_FOR_TEACHER_CONFIRMATION
        }
    }

    const canUserCancel = (): boolean => {
        return true
    }

    const updateSlotInState = (slot: SlotType) => {
        setSlots((slots) => {
            return slots.map((s) => {
                if (s.id === slot.id) {
                    return {
                        ...s,
                        ...slot,
                    }
                }
                return s
            })
        })
    }

    const renderSlotList = (slots: Array<SlotType>, previousSlots: Array<SlotType>) => {
        return slots.map((slot: SlotType, i) => {
            const initialSlot = initialSlots.filter((s) => s.id === slot.id).pop()
            return (
                <Slot
                    {...{
                        slot,
                        previousSlot: previousSlots[i],
                        initialSlot,
                        isStudent: isStudent(),
                        bookingStatus: status,
                        updateSlotInState,
                        isEditable: canUserPerformAction(),
                        isRescheduleRequest
                    }}
                />
            )
        })
    }
    const cancelButtonName = getCancelActionName(isStudent())
    const sendButtonName = finalBookingAction?.name
    return (
        <div className="session-card-expanded">
            <div className="session-request-list">
                <div className="session-request-list-items">
                    {renderSlotList(slots, previosSlots)}
                </div>
            </div>
            {canUserCancel() && (
                <RequestForm
                    {...{
                        comment,
                        userName: isStudent() ? teacherName : studentName,
                        cancelButtonName,
                        sendButtonName,
                        isSendButtonDisabled: isLoading || !canUserPerformAction() || !canSend(),
                        hideSendButton: sendButtonName === cancelButtonName,
                        isCancelButtonDisabled: isLoading,
                        onCancel: (message: string) => {
                            setNewComment(message);
                            setShowAlert(true);
                        },
                        onSend: ({ message }: any) => {
                            if (canSend() === false) {
                                dispatch(
                                    showToast({
                                        show: true,
                                        message: t("session_require_action"),
                                        isSuccess: false,
                                    }),
                                )
                            } else if (finalBookingAction) {
                                setLoading(true)
                                dispatch(
                                    finalBookingAction.actionCreator(id, {
                                        slots,
                                        comment: message,
                                    }),
                                )
                            }
                        },
                        buttonLoading: isLoading,
                    }}
                />
            )}
            <ToastNotification
                message={message}
                isopen={show}
                isOpenAction={() => dispatch({ type: toastConstant.TOAST_HIDE })}
                styleClass={isSuccess ? "success-toast" : "error-toast"}
            />
            <Dialog maxWidth="md" open={showAlert} onClose={() => setShowAlert(false)}>
                <DialogTitle className="text-warning">Warning</DialogTitle>
                <DialogContent>
                    <p>{t("booking_about_to_be", { action: isStudent() ? `cancel` : `reject` })}</p>
                    <p>{t("are_you_sure_to_continue")}</p>
                </DialogContent>
                <DialogActions
                    className="Cancel-modal-btn"
                    style={{ justifyContent: "center", padding: "15px 30px" }}
                >
                    <Button title={t("buttons.no")} clickAction={() => setShowAlert(false)} />
                    <Button
                        title={t("buttons.yes")}
                        clickAction={(e: any) => {
                            setLoading(true)
                            setShowAlert(false)
                            dispatch(
                                getCancelActionCreator()(id, {
                                    slots,
                                    comment: newComment,
                                }),
                            )
                        }}
                    />
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default RequestListItem
