import React from "react"
import InspiredRoutes from "../../../routes/InspiredRoutes"
import { Redirect, Route, Switch } from "react-router-dom"
import LocalRoute from "routes/LocalRoute"



const InspiredPage = (): JSX.Element => {

    const getRoutes = (Routes: any) => {
        return Routes.map((prop: any, key: any) => {
            return (
                prop?.redirect ?
                    <Route path={prop.layout} render={() => <Redirect to={prop.layout} />} />
                    :
                    <LocalRoute exact={prop.exact} path={prop.layout + prop.path} component={prop.component} key={key} />
            )
        });
    }


    return (
        <>
            <Switch>{getRoutes(InspiredRoutes)}</Switch>
        </>
    )
}

export default InspiredPage
