import React, { useEffect } from "react"
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import FormHelperText from "@material-ui/core/FormHelperText"
import FormControl from "@material-ui/core/FormControl"
import Select from "@material-ui/core/Select"
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown"
import { applicationConstant } from "../../utils/constant/applicationConstant"
interface Iprops {
    title?: string
    placeholder: string
    optionsList: Ioption[]
    fieldDetails: any
    setProps: any
    isShowError?: boolean | true
    isDisabled?: boolean
}

interface Ioption {
    name: string | number
    id: string | number
    value?: string | number
}
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
        },
        formControl: {
            width: "100%",
            paddingRight: "18px",
            height: "unset",
            overflow: "hidden",
            textOverflow: "ellipsis",
            "& .MuiSelect-icon": {
                right: "15px",
                fill: theme.palette.primary.main,
            },
            "& .MuiSelect-select": {
                "&:focus": {
                    background: "none",
                },
            },
        },
        select: {
            paddingLeft: "10px",
        },
        MuiInputBase: {
            paddingLeft: "10px",
        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
            fontWeight: applicationConstant.fontSizes.LIGHTER,
        },
        paper: {
            boxShadow: "0 10px 10px 0 #0000000d",
            borderRadius: "0px 0px 8px 8px",
            maxHeight: 300
        },
        menuItem: {
            padding: "1rem",
        },
    }),
)
const SimpleDropdown = ({
    title,
    placeholder,
    optionsList,
    fieldDetails,
    setProps,
    isShowError,
    isDisabled,
}: Iprops): JSX.Element => {
    const classes = useStyles()
    const handleChange = async (event: React.ChangeEvent<{ value: unknown }>): Promise<void> => {
        const newOptionsState: any = Array(...optionsList)
        const currentProp = await newOptionsState.filter((e: any) => e.id === event.target.value)[0]

        currentProp.name &&
            setProps({
                value: {
                    name: currentProp.name,
                    id: currentProp.id,
                    value: currentProp.value,
                },
            })
    }
    
    return (
        <div className="dropdown-wrapper simple-dropdown">
            {title && <label className="input-label" htmlFor={title}>
                {title}
            </label>}
            <div className="input">
                <FormControl error={isShowError} disabled={isDisabled} fullWidth>
                    <InputLabel disableAnimation shrink={false}>
                        {!fieldDetails?.value?.id && placeholder}
                    </InputLabel>
                    <Select
                        MenuProps={{
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                            },
                            transformOrigin: {
                                vertical: "top",
                                horizontal: "left",
                            },
                            getContentAnchorEl: null,
                            MenuListProps: {
                                disablePadding: true,
                                dense: true,
                            },
                            PopoverClasses: {
                                paper: classes.paper,
                            },
                        }}
                        disableUnderline
                        IconComponent={KeyboardArrowDownIcon}
                        className={classes.formControl}
                        value={fieldDetails?.value?.id}
                        onChange={handleChange}
                    >
                        {optionsList.map((option: Ioption, index: number) => (
                            <MenuItem
                                className={classes.menuItem}
                                value={option.id}
                                key={`${option.id}-${index}`}
                                selected={option.id === fieldDetails?.value?.id}
                            >
                                {option.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                {isShowError ? <div className="error" /> : null}
            </div>
            {isShowError ? <FormHelperText>{fieldDetails.error}</FormHelperText> : null}
        </div>
    )
}

export default SimpleDropdown
