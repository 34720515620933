import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useSelector } from "react-redux"
import { useSessionPending, SessionPending, PendingType } from "pendings"
import { useHistory } from "react-router-dom"
import moment from "moment"
import firebase from "firebase"
import { cancelTheBookedSessionService } from "utils/services/sessionServices"
import { isEqual, isWithinInterval, parseISO } from "date-fns"
import { STUDENT_GRACE_START, TEACHER_GRACE_START } from "utils/constant/SessionConstant"
import { useModal } from "react-modal-hook"
import { useIsPhoneOrTablet } from "utils/hooks/useIsPhoneOrTablet"
import {JoinSession as JoinSessionModal} from "components/modal_/join-session/JoinSession";
import { SERVER_URL } from "utils/constant/api/api-config"
interface VendorData {
    newbookingId: string;
    userType: string;
    teacherName: string;
    studentName: string;
    sessionId: string;
}

export default function useSessionAndZoom(sessionData: {
    sessionId: string,
    startTime: string,
    sessionLength: number,
    extensionTime?: number,
    bookingId: string,
    studentName: string,
    teacherName: string,
    teacherId: string,
    studentId: string
}) {
    const { userType, userId } = useSelector((state: any) => state.auth.userAuthData)
    const [isJoinEnabled, setIsJoinEnabled] = useState(false)
    const [loading, setLoading] = useState(false)
    const [customToken, setCustomToken] = useState<string>("")
    const sessionPending: SessionPending = useSessionPending()
    const [session, setSession] = useState(sessionData)
    const [cancellationReason, setCancellationReason] = useState<string>("")
    const history = useHistory()
    const isPhoneOrTab = useIsPhoneOrTablet();
    const platform =localStorage.getItem('platform') ||false;

    const {
        sessionId,
        sessionLength,
        extensionTime,
        startTime,
        teacherName,
        teacherId,
        studentName,
        studentId,
        bookingId,
    } = session

    useEffect(() => {
        setLoading(true)
        const getToken = firebase.functions().httpsCallable("users")
        getToken({ actionType: "GET_USER_CUSTOM_TOKEN" })
            .then((result: any) => result.data)
            .then((token: string) => {
                setCustomToken(token)
                setLoading(false)
            })
            .catch((e) => {
                setLoading(false)
                console.error("Error while creating custom token to join meeting: ", e)
            })
    }, [userType, userId])

    const shouldEnableJoinButton = useCallback(
        (startTime: any, sessionLength: number, extensionTime: number): boolean => {
            if (!customToken) {
                return false
            }
            const startM = moment(startTime)
            /**
             * CD-1679 and CD-1681
             * enable to join the meeting before the start time
             */
            if (userType === "TEACHER") {
                startM.subtract(TEACHER_GRACE_START, "seconds")
            } else if (userType === "STUDENT") {
                startM.subtract(STUDENT_GRACE_START, "seconds")
            }
            const start = startM.toDate()
            const end = moment(startTime)
                .add(sessionLength, "minutes")
                .add(extensionTime || 0, "minutes")
                .toDate()
            const current = new Date()
            return (
                isWithinInterval(current, { start, end }) ||
                isEqual(start, current) ||
                isEqual(end, current)
            )
        },
        [userType, customToken],
    )

    /**
     *
     */
    const getEnableTime = useCallback(
        (startTime: string) => {
            const startM = moment(parseISO(startTime))
            /**
             * CD-1679 and CD-1681
             * enable to join the meeting before the start time
             */
            if (userType === "TEACHER") {
                return startM
                    .subtract(TEACHER_GRACE_START, "seconds")
                    .utc()
                    .diff(moment().utc(), "milliseconds")
            } else if (userType === "STUDENT") {
                return startM
                    .subtract(STUDENT_GRACE_START, "seconds")
                    .utc()
                    .diff(moment().utc(), "milliseconds")
            }
            return startM.utc().diff(moment().utc(), "milliseconds")
        },
        [userType],
    )

    const getDisableTime = useCallback(
        (startTime: string, sessionLength: number, extensionTime?: number) => {
            return moment(parseISO(startTime))
                .utc()
                .add(sessionLength, "minutes")
                .add(extensionTime || 0, "minutes")
                .diff(moment().utc(), "millisecond")
        },
        [userType],
    )

    useEffect(() => {
        const onValueChange = (snapshot: firebase.database.DataSnapshot) => {
            if (!snapshot.exists()) {
                return
            }
            const sessionPendingItem: PendingType = snapshot.val()
            setSession((prevState) => ({ ...prevState, ...sessionPendingItem.data }))
        }
        sessionPending.ref.child(sessionId).on("value", onValueChange, (e) => {
            console.error(e)
        })
        return () => {
            sessionPending.ref.child(sessionId).off("value", onValueChange)
        }
    }, [userId, sessionPending, sessionId])

    useEffect(() => {
        setIsJoinEnabled(shouldEnableJoinButton(startTime, sessionLength, extensionTime || 0))
        const enableTime = getEnableTime(startTime)
        const disableTime = getDisableTime(startTime, sessionLength, extensionTime)
        const enableTimeout = setTimeout(() => {
            setIsJoinEnabled(shouldEnableJoinButton(startTime, sessionLength, extensionTime || 0))
        }, enableTime + 1000) // added one second extra to be on safe side
        const disableTimeout = setTimeout(() => {
            const isEnabled = shouldEnableJoinButton(startTime, sessionLength, extensionTime || 0)
            setIsJoinEnabled(isEnabled)
        }, disableTime + 1000) // added one second extra to be on safe side

        return () => {
            clearTimeout(enableTimeout)
            clearTimeout(disableTimeout)
        }
    }, [startTime, sessionLength, extensionTime, customToken])

    const cancelSession = useCallback(async () => {
        if (!userId) {
            return false
        }
        setLoading(true)
        const result = await cancelTheBookedSessionService(sessionId, cancellationReason).catch(
            (e) => setLoading(false),
        )
        setLoading(false)
        return result?.data?.status === "success"
    }, [sessionId, userId, cancellationReason])

    const showDeleteButton = useMemo((): boolean => {
        return !isJoinEnabled
    }, [userType, isJoinEnabled])

    const EnterZoom = useCallback(async () => {
        if (isJoinEnabled && shouldEnableJoinButton(startTime, sessionLength, extensionTime || 0)) {
            // double check the validity of session
            setLoading(true)
            const vendorData: VendorData = {
                newbookingId: bookingId,
                userType: userType,
                teacherName: teacherName,
                studentName: studentName,
                sessionId: sessionId,
            }
            localStorage.setItem("vendorData", JSON.stringify(vendorData))
            history.push(
                `/meeting?bookingId=${bookingId}&sessionId=${sessionId}&userType=${userType}&teacherName=${teacherName}&studentName=${studentName}&customToken=${customToken}`,
            )
            setLoading(false)
        }
    }, [
        isJoinEnabled,
        bookingId,
        userType,
        teacherName,
        studentName,
        startTime,
        sessionLength,
        extensionTime,
        customToken,
    ])

    const [showInfoModal, hideModal] = useModal(() => {
        return (
            <JoinSessionModal currentUserPicUrl={`${SERVER_URL}/userImage?userId=${userId}`} withUserPicUrl={`${SERVER_URL}/userImage?userId=${userId === studentId ? teacherId : studentId}`} withUserName={userId === studentId ? teacherName : studentName} isShown={true} onGoto={() => EnterZoom().catch(console.error)} onClose={hideModal} />
        );
    }, [EnterZoom, userId, studentId, teacherId, studentName, teacherName]);

    return {
        shouldEnableJoinButton,
        setDataAndEnterZoom: useCallback(async () => {
            if (isPhoneOrTab && !platform) {
                return showInfoModal()
            } else {
                return EnterZoom()
            }
        }, [EnterZoom, showInfoModal, isPhoneOrTab]),
        showDeleteButton,
        cancelSession,
        loading,
        setLoading,
        isJoinEnabled,
        setIsJoinEnabled,
        session,
        setSession,
        cancellationReason,
        setCancellationReason,
    }
}
