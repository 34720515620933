import React, { useEffect, useRef, useState } from "react"
import { Row, Col } from "reactstrap"
import { useField } from "formik"
import MultipleDropdown from "../../../components/input-field/MultipleDropDown"
import { InputBox } from "../../../components/input-field/InputFields"
import { year } from "../../../utils/constant/educationLists"
import { setTutorEducation } from "../../../store/actions/TutorSignUpActions"
import { useDispatch, useSelector } from "react-redux"
import { applicationConstant } from "../../../utils/constant/applicationConstant"
import InterfaceConstant from "../../../utils/constant/interfaceConstant"
import { ExitIcon } from "../../../components/icons/Icons"
import { useTranslation } from 'react-i18next';
interface Iprops {
    index: number
    handleFormError: any
    setHandleFormError: Function
    deleteAction: Function
}
const GradeItem = ({
    index,
    handleFormError,
    setHandleFormError,
    deleteAction,
}: Iprops): JSX.Element => {
    const { t } = useTranslation();
    const tutorEducationInfo = useSelector(
        (state: any) => state.tutorEducation.options.gradeOptions,
    )
    const [showGradeOptionDropdown, setShowGradeOptionDropdown] = useState([
        { showDropdown: false },
        { showDropdown: false },
        { showDropdown: false },
        { showDropdown: false },
    ])
    const showHideDropDown = async (item: number) => {
        const newArray = JSON.parse(JSON.stringify(handleFormError))

        showGradeOptionDropdown[index].showDropdown && !tutorEducationInfo[index].graduationYear
            ? (newArray[index].graduationYear = true)
            : (newArray[index].graduationYear = false)
        setHandleFormError(newArray)

        setShowGradeOptionDropdown(
            showGradeOptionDropdown.map((el, index) =>
                item === index ? { showDropdown: !el.showDropdown } : el,
            ),
        )
    }
    const educationErrorLabel: InterfaceConstant.IappErrorLabels =
        applicationConstant.appErrorLabels

    const dispatch = useDispatch()
    const validateThis = (type: string, index: number, e: any) => {
        const newArray = JSON.parse(JSON.stringify(handleFormError))

        switch (type) {
            case "schoolName":
                e.target.value === ""
                    ? (newArray[index].schoolName = educationErrorLabel.GLOBAL_REQUIRED)
                    : (newArray[index].schoolName = "")
                break
            case "degreeName":
                e.target.value === ""
                    ? (newArray[index].degreeName = educationErrorLabel.GLOBAL_REQUIRED)
                    : (newArray[index].degreeName = "")
                break
            case "graduationYear":
                newArray[index].graduationYear = e.name === "" ? true : false
                break
        }
        setHandleFormError(newArray)
    }
    const addInformationOption = (type: string, index: number, e: any) => {
        const newArray = JSON.parse(JSON.stringify(tutorEducationInfo))

        switch (type) {
            case "schoolName":
                newArray[index].schoolName = e.target.value
                validateThis(type, index, e)
                break
            case "degreeName":
                newArray[index].degreeName = e.target.value
                validateThis(type, index, e)
                break
            case "graduationYear":
                newArray[index].graduationYear = e.target.value
                validateThis(type, index, e)
                break
        }

        dispatch(setTutorEducation({ gradeOptions: newArray }))
    }



    return (
        <>
            <Col lg="4" md="12" sm="12" xs="12" className="on-dashboard-profile">
                <InputBox
                    type={"text"}
                    title={t('institute')}
                    placeholder="Select Institute"
                    inputProps={{
                        value: tutorEducationInfo[index].schoolName,
                        name: `currentGrade[${index}].schoolName`,
                        onChange: (e: any) => addInformationOption("schoolName", index, e),
                        onBlur: (e: any) => validateThis("schoolName", index, e),
                    }}
                    error={handleFormError[index].schoolName}
                />
            </Col>
            <Col lg="4" md="12" sm="12" xs="12"  className="on-dashboard-profile">
                <InputBox
                    type={"text"}
                    title={t('degree')}
                    placeholder="Insert Degree"
                    inputProps={{
                        value: tutorEducationInfo[index].degreeName,
                        name: `currentGrade[${index}].degreeName`,
                        onChange: (e: any) => addInformationOption("degreeName", index, e),
                        onBlur: (e: any) => validateThis("degreeName", index, e),
                    }}
                    error={handleFormError[index].degreeName}
                />
            </Col>
            <Col lg={4} md={5} sm={11} xs={12} className="year-handle">
                <div className="drop-w-div">
                    <MultipleDropdown
                        index={index}
                        title={t('graduation-year')}  
                        placeholder="Year"
                        value={tutorEducationInfo[index].graduationYear}
                        name={`currentGrade[${index}].graduationYear`}
                        optionsList={year}
                        handleDropDownAction={addInformationOption}
                        error={handleFormError[index].graduationYear}
                        inputProps={{
                            onBlur: (e: any) => validateThis("graduationYear", index, e),
                        }}
                    />
                </div>
                
                <div className="drop-w-button" onClick={() => deleteAction(index)}>
                    <ExitIcon />
                </div>
            </Col>
        </>
    )
}
export default GradeItem