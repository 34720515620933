import React, { useCallback, useEffect, useMemo, useState } from "react";
import moment from 'moment'
import { addMinutes, parseISO, setDate, getDate, getDay } from "date-fns";
import { toDate } from "date-fns/esm";
import { AVAILABILITY_SLOT_LENGTH, BOOKING_SLOT_INTERVAL_LENGTHS, MAX_NO_OF_AVAILABILITY_SLOTS } from "utils/constant/BookingConstant";
import { useSelectedTutorRapidBookingInfo } from "utils/hooks/useSelectedTutorRapidBookingInfo";
import { useSelector } from "react-redux"
import { Skeleton } from "@material-ui/lab"
import { defaultTimeZone } from "utils/helper/Timezone";
import { BookingSlotTabType, slotWithAvailability } from "./BookingSlotTabTypes";
import { dateSlots, getOffsetBetweenTimezonesForDate } from "./BookingSlotTabUtilities";
import { availabilityStateType } from "store/tutor-search/store";
const BOOKING_SLOT_INTERVAL = BOOKING_SLOT_INTERVAL_LENGTHS;
const AVAILABLITY_SLOTS = MAX_NO_OF_AVAILABILITY_SLOTS;
const AVAILABLITY_SLOT_INTERVAL_LENGTH = AVAILABILITY_SLOT_LENGTH;

// eslint-disable-next-line no-undef
const BookingSlotTab = (props: BookingSlotTabType): JSX.Element => {
    const moduloFixed = (val : number, n : number) => {
        return ((val % n) + n) % n;
    };

    const [isCalculating, setIsCalculating] = useState<boolean>(false);

    // Data sent from the caller
    const {
        date,
        nonAvailableSlots,
        onChange,
        selectedSessions,
        selectedSlots,
        availability,
        teacherTimeZone,
        isRapidBooking = false,
        day = 0
    } = props;

    const rapidBookingInfo = useSelectedTutorRapidBookingInfo();

    // slots is a list of time slots for the current day.
    const [slots, setSlots] = useState<slotWithAvailability[]>([]);
    const SLOT_INTERVAL = useMemo(() => BOOKING_SLOT_INTERVAL[+isRapidBooking], [isRapidBooking]);
    const NO_OF_SLOTS = useMemo(() => 24 * 60 / SLOT_INTERVAL, [SLOT_INTERVAL]);

    const timezoneSlotOffset = getOffsetBetweenTimezonesForDate(teacherTimeZone, defaultTimeZone, SLOT_INTERVAL);
    const adjustedAvailability: availabilityStateType = {};
    const availabilityKeys = Object.keys(availability) as any;
    const maxKeys = 7;
    const maxSlotsPerKey = 24 * 60 / SLOT_INTERVAL;
    availabilityKeys.forEach((key:number) => { adjustedAvailability[key] = []; });
    availabilityKeys.forEach((key:number) => {
        availability[key].forEach((slotIndex) => {
            const adjustedSlot: number = slotIndex + timezoneSlotOffset;
            if (adjustedSlot < 0) {
                adjustedAvailability[moduloFixed(+key - 1, maxKeys)].push(maxSlotsPerKey + adjustedSlot);
            } else if (adjustedSlot >= maxSlotsPerKey) {
                adjustedAvailability[moduloFixed(+key + 1, maxKeys)].push(adjustedSlot - maxSlotsPerKey);
            } else {
                adjustedAvailability[+key].push(adjustedSlot);
            }
        });
    });
    availabilityKeys.forEach((key:number) => { adjustedAvailability[key].sort(); });
    const [availabilitySource, setAvailabilitySource] = useState<availabilityStateType>(adjustedAvailability);

    const isChecked = (e: string): boolean => {
        return selectedSessions.find((i: string) => i === e) ? true : false
    }

    /**
     * Sample return (?): return [`00:00:00.000Z-06:00:00.000Z`, `06:00:00.000Z-12:00:00.000Z`,`12:00:00.000Z-18:00:00.000Z`,`18:00:00.000Z-23:59:59.000Z`] 
     */
    const SlotTimeRanges = useMemo(() => {
        const timezoneName = defaultTimeZone?.trim() || "UTC";

        return new Array(AVAILABLITY_SLOTS).fill(undefined).map((el, index) => {
            // Set the date to the first day of the week (Sunday) in the users timezone
            const momentDateTime = moment.tz(moment(date).day(day).minutes(0).hours(0).seconds(0).format("YYYY-MM-DDTHH:mm:ss"), timezoneName);

            const fromTime = parseISO(momentDateTime.format());

            const timeRanges = [
                addMinutes(fromTime, (index) * AVAILABLITY_SLOT_INTERVAL_LENGTH),
                addMinutes(fromTime, ((index + 1) * AVAILABLITY_SLOT_INTERVAL_LENGTH) - 1)
            ];
            return timeRanges;
        });
    }, [AVAILABLITY_SLOTS, AVAILABLITY_SLOT_INTERVAL_LENGTH, teacherTimeZone, date]);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const getAvailabilityByTimeRange = (teacherAvailability: any): Date[][][] => {
        const availabilityKeys = Object.keys(teacherAvailability).filter((key: string) => teacherAvailability[key].length > 0);
        const availabilityWithTimeRange: Date[][][] = availabilityKeys
            .map((availabilityKey: string) => teacherAvailability[availabilityKey]
            .map((u: number) => {
                const [startTimeRange, endTimeRange] = SlotTimeRanges[u];
                if (getDay(startTimeRange) === parseInt(availabilityKey)) {
                    return [
                        setDate(startTimeRange, getDate(startTimeRange)),
                        setDate(endTimeRange, getDate(endTimeRange))
                    ];
                }
                return [null, null];
            }));

        const availabilityWithTimeRangeOutput = availabilityWithTimeRange.filter(interval => {
            return interval[0][0] !== null && interval[0][1] !== null;
        });

        return availabilityWithTimeRangeOutput;
    };
 
    const availabilityWithTimeRange = useMemo(() => {
        return getAvailabilityByTimeRange(availabilitySource);
    }, []);

    const calculateSlots = useCallback(async () => {
        const momentDate = moment(date);
        const currdate = moment();
        const diff = momentDate.diff(currdate, "days");
        if (diff >= 0) {
            // calculatedDateSlots is an array of all time slots starting at Sunday morning midnight in the BROWER'S time zone.
            const calculatedDateSlots = await dateSlots(date, NO_OF_SLOTS, SLOT_INTERVAL, nonAvailableSlots, availabilityWithTimeRange, isRapidBooking, rapidBookingInfo);
            const availableDateSlots = calculatedDateSlots.filter(slots => {
                return slots.isAvailable;
            });
            return Promise.resolve(availableDateSlots);
        } else {
            return Promise.resolve([]);
        }
    }, [date, nonAvailableSlots, availabilityWithTimeRange, isRapidBooking, rapidBookingInfo]);
    
    useEffect(() => {
        setIsCalculating(true);
        const immediateID = setImmediate(() => {
            calculateSlots().then((slots) => setSlots(() => slots)).catch(console.error).finally(() => {
                setIsCalculating(false);
            });
        });
        return () => {
            clearImmediate(immediateID);
            setSlots(() => ([]));
        }
    }, [date]);

    return (isCalculating === true
        ? <div className="single-tab">
            <Skeleton variant="text" animation="wave" width={1000} height={50} />
        </div>
        : <>
            {slots.length > 0 ? slots.map((slot: slotWithAvailability) => {
                const slotDateTime = moment(slot.time).format();
                const slotTime = moment(slot.time).format('hh:mm a');

                const selectedSlot = selectedSlots.find(
                    (slotInfo: any) =>
                        slotInfo.id === slotDateTime,
                );

                return (
                    <div className="single-tab" key={`${date}-${slotTime}`}>
                        <input
                            id={`${slotDateTime}`}
                            type="checkbox"
                            checked={isChecked(slotDateTime)}
                            name={`${slotDateTime}`}
                            value={`${slotDateTime}`}
                            // eslint-disable-next-line @typescript-eslint/no-empty-function
                            onChange={slot.isAvailable ? onChange : () => { }}
                            disabled={!slot.isAvailable}
                        />

                        {!isChecked(slotDateTime) ? (
                            <label htmlFor={slotDateTime}>
                                <span>{slotTime}</span>
                            </label>
                        ) : (
                            selectedSlot ? null : <span></span>
                        )}

                        {selectedSlot &&
                            isChecked(slotDateTime) ? (
                            <label className="selected_slot" htmlFor={slotDateTime}>
                                <span >{`${moment(selectedSlot.startTime).format('hh:mm a')} - ${moment(selectedSlot.endTime).format('hh:mm A')}`}</span>
                            </label>
                        ) : null}


                    </div>
                )
            }
            ) : <div className="not-available-label">Not Available</div>}
        </>
    )

}

export default BookingSlotTab