interface Ioption {
    name: string
    id: string
}
export const HelpTopics: Ioption[] = [
    {
        id: "MY_ACCOUNT",
        name: "My Account",
    },
    {
        id: "SCHEDULING",
        name: "Scheduling",
    },
    {
        id: "PAYMENTS",
        name: "Payments",
    },
    {
        id: "OTHER",
        name: "Other",
    },
]
