import React from "react"
import { Button, ButtonGroup, Tooltip } from "@material-ui/core"


interface Props {
    items: Array<any>,
    label: string,
    setItems: any
}

const MultiselectBar = (props: Props): JSX.Element => {
    const { items, label, setItems } = props
    const toggleButton = (index: number) => {
        const newItems = [...items]
        newItems[index].selected = !newItems[index].selected
        setItems(newItems)
    }
    return (
        <>
            <label className="input-label">{label}</label>
            <ButtonGroup className="multi-selector" variant="contained" color="primary" aria-label="contained primary button group">
                {
                    items.map((item: any, index: number) => {
                        return (
                            <Tooltip title={item.tooltip} placement="top" key={index}>
                                <Button id={item.id} className={item.selected ? "option-selected  " + item.selectedClassName : "option " + item.className} onClick={(event) => toggleButton(index)}>{item.text}</Button>
                            </Tooltip>
                        )
                    })
                }
            </ButtonGroup>
        </>
    )
}
export default MultiselectBar
