import React, { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import InterfaceConstant from "utils/constant/interfaceConstant";
import { applicationConstant } from "utils/constant/applicationConstant";
import NoResultsCard from "components/dashboard/NoResultsCard";
import { navigatetoUrl, navigatetoUrlWithProps } from "utils/helper/helper";
import TutorDetailCard from "components/dashboard/detail-cards/TutorDetailCard";
import useSetTeacher from "utils/hooks/useSetTeacher";
import { getStudentAdvisors } from "store/actions/studentDashboardActions";
import TutorsAdvisorsHeader from "../components/TutorsAdvisorsHeader"
import AddFilledSvgIcon from "../../../../assets/images/svgIcons/AddFilledSvgIcon"
import Button from "components/button/Button";


const teacherTabsConstants = {
    MYTUTORS: "My Tutors",
    MYADVISORS: "My Advisors"
}

const teacherTabs = {
    [teacherTabsConstants.MYTUTORS]: "tutors",
    [teacherTabsConstants.MYADVISORS]: "advisors"
}

const AdvisorView = (): JSX.Element => {
    const history = useHistory()
    const userId = useSelector((state: any) => state?.auth?.userAuthData?.userId);
    const dashboardLabel: InterfaceConstant.IdashboardLabel = applicationConstant.dashboardLabel;
    const advisors = useSelector((state: any) => state.studentDashboard.advisors.data);
    const dataOk = !useSelector((state: any) => state.studentDashboard.advisors.isLoading);
    const setTeacher = useSetTeacher();
    const dispatch = useDispatch();

    useEffect(() => {
        userId && dispatch(getStudentAdvisors({ userId }))
    }, [userId]);

    return (
        <>
            <div className="tutors-advisors-container">
                <TutorsAdvisorsHeader />
                <div className="cards-container">
                    <div className="total-count">
                        <p style={{
                            marginTop: "auto",
                            marginBottom: "auto",
                            marginRight: "10px"
                        }}>Total: {advisors.length}</p>
                        <span onClick={() => navigatetoUrlWithProps(history, "/panel/search", { type: "advisors" })}>
                            {advisors.length === 0 ?
                                <></>
                                :
                                <Button
                                    title="New Search"
                                    isOutlineStyle />
                            }
                        </span>
                    </div>
                    {advisors.map((tutor: any) => (
                        <TutorDetailCard
                            {...tutor}
                            onBookNew={() => {
                                setTeacher(tutor.teacherId).then(isDone => {
                                    isDone && navigatetoUrl(history, "/panel/search/book-session")
                                }).catch(console.error)
                            }}
                        />
                    ))}
                    {dataOk && advisors.length === 0 && (
                        <div className="text-center">
                            <NoResultsCard
                                titleText={dashboardLabel.NO_ADVISORS_CARD_TITLE}
                                subtitleText={dashboardLabel.NO_TUTORS_CARD_SUBTITLE}
                                buttonText={dashboardLabel.NO_TUTORS_CARD_BUTTON_TEXT}
                                noTeacher={true}
                                buttonAction={() => navigatetoUrl(history, "/panel/search")}
                            />
                        </div>
                    )}
                </div>
            </div>
        </>
    )
}

export default memo(AdvisorView)
