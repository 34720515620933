import React, { Fragment } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMars, faHourglassEnd, faMoneyBillWave } from "@fortawesome/free-solid-svg-icons"
import MaleGenderSvgIcon from "../../../../assets/images/svgIcons/MaleGenderSvgIcon"
import { GenderTypes } from "../../../../store/types/tutorSearchTypes"
import FemaleGenderSvgIcon from "../../../../assets/images/svgIcons/FemaleGenderSvgIcon"
import RatherNotSayGenderSvgIcon from "../../../../assets/images/svgIcons/RatherNotSayGenderSvgIcon"
import NonBinaryGenderSvgIcon from "../../../../assets/images/svgIcons/NonBinaryGenderSvgIcon"

interface Props {
    gender: any
    hoursTutoring: number
    rate: number
}
const PersonalInfo = (props: Props): JSX.Element => {
    const { gender, hoursTutoring, rate } = props

    const getHoursTutoring = (): number => {
        if (!hoursTutoring) return 0
        return Math.floor(hoursTutoring / 60)
    } 


    const renderGenderIcon = (gender: string): JSX.Element => {
       
        switch (gender) {
            case GenderTypes.MALE:
                return (
                    <div>
                        <MaleGenderSvgIcon height={"28px"} width={"28px"} />
                    </div>
                )
            case GenderTypes.FEMALE:
                return (
                    <div>
                        <FemaleGenderSvgIcon height={"28px"} width={"28px"} />
                    </div>
                )
            case GenderTypes.RATHER_NOT_SAY:
                return (
                    <div>
                        <RatherNotSayGenderSvgIcon height={"28px"} width={"28px"} />
                    </div>
                )
            default:
                return (
                    <div>
                        <NonBinaryGenderSvgIcon height={"28px"} width={"28px"} />
                    </div>
                )
        }
    }

    return (
        <Fragment>
            <div className="rectangle-block">
                {/* <div className="gender item">
                    {renderGenderIcon(gender)}
                    <div className="label">{gender}</div>
                </div> */}
                {/* <div className="hours item">
                    <div>
                        <FontAwesomeIcon className="card-icon" icon={faHourglassEnd} />
                    </div>
                    <div className="label">{getHoursTutoring()}+ hours tutoring</div>
                </div> */}
                <div className="charge item">
                    <div>
                        <FontAwesomeIcon className="card-icon" icon={faMoneyBillWave} />
                    </div>
                    <div className="label">${rate} / hour</div>
                </div>
            </div>
        </Fragment>
    )
}
export default PersonalInfo
