
import SwitchTabComponent from "components/tabs/switch-tab/SwitchTabComponent";
import React from "react"
import { useTranslation } from 'react-i18next';



const RapidBookingFilter = ({ options, rapidBook, setRapidBook }: { options: string[], rapidBook: boolean, setRapidBook: (a: boolean) => void }): JSX.Element => {
    const { t } = useTranslation();
    return (
        <div className="switch-tab tutor-search-filter blue-tab">
            <SwitchTabComponent
                title={t("rapid-book")}
                options={options}
                selectedOption={rapidBook ? "Now" : "Later"}
                onTabClick={(val) => setRapidBook(val === "Now")}
            />
        </div>
    )
}

export default RapidBookingFilter

