import React, { useState } from "react"
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Grid from "@material-ui/core/Grid"
import Calendar from "react-calendar"
import { Link } from "react-router-dom"
import bellIconSvg from "../../assets/images/icons/Thi-24.svg"
import timerIconSvg from "../../assets/images/icons/Thi-35.svg"
import historyIconSvg from "../../assets/images/icons/Thi-34.svg"

import Button from "../button/Button"
import { tasksType } from "./UserPanel"
import { plusIcon } from "../icons/Icons"
import { MenuItem, Select } from "@material-ui/core"
import SimpleDropdown from "../dropdown/SimpleDropdown"

interface Iprops {
    tasks: tasksType,
    button?: {
        name: string,
        onClick: () => void
    }
}

const CalendarTodo = ({ tasks, button }: Iprops): JSX.Element => {
    const markedDates = [""]
    tasks.overdue.forEach((todo) => markedDates.push(todo.date.toLocaleDateString()))
    tasks.deadlines.forEach((todo) => markedDates.push(todo.date.toLocaleDateString()))
    tasks.upcoming.forEach((todo) => markedDates.push(todo.date.toLocaleDateString()))
    const [showTodoDropdown, setShowTodoDropdown] = useState(false)
    const todoSections = [
        { id: 1, name: "All" },
        { id: 2, name: "Overdue" },
        { id: 3, name: "Deadlines" },
        { id: 4, name: "Upcoming" },
    ]
    const closeTodoDropDown = (status: boolean) => {
        setShowTodoDropdown(status)
    }
    const [todoSectionDetails, setTodoSection] = useState({
        value: { id: 1, name: "All" },
    })
    const currentTodoSection = todoSectionDetails.value.id
    const todoData = [
        { name: "Overdue", icon: bellIconSvg, tasks: tasks.overdue, keys: [1, 2] },
        { name: "Deadlines", icon: timerIconSvg, tasks: tasks.deadlines, keys: [1, 3] },
        { name: "Upcoming", icon: historyIconSvg, tasks: tasks.upcoming, keys: [1, 4] },
    ]
    const currentDateString = new Date().toLocaleDateString()
    return (
        <>
        <Grid container spacing={3}>
            <Grid item>
                <Calendar
                    className="user-panel-calendar"
                    tileClassName="user-panel-calendar-tile"
                    defaultView="month"
                    showNeighboringMonth={false}
                    next2Label={null}
                    prev2Label={null}
                    calendarType="US"
                    nextLabel={<FontAwesomeIcon icon={faAngleRight} />}
                    prevLabel={<FontAwesomeIcon icon={faAngleLeft} />}
                    tileContent={({ date }) => (
                        <div
                            className={
                                "user-calendar-tile " +
                                (markedDates.includes(date.toLocaleDateString())
                                    ? "marked-date-tile-child "
                                    : "") +
                                ([currentDateString].includes(date.toLocaleDateString())
                                    ? "today-user-calendar-tile"
                                    : "")
                            }
                        >
                            {date.getDate()}
                        </div>
                    )}
                />
            </Grid>
        </Grid>
    </>
    )
}

export default CalendarTodo
