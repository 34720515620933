import React from "react"
import { navigatetoUrl, targetForRedirection } from "../../utils/helper/helper"
import { useHistory } from "react-router-dom"

interface Props {
    heading?: string
    subHeading: string
    link?: string
    anchor?: string
    withMargin?: boolean
    reverse?: boolean | false
}

export const OnBoardingHeader = ({ heading, subHeading, link, anchor, withMargin, reverse }: Props): JSX.Element => {
    const history = useHistory()
    const url = anchor ? anchor : ""

    return (
        <>
        {withMargin && (
            <div className="gutter-top" />
        )}
        <div className={link ? "onboarding-subheader" : "onboarding-header"}>
            {!reverse && <div className="heading">{heading}</div>}
            <div className="sub-heading">
                {subHeading}
                {link && (
                    <a
                        className="navigation"
                        href={url}
                        target={targetForRedirection()}>
                        {link}
                    </a>
                )}
            </div>
            {reverse && <div className="heading">{heading}</div>}
            </div>
        </>
    )
}
