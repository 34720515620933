import React, { useState, useEffect, Fragment } from "react"
import { Container, Row, Col } from "reactstrap"
import { useFormik } from "formik"
import * as Yup from "yup"
import { applicationConstant } from "../../../utils/constant/applicationConstant"
import { useSelector, useDispatch } from "react-redux"
import InterfaceConstant from "../../../utils/constant/interfaceConstant"
import { InputBox } from "../../../components/input-field/InputFields"
import { setTeacherMyprofile } from "../../../store/actions/TutorSignUpActions"
import UploadProfileImage from "../../../components/upload-profile-image/UploadProfileImage"
import _ from "lodash"
import { navigatetoUrl } from "../../../utils/helper/helper"
import NavigationButtons from "../../../components/on-boarding/navigation-buttons/NavigationButtons"
import SimpleDropdown from "../../../components/dropdown/SimpleDropdown"
import MessageAlert from "components/message-alert/MessageAlert"
import { softTimeZones, timeZoneCountriesType, countries } from "utils/constant/softtimezones"
import MuiPhoneNumber from "material-ui-phone-number";
import parsePhoneNumber, { isValidPhoneNumber } from 'libphonenumber-js'
import PhoneNumberInput from "components/phone-number-input/PhoneNumberInput"

interface Iprops {
    history: any
}

const signUpErrorLabel: InterfaceConstant.IappErrorLabels = applicationConstant.appErrorLabels
const regexVariables: InterfaceConstant.IregexExpression = applicationConstant.regexExpression
const routeUrl: InterfaceConstant.IrouteUrl = applicationConstant.routeUrl
const appLabels: InterfaceConstant.IappLabels = applicationConstant.appLabels
const d = new Date()
d.setFullYear(d.getFullYear() - 15)


const countriesList = countries;
const timeZoneList = softTimeZones;


const appErrorLabels: InterfaceConstant.IappErrorLabels = applicationConstant.appErrorLabels


const TutorMyProfileForm = (props: Iprops): JSX.Element => {

    const dispatch = useDispatch()
    const tutorInfo = useSelector((state: any) => state.tutorMyprofile.options)
    const [imageurl, setImageurl] = useState(tutorInfo.profilePicture)
    const [loading, setLoading] = useState(false)
    const [isShowImageError, setShowImageError] = useState(false)
    const [message, setMessage] = useState({
        message: "",
        type: "",
    })
    const [lastName, setLastName] = useState(tutorInfo.lastName)
    const [openSnackBar, setOpenSnackBar] = useState(false)
    const [timeZoneCountryProps, settimeZoneCountryProps] = useState({
        error: appErrorLabels.GLOBAL_REQUIRED,
        value: countriesList[0]
    })
    const [isShowZoneError, setisShowZoneError] = useState(false)
    const [isShowZoneCountryError, setisShowZoneCountryError] = useState(false)

    const [timeZoneInputProps, settimeZoneInputProps] = useState({
        error: appErrorLabels.GLOBAL_REQUIRED,
        value: {
            id: '',
            name: '',
            standard: ''
        }
    })
    const [phoneNumberError, setPhoneNumberError] = useState(!isValidPhoneNumber(`${tutorInfo?.phoneNumber?.countryCode}${tutorInfo?.phoneNumber?.number}`));

    useEffect(() => {
        timeZoneInputProps.value.name !== "" && setisShowZoneError(false) &&
            formik.setFieldValue("timeZone", timeZoneInputProps, true)

        dispatch(setTeacherMyprofile({ timeZone: timeZoneInputProps.value.id }))

    }, [timeZoneInputProps])
    const navigatePrevPage = (): void => {
        navigatetoUrl(props.history, routeUrl.TUTOR_RATES_PAGE)
    }

    const formik = useFormik({
        initialValues: {
            firstName: tutorInfo.firstName,
            lastName: tutorInfo.lastName,
            email: tutorInfo.email,
            password: "",
            timeZone: tutorInfo.timeZone,
            confirmPassword: "",
        },
        validationSchema: Yup.object({
            firstName: Yup.string()
                .matches(/^[\\a-zA-Zp{L}.'-]+$/, 'English alphabets and .\'- are allowed only')
                .required(signUpErrorLabel.GLOBAL_REQUIRED),
            lastName: Yup.string()
                .matches(/^[\\a-zA-Zp{L} .'-]*$/, 'Blank space, English alphabets and .\'- are allowed only')
                .required(signUpErrorLabel.GLOBAL_REQUIRED),
            timeZone: Yup.string()
                .required(signUpErrorLabel.GLOBAL_REQUIRED),
            email: Yup.string()
                .matches(regexVariables.EMAIL_VALIDATION, signUpErrorLabel.GLOBAL_EMAIL_INVALID)
                .required(signUpErrorLabel.GLOBAL_REQUIRED),
            password: Yup.string()
                .min(6, signUpErrorLabel.GLOBAL_PASSWORD_REGEX_ERROR)
                .max(64, signUpErrorLabel.GLOBAL_PASSWORD_REGEX_ERROR)
                .required(signUpErrorLabel.GLOBAL_REQUIRED),
            confirmPassword: Yup.string()
                .min(6, signUpErrorLabel.GLOBAL_PASSWORD_REGEX_ERROR)
                .required(signUpErrorLabel.GLOBAL_REQUIRED)
                .oneOf([Yup.ref("password"), null], signUpErrorLabel.GLOBAL_PASSWORD_MATCH_ERROR),
        }),
        onSubmit: () => {
        },
    })

    const navigateNextPage = async (formikObject: any) => {

        dispatch(setTeacherMyprofile({ timeZone: timeZoneInputProps.value.id, defaultTimezoneCountry: timeZoneCountryProps.value.name }))
        let word = lastName.trim()
            .split(/ +/)
            .join(" ");
        setLastName(word);
        formik.setFieldValue("lastName", word, true)
        dispatch(setTeacherMyprofile({ lastName: word }))
        await formikObject.validateForm()
        let values = _.some(formikObject.values, (item) => item === "")
        let errors = formikObject.errors
        if (errors.timeZone) {
            errors = {};
            values = false;
        }
        let valuesError = false
        Object.values(errors).map((value) => (value ? (valuesError = true) : ""))
        if (timeZoneInputProps.value.name === '') {
            if (!isShowZoneError) {
                setisShowZoneError(true)
                return
            }
        }
        if (valuesError) {
            setMessage({
                message: "Please Add Correct Details    ",
                type: "error",
            })
            setOpenSnackBar(true)
        }
        formikObject.submitForm()
        if (!isShowZoneError &&
            !values &&
            !valuesError &&
            Object.keys(imageurl).length > 0
        ) {
            navigatetoUrl(props.history, routeUrl.TUTOR_REVIEW_PAGE)
            return
        }
        if (Object.keys(imageurl).length === 0) {
            if (!isShowImageError) {
                setShowImageError(true)
            }
        }


    }
    function onKeyDown(keyEvent: any) {
        if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
            keyEvent.preventDefault()
            navigateNextPage(formik).catch(console.error)
        }
    }
    const handlePhoneNumber = (value: string) => {
        const phoneNumber = parsePhoneNumber(value);
        setPhoneNumberError(!isValidPhoneNumber(value));
        const { countryCallingCode = '1', nationalNumber = '', country = 'US' } = phoneNumber || {};
        if(!value.split(" ")[0].includes("0")){
            dispatch(setTeacherMyprofile({ phoneNumber: { countryCode: '+' + countryCallingCode, number: nationalNumber, countryPrefix: country } }))
        }else {
            dispatch(setTeacherMyprofile({ phoneNumber: { countryCode: '+0', number: nationalNumber, countryPrefix: country } }))    
        }
    }
    const handleChange = (e: any) => {
        const { name, value } = e.target
        switch (name) {
            case "firstName":
                formik.setFieldValue("firstName", value && value[0].toUpperCase() + value.substring(1), true)
                dispatch(setTeacherMyprofile({ firstName: value }))
                break
            case "lastName":
                let words = value.split(" ");
                words = words.map((word: string) => {
                    return word && word[0].toUpperCase() + word.substring(1);
                }).join(" ");
                formik.setFieldValue("lastName", words, true)
                dispatch(setTeacherMyprofile({ lastName: words }))
                setLastName(words);
                break
            case "email":
                formik.setFieldValue("email", value, true)
                dispatch(setTeacherMyprofile({ email: value }))
                break
            case "password":
                formik.setFieldValue("password", value, true)
                dispatch(setTeacherMyprofile({ password: value }))
                break
            case "confirmPassword":
                formik.setFieldValue("confirmPassword", value, true)
                dispatch(setTeacherMyprofile({ password: value }))
                break
        }
    }


    return (
        <Fragment>
            <MessageAlert
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
                type={message.type}
                message={message.message}
            />
            <form
                onKeyDown={onKeyDown}
                className="teacher-myprofile-form"
                onSubmit={formik.handleSubmit}
            >
                <Container>
                    <Row>

                        <Col lg="3" md="4" sm="12" xs="12">
                            <UploadProfileImage
                                imageurl={imageurl}
                                setImageurl={setImageurl}
                                isShowError={isShowImageError}
                            />
                        </Col>

                        <Col lg="9" md="8" sm="12" xs="12">
                            <Row>
                                <Col xs="12" sm="12" md="6" lg="6" className="firstNameCol">
                                    <InputBox
                                        className="person-name"
                                        type={"text"}
                                        title="First Name"
                                        placeholder="Insert first name"
                                        inputProps={{
                                            name: "firstName",
                                            value: formik.values.firstName,
                                            onChange: handleChange,
                                            onBlur: formik.handleBlur,
                                        }}
                                        error={
                                            formik.touched.firstName && formik.errors.firstName
                                                ? formik.errors.firstName
                                                : null
                                        }
                                    />
                                </Col>
                                <Col xs="12" sm="12" md="6" lg="6">
                                    <InputBox
                                        className="person-name"
                                        type={"text"}
                                        title="Last Name"
                                        placeholder="Insert last name"
                                        inputProps={{
                                            name: "lastName",
                                            value: formik.values.lastName,
                                            onChange: handleChange,
                                            onBlur: formik.handleBlur,
                                        }}
                                        error={
                                            formik.touched.lastName && formik.errors.lastName
                                                ? formik.errors.lastName
                                                : null
                                        }
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="12" sm="12" md="6">
                                    <PhoneNumberInput
                                        className="phone-number"
                                        defaultCountry={'us'}
                                        onChange={handlePhoneNumber}
                                        preferredCountries={['us']}
                                        disableAreaCodes={true}
                                        countryCodeEditable={true}
                                        title="Phone Number"
                                        variant="outlined"
                                        style={{
                                            "boxShadow": "0 10px 10px 0 #0000000d",
                                            "width": "100%"
                                        }}
                                        inputProps={{
                                            style: {
                                                "height": "30px"
                                            }
                                        }}
                                        value={tutorInfo?.phoneNumber?.countryCode + tutorInfo?.phoneNumber?.number}
                                    />
                                </Col>
                                <Col xs="12" sm="12" md="6">
                                    <InputBox
                                        type={"text"}
                                        title="Email"
                                        placeholder="Insert email"
                                        inputProps={{
                                            name: "email",
                                            value: formik.values.email,
                                            onChange: handleChange,
                                            onBlur: formik.handleBlur,
                                        }}
                                        error={
                                            formik.touched.email && formik.errors.email
                                                ? formik.errors.email
                                                : null
                                        }
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="12" sm="12" md="6">
                                    <SimpleDropdown
                                        isDisabled={false}
                                        title={appLabels.COUNTRY}
                                        placeholder={"A"}
                                        optionsList={countriesList}
                                        fieldDetails={timeZoneCountryProps}
                                        setProps={settimeZoneCountryProps}
                                        isShowError={isShowZoneCountryError}
                                        />
                                </Col>
                                <Col xs="12" sm="12" md="6">
                                    <SimpleDropdown
                                        isDisabled={loading}
                                        title={appLabels.GLOBAL_TIMEZONE}
                                        placeholder={appLabels.GLOBAL_TIMEZONE}
                                        optionsList={timeZoneList[timeZoneCountryProps.value.id]}
                                        fieldDetails={timeZoneInputProps}
                                        setProps={settimeZoneInputProps}
                                        isShowError={isShowZoneError}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="12" sm="12" md="6">
                                    <InputBox
                                        type={"password"}
                                        title="Password"
                                        placeholder={"Insert password"}
                                        inputProps={{
                                            name: "password",
                                            value: formik.values.password,
                                            onChange: handleChange,
                                            onBlur: formik.handleBlur,
                                        }}
                                        error={
                                            formik.touched.password && formik.errors.password
                                                ? formik.errors.password
                                                : null
                                        }
                                    />
                                </Col>
                                <Col xs="12" sm="12" md="6">
                                    <InputBox
                                        type={"password"}
                                        title="Confirm Password"
                                        placeholder="Confirm your password"
                                        inputProps={{
                                            name: "confirmPassword",
                                            value: formik.values.confirmPassword,
                                            onChange: handleChange,
                                            onBlur: formik.handleBlur,
                                        }}
                                        error={
                                            formik.touched.confirmPassword &&
                                                formik.errors.confirmPassword
                                                ? formik.errors.confirmPassword
                                                : null
                                        }
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <div className="onboarding-actions">
                        <div className="onboarding-divider" />
                        <NavigationButtons
                            previousClick={navigatePrevPage}
                            nextClick={() => navigateNextPage(formik)}
                        />
                    </div>
                </Container>
            </form>
        </Fragment>
    )
}

export default TutorMyProfileForm