import React, { memo } from "react"
import colors from "../../../../assets/colors"

import AppleSvgIcon from "../../../../assets/images/svgIcons/AppleSvgIcon"

interface Iprops {
    clickAction: Function
    rating: number
}

const Rating = ({clickAction, rating}: Iprops) => {
    return (
        <div id="full-stars-example-two">
            <div className="rating-group">
                <input
                    disabled
                    checked={rating === 0}
                    className="rating__input rating__input--none"
                    name="rating3"
                    id="rating3-none"
                    value="0"
                    type="radio" 
                    
                    onChange={() => clickAction(0)}
                />
                <label aria-label="1 star" className="rating__label" htmlFor="rating3-1">
                    <AppleSvgIcon width="45" height="45" className="rating__icon rating__icon--star" />
                </label>
                <input
                    className="rating__input" 
                    name="rating3"
                    id="rating3-1"
                    value="1"
                    type="radio"
                    checked={rating === 1}
                    onChange={() => clickAction(1)}
                />
                <label aria-label="2 stars" className="rating__label" htmlFor="rating3-2">
                <AppleSvgIcon width="45" height="45" className="rating__icon rating__icon--star" />
                </label>
                <input
                    className="rating__input"
                    name="rating3"
                    id="rating3-2"
                    value="2"
                    type="radio"
                    checked={rating === 2}
                    onChange={() => clickAction(2)}
                />
                <label aria-label="3 stars" className="rating__label" htmlFor="rating3-3">
                    <AppleSvgIcon width="45" height="45" className="rating__icon rating__icon--star" />
                </label>
                <input
                    className="rating__input"
                    name="rating3"
                    id="rating3-3"
                    value="3"
                    type="radio"
                    checked={rating === 3} 
                    onChange={() => clickAction(3)}
                />
                <label aria-label="4 stars" className="rating__label" htmlFor="rating3-4">
                    <AppleSvgIcon width="45" height="45" className="rating__icon rating__icon--star" />
                </label>
                <input
                    className="rating__input"
                    name="rating3"
                    id="rating3-4"
                    value="4"
                    type="radio"
                    checked={rating === 4}
                    onChange={() => clickAction(4)}
                />
                <label aria-label="5 stars" className="rating__label" htmlFor="rating3-5">
                    <AppleSvgIcon width="45" height="45" className="rating__icon rating__icon--star" />
                </label>
                <input
                    className="rating__input"
                    name="rating3"
                    id="rating3-5"
                    value="5"
                    type="radio"
                    checked={rating === 5}
                    onChange={() => clickAction(5)}
                />
            </div>
        </div>
    )
}

export default Rating
