export const platformCheck = () => {
    try {
        const data: any = JSON.parse(localStorage.getItem("platform") || "")
        return data
    } catch (e) {
        return 404
    }
}

export const targetForRedirection = (): string => {
    const platform: any = platformCheck()
    if (platform && platform != 404) {
        return "_self"
    }
    return "_blank"
}

export const navigatetoUrl = (history: any, url: string): void => {
    history.push(url)
}
export const navigatetoUrlWithProps = (history: any, url: string, data: any): void => {
    history.push(url, { params: data })
}

export const returnIndex = (object: any, element: any): number => {
    return object.findIndex((item: string) => item === element)
}

export const getFile = (file: File) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = () => resolve(reader.result)
        reader.onerror = (error) => reject(error)
        reader.readAsDataURL(file)
    })
}

export const filterArray = (array: any, filters: any) => {
    const filterKeys = Object.keys(filters)
    return array.filter((item: any) => {
        // validates all filter criteria
        return filterKeys.every((key) => {
            // ignores non-function predicates
            if (typeof filters[key] !== "function") return true
            return filters[key](item[key])
        })
    })
}

export const urlify = (text: string) => {
    const urlRegex = /((http|https|ftp|ftps)\:\/\/[a-zA-Z0-9\-\.]+\.[a-zA-Z]{2,3}(\/\S*)?)/g
    return text?.replace(urlRegex, `<a href="$1" target=${targetForRedirection()} >$1</a>`)
}

export const arrayEquals = (a: any, b: any) => {
    return (
        a &&
        a.reduce((res: any, curr: any) => {
            return res && b.includes(curr)
        }, true)
    )
}

export const formatName = (name: string | undefined): string => {
    if (!name) {
        return "";
    }
    const [first, last] = name.split(" ");
    if (!last) {
        return first;
    }
    return first + " " + last.charAt(0) + ".";
}



export const ordinalSuffix = (num: number) => {
    if (num === 11 || num === 12 || num === 13) {
        return num.toString() + " th"
    }
    const onesDigit = num % 10

    switch (onesDigit) {
        case 1:
            return num.toString() + " st"
        case 2:
            return num.toString() + " nd"
        case 3:
            return num.toString() + " rd"
        default:
            return num.toString() + " th"
    }
}
