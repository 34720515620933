import React, { useState, useEffect } from "react"
import moment from "moment"
import { formatDate, DateFormats } from "../../../../utils/helper/date-utils"
import colors from "../../../../assets/colors/index"
import { KeyboardDatePicker } from "@material-ui/pickers"
import RoundedButton from "../../../../components/rounded-button/RoundedButton"
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons"
import { calendar } from "../../../../components/icons/Icons"
import Dialog from "@material-ui/core/Dialog"
import { makeStyles } from "@material-ui/core/styles"

import { exit } from "../../../../components/icons/Icons"
import { quarterTimeOptions } from "../../../../utils/helper/date-utils"
import Button from "../../../../components/button/Button"
import { useFormik } from "formik"
import * as Yup from "yup"
import SimpleDropdown from "../../../../components/dropdown/SimpleDropdown"

interface Mprops {
    show: boolean
    close: any
    slot: Record<string, any>
    onsetsuggest: any
}

const sessionLengthOptions: Array<{ name: number; id: number }> = []
let value = 0
let index = 0
const step = 15
while (value < 240) {
    index++
    value += step
    sessionLengthOptions.push({ name: value, id: index })
}

const useStyles = makeStyles({
    root: {
        borderRadius: "20px 0px 20px 0px",
        maxWidth: "940px",
        width: "100%",
        padding: "90px 61px 100px",
        overflow: "visible",
    },
})

const useStylesInput = makeStyles({
    root: {
        border: "0px",
        height: "65px",
        boxShadow: `0 10px 10px 0 ${colors.blackRussianOp}`,
        width: "100%",
        padding: "10px",
    },
})
const ChangeTimeModal = ({ show, close, slot, onsetsuggest }: Mprops): JSX.Element => {
    const classes = useStyles()
    const inputClasses = useStylesInput()
    const [isPickerOpen, setIsPickerOpen] = useState(false);
    const [maxLength, setMaxLength] = useState(slot.sessionLength || 360);
    const [minLength, setMinLength] = useState(30);

    const handleSetTime = (sugdate: any, sugtime: any, suglength: any): void => {
        if (sugdate <= 0 || sugtime === "" || suglength === "") {
            return
        }
        const date = moment(sugdate)
        const time = moment(sugtime, "hh:mm A")
        date.set({ hour: time.get("hour"), minute: time.get("minute") })
        onsetsuggest &&
            onsetsuggest({
                suggestedDateTime: moment.utc(date).toISOString(),
                sessionLength: suglength,
                studentAccepted: false,
                deleted: false,
            })
        close()
    }
    const formik = useFormik({
        initialValues: {
            suggestedDate: "",
            suggestedTime: "",
            suggestedLength: 15,
        },
        validationSchema: Yup.object().shape({
            suggestedDate: Yup.date().required("Please Provide a date"),
            suggestedTime: Yup.string().required("Please select a time"),
            suggestedLength: Yup.number().min(15),
        }),
        onSubmit: (values) => {
            const { suggestedDate, suggestedTime, suggestedLength } = values
            handleSetTime(suggestedDate, suggestedTime, suggestedLength)
        },
    })

    const timeOptionsArray = quarterTimeOptions()
    const timeOptionsArrayFiltered = timeOptionsArray.filter((item: any) => moment(`${formik.values.suggestedDate} ${item}`).isAfter(moment().format('LLL')))
    const timeOptionList = timeOptionsArrayFiltered.map((item: any, index: number) => ({
        id: `${index}_`,
        name: item,
    }))

    const handleDateUpdate = (date: any) => {
        const formatted = moment(date).isValid() ? formatDate(date, DateFormats.LONG_DATE) : date
        formik.setFieldValue("suggestedDate", formatted, true)
    }

    const handleSuggestedTimeChange = (time: any) => {
        formik.setFieldValue("suggestedTime", time, true)
    }

    const handleLengthChange = (length: any) => {
        formik.setFieldValue("suggestedLength", length, true)
    }

    const increaseLength = (e: any) => {
        e.preventDefault()
        if (formik.values.suggestedLength < maxLength) {
            handleLengthChange(formik.values.suggestedLength + 15)
        }
    }

    const decreaseLength = (e: any) => {
        e.preventDefault()
        if (formik.values.suggestedLength > minLength) {
            handleLengthChange(formik.values.suggestedLength - 15)
        }
    }

    useEffect(() => {
        if (Object.keys(slot).length) {
            const formattedDate = slot.suggestedDateTime
                ? formatDate(slot.suggestedDateTime, DateFormats.LONG_DATE)
                : formatDate(slot.date, DateFormats.LONG_DATE)
            handleDateUpdate(formattedDate)
            slot.suggestedDateTime
                ? handleSuggestedTimeChange(
                    formatDate(slot.suggestedDateTime, DateFormats.TIME_IN_MERIDIAN),
                )
                : handleSuggestedTimeChange("")
            slot.sessionLength !== undefined
                ? handleLengthChange(slot.sessionLength)
                : handleLengthChange(15)
        }
    }, [slot])

    return (
        <Dialog className="suggest-modal" fullWidth maxWidth='lg' open={show} onClose={close} classes={{ paper: classes.root }}>
            <img alt="close" src={exit} className="session-modal-exit" onClick={close} />
            <div className="session-time-modal">
                <h4>Suggest Time</h4>
                <p>
                    If you are unavailable for a requested session, suggest a time that works for
                    you.
                </p>
                <form onSubmit={formik.handleSubmit}>
                    <div className="session-time-selector-wrapper">
                        <div className="session-time-selector-input">
                            <label className="input-label">Select a date</label>
                            <KeyboardDatePicker
                                autoOk

                                clearable
                                inputVariant="standard"
                                format={`${DateFormats.LONG_DATE}`}
                                variant="inline"
                                className="datepicker-root"
                                // label= "Select a date"
                                placeholder={`${formatDate(
                                    moment().toString(),
                                    DateFormats.LONG_DATE,
                                )}`}
                                value={formik.values.suggestedDate}
                                minDate={moment()}
                                InputProps={{
                                    classes: { root: inputClasses.root },
                                    disableUnderline: true,
                                    readOnly: true
                                }}
                                InputAdornmentProps={{ position: "end" }}
                                InputLabelProps={{ disableAnimation: true, shrink: false }}
                                helperText={
                                    formik.touched.suggestedDate &&
                                    formik.errors.suggestedDate &&
                                    "Please provide a valid date"
                                }
                                error={
                                    formik.touched.suggestedDate && formik.errors.suggestedDate
                                        ? true
                                        : undefined
                                }
                                open={isPickerOpen}
                                onClose={() => setIsPickerOpen(false)}
                                onChange={(e: any) => handleDateUpdate(e)}
                                onClick={() => setIsPickerOpen(true)}
                            />
                        </div>
                        <div className="session-time-selector-input time">
                            <SimpleDropdown
                                title="Starts At"
                                optionsList={timeOptionList}
                                placeholder="Select a time"
                                fieldDetails={{
                                    error: "Please select a time",
                                    value: timeOptionList.filter(
                                        (item: any) => item.name === formik.values.suggestedTime,
                                    )[0]
                                        ? timeOptionList.filter(
                                            (item: any) =>
                                                item.name === formik.values.suggestedTime,
                                        )[0]
                                        : formik.values.suggestedTime,
                                }}
                                setProps={(p: any) => handleSuggestedTimeChange(p.value.name)}
                                // boxclassName="change-modal-drop-box"
                                isShowError={
                                    formik.touched.suggestedTime && formik.errors.suggestedTime
                                        ? true
                                        : false
                                }
                            />
                        </div>
                        <div className="session-time-selector-input duration">
                            <label className="input-label duration-label">Duration</label>
                            <div className="duration-selector">
                                <RoundedButton
                                    onPress={(e: any) => decreaseLength(e)}
                                    iconProps={{
                                        icon: faMinus,
                                        style: {
                                            color: colors.darkMango,
                                            fontSize: "12px",
                                        },
                                    }}
                                    buttonProps={{
                                        style: { border: "0" },
                                    }}
                                    active={formik.values.suggestedLength === minLength ? false : true}
                                />
                                <input
                                    type="text"
                                    readOnly
                                    value={formik.values.suggestedLength + " min"}
                                />
                                <RoundedButton
                                    onPress={(e: any) => increaseLength(e)}
                                    iconProps={{
                                        icon: faPlus,
                                        style: { color: colors.darkMango, fontSize: "12px" },
                                    }}
                                    buttonProps={{
                                        style: { border: "0" },
                                    }}
                                    active={formik.values.suggestedLength === maxLength ? false : true}
                                />
                            </div>
                        </div>
                    </div>
                    <Button
                        title="Send"
                        clickAction={undefined}
                        style={{ margin: "0 auto", display: "block" }}
                    />
                </form>
            </div>
        </Dialog>
    )
}

export default ChangeTimeModal