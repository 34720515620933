import { withStyles } from "@material-ui/core/styles"
import colors from "../../../assets/colors"
import Slider from "@material-ui/core/Slider"

export const CustomSlider = withStyles({
    root: {
        color: colors.darkMango,
        height: 8,
        width: "75%",
    },
    thumb: {
        height: 24,
        width: 24,
        backgroundColor: colors.darkMango,
        border: "2px solid white",
        marginTop: -8,
        marginLeft: -12,
        "&:focus, &:hover, &$active": {
            boxShadow: "inherit",
        },
    },
    active: {},
    valueLabel: {
        left: "calc(-50% + 4px)",
    },
    track: {
        backgroundColor: colors.battleshipGrey,
        height: 8,
        borderRadius: 4,
    },
    rail: {
        backgroundColor: colors.paleGrey,
        height: 8,
        borderRadius: 4,
    },

})(Slider)