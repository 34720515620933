import React, { useState, useEffect, useContext, useCallback } from "react"
import { Modal, Grid } from "@material-ui/core"
import { InputBox } from "../../../components/input-field/InputFields"
import Button from "../../../components/button/Button"
import _ from "lodash"
import { CloseIcon } from "../../../components/icons/Icons"
import StudentTutorAvatar from "../../../components/student-tutor-avatar/StudentTutorAvatar"
import { useSelector } from "react-redux"
import Rating from "./components/Rating"
import SwitchComponent from "../../../components/switch/SwitchComponent"
import success from "../../../assets/images/reviewSubmited.svg"
import ok from "../../../assets/images/ok.svg"
import { createReview } from "../../../utils/helper/firebase"
import { useFormik } from "formik"
import * as Yup from "yup"
import { applicationConstant } from "../../../utils/constant/applicationConstant"
import InterfaceConstant from "../../../utils/constant/interfaceConstant"
import defaultAvatar from "../../../assets/images/default-avatar.png"
import { getTeacherBookingData, sendEmailToAdmin } from "utils/services/userServices"
import { navigatetoUrl } from "utils/helper/helper"
import { useHistory, useLocation } from "react-router-dom"


interface Iprops {
    open: boolean,
    close: Function,
    teacherId: string,
    sessionId: string,
    resolveStatus: Function
}
type review = {
    rating: number
    isPrivate: boolean
    reviewTitle: string
    reviewText: string
    reviewerId: string
    reviewerName: string
    reviewerType: "tutor" | "student"
    reportedOn: string
    reportedBy: string
    reportText: string
    addresseeId: string
    submittedOn: string
    sessionId: string
    reports: Array<any>
}
const getTutorInfo = async (tutorId: string) => {
    return getTeacherBookingData(tutorId).then(data => {
        return data;
    })
}

const capitalizeName = (name: string): string => {
    if (name && name.length > 0) {
        return name.charAt(0).toUpperCase() + name.substring(1);
    } else {
        return "";
    }
}

const SessionFeedbackModal = ({ open, close, teacherId, sessionId, resolveStatus }: Iprops): JSX.Element => {

    const [tutorInfo, setTutorInfo] = useState<any>();
    const [tutorInfoHere, setTutorInfoHere] = useState<boolean>(false);
    const [isVisible, setIsVisible] = useState(open);
    const history = useHistory();
    const { userAuthData } = useSelector((state: any) => state.auth)
    const reviewDefaultData = {
        rating: 0,
        isPrivate: false,
        reviewTitle: "",
        reviewText: "",
        reviewerId: userAuthData.userId,
        reviewerName: userAuthData.firstName + " " + userAuthData.lastName,
        reviewerType: "STUDENT",
        reportedOn: "",
        reportedBy: "",
        reportText: "",
        addresseeId: teacherId,
        submittedOn: new Date(),
        sessionId: sessionId,
        reports: [],
    }
    const [reviewData, setReviewData] = useState(reviewDefaultData)
    const [rating, setRating] = useState(0)
    const [step, setStep] = useState<number>(1)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const location = useLocation()
    const appErrorLabels: InterfaceConstant.IappErrorLabels = applicationConstant.appErrorLabels

    const getQueryParam = useCallback(
        (name: string) => {
            const urlParams = new URLSearchParams(location.search)
            return urlParams.get(name)
        },
        [location.search],
    )

    useEffect(() => {
        if (teacherId) {
            getTutorInfo(teacherId).then((result: any) => {
                setTutorInfo(result.data);
                setTutorInfoHere(true);
            }).catch(console.error);
        }
    }, [teacherId]);

    useEffect(() => {
        if (localStorage.getItem('platform') || getQueryParam("platform")) {
            setIsVisible(false);
        } else {
            setIsVisible(open);
        }
    }, [open]);

    useEffect(() => {

        const newreviewDefaultData = {
            rating: 0,
            isPrivate: false,
            reviewTitle: "",
            reviewText: "",
            reviewerId: userAuthData.userId,
            reviewerName: userAuthData.firstName + " " + userAuthData.lastName,
            reviewerType: "STUDENT",
            reportedOn: "",
            reportedBy: "",
            reportText: "",
            addresseeId: teacherId,
            submittedOn: new Date(),
            sessionId: sessionId,
            reports: [],
        }
        setReviewData(newreviewDefaultData);
    }, [teacherId, sessionId])

    const handleCloseModal = (stepnumber: number): void => {
        if (stepnumber == 1) {
            return;
        }
        setIsLoading(false)
        setReviewData(reviewDefaultData)
        setStep(1)
        setRating(0)
        close()
    }
    const sendEmailtoAdmin = async (reviewtext: string) => {
        const messageNow =
            '<h1> Hi Admin, </h1>' + '<p>' + reviewtext + '</p>' + '<h2> Regards,</h2>' + '<h3>' + userAuthData.firstName + ' ' + userAuthData.lastName + '</h3>' + '<h3>' + userAuthData.email + '</h3>' + '<h3>' + 'User type : ' + userAuthData.userType + '</h3>'

        await sendEmailToAdmin('Personal Feedback', messageNow);
    }
    const formik = useFormik({
        initialValues: {
            reviewText: "",
            reviewTitle: "",
        },
        validationSchema: Yup.object().shape({
            reviewText: Yup.string().max(1000, appErrorLabels.GLOBAL_MAX_1000),
            reviewTitle: Yup.string().max(50, appErrorLabels.GLOBAL_MAX_50),
        }),
        onSubmit: (values: any) => {
            try {
                setIsLoading(true)
                createReview(reviewData, userAuthData.userId, teacherId, sessionId).catch(console.error);
                setReviewData(reviewDefaultData)
                setRating(0)
                setStep(2)
                resolveStatus(sessionId);
            } catch (error) {

            }
        },
    })
    const handleChange = (e: any, type: "reviewText" | "reviewTitle"): void => {
        if (type === "reviewText") {
            formik.setFieldValue("reviewText", e.target.value, true)
            setReviewData({
                ...reviewData,
                reviewText: e.target.value,
            })
        } else {
            formik.setFieldValue("reviewTitle", e.target.value, true)
            setReviewData({
                ...reviewData,
                reviewTitle: e.target.value,
            })
        }
    }

    const handleRating = (rating: number): void => {
        setReviewData({
            ...reviewData,
            rating: rating,
        })
        setRating(rating)
    }
    const renderSteps = (): JSX.Element | void => {
        switch (step) {
            case 1:
                return (
                    <>  <div className='noCloseButton'>
                        <div className="avatar">
                            <StudentTutorAvatar
                                size="100"
                                student={
                                    userAuthData.profilePic.downloadURL
                                        ? userAuthData.profilePic.downloadURL
                                        : defaultAvatar
                                }
                                tutor={
                                    tutorInfo
                                        ? tutorInfo.profilePic.downloadURL
                                        : ""
                                }
                            />
                        </div>
                        <div className="title">
                            <h3>
                                Hi {userAuthData.firstName}, how was your tutoring session with{" "}
                                {tutorInfo ? capitalizeName(tutorInfo.firstName) : ""}  ?
                            </h3>
                        </div>
                        <div className="rates">
                            <h5><b>Rate your session</b></h5>
                            <Rating rating={rating} clickAction={handleRating} />
                        </div>
                        <form onSubmit={formik.handleSubmit}>
                            <div className="comments">
                                {/* <InputLabel>Write a review (required)</InputLabel>
                                <InputBox
                                    type={"text"}
                                    title={"Title"}
                                    placeholder={"Type a title for your review"}
                                    inputProps={{
                                        name: "review",
                                        value: reviewData.reviewTitle,
                                        onChange: (e: React.ChangeEvent<HTMLInputElement>): void =>
                                            handleChange(e, "reviewTitle"),
                                    }}
                                    error={
                                        formik.touched.reviewTitle && formik.errors.reviewTitle
                                            ? formik.errors.reviewTitle
                                            : null
                                    }
                                /> */}
                                <InputBox
                                    type={"textarea"}
                                    title={" Write a review (optional) "}
                                    placeholder={"Tell others about your learning experience..."}
                                    inputProps={{
                                        name: "review",
                                        value: reviewData.reviewText,
                                        onChange: (e: React.ChangeEvent<HTMLInputElement>): void =>
                                            handleChange(e, "reviewText"),
                                        onBlur: formik.handleBlur,
                                    }}
                                    error={
                                        formik.touched.reviewText && formik.errors.reviewText
                                            ? formik.errors.reviewText
                                            : reviewData.reviewText.length > 1000
                                                ? appErrorLabels.GLOBAL_MAX_1000
                                                : null
                                    }
                                />
                                <p className="maxCharacters"> 1000 characters maximum</p>
                                <SwitchComponent
                                    label="Keep my review private"
                                    active={reviewData.isPrivate}
                                    onTap={(): void =>
                                        setReviewData({
                                            ...reviewData,
                                            isPrivate: !reviewData.isPrivate,
                                        })
                                    }
                                />
                            </div>
                            <Button
                                className="centered-button"
                                isDisabled={reviewData.rating === 0}
                                isloading={isLoading}
                                clickAction={undefined}
                                title="Submit review"
                            />
                        </form>
                    </div>
                    </>
                )
            case 2:
                return (
                    <>
                        <div className="title step2">
                            <Grid container spacing={3}>
                                <Grid item md={3}>
                                    <img src={success} alt="" />
                                </Grid>
                                <Grid item md={9}>
                                    <h3>Your review was submitted</h3>
                                    <Button
                                        className="centered-button"
                                        isOutlineStyle
                                        clickAction={() => {
                                            close();
                                            navigatetoUrl(history, "/panel/search/tutor-profile")
                                        }}

                                        title={`Book a new session with a Tutor/Advisor`}
                                    />
                                </Grid>
                            </Grid>
                        </div>
                        <div className="gray-section">
                            <div className="title">
                                <span
                                    className="navigation text-center mt-4"
                                    onClick={(): void => setStep(3)}
                                >
                                    Help us improve! Give us your feedback.
                                </span>
                            </div>
                        </div>
                    </>
                )
            case 3:
                return (
                    <>
                        <div className="title">
                            <h3>
                                Your feedback is important to us
                                <span>Your message will only be seen by TCD Admin</span>
                            </h3>
                        </div>
                        <div className="comments">
                            <InputBox
                                type={"textarea"}
                                title={""}
                                placeholder={"Please write your feedback here"}
                                inputProps={{
                                    name: "reportText",
                                    value: reviewData.reportText,
                                    onChange: (e: React.ChangeEvent<HTMLInputElement>): void =>
                                        setReviewData({
                                            ...reviewData,
                                            reportText: e.target.value,
                                        }),
                                }}
                                error={null}
                            />
                        </div>
                        <Button
                            className="centered-button"
                            isDisabled={reviewData.reportText === ""}
                            clickAction={() => {
                                sendEmailtoAdmin(reviewData.reportText)
                                setStep(4)
                            }}
                            title="Send feedback"
                        />
                    </>
                )
            case 4:
                return (
                    <div className="title ptb-5">
                        <img src={ok} alt="" />
                        <h3>Thank you for your feedback</h3>
                    </div>
                )
            default:
                break
        }
    }
    if (tutorInfoHere) {
        return (
            <Modal
                open={isVisible}
                onClose={(): void => {
                    handleCloseModal(step)
                }}

                className="session-feedback custom-modal"
            >

                <div className="feedback-custom-modal-body">
                    <div className={`feedback-custom-modal-content ${step === 2 && "no-padding"} ${step === 1 && "noCloseButton"}`}>
                        <div
                            onClick={(): void => {
                                handleCloseModal(step)
                            }}
                            className={`close-modal-button ${step === 2 && "gray-bg"}`}
                        >
                            <CloseIcon />
                        </div>
                        {renderSteps()}
                    </div>
                </div>

            </Modal>
        )
    }
    else
        return (<></>)
}

export default SessionFeedbackModal
