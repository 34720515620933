import { createActionCreator } from "../../../libs/reducers/utils"
import {
    goalAndObjectiveStateType,
    subjectStateType,
    genderStateType,
    availabilityStateType,
    educationStateType,
    sortByStateType,
} from "../store"

import { TutorDetailType } from "../../types/tutorSearchTypes"
// Actions
export const fetchTeachersAction = createActionCreator("FETCH_TEACHERS")
export const filterTeachersByGoalsAndObjectivesAction = createActionCreator<
    goalAndObjectiveStateType
>("FILTER_TEACHERS_BY_GOALS_OBJECTIVE")
export const changeProgressStatusAction = createActionCreator<boolean>("FETCH_PROGRESS_STATUS")
export const filterTeacherBySubjectsAction = createActionCreator<subjectStateType[]>(
    "FILTER_TEACHERS_BY_SUBJECTS",
)
export const filterTeachersByHoursRateAction = createActionCreator<subjectStateType>(
    "FILTER_TEACHERS_BY_HOURS_RATES",
)
export const filterTeacherByGenderAction = createActionCreator<string>("FILTER_TEACHERS_BY_GENDER")
export const filterTeachersByAvailabilityAction = createActionCreator<availabilityStateType>(
    "FILTER_TEACHERS_BY_AVAILABILITY",
)
export const filterTeachersBySelectAllAvailabilityAction = createActionCreator<boolean>(
    "FILTER_TEACHERS_BY_SELECT_ALL_AVAILABILITY",
)
export const filterTeacherByEducationAction = createActionCreator<string>(
    "FILTER_TEACHERS_BY_EDUCATION",
)
export const filterTeacherByBackgroundCheckAction = createActionCreator<boolean>(
    "FILTER_TEACHERS_BY_BACKGROUND_CHECK",
)
export const resetAllFiltersAction = createActionCreator("RESET_ALL_FILTERS")
export const changeSortByTypeAction = createActionCreator<sortByStateType>("SORT_BY_TYPE")
export const changeSortTypeDirectionAction = createActionCreator<boolean>("SORT_TYPE_DIRECTION")
export const filterTeacherByMyFavoritesAction = createActionCreator<boolean>(
    "FILTER_TEACHERS_BY_MY_FAVORITES",
)
export const setSelectedTutor = createActionCreator<TutorDetailType>("SET_SELECTED_TUTOR")
