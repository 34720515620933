import {
    tutorConstant,
} from "../constant"

export const fetchTutor = (props: any) => {
    return {
        type: tutorConstant.REQUEST_TUTOR,
        payload: props
    }
}

export const fetchTutorsSuccess = (props: any) => {
    return {
        type: tutorConstant.REQUEST_TUTOR_SUCCESS,
        payload: props
    }

}
export const fetchTutorsFailure = (props: any) => {
    return {
        type: tutorConstant.REQUEST_TUTOR_FAILURE,
        payload: props
    }
}
