import React from "react"
import { useHistory, Link } from "react-router-dom"
import { useTranslation } from 'react-i18next';
import { navigatetoUrlWithProps } from "../../utils/helper/helper"


const References = ({ otherLessons, moduleId, lessonId }: { otherLessons: any, moduleId: any, lessonId: any }): JSX.Element => {
    let history = useHistory()
    const { t } = useTranslation();
    return (
        <>
            {otherLessons ? (
                <div className="module-references">
                    <p className="-XXL-Medium-Left-Dark-Copy-10">{t('references')}</p>
                    {otherLessons.map((lesson: any, index: number) => (
                        <p
                            onClick={() => { navigatetoUrlWithProps(history, `/panel/lessons/` + lesson.sys.id, { prevModuleId:moduleId, prevLessonId:lessonId }) }}
                        >
                            <Link className="-Medium-Regular-Left-Dark-ref" to={lesson.sys.id}>{lesson.fields?.lessonTitle}</Link>
                        </p>
                    ))}
                </div>
            ) : (
                <></>
            )}
        </>
    )
}

export default References