import React from "react"
import TutorSearch from "./TutorSearch"

export const defaultFilters = []

const TutorSearchPage = (): JSX.Element => {
    return (
        <TutorSearch></TutorSearch>
    )
}

export default TutorSearchPage
