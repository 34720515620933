import { all } from "redux-saga/effects"

import userAuthentication from "./userSaga"
import tutorAuthentication from "./tutorSignupSaga"
import sessionSaga from "./sessionSaga"
import messagesSaga from "./messageSaga"
import paymentSaga from "./paymentSaga"
import lessonSaga from "./lessonSaga"
import moduleSaga from "./moduleSaga"
import subscriptionSaga from "./subscriptionSaga"
import tutorSearchSaga from "./tutorSearchSaga"
import tutorSaga from "./tutorSaga"
import paymentHistorySaga from "./paymentHistorySaga"
import studentDashboardSaga from "./studentDashboardSaga";
import rapidBookingSaga from "./rapidBookingSaga";

export default function* rootSaga() {
    return yield all([
        userAuthentication,
        tutorAuthentication,
        sessionSaga,
        messagesSaga,
        paymentSaga,
        lessonSaga,
        moduleSaga,
        subscriptionSaga,
        tutorSearchSaga,
        tutorSaga,
        paymentHistorySaga,
        studentDashboardSaga,
        rapidBookingSaga,
    ]);
}
