import { TutorCancelPolicyconstant, LogoutConstant } from "../../constant"

const initialState = {
    freeCancellationBeforeHours: undefined,
    chargesAfterFreeCancellationHours: undefined,
    advanceScheduledSessionHours: undefined,
    freeCancellationOfAdvanceScheduledSessionHours: undefined,
    chargesAfterFreeAdvanceScheduledSessionCancellationHours: undefined,
}

const tutorCancellationPolicyReducer = (state = initialState, action: any): object => {
    switch (action.type) {
        case TutorCancelPolicyconstant.SET_CANCEL_POLICY:
            return {
                freeCancellationBeforeHours: action.props.freeCancellationBeforeHours,
                chargesAfterFreeCancellationHours: action.props.chargesAfterFreeCancellationHours,
                advanceScheduledSessionHours: action.props.advanceScheduledSessionHours,
                freeCancellationOfAdvanceScheduledSessionHours:
                    action.props.freeCancellationOfAdvanceScheduledSessionHours,
                chargesAfterFreeAdvanceScheduledSessionCancellationHours:
                    action.props.chargesAfterFreeAdvanceScheduledSessionCancellationHours,
            }
         case LogoutConstant.CLEAR_DATA:
             return initialState   
        default:
            return state
    }
}

export default tutorCancellationPolicyReducer
