import colors from "assets/colors"
import React from "react"
import interfaceConstant from "../../../utils/constant/interfaceConstant"

class HelpPageModalSvgIcon extends React.PureComponent<interfaceConstant.SvgIconProps> {
    render(): JSX.Element {
        const { width, height, ...otherProps } = this.props
        return (
            <svg width={width} height={height} viewBox="0 0 145 145" {...otherProps}>
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g>
                        <path
                            d="M72.5,0 C32.4603827,0 0,32.4603827 0,72.5 C0,112.539617 32.4603827,145 72.5,145 C112.539617,145 145,112.539617 145,72.5 C145,32.4603827 112.539617,0 72.5,0 Z"
                            id="Shape"
                            fill={colors.darkMango}
                        ></path>
                        <g transform="translate(30.000000, 30.000000)">
                            <path
                                d="M82.0424912,20.2932982 C80.0011228,16.682807 77.4905263,13.3785965 74.5785965,10.4666667 C71.6621404,7.55021053 68.3549123,5.03961404 64.7519649,3.00277193 L64.0096491,2.58333333 C63.5721053,2.33438596 63.0168772,2.47319298 62.7422807,2.89564912 L62.1040702,3.88238596 L62.1040702,3.88389474 L52.0858246,19.3669123 C52.0858246,19.3684211 52.0843158,19.3684211 52.0843158,19.3699298 L51.8640351,19.7124211 C51.6633684,20.0217193 51.7025965,20.3234737 51.7433333,20.4758596 C51.7840702,20.6282456 51.9017544,20.9088772 52.2291579,21.0763509 L53.1253684,21.5335088 C55.364386,22.6756491 57.3921754,24.1436842 59.148386,25.9014035 C60.9061053,27.6606316 62.3756491,29.6869123 63.5177895,31.9259298 L63.9734386,32.8191228 C64.1409123,33.1465263 64.4215439,33.2642105 64.5739298,33.3049474 C64.7263158,33.3456842 65.0280702,33.3849123 65.3373684,33.1857544 L82.1526316,22.306 C82.5750877,22.0329123 82.712386,21.4746667 82.4649474,21.0371228 L82.0424912,20.2932982 Z"
                                id="Path"
                                fill="#262FFA"
                            ></path>
                            <path
                                d="M20.6294386,2.58182456 L19.8871228,3.00126316 C16.2841754,5.03810526 12.9769474,7.54870175 10.0589825,10.4651579 C7.1485614,13.3740702 4.63645614,16.6797895 2.59508772,20.2917895 L2.17414035,21.0341053 C1.92670175,21.4716491 2.064,22.028386 2.48645614,22.3014737 L2.48645614,22.3014737 L3.47168421,22.9396842 L3.47319298,22.9396842 L15.9944912,31.0417895 L19.3002105,33.1812281 C19.6110175,33.3818947 19.9127719,33.3426667 20.0651579,33.3019298 C20.2175439,33.261193 20.4981754,33.1435088 20.6656491,32.8161053 L21.1212982,31.9214035 C22.2619298,29.682386 23.7314737,27.6561053 25.489193,25.8968772 C27.2484211,24.1391579 29.273193,22.669614 31.5137193,21.5289825 L32.4069123,21.0733333 C32.7358246,20.9058596 32.8535088,20.6252281 32.8942456,20.4728421 C32.9349825,20.3204561 32.9742105,20.0187018 32.7750526,19.7094035 L32.5457193,19.3563509 L32.5457193,19.3563509 L22.5501053,3.90652632 L22.5501053,3.90652632 L21.8952982,2.89414035 C21.6237193,2.47168421 21.0669825,2.33589474 20.6294386,2.58182456 Z"
                                id="Path"
                                fill="#262FFA"
                            ></path>
                            <path
                                d="M21.896807,82.5572982 L22.5335088,81.5720702 L22.5335088,81.5705614 L27.758386,73.495614 L32.7750526,65.7420351 C32.9757193,65.4327368 32.9364912,65.1309825 32.8957544,64.9785965 C32.8550175,64.8262105 32.7373333,64.5455789 32.4099298,64.3781053 L31.5152281,63.9224561 C29.2777193,62.7833333 27.2499298,61.3137895 25.4922105,59.5545614 C23.7344912,57.7968421 22.2649474,55.7705614 21.122807,53.5300351 L20.6671579,52.6368421 C20.4996842,52.3094386 20.2190526,52.1917544 20.0666667,52.1510175 C20.0032982,52.1344211 19.9172982,52.1178246 19.8147018,52.1178246 C19.6653333,52.1178246 19.4857895,52.1525263 19.3017193,52.2717193 L2.48796491,63.1514737 C2.06550877,63.4245614 1.92821053,63.9812982 2.17564912,64.4188421 L2.59508772,65.1626667 C4.63796491,68.7746667 7.15007018,72.080386 10.0589825,74.9877895 C12.9754386,77.9042456 16.2826667,80.4148421 19.885614,82.4516842 L19.885614,82.4516842 L20.6279298,82.8711228 C21.0654737,83.1140351 21.6237193,82.9797544 21.896807,82.5572982 Z"
                                id="Path"
                                fill="#262FFA"
                            ></path>
                            <path
                                d="M16.3445263,50.5879298 C14.8040702,45.4762105 14.8040702,39.9752281 16.3445263,34.8635088 L3.31929825,26.4355088 C-1.04708772,36.8792281 -1.04708772,48.5722105 3.31929825,59.0159298 L16.3445263,50.5879298 Z"
                                id="Path"
                                fill="#FFFFFF"
                            ></path>
                            <path
                                d="M26.0459298,3.75112281 L34.452807,16.7431579 C37.0056491,15.9767018 39.6565614,15.5934737 42.3059649,15.5934737 C44.9644211,15.5934737 47.6213684,15.9797193 50.1817544,16.7507018 L58.6097544,3.72547368 C48.1871579,-0.627333333 36.5017193,-0.618280702 26.0459298,3.75112281 Z"
                                id="Path"
                                fill="#FFFFFF"
                            ></path>
                            <path
                                d="M81.3182807,26.4355088 L68.2930526,34.8635088 C69.8335088,39.9752281 69.8365263,45.4762105 68.3005965,50.5924561 L81.2926316,58.9993333 C85.6635439,48.5435439 85.6741053,36.8581053 81.3182807,26.4355088 Z"
                                id="Path"
                                fill="#FFFFFF"
                            ></path>
                            <path
                                d="M64.0081404,82.8681053 L64.7504561,82.4486667 C68.3564211,80.4103158 71.6621404,77.8997193 74.5770877,74.9847719 C77.4875088,72.0743509 79.999614,68.7686316 82.0409825,65.1581404 L82.4604211,64.4158246 C82.7078596,63.9782807 82.5705614,63.4215439 82.1481053,63.1484561 L66.1717193,52.8103509 L65.6858947,52.4965263 L65.6858947,52.4965263 L65.3328421,52.2687018 C65.0235439,52.0695439 64.7217895,52.1072632 64.5694035,52.1495088 C64.4170175,52.1902456 64.136386,52.3079298 63.9689123,52.6353333 L63.5132632,53.5300351 C62.3726316,55.7690526 60.9030877,57.7953333 59.1453684,59.5530526 C57.3876491,61.3107719 55.3613684,62.7803158 53.1223509,63.9209474 L52.2261404,64.3765965 C51.8987368,64.5440702 51.7810526,64.8247018 51.7403158,64.9770877 C51.6995789,65.1294737 51.6603509,65.4327368 51.8610175,65.7405263 L52.0843158,66.0860351 L52.0843158,66.0860351 L52.6289825,66.9279298 L62.7422807,82.5572982 C63.0153684,82.9782456 63.5705965,83.1170526 64.0081404,82.8681053 Z"
                                id="Path"
                                fill="#262FFA"
                            ></path>
                            <path
                                d="M51.0689123,70.0707018 L50.1817544,68.6992281 C45.0685263,70.2381754 39.5690526,70.2381754 34.4573333,68.6992281 L29.8706667,75.7874386 L26.0293333,81.7244561 C36.4730526,86.0908421 48.1660351,86.0908421 58.6097544,81.7244561 L51.0689123,70.0707018 Z"
                                id="Path"
                                fill="#FFFFFF"
                            ></path>
                        </g>
                    </g>
                </g>
            </svg>
        )
    }
}

export default HelpPageModalSvgIcon
