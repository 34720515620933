import React, { useCallback, useState } from "react";

/**
 * a basic interface of a single handler
 */
export type StepHandlerType = (data: any, sharedState: any, setSharedState: React.Dispatch<any>) => Promise<any>;

/**
 * this is the actual interface of defining steps and their handlers
 * our useStepwiseExecution hook accept this interface to register all
 * steps and their handlers
 * 
 * Note: each index in the main array is a step where are all
 * elements in the child array of handlers of that step
 * 
 * Note: output of first step will be input of next handler. The
 * second parameter is shared state which can be accessed. The
 * third parameter is shared state setter. Through which you can
 * update the shared state between all handlers of all step
 * 
 * Note: if you want to break the loop of handlers then
 * just throw an error because otherwise a step will be
 * considered complete when its all handlers run successfully
 * without any error
 * 
 * Note: all the handlers must be async of return promise
 *
 * e.g
 * 
 *  const StepHandlers: StepsAndHandlersMapType = [
 *
 *       [
 *          //handlers of this step
 *       ], //first step
 *
 *       [
 *          //handlers of this step
 *       ], //second step
 *       
 *       [
 *          //handlers of this step
 *       ], //third step
 *  ];
 * 
 * 
 * */
export type StepsAndHandlersMapType = Array<Array<StepHandlerType>>;


/**
 * Usage Example:
 * const startStep = 0;
 * const stepsAndHandlers = [
 *      [
 *          (previousOutput, state, setState) => { return newOutput;},
 *          (previousOutput, state, setState) => { return newOutput2;}
 *      ], //first step and their handlers
 * 
 *      [
 *          (previousOutput, state, setState) => { return newOutput;},
 *          (previousOutput, state, setState) => { return newOutput2;}
 *      ] //second step and their handlers
 * ];
 * const {
 *      currentStep,
 *      execute,
 *      isCompleted,
 *      isSuccess,
 *      isLoading,
 *      next,
 *      isAllDone
 * } = useStepwiseExecution(startStep, stepsAndHandlers);
 * 
 * @param initialStep execution will start from this step
 * @param stepsHandlers an array of steps and their handlers. this will
 * set how many steps are there
 */
const useStepwiseExecution = (initialStep = 0, stepsHandlers: StepsAndHandlersMapType) => {
    const [currentStep, setCurrentStep] = useState(initialStep);
    const [stepsAndHandlers, setStepsAndHandlers] = useState(stepsHandlers);
    const [lastStepOutput, setLastStepOutput] = useState<any>(undefined);
    const [isLoading, setLoading] = useState<boolean>(false);
    const [isCompleted, setIsCompleted] = useState<boolean>(false);
    const [isSuccess, setIsSuccess] = useState<boolean>(false);
    const [isAllDone, setIsAllDone] = useState<boolean>(false);
    const [sharedState, setSharedState] = useState<any>({});

    /**
     * this function will move to next step
     * only if the previous step gets successfully
     * executed. If force is true then it will move
     * to the next does not matter whether the previous
     * step was successfully or not
     * 
     * @param force 
     */
    const next = useCallback((force = false) => {
        if (force || (isCompleted && isSuccess)) {
            //if is all steps executed
            if (currentStep === stepsAndHandlers.length - 1) {
                setIsAllDone(true);
            } else {
                setCurrentStep((previousStep) => {
                    return previousStep + 1;
                });
                setIsCompleted(false);
                setIsSuccess(false);
                setLoading(false);
            }
        }
    }, [currentStep, isCompleted, isSuccess, stepsAndHandlers]);

    /**
     * this function will execute all handlers
     * of the current step one by one and will
     * return the final output of tha last handler
     * of the current step.
     * 
     * Note: if the current step is already executed
     * successfully and you try to execute it again it will
     * not execute it again and will just return the output
     * of last execution
     * 
     * Note: if you really want to execute the current step
     * again then send force parameter as true
     * 
     * @param force boolean by default it is false
     * 
     */
    const execute = useCallback(async (force = false) => {
        if (stepsAndHandlers.length < 1) {
            return null;
        }
        if (!force && isCompleted && isSuccess) {
            return lastStepOutput;
        }
        try {
            setLoading(true);
            const finalOutput = await stepsAndHandlers[currentStep].reduce(async (previousOutputPromise, nextHandler) => {
                const previousOutput = await previousOutputPromise;
                return await nextHandler(previousOutput, sharedState, setSharedState);
            }, Promise.resolve(lastStepOutput));
            setLoading(false);
            setLastStepOutput(finalOutput);
            setIsCompleted(true);
            setIsSuccess(true);
            return finalOutput;
        } catch (e) {
            setLoading(false);
            setIsCompleted(true);
            setIsSuccess(false);
            throw e;
        }
    }, [currentStep, stepsAndHandlers, isCompleted, isSuccess, sharedState, lastStepOutput]);

    return {
        currentStep,
        execute,
        isCompleted,
        isSuccess,
        isLoading,
        next,
        isAllDone,
        setSharedState,
        sharedState
    }
}

export default useStepwiseExecution;