import React, { useCallback, useEffect, useState } from "react";
import { BookingPending, PendingType, PENDING_STATUS, useBookingPending } from "../../../../pendings";
import { singleSlotBookingByStudentType, SingleSlotBookingRequested, slotType } from "./SingleSlotBookingRequested";
import { useHistory } from "react-router-dom";
import moment from "moment";
import defaultAvatar from "assets/images/default-avatar.png";
import { useDispatch, useSelector } from "react-redux";
import { getPendingBookings } from "store/actions/sessionsActions";
import { StudentRescheduleRequest } from "./rescheduling/StudentRescheduleRequest";
import firebase from "firebase";

export default function SingleSlotBookingRequestedContainer() {

    const bookingPending: BookingPending = useBookingPending();
    const history = useHistory();
    const dispatch = useDispatch()
    const [isShown, setIsShown] = useState(false);
    const [data, setData] = useState<singleSlotBookingByStudentType>({
        id: "",
        studentName: "",
        studentPicUrl: defaultAvatar,
        studentNote: "",
        startTime: "",
        sessionLength: 0,
        isRescheduleRequest: false,
        rescheduleRequestedBy: "",
        slots: []
    });
    const [currentPendingItem, setCurrentPendingItem] = useState<PendingType | undefined>(undefined);

    const { userId } = useSelector((state: any) => state.auth.userAuthData);

    const updateBookingData = (bookingRequest: any) => {
        const slot: slotType = bookingRequest.slots.pop();

        setData({
            id: bookingRequest.id,
            studentName: bookingRequest.studentName,
            studentNote: bookingRequest.comment,
            startTime: moment(slot.startTime).format("MMMM DD, YYYY hh:mm A"),
            sessionLength: slot.sessionLength,
            studentPicUrl: bookingRequest.studentProfilePicture || defaultAvatar,
            isRescheduleRequest: bookingRequest?.isRescheduleRequest,
            rescheduleRequestedBy: bookingRequest?.rescheduleMeta?.rescheduledBy,
            originalStartTime: bookingRequest?.rescheduleMeta?.previousStartTime,
            slots: [slot]
        });
        setIsShown(true);
        dispatch(getPendingBookings())
    }

    const handleOneBookingPendingItem = (pendingItem: PendingType) => {
        const bookingRequest = pendingItem.data;
        if (!bookingRequest) {
            return;
        }
        if (bookingRequest.slots.length === 1 && pendingItem.status === PENDING_STATUS.CREATED) {
            updateBookingData(bookingRequest);
            setCurrentPendingItem(pendingItem);
        }
    }

    useEffect(() => {
        const pendingList = (snapshot: firebase.database.DataSnapshot) => {
            const pendingItem: PendingType = snapshot.val();
            if (pendingItem) {
                handleOneBookingPendingItem(pendingItem);
            }
        }
        bookingPending.ref.on("child_added", pendingList);
        return () => bookingPending.ref.off("child_added", pendingList);
    }, [bookingPending]);

    const onClose = useCallback(() => {
        currentPendingItem && bookingPending.resolveItem(currentPendingItem.id);
        setIsShown(false);
    }, [currentPendingItem, isShown, data]);

    const onGotoMySession = useCallback(() => {
        onClose();
        history && history.push('/panel/session')
    }, [isShown, data, currentPendingItem])

    return <>
        {data.isRescheduleRequest ?
            <>
                {data.rescheduleRequestedBy !== userId &&
                    <StudentRescheduleRequest {...{
                        ...data,
                        isShown,
                        onClose,
                        setIsShown
                    }} />}
            </>
            :
            <SingleSlotBookingRequested {...{
                ...data,
                onGotoMySession,
                isShown,
                onClose
            }} />
        }
    </>
}