import React, { useEffect } from "react";

import { getStripeAccountLinkUrl } from "../../utils/services/paymentServices";
import LoaderSvgIcon from "../../assets/images/svgIcons/LoaderSvgIcon";
import { useHistory } from "react-router";

export default function RefreshStripeAccountLink() {
    const history = useHistory();
    useEffect(() => {
        getStripeAccountLinkUrl().then(({ accountURL }) => {
            window.open(accountURL, "_self");
        }).catch((e) => {
            console.error("Error while fetching refreshed stripe account link: ", e);
            history.push("/");
        });
    }, []);
    return (
        <div className="backDrop">
            <div className="wrapper">
                <LoaderSvgIcon />
            </div>
        </div>
    );
}