import React, { useState, useEffect } from "react"
import { useTranslation } from 'react-i18next';
import Button from "../../../../components/button/Button"
import _ from "lodash"
import ImageDropDown from "../../../../components/input-field/ImageDropDown"
import { Checkbox } from "@material-ui/core"
import { InputBox } from "../../../../components/input-field/InputFields"
import AddPaymentModal from "../../../../components/add-card/AddPaymentModal"
import { addCardDetails, paymentMethod } from "../../../../store/actions/PaymentActions"
import { useSelector, useDispatch } from "react-redux"
import firebase from "firebase"
import { useFormik } from "formik"
import * as Yup from "yup"
import { applicationConstant } from "../../../../utils/constant/applicationConstant"
import InterfaceConstant from "../../../../utils/constant/interfaceConstant"
import MessageAlert from "../../../../components/message-alert/MessageAlert"
import { useHistory } from "react-router-dom"
import Modal from "../../../../components/modal_/Modal"
import { PaymentConstant } from "../../../../store/constant"
import { navigatetoUrl, targetForRedirection } from "utils/helper/helper";
import PrivacyModal from "pages/home/booking/resume-confirm/PrivacyModal";
import { externalLinks } from "utils/constant/externalLinks";
import { getUpdatedUserMeta } from "utils/services/userServices";
import Wallet from "../../../../components/wallet/wallet";


interface User {
    password: string
    card: string
}

const LessonSubscribeForm = ({ plan, dontRenew, promoCodeId, applyingPromo }: { plan: any, dontRenew: boolean, promoCodeId: string | undefined, applyingPromo?: boolean }): JSX.Element => {
    const dispatch = useDispatch()
    const history = useHistory()
    const { t } = useTranslation()
    const { userAuthData } = useSelector((state: any) => state.auth)
    const { payment } = useSelector((state: any) => state)
    const addStudentCard = (data: any): any => {
        dispatch(addCardDetails(data))
    }
    const { alreadySubscribed, subscribing } = useSelector((state: any) => state.payment)
    const [addPaymentModalOpen, setAddPaymentModalOpen] = useState(false)
    const [privacyModalOpen, setPrivacyModalOpen] = useState(false)
    const [isShowError, setShowError] = useState(false)
    const [isPasswordError, setPasswordError] = useState(false)
    const [isTermsAcceptedError, setTermsAcceptedError] = useState(false)
    const [acceptTerms, setAcceptTerms] = useState(false)
    const [receiveMarketingMaterials, setReceiveMarketingMaterials] = useState(true)
    const [password, setPassword] = useState("")
    const [openSnackbar, setOpenSnackbar] = useState(false)
    const cardList = userAuthData.stripeCards ? userAuthData.stripeCards : []
    const [cardOption, setCardOption] = useState({
        error: "Add a card",
        value: cardList[cardList.length - 1],
    })
    const [showDropdown, setShowDropdown] = useState(false)
    const signInErrorLabel: InterfaceConstant.IappErrorLabels = applicationConstant.appErrorLabels
    const bookingLabel: InterfaceConstant.IbookingLabel = applicationConstant.bookingLabel

    const [message, setMessage] = useState({
        message: payment.message,
        type: payment.type,
    })

    useEffect(() => {
        getUpdatedUserMeta(userAuthData.userId).then(data => {
            dispatch({ type: PaymentConstant.UPDATE_DEFAULT_SOURCE, payload: data?.stripeCustomer?.default_source })
        }).catch(e => e);
    }, [])

    useEffect(() => {
        setCardOption({
            error: t('add-a-card'),
            value: cardList[cardList.length - 1],
        });
    }, [cardList.length])
    useEffect(() => {
        setMessage({ message: payment.message, type: payment.type })
        payment.message && setAddPaymentModalOpen(false)
    }, [payment])
    const closeDropDown = (status: boolean) => {
        setShowDropdown(status)
    }
    const formik = useFormik({
        initialValues: {
            password: "",
            card: "",
        },
        validationSchema: Yup.object().shape({
            password: Yup.string().required(signInErrorLabel.GLOBAL_REQUIRED),
        }),
        onSubmit: (values: User) => {
            if (cardOption.value === undefined) {
                setShowError(true)
                return
            }
        },
    })
    const navigateNextPage = async (formikObject: any) => {
        await formikObject.validateForm()
        if (!acceptTerms) {
            setTermsAcceptedError(true)
            return;
        }
        setTermsAcceptedError(false)
        _.some(formikObject.values, (item) => item === "")
        await firebase
            .auth()
            .signInWithEmailAndPassword(userAuthData.email, password)
            .then(() => {
                const errors = formikObject.errors
                let valuesError = false
                Object.values(errors).map((value) => (value ? (valuesError = true) : ""))
                if (cardOption.value === undefined || valuesError) {
                    setShowError(true)
                    return false
                } else {
                    setPasswordError(false)
                    dispatch(paymentMethod({ data: { price: plan.id, card: cardOption.value.id, product: plan.productId, renew: !dontRenew, promoCodeId: promoCodeId }, history: history }))
                }
            })
            .catch(() => {
                setPasswordError(true)
            })
    }
    const handleChange = (e: any) => {
        setPassword(e.target.value)
        setPasswordError(false)
        formik.setFieldValue("password", e.target.value, true)
    }


    return (
        <>
            <AddPaymentModal
                open={addPaymentModalOpen}
                close={(): void => setAddPaymentModalOpen(false)}
                clickAction={(token: any): void =>
                    addStudentCard({ token, customerId: userAuthData.stripeCustomer.id })
                }
                isLoading={payment.isLoading}
            />
            <Modal
                visible={alreadySubscribed}
                onExit={() => dispatch({ type: PaymentConstant.ALREADY_SUBSCRIBED, payload: false })}
                title="Already Subscribed"
                subtitle="You have already an active subscription for lessons."
                buttons={[{ title: "Go To Lessons", clickAction: () => history.push("/panel/lessons") }]}
            >
            </Modal>
            <form onSubmit={formik.handleSubmit}>
                <div className="header">
                    <p className={'title-right'}>{bookingLabel.RESUME_TITLE_RIGHT}</p>
                    <Wallet/>
                    <ImageDropDown
                        title="Pay With"
                        showDropdown={showDropdown}
                        optionsList={[...cardList]?.reverse()}
                        closeDropDownAction={closeDropDown}
                        fieldDetails={cardOption}
                        setProps={setCardOption}
                        error={cardOption.value === undefined ? isShowError : false}
                        placeholder={t("Add-Card-Details")}
                        setAddPaymentModalOpen={setAddPaymentModalOpen}
                    />
                    {/* <span
                        className="navigation addnewcard"
                        onClick={(): void => setAddPaymentModalOpen(true)}
                    >
                        {t("Add-new-card")}
                    </span> */}
                    <div className="password-section">
                        <p className="input-label">
                            {bookingLabel.RESUME_PASSWORD_LABEL}
                        </p>
                        <InputBox
                            type={"password"}
                            className={"resume-page-input"}
                            title={
                                bookingLabel.RESUME_PASSWORD_SUBLABEL +
                                userAuthData.email
                            }
                            placeholder={bookingLabel.RESUME_PASSWORD_PLACEHOLDER}
                            inputProps={{
                                name: "password",
                                value: password,
                                onChange: handleChange,
                                onBlur: formik.handleBlur,
                            }}
                            error={
                                formik.touched.password && formik.errors.password
                                    ? formik.errors.password
                                    : isPasswordError
                            }
                        />
                    </div>
                </div>
                <div className="tcd-footer">
                    <p className="tcd-p">
                        <Checkbox
                            checked={acceptTerms}
                            onClick={() => setAcceptTerms(!acceptTerms)}
                            name="acceptedTerms"
                            color="secondary"

                        />
                        <span className="checkbox-label1">
                            {t('term1')}
                            {" "}
                            <a
                                className="navigation"
                                href={externalLinks.TERMS_OF_USE}
                                target={targetForRedirection()}
                                rel="noopener noreferrer"
                            >
                                {t('term2')}
                            </a>
                            ,{" "}
                            <p className="navigation" onClick={() => setPrivacyModalOpen(true)}>
                                {t('term3')}
                            </p>
                            {" "}
                            {t('term5')}

                            .
                        </span>
                        <small
                            style={{
                                display: isTermsAcceptedError ? "block" : "none",
                                color: "red",
                                marginLeft: 15,
                            }}
                        >
                            {t('accept-this')}
                        </small>
                    </p>
                    <p>
                        <Checkbox
                            checked={receiveMarketingMaterials}
                            onClick={() => setReceiveMarketingMaterials(!receiveMarketingMaterials)}
                            name="receiveMarketingMaterials"
                            color="secondary"
                        />
                        <div className="checkbox-label">
                            <span>
                                {t("receive-marketing-material")}
                            </span>
                        </div>
                    </p>
                    <Button
                        title={"Subscribe"}
                        clickAction={(): void => {
                            if (
                                cardOption.value === undefined ||
                                password.length === 0
                            ) {
                                setShowError(true)
                                return
                            } else {
                                navigateNextPage(formik).catch(console.error)
                            }
                        }}
                        isloading={subscribing}
                        isDisabled={subscribing || applyingPromo}
                    />
                </div>
            </form>
            <MessageAlert
                openSnackBar={payment.openSnackbar}
                setOpenSnackBar={setOpenSnackbar}
                message={message.message}
                type={message.type}
            />

            <PrivacyModal open={privacyModalOpen} close={() => setPrivacyModalOpen(false)} />
        </>
    )
}

export default LessonSubscribeForm

