import React, {memo} from "react"
import { ExitIcon } from "../icons/Icons"
import { Tooltip } from "@material-ui/core"
import colors from "assets/colors"

interface Iprops {
    title: string
    action: Function
    tooltip: string
}

const MiniCard = ({ title, action, tooltip }: Iprops): JSX.Element => {
    return (
        <div className="mini-card">
            {title}
            <Tooltip title={tooltip} placement="right">
                {ExitIcon({
                    style: { color: colors.darkMango, cursor: "pointer" },
                    onClick:() => action()
                })}
            </Tooltip>
        </div>
    )
}

export default memo(MiniCard)
