import colors from "assets/colors"
import React from "react"
import interfaceConstant from "../../../utils/constant/interfaceConstant"

class NoSearchResultSvgIcon extends React.PureComponent<interfaceConstant.SvgIconProps> {
    public static defaultProps = {
        width: "100%",
        height: "100%",
    }
    render(): JSX.Element {
        const { width, height, ...otherProps } = this.props
        return (
            <svg width={width} height={height} viewBox="0 0 145 145" {...otherProps}>
                <g fill="none" fillRule="evenodd">
                    <path
                        d="M72.5,0 C32.4603827,0 0,32.4603827 0,72.5 C0,112.539617 32.4603827,145 72.5,145 C112.539617,145 145,112.539617 145,72.5 C145,32.4603827 112.539617,0 72.5,0 Z"
                        fill={colors.darkMango}
                    />
                    <g transform="translate(29.000000, 30.000000)">
                        <path
                            d="M35.5,71 C42.8,71 49.5,68.8 55.1,65.1 L74,83.9 C75.4,85.3 77.2,86 79,86 C80.8,86 82.6,85.3 84,83.9 C86.7,81.2 86.7,76.7 84,74 L65,55.1 C68.8,49.5 70.9,42.7 70.9,35.5 C71,15.9 55.1,0 35.5,0 C15.9,0 0,15.9 0,35.5 C0,55.1 15.9,71 35.5,71 Z M35.5,14 C47.3,14 57,23.6 57,35.5 C57,47.3 47.3,57 35.5,57 C23.6,57 14,47.3 14,35.5 C14,23.6 23.6,14 35.5,14 Z"
                            fill="#FFF"
                            fillRule="nonzero"
                        />
                        <path
                            d="M69.9953761,0 C61.7111054,0.00255412095 54.9974476,6.72035294 55.0000007,15.0046238 C55.0025553,23.2888947 61.7203538,30.0025529 70.0046244,30 C78.2888951,29.9974457 85.0025533,23.2796473 85.0000007,14.9953764 C84.9867863,6.71552834 78.2752305,0.00810893399 69.9953761,0 Z"
                            fill="#262FFA"
                        />
                        <path
                            d="M2.34579856,2.35275075 C2.80686332,1.88241642 3.55439708,1.88241642 4.01546184,2.35275075 L4.01546184,2.35275075 L8,6.43080019 L11.9845382,2.35275075 C12.4456029,1.88241642 13.1931367,1.88241642 13.6542014,2.35275075 C14.1152662,2.82308509 14.1152662,3.58564784 13.6542014,4.05598218 L13.6542014,4.05598218 L9.798,7.999 L13.6542014,11.9440178 C14.1152662,12.4143522 14.1152662,13.1769149 13.6542014,13.6472492 C13.1931367,14.1175836 12.4456029,14.1175836 11.9845382,13.6472492 L8,9.56919981 L4.01546184,13.6472492 C3.55439708,14.1175836 2.80686332,14.1175836 2.34579856,13.6472492 C1.88473381,13.1769149 1.88473381,12.4143522 2.34579856,11.9440178 L6.201,7.999 L2.34579856,4.05598218 C1.91766701,3.61924315 1.88708618,2.93053167 2.25405609,2.45779657 Z"
                            fill="#FFF"
                            transform="translate(62.000001, 7.000000)"
                        />
                    </g>
                </g>
            </svg>
        )
    }
}

export default NoSearchResultSvgIcon
