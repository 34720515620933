import { RapidBookingConstant } from "../constant"

export const getRapidBookingAvailability = (userId: string) => {
    return { type: RapidBookingConstant.GET_RAPID_BOOKING_AVAILABILITY, payload: { userId } }
}

export const setRapidBookingAvailability = (isAvailable: boolean) => {
    return { type: RapidBookingConstant.SET_RAPID_BOOKING_AVAILABILITY, payload: { isAvailable } }
}

export const updateRapidBookingAvailability = (userId: string, isAvailable: boolean) => {
    return { type: RapidBookingConstant.UPDATE_RAPID_BOOKING_AVAILABILITY, payload: { userId, isAvailable } }
}

export const setRapidBookingLoadingStatus = (status: boolean) => {
    return {
        type: RapidBookingConstant.SET_RAPID_BOOKING_LOADING_STATUS,
        payload: {
            loading: status
        }
    }
}