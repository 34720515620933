import React, { memo, useState } from "react"
import { useDispatch } from "react-redux"
import { setTeacherMyprofile } from "../../store/actions/TutorSignUpActions"
import imageCompression from "browser-image-compression"
import { getFile } from "../../utils/helper/helper"
import { CameraIcon } from "../../components/icons/Icons"
import BeatLoader from "react-spinners/BeatLoader"
import AvatarEditor from 'react-avatar-editor'
import Button from "../button/Button"
interface Props {
    imageurl: any
    setImageurl: any
    isShowError?: any
}

const UploadProfileImage = ({ imageurl, setImageurl, isShowError }: Props) => {
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const [ZoomImage, SetZoomImage] = useState(1)
    const [CropFlag, SetCropFlag] = useState(false)
    const [Editor, SetEditor] = useState<any>()
    const handleScale = (e: any) => {
        const scale = parseFloat(e.target.value)
        SetZoomImage(scale)
    }
    function urltoFile(url: string, filename: string, mimeType: any) {
        return (fetch(url)
            .then(function (res) { return res.arrayBuffer(); })
            .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
        );
    }
    const onSave = () => {
        setLoading(true)
        urltoFile(Editor.getImageScaledToCanvas().toDataURL(), 'profile', 'image/png')
            .then(async function (file) {
                await handleFileUpload(file);
            }).catch(console.error);
        setLoading(false)
        SetCropFlag(false);
    }
    const handleFileUpload = async (file: any | null): Promise<void> => {

        const compressedMaxFileSize = {
            maxSizeMB: 1,
        }
        try {
            setLoading(true)
            const compressedFile: any = await imageCompression(file, compressedMaxFileSize)
            const profilePicture = await getFile(compressedFile)
            dispatch(setTeacherMyprofile({ profilePicture }))
            setImageurl(profilePicture)
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }
    }
    return (
        <>
            <div className="form-file-picker-wrapper">
                <label
                    className={
                        isShowError && Object.keys(imageurl).length === 0
                            ? "form-file-picker form-file-picker-error"
                            : "form-file-picker"
                    }
                    style={{ backgroundImage: `url(${imageurl})`, backgroundSize: "cover" }}
                    htmlFor="upload-button"
                >
                    {loading ? (
                        <BeatLoader color="white" />
                    ) : (
                        Object.keys(imageurl).length === 0 && <CameraIcon color="#fff" size="2x" />
                    )}
                    <input
                        type="file"
                        accept="image/*"
                        id="upload-button"
                        style={{ display: "none" }}
                        onChange={(event: any): void => {
                            setImageurl(event.target.files[0])
                            SetCropFlag(true);
                            event.target.value = ""
                        }}
                    />
                    {

                    }
                </label>
            </div>
            {CropFlag ? (
                <div className='popup'>
                    <div className='popup_inner'>
                            <AvatarEditor
                                ref={SetEditor}
                                image={imageurl}
                                width={250}
                                height={250}
                                border={50}
                                color={[255, 255, 255, 0.6]} // RGBA
                                scale={ZoomImage}
                                rotate={0}
                            />
                          <div className="text-center">
                          <div style={{alignContent:"center"}}>
                            <span>Zoom: </span>
                            <input
                                name="scale"
                                type="range"
                                onChange={handleScale}
                                min="1"
                                max="2"
                                step="0.01"
                                defaultValue="1"
                            />
                            </div>
                            <div style={{alignContent:"space-between"}}>
                            <Button
                                clickAction={onSave}
                                title="Save" 
                            />
                            <Button
                                clickAction={()=>{
                                    SetCropFlag(false)
                                    setImageurl("")
                                }}
                                title="Close" />
                            </div>
                          </div>
                    </div> 
                </div>) : 
                (<div></div>)
            }

        </>
    )
}

export default memo(UploadProfileImage)
