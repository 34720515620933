import React, { useEffect, useState } from "react"
import { CardView } from "../../card/CardView"
import Button from "../../button/Button"
import { SERVER_URL } from "../../../utils/constant/api/api-config"
import {
    DateFormats,
    formatDate
} from "../../../utils/helper/date-utils"
import { useHistory } from "react-router-dom"
import { Grid } from "@material-ui/core"
import defaultImg from '../../../assets/images/default-avatar.png'

interface Iprops {
    studentName: string
    teacherId: string
    studentId: string
    startTime: string
    teacherName: string
    subjects: any
    onBookNew: Function
}

const TutorDetailCard = ({
    teacherId,
    teacherName,
    startTime,
    subjects,
    onBookNew
}: Iprops): JSX.Element => {
    const teacherNameSplitted = teacherName.split(" ");
    const shouldShowBookNowButtong = !(teacherName.toLowerCase().includes("deleted deleted") || teacherName.toLowerCase().includes("deleted user"));
    const history = useHistory();
    return (
        <div className="session-detail-card">
            <CardView>
                <img onError={(e:any)=>{e.target.src=defaultImg}} src={`${SERVER_URL}/userImage?userId=${teacherId}`} />
                <Grid container className="card-content from-dashboard-usession-card ml-2">
                    <Grid item xs={12} sm={12} md={5} lg={4} className="max-width-45">
                        <p className="tutor-name">
                            {teacherNameSplitted[0] +
                                " " +
                                (teacherNameSplitted[1] !== undefined
                                    ? teacherNameSplitted[1].charAt(0) + "."
                                    : "")}
                        </p>
                        <p className="subject-name">
                            {subjects?.map((subject: any) => (
                                <span>{subjects.length===1? subject:subject+", "}</span>
                            ))}
                        </p>
                    </Grid>
                    <Grid item xs={12} sm={12} md={7} lg={8} className="session-content-mob-left max-width-45">

                        <p className="session-date">
                            <b>{startTime > (new Date()).toISOString() ? 'Upcoming' : 'Last'} Session</b><br />
                            {`${formatDate(
                                startTime,
                                DateFormats.LONG_DATE,
                            )} at ${formatDate(startTime, DateFormats.TIME_IN_MERIDIAN)}`}</p>
                    </Grid>
                </Grid>
                { shouldShowBookNowButtong === true && <Button
                    title="Book Again"
                    clickAction={() => onBookNew()}
                    isloading={false}
                />}
            </CardView>
        </div>
    )
}

export default TutorDetailCard
