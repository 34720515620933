import React, { useMemo } from "react"
import { useSelector } from "react-redux"
import { Route, Redirect, RouteProps, useLocation } from "react-router-dom"
import withTokenLogin from "./withTokenLogin";
interface PrivateRouteProps extends RouteProps {
    component: any
}

const PrivateRoute = (props: PrivateRouteProps): JSX.Element => {
    const isLoggedIn = useSelector((state: any) => state?.auth?.loggedIn);
    const isAdmin = useSelector((state: any) => state?.auth?.userAuthData?.userType === "ADMIN");
    const location = useLocation();
    const redirectURL = useMemo(() => {
        return encodeURI(location.pathname + location.search);
    }, [location.pathname, location.search])
    const { component: Component, ...rest } = props
    return (
        <Route
            {...rest}
            render={(routeProps) =>
                isLoggedIn === true ? (
                    isAdmin === true ?
                        <Redirect
                            to={{
                                pathname: '/admin/panel/dashboard',
                                state: { from: routeProps.location },
                            }}
                        /> :
                        <Component {...routeProps} />
                ) : (<Redirect
                    to={{
                        pathname: redirectURL.indexOf("/panel/search/tutor-profile") > -1 ? "/sign-up" : "/sign-in",
                        search: redirectURL && "?redirectTo=" + redirectURL,
                        state: { from: routeProps.location },
                    }}
                />
                )
            }
        />
    )
}

export default withTokenLogin(PrivateRoute);
