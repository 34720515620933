import { Grid } from "@material-ui/core"
import colors from "assets/colors"
import BackArrowSvgIcon from "assets/images/svgIcons/BackArrowSvgIcon"
import Button from "components/button/Button"
import moment from "moment"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { fetchPaymentHistory } from "store/actions/paymentHistoryActions"
import HistoryTable from "./components/HistoryTable"
import { getMyCurrentPeriodEarnings } from "utils/services/paymentServices"

const padZero = (number: number) => {
    return /^\d$/.test(number.toString()) ? "0" + number : number
}


const PaymentHistory = (): JSX.Element => {
    const [showTable, setShowTable] = useState(false)
    const [nextDate, setNextDate] = useState("")
    const [currentPeriodEarning, setCurrentPeriodEarning] = useState(0);
    const { lastTransaction, userId, stripePayoutAccount } = useSelector((state: any) => state.auth.userAuthData)
    const { history, isLoading, hasMore } = useSelector((state: any) => state.paymentHistory)
    const dispatch = useDispatch()
    const { t } = useTranslation();
    useEffect(() => {
        let currentdate = new Date()
        currentdate.getDate() >= 16 ? currentdate.setMonth(currentdate.getMonth() + 1) && currentdate.setDate(1) : currentdate.setDate(16)

        const datetime = padZero((currentdate.getMonth() + 1)) + "/"
            + padZero(currentdate.getDate()) + "/"
            + currentdate.getFullYear()
        setNextDate(datetime)
        loadHistory(true)
    }, [])

    useEffect(() => {
        getMyCurrentPeriodEarnings()
            .then((amount: number) => setCurrentPeriodEarning(amount))
            .catch(console.error);
    }, [userId]);

    const loadHistory = (refresh: boolean) => {
        let currentdate = new Date()
        currentdate.getDate() >= 16 ? currentdate.setMonth(currentdate.getMonth() + 1) && currentdate.setDate(1) : currentdate.setDate(16)
        const datetime = currentdate.getFullYear() + "-"
            + padZero((currentdate.getMonth() + 1)) + "-"
            + padZero(currentdate.getDate()) + "T00:00:00.000Z"
        dispatch(fetchPaymentHistory({
            tutorId: [userId],
            limit: 10,
            starting_after: hasMore && !refresh ? history.length - 1 : 0,
            startedBefore: datetime,
            accountId: stripePayoutAccount?.accountId
        }))
    }
    return (
        <>
            {showTable ?
                <>
                    <span className="payment-history-title">
                        <span onClick={() => setShowTable(false)}><BackArrowSvgIcon width="22" height="22" iconColor={colors.vividBlue} /></span>
                        <p>{t("payment-history")}</p>
                    </span>
                    <HistoryTable history={history} isLoading={isLoading} hasMore={hasMore} loadHistory={loadHistory} />
                </>
                :
                <>
                    <p className="payment-details-title">Current Payment</p>
                    <Grid container className="next-payment">
                        <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                            <p>{t("next-payment-date")}</p>
                            <p className="large">{nextDate}</p>
                        </Grid>
                        <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                            <p>{t("current-period-earning")}</p>
                            <p className="large">$ {!currentPeriodEarning ? 0 : Math.round(currentPeriodEarning * 100) / 100}</p>
                        </Grid>

                    </Grid>

                    <Grid container className="last-payment">
                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                            <p>{t("last-payment-date")}</p>
                            <p className="p-grey">{lastTransaction?.date ? moment(lastTransaction.date).format('MM/DD/YYYY') : "--/--/----"}</p>
                        </Grid>
                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                            <p>{t("last-payment")}</p>
                            <p className="p-grey">$ {lastTransaction?.amount ? lastTransaction?.amount / 100 : "--"}</p>
                        </Grid>
                    </Grid>
                    <div className="flex view-history-btn">
                        <Button
                            spanStyle={{ margin: "auto", padding: "30px" }}
                            title="View payment history"
                            clickAction={() => setShowTable(true)}
                        />
                    </div>
                </>
            }
        </>
    )
}

export default PaymentHistory
