import React, { useCallback, useEffect, useState } from "react"
import Grid from "@material-ui/core/Grid"
import UserPanel from "../../../components/dashboard/UserPanel"
import StudentDetailCard from "../../../components/dashboard/detail-cards/StudentDetailCard";
import StudentLastDetailCard from "../../../components/dashboard/detail-cards/StudentLastDetailCard"
import TutorStatCards from "../../../components/dashboard/TutorStatCards"
import { CardView } from "../../../components/card/CardView"
import { InputBox } from "../../../components/input-field/InputFields"
import { faSearch } from "@fortawesome/free-solid-svg-icons"
import { useSelector, useDispatch } from "react-redux"
import subjectsList from "../../../utils/constant/subjectsList"
import { SubjectType } from "../../../store/types/tutorSearchTypes"
import InterfaceConstant from "../../../utils/constant/interfaceConstant"
import { applicationConstant } from "../../../utils/constant/applicationConstant"
import { formatName, navigatetoUrl } from "../../../utils/helper/helper"
import LoaderSpinner from "../../../components/loader/LoaderSpinner"
import { useHistory } from "react-router-dom"
import NoResultsCard from "../../../components/dashboard/NoResultsCard"
import {
    getUpcomingSessions,
} from "store/actions/sessionsActions"
import moment from "moment";
import { getMyFutureEarnings, getMyNextPeriodEarnings, getMyCurrentPeriodEarnings } from "utils/services/paymentServices";
import { addWeeks, isWithinInterval, endOfMonth, startOfMonth, parseISO } from "date-fns";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import Button from "components/button/Button";
import { filterNotHeldBookings, orderSessionsByDate } from "utils/helper/session-utils";
import { EPICBOARD_SESSION_STATUS_CODES } from "store/constant";
import { BookingPending, useBookingPending } from "pendings";
import { BOOKING_REQUEST_STATUS_CODES } from "store/reducers/sessions-reducers/sessionReducer";
import { setTeacherMyprofile } from "store/actions/TutorSignUpActions";
import { updateDefaultTimeZone } from "utils/services/userServices";
import { RapidbookComponent } from "components/Rapidbook";
import firebase from "firebase";

const incompleteFinalizedSessionStatuses = [
    EPICBOARD_SESSION_STATUS_CODES.CANCELLED,
    EPICBOARD_SESSION_STATUS_CODES.REQUEST_REJECTED,
    EPICBOARD_SESSION_STATUS_CODES.BOOKING_REQUEST_CANCELLED,
    EPICBOARD_SESSION_STATUS_CODES.BOOKING_REQUEST_REJECTED,
    EPICBOARD_SESSION_STATUS_CODES.REQUEST_EXPIRED_AT_STUDENT_SIDE,
    EPICBOARD_SESSION_STATUS_CODES.REQUEST_EXPIRED_AT_TEACHER_SIDE
]

const TutorDashboardPage = (): JSX.Element => {
    const { userAuthData } = useSelector((state: any) => state.auth);
    const { userId }: { userId: string } = userAuthData;
    const history = useHistory();
    const dispatch = useDispatch();
    const routeUrl: InterfaceConstant.IrouteUrl = applicationConstant.routeUrl
    const dashboardLabel: InterfaceConstant.IdashboardLabel = applicationConstant.dashboardLabel
    const allSessions = useSelector((state: any) => state.session.sessions);
    const [sessions, setSessions] = useState<Array<any>>([]);
    const [showAlert, setShowAlert] = useState<boolean>(false);
    const [searchedSessions, setSearchedSessions] = useState<Array<any>>([]);
    const isLoading = useSelector((state: any) => state.session.upcomingSessionsLoading);
    const [dataOk, setDataOk] = useState(false);
    const [futureEarning, setFutureEarning] = useState(0);
    const [nextPeriodEarning, setNextPeriodEarning] = useState(0);
    const [currentPeriodEarning, setCurrentPeriodEarning] = useState(0);
    const [pendingRequestsCount, setPendingRequestsCount] = useState(0);
    const bookingPending: BookingPending = useBookingPending();
    const [tasks, setTasks] = useState({
        overdue: [] as Array<any>,
        deadlines: [] as Array<any>,
        upcoming: [] as Array<any>,
    });
    const currentTimeString = (new Date()).toISOString();

    useEffect(() => {
        if (!userId) {
            return;
        }
        const pendingList = (snapshot: firebase.database.DataSnapshot) => {
            if (!snapshot.exists()) {
                return;
            }
            const bookings = Object.values(snapshot.val()).map((b: any) => b.data);
            const notHeldBookings = filterNotHeldBookings(bookings);
            //get all those bookings which are waiting for teachers response
            const totalPendingBookings = notHeldBookings.filter(b => b.status === BOOKING_REQUEST_STATUS_CODES.WAITING_FOR_TEACHER_CONFIRMATION).length;
            setPendingRequestsCount(totalPendingBookings);
        }       

        bookingPending.ref.on("value", pendingList);
        return () => bookingPending.ref.off("value", pendingList);

    }, [userId])


    useEffect(() => {

    }, [])
    useEffect(() => {
        getMyCurrentPeriodEarnings()
            .then((amount: number) => setCurrentPeriodEarning(amount))
            .catch(console.error);
        getMyNextPeriodEarnings()
            .then((amount: number) => setNextPeriodEarning(amount))
            .catch(console.error);
    }, [userId]);

    useEffect(() => {
        userAuthData?.userId && dispatch(getUpcomingSessions(userAuthData?.userId))
        if (!userAuthData?.defaultTimeZone) {
            // setShowAlert(true);
            const currentTimeZone = moment.tz.guess()
            updateDefaultTimeZone(userId, currentTimeZone).then(result => {
                dispatch(setTeacherMyprofile({ defaultTimeZone: currentTimeZone }))
            })
        }
    }, [userAuthData?.userId]);

    useEffect(() => {
        setDataOk(!isLoading);
    }, [isLoading]);


    useEffect(() => {
        let overdue = [] as Array<any>;
        let upcoming = [] as Array<any>;
        const formattedSessions = orderSessionsByDate(allSessions.map((session: any) => {
            const endTime = moment(session.startTime).utc().add(session.sessionLength, "minutes").add(session.extensionTime || 0, "minutes");
            const endTimeString = endTime.toISOString();
            return {
                ...session,
                endTime: endTimeString
            };
        }));

        formattedSessions.map((session: any) => {
            if (currentTimeString > session.endTime) {
                // overdue.push({
                //     name: "Past",
                //     date: new Date(session.startTime)
                // });
            } else if (session.endTime > currentTimeString) {
                upcoming.push({
                    name: "Upcoming",
                    date: new Date(session.startTime)
                });
            }
        });
        const groupedSessionsAndIds = formattedSessions.reduce((result: any, session: any) => {
            //if session is cancelled do NOT count it
            if (incompleteFinalizedSessionStatuses.includes(session.status)) {
                return result;
            }
            if (result.userIds.includes(session.studentId)) {
                //compare and get the latest session
                //but if a session is upcoming then the first most session
                //should come
                const lastSession = result.students[session.studentId];
                if (lastSession.endTime > currentTimeString) { //its an upcoming
                    //if new session should be upcoming otherwise skip
                    if (session.endTime > currentTimeString) {
                        result.students[session.studentId] = lastSession.startTime < session.startTime ? lastSession : session;
                    }
                } else {
                    result.students[session.studentId] = lastSession.startTime < session.startTime ? session : lastSession;
                }
            } else {
                result.userIds.push(session.studentId);
                result.students[session.studentId] = session;
            }
            return result;
        }, { userIds: [], students: {} });
        const lastSessions = Object.values(groupedSessionsAndIds.students).sort(({ startTime: t1 }: any, { startTime: t2 }: any) => {
            return t1 > t2 ? -1 : t1 < t2 ? 1 : 0;
        });;
        setSessions(lastSessions);
        setTasks({
            overdue,
            upcoming,
            deadlines: []
        });
    }, [allSessions]);


    useEffect(() => {
        setSearchedSessions(sessions.sort(({ startTime: t1 }: any, { startTime: t2 }: any) => {
            const currentTime = moment().utc();
            const d1 = moment(parseISO(t1)).utc().diff(currentTime, "milliseconds");
            const d2 = moment(parseISO(t2)).utc().diff(currentTime, "milliseconds");
            if (d1 < 0) {
                if (d2 < 0) {
                    return d2 - d1;
                }
                return 1;
            } else if (d2 < 0) {
                return -1;
            } else {
                return d1 - d2;
            }
        }));
    }, [sessions]);
    const searchMyStudents = useCallback((searchString: string) => {
        if (!searchString || !searchString.trim()) {
            return setSearchedSessions(sessions);
        }
        const searchedLastSessions = sessions.filter(session => {
            return session.studentName.toLowerCase().includes(searchString.toLowerCase())
        });
        setSearchedSessions(searchedLastSessions);
    }, [sessions]);


    const navigatetoEpicboard = () => {
        navigatetoUrl(history, routeUrl.EPIC_BOARD_PAGE)
    }

    return (
        <>
            <div className="tutor-dashboard-container">
                <Grid container>
                    <Grid xl={4} lg={4} md={6} sm={12} className="dashboard-left-panel">
                        <UserPanel
                            firstName={userAuthData.firstName}
                            lastName={userAuthData.lastName}
                            subtitle={subjectsList
                                .filter((subjectObject: SubjectType) =>
                                    userAuthData?.subjects?.includes(subjectObject.id),
                                )
                                .map((subject: SubjectType) => subject.name)
                                .join(", ")}
                            profilePicUrl={userAuthData.profilePic.downloadURL}
                            isTutor
                            tasks={tasks}
                        />
                    </Grid>
                    <Grid
                        item
                        id="right-col"
                        xl={8} lg={8} md={6} sm={12}
                    >
                        <Grid container>
                            <div className="tutor-dash-head">
                                <Grid container>
                                    <Grid item md={4} sm={12} xs={12}>
                                        <p className="tutor-dashboard-section-head">My Students</p>
                                        <p className="total-student-counter">
                                            Total: {searchedSessions.length}
                                        </p>
                                    </Grid>
                                    <Grid item md={8} sm={12} xs={12}>
                                        <div className="tutor-dash-search-container">
                                            <InputBox

                                                title=""
                                                placeholder="Search"
                                                type={"text"}
                                                inputProps={{
                                                    onChange: (e: any) => searchMyStudents(e.target.value)
                                                }}
                                                iconProps={{ icon: faSearch }}
                                                error={null}

                                            />
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                        <CardView overFlowChildStyle className="student-card-view-height" style={{ overflowY: "auto", height: "290px" }}>
                            {searchedSessions.length > 0 &&
                                searchedSessions.map((session: any) => {
                                    session.studentName = formatName(session.studentName);
                                    return session.endTime > currentTimeString ? (
                                        <StudentDetailCard
                                            {...session}
                                            navigatetoEpicboard={() => navigatetoEpicboard()}
                                        />
                                    ) : (<StudentLastDetailCard
                                        {...session}
                                    />);
                                })}
                            {isLoading && (
                                <div className="text-center ptb-1">
                                    <LoaderSpinner />
                                </div>
                            )}

                            {dataOk && sessions.length === 0 && (
                                <NoResultsCard
                                    titleText={dashboardLabel.NO_STUDENTS_CARD_TITLE}
                                    subtitleText={"" /*dashboardLabel.NO_STUDENTS_CARD_SUBTITLE*/}
                                    noButton
                                />
                            )}
                        </CardView>
                        <RapidbookComponent userId={userId} />
                        <div className="tutor-dashboard-detail-card-container">
                            <TutorStatCards
                                pendingRequests={pendingRequestsCount}
                                studentData={[]}
                                upcomigSessionCount={tasks.upcoming.length}
                                nextSession={tasks.upcoming.length && tasks.upcoming[0].date.toISOString()}
                                totalMinutesWorked={userAuthData.totalMinutesWorked}
                                totalEarning={futureEarning * 100}
                                nextPeriodEarning={nextPeriodEarning * 100}
                                currentPeriodEarning={currentPeriodEarning * 100}
                                totalReviews={userAuthData.reviews && Object.keys(userAuthData.reviews).length}
                            />
                        </div>
                    </Grid>
                </Grid>

                <Dialog open={showAlert} onClose={() => setShowAlert(false)} >
                    <DialogTitle className="text-warning">Information</DialogTitle>
                    <DialogContent className="p-4">
                        <p style={{ textAlign: "center" }}>Looks Like you haven't set your time zone</p>
                        <p className="text-warning" style={{ textAlign: "center" }}>This will affect your availability</p>
                    </DialogContent>
                    <DialogActions style={{ justifyContent: "center", padding: "15px 30px" }}>
                        <Button

                            title={"Set Time Zone"}
                            clickAction={(e: any) => {
                                setShowAlert(false);
                                navigatetoUrl(
                                    history,
                                /* routeUrl.USER_PROFILE */ "/panel/account",
                                )
                            }}

                        />
                    </DialogActions>

                </Dialog>
            </div>

        </>
    )
}

export default TutorDashboardPage
