import React from "react"
import Thumbnail from "../../assets/images/LessonsThumbnail.png"

const LessonsCard = () => {
    return (
        <div className="lessons-card">
            <img src={Thumbnail} />
            <p className="p-16 p-mango"><span className="d-block">College Counseling</span><span className="d-block">Lessons</span></p>
            
        </div>
    )
}

export default LessonsCard
