import { Pending, PENDING_STATUS, PENDING_TYPE } from "./Pending";


export class BookingPending extends Pending {
    /**
     * 
     * @param userId 
     */
    constructor(userId: string) {
        super(userId);
        this.initialize();
    }

    initialize(){
        if (this.userId) {
            this.ref = this.database.child(PENDING_TYPE.BOOKING).child(this.userId);
        }
    }

    resolveItem(itemId: string) {
        if (this.ref) {
            this.ref.child(itemId).child('status').set(PENDING_STATUS.RESOLVED).catch(e => console.error("Error while resolving booking pending item: ", e));   
            this.ref.child(itemId).child('resolvedBy').set(this.userId).catch(e => console.error("Error while resolving booking pending item: ", e));
        }
    }

}