import React, { useEffect, useContext } from "react"
import TutorImageBlock from "./ui-elements/TutorImageBlock"
import TitleLabel from "../../../components/title-label/TitleLabel"
import DescriptionLabel from "../../../components/description-label/DescriptionLabel"
import AvailabilityBlock from "./ui-elements/AvailabilityBlock"
import PersonalInfo from "./ui-elements/PersonalInfo"
import EducationBackground from "./ui-elements/EducationBackground"
import OtherDetails from "./ui-elements/OtherDetails"
import RatingBlock from "./ui-elements/RatingBlock"
import { fetchTutor } from "../../../store/actions/tutorActions"
import { navigatetoUrl } from "../../../utils/helper/helper"
import { setSelectedTutor } from "../../../store/tutor-search/actions/actions"

import { TutorSearchStoreContext } from "../../../store/tutor-search/store"
import { GenderTypes } from "../../../store/types/tutorSearchTypes"
import { useDispatch, useSelector } from "react-redux"
import { setSubject, setSessionRequest, setActiveSlot } from "../../../store/actions/BookingActions"

import subjectsList from "../../../utils/constant/subjectsList" //tutoring
import counsellingList from "../../../utils/constant/counsellingList" //advising
import standardizedTestList from "../../../utils/constant/standardizedTestList"

import { SubjectType } from "../../../store/types/tutorSearchTypes"
import BookingCalendarSvgIcon from "../../../assets/images/svgIcons/BookingCalendarSvgIcon"
import colors from "../../../assets/colors"
import StarSvgIcon from "../../../assets/images/svgIcons/StarSvgIcon"
import { toggleFavorites } from "../../../store/actions/userActions"
import { concat } from "lodash"

interface Props {
    history: any,
    location: any
}

const extractSubjectsAndTests = (
    ids: string[],
): { subjects: SubjectType[]; tests: SubjectType[]; advisingTests: SubjectType[] } => {
    const subjects = subjectsList.filter((subjectItem: any) => ids.includes(subjectItem.id))
    const advisingTests = counsellingList.filter((testItem: any) => ids.includes(testItem.id))
    const tests = standardizedTestList.filter((testItem: any) => ids.includes(testItem.id))
    return { subjects, tests, advisingTests }
}
	
const TutorProfilePage = (props: Props): JSX.Element => {
    const tutorSearchStoreContext = useContext(TutorSearchStoreContext)

    const { history, location } = props
    const { userAuthData } = useSelector((state: any) => state.auth)
    const { defaultTimeZone: studentTimeZone } = userAuthData
    const selectedSubject = useSelector((st: any) => st.booking.options.subject)
    const dispatch = useDispatch()

    const teacherStore = useSelector((state: any) => state.tutor);
    const urlParams = new URLSearchParams(location.search)
    let teacherId = urlParams.get("teacherId")

    if (!teacherId && location.pathname.indexOf("?") > -1) {
        const urlParamsPath = new URLSearchParams(location.pathname.split("?")[1])
        teacherId = urlParamsPath.get("teacherId")
    }

    const allSubjectIds = teacherStore.teacher.subjects;
    const extracted = extractSubjectsAndTests(allSubjectIds || []);
    let subjects: any[] = extracted.subjects;
    let tests: any[] = extracted.tests;
    let advisingTests: any[] = extracted.advisingTests;

    useEffect(() => {
        if (teacherId && teacherStore.teacher.userId != teacherId) {
            dispatch(fetchTutor({
                teacherId: teacherId,
            }))
        }
    }, [teacherId])

    useEffect(() => {
        if (allSubjectIds) {
            const tutorSubjects = concat(subjects, tests, advisingTests)
            const newSubjectList = selectedSubject ? selectedSubject.filter((subject: any) => {
                return tutorSubjects.find((tutorSubject: any) => {
                    if (tutorSubject.id === subject.id) {
                        return true
                    }
                })
            }) : [];
            dispatch(setSubject({ subject: newSubjectList }))
        }

        if (teacherStore.teacher && teacherId && teacherStore.teacher.userId == teacherId) {
            tutorSearchStoreContext.dispatch(setSelectedTutor.action(teacherStore.teacher))
        }
    }, [teacherStore])

    useEffect(() => {
        dispatch(setSessionRequest({ sessionRequests: [] }))
        dispatch(setActiveSlot({ activeSlots: [] }))
    })

    return (teacherStore.isloading || teacherStore.teacher.firstName.length < 1) && subjects ? (
        <div>Redirecting...</div>
    ) : (
        <div className="row tutor-profile">
            <div className="col-md-5 col-sm-12 profile-left-block">
                <div>
                    <TutorImageBlock
                        firstName={teacherStore.teacher.firstName}
                        lastName={teacherStore.teacher.lastName}
                        imageURL={teacherStore.teacher.profilePic.downloadURL}
                        rating={parseFloat(teacherStore.teacher.avgRating)}
                    />
                    <div className="booking-route">
                        <div className="icon cursor-pointer" onClick={() => {
                            dispatch(setSelectedTutor.action(teacherStore.teacher))
                            navigatetoUrl(history, "/panel/search/book-session")
                        }}>

                            <BookingCalendarSvgIcon
                                height="23px"
                                width="23px"
                                onClick={() => {
                                    navigatetoUrl(history, "/panel/search/book-session")
                                }}
                            />
                            <span> Request Sessions </span>
                        </div>
                    </div>
                    <div className="time-schedule">
                        {teacherStore.teacher.teacherType === "TUTORING_AND_ADVISING" && (
                            <>
                                <TitleLabel title="Subjects" />
                                <div className="margin-b-54">
                                    <DescriptionLabel
                                        description={
                                            subjects.length !== 0
                                                ? subjects.map((subject) => subject.name).join(", ")
                                                : "N/A"
                                        }
                                    />
                                </div>
                                <TitleLabel title="Standardized Tests" />
                                <div className="margin-b-54">
                                    <DescriptionLabel
                                        description={
                                            tests.length !== 0
                                                ? tests.map((test) => test.name).join(", ")
                                                : "N/A"
                                        }
                                    />
                                </div>
                                <TitleLabel title="Advising Specialities" />
                                <div className="margin-b-54">
                                    <DescriptionLabel
                                        description={
                                            advisingTests.length !== 0
                                                ? advisingTests
                                                    .map((subject) => subject.name)
                                                    .join(", ")
                                                : "N/A"
                                        }
                                    />
                                </div>
                            </>
                        )}
                        {teacherStore.teacher.teacherType === "TUTORING" && (
                            <>
                                <TitleLabel title="Subjects" />
                                <div className="margin-b-54">
                                    <DescriptionLabel
                                        description={
                                            subjects.length !== 0
                                                ? subjects.map((subject) => subject.name).join(", ")
                                                : "N/A"
                                        }
                                    />
                                </div>
                                <TitleLabel title="Standardized Tests" />
                                <div className="margin-b-54">
                                    <DescriptionLabel
                                        description={
                                            tests.length !== 0
                                                ? tests.map((test) => test.name).join(", ")
                                                : "N/A"
                                        }
                                    />
                                </div>
                            </>
                        )}
                        {teacherStore.teacher.teacherType === "ADVISING" && (
                            <>
                                <TitleLabel title="Advising Specialities" />
                                <div className="margin-b-54">
                                    <DescriptionLabel
                                        description={
                                            advisingTests.length !== 0
                                                ? advisingTests
                                                    .map((subject) => subject.name)
                                                    .join(", ")
                                                : "N/A"
                                        }
                                    />
                                </div>
                            </>
                        )}

                        <div className="margin-b-17">
                            <TitleLabel title="Availability" />
                            <p className="subtitle">Click below for specific times and schedule a session</p>
                        </div>
                        <AvailabilityBlock
                            availabilityData={
                                teacherStore.teacher.teacherWeeklyAvailability
                            }
                            tutorTimeZone={teacherStore.teacher.defaultTimeZone}
                            studentTimeZone={studentTimeZone}
                        />
                    </div>
                </div>
            </div>

            <div className="col-md-7 col-sm-12">
                <div className="profile-detail">
                    <button
                        type="button"
                        className="orange-button btn btn-outline-danger"
                        onClick={() => {
                            const oldFavorites = [...userAuthData.favorites]
                            const updatedFavorites = oldFavorites.includes(
                                teacherStore.teacher.userId,
                            )
                                ? oldFavorites.filter(
                                    (id) => id !== teacherStore.teacher.userId,
                                )
                                : [...oldFavorites, teacherStore.teacher.userId]
                            dispatch(toggleFavorites({ payload: updatedFavorites }))
                        }}
                    >
                        <div className="favorites-button-content">
                            <StarSvgIcon width="30px" height="30px" iconColor={colors.vividBlue} />
                            <div style={{ paddingTop: "2px" }}>
                                {userAuthData.favorites?.includes(
                                    teacherStore.teacher.userId,
                                )
                                    ? "Remove from Favorites"
                                    : "Add to Favorites"}
                            </div>
                        </div>
                    </button>
                    <div className="about">
                        <div className="margin-b-10">
                            <TitleLabel title="About" />
                        </div>
                        <DescriptionLabel
                            description={teacherStore.teacher.teacherBiography}
                        />
                    </div>
                    <PersonalInfo
                        gender={GenderTypes[teacherStore.teacher.gender]}
                        hoursTutoring={teacherStore.teacher.minutesTutoring}
                        rate={teacherStore.teacher.oneToOneSessionRate}
                    />
                    <EducationBackground data={teacherStore.teacher.teacherSchoolDetails} />
                    <OtherDetails
                        data={teacherStore.teacher.teacherSchoolDetails}
                        education={teacherStore.teacher.teacherEducationQualification.name}
                        certification={
                            teacherStore.teacher.teacherCertificateDetails.certificationName
                        }
                        cancellationNotice={
                            teacherStore.teacher.freeCancellationBeforeHours
                        }
                    />

                    <RatingBlock />
                </div>
            </div>
        </div>
    )
}
export default TutorProfilePage
