import firebase from "firebase"

enum ActionTypes {
    CHECK_SUBSCRIPTION_EXPIRY = "CHECK_SUBSCRIPTION_EXPIRY",
    CANCEL_SUBSCRIPTION = "CANCEL_SUBSCRIPTION",
}

export const getCurrentPeriodExpiryStatus = async () => {
    const getCurrentPeriodExpiryStatus = firebase.functions().httpsCallable("subscription");
    return await getCurrentPeriodExpiryStatus({ actionType: ActionTypes.CHECK_SUBSCRIPTION_EXPIRY })
        .then((result: any) => result.data).catch((err: any) => err);
}

export const cancelSubscriptionService = async (subscriptionId: any) => {
    const cancelSsubscriptionService = firebase.functions().httpsCallable("subscription");
    return await cancelSsubscriptionService({ actionType: ActionTypes.CANCEL_SUBSCRIPTION, subscriptionId })
        .then((result: any) => result.data).catch((err: any) => err);
}
