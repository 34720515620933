import React, { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next";
import firebase from "firebase";
import { Link, useHistory } from "react-router-dom";
import Button from "components/button/Button";
import { navigatetoUrl } from "utils/helper/helper";


const EmailVerification = ({ oobCode }: { oobCode: string }): JSX.Element => {

    const history = useHistory();
    const { t } = useTranslation();
    const [response, setResponse] = useState<"success" | "error" | "inprogress">("inprogress");


    const verifyEmail = useCallback(async () => {
        try {
            await firebase.auth().applyActionCode(oobCode);
            setResponse("success");
        } catch (err) {
            setResponse("error");
        }
    }, []);

    useEffect(() => {
        verifyEmail();
    }, []);

    return (
        <>
            <div className="auth-redirect">
                <div className="card-container">
                    {response === "success" ?
                        <>
                            <p className="title">{t("verify-email-success-title")}</p>
                            <Button
                                className="button"
                                clickAction={() => {
                                    navigatetoUrl(history, "/sign-in")
                                }}
                                title={t("go-to-login-page")}
                            />
                        </>
                        :
                        response === "error" ?
                            <>
                                <p className="title">{t("verify-email-failure-title")}</p>
                                <p>{t("verify-email-failure-subtitle")}</p>
                                <Link to="/sign-in">
                                    <u>{t("sign-in")}?</u>
                                </Link>
                            </>
                            :
                            <p>verifying...</p>
                    }
                </div>
            </div>
        </>
    )
}

export default EmailVerification

