/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useCallback } from "react";
import Modal from "components/modal_/Modal";
import StudentTutorAvatar from "components/student-tutor-avatar/StudentTutorAvatar"
import 'popups/booking/student/popups/StdTutorAcceptRequest.scss';
import Button from "components/button/Button";
import moment from "moment";
import { Note } from "components/tips/Note";
import { useIsPhoneOrTablet } from "utils/hooks/useIsPhoneOrTablet";
import { getWindowDimensions } from "utils/helper/window-dimesions";
import { useLocation } from "react-router-dom";

interface TutorStdAcceptRequests {
    studentPicUrl: string,
    teacherPicUrl: string,
    onGoto: () => void,
    studentName: string,
    sessions: {
        startTime: string,
        endTime: string,
        sessionLength: string
    }[]
    isShown: boolean,
    onClose: () => void
}


export function TutorStdAcceptRequest(props: TutorStdAcceptRequests) {
    const isPhoneOrTablet = useIsPhoneOrTablet();
    const dimensions =getWindowDimensions() ||false;
    const platform = localStorage.getItem("platform") ||false;
    return <Modal
        visible={props.isShown}
        onExit={props.onClose}
    >
        <div className="model_tutoracceptrequest">
            <div className="imgs_div">
                <StudentTutorAvatar
                    size="100"
                    student={
                        props.studentPicUrl
                    }
                    tutor={
                        props.teacherPicUrl
                    }
                />
            </div>

            <h2>Your sessions are booked!</h2>
            <h4>{props.studentName} accepted your booking request(s)</h4>
            {props.sessions.map(session => <p>{moment(session.startTime).format("dddd, MMMM DD, YYYY")} at {moment(session.startTime).format("hh:mm A")}</p>)}{/*Sunday, February 6, 2019 at 2:00PM*/}
            {(!platform && dimensions?.width <= 768 && isPhoneOrTablet)  &&
                (
                    <>
                    <Note title="Please note" content="Currently, our mobile web versions are undergoing necessary updates. Please use the browser on a computer / desktop while we complete these. We apologize for any inconvenience this may cause!"/>
                    </>
                    )   
                    } <Button className="" title="Go to Dashboard" clickAction={props.onGoto} />
            <p>
                If you must reschedule/cancel, please do so in accordance with our<br/>
                <a href="https://theclassroomdoor.com/rescheduling-cancellation-policy/" target="cancellation">rescheduling/cancelation policy</a>
            </p>
        </div>

    </Modal>
}
