import React, { useEffect, useState } from "react"
import { InputBox } from "../../../../../components/input-field/InputFields"
import { faSearch } from "@fortawesome/free-solid-svg-icons"
import ContactCard from "./components/ContactCard"
import { useSelector } from "react-redux"
import UserConversationBlockPortal from "../ConversationBlock/UserConversationBlockPortal"
import { Chat } from "tcd-message-center"
import colors from "assets/colors"


const ConnectedPeople = ({ currentConversation, setCurrentConversation }: { currentConversation: Chat | undefined, setCurrentConversation: any }): JSX.Element => {
    const { connectedPeople } = useSelector((state: any) => state.messageCenter);

    const [searchMessageText, setSearchMessageText] = useState("");
    const [filteredConversations, setFilteredConversations] = useState<Array<Chat>>(connectedPeople);

    const filterMessages = () => {
        return connectedPeople?.filter((conversation: Chat) => {
            const searchIn: string = `${conversation?.recipient?.firstName} ${conversation?.recipient?.lastName}`;
            if (searchIn.toLowerCase().includes(searchMessageText.toLowerCase())) {
                return true;
            } else {
                return false;
            }
        });
    };

    const updateFilterMessages = () => {
        searchMessageText ? setFilteredConversations(filterMessages) : setFilteredConversations(connectedPeople);
    }

    useEffect(() => {
        setFilteredConversations(connectedPeople);
    }, [connectedPeople])


    useEffect(() => {
        updateFilterMessages();
    }, [searchMessageText]);

    return (
        <>
            <div className="connected-people-container">
                <p className="message-title">Messages</p>
                <div className="messgae-inner-card-tab">
                    <InputBox
                        title=""
                        placeholder="Search your messages"
                        type={"text"}
                        inputProps={{
                            name: "subjectFilter",
                            value: searchMessageText,
                            onChange: (e: any) =>
                                setSearchMessageText(e.target.value),
                        }}
                        iconProps={{ icon: faSearch, style: { color: colors.darkMango } }}
                        error={null}
                    />
                    <div>
                        {filteredConversations?.map((conversation, index) => {
                            return (
                                <ContactCard conversation={conversation} setCurrentConversation={setCurrentConversation} open={conversation.id === currentConversation?.id} />
                            )
                        })}
                    </div>
                </div>
                {currentConversation &&
                    <UserConversationBlockPortal
                        conversation={currentConversation}
                    />
                }
            </div>
        </>
    )
}

export default ConnectedPeople;
