import React, { useState } from "react"
import { Grid } from "@material-ui/core"
import UnderLinedTabs from "../../../../../components/tabs/underline-tabs/UnderlinedTabs"
import PaymentHistory from "./components/PaymentHistory"
import PaymentMethods from "pages/home/my-account/components/Payments/components/PaymentMethods"
import Button from "components/button/Button"
import { useDispatch, useSelector } from "react-redux"
import { PaymentConstant } from "store/constant";
import { useTranslation } from "react-i18next"
import { InfoIcon } from "components/icons/Icons"




const paymentTabsConstants = {
    WALLET: "Wallet",
    DETAILS: "Payment History"
}

const paymentTabs = {
    [paymentTabsConstants.WALLET]: "methods",
    [paymentTabsConstants.DETAILS]: "details"
}

const Payments = (): JSX.Element => {
    const [activePaymentTab, setActivePaymentTab] = useState(paymentTabsConstants.WALLET)
    const { stripeCustomer } = useSelector((state: any) => state.auth.userAuthData)
    const { t } = useTranslation();

    const dispatch = useDispatch()
    return (
        <>
            <Grid item xl={8} lg={8} md={8} sm={12} xs={12} className="tab-heading">
                <UnderLinedTabs
                    labels={Object.keys(paymentTabs)}
                    selectedLabel={activePaymentTab}
                    onLabelClick={setActivePaymentTab}
                />
            </Grid>
            <Grid container className="walletModel" >
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="wallet"> 
                    <Grid item xl={2} lg={2} md={3} sm={12} xs={12} className="balance">
                        <div>
                            <p>{t("wallet-balance")}</p>
                            <h1> ${(Math.abs(stripeCustomer?.balance))/100}</h1>
                        </div>
                    </Grid>
                    <Grid item xl={10} lg={10} md={9} sm={12} xs={12} className="margin-auto description">
                        <InfoIcon color="#fff" className="circle" titleAccess={t('rapid-book-info')}></InfoIcon>
                        <p>{t("wallet-balance-desc")}</p>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="tab-buttons">
                {activePaymentTab === paymentTabsConstants.WALLET &&
                    <Button className="tab-button" title="+ Add Card" isOutlineStyle={true} clickAction={() => dispatch({ type: PaymentConstant.TOGGLE_ADD_CARD_MODAL, payload: { showAddCardModal: true } })} />
                }
            </Grid>
            {activePaymentTab === paymentTabsConstants.DETAILS &&
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="payment-tab-content">
                    <PaymentHistory />
                </Grid>
            }
            {activePaymentTab === paymentTabsConstants.WALLET &&
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="payment-tab-content">
                    <PaymentMethods />
                </Grid>
            }
        </>
    )
}

export default Payments
