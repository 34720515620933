import React, { useEffect, useState } from "react"
import { Card } from "@material-ui/core"
import SingleTickSvgIcon from "../../../../../../assets/images/svgIcons/SingleTickSvgIcon"
import { Chat, ChatEvents, CountEvents, MessageEvents, MessageType } from "tcd-message-center"
import { timestampToDateFormate } from "utils/helper/date-utils"
import _ from "lodash"
import defaultAvatar from "../../../../../../assets/images/default-avatar.png"
import { useDispatch } from "react-redux"
import { messageCenterConstant } from "store/constant"



export type personType = {
    firstName: string,
    lastName: string,
    profilePic: {
        downloadURL: string
    }
    lastMessage: string
    lastMessageStatus: "Seen" | "Delivered"
}


const ContactCard = ({ conversation, setCurrentConversation, open }: { conversation: Chat, setCurrentConversation: any, open: boolean }): JSX.Element => {

    const [isReady, setIsReady] = useState<boolean>(false);
    const [unreadCount, setUnreadCount] = useState<number>(conversation.getUnreadCount());
    const [lastMessageFetched, setLastMessageFetched] = useState<boolean>(false);
    const [pageLoadCount, setPageLoadCount] = useState(0);

    const dispatch = useDispatch();

    useEffect(() => {
        const isReadyListner = () => {
            setIsReady(true);
        }
        conversation.on(ChatEvents.READY, isReadyListner);

        const lastMessageListener = (message: MessageType) => {
            dispatch({ type: messageCenterConstant.SORT_CHATS });

            setLastMessageFetched(true);
        }

        conversation.on(MessageEvents.LAST_MESSAGE, lastMessageListener);

        const chatUnreadCountListener = (count: number) => {
            setUnreadCount(count);
        }

        conversation.on(CountEvents.Unread, chatUnreadCountListener);
        setUnreadCount(conversation.getUnreadCount());
        return () => {
            conversation.off(MessageEvents.LAST_MESSAGE, lastMessageListener);
            conversation.off(ChatEvents.READY, isReadyListner);
            conversation.off(CountEvents.Unread, chatUnreadCountListener);
        }
    }, [conversation, pageLoadCount]);


    return (
        <>
            <Card
                className="message-card"
                key={conversation.id}
                style={{ boxShadow: "0 10px 10px 0 #0000000d" }}
                onClick={() => {
                    setCurrentConversation(conversation);
                }}
            >
                <div style={{ position: "relative", height: "inherit" }}>
                    <span className={open ? `active_chat` : unreadCount > 0 ? `unread-chat` : `rectangle`}></span>
                </div>
                <div className="message-card-avatar">
                    <img
                        style={{ height: "inherit", width: "inherit" }}
                        src={conversation?.recipient?.profilePicture || defaultAvatar}
                        alt=""
                        onError={(ev) => { ev.currentTarget.src = defaultAvatar }}
                    />
                </div>
                <div
                    style={{
                        display: "flex",
                        width: "85%",
                        justifyContent: "space-between",
                    }}
                >
                    <div className="message-contain">
                        <div className="user-name">{conversation?.recipient?.firstName && (`${conversation.recipient.firstName} ${conversation.recipient.lastName[0]}.`)}</div>
                        <div className="last-message">
                            <SingleTickSvgIcon iconColor="#686c80" height="20px" width="20px" />
                            {conversation?.lastMessage?.text}
                        </div>
                    </div>
                    <div style={{ textAlign: "center" }}>
                        <div className="message-time">{timestampToDateFormate(conversation?.lastMessage?.createdAt)}</div>
                    </div>
                </div>
            </Card>
        </>
    )
}

export default ContactCard;
