export const timeZoneCountries = [
    "US",
    "UK",
    "Australia", 
    "Austria",
    "Belgium", 
    "Brazil", 
    "Bulgaria", 
    "Canada", 
    "Cyprus", 
    "Czech Republic", 
    "Denmark", 
    "Estonia", 
    "Finland",
    "France",
    "Germany", 
    "Greece", 
    "Hong Kong", 
    "Hungary", 
    "India",
    "Ireland", 
    "Italy", 
    "Japan", 
    "Latvia", 
    "Lithuania", 
    "Luxembourg", 
    "Malaysia", 
    "Malta", 
    "Mexico", 
    "Netherlands", 
    "New Zealand", 
    "Norway", 
    "Peru",
    "Poland", 
    "Portugal", 
    "Romania", 
    "Singapore", 
    "Slovakia", 
    "Slovenia", 
    "Spain", 
    "Sweden", 
    "Switzerland",
    "UAE"
] as const;
export type timeZoneCountriesType = typeof timeZoneCountries[number];
export type timeZoneCountryListType = typeof softTimeZones.US;
export const softTimeZones = {
    US: [{
        name: "Hawaii",
        id: "Pacific/Honolulu",
        standard: "Hawaiian Standard Time",
    },
    {
        name: "Alaska",
        id: "America/Anchorage",
        standard: "Alaskan Standard Time",
    },
    {
        name: "Pacific Time (US & Canada)",
        id: "Canada/Pacific",
        standard: "Pacific Standard Time",
    },

    {
        name: "Mountain Time (US & Canada)",
        id: "Canada/Mountain",
        standard: "Mountain Standard Time",
    },
    {
        name: "Arizona",
        id: "America/Phoenix",
        standard: " Mountain Standard Time",
    },
    {
        name: "Central Time (US & Canada)",
        id: "America/Chicago",
        standard: "Central Standard Time",
    },

    {
        name: "Eastern Time (US & Canada)",
        id: "Canada/Eastern",
        standard: "Eastern Standard Time",
    }],
    UK: [{
        name: "Western European Time",
        id: "Europe/London",
        standard: "Greenwich Mean Standard Time"
    }],
    Peru: [{
        name: "Peru Time",
        id: "America/Lima",
        standard: "Peru Standard Time"
    }],
    India: [{
        name: "India Time",
        id: "Asia/Kolkata",
        standard: "India Standard Time"
    }],
    UAE: [{
        name: "Gulf Time",
        id: "Asia/Dubai",
        standard: "Gulf Standard Time"
    }],
    France: [{
        name: "Central European Time",
        id: "Europe/Paris",
        standard: "Central European Standard Time"
    }],
    Australia: [{
        name: "Australian Eastern Time",
        id: "Australia/Sydney",
        standard: "Australian Eastern Time",
    }, 
    {
        name: "Norfolk Island Time",
        id: "Pacific/Norfolk",
        standard: "Norfolk Island Time",
    }, 
    {
        name: "Australian Central Time",
        id: "Australia/Adelaide",
        standard: "Australian Central Time",
    }, 
    {
        name: "Australian Western Time",
        id: "Australia/Perth",
        standard: "Australian Western Time",
    }, 
    {
        name: "Christmas Island Time",
        id: "Indian/Christmas",
        standard: "Christmas Island Time",
    }],
    Austria: [{
        name: "Central European Time",
        id: "Europe/Vienna",
        standard: "Central European Time",
    }],
    Belgium: [{
        name: "Central European Time",
        id: "Europe/Brussels",
        standard: "Central European Time",
    }],
    Brazil: [{
        name: "Acre Time",
        id: "America/Rio_Branco",
        standard: "Acre Time",
    }, {
        name: "Fernando de Noronha Time",
        id: "America/Noronha",
        standard: "Fernando de Noronha Time",
    }, {
        name: "Brasilia Time",
        id: "America/Sao_Paulo",
        standard: "Brasilia Time",
    }, {
        name: "Amazon Time",
        id: "America/Manaus",
        standard: "Amazon Time",
    }],
    Bulgaria: [{
        name: "Eastern European Time",
        id: "Europe/Sofia",
        standard: "Eastern European Time",
    }],
    Canada: [{
        name: "Atlantic Time",
        id: "America/Halifax",
        standard: "Atlantic Time", 
    }, 
    {
        name: "Central Time",
        id: "America/Winnipeg",
        standard: "Central Time", 
    }, 
    {
        name: "Eastern Time",
        id: "America/Toronto",
        standard: "Eastern Time", 
    }, 
    {
        name: "Mountain Time",
        id: "America/Edmonton",
        standard: "Mountain Time", 
    }, 
    {
        name: "Newfoundland Time",
        id: "America/St_Johns",
        standard: "Newfoundland Time", 
    }, {
        name: "Pacific Time",
        id: "America/Vancouver",
        standard: "Pacific Time", 
    }, 
    {
        name: "Central Time",
        id: "America/Regina",
        standard: "Central Time", 
    }],
    Cyprus: [{
        name: "Eastern European Time",
        id: "Asia/Nicosia",
        standard: "Eastern European Time",
    }],
    "Czech Republic": [{
        name: "Central European Time",
        id: "Europe/Copenhagen",
        standard: "Central European Time",
    }],
    Denmark: [{
        name: "Central European Time",
        id: "Europe/Copenhagen",
        standard: "Central European Time",
    }],
    Estonia: [{
        name: "Eastern European Time",
        id: "Europe/Tallinn",
        standard: "Eastern European Time",
    }],
    Finland: [{
        name: "Eastern European Time",
        id: "Europe/Helsinki",
        standard: "Eastern European Time",
    }],
    Germany: [{
        name: "Central European Time",
        id: "Europe/Berlin",
        standard: "Central European Time",
    }],
    Greece: [{
        name: "Eastern European Time",
        id: "Europe/Athens",
        standard: "Eastern European Time",
    }],
    "Hong Kong": [{
        name: "Hong Kong Time",
        id: "Asia/Hong_Kong",
        standard: "Hong Kong Time",
    }],
    Hungary: [{
        name: "Central European Time",
        id: "Europe/Budapest",
        standard: "Central European Time",
    }],
    Ireland: [{
        name: "Greenwich Mean Time",
        id: "Europe/Dublin",
        standard: "Greenwich Mean Time",
    }],
    Italy: [{
        name: "Central European Time",
        id: "Europe/Luxembourg",
        standard: "Central European Time",
    }],
    Japan: [{
        name: "Japan Time",
        id: "Asia/Tokyo",
        standard: "Japan Time",
    }],
    Latvia: [{
        name: "Eastern European Time",
        id: "Europe/Riga",
        standard: "Eastern European Time",
    }],
    Lithuania: [{
        name: "Eastern European Time",
        id: "Europe/Vilnius",
        standard: "Eastern European Time",
    }],
    Luxembourg: [{
        name: "Central European Time",
        id: "Europe/Luxembourg",
        standard: "Central European Time",
    }],
    Malaysia: [{
        name: "Malaysia Time",
        id: "Asia/Kuala_Lumpur",
        standard: "Malaysia Time",
    }],
    Malta: [{
        name: "Central European Time",
        id: "Europe/Malta",
        standard: "Central European Time",
    }],
    Mexico: [{
        name: "Mexican Pacific Time",
        id: "America/Chihuahua",
        standard: "Mexican Pacific Time",
    }, 
    {
        name: "Central Time",
        id: "America/Mexico_City",
        standard: "Central Time",
    }],
    Netherlands: [{
        name: "Central European Time",
        id: "Europe/Warsaw",
        standard: "Central European Time",
    }],
    "New Zealand": [{
        name: "New Zealand Time",
        id: "Pacific/Auckland",
        standard: "New Zealand Time",
    }],
    Norway: [{
        name: "Central European Time",
        id: "Europe/Warsaw",
        standard: "Central European Time",
    }],
    Poland: [{
        name: "Central European Time",
        id: "Europe/Warsaw",
        standard: "Central European Time",
    }],
    Portugal: [{
        name: "Western European Time",
        id: "Europe/Lisbon",
        standard: "Western European Time",
    }],
    Romania: [{
        name: "Eastern European Time",
        id: "Europe/Bucharest",
        standard: "Eastern European Time",
    }],
    Singapore: [{
        name: "Singapore Time",
        id: "Asia/Singapore",
        standard: "Singapore Time",
    }],
    Slovakia: [{
        name: "Central European Time",
        id: "Europe/Prague",
        standard: "Central European Time",
    }],
    Slovenia: [{
        name: "Central European Time",
        id: "Europe/Belgrade",
        standard: "Central European Time",
    }],
    Spain: [{
        name: "Central European Time",
        id: "Europe/Madrid",
        standard: "Central European Time",
    }],
    Sweden: [{
        name: "Central European Time",
        id: "Europe/Paris",
        standard: "Central European Time",
    }],
    Switzerland: [{
        name: "Central European Time",
        id: "Europe/Paris",
        standard: "Central European Time",
    }]
}
export const countries: Array<{
    id: timeZoneCountriesType,
    value: timeZoneCountriesType,
    name: timeZoneCountriesType,
}> = [{ id: 'US', name: 'US', value: 'US' },
{ id: 'UK', name: 'UK', value: 'UK', },
{ id: 'Australia', name: 'Australia', value: 'Australia' },
{ id: 'Austria', name: 'Austria', value: 'Austria' },
{ id: 'Belgium', name: 'Belgium', value: 'Belgium' },
{ id: 'Brazil', name: 'Brazil', value: 'Brazil' },
{ id: 'Bulgaria', name: 'Bulgaria', value: 'Bulgaria' },
{ id: 'Canada', name: 'Canada', value: 'Canada' },
{ id: 'Cyprus', name: 'Cyprus', value: 'Cyprus' },
{ id: 'Czech Republic', name: 'Czech Republic', value: 'Czech Republic' },
{ id: 'Denmark', name: 'Denmark', value: 'Denmark' },
{ id: 'Estonia', name: 'Estonia', value: 'Estonia' },
{ id: 'Finland', name: 'Finland', value: 'Finland' },
{ id: 'France', name: 'France', value: 'France' },
{ id: 'Germany', name: 'Germany', value: 'Germany' },
{ id: 'Greece', name: 'Greece', value: 'Greece' },
{ id: 'Hong Kong', name: 'Hong Kong', value: 'Hong Kong' },
{ id: 'Hungary', name: 'Hungary', value: 'Hungary' },
{ id: 'India', name: 'India', value: 'India' },
{ id: 'Ireland', name: 'Ireland', value: 'Ireland' },
{ id: 'Italy', name: 'Italy', value: 'Italy' },
{ id: 'Japan', name: 'Japan', value: 'Japan' },
{ id: 'Latvia', name: 'Latvia', value: 'Latvia' },
{ id: 'Lithuania', name: 'Lithuania', value: 'Lithuania' },
{ id: 'Luxembourg', name: 'Luxembourg', value: 'Luxembourg' },
{ id: 'Malaysia', name: 'Malaysia', value: 'Malaysia' },
{ id: 'Malta', name: 'Malta', value: 'Malta' },
{ id: 'Mexico', name: 'Mexico', value: 'Mexico' },
{ id: 'Netherlands', name: 'Netherlands', value: 'Netherlands' },
{ id: 'New Zealand', name: 'New Zealand', value: 'New Zealand' },
{ id: 'Norway', name: 'Norway', value: 'Norway' },
{ id: 'Peru', name: 'Peru', value: 'Peru' },
{ id: 'Poland', name: 'Poland', value: 'Poland' },
{ id: 'Portugal', name: 'Portugal', value: 'Portugal' },
{ id: 'Romania', name: 'Romania', value: 'Romania' },
{ id: 'Singapore', name: 'Singapore', value: 'Singapore' },
{ id: 'Slovakia', name: 'Slovakia', value: 'Slovakia' },
{ id: 'Slovenia', name: 'Slovenia', value: 'Slovenia' },
{ id: 'Spain', name: 'Spain', value: 'Spain' },
{ id: 'Sweden', name: 'Sweden', value: 'Sweden' },
{ id: 'Switzerland', name: 'Switzerland', value: 'Switzerland' },
{ id: 'UAE', name: 'UAE', value: 'UAE' }]


