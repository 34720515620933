import { convertNumberToDayTime } from "components/tab-selection/TabSelection"
import moment from "moment"
import { INTERVAL, NO_OF_DAYS, NO_OF_SLOTS } from "pages/onboarding/tutor-availability/components/TutorAvailabilitySlots"
import React, { Fragment, useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useHistory } from "react-router"
import { applicationConstant } from "utils/constant/applicationConstant"
import { navigatetoUrl } from "utils/helper/helper"
import { weekDayShortNameList } from "../../../../utils/helper/date-utils"


type MinMaxOFASlotType = {
    min: number,
    max: number
}

interface Props {
    // eslint-disable-next-line @typescript-eslint/ban-types
    availabilityData: object
    tutorTimeZone?: string
    studentTimeZone?: string
}

const ALL_DAY = 'All day';

// eslint-disable-next-line no-undef
const AvailabilityBlock = (props: Props): JSX.Element => {

    const { availabilityData, tutorTimeZone = undefined, studentTimeZone = undefined } = props;

    const [adjustedAvailability, setAdjustedAvailability] = useState<number[][]>([[]]);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const userType = useSelector((state: any) => state.auth.userAuthData.userType);

    // locally observed that availabilityData is NaN for some reason and is preventing ranges from being calculated
    useEffect(() => {
        let adjAva = Object.values(availabilityData);
        if (userType === applicationConstant.userTypeKeys.STUDENT) {
            adjAva = adjAva.map((slot: Array<number>) => {
                if (!tutorTimeZone || !studentTimeZone) return slot;
                const now = moment.utc();
                const tutorTZ = moment(now).tz(tutorTimeZone.trim())?.utcOffset();
                const studentTZ = moment(now).tz(studentTimeZone.trim())?.utcOffset();
                const offset = (studentTZ - tutorTZ) / 60;
                return slot.map((val) => val + (offset / INTERVAL));
            });
            for (let i = 0; i < adjAva.length; i++) {
                for (let j = 0; j < adjAva[i].length; j++) {
                    if (adjAva[i][j] >= NO_OF_SLOTS) {
                        adjAva[(i + 1) % NO_OF_DAYS].push(adjAva[i][j] - NO_OF_SLOTS);
                        adjAva[i].splice(j, 1);
                    }
                    if (adjAva[i][j] < 0) {
                        adjAva[(((i - 1) % NO_OF_DAYS) + NO_OF_DAYS) % NO_OF_DAYS].push(adjAva[i][j] + NO_OF_SLOTS);
                        adjAva[i].splice(j, 1);
                    }
                }
            }
        }
        setAdjustedAvailability(adjAva);
    }, [availabilityData])

    const history = useHistory();

    const getMinMaxForASlots = ({ arr, min, max }: { arr: number[], min: number, max: number }): string => {
        arr.sort((a, b) => a - b);

        const res = arr.reduce((prevResult: MinMaxOFASlotType, currValue: number): MinMaxOFASlotType => {
            const res: MinMaxOFASlotType = { ...prevResult };
            if (currValue >= min && currValue < max) {
                if (prevResult.min < 0) res.min = currValue;
                if (currValue >= res.max && currValue <= max) res.max = currValue + 1;
            }
            return res;
        }, { min: -1, max: -1 });

        if (res.min === -1) {
            return '';
        }
        
        return convertNumberToDayTime((res.min % 2 ? res.min - 1 : res.min) * INTERVAL) + "-" + convertNumberToDayTime((res.max % 2 ? res.max + 1 : res.max) * INTERVAL);
    }

    const getAvailabilityFromIndices = (availabilityIndexList: Array<number>): string[] => {
        if (availabilityIndexList.length === NO_OF_SLOTS) {
            return [ALL_DAY];
        };

        const range = 12;
        const res: string[] = [];
        for (let min = 0; min + range <= NO_OF_SLOTS; min = min + range) {
            res.push(getMinMaxForASlots({ arr: availabilityIndexList, min, max: min + range }));
        }

        return res;
    }

    return (
        <Fragment>
            <div className="availability-block">
                {adjustedAvailability.map((slot: Array<number>, index: number) => {
                    return (
                        <div key={index} className="day-wise">
                            <div className={`day active-slot`}>
                                {weekDayShortNameList[index].toUpperCase()}
                            </div>
                            <div className="timing">{getAvailabilityFromIndices(slot).map((val: string) => {
                                return <>
                                    {val && (userType === applicationConstant.userTypeKeys.STUDENT ? <u className="slot" onClick={() => { navigatetoUrl(history, "/panel/search/book-session") }}>{val}</u> : <p className="slot">{val}</p>)}
                                </>
                            })}</div>
                        </div>
                    )
                })}
            </div>
        </Fragment>
    )
}
export default AvailabilityBlock
