import React, { useState, useEffect } from "react"
import { Grid, Container } from "@material-ui/core"
import LessonHeader from "./LessonHeader"
import LessonRelatedModules from "./LessonRelatedModules"
import LessonDescription from "./LessonDescription"
import LessonVideo from "./LessonVideo"
import LessonPortrait from "./LessonPortrait"
import LessonNavigation from "./LessonNavigation"
import LessonExitModal from "./LessonExitModal"
import OneOnOneSession from "./OneOnOneSession"
import { useSelector, useDispatch } from "react-redux"
import LoaderSpinner from "../../components/loader/LoaderSpinner"
import { useHistory } from "react-router-dom"
import { lessonsConstant } from "../../store/constant"

export type refType = {
    sys: {
        id: string
        linkType: string
        type: string
    }
    fields: {
        lessonTitle: string
        description: string
    }
}

interface lessonType {
    description: string
    lessonTitle: string
    portraitOfAnApplicant: string
    lessonVideo?: {
        fields: {
            file: {
                contentType: string
                url: string
            }
        }
    }
    modules: Array<refType>
}


const LessonContent = ({ lessons, lessonIndex }: { lessons: any, lessonIndex: any }): JSX.Element => {
    const modules = useSelector((state: any): any => state.modules.modules)
    const isLoading = useSelector((state: any): any => state.modules.isLoading)
    const [lesson, setLesson] = useState<lessonType>()
    const [isExiting, setExiting] = useState<any>(false)
    const dispatch = useDispatch()
    let history = useHistory()
    useEffect(() => {
        setLesson(lessons[lessonIndex].fields as lessonType)
    }, [lessonIndex])

    useEffect(() => {
        document.body.scrollTo(0, 0);
    }, [])

    const previousLesson = () => {
        if (lessonIndex > 0) {
            navigateToLesson(lessonIndex - 1)
        }
    }
    const nextLesson = () => {
        if (lessonIndex < lessons.length - 1) {
            navigateToLesson(lessonIndex + 1)
        }
    }

    const navigateToLesson = (newIndex: any) => {
        dispatch({ type: lessonsConstant.NAVIGATE_TO_LESSON, lessonIndex: newIndex })
        history.push("/panel/lessons/" + lessons[newIndex].sys.id)
        document.body.scrollTo(0, 0)
    }
    return (
        <>
            <LessonExitModal isExiting={isExiting} setExiting={setExiting} />
            <Grid container className="lesson-flexible">
                <Container maxWidth={false} className='lesson-header'>
                    <LessonHeader lessonTitle={lesson?.lessonTitle} setExiting={setExiting} />
                </Container>
                <Grid container className="lesson-content">
                <Grid item xl={1} lg={1} md={1} sm={12} xs={12}></Grid>
                    <Grid item xl={10} lg={10} md={10} sm={12} xs={12} className="main-content">
                        <LessonDescription description={lesson?.description} />
                        {
                            isLoading ?
                                <LoaderSpinner />
                                :
                                <LessonVideo lessonVideo={modules[0]?.lessonVideo} />
                        }
                        <LessonPortrait portraitOfAnApplicant={lesson?.portraitOfAnApplicant} />
                        {
                            isLoading ?
                                <LoaderSpinner />
                                :
                                <LessonRelatedModules lessonId={lessons[lessonIndex].sys.id} modules={modules[0]?.modules} />
                        }
                    </Grid>
                    <Grid item xl={1} lg={1} md={1} sm={12} xs={12}></Grid>
                </Grid>
                <OneOnOneSession />
                <Grid container className="lesson-navigation">
                    <Grid item xl={1} lg={1} md={1} sm={12} xs={12}></Grid>
                    <Grid item xl={10} lg={10} md={10} sm={12} xs={12} className="main-content">
                        <LessonNavigation total={lessons.length} index={lessonIndex} previous={previousLesson} next={nextLesson} page="lesson" />
                    </Grid>
                    <Grid item xl={1} lg={1} md={1} sm={12} xs={12}></Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default LessonContent