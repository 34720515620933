import React, { useCallback, useEffect, useMemo, useState } from "react"
import { firestore } from "../../utils/helper/firebase"
import CheckBox from "../input-field/CheckBox"
import { useDispatch } from "react-redux"

export type NotificationSubscriptions = {
    sms?: boolean,
    email?: boolean,
}

export const SMSOptIn = ({ userId, setNotificationStatus }: { userId: string , setNotificationStatus: React.Dispatch<boolean>}) => {
    const dispatch = useDispatch()
    const subscriptionsField = "notificationSubscriptions"
    const [optIn, setOptIn] = useState<boolean>()
    const userDoc = useMemo(() => firestore.collection("users").doc(userId), [userId])
    //get latest value from db
    useEffect(() => {
        userDoc
            .get()
            .then((userSnapshot) => {
                const subscriptions = userSnapshot.get(subscriptionsField) || {};
                setOptIn(subscriptions.sms === true) // only explicity true is true. all other values are false
            })
            .catch(console.error)
    }, [userDoc, subscriptionsField])

    const toggleSMSSubscription = useCallback(() => {
        const newValue = !optIn;
        setNotificationStatus(newValue)
        setOptIn(newValue)
        userDoc.update({
            [`${subscriptionsField}.sms`]: newValue,
        }).then(() => {
            setOptIn(newValue);
        }).catch(() => {
            setOptIn(optIn);
        });
    }, [optIn, userDoc, subscriptionsField])

    return (
        <CheckBox
            title="I agree to receive SMS/Text Messages for notifications about my account – this is highly recommended to ensure you remain up-to-date on your pending requests, cancelations, etc. Local rates may apply."
            name="sms_subscriptions"
            classNames="sms-subscription-optin"
            inputProps={{
                onChange: toggleSMSSubscription,
                value: optIn,
                checked: optIn,
            }}
        />
    )
}
