let years = []

const currentYear = new Date().getFullYear()
let maxYear = currentYear + 9
let minYear = currentYear - 82
let id = 0

while (maxYear > minYear) {
    years.push({ name: maxYear.toString(), id: id + 1 })
    maxYear = maxYear - 1
    id = id + 1
}

const yearList = [...years]

const weekDay = [
    { id: 0, name: "SUN" },
    { id: 1, name: "MON" },
    { id: 2, name: "TUE" },
    { id: 3, name: "WED" },
    { id: 4, name: "THU" },
    { id: 5, name: "FRI" },
    { id: 6, name: "SAT" },
]



export { yearList, weekDay }
