import React, { useCallback, useEffect, useState } from "react"
import { Switch, Route, Redirect, useHistory } from "react-router-dom"
import SideBar from "../home/side-bar/SideBar"
import { applicationConstant } from "../../utils/constant/applicationConstant"
import interfaceConstant from "../../utils/constant/interfaceConstant"
import { useDispatch, useSelector } from "react-redux"
import { getUserWithDetails } from "../../utils/services/userServices"
import { loginSuccess } from "../../store/actions/userActions"
import LoaderSvgIcon from "../../assets/images/svgIcons/LoaderSvgIcon"
import BurgerMenu from "../../components/burger-menu/BurgerMenu"
import AdminRoute from "routes/AdminRoute"
import PanelRoutesForAdmin from "routes/PanelRoutesForAdmin"



const routeUrl: interfaceConstant.IrouteUrl = applicationConstant.routeUrl

export const AdminPanel = () => {
    const { userId } = useSelector((state: any) => state?.auth?.userAuthData) || {};
    const dispatch = useDispatch();
    const [isLoading, setLoading] = useState<boolean>(false);

    const fetchUserDetails = useCallback(async (): Promise<void> => {
        const userDetails = await getUserWithDetails(userId)
        dispatch(loginSuccess(userDetails));
    }, [userId]);

    const getRoutes = useCallback((Routes: any): JSX.Element | null => {
        return Routes.map((prop: any, key: number) => {
            if (prop.layout === routeUrl.ADMIN_PANEL_ROUTE) {
                return (
                    <AdminRoute
                        path={prop.layout + prop.path}
                        component={prop.component}
                        key={key}
                    />
                )
            } else {
                return null
            }
        })
    }, []);

    useEffect(() => {
        setLoading(true);
        fetchUserDetails().catch(console.error).finally(() => setLoading(false));
    }, [fetchUserDetails]);

    return isLoading ? (
        <div className="backDrop">
            <div className="wrapper">
                <LoaderSvgIcon />
            </div>
        </div>
    ) : (
        <div className="panel">
            <SideBar routes={PanelRoutesForAdmin} />
            <BurgerMenu routes={PanelRoutesForAdmin} />
            <div className="panel-body">
                <Switch>
                    {getRoutes(PanelRoutesForAdmin)}
                    {<Route path="*" exact>
                        <Redirect to="/admin/panel/dashboard" />
                    </Route>}
                </Switch>
            </div>
        </div>
    )
}