import React, { useState, useEffect } from "react"
import { Row, Col } from "reactstrap"
import { OnBoardingHeader } from "../../../components/on-boarding/OnboardingHeader"
import { educationLevel as educationList } from "../../../utils/constant/educationLists"

import { InputBox } from "../../../components/input-field/InputFields"
import { applicationConstant } from "../../../utils/constant/applicationConstant"
import InterfaceConstant from "../../../utils/constant/interfaceConstant"
import PlusButton from "../../../assets/images/plusButtonOnboarding.svg"
import { useFormik } from "formik"
import * as Yup from "yup"
import * as _ from "lodash"
import { modalConstant } from "../../../store/constant"
import { getFile } from "../../../utils/helper/helper"
import { setTutorEducation } from "../../../store/actions/TutorSignUpActions"
import { useDispatch, useSelector } from "react-redux"
import NavigationButtons from "../../../components/on-boarding/navigation-buttons/NavigationButtons"
import { useHistory } from "react-router-dom"
import { navigatetoUrl } from "../../../utils/helper/helper"
import GradeItem from "./GradeItem"
import { formatDate, DateFormats } from "../../../utils/helper/date-utils"
import { DatePicker } from "@material-ui/pickers"
import MessageAlert from "../../../components/message-alert/MessageAlert"
import SimpleDropdown from "../../../components/dropdown/SimpleDropdown"

interface Certifications {
    teacherEducationQualification: string
    certification: string
    issuing: string
    issuingDate: string
    expirationDate: string
    credentialId: string
    credentialUrl: string
    resumeFile: string
    Biography: string
    certificationValidation: any
}
interface Props {
    isComponent?: boolean
}
const TutorAdvisorEducationForm = ({ isComponent }: Props) => {
    const [openSnackBar, setOpenSnackBar] = useState(false)
    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState({
        message: "",
        type: "",
    })

    const [errorFile, setErrorFile] = useState(false)
    const history = useHistory()
    const dispatch = useDispatch()
    const [isShowError, setShowError] = useState(false)
    const routeUrl: InterfaceConstant.IrouteUrl = applicationConstant.routeUrl
    const tutorEducationInfo = useSelector((state: any) => state.tutorEducation.options)
    const grade = tutorEducationInfo.gradeOptions
    const educationLabel: InterfaceConstant.IappLabels = applicationConstant.appLabels
    const educationErrorLabel: InterfaceConstant.IappErrorLabels =
        applicationConstant.appErrorLabels
    const [handleFormError, setHandleFormError] = useState(
        grade.map((item: any) => {
            return { id: item.id, schoolName: "", degreeName: "", graduationYear: false }
        }),
    )


    const addOption = () => {
        const newErrorArray = [...handleFormError]
        const id = grade.length
        const optionLine = {
            id: id,
            schoolName: "",
            degreeName: "",
            graduationYear: "",
        }
        const optionErrorLine: any = {
            id: id,
            schoolName: "",
            degreeName: "",
            graduationYear: false,
        }
        newErrorArray.push(optionErrorLine)
        dispatch(setTutorEducation({ gradeOptions: [...grade, optionLine] }))
        setHandleFormError(newErrorArray)
    }

    const deleteOption = (index: number) => {
        const id = grade.length
        if (id === 1) {
            const modalInfo = {
                title: "Error",
                icon: "",
                text: "Can't delete all school details",
            }
            dispatch({ type: modalConstant.MODAL_SHOW, modalInfo })
        } else {
            const newErrorArray = handleFormError.filter((_: any, i: any) => i !== index)
            dispatch(
                setTutorEducation({ gradeOptions: grade.filter((_: any, i: any) => i !== index) }),
            )
            setHandleFormError(newErrorArray)
        }
    }

    const navigatePreviousPage = (): void => {
        navigatetoUrl(history, routeUrl.TUTOR_ADVISOR_APPLICATION_PAGE)
    }

    const navigateNextPage = (formikValues: any): void | boolean => {
        const keys = Object.keys(formikValues.errors)
        if (keys.length > 0) {
            window.scrollTo(0, 0)

        }
        const newArray = JSON.parse(JSON.stringify(handleFormError))
        grade.map((e: any, index: any) => {
            if (e.schoolName === "") {
                newArray[index].schoolName = educationErrorLabel.GLOBAL_REQUIRED
            }
            if (e.degreeName === "") {
                newArray[index].degreeName = educationErrorLabel.GLOBAL_REQUIRED
            }
            if (e.graduationYear === "") {
                newArray[index].graduationYear = true
            }
            setHandleFormError(newArray)
        })
        if (tutorEducationInfo.teacherEducationQualification.name === undefined) {
            if (!isShowError) {
                setShowError(true)
            }
        }
    }

    const formik = useFormik({
        initialValues: {
            teacherEducationQualification: tutorEducationInfo.teacherEducationQualification,
            certification: tutorEducationInfo.certification,
            issuing: tutorEducationInfo.issuing,
            issuingDate: tutorEducationInfo.issuingDate,
            expirationDate: tutorEducationInfo.expirationDate,
            credentialId: tutorEducationInfo.credentialId,
            credentialUrl: tutorEducationInfo.credentialUrl,
            resumeFile: tutorEducationInfo.resumeFile,
            Biography: tutorEducationInfo.biography,
            certificationValidation: false,
        },
        validationSchema: Yup.object({
            teacherEducationQualification: Yup.string().required(
                educationErrorLabel.GLOBAL_REQUIRED,
            ),
            certification: Yup.string().when(["certificationValidation"], {
                is: true,
                then: Yup.string().required(educationErrorLabel.GLOBAL_REQUIRED),
                otherwise: Yup.string().notRequired(),
            }),
            issuing: Yup.string().when(["certificationValidation"], {
                is: true,
                then: Yup.string().required(educationErrorLabel.GLOBAL_REQUIRED),
                otherwise: Yup.string().notRequired(),
            }),
            issuingDate: Yup.date().when(["certificationValidation"], {
                is: true,
                then: Yup.date()
                    .typeError(educationErrorLabel.GLOBAL_ISSUING_DATE_INVALID)
                    .required(educationErrorLabel.GLOBAL_ISSUING_DATE_INVALID),

                otherwise: Yup.date()
                    .notRequired()
                    .typeError(educationErrorLabel.GLOBAL_ISSUING_DATE_INVALID),
            }),
            expirationDate: Yup.date().when(["certificationValidation"], {
                is: true,
                then: Yup.date()
                    .typeError(educationErrorLabel.GLOBAL_ISSUING_DATE_INVALID)
                    .required(educationErrorLabel.GLOBAL_EXPIRATION_DATE_INVALID),
                otherwise: Yup.date()
                    .notRequired()
                    .typeError(educationErrorLabel.GLOBAL_ISSUING_DATE_INVALID),
            }),
            Biography: Yup.string().min(100).required(educationErrorLabel.GLOBAL_REQUIRED),
        }),
        onSubmit: (values: Certifications) => {

            let isError = false
            handleFormError.map((item: any) => {
                if (item.schoolName || item.degreeName || item.graduationYear) {
                    isError = true
                }
            })
            grade.map((item: any) => {
                if (!item.schoolName || !item.degreeName || !item.graduationYear) {
                    isError = true
                }
            })
            if (!errorFile && !isError && !isShowError) {
                navigatetoUrl(history, routeUrl.TUTOR_AVAILABILITY_PAGE)
            }
        },
    })

    useEffect(() => {
        const {
            certification,
            issuing,
            issuingDate,
            expirationDate,
            credentialId,
            credentialUrl,
        } = tutorEducationInfo
        if (
            certification ||
            issuing ||
            issuingDate ||
            expirationDate ||
            credentialId ||
            credentialUrl
        ) {
            formik.setFieldValue("certificationValidation", true, true)
        }
    }, [tutorEducationInfo])

    function onKeyDown(keyEvent: any) {
        if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
            keyEvent.preventDefault()
            navigateNextPage(formik)
        }
    }
    const handleDateChange = (e: any, type: string) => {
        const dateFormated = formatDate(e, DateFormats.EUA_DATE)
        switch (type) {
            case "issuingDate":
                !e
                    ? formik.setFieldValue("certificationValidation", false, true)
                    : formik.setFieldValue("certificationValidation", true, true)
                formik.setFieldValue("issuingDate", dateFormated, true)
                dispatch(setTutorEducation({ issuingDate: dateFormated }))
                break
            case "expirationDate":
                !e
                    ? formik.setFieldValue("certificationValidation", false, true)
                    : formik.setFieldValue("certificationValidation", true, true)
                formik.setFieldValue("expirationDate", dateFormated, true)
                dispatch(setTutorEducation({ expirationDate: dateFormated }))
                break

            default:
                break
        }
    }
    const handleChange = (e: any) => {
        const { name, value } = e.target

        switch (name) {
            case "certification":
                !value
                    ? formik.setFieldValue("certificationValidation", false, true)
                    : formik.setFieldValue("certificationValidation", true, true)
                formik.setFieldValue("certification", value, true)
                dispatch(setTutorEducation({ certification: value }))
                break
            case "issuing":
                !value
                    ? formik.setFieldValue("certificationValidation", false, true)
                    : formik.setFieldValue("certificationValidation", true, true)
                formik.setFieldValue("issuing", value, true)
                dispatch(setTutorEducation({ issuing: value }))
                break

            case "credentialId":
                !value
                    ? formik.setFieldValue("certificationValidation", false, true)
                    : formik.setFieldValue("certificationValidation", true, true)
                formik.setFieldValue("credentialId", value, true)
                dispatch(setTutorEducation({ credentialId: value }))
                break
            case "confirmPassword":
                formik.setFieldValue("confirmPassword", value, true)
                dispatch(setTutorEducation({ password: value }))
                break
            case "credentialUrl":
                !value
                    ? formik.setFieldValue("certificationValidation", false, true)
                    : formik.setFieldValue("certificationValidation", true, true)
                formik.setFieldValue("credentialUrl", value, true)
                dispatch(setTutorEducation({ credentialUrl: value }))
                break
            case "Biography":
                formik.setFieldValue("Biography", value, true)
                dispatch(setTutorEducation({ biography: value }))
                break
        }
    }

    const [educationLevel, setEducationLevel] = useState({
        error: educationErrorLabel.GLOBAL_REQUIRED,
        value: tutorEducationInfo.teacherEducationQualification
            ? tutorEducationInfo.teacherEducationQualification
            : { name: "", id: "", value: "" },
    })

    useEffect(() => {
        educationLevel.value.name !== "" &&
            formik.setFieldValue("teacherEducationQualification", educationLevel, true) &&
            setShowError(false)
        dispatch(setTutorEducation({ teacherEducationQualification: educationLevel.value }))
    }, [educationLevel])

    const handleResumeFile = async (file: any) => {
        const permittedTypes = [
            "application/msword",
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            "application/pdf",
            "image/jpeg",
            "image/png",
        ]

        if (file !== null && file.size > 10000000) {
            setErrorFile(true)
            setMessage({
                message: "Check the size of your file. 10mb max size allowed.",
                type: "error",
            })
            setOpenSnackBar(true)
        } else if (file !== null && !permittedTypes.includes(file.type)) {
            setErrorFile(true)
            setMessage({
                message: "You have entered an invalid file type for your resume, please try again.",
                type: "error",
            })
            setOpenSnackBar(true)
        } else {
            try {
                if (file !== null) {
                    const resumeFile = await getFile(file)
                    dispatch(
                        setTutorEducation({ resumeFile: { file: resumeFile, name: file.name } }),
                    )
                    setErrorFile(false)
                } else {
                    dispatch(setTutorEducation({ resumeFile: "" }))
                    setErrorFile(false)
                }
            } catch (error) {
                console.log(error)
            }
        }
    }
    return (
        <>
            <MessageAlert
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
                type={message.type}
                message={message.message}
            />
            <form onKeyDown={onKeyDown} onSubmit={formik.handleSubmit} autoComplete="off">
                <Row>
                    <Col xs="12">
                        <div className="onboarding-subheader">
                            <div className="heading">Select the option that best describes you:</div>
                        </div>
                    </Col>
                    <Col lg="4" md="12" sm="12" xs="12" className="positioned-dropdown">
                        <SimpleDropdown
                            title="Education Level"
                            placeholder="Select Education Level"
                            optionsList={educationList.filter((e: any) => e.id !== "ALL")}
                            fieldDetails={educationLevel}
                            setProps={setEducationLevel}
                            isShowError={isShowError}
                        />
                    </Col>
                </Row>

                {grade.map((option: any, index: any) => (
                    <Row key={index}>
                        <GradeItem
                            index={index}
                            handleFormError={handleFormError}
                            setHandleFormError={setHandleFormError}
                            deleteAction={() => deleteOption(index)}
                        />
                    </Row>
                ))}
                {grade.length < 4 && (
                    <Row>
                        <Col lg="2">
                            <div className="plus_button" onClick={addOption}>
                                <img src={PlusButton} alt="" />
                                <span>Add more</span>
                            </div>
                        </Col>
                    </Row>
                )}

                <Row>
                    <Col xs="12">
                        <hr className="divider m-space" />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <OnBoardingHeader
                            withMargin
                            heading={"Licenses and Certifications"}
                            subHeading="The Classroom Door requires College Advisors and Educational Consultants to meet certain criteria."
                            link="Learn more"
                            anchor="https://theclassroomdoor.com/find-an-academic-advisor/"
                        />
                    </Col>
                </Row>
                <Row>
                    <Col lg="6" md="6" sm="12" xs="12">
                        <InputBox
                            type={"text"}
                            title={educationLabel.EDUCATION_CERTIFICATION}
                            placeholder={educationLabel.EDUCATION_CERTIFICATION_PLACEHOLDER}
                            inputProps={{
                                name: "certification",
                                value: formik.values.certification,
                                onChange: handleChange,
                                onBlur: formik.handleBlur,
                            }}
                            error={
                                formik.touched.certification && formik.errors.certification
                                    ? formik.errors.certification
                                    : null
                            }
                        />
                    </Col>
                    <Col lg="6" md="6" sm="12" xs="12">
                        <InputBox
                            type={"text"}
                            title={educationLabel.EDUCATION_ISSUING}
                            placeholder={educationLabel.EDUCATION_ISSUING_PLACEHOLDER}
                            inputProps={{
                                name: "issuing",
                                value: formik.values.issuing,
                                onChange: handleChange,
                                onBlur: formik.handleBlur,
                            }}
                            error={
                                formik.touched.issuing && formik.errors.issuing
                                    ? formik.errors.issuing
                                    : null
                            }
                        />
                    </Col>
                </Row>
                <Row className="positioned-dropdown">
                    <Col lg="6" md="6" sm="12" xs="12" className="datepicker-wrapper p-30 drop">
                        <label>{educationLabel.EDUCATION_ISSUING_DATE}</label>
                        <DatePicker
                            format={DateFormats.EUA_DATE}
                            autoOk
                            clearable
                            inputVariant="standard"
                            variant="dialog"
                            className="datepicker-root"
                            value={formik.values.issuingDate}
                            InputProps={{
                                fullWidth: true,
                                label: educationLabel.EDUCATION_ISSUING_DATE,
                                placeholder: educationLabel.EDUCATION_ISSUING_DATE_PLACEHOLDER,
                                value: formik.values.issuingDate,
                                classes: { root: "mui-datepicker-input" },
                                disableUnderline: true,
                                onBlur: formik.handleBlur,
                            }}
                            maxDate={
                                tutorEducationInfo.expirationDate
                                    ? tutorEducationInfo.expirationDate
                                    : new Date()
                            }
                            InputLabelProps={{
                                disableAnimation: true,
                                shrink: false,
                            }}
                            helperText={
                                formik.touched.issuingDate &&
                                formik.errors.issuingDate &&
                                educationErrorLabel.GLOBAL_ISSUING_DATE_INVALID
                            }
                            onChange={(e: any) => handleDateChange(e, "issuingDate")}
                            error={
                                formik.touched.issuingDate && formik.errors.issuingDate
                                    ? true
                                    : undefined
                            }
                        />
                    </Col>
                    <Col lg="6" md="6" sm="12" xs="12" className="datepicker-wrapper p-30">
                        <label>{educationLabel.EDUCATION_EXPIRATION_DATE}</label>
                        <DatePicker
                            format={DateFormats.EUA_DATE}
                            autoOk
                            clearable
                            inputVariant="standard"
                            variant="dialog"
                            className="datepicker-root"
                            value={formik.values.expirationDate}
                            disabled={!tutorEducationInfo.issuingDate}
                            InputProps={{
                                fullWidth: true,
                                label: educationLabel.EDUCATION_EXPIRATION_DATE,
                                placeholder: educationLabel.EDUCATION_EXPIRATION_DATE_PLACEHOLDER,
                                value: formik.values.expirationDate,
                                classes: { root: "mui-datepicker-input" },
                                disableUnderline: true,
                                onBlur: formik.handleBlur,
                            }}
                            minDate={tutorEducationInfo.issuingDate}
                            InputLabelProps={{
                                disableAnimation: true,
                                shrink: false,
                            }}
                            helperText={
                                formik.touched.expirationDate &&
                                formik.errors.expirationDate &&
                                educationErrorLabel.GLOBAL_EXPIRATION_DATE_INVALID
                            }
                            onChange={(e: any) => handleDateChange(e, "expirationDate")}
                            error={
                                formik.touched.expirationDate && formik.errors.expirationDate
                                    ? true
                                    : undefined
                            }
                        />
                    </Col>
                </Row>
                <Row>
                    <Col lg="6" md="6" sm="12" xs="12">
                        <InputBox
                            type={"text"}
                            title={educationLabel.EDUCATION_CREDENTIAL_ID}
                            placeholder={educationLabel.EDUCATION_CREDENTIAL_ID_PLACEHOLDER}
                            inputProps={{
                                name: "credentialId",
                                value: formik.values.credentialId,
                                onChange: handleChange,
                                onBlur: formik.handleBlur,
                            }}
                            error={
                                formik.touched.credentialId && formik.errors.credentialId
                                    ? formik.errors.credentialId
                                    : null
                            }
                        />
                    </Col>
                    <Col lg="6" md="6" sm="12" xs="12">
                        <InputBox
                            type={"text"}
                            title={educationLabel.EDUCATION_CREDENTIAL_URL}
                            placeholder={educationLabel.EDUCATION_CREDENTIAL_URL_PLACEHOLDER}
                            inputProps={{
                                name: "credentialUrl",
                                value: formik.values.credentialUrl,
                                onChange: handleChange,
                                onBlur: formik.handleBlur,
                            }}
                            error={
                                formik.touched.credentialUrl && formik.errors.credentialUrl
                                    ? formik.errors.credentialUrl
                                    : null
                            }
                        />
                    </Col>
                </Row>
                <hr className="divider" />
                <Row>
                    <Col xs="12">
                        <div className="wrap">
                            <label className="input-label" style={{marginLeft:"-15px"}}>Upload Resume</label>
                            <label
                                className={`form-file-picker ${errorFile && "error-input-file"}`}
                                htmlFor="upload_button"
                            >
                                <span> Add file max 10 MB: .doc, .docx, .pdf, .jpg, .png</span>

                                <input
                                    type="file"
                                    id="upload_button"
                                    accept=".doc,.docx,.pdf,.jpg,.png"
                                    value=""
                                    onChange={(e: any) => handleResumeFile(e.target.files[0])}
                                />
                            </label>
                            {tutorEducationInfo.resumeFile && (
                                <div className="info-file">
                                    <span>
                                        {tutorEducationInfo.resumeFile.name
                                            ? tutorEducationInfo.resumeFile.name
                                            : "Resume File"}
                                    </span>
                                    <span
                                        className="navigation"
                                        onClick={() => handleResumeFile(null)}
                                    >
                                        Delete File
                                    </span>
                                </div>
                            )}
                        </div>
                    </Col>
                </Row>
                <hr className="divider" />
                <Row>
                    <Col xs="12">
                        <InputBox
                            type={"textarea"}
                            title={educationLabel.EDUCATION_BIOGRAPHY}
                            placeholder={educationLabel.EDUCATION_BIOGRAPHY_PLACEHOLDER}
                            inputProps={{
                                name: "Biography",
                                value: formik.values.Biography,
                                onChange: handleChange,
                                onBlur: formik.handleBlur,
                            }}
                            error={
                                formik.touched.Biography && formik.errors.Biography
                                    ? formik.errors.Biography
                                    : null
                            }
                        />
                    </Col>
                </Row>
                {!isComponent && (
                    <>
                        <div className="onboarding-actions">
                            <div className="onboarding-divider" />
                            <NavigationButtons
                                previousClick={navigatePreviousPage}
                                nextClick={() => navigateNextPage(formik)}
                            />
                        </div>
                    </>
                )}
            </form>
        </>
    )
}

export default TutorAdvisorEducationForm
