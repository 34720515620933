import React, { useState, useEffect } from "react"
import { Grid } from "@material-ui/core"
import { useFormik } from "formik"
import * as Yup from "yup"
import { applicationConstant } from "../../../../utils/constant/applicationConstant"
import InterfaceConstant from "../../../../utils/constant/interfaceConstant"
import { InputBox } from "../../../../components/input-field/InputFields"
import { Container, Row, Col } from "reactstrap"
import Button from "../../../../components/button/Button"
import UserProfilePhoto from "./UserProfilePhoto"
import { useSelector, useDispatch } from "react-redux"
import MessageAlert from "../../../../components/message-alert/MessageAlert"
import _ from "lodash"
import { DatePicker } from "@material-ui/pickers"
import { DateFormats, formatDate } from "../../../../utils/helper/date-utils"
import BeatLoader from "react-spinners/BeatLoader"
import {
    setTeacherMyprofile,
    setTutorEducation,
} from "../../../../store/actions/TutorSignUpActions"
import { loginSuccess } from "../../../../store/actions/userActions"
import { updateTutor, updateUser, fileUpload } from "../../../../utils/helper/firebase"
import { OnBoardingHeader } from "../../../../components/on-boarding/OnboardingHeader"
import { educationLevel as educationList } from "../../../../utils/constant/educationLists"
import PlusButton from "../../../../assets/images/plusButtonOnboarding.svg"
import { modalConstant } from "../../../../store/constant"
import { getFile } from "../../../../utils/helper/helper"
import GradeItem from "../../../onboarding/tutor-advisor-education/GradeItem"
import TutorAdvisorSubjects from "./TutorAdvisorSubjects"
import TutorAdvisorTests from "./TutorAdvisorTests"
import Modal from "../../../../components/modal_/Modal"
import { getUserWithDetails } from "../../../../utils/services/userServices"
import TutorAdvisorAdvisingSpeciality from "./TutorAdvisorAdvisingSpeciality"
import SimpleDropdown from "../../../../components/dropdown/SimpleDropdown"
import moment from "moment"
import useSetTeacher from "utils/hooks/useSetTeacher"
import { softTimeZones, timeZoneCountries, timeZoneCountriesType, timeZoneCountryListType, countries } from "../../../../utils/constant/softtimezones"
import { timeZoneSoftReturn, timeZoneAlreadyAvailable, extractDefaultTimezonCountry } from "../../../../utils/helper/softTimeZone-utils"
import PhoneNumberInput from "components/phone-number-input/PhoneNumberInput"
import parsePhoneNumber, { isValidPhoneNumber, NationalNumber } from "libphonenumber-js"
import { SMSOptIn } from "components/OptIns/SMSOptIn";
import { useSnackbar } from "notistack"
import { useMediaQuery } from "react-responsive"

interface User {
    firstName: string
    lastName: string
    teacherEducationQualification: string
    certification: string
    issuing: string
    issuingDate: string
    expirationDate: string
    credentialId: string
    credentialUrl: string
    teacherResume: string
    biography: string
    biographyFlag: boolean
    certificationValidation: any
    timeZone: string
    phoneNumber: {
        countryCode: string,
        countryPrefix: string,
        number: string
    }
}
interface Iprops {
    handleAvatarClick: Function
    optionsOpen: boolean
    setDataUpdated: Function
    tutorPreview: Function
}
const teacherTypeKeys: InterfaceConstant.IteacherTypes = applicationConstant.teacherTypeKeys


let timeZoneList = softTimeZones;

const countriesList = countries;

const TutorProfileForm = ({ handleAvatarClick, optionsOpen, setDataUpdated, tutorPreview }: Iprops) => {
    const dispatch = useDispatch()
    const { userAuthData } = useSelector((state: any) => state.auth)
    const { enqueueSnackbar } = useSnackbar();
    const [errorFile, setErrorFile] = useState(false)
    const [isShowError, setShowError] = useState(false)
    const educationLabel: InterfaceConstant.IappLabels = applicationConstant.appLabels
    const educationErrorLabel: InterfaceConstant.IappErrorLabels =
        applicationConstant.appErrorLabels
    const userData = useSelector((state: any) => state.auth.userAuthData)
    const tutorEducationInfo = useSelector((state: any) => state.tutorEducation.options)
    const tutorProfileInfo = useSelector((state: any) => state.tutorMyprofile.options)
    const { subject, test, specialization } = useSelector((state: any) => state.tutorSignUp.value)
    const { teacherType } = useSelector((state: any) => state.tutorSignUp)
    const [defaultTimeZone, setDefaultTimeZone] = useState(moment.tz.guess())
    const modalInfo = useSelector((state: any) => state.modal)
    const grade = tutorEducationInfo.gradeOptions
    const [handleFormError, setHandleFormError] = useState(
        grade.map((item: any) => {
            return { id: item.id, schoolName: "", degreeName: "", graduationYear: false }
        }),
    )
    const d = new Date()
    d.setFullYear(d.getFullYear() - 5)
    const [notificationStatus, setNotificationStatus] = useState(userAuthData.notificationSubscriptions?.sms)
    const [openSnackBar, setOpenSnackBar] = useState<boolean>(false)
    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState({
        message: "",
        type: "",
    })
    const [phoneNumber, setPhoneNumber] = useState<{
        number: "" | NationalNumber | undefined,
        countryCode: string,
        countryPrefix: string
    }>({
        number: tutorProfileInfo?.phoneNumber?.number || "",
        countryCode: tutorProfileInfo?.phoneNumber?.countryCode || "+1",
        countryPrefix: tutorProfileInfo?.phoneNumber?.countryPrefix || "US"
    });
    const [phoneNumberError, setPhoneNumberError] = useState<string | undefined>();
    const setTeacher = useSetTeacher();
    const appLabels: InterfaceConstant.IappLabels = applicationConstant.appLabels
    const appErrorLabels: InterfaceConstant.IappErrorLabels = applicationConstant.appErrorLabels
    const [timeZoneInputProps, settimeZoneInputProps] = useState({
        error: appErrorLabels.GLOBAL_REQUIRED,
        value: {
            id: '',
            name: '',
            standard: ''
        }
    })
    const [firstNameInputProps, setfirstNameInputProps] = useState({
        value: userData.firstName ? userData.firstName : "First Name",
    })
    const [lastNameInputProps, setlastNameInputProps] = useState({
        value: userData.lastName ? userData.lastName : "Last Name",
    })
    const userCountryName = useSelector((state: any) => userData.defaultTimezoneCountry);
    const [timeZoneCountryProps, settimeZoneCountryProps] = useState({
        error: appErrorLabels.GLOBAL_REQUIRED,
        value: extractDefaultTimezonCountry(userCountryName)
    })
    const [allTimeZones, setAllTimeZones] = useState(timeZoneList[timeZoneCountryProps?.value?.name])
    const [isShowZoneError, setisShowZoneError] = useState(false)
    const [isCountrySetByTutor, setIsCountrySetByTutor] = useState(false)
    const [isPhoneNumberEmpty, setIsPhoneNumberEmpty] = useState<boolean>(true);


    useEffect(() => {
        dispatch(setTeacherMyprofile({ defaultTimeZone: timeZoneInputProps.value.name }))
    }, [timeZoneInputProps])
    useEffect(() => {
        setLoading(false)

    }, [tutorProfileInfo.profilePic])

    useEffect(() => {
        if (userData.defaultTimeZone) {
            const newTimeZone: any = timeZoneAlreadyAvailable(userData.defaultTimeZone);
            if (newTimeZone) {
                settimeZoneInputProps({
                    error: appErrorLabels.GLOBAL_REQUIRED,
                    value: newTimeZone
                }
                )
            }
            else {
                const newTimeZone = timeZoneSoftReturn(userData.defaultTimeZone);
                settimeZoneInputProps({
                    error: appErrorLabels.GLOBAL_REQUIRED,
                    value: newTimeZone
                })
            }
        }

    }, [timeZoneList, userData])
    useEffect(() => {
        //dont remove it
    }, [tutorProfileInfo, tutorEducationInfo])

    useEffect(() => {
        if (!isCountrySetByTutor) {
            setIsCountrySetByTutor(true)
            return
        }
        setAllTimeZones(timeZoneList[timeZoneCountryProps?.value?.name]);
        settimeZoneInputProps({ error: appErrorLabels.GLOBAL_REQUIRED, value: timeZoneList[timeZoneCountryProps?.value?.name][0] })
    }, [timeZoneCountryProps])

    useEffect(() => {
        dispatch(setTeacherMyprofile({ firstName: firstNameInputProps.value }))
    }, [firstNameInputProps.value])

    useEffect(() => {
        dispatch(setTeacherMyprofile({ lastName: lastNameInputProps.value }))
    }, [lastNameInputProps.value])

    useEffect(() => {
        formik.setFieldValue("phoneNumber", phoneNumber);
    }, [phoneNumber]);



    const addOption = () => {
        const newErrorArray = [...handleFormError]
        const id = grade.length
        const optionLine = {
            id: id,
            schoolName: "",
            degreeName: "",
            graduationYear: "",
        }
        const optionErrorLine: any = {
            id: id,
            schoolName: "",
            degreeName: "",
            graduationYear: false,
        }
        newErrorArray.push(optionErrorLine)
        dispatch(setTutorEducation({ gradeOptions: [...grade, optionLine] }))
        setHandleFormError(newErrorArray)
    }

    const handlePhoneNumber = (value: string) => {
        const phoneNumber = parsePhoneNumber("" + value);
        if (!value.split(" ")[0].includes("0") && (phoneNumber?.nationalNumber === undefined)) {
            setIsPhoneNumberEmpty(true)
        } else {
            setIsPhoneNumberEmpty(false)
        }
        const { countryCallingCode = userAuthData?.phoneNumber?.countryCode, nationalNumber = '', country = userAuthData?.phoneNumber?.countryPrefix } = phoneNumber || {};
        setPhoneNumber({ countryCode: "+" + countryCallingCode, number: nationalNumber, countryPrefix: country?.toUpperCase() });
    }


    const deleteOption = (index: number) => {
        const id = grade.length
        if (id === 1) {
            const modalInfo = {
                title: "Error",
                icon: "",
                text: "Can't delete all school details",
            }
            dispatch({ type: modalConstant.MODAL_SHOW, modalInfo })
        } else {
            const newErrorArray = handleFormError.filter((_: any, i: any) => i !== index)
            dispatch(
                setTutorEducation({ gradeOptions: grade.filter((_: any, i: any) => i !== index) }),
            )
            setHandleFormError(newErrorArray)
        }
    }

    const formatCheck = (value: string) => {
        var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;

        if (format.test(value)) {
            return true;
        } else {
            return false;
        }

    }
    const hasWhiteSpace = (s: string) => {
        return s.indexOf(' ') >= 0;
    }

    const checkforcertification = () => {
        if (
            tutorEducationInfo.issuing &&
            tutorEducationInfo.issuingDate &&
            tutorEducationInfo.certification) { return true; }
        else if (
            !tutorEducationInfo.issuing &&
            !tutorEducationInfo.issuingDate &&
            !tutorEducationInfo.certification) { return true; }

        return false


    }
    const navigateNextPage = async (formikValues: any): Promise<boolean> => {
        if (!timeZoneInputProps.value?.name) {
            setisShowZoneError(true);
            setMessage({
                message: "Please select a Time Zone",
                type: "error",
            })
            setOpenSnackBar(true)
            setLoading(false)
            return false
        }
        const newArray = JSON.parse(JSON.stringify(handleFormError))
        grade.map((e: any, index: any) => {
            if (e.schoolName === "") {
                newArray[index].schoolName = educationErrorLabel.GLOBAL_REQUIRED
                setMessage({
                    message: "Please add a correct School name",
                    type: "error",
                })
                setOpenSnackBar(true)
            }
            if (e.degreeName === "") {
                newArray[index].degreeName = educationErrorLabel.GLOBAL_REQUIRED
                setMessage({
                    message: "Please add a correct Degree name",
                    type: "error",
                })
                setOpenSnackBar(true)
            }
            if (e.graduationYear === "") {
                newArray[index].graduationYear = true
                setMessage({
                    message: "Please add a correct Graduation Year",
                    type: "error",
                })
                setOpenSnackBar(true)
            }
            setHandleFormError(newArray)
        })
        if (tutorEducationInfo.biography.length < 100) {
            if (!isShowError) {
                setShowError(true)
                setMessage({
                    message: "Biography length should be greater than 100 chars.",
                    type: "error",
                })
                setOpenSnackBar(true)
            }
        }
        if (tutorEducationInfo.teacherEducationQualification.name === "") {
            if (!isShowError) {
                setShowError(true)
                setMessage({
                    message: "Please choose a Current Grade to continue.",
                    type: "error",
                })
                setOpenSnackBar(true)
            }
        }

        let word = lastNameInputProps.value.trim()
            .split(/ +/)
            .join(" ");
        formik.setFieldValue("lastName", word, true)
        setlastNameInputProps({ value: word })
        dispatch(setTeacherMyprofile({ lastName: word }))

        if (!formikValues.validateForm()) {
            setMessage({
                message: "Please check and fill all fields to continue.",
                type: "error",
            })
            setOpenSnackBar(true)
            setLoading(false)
            return false
        }
        if (firstNameInputProps.value === "" || formatCheck(firstNameInputProps.value) || hasWhiteSpace(firstNameInputProps.value)) {
            setMessage({
                message: "Please enter a valid First Name.",
                type: "error",
            })
            setOpenSnackBar(true)
            setLoading(false)
            return false
        }
        if (!checkforcertification()) {
            setMessage({
                message: "Please enter valid certification data",
                type: "error",
            })
            setOpenSnackBar(true)
            setLoading(false)
            return false
        }
        if (lastNameInputProps.value === "" || formatCheck(lastNameInputProps.value)) {
            setMessage({
                message: "Please enter a valid Last Name.",
                type: "error",
            })
            setOpenSnackBar(true)
            setLoading(false)
            return false
        }

        return true
    }
    const formik = useFormik({
        initialValues: {
            firstName: firstNameInputProps.value,
            lastName: lastNameInputProps.value,
            teacherEducationQualification: tutorEducationInfo.teacherEducationQualification.name,
            certification: tutorEducationInfo.certification,
            issuing: tutorEducationInfo.issuing,
            issuingDate: tutorEducationInfo.issuingDate,
            expirationDate: tutorEducationInfo.expirationDate,
            credentialId: tutorEducationInfo.credentialId,
            credentialUrl: tutorEducationInfo.credentialUrl,
            teacherResume: tutorEducationInfo.teacherResume,
            biography: tutorEducationInfo.biography,
            biographyFlag: false,
            certificationValidation: false,
            timeZone: tutorEducationInfo?.timeZone,
            phoneNumber: {
                countryCode: tutorProfileInfo?.phoneNumber?.countryCode || "+1",
                countryPrefix: tutorProfileInfo?.phoneNumber?.countryPrefix || "US",
                number: tutorProfileInfo?.phoneNumber?.number || ""
            }
        },
        validationSchema: Yup.object({
            firstName: Yup.string()
                .matches(/^[\\a-zA-Zp{L}.'-]+$/, 'English alphabets and .\'- are allowed only')
                .required(appErrorLabels.GLOBAL_REQUIRED),
            lastName: Yup.string().matches(/^[\\a-zA-Zp{L} .'-]*$/, 'Blank space, English alphabets and .\'- are allowed only')
                .required(appErrorLabels.GLOBAL_REQUIRED),
            teacherEducationQualification: Yup.string().required(educationErrorLabel.GLOBAL_REQUIRED),
            certification: Yup.string().when(["certificationValidation"], {
                is: true,
                then: Yup.string().required(educationErrorLabel.GLOBAL_REQUIRED),
                otherwise: Yup.string().notRequired(),
            }),
            issuing: Yup.string().when(["certificationValidation"], {
                is: true,
                then: Yup.string().required(educationErrorLabel.GLOBAL_REQUIRED),
                otherwise: Yup.string().notRequired(),
            }),
            issuingDate: Yup.date().when(["certificationValidation"], {
                is: true,
                then: Yup.date()
                    .required(educationErrorLabel.GLOBAL_ISSUING_DATE_INVALID),

                otherwise: Yup.date()
                    .notRequired()
                    .typeError(educationErrorLabel.GLOBAL_ISSUING_DATE_INVALID),
            }),
            expirationDate: Yup.date().when(["certificationValidation"], {
                is: true,
                then: Yup.date()
                    .typeError(educationErrorLabel.GLOBAL_ISSUING_DATE_INVALID)
                    .required(educationErrorLabel.GLOBAL_EXPIRATION_DATE_INVALID),
                otherwise: Yup.date()
                    .notRequired()
                    .typeError(educationErrorLabel.GLOBAL_ISSUING_DATE_INVALID),
            }),
            biography: Yup.string()
                .min(100, educationErrorLabel.GLOBAL_MIN_LENGTH_BIOGRAPHY)
                .required(educationErrorLabel.GLOBAL_REQUIRED),
        }),
        onSubmit: async (values: User) => {
            if (!isValidPhoneNumber(values.phoneNumber.countryCode + values.phoneNumber.number) && (!isPhoneNumberEmpty || notificationStatus)) {
                const errorMessage = "Please enter a valid Phone Number.";
                setPhoneNumberError(errorMessage);
                enqueueSnackbar(errorMessage, {
                    variant: "error",
                    autoHideDuration: 10000
                });
                return;
            } else {
                setPhoneNumberError(undefined);
            }
            if (!timeZoneInputProps.value?.name) {
                setisShowZoneError(true);
                setMessage({
                    message: "Please select a Time Zone",
                    type: "error",
                })
                setOpenSnackBar(true)
                setLoading(false)
                return false
            }
            let isError = false
            handleFormError.map((item: any) => {
                if (item.schoolName || item.degreeName || item.graduationYear) {
                    isError = true
                }
            })
            grade.map((item: any) => {
                if (!item.schoolName || !item.degreeName || !item.graduationYear) {
                    isError = true
                }
            })
            if (!errorFile && !isError) {
                setLoading(true)
                const newOptions: Array<string> = []

                interface ItemProps {
                    id: string
                    name: string
                }

                subject.length > 0 && subject.map((item: ItemProps) => newOptions.push(item.id))
                test.length > 0 && test.map((item: ItemProps) => newOptions.push(item.id))
                specialization.length > 0 &&
                    specialization.map((item: ItemProps) => newOptions.push(item.id))


                const profileData = {
                    file: tutorProfileInfo.profilePic.downloadURL,
                    fileName: "profileImage.jpeg",
                    filePath: `/users/${tutorProfileInfo.userId}/public/`,
                }
                const data = tutorProfileInfo.profilePic.newPic ? await fileUpload(profileData) : tutorProfileInfo.profilePic.downloadURL;

                const profilePic = {
                    downloadURL: data,
                    storagePath: `/users/${tutorProfileInfo.userId}/public/`,
                }

                const userDBData = {
                    firstName: firstNameInputProps.value,
                    lastName: lastNameInputProps.value,
                    profilePic: profilePic,
                    phoneNumber: values.phoneNumber,
                    defaultTimeZone: timeZoneInputProps.value?.id ? timeZoneInputProps.value.id : null,
                    defaultTimezoneCountry: timeZoneCountryProps.value.id,
                }
                const tutorDBData = {
                    teacherCertificateDetails: {
                        certificationName: tutorEducationInfo.certification,
                        credentialId: tutorEducationInfo.credentialId,
                        credentialUrl: tutorEducationInfo.credentialUrl,
                        expirationDate: tutorEducationInfo.expirationDate,
                        issueDate: tutorEducationInfo.issuingDate,
                        issuingOrganization: tutorEducationInfo.issuing,
                    }
                    ,
                    subjects: newOptions,
                    teacherSchoolDetails: tutorEducationInfo.gradeOptions,
                    teacherEducationQualification: tutorEducationInfo.teacherEducationQualification,
                    teacherResume: tutorEducationInfo.teacherResume,
                    teacherBiography: tutorEducationInfo.biography,
                }
                try {
                    await updateTutor(tutorProfileInfo.userId, tutorDBData)
                    await updateUser(tutorProfileInfo.userId, userDBData)
                    await getUserWithDetails(tutorProfileInfo.userId).then((data: any) => {
                        setLoading(false)
                        setMessage({
                            message: "Profile sucessfully updated.",
                            type: "success",
                        })
                        setOpenSnackBar(true)
                        dispatch(loginSuccess(data))
                        setDataUpdated(true)
                    })
                } catch (error) {
                    setLoading(false)
                    setMessage({
                        message: "An error occurred: " + error,
                        type: "error",
                    })
                    setOpenSnackBar(true)
                }
            }
        },
    })

    useEffect(() => {
        const {
            certification,
            issuing,
            issuingDate,
            expirationDate,
            credentialId,
            credentialUrl,
        } = tutorEducationInfo
        if (
            certification ||
            issuing ||
            issuingDate ||
            expirationDate ||
            credentialId ||
            credentialUrl
        ) {
            formik.setFieldValue("certificationValidation", true, true)
        }
    }, [tutorEducationInfo])

    const handleDateChange = (e: any, type: string) => {
        var dateFormated = formatDate(e, DateFormats.EUA_DATE)
        if (dateFormated == "Invalid date") {
            dateFormated = ""
        }
        switch (type) {
            case "issuingDate":
                !e
                    ? formik.setFieldValue("certificationValidation", false, true)
                    : formik.setFieldValue("certificationValidation", true, true)
                formik.setFieldValue("issuingDate", dateFormated, true)
                dispatch(setTutorEducation({ issuingDate: dateFormated }))
                break
            case "expirationDate":
                !e
                    ? formik.setFieldValue("certificationValidation", false, true)
                    : formik.setFieldValue("certificationValidation", true, true)
                formik.setFieldValue("expirationDate", dateFormated, true)
                dispatch(setTutorEducation({ expirationDate: dateFormated }))
                break

            default:
                break
        }
    }
    const handleChange = (e: any) => {
        const { name, value } = e.target
        switch (name) {
            case "certification":
                !value
                    ? formik.setFieldValue("certificationValidation", false, true)
                    : formik.setFieldValue("certificationValidation", true, true)
                formik.setFieldValue("certification", value, true)
                dispatch(setTutorEducation({ certification: value }))
                break
            case "issuing":
                !value
                    ? formik.setFieldValue("certificationValidation", false, true)
                    : formik.setFieldValue("certificationValidation", true, true)
                formik.setFieldValue("issuing", value, true)
                dispatch(setTutorEducation({ issuing: value }))
                break
            case "credentialId":
                !value
                    ? formik.setFieldValue("certificationValidation", false, true)
                    : formik.setFieldValue("certificationValidation", true, true)
                formik.setFieldValue("credentialId", value, true)
                dispatch(setTutorEducation({ credentialId: value }))
                break
            case "confirmPassword":
                formik.setFieldValue("confirmPassword", value, true)
                dispatch(setTutorEducation({ password: value }))
                break
            case "credentialUrl":
                !value
                    ? formik.setFieldValue("certificationValidation", false, true)
                    : formik.setFieldValue("certificationValidation", true, true)
                formik.setFieldValue("credentialUrl", value, true)
                dispatch(setTutorEducation({ credentialUrl: value }))
                break
            case "biography":
                formik.setFieldValue("biography", value, true)
                if (value?.length > 0) {
                    formik.setFieldValue("biographyFlag", true, true)
                } else {
                    formik.setFieldValue("biographyFlag", false, true)
                }
                dispatch(setTutorEducation({ biography: value }))
                break
            case "firstName":
                let valuef = value.trim()
                if (valuef[0] != '-' && valuef[0] != '.' && valuef[0] != "'") {
                    setfirstNameInputProps({ value: value && value[0].toUpperCase() + value.substring(1) })
                    formik.setFieldValue("firstName", value && value[0].toUpperCase() + value.substring(1), true)
                    dispatch(setTeacherMyprofile({ firstName: value }))
                }
                break
            case "lastName":
                let valuel = value
                if (valuel[0] == ' ') {
                    valuel = valuel.trim()

                }
                let words = valuel.split(" ");
                words = words.map((word: string) => {
                    return word && word[0].toUpperCase() + word.substring(1);
                }).join(" ");
                if (value[0] != '-' && value[0] != '.' && value[0] != "'") {
                    formik.setFieldValue("lastName", words, true)
                    setlastNameInputProps({ value: words })
                    dispatch(setTeacherMyprofile({ lastName: words }))
                }
                break
        }
    }
    const [educationLevel, setEducationLevel] = useState({
        error: educationErrorLabel.GLOBAL_REQUIRED,
        value: tutorEducationInfo.teacherEducationQualification
            ? tutorEducationInfo.teacherEducationQualification
            : { name: "", id: "" },
    })

    useEffect(() => {
        educationLevel.value.name !== undefined &&
            formik.setFieldValue("teacherEducationQualification", educationLevel.value, true)
        dispatch(setTutorEducation({ teacherEducationQualification: educationLevel.value }))
    }, [educationLevel])

    const handleResumeFile = async (file: any) => {
        const permittedTypes = [
            "application/msword",
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            "application/pdf",
            "image/jpeg",
            "image/png",
        ]

        if (file !== null && file.size > 10000000) {
            setErrorFile(true)
            setMessage({
                message: "Check the size of your file. 10mb max size allowed.",
                type: "error",
            })
            setOpenSnackBar(true)
        } else if (file === null) {
            const resumeData = {
                downloadURL: "",
                storagePath: "",
                name: "",
            }
            dispatch(setTutorEducation({ teacherResume: resumeData }))
            setErrorFile(false)
            setLoading(false)
        } else if (!permittedTypes.includes(file.type)) {
            setErrorFile(true)
            setMessage({
                message: "You have entered an invalid file type for your resume, please try again.",
                type: "error",
            })
            setOpenSnackBar(true)
        } else {
            setErrorFile(false)
            setLoading(true)

            try {
                if (file !== null) {
                    const teacherResume = await getFile(file)
                    const profileData = {
                        file: teacherResume,
                        fileName: file.name,
                        filePath: `/users/${tutorProfileInfo.userId}/public/`,
                    }

                    fileUpload(profileData).then((data: any) => {
                        const resumeData = {
                            downloadURL: data,
                            storagePath: `/users/${tutorProfileInfo.userId}/public/`,
                            name: file.name,
                        }

                        dispatch(setTutorEducation({ teacherResume: resumeData }))
                        setErrorFile(false)
                        setLoading(false)
                    })
                } else {
                    setLoading(false)
                    dispatch(setTutorEducation({ teacherResume: "" }))
                }
            } catch (error) {
                setLoading(false)
            }
        }
    }
    const onExitModal = (): void => {
        dispatch({ type: modalConstant.MODAL_HIDE })
    }

    const isMobileScreen = useMediaQuery({ query: '(max-device-width: 768px)' });

    return (
        <>
            <MessageAlert
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
                type={message.type}
                message={message.message}
            />
            <form onSubmit={formik.handleSubmit} autoComplete="off" className="account-profile py-4">
                <Grid container direction="row" justify="center" alignContent="flex-start">
                    <Grid item xl={2} lg={3} md={3} sm={12} xs={12}>
                        <div className='previewProfile'>
                            <UserProfilePhoto
                                loading={loading}
                                setLoading={setLoading}
                                handleAvatarClick={handleAvatarClick}
                                optionsOpen={optionsOpen}
                                source={"tutor"}
                            />
                            <Button
                                title={'Preview Profile'}
                                type='button'
                                isOutlineStyle={true}
                                style={{ marginTop: '30px' }}
                                clickAction={() => {
                                    setTeacher(userData.userId).then(isDone => {
                                        isDone && tutorPreview(true);
                                    }).catch(console.error)
                                }}

                            ></Button>
                        </div>
                    </Grid>

                    <Grid item xl={10} lg={9} md={9} sm={12} xs={12} >
                        <Grid container direction="row" justify="center" alignContent="flex-start">
                            <Grid item xl={6} lg={6} md={6} sm={12} xs={12} className="user-name">
                                <InputBox
                                    type={"text"}
                                    title={appLabels.GLOBAL_FIRST_NAME}
                                    placeholder={appLabels.FIRST_NAME_PLACEHOLDER}
                                    inputProps={{
                                        disabled: loading,
                                        name: "firstName",
                                        value: firstNameInputProps.value,
                                        onChange: (e: any) => handleChange(e),
                                        onBlur: formik.handleBlur,
                                    }}
                                    error={
                                        formik.touched.firstName && formik.errors.firstName
                                            ? formik.errors.firstName
                                            : null
                                    }
                                />
                            </Grid>
                            <Grid item xl={6} lg={6} md={6} sm={12} xs={12} className="user-name">
                                <InputBox
                                    type={"text"}
                                    title={appLabels.GLOBAL_LAST_NAME}
                                    placeholder={appLabels.LAST_NAME_PLACEHOLDER}
                                    inputProps={{
                                        name: "lastName",
                                        disabled: loading,
                                        value: lastNameInputProps.value,
                                        onChange: (e: any) => handleChange(e),
                                        onBlur: formik.handleBlur,
                                    }}
                                    error={
                                        formik.touched.lastName && formik.errors.lastName
                                            ? formik.errors.lastName
                                            : null
                                    }
                                />
                            </Grid>
                            <Grid item xl={6} lg={6} md={6} sm={12} xs={12} >
                                <SimpleDropdown
                                    isDisabled={false}
                                    title={appLabels.COUNTRY}
                                    placeholder={"A"}
                                    optionsList={countriesList}
                                    fieldDetails={timeZoneCountryProps}
                                    setProps={settimeZoneCountryProps}
                                    isShowError={false}
                                />
                            </Grid>
                            <Grid item xl={6} lg={6} md={6} sm={12} xs={12} >
                                <SimpleDropdown
                                    isDisabled={loading}
                                    title={appLabels.GLOBAL_TIMEZONE}
                                    placeholder={appLabels.GLOBAL_TIMEZONE}
                                    optionsList={allTimeZones}
                                    fieldDetails={timeZoneInputProps}
                                    setProps={settimeZoneInputProps}
                                    isShowError={isShowZoneError}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                            <div className="phone-number-wrapper">
                                <PhoneNumberInput
                                    className="phone-number"
                                    defaultCountry={'us'}
                                    onChange={handlePhoneNumber}
                                    preferredCountries={['us']}
                                    disableAreaCodes={true}
                                    countryCodeEditable={true}
                                    title="Phone Number"
                                    variant="outlined"
                                    inputProps={{
                                        style: {
                                            "height": "30px"
                                        }
                                    }}
                                    value={userData?.phoneNumber?.countryCode + userData?.phoneNumber?.number}
                                    errorMessage={phoneNumberError}
                                />
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container direction="row" justify="space-between" alignItems="flex-start" className="education-wrapper">
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <div className="onboarding-subheader">
                            <div className="heading">Select the option that best describes you:</div>
                        </div>
                    </Grid>
                    <Grid item xl={4} lg={4} md={4} sm={12} xs={12} >
                        <div className="education-dropdown-grid">
                            <SimpleDropdown
                                title="Education Level"
                                placeholder="Select Education Level"
                                optionsList={educationList.filter((e: any) => e.id !== "ALL")}
                                fieldDetails={educationLevel}
                                setProps={setEducationLevel}
                                isShowError={isShowError}
                            />
                        </div>
                    </Grid>
                </Grid>
                <Grid container direction="row" alignItems="baseline">
                    {grade.map((option: any, index: any) => (

                        <GradeItem
                            index={index}
                            handleFormError={handleFormError}
                            setHandleFormError={setHandleFormError}
                            deleteAction={() => deleteOption(index)}
                        />
                    ))}
                </Grid>
                {grade.length < 4 && (
                    <Grid className="plus_button" container>
                        <Grid item lg={2}>
                            <div onClick={addOption}>
                                <img src={PlusButton} alt="" />
                                <span>Add more</span>
                            </div>
                        </Grid>
                    </Grid>
                )}
                <Grid container direction="row" justify="space-between" alignItems="flex-start">
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <hr className="divider m-space" />
                    </Grid>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <OnBoardingHeader
                            withMargin
                            heading={"Licenses and Certifications"}
                            subHeading="The Classroom Door requires College Advisors and Educational Consultants to meet certain criteria."
                            link="Learn more"
                            anchor="https://theclassroomdoor.com/find-an-academic-advisor/"
                        />
                    </Grid>
                    <Grid item className="tutor-license-inputs" xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Grid container>
                            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                <InputBox
                                    type={"text"}
                                    title={educationLabel.EDUCATION_CERTIFICATION}
                                    placeholder={educationLabel.EDUCATION_CERTIFICATION_PLACEHOLDER}
                                    inputProps={{
                                        name: "certification",
                                        value: formik.values.certification,
                                        onChange: handleChange,
                                        onBlur: formik.handleBlur,
                                    }}
                                    error={
                                        formik.touched.certification && formik.errors.certification
                                            ? formik.errors.certification
                                            : null
                                    }
                                />
                            </Grid>
                            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                <InputBox
                                    type={"text"}
                                    title={educationLabel.EDUCATION_ISSUING}
                                    placeholder={educationLabel.EDUCATION_ISSUING_PLACEHOLDER}
                                    inputProps={{
                                        name: "issuing",
                                        value: formik.values.issuing,
                                        onChange: handleChange,
                                        onBlur: formik.handleBlur,
                                    }}
                                    error={
                                        formik.touched.issuing && formik.errors.issuing
                                            ? formik.errors.issuing
                                            : null
                                    }
                                />
                            </Grid>
                            <Grid item xl={6} lg={6} md={6} sm={12} xs={12} className="datepicker-wrapper p-15">
                                <label>{educationLabel.EDUCATION_ISSUING_DATE}</label>
                                <DatePicker
                                    format={DateFormats.EUA_DATE}
                                    autoOk
                                    clearable
                                    inputVariant="standard"
                                    variant="dialog"
                                    className="datepicker-root"
                                    value={formik.values.issuingDate}
                                    InputProps={{
                                        fullWidth: true,
                                        label: educationLabel.EDUCATION_ISSUING_DATE,
                                        placeholder: educationLabel.EDUCATION_ISSUING_DATE_PLACEHOLDER,
                                        value: formik.values.issuingDate,
                                        classes: { root: "mui-datepicker-input" },
                                        disableUnderline: true,
                                        onBlur: formik.handleBlur,
                                    }}
                                    maxDate={
                                        tutorEducationInfo.expirationDate
                                            ? tutorEducationInfo.expirationDate
                                            : new Date()
                                    }
                                    InputLabelProps={{
                                        disableAnimation: true,
                                        shrink: false,
                                    }}
                                    helperText={
                                        formik.touched.issuingDate &&
                                        formik.errors.issuingDate &&
                                        educationErrorLabel.GLOBAL_ISSUING_DATE_INVALID
                                    }
                                    onChange={(e: any) => handleDateChange(e, "issuingDate")}
                                    error={
                                        formik.touched.issuingDate && formik.errors.issuingDate
                                            ? true
                                            : undefined
                                    }
                                />
                            </Grid>
                            <Grid item xl={6} lg={6} md={6} sm={12} xs={12} className="datepicker-wrapper p-15">
                                <label>{educationLabel.EDUCATION_EXPIRATION_DATE}</label>
                                <DatePicker
                                    format={DateFormats.EUA_DATE}
                                    autoOk
                                    clearable
                                    inputVariant="standard"
                                    variant="dialog"
                                    className="datepicker-root"
                                    value={formik.values.expirationDate}
                                    disabled={!tutorEducationInfo.issuingDate}
                                    InputProps={{
                                        fullWidth: true,
                                        label: educationLabel.EDUCATION_EXPIRATION_DATE,
                                        placeholder: educationLabel.EDUCATION_EXPIRATION_DATE_PLACEHOLDER,
                                        value: formik.values.expirationDate,
                                        classes: { root: "mui-datepicker-input" },
                                        disableUnderline: true,
                                        onBlur: formik.handleBlur,
                                    }}
                                    minDate={tutorEducationInfo.issuingDate}
                                    InputLabelProps={{
                                        disableAnimation: true,
                                        shrink: false,
                                    }}
                                    helperText={
                                        formik.touched.expirationDate &&
                                        formik.errors.expirationDate &&
                                        educationErrorLabel.GLOBAL_EXPIRATION_DATE_INVALID
                                    }
                                    onChange={(e: any) => handleDateChange(e, "expirationDate")}
                                    error={
                                        formik.touched.expirationDate && formik.errors.expirationDate
                                            ? true
                                            : undefined
                                    }
                                />
                            </Grid>
                            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                <InputBox
                                    type={"text"}
                                    title={educationLabel.EDUCATION_CREDENTIAL_ID}
                                    placeholder={educationLabel.EDUCATION_CREDENTIAL_ID_PLACEHOLDER}
                                    inputProps={{
                                        name: "credentialId",
                                        value: formik.values.credentialId,
                                        onChange: handleChange,
                                        onBlur: formik.handleBlur,
                                    }}
                                    error={
                                        formik.touched.credentialId && formik.errors.credentialId
                                            ? formik.errors.credentialId
                                            : null
                                    }
                                />
                            </Grid>
                            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                <InputBox
                                    type={"text"}
                                    title={educationLabel.EDUCATION_CREDENTIAL_URL}
                                    placeholder={educationLabel.EDUCATION_CREDENTIAL_URL_PLACEHOLDER}
                                    inputProps={{
                                        name: "credentialUrl",
                                        value: formik.values.credentialUrl,
                                        onChange: handleChange,
                                        onBlur: formik.handleBlur,
                                    }}
                                    error={
                                        formik.touched.credentialUrl && formik.errors.credentialUrl
                                            ? formik.errors.credentialUrl
                                            : null
                                    }
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <hr className="divider" />
                <Grid container>
                    <Grid item xs={12}>
                        <div className="wrap file-wrapper">
                            <label className="input-label" style={{ marginLeft: "-15px" }}>Upload Resume</label>
                            <label
                                className={`form-file-picker ${errorFile && "error-input-file"}`}
                                htmlFor="upload_button"
                            >
                                {loading ? (
                                    <BeatLoader color="gray" />
                                ) : (
                                    <span>Add file max 10 MB: .doc, .docx, .pdf, .jpg, .png</span>
                                )}

                                <input
                                    type="file"
                                    id="upload_button"
                                    accept=".doc,.docx,.pdf,.jpg,.png"
                                    value=""
                                    onChange={(e: any) => handleResumeFile(e.target.files[0])}
                                />
                            </label>
                            {tutorEducationInfo.teacherResume && (
                                <div className="info-file">
                                    <span>
                                        {tutorEducationInfo.teacherResume.name
                                            ? tutorEducationInfo.teacherResume.name
                                            : "Resume File"}
                                    </span>
                                    <span
                                        className="navigation"
                                        onClick={() => handleResumeFile(null)}
                                    >
                                        Delete File
                                    </span>
                                </div>
                            )}
                        </div>
                    </Grid>
                </Grid>
                <Grid container>
                    <hr className="divider" />
                    <Grid item xs={12} className="Bio">
                        <InputBox
                            type={"textarea"}
                            title={educationLabel.EDUCATION_BIOGRAPHY}
                            placeholder={educationLabel.EDUCATION_BIOGRAPHY_PLACEHOLDER}
                            inputProps={{
                                name: "biography",
                                value: formik.values.biography,
                                onChange: handleChange,
                                onBlur: formik.handleBlur,
                            }}
                            error={
                                formik.touched.biography && formik.errors.biography
                                    ? formik.errors.biography
                                    : null
                            }
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={5} className="subjects-and-tests">
                    <hr className="divider" />
                    {teacherType === teacherTypeKeys.ADVISING &&
                        <TutorAdvisorAdvisingSpeciality />
                    }
                    {teacherType === teacherTypeKeys.TUTORING && (
                        <>
                            <TutorAdvisorSubjects />
                            <TutorAdvisorTests />
                        </>)
                    }
                    {teacherType === teacherTypeKeys.TUTORING_AND_ADVISING && (
                        <>
                            <TutorAdvisorSubjects />
                            <TutorAdvisorTests />
                            <TutorAdvisorAdvisingSpeciality />
                        </>
                    )}
                </Grid>
                <Grid container className="actions-profile">
                    <div className="subscription-optins">
                        <SMSOptIn userId={userData.userId} setNotificationStatus={setNotificationStatus} />
                    </div>
                    <Button
                        isDisabled={loading}
                        isloading={loading}
                        title={"Save Profile"}
                        clickAction={(): Promise<boolean> => navigateNextPage(formik)}
                    />
                </Grid>
            </form>
            <Modal
                title={modalInfo.title}
                visible={modalInfo.show}
                icon={modalInfo.icon}
                subtitle={modalInfo.text}
                onExit={onExitModal}
            />

        </>

    )

}
export default TutorProfileForm