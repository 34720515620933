import React from "react"
import interfaceConstant from "../../../utils/constant/interfaceConstant"

class MaleGenderSvgIcon extends React.PureComponent<interfaceConstant.SvgIconProps> {
    render(): JSX.Element {
        const { width, height, ...otherProps } = this.props
        return (
            <svg width={width} height={height} viewBox="0 0 32 32" version="1.1" {...otherProps}>
                <g
                    id="Icons-/-Thin-/-Thi-15"
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                >
                    <path
                        d="M7,6 L14.0945055,7.11894273 L13.821978,8.88105727 L10.5868132,8.37004405 L14.756,12.514 L14.9455807,12.3687916 C17.8152635,10.230942 21.8658525,10.6316329 24.2582354,13.330712 C26.7075799,16.0940549 26.5562102,20.2933465 23.9142622,22.8732003 C21.3473379,25.5454142 17.131074,25.7223396 14.3492619,23.2745728 C11.5735049,20.8321338 11.2084745,16.6409468 13.5161648,13.7559096 L9.36483516,9.59471366 L9.87472527,12.8370044 L8.11648352,13.1101322 L7,6 Z"
                        id="Combined-Shape"
                        fill="#686C80"
                        transform="translate(16.500000, 15.500000) scale(-1, 1) translate(-16.500000, -15.500000) "
                    ></path>
                </g>
            </svg>
        )
    }
}

export default MaleGenderSvgIcon
