import React from "react";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useMemo } from "react"
import { useModal } from "react-modal-hook";
import { useMutation, useQuery } from "react-query";
import { createStripeConnectedAccount, getStripeAccountLinkUrl, getStripeConnectedAccount } from "../services/paymentServices";
import { StripeConnectCountrySelection } from "components/modal_/stripe-connect-country/StripeConnectCountry";


const useStripeConnectedAccount = () => {
    const { isLoading: isLinkLoading, error: linkError, data: accountLink, refetch: refetchAccountLink } = useQuery('stripe-connected-account-link', getStripeAccountLinkUrl, {
        refetchOnWindowFocus: false
    }) as any;
    const { isLoading: isAccountLoading, error: accountError, data: account, refetch: refetchAccount } = useQuery('stripe-connected-account', getStripeConnectedAccount, {
        refetchOnWindowFocus: false
    }) as any;
    const { mutate: createAccount, error: accountCreationError, isLoading: accountCreationLoading } = useMutation(createStripeConnectedAccount, {
        onSuccess: () => {
            refetchAccount();
            refetchAccountLink();
        }
    }) as any;
    const { enqueueSnackbar } = useSnackbar();
    const [showCountryModal, hideCountryModal] = useModal(() => {
        return (
            <StripeConnectCountrySelection isShown={true} onGoto={(country) => {
                createAccount(country.id);
                hideCountryModal();
            }} onClose={hideCountryModal} />
        );
    }, []);

    const accountType: "standard" | "express" | "custom" = account?.type || "express";

    const payoutsEnabled = useMemo(() => {
        return !!account?.payouts_enabled;
    }, [account]);
    const chargesEnabled = useMemo(() => {
        return !!account?.charges_enabled;
    }, [account]);
    const externalAccounts = useMemo(() => {
        return account?.external_accounts?.data || [];
    }, [account?.external_accounts]);

    const alertData: {
        message: string,
        type: "warning" | "success" | "error",
        actions: {
            title: string,
            action: () => void
        }[]
    } | undefined = useMemo(() => {
        if (!account?.id) {
            return {
                message: `You have not set up your Stripe payment information. Please do so immediately to ensure timely payments to your account.`,
                type: 'error',
                actions: []
            }
        }
        if (!account.external_accounts?.total_count) {
            return {
                message: `Please attach an external account (bank/card) to your payout account so you can receive payments.`,
                type: 'error',
                actions: []
            }
        }
        if (account?.requirements?.currently_due?.length > 0) {
            return {
                message: `There are some requirements to verify. Please, verify them to avoid any issue in your payouts`,
                type: 'error',
                actions: [
                    {
                        title: "Verify Now",
                        action: () => {
                            accountLink?.accountURL && window.open(accountLink.accountURL, "_self")
                        }
                    }
                ]
            }
        }
    }, [account, accountLink]);

    useEffect(() => {
        if (linkError?.message && linkError?.code !== "NOT_FOUND") {
            enqueueSnackbar(linkError?.message || accountError?.message, { variant: "error" });
        } else if (accountError?.message && accountError?.code !== "NOT_FOUND") {
            enqueueSnackbar(accountError?.message, { variant: "error" });
        } else if (accountCreationError) {
            enqueueSnackbar(accountCreationError?.message, { variant: "error" });
        }
    }, [linkError?.message, accountError?.message, accountCreationError?.message]);

    return {
        isLoading: isLinkLoading || isAccountLoading || accountCreationLoading,
        accountType,
        accountLink: accountLink?.accountURL,
        dashboardLink: accountLink?.dashboardURL || undefined,
        connectedAccount: account,
        payoutsEnabled,
        chargesEnabled,
        alertData,
        externalAccounts,
        createConnectedAccount: useCallback(() => {
            if (!account || !account.id) {
                showCountryModal(); //first get country and then create
            }
        }, [account, createAccount])
    }

}

export default useStripeConnectedAccount
