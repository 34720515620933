/* eslint-disable no-useless-escape */

import interfaceConstant from "./interfaceConstant"

export const appLabels: interfaceConstant.IappLabels = {
    ADDRESS_LINE_1: "Address Line 1",
    ADDRESS_LINE_1_PLACEHOLDER: "Enter Address Line 1",
    ADDRESS_LINE_2: "Address Line 2",
    ADDRESS_LINE_2_PLACEHOLDER: "Enter Address Line 2",
    ADDRESS_CITY: "City",
    ADDRESS_CITY_PLACEHOLDER: "Enter City",
    ADDRESS_STATE: "State",
    ADDRESS_STATE_PLACEHOLDER: "Enter State",
    ADDRESS_COUNTRY: "Country",
    ADDRESS_COUNTRY_PLACEHOLDER: "Enter Country",
    ADDRESS_POSTAL_CODE: "Postal Code",
    ADDRESS_POSTAL_CODE_PLACEHOLDER: "Enter Postal Code",
    PAYMENTS_SSN: "SSN",
    PAYMENTS_SSN_PLACEHOLDER: "Enter SSN",
    GLOBAL_EMAIL: "Email",
    GLOBAL_FIRST_NAME: "First Name",
    GLOBAL_LAST_NAME: "Last Name",
    GLOBAL_BIRTH_DATE: "Date of Birth",
    GLOBAL_CURRENT_SCHOOL: "Current school",
    GLOBAL_GENDER: "Gender",
    GLOBAL_TIMEZONE: "Time zone",
    COUNTRY: "Country",
    GLOBAL_PASSWORD: "Password",
    GLOBAL_BIOGRAPHY: "About me",
    EMAIL_PLACEHOLDER: "Insert email",
    PASSWORD_PLACEHOLDER: "Insert password",
    GLOBAL_BIOGRAPHY_PLACEHOLDER: "Tell us more about yourself.",
    GLOBAL_GOAL: "My goal",
    GOAL_PLACEHOLDER: "Insert Goal",
    GLOBAL_OPTIONAL_GRADE_LEVEL: "Grade level (optional)",
    OPTIONAL_GRADE_LEVEL_PLACEHOLDER: "Select Grade",
    FIRST_NAME_PLACEHOLDER: "Insert first name",
    LAST_NAME_PLACEHOLDER: "Insert last name",
    CURRENT_SCHOOL_PLACEHOLDER: "Insert Current School",
    BIRTH_DATE_PLACEHOLDER: "Insert Date of Birth",
    GENDER_PLACEHOLDER: "Select gender",
    TIMEZONE_PLACEHOLDER: "Select Timezone",
    SIGN_UP_TERMS: "<p>I Accept the <a>TCD Terms and Conditions</a>.</p>",
    SIGN_UP_TITLE: "Almost Done!",
    SIGN_UP_SUB_TITLE: "Let’s create your account and start maximizing your full potential.",
    SIGN_UP_WITH_OTHER_TITLE: "Register with",
    SIGN_UP_SUCCESS_MODAL_TITLE: "Your account is almost set up!",
    SIGN_UP_SUCCESS_MODAL_SUB_TITLE: "Please check your email to verify your account",
    NEW_PASSWORD: "New Password",
    CONFIRM_NEW_PASSWORD: "Confirm New Password",
    NEW_PASSWORD_PLACEHOLDER: "New Password",
    CONFIRM_NEW_PASSWORD_PLACEHOLDER: "Confirm New Password",
    GLOBAL_NAVIGATION_DETAILS_SIGN_UP: "Don’t have an account? ",
    GLOBAL_NAVIGATION_LINK__SIGN_UP: "/my-role",
    GLOBAL_NAVIGATION_TEXT__SIGN_UP: "Join us",
    GLOBAL_APP_TITLE: "The Classroom Door",
    CHANGE_PASSWORD_PAGE_TITLE: "Change Password",
    RESET_PASSWORD_PAGE_TITLE: "Reset Password",
    EDUCATION_CERTIFICATION: "Certification Name",
    EDUCATION_CERTIFICATION_PLACEHOLDER: "Insert Certification Name",
    EDUCATION_ISSUING: "Issuing Organization",
    EDUCATION_ISSUING_PLACEHOLDER: "Insert Issuing Organization",
    EDUCATION_ISSUING_DATE: "Issuing Date",
    EDUCATION_ISSUING_DATE_PLACEHOLDER: "Insert Issuing Date",
    EDUCATION_EXPIRATION_DATE: "Expiration Date",
    EDUCATION_EXPIRATION_DATE_PLACEHOLDER: "Insert Expiration Date",
    EDUCATION_CREDENTIAL_ID: "Credential ID",
    EDUCATION_CREDENTIAL_ID_PLACEHOLDER: "Insert Credential ID",
    EDUCATION_CREDENTIAL_URL: "Credential URL",
    EDUCATION_CREDENTIAL_URL_PLACEHOLDER: "Insert Credential URL",
    EDUCATION_BIOGRAPHY: "Biography",
    EDUCATION_BIOGRAPHY_PLACEHOLDER:
        "Tell us more about yourself. Please be sure to include details of all relevant experience(s).",
    GLOBAL_EARLY_MORNING_SLOT: "Early Morning 12:00 AM - 6:00 AM",
    GLOBAL_MORNING_SLOT: "Morning 6:00 AM - 12:00 PM",
    GLOBAL_AFTERNOON_SLOT: "Afternoon 12:00 PM - 6:00 PM",
    GLOBAL_EVENING_SLOT: "Night 6:00 PM - 12:00 AM",
    GLOBAL_UPDATE_SETTING_NOTE: "You can always update these settings later.",
    GLOBAL_BANK_LOCATION: "Bank Location",
    BANK_LOCATION_PLACEHOLDER: "United States",
    GLOBAL_BANK_CURRENCY: "Bank Currency",
    BANK_CURRENCY_PLACEHOLDER: "US Dollars",
    GLOBAL_BANK_NAME: "Bank Name",
    BANK_NAME_PLACEHOLDER: "Insert Bank Name",
    GLOBAL_HOLDER_NAME: "Account Holder Name",
    HOLDER_NAME_PLACEHOLDER: "Insert Account Holder Name",
    GLOBAL_ACCOUNT_TYPE: "Account Type",
    ACCOUNT_TYPE_PLACEHOLDER: "Choose a Account Type",
    GLOBAL_ACCOUNT_NUMBER: "Account Number",
    ACCOUNT_NUMBER_PLACEHOLDER: "Insert Account Number",
    GLOBAL_CONFIRM_ACCOUNT_NUMBER: "Confirm Account Number",
    ACCOUNT_CONFIRM_NUMBER_PLACEHOLDER: "Confirm Account Number",
    GLOBAL_ROUTING_NUMBER: "Routing Number (ABA)",
    ROUTING_NUMBER_PLACEHOLDER: "Insert Routing Number",
    HOLDER_CARD_TOOLTIP:
        "Enter the account holder name exactly as it appears on your statements to avoid processing delays.",
    ROUTING_NUMBER_TOOLTIP:
        "Bank routing numbers are nine digits long. You can find your bank routing number within the string of numbers located at the bottom of one of your checks.",
}
export const appErrorLabels: interfaceConstant.IappErrorLabels = {
    GLOBAL_ACCOUNT_NUMBER: "Account number must be only numbers",
    GLOBAL_SSN: "SSN must be only numbers",
    GLOBAL_ACCOUNT_NOT_MATCH: "Account number does not match",
    GLOBAL_MIN_LENGTH_BIOGRAPHY: "Biography must have at least 100 chars",
    GLOBAL_MAX_50: "50 characters maximum",
    GLOBAL_MIN_100: "100 characters minimum",
    GLOBAL_MAX_100: "100 characters maximum",
    GLOBAL_MAX_1000: "1000 characters maximum",
    GLOBAL_MAX_25: "25 characters maximum",
    GLOBAL_EXACTLY_9_NUMBERS: "Must be exactly 9 numbers",
    GLOBAL_EXACTLY_12_NUMBERS: "Must be exactly 12 numbers",
    GLOBAL_REQUIRED: "Required",
    GLOBAL_NAME_INVALID: "Name is Invalid",
    GLOBAL_ONLY_DIGITS: "Only digits are allowed",
    GLOBAL_FIRST_NAME_FORMAT: "First letter should be capital and Spaces are not allowed",
    GLOBAL_LAST_NAME_FORMAT: "First letter should be capital",
    GLOBAL_EMAIL_INVALID: "Email is Invalid",
    GLOBAL_URL_INVALID: "Should be a valid URL",
    GLOBAL_PHONENUMBER_INVALID: "Invalid Phone Number",
    GLOBAL_PHONENUMBER_FORMAT_INVALID: "Phone number must contain exactly 10 digits",
    GLOBAL_PASSWORD_REGEX_ERROR: "Password should have at least 6 characters and max 64",
    GLOBAL_DROPDOWN_ERROR: "Select at least one option",
    GLOBAL_SELECT_ACTIVITY_ERROR: "Please select a activity or subject",
    GLOBAL_PASSWORD_MATCH_ERROR: "Passwords must match",
    GLOBAL_ISSUING_DATE_INVALID: "Invalid Date",
    GLOBAL_EXPIRATION_DATE_INVALID: "Invalid Date",
    GLOBAL_ROUTING_NUMBERS: "Account Number should be minimum 2 digits and only numbers",
    GLOBAL_CARD_HOLDER_NAME_INVALID: "Invalid holder name",
    GLOBAL_CARD_NUMBER_INVALID: "Invalid card number",
    GLOBAL_CVV_INVALID: "Invalid CVV number",
    GLOBAL_EXPIRY_DATE_INVALID: "Invalid expiry date",
    GLOBAL_CONFIRM_EMAIL_INVALID: "Email doesn't match with the email above",
}

const resetPasswordLabel: interfaceConstant.IresetPasswordLabel = {
    NAVIGATIONDETAILS: "Return to ",
    NAVIGATIONLINK: "/sign-in",
    NAVIGATIONTEXT: "Sign in",
    DESCRIPTION: "Forgot your password? Don’t worry, you can reset it now.",
}
export const regexExpression: interfaceConstant.IregexExpression = {
    NAMES_VALIDATION: /^[_A-zA-Z]*((-|\s)*[_A-zA-Z])*$/,
    EMAIL_VALIDATION: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    NUMBER_VALIDATION: /^(0|[1-9][0-9]*)$/,
    PHONENUMBER_VALIDATION: /^[0-9]*$/,
    PHONENUMBER_VALIDATION_LENGTH: /^[0-9]{10}$/,
    ROUTING_NUMBER_VALIDATION: /^[0-9]{9}$/,
    SSN_VALIDATION: /^[0-9]{9}$/,
    CREDIT_CARD_VALIDATION: /^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/,
    EXPIRY_DATE_VALIDATION: /^(0[1-9]|1[0-2]|[1-9])\/(1[4-9]|[2-9][0-9]|20[2-9][0-9])$/,
    CURRENCY_VALIDATION: /^\d{1,3}$|^\d{1,3}\.\d{1,2}$/,
}
const routeUrl: interfaceConstant.IrouteUrl = {
    LOOKING_FOR_PAGE: "/looking-for",
    TUTOR_AVAILABILITY_PAGE: "/tutor-availability",
    TUTOR_ADVISOR_APPLICATION_PAGE: "/tutor-advisor-application",
    TUTOR_EDUCATION_PAGE: "/tutor-advisor-education",
    TUTOR_RATES_PAGE: "/tutor-cancellation-policy",
    TUTOR_MY_PROFILE_PAGE: "/tutor-myprofile",
    TUTOR_REVIEW_PAGE: "/tutor-review",
    MY_ROLE: "/my-role",
    BOOK_SESSION_PAGE: "/panel/search/book-session",
    RESUME_AND_CONFIRM_PAGE: "/panel/search/resume-and-confirm",
    SESSION_CONFIRMED_PAGE: "/panel/search/booking-confirmed",

    ROOMS_PAGE: "/epic-board-rooms",
    PANEL_ROUTE: "/panel",
    ADMIN_PANEL_ROUTE: "/admin/panel",
    DASHBOARD_PAGE: "/dashboard",
    SEARCH_OPEN_PAGE: "/search",
    SEARCH_PAGE: "/search",
    SESSION_PAGE: "/session",
    ROOM_PAGE: "/room",
    FOLDER_PAGE: "/folder",
    MESSAGE_PAGE: "/message",
    ACCOUNT_PAGE: "/account",
    HELP_PAGE: "/help",
    EPIC_BOARD_PAGE: "/epic-board",
    FORGOT_PASSWORD_PAGE: "/forgot-password",
    SIGN_UP_PAGE: "/sign-up",
    SIGN_IN_PAGE: "/sign-in",
    RESET_PASSWORD_PAGE: "/reset-password",
    LOOKING_FOR_TUTORING_PAGE: "/looking-for-tutoring",
    LOOKING_FOR_ADVISING_PAGE: "/looking-for-advising",
    LOOKING_FOR_TUTORING_AND_ADVISING_SUBJECTS_PAGE: "/looking-for-tutoring-and-advising-subjects",
    LOOKING_FOR_TUTORING_BACKGROUND_PAGE: "/looking-for-tutoring-background",
    LOOKING_FOR_ADVISING_BACKGROUND_PAGE: "/looking-for-advising-background",
    LOOKING_FOR_TUTORING_AND_ADVISING_BACKGROUND_PAGE:
        "/looking-for-tutoring-and-advising-background",
    TUTOR_PROFILE_PAGE: "/tutor-profile",
    USER_PROFILE_PAGE: "/user-profile",
    LESSONS_PAGE: "/lessons",
}
const teacherTypes: interfaceConstant.IteacherTypes = {
    TUTORING: "Tutoring",
    ADVISING: "Advising",
    TUTORING_AND_ADVISING: "Tutoring & Advising",
}

const teacherTypeKeys: interfaceConstant.IteacherTypes = {
    TUTORING: "TUTORING",
    ADVISING: "ADVISING",
    TUTORING_AND_ADVISING: "TUTORING_AND_ADVISING",
}
const collegeCounsellingKeys: interfaceConstant.ICounselingTypes = {
    COLLEGE_COUNSELING: "COLLEGE_COUNSELING"
}
const userTypeKeys: interfaceConstant.IuserTypes = {
    STUDENT: "STUDENT",
    TEACHER: "TEACHER",
    ADMIN: "ADMIN",
}

const tutorSearch: interfaceConstant.ItutorSearchLabel = {
    HEADING: "Book sessions with",
    SUB_HEADING: "",
    ERROR: "Please select at least one slot",
    DESCRIPTION: "You can always update these settings later.",
}
const tutorAdvisorLabel = {
    HEADING: "Select Your Preferred Field",
    SUB_HEADING: "Tutor and Advisor Application",
    SUBJECT_DROPDOWN_TITLE: "Choose Your Subjects",
    SUBJECT_DROPDOWN_PLACEHOLDER: "Subjects",
    TEST_DROPDOWN_TITLE: "Choose Your Standardized Test Subjects",
    TEST_DROPDOWN_PLACEHOLDER: "Standardized Tests",
    SPECIALTIES_DROPDOWN_TITLE: "Choose Your Advising Speciality",
    SPECIALTIES_DROPDOWN_PLACEHOLDER: "Specialities",
}
const tutorAvailabilityLable: interfaceConstant.ItutorAvailabilityLable = {
    HEADING: "Select Your Available Hours",
    SUB_HEADING: "Availability",
    DESCRIPTION: "You can always update these settings later.",
    ERROR: "Please select at least one slot",
}

const tutormyProfileLabel: interfaceConstant.ItutorProfileLabel = {
    HEADING: "My Profile",
    SUB_HEADING: "Choose One of the Following Registration Methods",
}
const tutorReviewLabel: interfaceConstant.ItutorReviewLabel = {
    DEFAULT_PLACEHOLDER: "Select all that apply",
    ACCEPTED_TERMS: "I agree to the TCD Rules, Cancelation Policy, and Refund Policy.",
    CERTIFY_TERMS:
        "I certify that the information that I am submitting is 100 percent accurate and I understand that my account may be subject to termination in the event that the above statements are found to be exaggerated or falsified.",
    ACCEPTED_TERMS_ERROR: "You must agree to terms and conditions",
    CERTIFY_TERMS_ERROR: "You should consent that all the details and documents are true",
}

const cancelPolicyLabel: interfaceConstant.IcancelPolicyLabel = {
    RATE_CONTENT_TITLE: "Rates",
    CANCELLATION_POLICY_CONTENT_TITLE: "Cancelation policy",
    NOTE_FOR_SESSION: "Please note that TCD will receive 25% of your fee for each session.",
    SUB_NOTE_FOR_SESSION: "Undergraduates are able to charge a maximum of $35 per hour",
    INDIVIDUAL_RATE_SESSION_DROPDOWN_TITLE: "Individual Session Rate (Per Hour)",
    GROUP_RATE_SESSION_DROPDOWN_TITLE: "Group Session Rate (Per Hour)",
    INDIVIDUAL_GROUP_DROPDOWN_PLACEHOLDER: "Type rate",
    DESCRIPTION_PART_ONE: "If a user cancels less than",
    DESCRIPTION_PART_SECOND: "hours before a scheduled session, I will charge a $",
    DESCRIPTION_PART_THIRD: "cancelation fee. If a session is scheduled less than",
    DESCRIPTION_PART_FORTH: "hours in advance and is canceled less than",
    DESCRIPTION_PART_FIFTH: "hours in advance, I will charge a cancelation fee of $",
    GLOBAL_REQUIRED: "Required",
}

const dashboardLabel: interfaceConstant.IdashboardLabel = {
    SESSION_TAB_HEADING: "Upcoming Sessions",
    TUTOR_TAB_HEADING: "My Tutors",
    ADVISOR_TAB_HEADING: "My Advisors",
    NO_TUTORS_CARD_TITLE: "You have no tutors... yet!",
    NO_TUTORS_CARD_SUBTITLE: "But we have some suggestions for you",
    NO_TUTORS_CARD_SUBTITLE_2: "You have no upcoming sessions yet! Try to book a new session",
    NO_TUTORS_CARD_BUTTON_TEXT: "Book Now",
    NO_ADVISORS_CARD_TITLE: "You have no advisors... yet!",
    NO_SESSIONS_CARD_TITLE: "No Pending Actions",
    NO_UPCOMING_CARD_TITLE: "Upcoming Sessions",
    NO_STUDENTS_CARD_TITLE: "You have no students... yet!",
    NO_STUDENTS_CARD_SUBTITLE: "lorem ipsum dolor sit amet lorem lorem",
}

const bookingLabel: interfaceConstant.IbookingLabel = {
    SESSION_LENGTH_INITIAL_VALUE: 15,
    SESSION_LENGTH_STEP_VALUE: 15,
    BUFFER_HOURS_FROM_NOW: 1,
    BUFFER_MINUTES_FROM_NOW: 1,
    EARLY_MORNING_LABEL: "Early Morning",
    EARLY_MORNING_SUBLABEL: "12:00 AM - 6:00 AM",
    EARLY_MORNING_VALUE: "EARLY_MORNING",
    MORNING_LABEL: "Morning",
    MORNING_SUBLABEL: "6:00 AM - 12:00 PM",
    MORNING_VALUE: "MORNING",
    AFTERNOON_LABEL: "Afternoon",
    AFTERNOON_SUBLABEL: "12:00 PM - 6:00 PM",
    AFTERNOON_VALUE: "AFTERNOON",
    EVENING_LABEL: "Night",
    EVENING_SUBLABEL: "6:00 PM - 12:00 AM",
    EVENING_VALUE: "NIGHT",
    CONTACT_TITLE: "How Can Your Tutor / Advisor Help?",
    CONTACT_SUBTITLE: "Please include what you are planning to work on during the session(s).",
    CONTACT_PLACEHOLDER: "Hello John, I cannot wait for our lesson.",
    CONTACT_BUTTON: "Book Session(s)",
    CONTACT_NOTE: "You will not be charged yet",
    RESUME_HEADER_TUTOR: "Sessions Booked with",
    RESUME_SUBTOTAL: "Total (USD)",
    RESUME_CANCELATION_POLICY_TITLE: "Cancelation policy:",
    RESUME_CANCELATION_POLICY_DESCRIPTION:
        "Cancel up to 5 hours before the first session and receive a credit added to your account.",
    RESUME_TITLE_LEFT: "Confirmation",
    RESUME_TITLE_RIGHT: "Confirmation and payment",
    RESUME_PASSWORD_LABEL: "Enter your password",
    RESUME_PASSWORD_SUBLABEL: "Currently signed in as ",
    RESUME_PASSWORD_PLACEHOLDER: "Insert password",
    RESUME_PAGE_BUTTON: "Send Request",
    CONFIRMED_PAGE_TITLE: "Almost There!",
    CONFIRMED_PAGE_SUBTITLE: "Your sessions will be booked when your tutor confirms.",
    CONFIRMED_PAGE_BUTTON: "Edit request",
    CONFIRMED_PAGE_BUTTON2: "Go to Dashboard",
    CONFIRMED_PAGE_NAV1: "Visit help center",
    CONFIRMED_PAGE_NAV2: "Contact TCD",
}
const paymentLabel: interfaceConstant.IpaymentLabel = {
    CARD_HOLDER_NAME_LABEL: "Card holder name",
    CARD_HOLDER_NAME_PLACEHOLDER: "Maria Wilson",
    CARD_NUMBER_LABEL: "Card number",
    CARD_NUMBER_PLACEHOLDER: "0000 0000 0000 0000",
    CVV_LABEL: "CVV",
    CVV_PLACEHOLDER: "123",
    EXPIRY_DATE_LABEL: "Expiration date",
    EXPIRY_DATE_PLACEHOLDER: "03/2020",
    SAVE_CARD_LABEL: "Save this card",
}
const fontSizes: interfaceConstant.IfontSizes = {
    LIGHTER: "lighter"
}
export const applicationConstant = {
    appLabels,
    appErrorLabels,
    resetPasswordLabel,
    regexExpression,
    routeUrl,
    teacherTypes,
    teacherTypeKeys,
    userTypeKeys,
    tutormyProfileLabel,
    tutorAdvisorLabel,
    tutorAvailabilityLable,
    tutorReviewLabel,
    cancelPolicyLabel,
    tutorSearch,
    dashboardLabel,
    bookingLabel,
    paymentLabel,
    collegeCounsellingKeys,
    fontSizes,
}

export const allDayTime = '12 AM - 11:59 PM';
