import React, { useState } from "react"
import { Container, Row, Col } from "reactstrap"
import { currentGradeOptions } from "../../../utils/constant/currentGradeOptions"

import { yearList } from "../../../utils/constant/yearList"
import { school } from "../../../utils/constant/educationLists"
import SimpleDropdown from "../../../components/dropdown/SimpleDropdown"

interface Props {
    setyearInputProps: Function
    setGradeInputProps: Function
    gradeInputProps: any
    yearInputProps: any
}

const EducationalBackgroundForm = ({
    gradeInputProps,
    setGradeInputProps,
    yearInputProps,
    setyearInputProps,
}: Props): JSX.Element => {
    const [optionsYearList] = useState(yearList)

    return (
        <>
            <Row style={{marginTop: "50px"}}>
                <Col xs="12" sm="12" md="12" lg="8" xl="9">
                    <SimpleDropdown
                        title="Current Grade  (Optional)"
                        placeholder="Select grade"
                        optionsList={school}
                        fieldDetails={gradeInputProps}
                        setProps={setGradeInputProps}
                        isShowError={gradeInputProps.error}
                    />
                </Col>
                <Col xs="12" sm="12" md="12" lg="4" xl="3">
                    <SimpleDropdown
                        title="Graduation Year (Optional)"
                        placeholder="Select year"
                        optionsList={optionsYearList}
                        fieldDetails={yearInputProps}
                        setProps={setyearInputProps}
                        isShowError={yearInputProps.error}
                    />
                </Col>
            </Row>
        </>
    )
}

export default EducationalBackgroundForm
