
/**
 * 
 * @param rest 
 */
export const log = async (pointer: string, e: Error) => {
    const url: string | undefined = process.env.REACT_APP_CRASH_LOGGER;
    if (!url) {
        return;
    }
    const body = createChatBody(pointer, e);
    await fetch(url, {
        method: 'post',
        body: JSON.stringify(body),
        headers: { 'Content-Type': 'application/json' },
    }).then(res => res.json())
    .catch(e => console.error(`Error while send message to Google Chat: `, e));
}

function createChatBody(pointer: string, e: Error & { code?: any }) {
    return {
        cards: [
            {
                sections: [
                    {
                        widgets: [
                            {
                                keyValue: {
                                    topLabel: "Pointer",
                                    content: pointer,
                                    contentMultiline: true
                                }
                            }
                        ]
                    },
                    {
                        widgets: [
                            {
                                keyValue: {
                                    topLabel: "Error Message",
                                    content: e.message,
                                    contentMultiline: true
                                }
                            },
                            e.code && {
                                keyValue: {
                                    topLabel: "Error Code",
                                    content: e.code,
                                    contentMultiline: true
                                }
                            },
                            e.stack && {
                                keyValue: {
                                    topLabel: "Stack Trace",
                                    content: e.stack,
                                    contentMultiline: true
                                }
                            }
                        ],
                        collapsable: true
                    }
                ]
            }
        ]
    };
}