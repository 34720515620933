import React from "react"
import "./switch-component.scss"
import { withStyles, Theme, createStyles } from "@material-ui/core/styles"
import Switch from "@material-ui/core/Switch"
import colors from "../../assets/colors"

interface Props {
    label?: string
    active: boolean
    onTap: (active: boolean) => void
    style?: any | undefined
    disable?: boolean
    children?: any
    size?: "medium" | "small"
}

export const CustomSwitch = withStyles((theme: Theme) =>
    createStyles({
        root: {
            width: 68,
            height: 29,
            padding: 0,
            display: "flex",
        },
        switchBase: {
            padding: 2,
            color: theme.palette.common.white,
            "&$checked": {
                transform: "translateX(33px)",
                color: theme.palette.common.white,
                "& + $track": {
                    opacity: 1,
                    backgroundColor: colors.darkMango,
                    borderColor: theme.palette.primary.main,
                },
            },
        },
        thumb: {
            width: 21,
            height: 21,
            boxShadow: "none",
            marginTop: 2,
            marginLeft: 5
        },
        track: {
            border: `1px solid ${theme.palette.grey[500]}`,
            borderRadius: 29 / 2,
            opacity: 1,
            backgroundColor: colors.battleshipGrey,
        },
        checked: {},
    }),
)(Switch)

const LargeSwitchComponent = (props: Props): JSX.Element => {
    const { active, onTap, style, disable } = props
    const { label } = props

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        onTap(event.target.checked)
    }

    return (
        <div className="switch-component" style={style}>
            <CustomSwitch
                disabled={disable}
                checked={active}
                onChange={handleChange}
                name="customSwitch"
                size={props.size}
            />
            {label ? (
                <div style={{ marginLeft: "20px" }}>{label}</div>
            ) : null}
            {props.children}
        </div>
    )
}

export default LargeSwitchComponent
