import React, { useState, Fragment } from "react"
import { Container } from "reactstrap"
import { tutoring, counselling, tutoringCounselling } from "../../../components/icons/Icons"
import { navigatetoUrl } from "../../../utils/helper/helper"
import OnboardingBackground from "../../../components/on-boarding/onboarding-background/OnboardingBackground"
import NavigationButtons from "../../../components/on-boarding/navigation-buttons/NavigationButtons"
import TeachingOptionsComponent from "./TeachingOptionsComponent"

import standardizedTestList from "../../../utils/constant/standardizedTestList"
import counsellingList from "../../../utils/constant/counsellingList"
import subjectsList from "../../../utils/constant/subjectsList"
import { useSelector, useDispatch } from "react-redux"
import { selectteacherType } from "../../../store/actions/TutorSignUpActions"
import ToastNotification from "../../../components/modal/ToastNotification"
import { toastConstant } from "../../../store/constant"
import { showToast } from "../../../store/actions/toastAction"
import { applicationConstant } from "../../../utils/constant/applicationConstant"
import InterfaceConstant from "../../../utils/constant/interfaceConstant"
import ProgressBar from "../../../components/progress-bar/ProgressBar"
import MultiSelectDropDown from "../../../components/dropdown/MultiSelectDropdown"
interface Iprops {
    history: any
}
const routeUrl: InterfaceConstant.IrouteUrl = applicationConstant.routeUrl
const teacherType: InterfaceConstant.IteacherTypes = applicationConstant.teacherTypes
const teacherTypeKeys: InterfaceConstant.IteacherTypes = applicationConstant.teacherTypeKeys
const tutorAdvisorLabel: InterfaceConstant.ItutorAdvisorLabel =
    applicationConstant.tutorAdvisorLabel
const tutorAdvisorErrorLable: InterfaceConstant.IappErrorLabels = applicationConstant.appErrorLabels
const TutorAdvisorApplicationPageOptions = [
    {
        key: teacherTypeKeys.TUTORING,
        title: teacherType.TUTORING,
        icon: tutoring,
    },
    {
        key: teacherTypeKeys.ADVISING,
        title: teacherType.ADVISING,
        icon: counselling,
    },
    {
        key: teacherTypeKeys.TUTORING_AND_ADVISING,
        title: teacherType.TUTORING_AND_ADVISING,
        icon: tutoringCounselling,
    },
]
const TutorAdvisorApplicationPage = (props: Iprops): JSX.Element => {
    const tutorInfo = useSelector((state: any) => state.tutorSignUp)
    const { message, show, isSuccess } = useSelector((state: any) => state.toast)
    const dispatch = useDispatch()
    const [isShowError, setShowError] = useState(false)
   
    const handleError = (status: boolean): void => {
        if (isShowError !== status) {
            setShowError(status)
        }
    }
    const handleTeacherType = (TeacherType: string): void => {
        handleError(false)
        dispatch(
            selectteacherType({
                teacherType: TeacherType,
                value: tutorInfo.value,
            }),
        )
    }
    const handledropdownValue = (subject: object, test: object, specialization: object): void => {
        dispatch(
            selectteacherType({
                teacherType: tutorInfo.teacherType,
                value: {
                    subject: subject,
                    test: test,
                    specialization: specialization,
                },
            }),
        )
    }
    const handleOptionPress = (selectedOption: string): string => {
        switch (selectedOption) {
            case teacherTypeKeys.TUTORING:
                handleTeacherType(teacherTypeKeys.TUTORING)
                return teacherTypeKeys.TUTORING
            case teacherTypeKeys.ADVISING:
                handleTeacherType(teacherTypeKeys.ADVISING)
                return teacherType.ADVISING
            case teacherTypeKeys.TUTORING_AND_ADVISING:
                handleTeacherType(teacherTypeKeys.TUTORING_AND_ADVISING)
                return teacherTypeKeys.TUTORING_AND_ADVISING
            default:
                return ""
        }
    }
 
    const setsubjectvalue = (newOptionsState: object): void => {
        handledropdownValue(newOptionsState, tutorInfo.value.test, tutorInfo.value.specialization)
    }
   
    const setTestvalue = (newOptionsState: object): void => {
        handledropdownValue(
            tutorInfo.value.subject,
            newOptionsState,
            tutorInfo.value.specialization,
        )
    }
   
    const setSpecialtiesvalue = (newOptionsState: object): void => {
        handledropdownValue(tutorInfo.value.subject, tutorInfo.value.test, newOptionsState)
    }
    const nextNavigation = (): void => {
        switch (tutorInfo.teacherType) {
            case teacherTypeKeys.TUTORING:
                if (tutorInfo.value.subject.length === 0) {
                    handleError(true)
                    return
                }
                navigatetoUrl(props.history, routeUrl.TUTOR_EDUCATION_PAGE)
                break
            case teacherTypeKeys.ADVISING:
                if (tutorInfo.value.specialization.length === 0) {
                    handleError(true)
                    return
                }
                navigatetoUrl(props.history, routeUrl.TUTOR_EDUCATION_PAGE)
                break
            case teacherTypeKeys.TUTORING_AND_ADVISING:
                if (
                    tutorInfo.value.subject.length === 0 ||
                    tutorInfo.value.specialization.length === 0
                ) {
                    handleError(true)
                    return
                }
                navigatetoUrl(props.history, routeUrl.TUTOR_EDUCATION_PAGE)
                break
            default:
                dispatch(
                    showToast({
                        show: true,
                        message: tutorAdvisorErrorLable.GLOBAL_SELECT_ACTIVITY_ERROR,
                        isSuccess: false,
                    }),
                )
                return
        }
        if (!isShowError) {
            navigatetoUrl(props.history, routeUrl.TUTOR_EDUCATION_PAGE)
        }
    }
    const prevNavigation = (): void => {
        navigatetoUrl(props.history, routeUrl.MY_ROLE)
    }

    const renderTutorFrom = (): JSX.Element => {
        return (
            <Fragment>
                <MultiSelectDropDown
                    
                    title={tutorAdvisorLabel.SUBJECT_DROPDOWN_TITLE}
                    placeholder={tutorAdvisorLabel.SUBJECT_DROPDOWN_PLACEHOLDER}
                    optionsList={subjectsList}
                    error={
                        isShowError && tutorInfo.value.subject.length === 0
                            ? tutorAdvisorErrorLable.GLOBAL_DROPDOWN_ERROR
                            : ""
                    }
                    subjectvalue={setsubjectvalue}
                    selectedOption={tutorInfo.value.subject}
                />
                <MultiSelectDropDown
                    
                    title={tutorAdvisorLabel.TEST_DROPDOWN_TITLE}
                    placeholder={tutorAdvisorLabel.TEST_DROPDOWN_PLACEHOLDER}
                    optionsList={standardizedTestList}
                    error={null}
                    subjectvalue={setTestvalue}
                    selectedOption={tutorInfo.value.test}
                />
            </Fragment>
        )
    }
    const renderAdviserFrom = (): JSX.Element => {
        return (
            <Fragment>
                <MultiSelectDropDown
                    
                    title={tutorAdvisorLabel.SPECIALTIES_DROPDOWN_TITLE}
                    placeholder={tutorAdvisorLabel.SPECIALTIES_DROPDOWN_PLACEHOLDER}
                    optionsList={counsellingList}
                    error={
                        isShowError && tutorInfo.value.specialization.length === 0
                            ? tutorAdvisorErrorLable.GLOBAL_DROPDOWN_ERROR
                            : ""
                    }
                    
                    subjectvalue={setSpecialtiesvalue}
                    selectedOption={tutorInfo.value.specialization}
                />
            </Fragment>
        )
    }
    const renderTutorAdviserFrom = (): JSX.Element => {
        return (
            <Fragment>
                {renderTutorFrom && renderTutorFrom()}
                {renderAdviserFrom && renderAdviserFrom()}
            </Fragment>
        )
    }
    const renderForm = (): JSX.Element => {
        switch (tutorInfo.teacherType) {
            case teacherTypeKeys.TUTORING:
                return renderTutorFrom && renderTutorFrom()
            case teacherTypeKeys.ADVISING:
                return renderAdviserFrom && renderAdviserFrom()
            case teacherTypeKeys.TUTORING_AND_ADVISING:
                return renderTutorAdviserFrom && renderTutorAdviserFrom()
            default:
                return <Fragment></Fragment>
        }
    }
    return (
        <>
            <ProgressBar at="16.7" theme="darkMango" />
            <OnboardingBackground />
            <Container className="tutor-advisor-application">
                <TeachingOptionsComponent
                    heading={tutorAdvisorLabel.HEADING}
                    subHeading={tutorAdvisorLabel.SUB_HEADING}
                    options={TutorAdvisorApplicationPageOptions}
                    onOptionPress={handleOptionPress}
                    teacherType={tutorInfo.teacherType}
                />
                <div className="form-container">{renderForm && renderForm()}</div>
                <ToastNotification
                    message={message}
                    isopen={show}
                    isOpenAction={() => dispatch({ type: toastConstant.TOAST_HIDE })}
                    styleClass={isSuccess ? "success-toast" : "error-toast"}
                />
                <div className="onboarding-actions">
                    <NavigationButtons previousClick={prevNavigation} nextClick={nextNavigation} />
                </div>
            </Container>
        </>
    )
}
export default TutorAdvisorApplicationPage
