import React, { useCallback, useEffect, useState } from "react"
import firebase from "firebase"
import { Button, Card, CardActions, CardContent, Grid, Typography } from "@material-ui/core"
import { functions } from "utils/helper/firebase"
import { useSnackbar } from "notistack"
import { useQueryParams } from "utils/hooks/useQueryParams"

export type UnsubEmailNotificationProps = {
    user: UserType,
    functions: firebase.functions.Functions,
    loading: boolean
}
export type UserType = {
    hashedId: string,
    email: string,
    emailNotifications: boolean,
}

export const Component = ({ functions, user, loading }: UnsubEmailNotificationProps) => {
    const [status, setStatus] = useState<"initial" | "loading" | "unsubscribed">("initial")
    const { enqueueSnackbar } = useSnackbar()
    const emailNotifications = user.emailNotifications
    useEffect(() => {
        if (emailNotifications === false) {
            setStatus("unsubscribed")
            enqueueSnackbar("You've already unsubscribed!", {
                variant: "info",
            })
        }
    }, [emailNotifications])

    const unsubNow = useCallback(() => {
        if (!functions || emailNotifications === false) {
            return
        }
        setStatus("loading")
        enqueueSnackbar("Processing ...", {
            variant: "info",
        })
        functions
            .httpsCallable("notifications")({
                actionType: "UPDATE_EMAIL_SUBSCRIPTION_STATUS",
                ...user,
                shouldOptIn: false,
            })
            .then(({ data }) => {
                if (data.status !== "success") {
                    throw new Error(data.message || "Unknown error occurred.")
                }
                setStatus("unsubscribed")
                enqueueSnackbar("You have been unsubscribed from email notifications", {
                    variant: "success",
                    persist: true,
                })
            })
            .catch((e) => {
                setStatus("initial")
                enqueueSnackbar(`Error: ${e.message || e.code || "Unknown. Please try again."}`, {
                    variant: "error",
                })
            })
    }, [status, user])
    return (
        <Grid container={true} alignItems="center" direction="row" justify="center">
            <Grid item>
                <Card variant="outlined">
                    <CardContent>
                        <Typography variant="caption" display="block" gutterBottom>
                            Email: {user.email}
                        </Typography>
                        <hr />
                        <Typography paragraph>
                            Please click the button below to <br /> unsubscribe from the email
                            notifications
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <Button
                            onClick={() => {
                                unsubNow()
                            }}
                            disabled={status !== "initial" || loading}
                            color="primary"
                            variant="outlined"
                            fullWidth={true}
                        >
                            {status === "initial" && "Unsubscribe Now"}
                            {status === "loading" && "Unsubscribing ..."}
                            {status === "unsubscribed" && "Successfully Unsubscribed"}
                        </Button>
                    </CardActions>
                </Card>
            </Grid>
        </Grid>
    )
}

export const UnsubEmailNotification = () => {
    const queryParams = useQueryParams()
    const hashedId = queryParams.get("data")
    const email = queryParams.get("email")
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState<boolean>(false);

    const [user, setUser] = useState<UserType>({
        hashedId: hashedId || "",
        email: email || "",
        emailNotifications: true,
    })
    useEffect(() => {
        if (!hashedId) {
            return
        }
        setLoading(true);
        functions
            .httpsCallable("notifications")({
                actionType: "GET_EMAIL_SUBSCRIPTION_STATUS",
                hashedId,
                email,
            })
            .then(({ data }) => {
                if (data.status !== "success") {
                    throw Error(data.message || "Error while getting email subscription status")
                }
                setUser((prevUser) => ({
                    hashedId: prevUser.hashedId || hashedId || "",
                    email: prevUser.email || email || "",
                    emailNotifications: data?.data?.email === true || data?.data?.email === undefined,
                }))
            })
            .catch((e) => {
                enqueueSnackbar(`Error: ${e.message || e.code || "Unknown. Please try again."}`, {
                    variant: "error",
                })
            }).finally(() => {
                setLoading(false);
            })
    }, [hashedId, email])
    return user?.hashedId && user.email ? <Component functions={functions} user={user} loading={loading} /> : <></>
}
