import React from "react"
import { LinkRoundedIcon } from "../icons/Icons"
import DefaultAvatar from "../../assets/images/default-avatar.png";
import { AnyAction } from "redux";
interface Iprops {
    student: string
}
const StudentAvatar = ({ student }: Iprops) => {
    return (
        <div className ="student-avatar">
            <img
                onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = DefaultAvatar;
                  }}
                src={student ? student : DefaultAvatar}
                className="upcoming-student-img"
              
            />
        </div>
    )
}

export default StudentAvatar