import React, { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import { applicationConstant } from "../../../utils/constant/applicationConstant"
import InterfaceConstant from "../../../utils/constant/interfaceConstant"
import { useHistory } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { Grid } from "@material-ui/core"
import LessonSubscribeHeader from "./components/LessonSubscribeHeader"
import LessonSubscribeSubtotal from "./components/LessonSubscribeSubtotal"
import LessonSubscribeCancelation from "./components/LessonSubscribeCancelation"
import LessonSubscribeForm from "./components/LessonSubscribeForm"
import { findPlansByName } from "../../../store/actions/PaymentActions"
import { PaymentConstant } from "../../../store/constant"
import LoaderSpinner from "../../../components/loader/LoaderSpinner"
import { useTranslation } from "react-i18next"
import { Checkbox } from "@material-ui/core"
import { navigatetoUrl, navigatetoUrlWithProps } from "utils/helper/helper"
import PromoCode, { promoCodeDetailsType } from "../booking/components/PromoCode"
import { constructProductName } from "utils/helper/promo-code"

const LessonSubscribePage = (props: any): JSX.Element => {
    const { t } = useTranslation()
    const { subType } = useParams()
    const { plan, isLoading, planNotFound } = useSelector((state: any) => state.payment)
    const { subscribed } = useSelector((state: any) => state.payment)

    const dispatch = useDispatch()
    const bookingLabel: InterfaceConstant.IbookingLabel = applicationConstant.bookingLabel

    const history = useHistory()

    const getPrice = plan
        ? plan.unit_amount_decimal
            ? plan.unit_amount / 100
            : plan.unit_amount
        : ""
    const productName = constructProductName(plan)
    const subscriptionType = { term: plan.nickname, price: getPrice }
    const [dontRenew, setDontRenew] = useState(false)
    const [promoCodeDetails, setPromoCodeDetails] = useState<promoCodeDetailsType | undefined>(
        undefined,
    )
    const [netPrice, setNetPrice] = useState<number>(subscriptionType.price)
    const [applyingPromo, setApplyingPromo] = useState<boolean>(false)

    useEffect(() => {
        const totalPrice: number = subscriptionType.price
        const discount = promoCodeDetails?.amount_off
            ? promoCodeDetails.amount_off / 100
            : promoCodeDetails?.percent_off
            ? (totalPrice * promoCodeDetails?.percent_off) / 100
            : 0
        let discountedPrice = totalPrice - discount
        discountedPrice = discountedPrice >= 0 ? discountedPrice : 0
        discountedPrice = Number(discountedPrice.toFixed(2))
        setNetPrice(discountedPrice)
    }, [promoCodeDetails, subscriptionType])

    useEffect(() => {
        subscribed &&
            navigatetoUrlWithProps(history, "/panel/lessons/subscribe/" + subType + "/confirmed", {
                ...plan,
                promoCodeDetails,
            })
        return () => {
            dispatch({ type: PaymentConstant.RESET_SUBSCRIBED })
        }
    }, [subscribed])

    useEffect(() => {
        dispatch(findPlansByName({ subType, history }))
    }, [])

    useEffect(() => {
        if (planNotFound) {
            navigatetoUrl(history, "/panel/lessons")
        }
    }, [planNotFound])

    return (
        <>
            {!isLoading ? (
                <>
                    <Grid container className="subscription-payment">
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <div className="white-section">
                                <div className="lesson-whitesection-mob-pad">
                                    <LessonSubscribeHeader />
                                </div>
                                <div className="lesson-whitesection-mob-pad">
                                    <LessonSubscribeSubtotal
                                        subscriptionType={subscriptionType}
                                        bookingLabel={bookingLabel}
                                        price={netPrice}
                                        promoCodeDetails={promoCodeDetails}
                                        setPromoCodeDetails={setPromoCodeDetails}
                                    />
                                </div>
                                <div>
                                    <PromoCode
                                        product={productName}
                                        setPromoCodeDetails={setPromoCodeDetails}
                                        promoCodeDetails={promoCodeDetails}
                                        setApplying={setApplyingPromo}
                                    />
                                </div>
                                <div className="lesson-whitesection-mob-pad">
                                    <LessonSubscribeCancelation />
                                </div>
                                <p className="lesson-whitesection-mob-pad">
                                    <Checkbox
                                        checked={dontRenew}
                                        onChange={() => setDontRenew(!dontRenew)}
                                        name="acceptedTerms"
                                        color="primary"
                                    />
                                    <span className="checkbox-label">{t("dont-renew")}</span>
                                </p>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <div className="gray-section">
                                <LessonSubscribeForm
                                    plan={plan}
                                    dontRenew={dontRenew}
                                    promoCodeId={promoCodeDetails?.id}
                                    applyingPromo={applyingPromo}
                                />
                            </div>
                        </Grid>
                    </Grid>
                </>
            ) : (
                <LoaderSpinner />
            )}
        </>
    )
}

export default LessonSubscribePage
