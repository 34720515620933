/* TUTORING LIST */
const subjectsList = [

    { id: '8f11822e-16d1-4f07-adb9-f54470fa586f', name: 'Art' },

    { id: 'c38e981b-0184-4d1f-aa0b-fa9d8a51b8f6', name: 'Art: Architecture' },

    { id: '3b1b2a25-4dd5-42b2-9a0e-493ec2f93c09', name: 'Art: Art Theory' },

    { id: '912eaf9b-9df0-4ad9-b029-2975c66e5d0d', name: 'Art: Drawing' },

    { id: '99be1d34-a267-46fd-9d10-3902bb85c46d', name: 'Art: Film Studies' },

    {

        id: '61dae0b0-328b-4ead-8c16-d9eef95630dd',

        name: 'Art: Music Composition',

    },

    { id: 'fd35d563-69bd-4289-9f8b-094de093ffff', name: 'Art: Painting' },

    { id: '283f9654-9761-4ba4-9c2d-710ccb9c00ae', name: 'Art: Photography' },

    { id: '0906ec8b-71f4-40f4-ac87-0a27ddb38715', name: 'Art: Sculpture' },

    { id: 'e07c97a8-bac8-40fd-85e9-6fd459863c24', name: 'Art: Theatre' },


    { id: '32d8b178-415b-4179-a339-e6a36b9baed4', name: 'Astronomy' },

    { id: '4ded8f8c-ba12-400e-8fdd-3aac938d2645', name: 'Biochemistry' },

    { id: '32a10927-307e-4c5b-aaf0-d1d5fdd0c8ca', name: 'Biology' },

    { id: 'f7367de2-2b5c-4e6c-a470-de9530436301', name: 'Biology (9-12)' },

    { id: '03b5d99c-5cb3-11ec-bf63-0242ac130002', name: 'Biology (K-5)' },

    { id: '03b5dbea-5cb3-11ec-bf63-0242ac130002', name: 'Biology (6-8)' },


    { id: '97a205b6-d796-4f33-96cc-b1d634147929', name: 'Biology (College)' },

    { id: 'a05274d7-5870-403e-a906-b849ebfa573f', name: 'Biology (K-12)' },

    { id: 'c13740af-a1ee-486a-89af-6da3f507bbbb', name: 'Business' },

    { id: 'c58465a0-92fb-4378-9e1a-2e1877d6ae0a', name: 'Chemistry' },

    { id: 'cbfc01ef-7601-47f3-ba50-b26d3d67f813', name: 'Chemistry (9-12)' },
    { id: '03b5dd02-5cb3-11ec-bf63-0242ac130002', name: 'Chemistry (K-5)' },
    { id: '03b5ddfc-5cb3-11ec-bf63-0242ac130002', name: 'Chemistry (6-8)' },

    {

        id: '37fc6698-36ed-4306-a1db-514bba2d80ac',

        name: 'Chemistry (College)',

    },

    { id: 'c33db7d2-3525-4f55-b79a-1b7c318fa587', name: 'Chemistry (K-12)' },

    { id: '1c374b06-16b5-4499-b851-be6643aa52e9', name: 'Chemistry (Organic)' },

    { id: '6e6e6063-b65c-4f38-b289-443a28e5f7a7', name: 'Computer Science' },

    { id: 'b0753a6f-1603-48ef-9a8e-107d9e7869cc', name: 'Computer: App Design' },

    { id: '0602e930-a549-4880-a779-96ed85d5e85c', name: 'Computer: ASP.NET' },

    {

        id: 'ae75642c-de5c-4bc4-bbcb-7ae46965318f',

        name: 'Computer: Adobe Flash',

    },

    {

        id: '54ea396b-c43c-46b4-a08b-28ae4307a46a',

        name: 'Computer: Adobe Illustrator',

    },

    {

        id: '36759d0f-c7dd-4da0-bf3d-eeffe2fd031d',

        name: 'Computer: Adobe InDesign',

    },

    {

        id: '48c35bf3-dfb3-4b4b-9e04-1ae90db121c4',

        name: 'Computer: Adobe Lightroom',

    },

    {

        id: '4efeedbf-824f-4c98-a196-23b2f9b1c183',

        name: 'Computer: Adobe Photoshop',

    },

    { id: 'c498295a-9006-41b4-966d-067fa81c5f86', name: 'Computer: Angular' },

    { id: 'd2d784b4-375b-4fee-b47a-9661ce87d53b', name: 'Computer: Animation' },

    { id: 'e1072d1d-24eb-4260-bca8-233207e6fcb2', name: 'Computer: AutoCAD' },

    {

        id: 'e128f0f4-dac4-4274-8cba-f3b5fc00104a',

        name: 'Computer: Autodesk Maya',

    },

    { id: 'c9846208-b30b-4d39-b009-9a0cbd693ffc', name: 'Computer: C' },

    { id: 'faea0f05-5084-4bcc-a41d-69ffdf143abd', name: 'Computer: C#' },

    { id: 'c274ee1a-3404-4725-a44c-139e3a649b00', name: 'Computer: C/C++' },

    { id: '5a151595-74a8-4f28-9cfd-f56706d4aaba', name: 'Computer: COBOL' },

    { id: 'b8a1914e-c2fc-4531-92e4-915349a9b003', name: 'Computer: CSS' },

    { id: 'b1191390-a547-43d5-8809-8f37ea745435', name: 'Computer: Coding' },

    {

        id: 'ae58bcd2-b315-4ad4-bf9d-c05c857b508a',

        name: 'Computer: Computer Engineering',

    },

    {

        id: 'f3ea5963-c74d-4856-9d39-f5aed98a4d63',

        name: 'Computer: Computer Gaming (Game Design)',

    },

    {

        id: '92d19b8d-bbbd-4264-9c8b-8824debc4a8b',

        name: 'Computer: Computer Networking',

    },

    {

        id: 'fc01b605-7039-457e-a28b-0047324d5dca',

        name: 'Computer: Computer Programming',

    },

    { id: 'f3c24b00-c355-4ee9-a391-d9d5e1f9583c', name: 'Computer: DOS' },

    {

        id: '8f6a1de0-e197-4abb-8bf1-91b63c9149c1',

        name: 'Computer: Desktop Publishing',

    },

    {

        id: '4e0b69de-29d5-4882-a969-e5ae8d075442',

        name: 'Computer: Dreamweaver',

    },

    {

        id: 'dfb93484-41e6-4508-8925-21ee738e9720',

        name: 'Computer: Electronics',

    },

    { id: '6b215b23-17e1-4486-9172-481bc7045841', name: 'Computer: Fortran' },

    { id: '41aded99-b777-4ae7-ab71-23b321ed73b6', name: 'Computer: GIS' },

    {

        id: '0d3e8347-3a81-4197-a979-c8ab4856929c',

        name: 'Computer: Graphic Design',

    },

    { id: 'ac4a921c-7b51-4d7b-ad65-2f6916a18502', name: 'Computer: HTML' },

    { id: '40d988cb-c7ea-4091-b477-c716efd28368', name: 'Computer: JQuery' },

    { id: '065928a4-5d1f-4706-bf18-da03bc2c8812', name: 'Computer: Java' },

    {

        id: '0d97a8f7-729c-40b9-9a73-78ee1c9b037a',

        name: 'Computer: Javascript',

    },

    { id: '1a22b985-5d49-4a2d-8bcd-346152dc8ad9', name: 'Computer: Linux' },

    { id: '853e2d7c-75dd-4c62-835b-49e3a1ecc726', name: 'Computer: MATLAB' },

    { id: '6148ef8a-3abe-49f6-b456-e850276327b3', name: 'Computer: Mac' },

    {

        id: '28dd1718-b23f-4ff2-b6af-63378217dbdb',

        name: 'Computer: Mathematica',

    },

    {

        id: 'b2db1264-bb7a-4dbd-a3b3-c5b818d86db2',

        name: 'Computer: Microsoft Access',

    },

    {

        id: 'ebf5d6a7-21ac-4380-aa86-b8c624e7681a',

        name: 'Computer: Microsoft Excel',

    },

    {

        id: 'f29c45be-bf8e-41e4-a17a-7f42b5b4c29a',

        name: 'Computer: Microsoft Outlook',

    },

    {

        id: '4dab36f1-24d5-4e08-b462-e8cf0ea06487',

        name: 'Computer: Microsoft PowerPoint',

    },

    {

        id: 'ad2a394c-28fd-4002-bc85-ccf90be3ca47',

        name: 'Computer: Microsoft Publisher',

    },

    {

        id: '7beea9eb-1c10-4f47-8a04-1e31f91bcfec',

        name: 'Computer: Microsoft Windows',

    },

    {

        id: 'cdfefd84-4317-4588-85d0-f97e45200bb7',

        name: 'Computer: Microsoft Word',

    },

    { id: '5781ce14-7f41-40ba-9585-789e083780c4', name: 'Computer: Oracle' },

    { id: '9516ee27-de37-45c2-ad37-1758cd59f890', name: 'Computer: PHP' },

    { id: '51956e4d-545e-421c-b0a9-7eaf0b03f69d', name: 'Computer: Pascal' },

    { id: '6b130504-eea9-4648-8d49-380fd584ceec', name: 'Computer: Perl' },

    { id: '7c190aa9-a239-4887-b64f-c09b4b7db87c', name: 'Computer: Python' },

    {

        id: '3fe34c96-c333-42a6-b5ce-38b6f49e1678',

        name: 'Computer: QuickBooks',

    },

    { id: 'ad799611-e22d-4b2e-a3b8-d5fa0b6b6283', name: 'Computer: R' },

    { id: '405a58d5-d1b7-43bb-a540-d61b05b62075', name: 'Computer: Revit' },

    { id: '8ce4b8ac-ad59-4d3c-a288-b9ded57b1265', name: 'Computer: Ruby' },

    { id: 'a3a6ed02-881b-438c-b1c7-bdafa88b7509', name: 'Computer: SAS' },

    { id: '75bc26d3-edb6-453d-b907-dd9ec442360d', name: 'Computer: SPSS' },

    { id: 'dc4ad0df-4af5-4361-9263-323444628ed3', name: 'Computer: SQL' },

    { id: 'a0c6e116-dd0d-4686-8497-7088e64b06c6', name: 'Computer: STATA' },

    { id: '54994817-41d6-46c6-a6f9-af88322e394d', name: 'Computer: Sketchup' },

    {

        id: 'b656a1cc-d183-4e9d-87ff-ea52adb96ace',

        name: 'Computer: Solidworks',

    },

    { id: '5c98060c-eb7a-456e-9f3d-c8a67201e2ef', name: 'Computer: Swift' },

    { id: '9eea9e7d-a834-4157-8bef-b36003e601db', name: 'Computer: UNIX' },

    {

        id: '57efe23a-7d10-426d-a755-1c7587b0f4b8',

        name: 'Computer: Video Production',

    },

    {

        id: '41e438ef-7024-4c44-98c5-d9b33f2b80e4',

        name: 'Computer: Visual Basic',

    },

    {

        id: 'fb9e9fc6-783f-4d3e-9769-2d82eae4e999',

        name: 'Computer: Web Design',

    },

    { id: 'aa34bb02-f350-462d-88d5-799e7431da7f', name: 'Design' },

    {

        id: '4e53c7b9-d138-4e62-b90e-ba1e8abdd216',

        name: 'Early Childhood School Readiness',

    },

    { id: '1dfada77-e4b6-4c0c-9013-3d39e5e3ba8e', name: 'Earth Science' },

    { id: '4a2e15c7-a11a-4b83-9558-11c737a305a0', name: 'Economics' },

    {

        id: 'f6f6a5b4-565f-4a5e-8c15-8b05b6e1d0a1',

        name: 'Elementary: Common Core',

    },

    { id: '15411bb1-6831-4a3b-8de1-0c53017c1e5b', name: 'Elementary: ELA' },

    { id: 'cc8abd0c-dbee-4a67-842f-e27a7cf85354', name: 'Elementary: ERB' },

    {

        id: '9c6b7917-dd13-4a1f-ae04-69d597eb453b',

        name: 'Elementary: Elementary Math',

    },
    { id: '03b5f6e8-5cb3-11ec-bf63-0242ac130002', name: 'Elementary: Orton Gillingham' },
    { id: '03b5f7ba-5cb3-11ec-bf63-0242ac130002', name: 'Elementary: Remedial Reading' },
    {

        id: 'c5788b0d-a3ac-4227-82b8-499274a497dd',

        name: 'Elementary: Elementary Science',

    },

    { id: '4e23e68c-4e2e-47b1-b4cf-fec765862b6f', name: 'Elementary: Grammar' },

    {

        id: '3102962f-3917-46ee-b816-baaa1eb6fc10',

        name: 'Elementary: Handwriting',

    },

    {

        id: '6d4646f2-46bd-4895-b452-c217d132e0d7',

        name: 'Elementary: Homeschool',

    },

    { id: '2176ea1b-7aad-4fe6-b87f-e7285f31b717', name: 'Elementary: Phonics' },

    { id: 'd8f45354-f4bd-4c5f-8b9b-408ff8bba99f', name: 'Elementary: Reading' },

    {

        id: '6b9af701-77c2-424a-8c66-748e16772b54',

        name: 'Elementary: Reading Comprehension',

    },

    {

        id: '841fea64-e09b-4afb-8160-e88a86cd55e4',

        name: 'Elementary: Singapore Math',

    },

    {

        id: 'ee6eaac1-e487-43e7-8da4-1fa805c2acbe',

        name: 'Elementary: Spelling',

    },

    {

        id: 'fc7f6085-bdc3-411a-9468-8086220d7dc3',

        name: 'Elementary: Study Skills & Organization',

    },

    { id: '00bdc8d1-41a9-4e49-af97-e7a37e5afb5b', name: 'Elementary: Writing' },

    {

        id: '95c0cd68-1a44-4976-93b6-7705aaf255a0',

        name: 'Executive Functioning',

    },

    {

        id: 'd96a0cf3-aa35-4af7-af06-8b2d8cec6b9a',

        name: 'Folklore and Mythology',

    },

    { id: '3a6344d4-8104-451b-9b6e-a589642abaa4', name: 'Genetics' },

    { id: '23848f9c-f713-4097-90fc-6493d992eadd', name: 'Government' },

    {

        id: '91b50a35-69f7-488f-8529-ce46bc54090c',

        name: 'Group Science Classes (K-12)',

    },

    { id: '34680a85-e2d4-4cf9-bd7a-8ed25e66b32d', name: 'History' },

    {

        id: '1bc2d2b8-caf9-4d44-a4d8-23312ffbf2cb',

        name: 'History: African American History',

    },

    {

        id: '228520b0-5e5e-4ea5-9629-fa45ceb399dd',

        name: 'History: African History',

    },

    {

        id: 'ebb3b987-e20c-4722-94c3-7a2f915a9a6b',

        name: 'History: American History',

    },

    {

        id: 'ba57df02-2d3c-4cd7-85c1-9daebfa715ed',

        name: 'History: Anthropology',

    },

    {

        id: '42223d11-84c6-4097-8b52-b3367d542430',

        name: 'History: Archaeology',

    },

    {

        id: '0e3ebb00-465d-4f08-9eda-fc89e5096572',

        name: 'History: Art & Architecture',

    },

    {

        id: 'ed8d4dc5-00b7-4a62-a977-6409b2095664?',

        name: 'History: Art History',

    },


    { id: 'ed79add3-4fe7-46c4-82c8-b69e252afc8c', name: 'History: Civil Rights' },


    {

        id: '31cfbaa6-6724-4ac7-a810-7ca5710d1bcb',

        name: 'History: Classical History',

    },

    {

        id: '597e8b71-0b1f-4c59-81b8-5bc005e1a3d2',

        name: 'History: College-Level History',

    },

    {
        id: '03b5fa44-5cb3-11ec-bf63-0242ac130002',

        name: 'History: Religious Studies'
    },


    {

        id: 'd19516b0-d3a8-4d2a-b02f-b349c9207020',

        name: 'History: Criminal Justice',

    },

    {

        id: 'b252e194-3cd0-4509-a7b9-daac0f6c8466',

        name: 'History: East Asian History',

    },

    {

        id: '129fdfc3-8a29-460d-bc01-d781feff8ba5',

        name: 'History: Elementary Social Studies',

    },

    {

        id: '3533c496-c044-4151-a4cb-7a5d1a7407cb',

        name: 'History: European History',

    },

    { id: 'a9573bcc-470b-43e8-871f-3585c7612753', name: 'History: Geography' },

    {

        id: '03a38262-e73c-438d-ae22-df57e92a3db9',

        name: 'History: Government and Politics',

    },

    {

        id: '3d8fd3bb-9c43-4c64-800f-84c306e58151',

        name: 'History: High School History',

    },

    {

        id: 'a18c08c0-7fb5-49d1-99fe-984e7882b5c1',

        name: 'History: History of Science and Technology',

    },

    {

        id: '15f8c8e6-a483-4108-b4c5-4d502714ab5c',

        name: 'History: Middle Eastern History',

    },

    {

        id: 'cf555010-4bed-43d4-a1f8-095ccf57e137',

        name: 'History: Middle School Social Studies',

    },

    {

        id: '5c8fae8a-7cc2-405c-9676-9bd512f4351d',

        name: 'History: Music History',

    },

    {

        id: '27bed7ac-36ce-4654-b293-3cbfb6a7c796',

        name: 'History: Political Science',

    },

    { id: '1626dc39-b91c-4b8c-952e-3aea70c66aca', name: 'History: Religion' },

    {

        id: '80139bfe-d3b1-423f-9fc1-74decfa61638',

        name: 'History: U.S. History',

    },

    {

        id: '4da9c709-34f2-40cb-afaa-0786a8e4d9ea',

        name: 'History: World History',

    },

    {

        id: '470a0d9d-8bc5-4a65-afc1-254e7da873ec',

        name: 'IB Individuals and Societies',

    },

    {

        id: '1a039978-8846-4e34-aee3-d77fd66bfcfd',

        name: 'IB Language Acquisition',

    },

    {

        id: 'acde8fe5-6d05-4f98-a603-53901ab3252d',

        name: 'IB:  Math Analysis and Approaches HL',

    },

    {

        id: '49e24d25-d5ca-493c-ab6a-232d965dc753',

        name: 'IB:  Math Analysis and Approaches SL',

    },

    {

        id: '23dd6f2f-d40a-4d20-9a96-73cee1043a39',

        name: 'IB: Applications and Interpretation HL',

    },

    {

        id: '0abc13a1-e3dc-4419-ace9-20d19d9b6cd1',

        name: 'IB: Applications and Interpretation SL',

    },

    { id: '95d59ccb-b4e6-4489-9565-706c3c8e29ce', name: 'IB: Biology' },

    {

        id: 'bd303edf-ef3e-4e27-b3e4-c83edb4a74a2',

        name: 'IB: Business Management',

    },

    { id: 'ce6ab376-8f6e-41e0-9c81-b1819193519a', name: 'IB: Chemistry' },

    {

        id: '7908f04e-1f47-4658-bb0c-abfe388c29ea',

        name: 'IB: Computer Science',

    },

    { id: '2aecefb9-9560-45f6-b148-221d84538c7d', name: 'IB: Dance' },

    {

        id: '080929da-f62c-4918-8d18-fe578f504ee6',

        name: 'IB: Design Technology',

    },

    { id: '97e3892c-53c7-40a4-99cb-089a7e349975', name: 'IB: Economics' },

    {

        id: 'b50730be-a281-4deb-bd21-23b7c5eadbee',

        name: 'IB: Environmental Systems and Societies',

    },

    { id: '5ab31c0a-ab4f-47eb-9eb2-736767fb5934', name: 'IB: Film' },

    {

        id: '12d2b93c-0dc6-487c-bcbb-03a51e340258',

        name: 'IB: French ab initio',

    },

    { id: '78b1c8d4-7d63-41bd-b472-7bef2c850594', name: 'IB: Geography' },

    { id: '92ee105c-bb9e-47e0-916b-5e57a87f9777', name: 'IB: Global Politics' },

    { id: '41f5b36f-dbcd-481c-b855-7620d8c5c39c', name: 'IB: History' },

    {

        id: '56937502-6828-4284-9489-0316d8faa2cd',

        name: 'IB: Information Technology in a Global Society',

    },

    { id: '92acc8b2-77bd-4a1d-b7fd-8f729376abc3', name: 'IB: Language A ' },

    {

        id: '496317ca-2472-4da7-bd7e-3308e5ac2d83',

        name: 'IB: Latin or Classical Greek ',

    },

    {

        id: '957f50fe-a990-4718-b77b-6edcc869dedc',

        name: 'IB: Literature and Performance',

    },

    {

        id: 'a86e6855-f2ed-4223-9915-10bb512da0b9',

        name: 'IB: Mandarin ab initio',

    },

    { id: 'e192f745-577f-4408-811c-f7b5e353c79f', name: 'IB: Music' },

    { id: '3581bb5e-e123-42c3-a080-4c53b667a360', name: 'IB: Philosophy' },

    { id: '3504ddcc-7be8-4b3e-90f0-e1609643ef3f', name: 'IB: Physics' },

    { id: 'dea625e5-38d4-4cc0-97b4-b3e3f68aa6c0', name: 'IB: Psychology' },

    {

        id: '2dba288f-c1f9-4b83-a4c6-e565aeb0ae0e',

        name: 'IB: Social and Cultural Anthropology',

    },

    { id: 'a4609379-4df5-4a15-8851-50b7b4c14d98', name: 'IB: Spanish B SL' },

    {

        id: '049e21ce-1ed9-433b-92df-d8a14afeb53e',

        name: 'IB: Spanish ab initio',

    },

    {

        id: 'f897c0e0-5df5-4f59-8ef8-0cf403bf53db',

        name: 'IB: Sports, Exercise and Health Science',

    },

    { id: 'a4dbc8c0-f676-4d21-bf7f-593af933d5de', name: 'IB: Theatre' },

    { id: '60bea839-7346-4aa3-a703-9fa6ddf41617', name: 'IB: Visual Art' },

    { id: '153424c9-9fb2-4879-8aa5-3048589a4018', name: 'IB: World Religions' },

    {

        id: 'ee51d8e4-fc76-4db1-9ec9-a628937e886d',

        name: 'International Relations',

    },

    {

        id: '911c62ae-d5d9-425a-b709-3322d9eb6c4a',

        name: 'International Studies',

    },

    {

        id: '5e6cf5c6-60bb-46e2-967e-7bac26c7d7fa',

        name: 'Kindergarten Readiness',

    },

    { id: '7dddedf2-7f02-4962-a77d-141d22ec0270', name: 'Language Arts' },

    {

        id: 'd1914d21-1ea4-49fa-ae8d-f1336940aed3',

        name: 'Language: American Sign Language',

    },

    { id: '8000541b-5f5b-4e82-9549-dcbe717b2c31', name: 'Language: Arabic' },

    { id: '6e0e5aa2-8f46-4042-b476-9e7520ce4b0a', name: 'Language: Braille' },

    { id: '1de8cd58-0d69-418b-99e9-11f5dbdad6d6', name: 'Language: Bulgarian' },

    { id: '3e70e8a4-923c-4928-aff5-99955b55bb80', name: 'Language: Cantonese' },

    { id: '364b9478-a1ca-4167-b8de-5695f9fecad8', name: 'Language: Chinese' },

    {

        id: '34248b47-2d3e-4bb9-931c-d093dae96a54',

        name: 'Language: Classical Greek',

    },

    { id: '39ce504b-afc9-4229-aa5e-ee6684c2ec11', name: 'Language: Czech' },

    { id: '0c4621fe-77df-4ed0-85f7-1e779530f0b4', name: 'Language: Dutch' },

    { id: 'be6abc25-c40a-4336-b4c7-3474920e24c5', name: 'Language: ESL/ESOL' },

    { id: '5a483fa5-e05e-46df-ac08-59e03e2b6214', name: 'Language: English' },

    { id: 'e86513f1-3462-4c12-952b-26f471ebb584', name: 'Language: Farsi' },

    { id: 'ef56d9d9-26c7-4fc6-981c-2f84356cc073', name: 'Language: French' },

    { id: '1a059c12-ec29-4204-842e-0f4cd41826a1', name: 'Language: German' },

    { id: 'a95561d6-b17e-4e9e-b266-d0cea8ae584b', name: 'Language: Greek' },

    { id: '07bfd73b-92d7-43f9-9e82-5c554562dcd1', name: 'Language: Hebrew' },

    { id: 'f5d65212-24c8-43ae-afeb-2618be6abcb2', name: 'Language: Hindi' },

    { id: '08cbb316-57b7-4272-918d-c70043f987fe', name: 'Language: Hungarian' },

    {

        id: 'd019b9e6-4089-4a36-8532-32e7c13b3775',

        name: 'Language: Indonesian',

    },

    { id: '62fa7d46-3cd4-442f-a085-f63355d5a72d', name: 'Language: Italian' },

    { id: '702936bd-9dd4-4ebf-8091-bd9a38cebb39', name: 'Language: Japanese' },

    { id: '4bc84d7c-b748-4ca8-81a5-c1401f076867', name: 'Language: Korean' },

    { id: 'd9c4f60e-5c30-48a5-8256-56207477ca95', name: 'Language: Latin' },

    { id: 'f75e6e1f-e770-480a-9646-06dfa45dc865', name: 'Language: Polish' },

    {

        id: 'bb36360f-e572-4bf9-ba8e-c0a752099820',

        name: 'Language: Portuguese',

    },

    { id: 'fedc1e77-ca0a-4b10-848d-afe8ba3c1409', name: 'Language: Romanian' },

    { id: 'f01065e1-7886-48cc-a2da-259e451018be', name: 'Language: Russian' },

    { id: '9116f63b-59ef-4b60-8c45-970adbb28256', name: 'Language: Spanish' },

    { id: 'ecf22de5-7227-4844-a21e-74a03fe98696', name: 'Language: Thai' },

    { id: '91df8c78-3a5f-4f71-adea-278f32defa58', name: 'Language: Turkish' },

    { id: 'b0d85e85-afda-4bed-80f1-f49095403dd0', name: 'Language: Urdu' },

    {

        id: 'a34ccb0b-aefe-4550-91fc-c16a2879cf37',

        name: 'Language: Vietnamese',

    },

    { id: '6dffcf1e-6c67-4853-b2df-82a31b416bb2', name: 'Law/ Legal Studies' },


    { id: '628180d2-4eee-48e4-bb09-d1cebdd36363', name: 'Linguistics' },

    { id: '6375e140-cd2a-486a-b899-08104321d23e', name: 'Math' },

    { id: 'bfe6a8c1-0aeb-4aa9-8925-7b72df787115', name: 'Math (K-8)' },

    { id: 'efa782e1-14db-425a-b5ba-0660c7c887b6', name: 'Math (PreK-6)' },

    { id: 'c2de1bb2-f863-4e9b-affc-2e86a125ef62', name: 'Math (PreK-9)' },

    { id: 'a91a6ef1-8296-4e35-8c95-f184cb1edc2a', name: 'Math: Accounting' },

    {

        id: '5b90a19a-c847-4581-b583-43c0c5486278',

        name: 'Math: Actuarial Science',

    },

    { id: 'd3153259-23e9-40ac-86dc-0acf1a9db496', name: 'Math: Algebra' },

    { id: 'b5935985-76e9-4234-bd79-6b76f10f2d8c', name: 'Math: Algebra 1' },

    { id: '6dc098fa-5487-44bf-9ec9-8b946e76c3b7', name: 'Math: Algebra 2' },

    {

        id: 'c613dfd4-2454-47be-ac6b-f76853d3b80c',

        name: 'Math: Applied Mathematics',

    },

    { id: 'aafa713b-e1bf-41cb-8b08-cc609bc98d47', name: 'Math: Business Math' },

    { id: '1aa4f670-9ffe-4ed5-81e5-e4868e4f5990', name: 'Math: Calculus' },

    {

        id: '1e5e5120-b216-485f-ba7b-db6d2db750f8',

        name: 'Math: College-Level Math',

    },

    { id: '5330ab92-a0f3-4a51-8964-258677a14c37', name: 'Math: Common Core' },

    {

        id: 'aee3b9a6-f524-4a9b-923e-45fad5c2d698',

        name: 'Math: Differential Equations',

    },

    { id: 'b39f2038-f69d-4be6-8bae-bd8f596315c1', name: 'Math: Discrete Math' },

    { id: '48f6c88a-76e6-49bd-888b-13483daaac7a', name: 'Math: Econometrics' },

    {

        id: 'ac601292-0f92-4061-9b6e-767f445e010f',

        name: 'Math: Elementary Math',

    },

    { id: 'a065addf-9eea-48e9-95fe-693c7712c729', name: 'Math: Finance' },

    { id: 'a427a3c3-367a-4b03-bdea-f375ff0218e5', name: 'Math: Finite Math' },

    { id: '212e9890-1279-4379-a581-08cdf197a78c', name: 'Math: Geometry' },

    {

        id: '75c4d299-bcf0-472f-9a50-4cd3b5a9a053',

        name: 'Math: High School Math',

    },

    { id: 'c591d19b-a968-4ea3-b7a0-ff0376dc9aa5', name: 'Math: IB Math HL' },

    { id: '7ee84610-efaa-4bf7-9d45-f798564a2400', name: 'Math: IB Math Sl' },

    {

        id: '6679b6b9-dac8-4ae0-96f9-619f6fa1aa0e',

        name: 'Math: Linear Algebra',

    },

    { id: 'f4718f9e-37d8-4966-ab5f-b31f33019da1', name: 'Math: Logic' },

    {

        id: '8a972e33-396b-4b4e-90a1-ab6db0f9b409',

        name: 'Math: Macroeconomics',

    },

    {

        id: '18d6a867-3f4a-4308-b5cf-4c9bc5d105cb',

        name: 'Math: Managerial Accounting',

    },

    {

        id: '5e87ceca-3af0-426f-8aa7-292aec9cf307',

        name: 'Math: Microeconomics',

    },

    {

        id: '14c7bc83-c284-4d24-96ef-b62590df75f0',

        name: 'Math: Microsoft Excel',

    },

    {

        id: '9b2a4c5c-7be4-40e7-b21f-b39ba60ca116',

        name: 'Math: Middle School Math',

    },

    { id: '33d98e31-8460-454b-b684-1d42607bf049', name: 'Math: ODEs' },

    { id: 'ee43c008-3027-4b75-a0e8-f08c279ebf13', name: 'Math: PDEs' },

    { id: '261353cc-7f9f-483d-bf5d-76d3cf1a1a40', name: 'Math: Pre-Algebra' },

    { id: '0879a47f-c5fd-4b00-9e47-6636f7f92371', name: 'Math: Pre-Calculus' },

    { id: 'cdc4e7ac-e9d0-41b7-a245-c9015faa1a9d', name: 'Math: Probability' },

    { id: '2ad695e6-dba9-4a56-9789-b8fba85ab922', name: 'Math: Statistics' },

    { id: '24794701-c9f6-4e37-bb19-145fa950fd9a', name: 'Math: Trigonometry' },

    { id: '68ab7c37-0fff-481f-9b96-f09533f07113', name: 'Medicine' },

    { id: '3aaec21c-bd95-494f-8c62-526fa4f17872', name: 'Museum Studies' },

    { id: '6364937a-d884-4c57-a6c0-f80cd7c2072e', name: 'Music: Cello' },
    { id: '05f681a9-c108-4e70-a963-3c02c73ad2cd', name: 'Music: Clarinet' },
    { id: 'eab1085b-0444-4a94-a209-3b6c19d3013a', name: 'Music: Trumpet' },
    { id: '110e29f9-526c-46e1-be62-40af4413353f', name: 'Music: Drums' },
    { id: '9ec3872c-08df-480f-8ff2-14d347bea83f', name: 'Music: Flute' },
    { id: '7b66572a-53a2-4b3f-be01-9a4b5631d203', name: 'Music: Guitar' },
    { id: 'e70c18fe-bde6-42bf-bb0c-84c401a8564b', name: 'Music: Keyboard' },
    { id: '4a277dc5-0841-4815-b20a-19d9a2da406e', name: 'Music: Jazz' },
    { id: '517c01a8-366d-4b84-8015-532691d7b1e8', name: 'Music: Musical Theater' },
    { id: 'dfe58cee-c68d-4ae9-89f2-2948a5bc0ac8', name: 'Music: Music Theory' },
    { id: '325230d7-96fa-4e74-a430-748efff23268', name: 'Music: Opera Singing' },
    { id: 'd34e7861-d8f9-48c4-ac98-a955d33fbca3', name: 'Music: Piano' },
    { id: 'ace22df3-7932-4b73-b345-6052c8716174', name: 'Music: Saxophone' },
    { id: 'de85ee16-76d7-4450-a3a6-b42cfabb3eda', name: 'Music: Singing' },
    { id: 'bed831f2-d430-48db-a086-99ee1e667832', name: 'Music: Songwriting' },
    { id: '8d6aa4b5-204a-4dbd-8c6a-0d3b11761acd', name: 'Music: Tambourine' },
    { id: '17263adf-6ca2-439f-b132-a6aff1c5598f', name: 'Music: Tuba' },
    { id: '6d1f99ec-ecc4-4fd0-8ead-da3b8425f67d', name: 'Music: Trombone' },
    { id: '498f03fc-bd08-42ee-bd48-887580d49480', name: 'Music: Ukulele' },
    { id: '5ebb6e80-8d5b-47f6-b834-85cb176086e9', name: 'Music: Violin' },

    { id: '1d7a817e-59e9-421d-86f8-96944f856d5a', name: 'Philosophy' },

    { id: '2bca7f13-537c-4c40-b1d6-d7e936df68c1', name: 'Psychology' },

    { id: '25d21307-f3c9-4082-922c-e78a8d1b8b50', name: 'Public Affairs' },

    { id: 'bc0bd32b-e843-4aeb-9013-c15dafaf6032', name: 'Public Health' },

    { id: '87337289-d65b-49d5-a028-73d0eb8447b1', name: 'Public Policy' },

    { id: '035b320b-5804-41db-9f09-1e95812e4c5c', name: 'Real Estate' },

    { id: '4f56275e-b796-4d3e-8441-16548dafc07f', name: 'Research Support' },

    {

        id: 'fff32654-de74-440c-b487-d16249c54206',

        name: 'Science: Agricultural Science',

    },

    { id: 'a3b59379-c6b5-4edf-86b5-3490d2089e4a', name: 'Science: Anatomy' },

    {

        id: 'fdeb66c4-d899-43d2-b946-2ea803fb5d98',

        name: 'Science: Astrophysics',

    },

    {

        id: '9dcba7d5-f3d0-45de-9fac-835f95f8859f',

        name: 'Science: Atmospheric and Oceanic Sciences',

    },

    {

        id: 'a0bf60d5-aac1-4dec-b3fa-805c33119b34',

        name: 'Science: Biomedical Science',

    },

    {

        id: '9b46e3da-9bb9-4c03-b24e-ae763fdde03b',

        name: 'Science: Biostatistics',

    },

    { id: '73918f0b-71c5-4622-b78e-770428e24612', name: 'Science: Botany' },

    {

        id: 'a084aade-632a-454f-a0c3-b8f574ee2e98',

        name: 'Science: Chemical Engineering',

    },

    {

        id: '72f154e3-ba17-4b19-9f1e-c426c8b03c6e',

        name: 'Science: Civil Engineering',

    },

    {

        id: '2fb37bdd-bf88-45da-a1e9-9b2f69fe330e',

        name: 'Science: Cognitive Science',

    },

    {

        id: 'c197d6f2-f480-4ae6-9d5f-dae3b3a70909',

        name: 'Science: College-Level Science',

    },

    {

        id: '60427631-7c9c-43e2-b235-0337d6ca5f83',

        name: 'Science: Data Science',

    },

    { id: '56fe33f9-4c57-421c-b8e4-10aba69c2638', name: 'Science: Ecology' },

    {

        id: 'd516e05d-fda2-466d-825f-e1d5fd2fbae0',

        name: 'Science: Electrical Engineering',

    },


    {

        id: 'e59a337b-c0be-43e8-918b-7d5461ab694b',

        name: 'Science: Environmental Science',

    },

    {

        id: '771f52c8-85c3-4a79-b619-8cbe5e2149a1',

        name: 'Science: Epidemiology',

    },

    {

        id: 'e7d102b6-1994-4a9e-8049-16b6f2972bb2',

        name: 'Science: Forensic Science',

    },

    { id: 'f6d58219-f0ca-4479-86e3-866e3d2236a7', name: 'Science: Geology' },

    {

        id: 'c60e6d07-4719-4b7f-9fb6-57a023bdc4e9',

        name: 'Science: Health and Medicine',

    },

    {

        id: 'cc32f607-55c2-4b93-9e2d-2d6c8bea1f9c',

        name: 'Science: High School Science',

    },

    {

        id: 'b6fc0136-f660-4c15-b9d1-e14dacef1db2',

        name: 'Science: Kinesiology',

    },

    {

        id: 'f31f72d0-220f-4462-be59-dcbcc57dea3c',

        name: 'Science: Mechanical Engineering',

    },

    {

        id: '52d560dc-1e90-40ac-8618-20a1cc374a85',

        name: 'Science: Medical Coding',

    },

    {

        id: 'b8c31b9c-ae06-4510-8456-1ebeb99a9046',

        name: 'Science: Microbiology',

    },

    {

        id: 'b2711c43-76d5-4ec9-a5ef-3373b3c2eb61',

        name: 'Science: Middle School Science',

    },

    {

        id: 'b8f0fb4e-379e-4efb-ada2-c2388a9bd93e',

        name: 'Science: Molecular and Cellular Biology',

    },

    {

        id: '45507569-e863-4459-8c2a-71cec6e86e9a',

        name: 'Science: Neuroscience',

    },

    { id: '105c01de-a21a-4551-a76d-1fab6392c4b0', name: 'Science: Nursing' },

    { id: '2c828570-0b61-4b26-b6dd-d88f13f94ef4', name: 'Science: Nutrition' },

    {

        id: '8c7e2122-1d51-47d1-b4b7-dea1de8dd8fd',

        name: 'Science: Organic Chemistry',

    },

    {

        id: 'b986763f-1305-4d57-a918-3011629ab905',

        name: 'Science: Pharmacology',

    },

    {

        id: '1da63cff-f308-4844-90c7-197c295c5537',

        name: 'Science: Physical Science',

    },

    { id: '17326d9e-0cf4-422f-b60b-65fc5e698e30', name: 'Science: Physics' },

    { id: '67c9f0cc-8d4e-415a-824d-068d1020889a', name: 'Science: Physiology' },

    { id: '532ffa08-b222-4b81-b46c-f4afecfb2c7a', name: 'Science: Zoology' },

    { id: '7069c1f7-fc11-44f6-97ff-d904bcc615b4', name: 'Social Work' },

    { id: '27bea1a9-afea-4298-83cc-fd564e70aa8b', name: 'Sociology' },

    { id: '03b5fb20-5cb3-11ec-bf63-0242ac130002', name: 'Special Needs: ADD' },
    { id: '2adf8230-c955-4e25-bd1b-fa0051f77795', name: 'Special Needs: ADHD' },
    { id: '1aef8bc5-eb17-416e-b508-5ebb319555b2', name: 'Special Needs: Autism Spectrum Disorder (ASD)' },
    { id: 'a9821191-f600-4683-aecc-b336d0e66bd0', name: 'Special Needs: Dyslexia' },
    { id: 'ffc5199f-b3a7-4a31-955a-9e59b2269987', name: 'Special Needs: Hearing Impaired' },
    { id: '9809d112-c060-47af-86ca-08bc7d608474', name: 'Special Needs: Homeschool Support' },
    { id: '54e81aeb-1c89-494d-8ec6-df284d1e16e2', name: 'Special Needs: Organization & Team Management' },
    { id: 'bde91e5e-1606-4873-86ab-d4af8b7abf4f', name: 'Special Needs: Sign Language' },

    {

        id: 'f8c7786d-b66e-4e49-a372-c92b101f69f5',

        name: 'Women and Gender Studies',

    },

    { id: '03b5dee2-5cb3-11ec-bf63-0242ac130002', name: 'English: Middle School ELA' },
    { id: '03b5e298-5cb3-11ec-bf63-0242ac130002', name: 'English: High School ELA' },
    { id: '03b5e388-5cb3-11ec-bf63-0242ac130002', name: 'College-Level English' },
    { id: '03b5e46e-5cb3-11ec-bf63-0242ac130002', name: 'English: African American Literature' },
    { id: '03b5e540-5cb3-11ec-bf63-0242ac130002', name: 'English: College Essays' },
    { id: '03b5e612-5cb3-11ec-bf63-0242ac130002', name: 'English: Comparative Literature' },
    { id: '03b5e6e4-5cb3-11ec-bf63-0242ac130002', name: 'English: Essay Writing' },
    { id: '03b5e7ac-5cb3-11ec-bf63-0242ac130002', name: 'English: Creative Writing' },
    { id: '03b5e888-5cb3-11ec-bf63-0242ac130002', name: 'English: Grammar' },
    { id: '03b5eb80-5cb3-11ec-bf63-0242ac130002', name: 'English: Handwriting' },
    { id: '03b5ec5c-5cb3-11ec-bf63-0242ac130002', name: 'English: Journalism' },
    { id: '03b5ed2e-5cb3-11ec-bf63-0242ac130002', name: 'English: Literature' },
    { id: '03b5ee00-5cb3-11ec-bf63-0242ac130002', name: 'English: Proofreading' },
    { id: '03b5eed2-5cb3-11ec-bf63-0242ac130002', name: 'English: Phonics' },
    { id: '03b5efa4-5cb3-11ec-bf63-0242ac130002', name: 'English: Public Speaking' },
    { id: '03b5f06c-5cb3-11ec-bf63-0242ac130002', name: 'English: Reading' },
    { id: '03b5f38c-5cb3-11ec-bf63-0242ac130002', name: 'English: Writing' },
    { id: '03b5f47c-5cb3-11ec-bf63-0242ac130002', name: 'English: Shakespeare' },
    { id: '03b5f54e-5cb3-11ec-bf63-0242ac130002', name: 'English: Speechwriting' },
    { id: '03b5f620-5cb3-11ec-bf63-0242ac130002', name: 'English: Vocabulary' },
    { id: '62acab18-84c4-11ec-a8a3-0242ac120002', name: 'Robotics' }
];

export default subjectsList
