import React from "react"
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { CardView } from "../../../../components/card/CardView"
import { navigatetoUrl,navigatetoUrlWithProps } from "../../../../utils/helper/helper"
import { useHistory } from "react-router-dom";
import Button from "components/button/Button";
import { Grid } from "@material-ui/core";
import CollegeCouncelingSvgIcon from "../../../../assets/images/svgIcons/CollegeCouncelingSvgIcon"
import colors from "assets/colors";
import { useTranslation } from 'react-i18next';



const LessonCard = (): JSX.Element => {
    const history = useHistory()
    const {t} = useTranslation()
    return (
        <div className="college-counseling-card" onClick={() => {
            navigatetoUrl(history, "/panel/lessons")
        }}>
            <CardView overFlowChildStyle className="navigation-card"
                style={{ position: "relative" }}
            >
                <Grid container className="card-content">
                    <Grid item xl={3} lg={3} md={12} sm={3} >
                        <CollegeCouncelingSvgIcon height="84" width="72" iconColor={colors.darkMango} />
                    </Grid>
                    <Grid item xl={9} lg={9} md={12} sm={9}>
                            <div className="pb-3">
                            <p className="title">{t('subscribe-college-counselling-guide')}</p>
                        <p className="sub-title">It's never too early to start planning for college! Read our college counseling lessons and use the College Organizer to get organized!</p>
                            </div>
                          <div className="navigation-button-container">
                        <Button className="dashboard-college-counselig-button" title={"Subscribe Now"} clickAction={() => {
                            navigatetoUrl(history, "/panel/lessons")
                        }} />
                    </div>
                        <div className="bottom-content">
                            <div className="nav-footer">
                                <div className="goto-arrow">
                                    <ArrowForwardIosIcon></ArrowForwardIosIcon>
                                </div>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </CardView>
        </div>
    )
}
export default LessonCard
