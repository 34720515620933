import { messageConstant } from "../../constant"

const initialState = {
    message: undefined,
    archived: undefined,
    isLoading: false,
}

const messageReducer = (state = initialState, action: any): object => {
    switch (action.type) {
        case messageConstant.GET_ALL_CONVERSATION_LIST_REQUEST:
            return {
                isLoading: true,
            }
        case messageConstant.GET_ALL_CONVERSATION_LIST_SUCCESS:
            return {
                message: action.messageList.messages,
                archived: action.messageList.archived,
                isLoading: false,
            }
        case messageConstant.GET_ALL_CONVERSATION_LIST_FAILURE:
            return {
                message: undefined,
                archived: undefined,
                isLoading: false,
            }
        default:
            return state
    }
}

export default messageReducer
