import LoaderSpinner from "components/loader/LoaderSpinner"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import moment from "moment"
import { Card,Grid } from "@material-ui/core"



const HistoryTable = ({ history, isLoading, hasMore, loadHistory }: { history: any, isLoading: boolean, hasMore: boolean, loadHistory: any }): JSX.Element => {
    const [expanded, setExpanded] = useState([false])
    const setExpandedArray = (index: number, value: boolean) => {
        let newExpanded = [...expanded]
        newExpanded[index] = value
        setExpanded(newExpanded)
    }
    const { t } = useTranslation();

    return (
        <>
            <table className="tutor-payment-history payment-history-table">
                <tr>
                    <th>{t("date")}</th>
                    <th>{t("amount-charged")}</th>
                    <th>{t("amount-earned")}</th>
                    <th>{t("details")}</th>
                </tr>
                {history?.map((payment: any, index: any) => {
                    const sessionTime = moment(payment.startTime).format("MM/DD/YYYY")
                    const totalSessionLength = (payment.sessionLength + (payment?.extensionTime ? payment.extensionTime : 0)) / 60
                    const amountCharged = Math.round((totalSessionLength * payment.teacherHourlyRate)*100)/100
                    const amountEarned = Math.round((amountCharged * 0.75)*100)/100
                    const sessionLengthDetails = (payment.sessionLength / 60) + 'h' + (payment?.extensionTime ? ' + ' + (payment.extensionTime / 60) + 'h extension' : '')
                    return (
                        <>
                            <tr className={expanded[index] ? "no-border" : ""} onClick={() => setExpandedArray(index, !expanded[index])}>
                                <td>{sessionTime}</td>
                                <td>{t('usd')} {amountCharged}</td>
                                <td>{t('usd')} {amountEarned}</td>
                                <td>
                                    {expanded[index] ?
                                        <ExpandLessIcon />
                                        :
                                        <ExpandMoreIcon />
                                    }
                                </td>
                            </tr>
                            {expanded[index] &&
                                <tr className="session-details">
                                    <td colSpan={2} className="date"><b>{t("session")} {t("date")}</b> {sessionTime}</td>
                                    <td colSpan={2} className="length"><b>{t("lenght")}</b> {sessionLengthDetails}</td>
                                </tr>
                            }
                        </>
                    )
                })}
            </table>
            <div className="payment-history-cards-container">
            {history?.map((payment: any, index: any) => {
                    const sessionTime = moment(payment.startTime).format("MM/DD/YYYY")
                    const totalSessionLength = (payment.sessionLength + (payment?.extensionTime ? payment.extensionTime : 0)) / 60
                    const amountCharged = Math.round((totalSessionLength * payment.teacherHourlyRate)*100)/100
                    const amountEarned = Math.round((amountCharged * 0.75)*100)/100
                    const sessionLengthDetails = (payment.sessionLength / 60) + 'h' + (payment?.extensionTime ? ' + ' + (payment.extensionTime / 60) + 'h extension' : '')
            return (
               <div className="card payment-card p-3">

                   <Grid xs={12} className="d-flex py-1">
                        <Grid xs={6}>
                        <b>{t("date")}</b>
                        </Grid>
                        <Grid xs={6} className="payment-text-end">
                        <span className="payment-grey-text">{sessionTime}</span>
                        </Grid>
                   </Grid>
                   <Grid xs={12} className="d-flex py-1">
                        <Grid xs={6}>
                        <b>{t("amount-charged")}</b>
                        </Grid>
                        <Grid xs={6} className="payment-text-end">
                        <span className="payment-grey-text">{t('usd')} {amountCharged}</span>
                        </Grid>
                   </Grid>
                   <Grid xs={12} className="d-flex py-1">
                        <Grid xs={6}>
                        <b>{t("amount-earned")}</b>
                        </Grid>
                        <Grid xs={6} className="payment-text-end">
                        <span className="payment-grey-text">{t('usd')} {amountEarned}</span>
                        </Grid>
                   </Grid>
                   <Grid xs={12} className="d-flex py-1">
                        <Grid xs={6} >
                        <b>{t("details")}</b>
                        </Grid>
                        <Grid xs={6} className="payment-text-end">
                        <div className="payment-detail-dropdown-span"> {expanded[index] ?
                                        <ExpandLessIcon />
                                        :
                                        <ExpandMoreIcon />
                                    }</div>
                        </Grid>
                   </Grid>
                   
                   {expanded[index] &&
                        <Grid xs={12}>
                            <table>
                            <tr>
                                    <td colSpan={2} className="date"><b>{t("session")} {t("date")}</b> {sessionTime}</td>
                                    <td colSpan={2} className="length"><b>{t("lenght")}</b> {sessionLengthDetails}</td>
                            </tr>
                            </table>
                        </Grid> }
               </div>
             
                    
            )
        })
    }
</div>
            {isLoading ?
                <LoaderSpinner />
                :
                hasMore && <p className="show-more" onClick={() => loadHistory(false)}>{t("show-more")}</p>
            }
        </>
    )
}

export default HistoryTable
