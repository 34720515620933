import React from "react"
import interfaceConstant from "../../../utils/constant/interfaceConstant"

class SortDescendSvgIcon extends React.PureComponent<interfaceConstant.SvgIconProps> {
    render(): JSX.Element {
        const { width, height, iconColor, ...otherProps } = this.props
        return (
            <svg width={width} height={height} viewBox="0 0 24 24" {...otherProps}>
                <g fill="none" fillRule="evenodd">
                    <path d="M0 0h23.998v23.998H0z" />
                    <path d="M0 0h23.998v23.998H0z" />
                    <path
                        d="M5.77023911,4.25386348 L9.44641292,8.67345074 C9.6809995,8.9591356 9.64903279,9.43243439 9.37903688,9.68667254 C9.11199175,9.94091078 8.67822784,9.90253519 8.44364123,9.61365233 L5.96941642,6.59104269 L5.96941642,18.6718874 C5.96941642,19.068968 5.66745378,19.3962264 5.30106589,19.3962264 C4.934678,19.3962264 4.63271536,19.068968 4.63271536,18.6718874 L4.63271536,6.59104269 L2.15849055,9.61365233 C1.89193722,9.90253519 1.49014002,9.94091078 1.22358669,9.68667254 C0.957033348,9.39778978 0.921624048,8.96233359 1.15621066,8.67345074 L4.79992594,4.25386348 C5.07287262,3.88822952 5.55532433,3.94366097 5.77023911,4.25386348 Z M17.9484277,16.6792453 C18.2061606,16.6792453 18.4150943,16.9688724 18.4150943,17.3261456 C18.4150943,17.6834187 18.2061606,17.9730458 17.9484277,17.9730458 L11.881761,17.9730458 C11.6240281,17.9730458 11.4150943,17.6834187 11.4150943,17.3261456 C11.4150943,16.9688724 11.6240281,16.6792453 11.881761,16.6792453 L17.9484277,16.6792453 Z M18.881761,13.0566038 C19.1763128,13.0566038 19.4150943,13.3462309 19.4150943,13.703504 C19.4150943,14.0607772 19.1763128,14.3504043 18.881761,14.3504043 L11.9484277,14.3504043 C11.6538758,14.3504043 11.4150943,14.0607772 11.4150943,13.703504 C11.4150943,13.3462309 11.6538758,13.0566038 11.9484277,13.0566038 L18.881761,13.0566038 Z M18.881761,9.43396227 C19.1763128,9.43396227 19.4150943,9.72358939 19.4150943,10.0808625 C19.4150943,10.4381357 19.1763128,10.7277628 18.881761,10.7277628 L11.9484277,10.7277628 C11.6538758,10.7277628 11.4150943,10.4381357 11.4150943,10.0808625 C11.4150943,9.72358939 11.6538758,9.43396227 11.9484277,9.43396227 L18.881761,9.43396227 Z M21.8912848,5.35849057 C22.1805768,5.35849057 22.4150943,5.64811768 22.4150943,6.00539084 C22.4150943,6.36266399 22.1805768,6.6522911 21.8912848,6.6522911 L11.9389039,6.6522911 C11.6496118,6.6522911 11.4150943,6.36266399 11.4150943,6.00539084 C11.4150943,5.64811768 11.6496118,5.35849057 11.9389039,5.35849057 L21.8912848,5.35849057 Z"
                        fill={iconColor}
                    />
                </g>
            </svg>
        )
    }
}

export default SortDescendSvgIcon
