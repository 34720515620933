import { PaymentConstant } from "../constant"

const InitialState = {
    token: "",
    closeModal: false,
    message: "",
    type: "",
    openSnackbar: false,
    isLoading: false,
    plans: [],
    plan: {},
    subscribing: false,
    alreadySubscribed: false,
    showAddCardModal: false,
    showDeleteCardModal: false,
    subscribed: false,
    planNotFound: false,
    showDeleteCardResponse: false 
}

const paymentReducer = (state = InitialState, action: any) => {
    switch (action.type) {
        case PaymentConstant.ADD_CARD_DETAILS: {
            return { ...state, ...action.payload, isLoading: true }
        }
        case PaymentConstant.CARD_DETAILS_ADDED: {
            if (action.payload.data.message.code === "card_declined") {
                return {
                    ...state,
                    closeModal: true,
                    message: "Card was declined",
                    type: "error",
                    openSnackbar: true,
                    isLoading: false,
                    showAddCardModal: false
                }
            }
            return {
                ...state,
                closeModal: true,
                message: "Card added successfully",
                type: "success",
                openSnackbar: true,
                isLoading: false,
                showAddCardModal: false
            }
        }
        case PaymentConstant.HIDE_TOAST_CONSTANT: {
            return { ...state, openSnackbar: false }
        }
        case PaymentConstant.ADD_CARD_FAILED: {
            return {
                ...state,
                closeModal: true,
                message: action.error.message,
                type: "error",
                openSnackbar: true,
                isLoading: false,
            }
        }
        case PaymentConstant.GET_LESSONS_PLANS: {
            return {
                ...state,
                planNotFound: false,
                plans: action.payload
            }
        }
        case PaymentConstant.FIND_PLANS_BY_PLAN_NAME:

            return {
                ...state,
                planNotFound: false,
                isLoading: true,
            }
        case PaymentConstant.FIND_PLAN_SUCCESS:

            return {
                ...state,
                isLoading: false,
                planNotFound: false,
                plan: action.payload.plan,
            }
        case PaymentConstant.FIND_PLAN_FAILURE:

            return {
                ...state,
                isLoading: false,
                planNotFound: true,
            }
        case PaymentConstant.ALREADY_SUBSCRIBED:
            return {
                ...state,
                alreadySubscribed: action.payload
            }
        case PaymentConstant.DELETE_CARD: {
            return { ...state, isLoading: true }
        }
        case PaymentConstant.TOGGLE_ADD_CARD_MODAL: {
            return { ...state, showAddCardModal: action.payload.showAddCardModal }
        }
        case PaymentConstant.TOGGLE_DELETE_CARD_MODAL: {
            return { ...state, showDeleteCardModal: action.payload.showDeleteCardModal }
        }
        case PaymentConstant.HIDE_DELETE_CARD_RESPONSE_MODAL: {
            return { ...state, showDeleteCardResponse: false }
        }
        case PaymentConstant.DELETE_CARD_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                type: "success",
                openSnackbar: true,
                message: "card deleted successfully",
                showDeleteCardModal: false
            }
        }
        case PaymentConstant.DELETE_CARD_FAILURE: {
            return {
                ...state,
                isLoading: false,
                message: action.payload.data.data,
                showDeleteCardModal: false,
                showDeleteCardResponse: true
            }
        }
        case PaymentConstant.CALL_PAYMENT_METHOD: {
            return {
                ...state,
                subscribing: true
            }
        }
        case PaymentConstant.SUBSCRIPTION_REQUEST_SUCCESS: {
            return {
                ...state,
                subscribing: false,
                subscribed: true
            }
        }
        case PaymentConstant.RESET_SUBSCRIBED: {
            return {
                ...state,
                subscribed: false
            }
        }
        default:
            return state
    }
}

export default paymentReducer