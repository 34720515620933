import React, { useEffect } from "react"
import { useDispatch } from "react-redux"
import { Route, RouteProps } from "react-router-dom"
import { logOutRequest } from "../store/actions/userActions"


interface OnboardingRouteProps extends RouteProps {
    component: any
}

const AuthenticationRoute = (props: OnboardingRouteProps): JSX.Element => {

    const { component: Component, ...rest } = props;
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(logOutRequest())
    }, []);

    return (
        <Route
            {...rest}
            render={(routeProps) =>
                <Component {...routeProps} />
            }
        />
    )
}
export default AuthenticationRoute
