import React from "react";
import StdTutorAcceptRequestContainer from "./popups/StdTutorAcceptRequestContainer";
import TutorRejectSessionContainer from "./popups/TutorRejectSessionContainer";
import StdWhenTutorAcceptRequestContainer from "./popups/StdWhenTutorAcceptRequestContainer";
import StdWhenTutorAcceptSomeOfMyRequestContainer from "./popups/StdWhenTutorAcceptSomeOfMyRequestContainer";
import StdTutorDoesntProcessMyRequestContainer from "./popups/StdTutorDoesntProcessMyRequestContainer";
import TutorNotConfirmedRescheduledReqContainer from "./popups/Rescheduling/TutorNotConfirmedRescheduledReqContainer"
import RescheduleRequestSentContainer from "./popups/Rescheduling/RescheduleRequestSentContainer"
import TutorRescheduleRequestContainer from './popups/Rescheduling/TutorRescheduleRequestContainer'


export function StudentBookingPopupContainer(props: any) {
    return (
        <div>
            <StdTutorAcceptRequestContainer />
            <TutorRejectSessionContainer />
            <StdWhenTutorAcceptRequestContainer />
            <StdWhenTutorAcceptSomeOfMyRequestContainer />
            <StdTutorDoesntProcessMyRequestContainer />
            <TutorNotConfirmedRescheduledReqContainer />
            <RescheduleRequestSentContainer />
            <TutorRescheduleRequestContainer />
        </div>)
}