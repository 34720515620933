import React, { useState, memo, useMemo, useCallback } from "react"
import { Grid, Button as CardButton, Card, CardHeader, Avatar, Chip } from "@material-ui/core"
import { Alert } from "@material-ui/lab";
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import { useFormik } from "formik"
import * as Yup from "yup"
import { applicationConstant } from "../../../../../../utils/constant/applicationConstant"
import InterfaceConstant from "../../../../../../utils/constant/interfaceConstant"
import { InputBox } from "../../../../../../components/input-field/InputFields"
import { countryList, currencyList, accountTypes } from "../../../../../../utils/constant/paymentList"
import Button from "../../../../../../components/button/Button"
import { useDispatch, useSelector } from "react-redux"
import _ from "lodash"
import MessageAlert from "../../../../../../components/message-alert/MessageAlert"
import { useStripe, Elements } from "@stripe/react-stripe-js"
import { stripe } from "../../../../../../utils/helper/stripe-utils"
import { attachTutorToAccount, getStripeAccountLinkUrl, getStripeConnectedAccount } from "../../../../../../utils/services/paymentServices"
import { getUserWithDetails } from "../../../../../../utils/services/userServices"
import { loginSuccess } from "../../../../../../store/actions/userActions"
import SimpleDropdown from "../../../../../../components/dropdown/SimpleDropdown"
import { DatePicker } from "@material-ui/pickers"
import { DateFormats, formatDate } from "utils/helper/date-utils"
import { setTeacherMyprofile } from "store/actions/TutorSignUpActions"
import { updateUser } from "../../../../../../utils/helper/firebase"
import PhoneNumberInput from "components/phone-number-input/PhoneNumberInput"
import parsePhoneNumber, { isValidPhoneNumber } from "libphonenumber-js"
import useStripeConnectedAccount from "utils/hooks/useStripeConnectedAccount"
import LoaderSpinner from "components/loader/LoaderSpinner";
import "./bank-account-info.scss";
import { useMediaQuery } from "react-responsive";
import { targetForRedirection } from "utils/helper/helper";

const accountHolderTypes = [
    { id: "company", name: "Company Account" },
    { id: "individual", name: "Individual Account" },
]

const dateFormatter = (datemmddyyyy: string) => {
    const elements = datemmddyyyy?.split("/")
    return elements[2] + "-" + elements[0] + "-" + elements[1]
}

interface User {
    firstName: string
    lastName: string
    phoneNumber: string
    dateOfBirth: string
    bankName: string
    accountHolderName: string
    accountNumber: string
    confirmAccountNumber: string
    routingNumber: string
    addressLine1: string
    addressLine2: string
    addressCity: string
    addressState: string
    addressCountry: string
    addressPostalCode: string
    id_number: string
}
interface Props {
    onSuccess: () => void
}
const UserPaymentForm = ({ onSuccess }: Props): JSX.Element => {
    const dispatch = useDispatch()
    const stripeObject = useStripe()
    const appLabels: InterfaceConstant.IappLabels = applicationConstant.appLabels
    const appErrorLabels: InterfaceConstant.IappErrorLabels = applicationConstant.appErrorLabels
    const regexExpression: InterfaceConstant.IregexExpression = applicationConstant.regexExpression
    const userData = useSelector((state: any) => state.auth.userAuthData)

    const [openSnackBar, setOpenSnackBar] = useState(false)
    const [formSubmitting, setFormSubmitting] = useState(false)
    const [snackBarData, setSnackBarData] = useState({ type: "success", message: "" })
    const [submitButtonLoading, setSubmitButtonLoading] = useState(false)
    const [phoneNumberError, setPhoneNumberError] = useState(!isValidPhoneNumber(`${userData?.phoneNumber?.countryCode}${userData?.phoneNumber?.number}`));
    const [phoneNumber, setPhoneNumber] = useState({
        number: userData?.phoneNumber?.number,
        countryCode: userData?.phoneNumber?.countryCode,
        countryPrefix: userData?.phoneNumber?.countryPrefix
    });
    const [bankLocationInputProps, setBankLocationInputProps] = useState({
        error: appErrorLabels.GLOBAL_REQUIRED,
        value: { name: "United States", id: "US" },
    })
    const [bankCountry, setbankCountry] = useState({
        error: appErrorLabels.GLOBAL_REQUIRED,
        value: { name: "United States", id: "US" },
    })
    const [isShowBankLocationError, setShowBankLocationError] = useState(false)

    const [bankCurrencyInputProps, setBankCurrencyInputProps] = useState({
        error: appErrorLabels.GLOBAL_REQUIRED,
        value: { id: "USD", name: "United States Dollar" },
    })
    const [isShowBankCurrencyError, setShowBankCurrencyError] = useState(false)

    const [accountTypeInputProps, setAccountTypeInputProps] = useState({
        error: appErrorLabels.GLOBAL_REQUIRED,
        value: { id: "individual", name: "Individual Account" },
    })
    const [isShowAccountTypeError, setShowAccountTypeError] = useState(false)

    const {
        connectedAccount,
        externalAccounts
    } = useStripeConnectedAccount();

    const currentData = useMemo(() => {
        const bankAccount = externalAccounts.filter((ac: any) => ac.default_for_currency).pop();
        const individual = connectedAccount?.individual;
        return {
            userId: connectedAccount?.metadata?.userId || userData?.userId,
            firstName: userData?.firstName || "",
            lastName: userData?.lastName || "",
            email: connectedAccount?.email || userData?.email,
            phoneNumber: userData?.phoneNumber?.number || "",
            dateOfBirth: userData?.dateOfBirth || "",
            bankName: bankAccount?.bank_name || "",
            accountHolderName: bankAccount?.account_holder_name || "",
            accountNumber: bankAccount?.last4 ? "xxxxxxxx" + bankAccount.last4 : "",
            confirmAccountNumber: bankAccount?.last4 ? "xxxxxxxx" + bankAccount.last4 : "",
            routingNumber: bankAccount?.routing_number || "",
            addressLine1: individual?.address?.line1 || "",
            addressLine2: individual?.address?.line2 || "",
            addressCity: individual?.address?.city || "",
            addressState: individual?.address?.state || "",
            addressCountry: individual?.address?.country || bankCountry.value.id,
            addressPostalCode: individual?.address?.postal_code || "",
            id_number: connectedAccount.id || "",
        };

    }, [connectedAccount, userData, externalAccounts, bankCountry]);

    const handleDateOfBirth = (e: any): void => {
        const dateFormated = formatDate(e, DateFormats.EUA_DATE)
        formik.setFieldValue("dateOfBirth", dateFormated, true)
        dispatch(setTeacherMyprofile({ dateOfBirth: dateFormated }))
    }
    const d = new Date()
    d.setFullYear(d.getFullYear() - 5)

    const handlePhoneNumber = (value: string) => {
        const phoneNumber = parsePhoneNumber(value);
        setPhoneNumberError(!isValidPhoneNumber(value));
        const { countryCallingCode = '1', nationalNumber = '0', country = 'US' } = phoneNumber || {};
        setPhoneNumber({ countryCode: "+" + countryCallingCode, number: nationalNumber, countryPrefix: country?.toUpperCase() });
    }

    const navigateNextPage = async (formikObject: any) => {
        await formikObject.validateForm()
        const values = _.some(formikObject.values, (item: any) => item === "")
        const errors = formikObject.errors
        let valuesError = false
        Object.values(errors).map((value) => (value ? (valuesError = true) : ""))

        if (bankLocationInputProps.value.name.length === 0 || values || valuesError) {
            setShowBankLocationError(true)
        }
        if (bankCurrencyInputProps.value.name.length === 0 || values || valuesError) {
            setShowBankCurrencyError(true)
        }
        if (accountTypeInputProps.value.name.length === 0 || values || valuesError) {
            setShowAccountTypeError(true)
        }
        if (bankLocationInputProps.value.name.length === 0 || values || valuesError) {
            setShowBankCurrencyError(true)
        }
        if (!valuesError) {
            formikObject.handleSubmit(values)
        }
        return isShowBankLocationError || isShowBankCurrencyError || isShowAccountTypeError
    }

    const formik = useFormik({
        initialValues: {
            ...currentData,
        },
        validationSchema: Yup.object().shape({
            firstName: Yup.string()
                .matches(/^[\\a-zA-Zp{L}.'-]+$/, 'English alphabets and .\'- are allowed only')
                .required(appErrorLabels.GLOBAL_REQUIRED),
            lastName: Yup.string().matches(/^[\\a-zA-Zp{L} .'-]*$/, 'Blank space, English alphabets and .\'- are allowed only')
                .required(appErrorLabels.GLOBAL_REQUIRED),
            dateOfBirth: Yup.date()
                .typeError(appErrorLabels.GLOBAL_ISSUING_DATE_INVALID)
                .required(appErrorLabels.GLOBAL_REQUIRED),
            bankName: Yup.string()
                .max(50, appErrorLabels.GLOBAL_MAX_50)
                .required(appErrorLabels.GLOBAL_REQUIRED),
            accountHolderName: Yup.string()
                .max(50, appErrorLabels.GLOBAL_MAX_50)
                .required(appErrorLabels.GLOBAL_REQUIRED),
            accountNumber: Yup.number()
                .typeError(appErrorLabels.GLOBAL_ACCOUNT_NUMBER)
                .min(2)
                .typeError(appErrorLabels.GLOBAL_ROUTING_NUMBERS)
                .required(appErrorLabels.GLOBAL_REQUIRED)
                .test(
                    "len",
                    appErrorLabels.GLOBAL_MAX_25,
                    (val) => Math.ceil(Math.log10(val + 1)) <= 25,
                ),
            confirmAccountNumber: Yup.number()
                .typeError(appErrorLabels.GLOBAL_ACCOUNT_NUMBER)
                .min(2)
                .typeError(appErrorLabels.GLOBAL_ROUTING_NUMBERS)
                .required(appErrorLabels.GLOBAL_REQUIRED)
                .test(
                    "len",
                    appErrorLabels.GLOBAL_MAX_25,
                    (val) => Math.ceil(Math.log10(val + 1)) <= 25,
                )
                .oneOf([Yup.ref("accountNumber"), null], appErrorLabels.GLOBAL_ACCOUNT_NOT_MATCH),
            routingNumber: Yup.string()
                .required(appErrorLabels.GLOBAL_REQUIRED)
                .matches(
                    regexExpression.ROUTING_NUMBER_VALIDATION,
                    appErrorLabels.GLOBAL_EXACTLY_9_NUMBERS,
                ),
            id_number: Yup.string()
                .required(appErrorLabels.GLOBAL_REQUIRED)
                .matches(regexExpression.SSN_VALIDATION, appErrorLabels.GLOBAL_EXACTLY_9_NUMBERS),
            addressLine1: Yup.string().required(appErrorLabels.GLOBAL_REQUIRED),
            addressLine2: Yup.string(),
            addressCity: Yup.string().required(appErrorLabels.GLOBAL_REQUIRED),
            addressState: Yup.string().required(appErrorLabels.GLOBAL_REQUIRED),
            addressCountry: Yup.string().required(appErrorLabels.GLOBAL_REQUIRED),
            addressPostalCode: Yup.string().required(appErrorLabels.GLOBAL_REQUIRED),
        }),
        onSubmit: async (values: User) => {
            if (phoneNumberError) {
                setSnackBarData({
                    message: "Please enter a valid Phone Number.",
                    type: "error",
                })
                setOpenSnackBar(true)
                setSubmitButtonLoading(false)
                return false
            }
            setSubmitButtonLoading(true)
            const addressObject = {
                line1: values.addressLine1,
                line2: values.addressLine2,
                city: values.addressCity,
                state: values.addressState,
                country: bankCountry.value.id,
                postal_code: values.addressPostalCode,
            }
            const data = connectedAccount ? {
                email: currentData.email,
                accountId: connectedAccount.id,
                address: addressObject,
                dateOfBirth: dateFormatter(values.dateOfBirth),
                id_number: values.id_number,
                phone: phoneNumber.countryCode + phoneNumber.number,
                firstName: values.firstName,
                lastName: values.lastName,
            } : {
                email: currentData.email,
                address: addressObject,
                dateOfBirth: dateFormatter(values.dateOfBirth),
                id_number: values.id_number,
                phone: phoneNumber.countryCode + phoneNumber.number,
                firstName: values.firstName,
                lastName: values.lastName,
            }

            const userDBData = {
                firstName: values.firstName,
                lastName: values.lastName,
                dateOfBirth: values.dateOfBirth,
                phoneNumber: phoneNumber,
            }
            try {
                await updateUser(userData.userId, userDBData)
                //await getUserWithDetails(tutorProfileInfo.userId).then((data: any) => {
            } catch (error) {
                console.log(error)
            }
            setFormSubmitting(true)
            stripeObject
                ?.createToken("bank_account", {
                    country: bankLocationInputProps.value.id,
                    currency: bankCurrencyInputProps.value.id.toLowerCase(),
                    routing_number: values.routingNumber,
                    account_number: values.accountNumber,
                    account_holder_name: values.accountHolderName,
                    account_holder_type: accountTypeInputProps.value.id,
                })
                .then((token: any) => attachTutorToAccount(token, data))
                .then((response: any) => {
                    response.data.error
                        ? setSnackBarData({ type: "error", message: response.data.message })
                        : setSnackBarData({
                            type: "success",
                            message: "Payment details Updated Successfully",
                        })
                    if (!response.data.error) {
                        getUserWithDetails(currentData.userId).then((updatedData: any) => {
                            dispatch(loginSuccess(updatedData))
                        }).catch(console.error)
                    }
                    setOpenSnackBar(true)
                    setSubmitButtonLoading(false)
                    setFormSubmitting(false)
                    onSuccess();
                })
                .catch((err: any) => {
                    setSnackBarData({
                        type: "error",
                        message: "Unable To Update. Please verify entered details.",
                    })
                    setOpenSnackBar(true)
                    setSubmitButtonLoading(false)
                    setFormSubmitting(false)
                })
        },
    })

    const handleChange = (e: any): void => {
        const { name, value } = e.target
        formik.setFieldValue(name, value, true)
    }

    return (
        <>
            <MessageAlert
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
                type={snackBarData.type}
                message={snackBarData.message}
            />
            <form onSubmit={formik.handleSubmit} autoComplete="off" className="user-payment-form">
                <h3>Payment Details</h3>
                <Grid container direction="row" alignItems="flex-start">
                    <Grid item xs={12} sm={12} md={6} lg={6} className="user-name">
                        <InputBox
                            type={"text"}
                            title={appLabels.GLOBAL_FIRST_NAME}
                            placeholder={appLabels.FIRST_NAME_PLACEHOLDER}
                            inputProps={{
                                name: "firstName",
                                value: formik.values.firstName,
                                onChange: (e: any) => handleChange(e),
                                onBlur: formik.handleBlur,
                            }}
                            error={
                                formik.touched.firstName && formik.errors.firstName
                                    ? formik.errors.firstName
                                    : null
                            }
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} className="user-name">
                        <InputBox
                            type={"text"}
                            title={appLabels.GLOBAL_LAST_NAME}
                            placeholder={appLabels.LAST_NAME_PLACEHOLDER}
                            inputProps={{
                                name: "lastName",
                                value: formik.values.lastName,
                                onChange: (e: any) => handleChange(e),
                                onBlur: formik.handleBlur,
                            }}
                            error={
                                formik.touched.lastName && formik.errors.lastName
                                    ? formik.errors.lastName
                                    : null
                            }
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <div className="datepicker-wrapper p-15">
                            <label>{appLabels.GLOBAL_BIRTH_DATE}</label>
                            <DatePicker
                                format={DateFormats.EUA_DATE}
                                autoOk
                                inputVariant="standard"
                                variant="dialog"
                                className="datepicker-root"
                                value={formik.values.dateOfBirth}
                                InputProps={{
                                    fullWidth: true,
                                    label: appLabels.GLOBAL_BIRTH_DATE,
                                    placeholder: appLabels.BIRTH_DATE_PLACEHOLDER,
                                    value: formik.values.dateOfBirth,
                                    classes: { root: "mui-datepicker-input" },
                                    disableUnderline: true,
                                    onBlur: formik.handleBlur,
                                }}
                                maxDate={d}
                                InputLabelProps={{
                                    disableAnimation: true,
                                    shrink: false,
                                }}
                                helperText={
                                    formik.touched.dateOfBirth &&
                                    formik.errors.dateOfBirth &&
                                    appErrorLabels.GLOBAL_REQUIRED
                                }
                                onChange={(e: any) => handleDateOfBirth(e)}
                                error={
                                    formik.touched.dateOfBirth && formik.errors.dateOfBirth
                                        ? true
                                        : undefined
                                }
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <div className="phone-number-wrapper">
                            <PhoneNumberInput
                                className="phone-number"
                                defaultCountry={'us'}
                                onChange={handlePhoneNumber}
                                preferredCountries={['us']}
                                disableAreaCodes={true}
                                countryCodeEditable={true}
                                title="Phone Number"
                                variant="outlined"
                                inputProps={{
                                    style: {
                                        "height": "30px"
                                    }
                                }}
                                value={userData?.phoneNumber?.countryCode + userData?.phoneNumber?.number}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} className="first-column">
                        <br />
                        <SimpleDropdown
                            title={appLabels.GLOBAL_BANK_LOCATION}
                            placeholder={appLabels.BANK_LOCATION_PLACEHOLDER}
                            optionsList={countryList}
                            fieldDetails={bankLocationInputProps}
                            setProps={setBankLocationInputProps}
                            isShowError={false}
                            isDisabled={true}
                        />
                        <InputBox
                            type={"text"}
                            title={appLabels.GLOBAL_BANK_NAME}
                            placeholder={appLabels.BANK_NAME_PLACEHOLDER}
                            inputProps={{
                                name: "bankName",
                                value: formik.values.bankName,
                                onChange: (e: any) => handleChange(e),
                                onBlur: formik.handleBlur,
                            }}
                            error={
                                formik.touched.bankName && formik.errors.bankName
                                    ? formik.errors.bankName
                                    : null
                            }
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <br />
                        <SimpleDropdown
                            title={appLabels.GLOBAL_BANK_CURRENCY}
                            placeholder={appLabels.BANK_CURRENCY_PLACEHOLDER}
                            optionsList={currencyList}
                            fieldDetails={bankCurrencyInputProps}
                            setProps={setBankCurrencyInputProps}
                            isShowError={false}
                            isDisabled={true}
                        />
                        <InputBox
                            type={"text"}
                            title={appLabels.GLOBAL_HOLDER_NAME}
                            placeholder={appLabels.HOLDER_NAME_PLACEHOLDER}
                            inputProps={{
                                name: "accountHolderName",
                                value: formik.values.accountHolderName,
                                onChange: (e: any) => handleChange(e),
                                onBlur: formik.handleBlur,
                            }}
                            tooltip={appLabels.HOLDER_CARD_TOOLTIP}
                            error={
                                formik.touched.accountHolderName && formik.errors.accountHolderName
                                    ? formik.errors.accountHolderName
                                    : null
                            }
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <SimpleDropdown
                            title={appLabels.GLOBAL_ACCOUNT_TYPE}
                            placeholder={appLabels.ACCOUNT_TYPE_PLACEHOLDER}
                            optionsList={accountHolderTypes}
                            fieldDetails={accountTypeInputProps}
                            setProps={setAccountTypeInputProps}
                            isShowError={false}
                            isDisabled={true}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <InputBox
                            type={"text"}
                            title={appLabels.GLOBAL_ACCOUNT_NUMBER}
                            placeholder={appLabels.ACCOUNT_NUMBER_PLACEHOLDER}
                            inputProps={{
                                name: "accountNumber",
                                value: formik.values.accountNumber,
                                onChange: (e: any) => handleChange(e),
                                onBlur: formik.handleBlur,
                            }}
                            error={
                                formik.touched.accountNumber && formik.errors.accountNumber
                                    ? formik.errors.accountNumber
                                    : null
                            }
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <InputBox
                            type={"text"}
                            title={appLabels.GLOBAL_CONFIRM_ACCOUNT_NUMBER}
                            placeholder={appLabels.ACCOUNT_CONFIRM_NUMBER_PLACEHOLDER}
                            inputProps={{
                                name: "confirmAccountNumber",
                                value: formik.values.confirmAccountNumber,
                                onChange: (e: any) => handleChange(e),
                                onBlur: formik.handleBlur,
                            }}
                            error={
                                formik.touched.confirmAccountNumber &&
                                    formik.errors.confirmAccountNumber
                                    ? formik.errors.confirmAccountNumber
                                    : null
                            }
                        />
                    </Grid>

                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <InputBox
                            type={"text"}
                            title={appLabels.GLOBAL_ROUTING_NUMBER}
                            placeholder={appLabels.ROUTING_NUMBER_PLACEHOLDER}
                            inputProps={{
                                name: "routingNumber",
                                value: formik.values.routingNumber,
                                onChange: (e: any) => handleChange(e),
                                onBlur: formik.handleBlur,
                            }}
                            tooltip={appLabels.ROUTING_NUMBER_TOOLTIP}
                            error={
                                formik.touched.routingNumber && formik.errors.routingNumber
                                    ? formik.errors.routingNumber
                                    : null
                            }
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <InputBox
                            type={"password"}
                            title={appLabels.PAYMENTS_SSN}
                            placeholder={appLabels.PAYMENTS_SSN_PLACEHOLDER}
                            inputProps={{
                                name: "id_number",
                                value: formik.values.id_number,
                                onChange: (e: any) => handleChange(e),
                                onBlur: formik.handleBlur,
                            }}
                            tooltip={"SSN is not stored by us."}
                            error={
                                formik.touched.id_number && formik.errors.id_number
                                    ? formik.errors.id_number
                                    : null
                            }
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} className="address-details-heading">
                        <h4>Address Details</h4>
                    </Grid>

                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <InputBox
                            type={"text"}
                            title={appLabels.ADDRESS_LINE_1}
                            placeholder={appLabels.ADDRESS_LINE_1_PLACEHOLDER}
                            inputProps={{
                                name: "addressLine1",
                                value: formik.values.addressLine1,
                                onChange: (e: any) => handleChange(e),
                                onBlur: formik.handleBlur,
                            }}
                            error={
                                formik.touched.addressLine1 && formik.errors.addressLine1
                                    ? formik.errors.addressLine1
                                    : null
                            }
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <InputBox
                            type={"text"}
                            title={appLabels.ADDRESS_LINE_2}
                            placeholder={appLabels.ADDRESS_LINE_2_PLACEHOLDER}
                            inputProps={{
                                name: "addressLine2",
                                value: formik.values.addressLine2,
                                onChange: (e: any) => handleChange(e),
                                onBlur: formik.handleBlur,
                            }}
                            error={
                                formik.touched.addressLine2 && formik.errors.addressLine2
                                    ? formik.errors.addressLine2
                                    : null
                            }
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <InputBox
                            type={"text"}
                            title={appLabels.ADDRESS_CITY}
                            placeholder={appLabels.ADDRESS_CITY_PLACEHOLDER}
                            inputProps={{
                                name: "addressCity",
                                value: formik.values.addressCity,
                                onChange: (e: any) => handleChange(e),
                                onBlur: formik.handleBlur,
                            }}
                            error={
                                formik.touched.addressCity && formik.errors.addressCity
                                    ? formik.errors.addressCity
                                    : null
                            }
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <InputBox
                            type={"text"}
                            title={appLabels.ADDRESS_STATE}
                            placeholder={appLabels.ADDRESS_STATE_PLACEHOLDER}
                            inputProps={{
                                name: "addressState",
                                value: formik.values.addressState,
                                onChange: (e: any) => handleChange(e),
                                onBlur: formik.handleBlur,
                            }}
                            error={
                                formik.touched.addressState && formik.errors.addressState
                                    ? formik.errors.addressState
                                    : null
                            }
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        {/* <InputBox
                            type={"text"}
                            title={appLabels.ADDRESS_COUNTRY}
                            placeholder={appLabels.ADDRESS_COUNTRY_PLACEHOLDER}
                            inputProps={{
                                name: "addressCountry",
                                value: formik.values.addressCountry,
                                onChange: (e: any) => handleChange(e),
                                onBlur: formik.handleBlur,
                            }}
                            error={
                                formik.touched.addressCountry && formik.errors.addressCountry
                                    ? formik.errors.addressCountry
                                    : null
                            }
                        /> */}
                        <SimpleDropdown
                            title={appLabels.ADDRESS_COUNTRY}
                            placeholder={appLabels.ADDRESS_COUNTRY_PLACEHOLDER}
                            optionsList={countryList}
                            fieldDetails={bankCountry}
                            setProps={{ setbankCountry }}
                            isShowError={false}
                            isDisabled={true}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <InputBox
                            type={"text"}
                            title={appLabels.ADDRESS_POSTAL_CODE}
                            placeholder={appLabels.ADDRESS_POSTAL_CODE_PLACEHOLDER}
                            inputProps={{
                                name: "addressPostalCode",
                                value: formik.values.addressPostalCode,
                                onChange: (e: any) => handleChange(e),
                                onBlur: formik.handleBlur,
                            }}
                            error={
                                formik.touched.addressPostalCode && formik.errors.addressPostalCode
                                    ? formik.errors.addressPostalCode
                                    : null
                            }
                        />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12} className="submit-area">
                        <Button
                            isloading={submitButtonLoading}
                            title={"Save"}
                            clickAction={() => navigateNextPage(formik)}
                            isDisabled={formSubmitting || submitButtonLoading}
                        />
                    </Grid>
                </Grid>
            </form>
        </>
    )
}

interface WrapperProps {
    user: any
}
const BankAccountInfo = (props: WrapperProps) => {
    const [isEditable, setIsEditable] = useState<boolean>(false);
    const {
        isLoading,
        accountLink,
        dashboardLink,
        accountType,
        externalAccounts,
        alertData,
        payoutsEnabled,
        chargesEnabled,
        connectedAccount,
        createConnectedAccount
    } = useStripeConnectedAccount();

    const shouldOpenCustomForm = useMemo(() => {
        return (isEditable === true && accountType === "custom");
    }, [isEditable, accountType]);
    const addOrUpdateAccountInfo = useCallback(() => {
        if (accountType === "custom") {
            setIsEditable((prevValue) => !prevValue);
        } else {
            if (!accountLink) {
                createConnectedAccount();
            } else {
                window.open(accountLink, "_self");
            }
        }
    }, [accountType, accountLink]);
    const isSmallScreen = useMediaQuery({ query: '(max-device-width: 768px)' });
    const shouldAddPayoutAccount = !connectedAccount?.id;
    const shouldConfigurePayoutAccount = externalAccounts?.length <= 0;
    const shouldUpdatePayoutAccount = !!connectedAccount?.id;

    return (
        <div className="payout-account">
            {isLoading ? <LoaderSpinner /> :
                <Elements stripe={stripe}>
                    {!isLoading && <div className="header">
                        <p className="heading">Status</p>
                        <div className="account-statuses">
                            <div className="account-status">
                                <div className={payoutsEnabled === true ? "success" : "error"}></div>
                                <p>Payouts</p>
                            </div>
                            <div className="account-status">
                                <div className={chargesEnabled === true ? "success" : "error"}></div>
                                <p>Payments</p>
                            </div>
                        </div>
                        {!isSmallScreen && <div className="account-actions">
                            {shouldUpdatePayoutAccount === true && <Button title={`${shouldConfigurePayoutAccount ? "Configure" : "Update"} Payout Account`} clickAction={addOrUpdateAccountInfo} isOutlineStyle={true} />}
                            {accountType === "express" && dashboardLink && <Button title="Go to Stripe Dashboard" isOutlineStyle={true} clickAction={() => {
                                window.open(dashboardLink, targetForRedirection());
                            }} />}
                        </div>}
                    </div>}
                    {alertData && <Alert
                        severity={alertData.type}
                        className="payout-account-alert"
                        action={
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "flex-start", justifyContent: "flex-start" }}>
                                {alertData.actions.map((actionData) => <CardButton color="inherit" size="small" onClick={actionData.action} key={actionData.title}>
                                    {actionData.title}
                                </CardButton>)}
                            </div>
                        }
                    >
                        {alertData.message}
                    </Alert>}
                    {shouldOpenCustomForm &&
                        <>
                            <UserPaymentForm onSuccess={() => {
                                setIsEditable(false);
                            }} />
                        </>
                    }
                    {isEditable === false && <div className="payout-account-detail-section">
                        {externalAccounts.map((account: any) => {
                            const chips = [account.currency.toUpperCase(), account.default_for_currency === true && "Default", ...(account.available_payout_methods || [])].filter(v => !!v);
                            const AccountAvatar = account.object === "bank_account" ? AccountBalanceIcon : CreditCardIcon;
                            const title = account.object === "bank_account" ? account.bank_name : account.brand;
                            const subheading = account.object === "bank_account" ? `${account.routing_number} || **** ${account.last4}` : `${account.exp_month}/${account.exp_year} || **** **** **** ${account.last4}`;
                            return <Card key={account.id} variant="outlined" className="payout-account-detail-item">
                                <CardHeader
                                    avatar={
                                        <Avatar className="avatar">
                                            <AccountAvatar />
                                        </Avatar>
                                    }
                                    title={<div className="payout-account-item-title">
                                        <p className="title">{title}</p>
                                        {chips.map(chip => {
                                            return <Chip className="badges" key={chip} label={chip} color="default" size="small" />
                                        })}
                                    </div>}
                                    subheader={<p className="payout-account-item-subtitle">{subheading}</p>}
                                >

                                </CardHeader>
                            </Card>;
                        })}</div>}
                    <div className="add-payout-account">
                        {shouldAddPayoutAccount && <Button title="Add Payout Account" clickAction={addOrUpdateAccountInfo} />}
                        {isSmallScreen && <>
                            {shouldUpdatePayoutAccount === true && <Button title={`${shouldConfigurePayoutAccount ? "Configure" : "Update"} Payout Account`} clickAction={addOrUpdateAccountInfo} isOutlineStyle={true} />}
                            {accountType === "express" && dashboardLink && <Button title="Go to Stripe Dashboard" isOutlineStyle={true} clickAction={() => {
                                window.open(dashboardLink, targetForRedirection());
                            }} />}
                        </>}
                    </div>
                </Elements>}
        </div>
    )
}

export default memo(BankAccountInfo)