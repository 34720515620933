import React, { useState } from "react"
import { Container } from "reactstrap"
import { navigatetoUrl } from "../../../utils/helper/helper"
import { signupInformation } from "../../../store/actions/userActions"
import { useDispatch, useSelector } from "react-redux"
import { OnBoardingHeader } from "../../../components/on-boarding/OnboardingHeader"
import NavigationButtons from "../../../components/on-boarding/navigation-buttons/NavigationButtons"
import EducationalBackgroundForm from "./EducationalBackgroundForm"
import { useHistory } from "react-router-dom"
import OnboardingBackground from "../../../components/on-boarding/onboarding-background/OnboardingBackground"
import ProgressBar from "../../../components/progress-bar/ProgressBar"
import { useTranslation } from 'react-i18next';
const EducationalBackgroundPage = () => {
    const {t} = useTranslation()
    const infoPage = useSelector((state: any) => state.signup.options)
    const expectedGraduationYear = useSelector(
        (state: any) => state.signup.options.expectedGraduationYear,
    )
    const studentCurrentGrade = useSelector(
        (state: any) => state.signup.options.studentCurrentGrade,
    )
    const [gradeInputProps, setGradeInputProps] = useState({
        error: "",
        value: studentCurrentGrade ? studentCurrentGrade : { name: "", id: "" },
    })
    const [yearInputProps, setyearInputProps] = useState({
        error: "",
        value: expectedGraduationYear ? expectedGraduationYear : { name: "", id: "" },
    })
    const history = useHistory()
    const dispatch = useDispatch()
    const navigateNextPage = (): void => {
        const options: any = {
            expectedGraduationYear: yearInputProps.value,
            studentCurrentGrade: gradeInputProps.value.id,
        }
        dispatch(signupInformation(options))
        navigatetoUrl(history, "/sign-up")
    }
    const userLookingFor = () => {
        switch (infoPage.userLookingFor) {
            case "TUTORING":
                return "Tutoring"

            case "ADVISING":
                return "Admiissions Advising"

            case "TUTORING_AND_ADVISING":
                return t('college-counselling-guide')

            default:
                break
        }
    }
    return (
        <>
            <ProgressBar at="80" theme="darkMango" />
            <OnboardingBackground />
            <Container className="flex-column-space-between">
                <div>
                    <OnBoardingHeader
                        subHeading={"Educational Background"}
                        heading={`I am looking for ${userLookingFor()}`}
                    />

                    <EducationalBackgroundForm
                        gradeInputProps={gradeInputProps}
                        setGradeInputProps={setGradeInputProps}
                        yearInputProps={yearInputProps}
                        setyearInputProps={setyearInputProps}
                    />
                </div>
                <NavigationButtons
                    previousClick={() =>
                        navigatetoUrl(
                            history,
                            "/looking-for"
                        )
                    }
                    nextClick={navigateNextPage}
                />
            </Container>
        </>
    )
}
export default EducationalBackgroundPage
