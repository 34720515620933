import { subscriptionConstant } from "../constant"

const initialState = {
    isError: false,
    isloading: false,
    errorMessage: "",
}

const subscriptionReducer = (state = initialState, action: any): object => {
    switch (action.type) {
        case subscriptionConstant.CANCEL_SUBSCRIPTION:
            return {
                isloading: true
            }
        case subscriptionConstant.CANCEL_SUBSCRIPTION_SUCCESS:
            return {
                isloading: false,
            }
        default:
            return state
    }
}

export default subscriptionReducer
