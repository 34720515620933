import React from "react"
import { Modal } from "@material-ui/core"

interface Iprops {
    openModal: boolean
    url: string
    openModalView: any
}
const ChatModalView = ({ url, openModal, openModalView }: Iprops) => {
    return (
        <Modal
            open={openModal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <div className={"media-modal"}>
                <div
                    onClick={() => {
                        openModalView(false, "")
                    }}
                    className={"close-modal-button"}
                >
                    X
                </div>
                <div className="media-modal-container">
                    <img src={url} className="media-modal-image" />
                </div>
            </div>
        </Modal>
    )
}
export default ChatModalView
