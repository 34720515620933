import React, { useState } from "react"
import { Container } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import { signupInformation } from "../../../store/actions/userActions"
import { navigatetoUrl } from "../../../utils/helper/helper"
import { OnBoardingHeader } from "../../../components/on-boarding/OnboardingHeader"
import NavigationButtons from "../../../components/on-boarding/navigation-buttons/NavigationButtons"
import { useHistory } from "react-router-dom"
import OnboardingBackground from "../../../components/on-boarding/onboarding-background/OnboardingBackground"

import ProgressBar from "../../../components/progress-bar/ProgressBar"
import subjectsList from "../../../utils/constant/subjectsList"
import counsellingList from "../../../utils/constant/counsellingList"
import MultiSelectDropDown from "../../../components/dropdown/MultiSelectDropdown"

interface Option {
    name: string
    id: string
}

const LookingForTutoringPage = () => {
    const infoPage = useSelector((state: any) => state.signup.options)
    const options = useSelector((state: any) =>
        infoPage.subtitle.includes("subjects/tests")
            ? state.signup.options.studentTutoringSubjects
            : state.signup.options.studentAdvisingTopics,
    )
    const [optionList] = useState<Option[]>(
        infoPage.subtitle.includes("subjects/tests") ? subjectsList : counsellingList,
    )
    const [placeholder] = useState(
        infoPage.subtitle.includes("subjects/tests") ? "Eg. Mathematics; SAT" : "Choose A Speciality",
    )
   
    const [optionsSelected, setOptionsSelected] = useState<Option[]>(options ? options : [])
    const [error, setError] = useState("")
    const history = useHistory()
    const dispatch = useDispatch()
    const checkURL = () => {
        return history.location.pathname === infoPage.urlNext
    }
    const setsubjectvalue = (newOptionsState: any): void => {
        setOptionsSelected(newOptionsState)
    }
    const navigatePrevPage = (): void => {
        navigatetoUrl(
            history,
            checkURL() ? "/looking-for-tutoring-and-advising-subjects" : "/looking-for",
        )
    }
    const navigateNextPage = (): void => {
        if (optionsSelected.length > 0) {
            navigatetoUrl(history, checkURL() ? infoPage.urlLast : infoPage.urlNext)
            const options: any = infoPage.subtitle.includes("subjects/tests")
                ? {
                      studentTutoringSubjects: optionsSelected,
                      userType: "STUDENT",
                  }
                : {
                      studentAdvisingTopics: optionsSelected,
                      userType: "STUDENT",
                  }
            dispatch(signupInformation(options))
        } else {
            setError("At least one option is needed")
        }
    }

    const userLookingFor = () => {
        switch (infoPage.userLookingFor) {
            case "TUTORING":
                return "Tutoring"

            case "ADVISING":
                return "Advising"

            case "TUTORING_AND_ADVISING":
                return "Tutoring & Advising"

            default:
                break
        }
    }
    return (
        <>
            <OnboardingBackground />
            <ProgressBar
                at={checkURL() ? infoPage.progressNext : infoPage.progressAt}
                theme="darkMango"
            />
            <Container className="flex-column-space-between">
                <div>
                    <OnBoardingHeader
                        subHeading={checkURL() ? infoPage.subtitleNext : infoPage.subtitle}
                        heading={`I am looking for ${userLookingFor()}`}
                    />
                    <MultiSelectDropDown
                        title=""
                        placeholder={placeholder}
                        optionsList={optionList}
                        error={error}
                     
                        subjectvalue={setsubjectvalue}
                        selectedOption={optionsSelected}
                    />
                </div>

                <NavigationButtons previousClick={navigatePrevPage} nextClick={navigateNextPage} />
            </Container>
        </>
    )
}
export default LookingForTutoringPage
