import { isTablet, isMobile, isIPad13, isIPhone13, isIOS, isIOS13 } from "react-device-detect"

export const useIsPhoneOrTablet = () => {
    return (
        isMobile ||
        isTablet ||
        isIPad13 ||
        isIPhone13 ||
        ((isIOS13 || isIOS) && (isMobile || isTablet))
    )
}
