import React, { useState, useEffect } from "react"
import { GiftedChat, MessageProps, IMessage } from "react-web-gifted-chat"
import _ from "lodash"
import { useSelector, useDispatch } from "react-redux"
import {
    sendMessageService,
    uploadPhoto,
    getChatConversations,
    changeMessageStatus,
    getChatMeta$,
} from "../../utils/services/messaingServices"
import moment from "moment"
import { getFile } from "../../utils/helper/helper"
import { getAllConversationsSuccess } from "../../store/actions/messageAction"
interface Iprops {
    renderBubble: any
    renderBottomToolBar: any
    chatId: string
    chatType: string
    handleAttachmentUpload?: any
    whoIsTyping?: any;
    roomMembers?: any
}

let value
let listenForMeta
const ChatWindow = ({
    renderBubble,
    renderBottomToolBar,
    chatType,
    chatId,
    handleAttachmentUpload,
    whoIsTyping,
    roomMembers,
}: Iprops): JSX.Element => {
    const dispatch = useDispatch()
    const { userAuthData } = useSelector((state: any) => state.auth)
    const [textMessage, setTextMessage] = useState("")
    const [messages, setMessages] = useState<any[]>([])
    const [loadEarlier, setLoadEarlier] = useState(true)
    const [isLoadPreviousMessage, setLoadPreviousMessage] = useState(true)
    const [uploadingMessages, setUploadingMessages] = useState<any[]>([])
    const [whoIsTypingobject, setwhoIsTypingobject] = useState(whoIsTyping)

    const setChatMessages = (messageStatus: any): void => {
        setMessages(messageStatus.message)
        setLoadPreviousMessage(messageStatus.isLoadOlderMessage)
    }

    const handleMetaObserver = (value: any): void => {
        setwhoIsTypingobject(value.whoIsTyping ? value.whoIsTyping : [])
    }

    useEffect(() => {
        if (chatId) {
            listenForMeta = getChatMeta$(chatType, chatId).subscribe(handleMetaObserver)
        }
    }, [chatId])

    const messageStatus = async (): Promise<void> => {
        try {
            const messageStatus = await changeMessageStatus(chatId, userAuthData.userId, true)
            //dispatch(getAllConversationsSuccess())
        } catch (error) {
            console.log("error", error)
        }
    }

    useEffect(() => {
        setMessages([])
        setLoadEarlier(true)
        setUploadingMessages([])
        if (chatType == "group-chats") {
            messageStatus().catch(console.error)
        }
    }, [chatId])

    useEffect(() => {
        if (loadEarlier) {
            value = getChatConversations(messages.length, chatId).subscribe(setChatMessages)
            setLoadEarlier(false)
        }
    })

    const createObject = (): object => {
        const timestamp = moment.utc().valueOf()
        const messageId = `${timestamp}-${userAuthData.userId}`
        const messages: any = {
            text: textMessage,
            _id: messageId,
            timestamp: timestamp,
            seenBy: {
                [userAuthData.userId]: true,
            },
            deliveredTo: {
                [userAuthData.userId]: true,
            },
            user: {
                _id: userAuthData.userId,
                name: userAuthData
                    ? `${userAuthData.firstName} ${userAuthData.lastName}`
                    : undefined,
                avatar: userAuthData.profilePic.downloadURL
                    ? userAuthData.profilePic.downloadURL
                    : "",
            },
            senderId: userAuthData.userId,
            image: "",
            video: "",
            attachment: "",
        }
        return messages
    }

    const onSend = async (message?: any): Promise<void> => {
        const newMessage = message ? message : createObject()

        if (newMessage?.text || newMessage?.image || newMessage.attachment) {
            setTextMessage("")
            await sendMessageService(newMessage._id, newMessage, chatType, chatId)
            const uploadingMessagesList = [...uploadingMessages]
            _.remove(uploadingMessagesList, {
                _id: newMessage._id,
            })
            setUploadingMessages(uploadingMessagesList)
            return
        }
        return
    }

    const getMediaDownloadURl = async (
        file: any,
        message: any,
        isAttachment?: boolean | false,
    ): Promise<void> => {
        const mediaFile = await getFile(file)
        const url = await uploadPhoto(chatId, mediaFile)
        if (isAttachment) {
            message = {
                ...message,
                attachment: { ...message.attachment, uri: url },
            }
        } else {
            message = {
                ...message,
                image: url,
            }
        }
        delete message["isUploading"]
        await onSend(message)
    }

    const handleAttachmentUploads = async (file: any): Promise<void> => {
        const imgWithTextMessage = textMessage
        let message = createObject()
        message = {
            ...message,
            attachment: {
                name: file.name,
                type: file.type,
                uri: "",
            },
            isUploading: true,
            text: imgWithTextMessage ? imgWithTextMessage : "",
        }
        setTextMessage("")
        const uploadingMessagesList = [...uploadingMessages]
        uploadingMessagesList.push(message)
        setUploadingMessages(uploadingMessagesList)
        await getMediaDownloadURl(file, message, true);
    }

    useEffect(() => {
        if (handleAttachmentUpload) {
            handleAttachmentUploads(handleAttachmentUpload).catch(console.error)
        }
    }, [handleAttachmentUpload])

    const handleFileUpload = async (event: any | null): Promise<void> => {
        const file = event.target.files[0]
        const tempUrl = await window.URL.createObjectURL(file)
        const imgWithTextMessage = textMessage
        let message = createObject()
        message = {
            ...message,
            image: tempUrl,
            isUploading: true,
            text: imgWithTextMessage ? imgWithTextMessage : "",
        }
        setTextMessage("")
        const uploadingMessagesList = [...uploadingMessages]
        uploadingMessagesList.push(message)
        setUploadingMessages(uploadingMessagesList)
        await getMediaDownloadURl(file, message)
    }

    const handleMessage = (event: any): boolean => {
        setTextMessage(event.target.value)
        return false
    }

    useEffect(() => {
        if (uploadingMessages.length > 0) {
            const messageList = _.unionBy(messages, uploadingMessages, "_id")
            setMessages(messageList)
        }
    }, [uploadingMessages])

    const sortByTimestamp = (a: any, b: any): number => {
        const dateA: any = a.timestamp
        const dateB: any = b.timestamp
        return dateB - dateA
    }

    const renderFooterView = (): JSX.Element | null => {
        roomMembers = roomMembers ? roomMembers : []
        const loggedInUserId = userAuthData.userId
        const typers = whoIsTypingobject
            ? [...whoIsTypingobject].filter((w) => w !== loggedInUserId).filter((t, i) => i < 3)
            : []
        if (!roomMembers.length || !typers.length) {
            if (chatType === chatType) {
                return <div></div>
            } else {
                return null
            }
        }

        const names = roomMembers
            .filter((r: any) => typers && typers.includes(r.userId))
            .map((t: any) => t.firstName || "someone")
            .join()

        return (
            <div>
                {names} {`${typers.length > 1 ? "are" : "is"}`} typing....
            </div>
        )
    }
    return <>
        {chatId &&
            <GiftedChat
                listViewProps={{
                    showsVerticalScrollIndicator: false,
                    extraData: { whoIsTypingobject },
                }}
                loadEarlier={isLoadPreviousMessage}
                onLoadEarlier={(): void => {
                    setLoadEarlier(true)
                }}
                messages={messages.sort(sortByTimestamp)}
                onSend={() => onSend()}
                renderInputToolbar={(props: any) => {
                    return renderBottomToolBar(
                        props,
                        textMessage,
                        handleFileUpload,
                        handleMessage,
                        onSend,
                    )
                }}
                renderMessage={(props: MessageProps<any>): React.ReactNode => {
                    const messageBelongsToCurrentUser =
                        userAuthData.userId == props.currentMessage?.senderId
                    return messageBelongsToCurrentUser
                        ? renderBubble(props, true)
                        : renderBubble(props, false)
                }}
                bottomOffset={100}
                renderFooter={renderFooterView}
            ></GiftedChat>
        }
    </>
}
export default ChatWindow
