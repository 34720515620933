import { EpicBoardConstant } from "../constant"

export const epicBoardUpdate = (action: any): object => {
    return { type: EpicBoardConstant.EPIC_BOARD_UPDATE, action }
}

export const epicBoardChangeTab = (action: any): object => {
    return { type: EpicBoardConstant.EPIC_BOARD_CHANGE_TAB, action }
}

export const epicBoardUpdateTutor = (action: any): object => {
    return { type: EpicBoardConstant.EPIC_BOARD_TUTOR_INFO, action }
}

export const epicBoardUpdateSessionInfo = (action: any): object => {
    return { type: EpicBoardConstant.EPIC_BOARD_UPDATE_SESSION_INFO, action }
}
