import colors from "assets/colors";
import React from "react"
import { useSelector } from "react-redux";
import MessageSvgIcon from "../../assets/images/svgIcons/MessageSvgIcon"

const MessageIcon = ({ iconColor }: { iconColor: string }): JSX.Element => {

    const { unreadCount }: { unreadCount: number } = useSelector((state: any) => state.messageCenter);
    return (
        <>
            <MessageSvgIcon iconColor={iconColor} />
            {unreadCount > 0 && <span className="message-badge" style={unreadCount > 9 ? { padding: "2px 4px" } : { padding: "2px 8px" }}>{unreadCount}</span>}
        </>
    )
}

export default MessageIcon