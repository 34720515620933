import React, { useMemo } from "react"
import { useSelector } from "react-redux"
import { Route, Redirect, RouteProps, useHistory, useLocation } from "react-router-dom"
import { applicationConstant } from "../utils/constant/applicationConstant"
import InterfaceConstant from "../utils/constant/interfaceConstant"

interface Props extends RouteProps {
    component: any
}
interface RootState {
    auth: any
}

const routeUrl: InterfaceConstant.IrouteUrl = applicationConstant.routeUrl
const LocalRoute = (props: Props): JSX.Element => {
    const userAuthData = useSelector((state: RootState) => state.auth.loggedIn)
    const location = useLocation();
    const redirectPath = useMemo(() => {
        const urlParams = new URLSearchParams(location.search);
        return urlParams.get("redirectTo");
    }, [location.pathname, location.search]);
    
    const { component: Component, ...rest } = props
    return (
        <Route
            {...rest}
            render={(routeProps) =>
                userAuthData ? (
                    <Redirect
                        to={{
                            pathname: redirectPath || routeUrl.PANEL_ROUTE + routeUrl.DASHBOARD_PAGE,
                            state: { from: routeProps.location },
                        }}
                    />
                ) : (
                    <Component {...routeProps} />
                )
            }
        />
    )
}
export default LocalRoute
