/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useEffect, useMemo, useState } from "react"
import Button from "../../button/Button"
import { Note } from "../../../components/tips/Note"
import SimpleDropdown from "../../../components/dropdown/SimpleDropdown";
import { STRIPE_CONNECT_EXPRESS_COUNTRIES } from "../../../utils/constant/stripeConstant"
import Modal from "./../SimpleModal"

interface StripeConnectCountryProps {
    onGoto: (country: { id: string, name: string, code: string }) => void;
    isShown: boolean;
    onClose: () => void;
}

export function StripeConnectCountrySelection(props: StripeConnectCountryProps) {
    const [country, setCountry] = useState({
        error: "Required",
        value: { name: "", id: "", code: "" }
    });
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const countriesList = useMemo(() => {
        return STRIPE_CONNECT_EXPRESS_COUNTRIES.map((country) => {
            return {
                id: country.code,
                ...country,
            };
        });
    }, [STRIPE_CONNECT_EXPRESS_COUNTRIES]);


    useEffect(() => {
        setIsLoading(true);
        fetch("https://api.ipregistry.co/?key=tryout")
            .then((res) => res.json())
            .then((d) => d?.location?.country?.code)
            .then((countryISOCode) => {
                const availableCountry = countriesList.filter(co => (co.id === countryISOCode)).pop();
                const defaultCountry = countriesList.filter(co => co.id === "US").pop();
                defaultCountry && setCountry(() => ({
                    error: "",
                    value: availableCountry || defaultCountry
                }));
            }).finally(() => {
                setIsLoading(false);
            });
    }, [countriesList]);
    return (
        <div className="stripe-connect-country-selection-modals">
            <Modal visible={props.isShown} onExit={props.onClose}>
                <div className="model_join_session">
                    <h2>Choose your payout country</h2>
                    <Note
                        title="Please note"
                        content={`Before choosing the country, make sure that you have a bank account there. This is a one time action. Once you have chosen your payout country and pressed "Continue", you won't be able to change your payout country`}
                    />
                    <SimpleDropdown
                        isDisabled={isLoading}
                        placeholder={"choose your payout country"}
                        optionsList={countriesList}
                        fieldDetails={country}
                        setProps={setCountry}
                        isShowError={false}
                    />
                    <div className="action-items">
                        <Button className="outlined" title="Cancel" clickAction={props.onClose} />
                        <Button className="" title="Continue" clickAction={() => props.onGoto(country?.value)} />
                    </div>
                </div>
            </Modal>
        </div>
    )
}
