import React, { memo } from "react";
import { Card, Grid, CardHeader, CardContent } from "@material-ui/core";
import  {UsersTable}  from "./components/users"
export const AdminDashboard = memo(() => {

    return <Grid container alignContent="center" justifyContent="center" spacing={2} style={{ marginTop: '20px' }}>
        <Grid item xs={11}>
            <Card>
                <CardHeader title="Users" />
                <CardContent>
                    <UsersTable />
                </CardContent>
            </Card>
        </Grid>
    </Grid>;

},()=>true)