import React from "react"

interface CardViewProps {
    children: any
    overFlowChildStyle?: boolean,
    style?: any,
    className?: string
}
export const CardView = ({ children, overFlowChildStyle, style, className }: CardViewProps): JSX.Element => {
    return (
        <div className={`card-view ${overFlowChildStyle ? "overflow-unset" : "overflow-hidden"} ${className}`} style={style}>
            {children}
        </div>
    )
}

interface OptionCardViewProps {
    children: any
}

export const OptionCardView = ({ children }: OptionCardViewProps): JSX.Element => {
    return (
        <div className={`intent-card`}>
            <CardView overFlowChildStyle={true}>{children}</CardView>
        </div>
    )
}
