export const countryList = [
    { name: "Afghanistan", id: "AF" },
    { name: "Åland Islands", id: "AX" },
    { name: "Albania", id: "AL" },
    { name: "Algeria", id: "DZ" },
    { name: "American Samoa", id: "AS" },
    { name: "AndorrA", id: "AD" },
    { name: "Angola", id: "AO" },
    { name: "Anguilla", id: "AI" },
    { name: "Antarctica", id: "AQ" },
    { name: "Antigua and Barbuda", id: "AG" },
    { name: "Argentina", id: "AR" },
    { name: "Armenia", id: "AM" },
    { name: "Aruba", id: "AW" },
    { name: "Australia", id: "AU" },
    { name: "Austria", id: "AT" },
    { name: "Azerbaijan", id: "AZ" },
    { name: "Bahamas", id: "BS" },
    { name: "Bahrain", id: "BH" },
    { name: "Bangladesh", id: "BD" },
    { name: "Barbados", id: "BB" },
    { name: "Belarus", id: "BY" },
    { name: "Belgium", id: "BE" },
    { name: "Belize", id: "BZ" },
    { name: "Benin", id: "BJ" },
    { name: "Bermuda", id: "BM" },
    { name: "Bhutan", id: "BT" },
    { name: "Bolivia", id: "BO" },
    { name: "Bosnia and Herzegovina", id: "BA" },
    { name: "Botswana", id: "BW" },
    { name: "Bouvet Island", id: "BV" },
    { name: "Brazil", id: "BR" },
    { name: "British Indian Ocean Territory", id: "IO" },
    { name: "Brunei Darussalam", id: "BN" },
    { name: "Bulgaria", id: "BG" },
    { name: "Burkina Faso", id: "BF" },
    { name: "Burundi", id: "BI" },
    { name: "Cambodia", id: "KH" },
    { name: "Cameroon", id: "CM" },
    { name: "Canada", id: "CA" },
    { name: "Cape Verde", id: "CV" },
    { name: "Cayman Islands", id: "KY" },
    { name: "Central African Republic", id: "CF" },
    { name: "Chad", id: "TD" },
    { name: "Chile", id: "CL" },
    { name: "China", id: "CN" },
    { name: "Christmas Island", id: "CX" },
    { name: "Cocos (Keeling) Islands", id: "CC" },
    { name: "Colombia", id: "CO" },
    { name: "Comoros", id: "KM" },
    { name: "Congo", id: "CG" },
    { name: "Congo, The Democratic Republic of the", id: "CD" },
    { name: "Cook Islands", id: "CK" },
    { name: "Costa Rica", id: "CR" },
    { name: "Cote D'Ivoire", id: "CI" },
    { name: "Croatia", id: "HR" },
    { name: "Cuba", id: "CU" },
    { name: "Cyprus", id: "CY" },
    { name: "Czech Republic", id: "CZ" },
    { name: "Denmark", id: "DK" },
    { name: "Djibouti", id: "DJ" },
    { name: "Dominica", id: "DM" },
    { name: "Dominican Republic", id: "DO" },
    { name: "Ecuador", id: "EC" },
    { name: "Egypt", id: "EG" },
    { name: "El Salvador", id: "SV" },
    { name: "Equatorial Guinea", id: "GQ" },
    { name: "Eritrea", id: "ER" },
    { name: "Estonia", id: "EE" },
    { name: "Ethiopia", id: "ET" },
    { name: "Falkland Islands (Malvinas)", id: "FK" },
    { name: "Faroe Islands", id: "FO" },
    { name: "Fiji", id: "FJ" },
    { name: "Finland", id: "FI" },
    { name: "France", id: "FR" },
    { name: "French Guiana", id: "GF" },
    { name: "French Polynesia", id: "PF" },
    { name: "French Southern Territories", id: "TF" },
    { name: "Gabon", id: "GA" },
    { name: "Gambia", id: "GM" },
    { name: "Georgia", id: "GE" },
    { name: "Germany", id: "DE" },
    { name: "Ghana", id: "GH" },
    { name: "Gibraltar", id: "GI" },
    { name: "Greece", id: "GR" },
    { name: "Greenland", id: "GL" },
    { name: "Grenada", id: "GD" },
    { name: "Guadeloupe", id: "GP" },
    { name: "Guam", id: "GU" },
    { name: "Guatemala", id: "GT" },
    { name: "Guernsey", id: "GG" },
    { name: "Guinea", id: "GN" },
    { name: "Guinea-Bissau", id: "GW" },
    { name: "Guyana", id: "GY" },
    { name: "Haiti", id: "HT" },
    { name: "Heard Island and Mcdonald Islands", id: "HM" },
    { name: "Holy See (Vatican City State)", id: "VA" },
    { name: "Honduras", id: "HN" },
    { name: "Hong Kong", id: "HK" },
    { name: "Hungary", id: "HU" },
    { name: "Iceland", id: "IS" },
    { name: "India", id: "IN" },
    { name: "Indonesia", id: "ID" },
    { name: "Iran, Islamic Republic Of", id: "IR" },
    { name: "Iraq", id: "IQ" },
    { name: "Ireland", id: "IE" },
    { name: "Isle of Man", id: "IM" },
    { name: "Israel", id: "IL" },
    { name: "Italy", id: "IT" },
    { name: "Jamaica", id: "JM" },
    { name: "Japan", id: "JP" },
    { name: "Jersey", id: "JE" },
    { name: "Jordan", id: "JO" },
    { name: "Kazakhstan", id: "KZ" },
    { name: "Kenya", id: "KE" },
    { name: "Kiribati", id: "KI" },
    { name: "Korea, Democratic People'S Republic of", id: "KP" },
    { name: "Korea, Republic of", id: "KR" },
    { name: "Kuwait", id: "KW" },
    { name: "Kyrgyzstan", id: "KG" },
    { name: "Lao People'S Democratic Republic", id: "LA" },
    { name: "Latvia", id: "LV" },
    { name: "Lebanon", id: "LB" },
    { name: "Lesotho", id: "LS" },
    { name: "Liberia", id: "LR" },
    { name: "Libyan Arab Jamahiriya", id: "LY" },
    { name: "Liechtenstein", id: "LI" },
    { name: "Lithuania", id: "LT" },
    { name: "Luxembourg", id: "LU" },
    { name: "Macao", id: "MO" },
    { name: "Macedonia, The Former Yugoslav Republic of", id: "MK" },
    { name: "Madagascar", id: "MG" },
    { name: "Malawi", id: "MW" },
    { name: "Malaysia", id: "MY" },
    { name: "Maldives", id: "MV" },
    { name: "Mali", id: "ML" },
    { name: "Malta", id: "MT" },
    { name: "Marshall Islands", id: "MH" },
    { name: "Martinique", id: "MQ" },
    { name: "Mauritania", id: "MR" },
    { name: "Mauritius", id: "MU" },
    { name: "Mayotte", id: "YT" },
    { name: "Mexico", id: "MX" },
    { name: "Micronesia, Federated States of", id: "FM" },
    { name: "Moldova, Republic of", id: "MD" },
    { name: "Monaco", id: "MC" },
    { name: "Mongolia", id: "MN" },
    { name: "Montserrat", id: "MS" },
    { name: "Morocco", id: "MA" },
    { name: "Mozambique", id: "MZ" },
    { name: "Myanmar", id: "MM" },
    { name: "Namibia", id: "NA" },
    { name: "Nauru", id: "NR" },
    { name: "Nepal", id: "NP" },
    { name: "Netherlands", id: "NL" },
    { name: "Netherlands Antilles", id: "AN" },
    { name: "New Caledonia", id: "NC" },
    { name: "New Zealand", id: "NZ" },
    { name: "Nicaragua", id: "NI" },
    { name: "Niger", id: "NE" },
    { name: "Nigeria", id: "NG" },
    { name: "Niue", id: "NU" },
    { name: "Norfolk Island", id: "NF" },
    { name: "Northern Mariana Islands", id: "MP" },
    { name: "Norway", id: "NO" },
    { name: "Oman", id: "OM" },
    { name: "Pakistan", id: "PK" },
    { name: "Palau", id: "PW" },
    { name: "Palestinian Territory, Occupied", id: "PS" },
    { name: "Panama", id: "PA" },
    { name: "Papua New Guinea", id: "PG" },
    { name: "Paraguay", id: "PY" },
    { name: "Peru", id: "PE" },
    { name: "Philippines", id: "PH" },
    { name: "Pitcairn", id: "PN" },
    { name: "Poland", id: "PL" },
    { name: "Portugal", id: "PT" },
    { name: "Puerto Rico", id: "PR" },
    { name: "Qatar", id: "QA" },
    { name: "Reunion", id: "RE" },
    { name: "Romania", id: "RO" },
    { name: "Russian Federation", id: "RU" },
    { name: "RWANDA", id: "RW" },
    { name: "Saint Helena", id: "SH" },
    { name: "Saint Kitts and Nevis", id: "KN" },
    { name: "Saint Lucia", id: "LC" },
    { name: "Saint Pierre and Miquelon", id: "PM" },
    { name: "Saint Vincent and the Grenadines", id: "VC" },
    { name: "Samoa", id: "WS" },
    { name: "San Marino", id: "SM" },
    { name: "Sao Tome and Principe", id: "ST" },
    { name: "Saudi Arabia", id: "SA" },
    { name: "Senegal", id: "SN" },
    { name: "Serbia and Montenegro", id: "CS" },
    { name: "Seychelles", id: "SC" },
    { name: "Sierra Leone", id: "SL" },
    { name: "Singapore", id: "SG" },
    { name: "Slovakia", id: "SK" },
    { name: "Slovenia", id: "SI" },
    { name: "Solomon Islands", id: "SB" },
    { name: "Somalia", id: "SO" },
    { name: "South Africa", id: "ZA" },
    { name: "South Georgia and the South Sandwich Islands", id: "GS" },
    { name: "Spain", id: "ES" },
    { name: "Sri Lanka", id: "LK" },
    { name: "Sudan", id: "SD" },
    { name: "Suriname", id: "SR" },
    { name: "Svalbard and Jan Mayen", id: "SJ" },
    { name: "Swaziland", id: "SZ" },
    { name: "Sweden", id: "SE" },
    { name: "Switzerland", id: "CH" },
    { name: "Syrian Arab Republic", id: "SY" },
    { name: "Taiwan, Province of China", id: "TW" },
    { name: "Tajikistan", id: "TJ" },
    { name: "Tanzania, United Republic of", id: "TZ" },
    { name: "Thailand", id: "TH" },
    { name: "Timor-Leste", id: "TL" },
    { name: "Togo", id: "TG" },
    { name: "Tokelau", id: "TK" },
    { name: "Tonga", id: "TO" },
    { name: "Trinidad and Tobago", id: "TT" },
    { name: "Tunisia", id: "TN" },
    { name: "Turkey", id: "TR" },
    { name: "Turkmenistan", id: "TM" },
    { name: "Turks and Caicos Islands", id: "TC" },
    { name: "Tuvalu", id: "TV" },
    { name: "Uganda", id: "UG" },
    { name: "Ukraine", id: "UA" },
    { name: "United Arab Emirates", id: "AE" },
    { name: "United Kingdom", id: "GB" },
    { name: "United States", id: "US" },
    { name: "United States Minor Outlying Islands", id: "UM" },
    { name: "Uruguay", id: "UY" },
    { name: "Uzbekistan", id: "UZ" },
    { name: "Vanuatu", id: "VU" },
    { name: "Venezuela", id: "VE" },
    { name: "Viet Nam", id: "VN" },
    { name: "Virgin Islands, British", id: "VG" },
    { name: "Virgin Islands, U.S.", id: "VI" },
    { name: "Wallis and Futuna", id: "WF" },
    { name: "Western Sahara", id: "EH" },
    { name: "Yemen", id: "YE" },
    { name: "Zambia", id: "ZM" },
    { name: "Zimbabwe", id: "ZW" },
]

export const currencyList = [
    { id: "AED", name: "United Arab Emirates Dirham" },
    { id: "AFN", name: "Afghan Afghani" },
    { id: "ALL", name: "Albanian Lek" },
    { id: "AMD", name: "Armenian Dram" },
    { id: "ANG", name: "Netherlands Antillean Guilder" },
    { id: "AOA", name: "Angolan Kwanza" },
    { id: "ARS", name: "Argentine Peso" },
    { id: "AUD", name: "Australian Dollar" },
    { id: "AWG", name: "Aruban Florin" },
    { id: "AZN", name: "Azerbaijani Manat" },
    { id: "BAM", name: "Bosnia-Herzegovina Convertible Mark" },
    { id: "BBD", name: "Barbadian Dollar" },
    { id: "BDT", name: "Bangladeshi Taka" },
    { id: "BGN", name: "Bulgarian Lev" },
    { id: "BHD", name: "Bahraini Dinar" },
    { id: "BIF", name: "Burundian Franc" },
    { id: "BMD", name: "Bermudan Dollar" },
    { id: "BND", name: "Brunei Dollar" },
    { id: "BOB", name: "Bolivian Boliviano" },
    { id: "BRL", name: "Brazilian Real" },
    { id: "BSD", name: "Bahamian Dollar" },
    { id: "BTC", name: "Bitcoin" },
    { id: "BTN", name: "Bhutanese Ngultrum" },
    { id: "BWP", name: "Botswanan Pula" },
    { id: "BYN", name: "Belarusian Ruble" },
    { id: "BZD", name: "Belize Dollar" },
    { id: "CAD", name: "Canadian Dollar" },
    { id: "CDF", name: "Congolese Franc" },
    { id: "CHF", name: "Swiss Franc" },
    { id: "CLF", name: "Chilean Unit of Account (UF)" },
    { id: "CLP", name: "Chilean Peso" },
    { id: "CNH", name: "Chinese Yuan (Offshore)" },
    { id: "CNY", name: "Chinese Yuan" },
    { id: "COP", name: "Colombian Peso" },
    { id: "CRC", name: "Costa Rican Colón" },
    { id: "CUC", name: "Cuban Convertible Peso" },
    { id: "CUP", name: "Cuban Peso" },
    { id: "CVE", name: "Cape Verdean Escudo" },
    { id: "CZK", name: "Czech Republic Koruna" },
    { id: "DJF", name: "Djiboutian Franc" },
    { id: "DKK", name: "Danish Krone" },
    { id: "DOP", name: "Dominican Peso" },
    { id: "DZD", name: "Algerian Dinar" },
    { id: "EGP", name: "Egyptian Pound" },
    { id: "ERN", name: "Eritrean Nakfa" },
    { id: "ETB", name: "Ethiopian Birr" },
    { id: "EUR", name: "Euro" },
    { id: "FJD", name: "Fijian Dollar" },
    { id: "FKP", name: "Falkland Islands Pound" },
    { id: "GBP", name: "British Pound Sterling" },
    { id: "GEL", name: "Georgian Lari" },
    { id: "GGP", name: "Guernsey Pound" },
    { id: "GHS", name: "Ghanaian Cedi" },
    { id: "GIP", name: "Gibraltar Pound" },
    { id: "GMD", name: "Gambian Dalasi" },
    { id: "GNF", name: "Guinean Franc" },
    { id: "GTQ", name: "Guatemalan Quetzal" },
    { id: "GYD", name: "Guyanaese Dollar" },
    { id: "HKD", name: "Hong Kong Dollar" },
    { id: "HNL", name: "Honduran Lempira" },
    { id: "HRK", name: "Croatian Kuna" },
    { id: "HTG", name: "Haitian Gourde" },
    { id: "HUF", name: "Hungarian Forint" },
    { id: "IDR", name: "Indonesian Rupiah" },
    { id: "ILS", name: "Israeli New Sheqel" },
    { id: "IMP", name: "Manx pound" },
    { id: "INR", name: "Indian Rupee" },
    { id: "IQD", name: "Iraqi Dinar" },
    { id: "IRR", name: "Iranian Rial" },
    { id: "ISK", name: "Icelandic Króna" },
    { id: "JEP", name: "Jersey Pound" },
    { id: "JMD", name: "Jamaican Dollar" },
    { id: "JOD", name: "Jordanian Dinar" },
    { id: "JPY", name: "Japanese Yen" },
    { id: "KES", name: "Kenyan Shilling" },
    { id: "KGS", name: "Kyrgystani Som" },
    { id: "KHR", name: "Cambodian Riel" },
    { id: "KMF", name: "Comorian Franc" },
    { id: "KPW", name: "North Korean Won" },
    { id: "KRW", name: "South Korean Won" },
    { id: "KWD", name: "Kuwaiti Dinar" },
    { id: "KYD", name: "Cayman Islands Dollar" },
    { id: "KZT", name: "Kazakhstani Tenge" },
    { id: "LAK", name: "Laotian Kip" },
    { id: "LBP", name: "Lebanese Pound" },
    { id: "LKR", name: "Sri Lankan Rupee" },
    { id: "LRD", name: "Liberian Dollar" },
    { id: "LSL", name: "Lesotho Loti" },
    { id: "LYD", name: "Libyan Dinar" },
    { id: "MAD", name: "Moroccan Dirham" },
    { id: "MDL", name: "Moldovan Leu" },
    { id: "MGA", name: "Malagasy Ariary" },
    { id: "MKD", name: "Macedonian Denar" },
    { id: "MMK", name: "Myanma Kyat" },
    { id: "MNT", name: "Mongolian Tugrik" },
    { id: "MOP", name: "Macanese Pataca" },
    { id: "MRO", name: "Mauritanian Ouguiya (pre-2018)" },
    { id: "MRU", name: "Mauritanian Ouguiya" },
    { id: "MUR", name: "Mauritian Rupee" },
    { id: "MVR", name: "Maldivian Rufiyaa" },
    { id: "MWK", name: "Malawian Kwacha" },
    { id: "MXN", name: "Mexican Peso" },
    { id: "MYR", name: "Malaysian Ringgit" },
    { id: "MZN", name: "Mozambican Metical" },
    { id: "NAD", name: "Namibian Dollar" },
    { id: "NGN", name: "Nigerian Naira" },
    { id: "NIO", name: "Nicaraguan Córdoba" },
    { id: "NOK", name: "Norwegian Krone" },
    { id: "NPR", name: "Nepalese Rupee" },
    { id: "NZD", name: "New Zealand Dollar" },
    { id: "OMR", name: "Omani Rial" },
    { id: "PAB", name: "Panamanian Balboa" },
    { id: "PEN", name: "Peruvian Nuevo Sol" },
    { id: "PGK", name: "Papua New Guinean Kina" },
    { id: "PHP", name: "Philippine Peso" },
    { id: "PKR", name: "Pakistani Rupee" },
    { id: "PLN", name: "Polish Zloty" },
    { id: "PYG", name: "Paraguayan Guarani" },
    { id: "QAR", name: "Qatari Rial" },
    { id: "RON", name: "Romanian Leu" },
    { id: "RSD", name: "Serbian Dinar" },
    { id: "RUB", name: "Russian Ruble" },
    { id: "RWF", name: "Rwandan Franc" },
    { id: "SAR", name: "Saudi Riyal" },
    { id: "SBD", name: "Solomon Islands Dollar" },
    { id: "SCR", name: "Seychellois Rupee" },
    { id: "SDG", name: "Sudanese Pound" },
    { id: "SEK", name: "Swedish Krona" },
    { id: "SGD", name: "Singapore Dollar" },
    { id: "SHP", name: "Saint Helena Pound" },
    { id: "SLL", name: "Sierra Leonean Leone" },
    { id: "SOS", name: "Somali Shilling" },
    { id: "SRD", name: "Surinamese Dollar" },
    { id: "SSP", name: "South Sudanese Pound" },
    { id: "STD", name: "São Tomé and Príncipe Dobra (pre-2018)" },
    { id: "STN", name: "São Tomé and Príncipe Dobra" },
    { id: "SVC", name: "Salvadoran Colón" },
    { id: "SYP", name: "Syrian Pound" },
    { id: "SZL", name: "Swazi Lilangeni" },
    { id: "THB", name: "Thai Baht" },
    { id: "TJS", name: "Tajikistani Somoni" },
    { id: "TMT", name: "Turkmenistani Manat" },
    { id: "TND", name: "Tunisian Dinar" },
    { id: "TOP", name: "Tongan Pa'anga" },
    { id: "TRY", name: "Turkish Lira" },
    { id: "TTD", name: "Trinidad and Tobago Dollar" },
    { id: "TWD", name: "New Taiwan Dollar" },
    { id: "TZS", name: "Tanzanian Shilling" },
    { id: "UAH", name: "Ukrainian Hryvnia" },
    { id: "UGX", name: "Ugandan Shilling" },
    { id: "USD", name: "United States Dollar" },
    { id: "UYU", name: "Uruguayan Peso" },
    { id: "UZS", name: "Uzbekistan Som" },
    { id: "VEF", name: "Venezuelan Bolívar Fuerte" },
    { id: "VND", name: "Vietnamese Dong" },
    { id: "VUV", name: "Vanuatu Vatu" },
    { id: "WST", name: "Samoan Tala" },
    { id: "XAF", name: "CFA Franc BEAC" },
    { id: "XAG", name: "Silver Ounce" },
    { id: "XAU", name: "Gold Ounce" },
    { id: "XCD", name: "East Caribbean Dollar" },
    { id: "XDR", name: "Special Drawing Rights" },
    { id: "XOF", name: "CFA Franc BCEAO" },
    { id: "XPD", name: "Palladium Ounce" },
    { id: "XPF", name: "CFP Franc" },
    { id: "XPT", name: "Platinum Ounce" },
    { id: "YER", name: "Yemeni Rial" },
    { id: "ZAR", name: "South African Rand" },
    { id: "ZMW", name: "Zambian Kwacha" },
    { id: "ZWL", name: "Zimbabwean Dollar" },
]

export const accountTypes = [
    { id: 1, name: "Checking accounts" },
    { id: 2, name: "Savings accounts" },
    { id: 3, name: "Money market accounts" },
    { id: 4, name: "Certificates of deposit" },
    { id: 5, name: "Brokerage accounts" },
    { id: 6, name: "Individual retirement accounts" },
]
