import { put, all, takeLatest } from "redux-saga/effects"
import { PaymentConstant } from "../constant"
import { getPaymentHistory } from "../../utils/services/paymentServices"


function* fetchPaymentHistory(action: any) {
    try {
        const paymentData = yield getPaymentHistory(action.payload)
        yield put({ type: PaymentConstant.PAYMENT_HISTORY_REQUEST_SUCCESS, payload: paymentData })
    } catch (error) {
        console.log(error)
    }
}

export default all([
    takeLatest(PaymentConstant.FETCH_PAYMENT_HISTORY, fetchPaymentHistory),
])
