import React from "react"
import { useTranslation } from 'react-i18next';
import SwitchComponent from "../../../../../components/switch/SwitchComponent"



const MyFavouritesFilter = ({ myFavourites, setMyFavourites }: { myFavourites: any, setMyFavourites: any }): JSX.Element => {
    const { t } = useTranslation();
    return (
        <div className="tutor-search-filter margin-top-zero">
            <SwitchComponent active={myFavourites} onTap={setMyFavourites} label={t("my-favourites-check")} />
        </div>
    )
}

export default MyFavouritesFilter

