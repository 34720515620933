import React from "react"
import interfaceConstant from "../../../utils/constant/interfaceConstant"

class ChatSvgIcon extends React.PureComponent<interfaceConstant.SvgIconProps> {
    render(): JSX.Element {
        const { width, height, iconColor, ...otherProps } = this.props
        return (
            <svg width={width} height={height} viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" {...otherProps}>
                <g id="Iconography" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="Icons-/-Base-/-Bas-50" >
                        <rect id="Rectangle" x="4.4408921e-14" y="-3.59356989e-12" width="23.9975009" height="23.9975009"></rect>
                        <rect id="Rectangle" x="4.4408921e-14" y="-3.59356989e-12" width="23.9975009" height="23.9975009"></rect>
                        <path d="M13.1515152,14 L4.96969697,14 L4.96969697,12.2727273 L13.1818182,12.2727273 L13.1818182,14 L13.1515152,14 Z M19.1212121,5.78787879 L4.96969697,5.78787879 L4.96969697,7.51515152 L19.1212121,7.51515152 L19.1212121,5.78787879 Z M19.1212121,9.03030303 L4.96969697,9.03030303 L4.96969697,10.7575758 L19.1212121,10.7575758 L19.1212121,9.03030303 Z M1,14.6969697 L1,5.15151515 C1,3.39393939 2.42424242,2 4.15151515,2 L19.9090909,2 C21.6666667,2 23.0606061,3.42424242 23.0606061,5.15151515 L23.0606061,14.6969697 C23.0606061,16.4545455 21.6363636,17.8484848 19.9090909,17.8484848 L9.12121212,17.8484848 L5.54545455,21.4545455 C5.33333333,21.6666667 5.03030303,21.7878788 4.72727273,21.7878788 C4.42424242,21.7878788 4.15151515,21.6666667 3.90909091,21.4545455 C3.66666667,21.2424242 3.54545455,20.9090909 3.54545455,20.5757576 L3.54545455,17.7575758 C2.09090909,17.4848485 1,16.2121212 1,14.6969697 Z M3.21212121,14.6969697 C3.21212121,15.2121212 3.63636364,15.6666667 4.18181818,15.6666667 L5.75757576,15.6666667 L5.75757576,18.1515152 L8.24242424,15.6666667 L19.969697,15.6666667 C20.4848485,15.6666667 20.9393939,15.2424242 20.9393939,14.6969697 L20.9393939,5.15151515 C20.9393939,4.63636364 20.5151515,4.18181818 19.969697,4.18181818 L4.21212121,4.18181818 C3.6969697,4.18181818 3.24242424,4.60606061 3.24242424,5.15151515 L3.24242424,14.6969697 L3.21212121,14.6969697 Z" id="Shape" fill="#f28334"></path>
                    </g>
                </g>
            </svg>
        )
    }
}

export default ChatSvgIcon