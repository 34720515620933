import React from "react"
import interfaceConstant from "../../../utils/constant/interfaceConstant"

class LinkSvgIcon extends React.PureComponent<interfaceConstant.SvgIconProps> {
    render(): JSX.Element {
        const {  iconColor, ...otherProps } = this.props
        return (
            <svg
                x="0px"
                y="0px"
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                enableBackground="new 0 0 24 24"
                {...otherProps}
            >
                
                <g id="Icons-_x2F_-Base-_x2F_-Bas-14">
                    <g id="noun_attach_1487041" transform="translate(2.000000, 2.000000)">
                        <path
                            id="Shape"
                            fill={iconColor}
                            d="M3.29,7.273c-1.098-1.098-1.098-2.885,0-3.983c0.532-0.532,1.24-0.825,1.992-0.825
             c0.752,0,1.459,0.293,1.992,0.825l3.252,3.252c0.233,0.232,0.542,0.36,0.872,0.36c0.328,0,0.639-0.128,0.871-0.36
             C12.502,6.309,12.631,6,12.631,5.67c0-0.33-0.129-0.639-0.361-0.872L9.017,1.547C8.02,0.549,6.693,0,5.282,0
             C3.871,0,2.544,0.549,1.547,1.547C0.549,2.544,0,3.87,0,5.281s0.549,2.737,1.547,3.735l3.252,3.251
             c0.233,0.233,0.542,0.361,0.872,0.361s0.639-0.128,0.872-0.361c0.233-0.232,0.361-0.542,0.361-0.871
             c0-0.33-0.128-0.639-0.361-0.871L3.29,7.273z M6.395,5.162c-0.329,0-0.639,0.128-0.872,0.361C5.291,5.756,5.163,6.065,5.163,6.395
             c0,0.33,0.128,0.639,0.361,0.872l7.212,7.21c0.233,0.233,0.542,0.361,0.872,0.361c0.329,0,0.639-0.128,0.871-0.361
             c0.481-0.48,0.481-1.262,0-1.743L7.267,5.522C7.034,5.29,6.725,5.162,6.395,5.162z M18.455,10.984l-3.252-3.252
             C14.971,7.5,14.66,7.372,14.332,7.372c-0.33,0-0.639,0.128-0.872,0.361c-0.233,0.232-0.36,0.542-0.36,0.872
             c0,0.329,0.127,0.639,0.36,0.872l3.253,3.251c1.098,1.099,1.098,2.885,0,3.983c-0.533,0.532-1.24,0.825-1.992,0.825
             s-1.46-0.293-1.992-0.825l-3.252-3.252c-0.233-0.233-0.542-0.36-0.872-0.36c-0.329,0-0.638,0.127-0.872,0.36
             C7.5,13.691,7.373,14,7.373,14.33c0,0.328,0.128,0.639,0.361,0.871l3.253,3.252C11.982,19.451,13.309,20,14.721,20
             c1.411,0,2.738-0.549,3.734-1.547C20.515,16.394,20.515,13.043,18.455,10.984z"
                        />
                    </g>
                </g>
            </svg>
        )
    }
}

export default LinkSvgIcon
