import React from "react"
import { useTranslation } from "react-i18next";
import MultiSelectBar from "../../../../../components/multiselect-bar/MultiselectBar"



const DaySelectFilter = ({ weekdays, setWeekdays }: { weekdays: any, setWeekdays: any }): JSX.Element => {
    const { t } = useTranslation();
    return (
        <div className="tutor-search-filter">
            <div>
                <MultiSelectBar items={weekdays} setItems={setWeekdays} label={t("day-of-week")} />
            </div>
        </div>
    )
}

export default DaySelectFilter

