import React from "react";
import Modal from "components/modal_/Modal";
import StudentTutorRejectionAvatar from "components/student-tutor-rejection-avatar/StudentTutorRejectionAvatar"
import './StdTutorAcceptRequest.scss';
import Button from "components/button/Button";

interface TutorRejectSessions {
    studentProfilePicture: string,
    teacherProfilePicture: string,
    teacherName: string,
    onGoto: () => void,
    onSearch: () => void
    isShown: boolean
    onClose: () => void
}
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function TutorRejectSession(props: TutorRejectSessions) {
    return <Modal
        visible={props.isShown}
        onExit={props.onClose}
    >
        <div className="model_tutorrejectsession">
            <div className="imgs_div">
                <StudentTutorRejectionAvatar
                    size="100"
                    student={
                        props.studentProfilePicture
                    }
                    tutor={
                        props.teacherProfilePicture
                    }
                />
            </div>
            <h2>Unfortunately, {props.teacherName} couldn't accept your request(s)</h2>
            {/* <h4>Test about the account credit.</h4> */}
            <div className="tutor_reject_session">
                <Button className="outlined-button-bottom-margin" title="Search for a new tutor" clickAction={props.onSearch} isOutlineStyle={false}/>
                <Button className=""  title="Go to Dashboard" clickAction={props.onGoto} isOutlineStyle={true}/> 
            </div>
        </div>
    </Modal>
}
