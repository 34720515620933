import {
    authLogInConstant,
    authLogOutConstant,
    authSignUpConstant,
    resetPassword
} from "../constant"

// import
interface Login {
    email: string
    password: string
}

interface UpdateProps {
    code: string
    newPassword: string
    history: any
}

export const loginRequest = (userData: Login): object => {
    return { type: authLogInConstant.LOGIN_REQUEST, user: userData }
}
export const loginWithCustomToken = (userData: any): object => {
    return { type: authLogInConstant.LOGIN_WITH_CUSTOM_TOKEN, user: userData }
}

export const signupRequest = (userData: any): object => {
    return { type: authSignUpConstant.SIGNUP_REQUEST, user: userData }
}

export const signupInformation = (options: any): object => {
    return { type: authSignUpConstant.SIGNUP_INFORMATION, options }
}

export const loginSuccess = (userAuthData: object): object => {
    return { type: authLogInConstant.LOGIN_SUCCESS, user: userAuthData }
}

export const logOutRequest = (): object => {
    return { type: authLogOutConstant.LOGOUT_REQUEST }
}

export const resetPasswordRequest = (email: string, history: any): object => {
    return { type: resetPassword.RESET_REQUEST, forgotPassword: { email, history } }
}

export const verifyEmail = (code: string): object => {
    return { type: "USER_VERIFY_EMAIL", code }
}

export const verifyCode = (action: any): object => {
    return { type: "USER_VERIFY_CODE", action }
}

export const updatePassword = ({ code, newPassword, history }: UpdateProps): object => {
    return { type: "USER_UPDATE_PASSWORD", updatePassword: { code, newPassword, history } }
}

export const toggleFavorites = ({ payload }: any): object => {
    return { type: "TOGGLE_FAVORITES", payload }
}