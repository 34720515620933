import { Grid } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import ConnectedPeople from "./components/ConnectedPeople/ConnectedPeople"
import { Chat, CountEvents, useMessageCenter } from "tcd-message-center"

import { messageCenterConstant } from "store/constant";
import { unRead } from "components/icons/Icons";
import BackArrowSvgIcon from "assets/images/svgIcons/BackArrowSvgIcon";
import colors from "assets/colors";



const MessagePage = (): JSX.Element => {

    const dispatch = useDispatch();
    const messageCenter = useMessageCenter();
    const { connectedPeople, unreadCount }: { connectedPeople: Chat[], unreadCount: number } = useSelector((state: any) => state.messageCenter);
    const [currentConversation, setCurrentConversation] = useState<Chat>();
    const [showConnectedPeople, setShowConnectedPeople] = useState<boolean>(true);

    const setOpenConversation = useCallback((conversation: Chat) => {
        setCurrentConversation(conversation);
        setShowConnectedPeople(false);
    }, []);

    useEffect(() => {
        if (!messageCenter) {
            return;
        }
        if (!connectedPeople?.length) {
            messageCenter.getChats(10, 0).then((chats: Array<Chat>) => {
                dispatch({ type: messageCenterConstant.GET_CONNECTED_PEOPLE_SUCCESS, payload: chats });
            });
        } else {
            dispatch({ type: messageCenterConstant.SORT_CHATS });
        }

    }, [messageCenter]);

    return (
        <>
            <Grid container className="message-page">
                <Grid item lg={4} md={6} xs={12} className={showConnectedPeople ? "show-connected-people" : "dont-show-connected-people"} style={{ height: "100vh", padding: "37px", overflow: "auto" }}>
                    <ConnectedPeople currentConversation={currentConversation} setCurrentConversation={setOpenConversation} />
                </Grid>
                <Grid id="conversation-block" item lg={8} md={6} xs={12} className={showConnectedPeople ? "dont-show-chat" : "show-chat"} style={{ height: "100vh", padding: "0px" }}>
                    {!showConnectedPeople &&
                        <span className="back_icon" onClick={() => setShowConnectedPeople(true)}>
                            <BackArrowSvgIcon iconColor={colors.darkMango} width="32" height="32" />
                        </span>
                    }
                    {!currentConversation &&
                        <div id="no-chat-open" style={{ height: "inherit", width: "inherit", display: "flex", margin: "auto" }}>
                            <div className="unread-count-container">
                                <span className="unread-count" style={unreadCount > 9 ? { left: "70px" } : { left: "67px" }}>{`${unreadCount}`}</span>
                                <img src={unRead} alt="" style={{ height: "125px", width: "125px" }} />
                                <div className="unread-message">
                                    You have {unreadCount} unread message{unreadCount > 1 && 's'}
                                </div>
                            </div>
                        </div>
                    }
                </Grid>
            </Grid>
        </>
    )
}

export default MessagePage;
