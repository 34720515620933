
import interfaceConstant from "./interfaceConstant"


export const externalLinks: interfaceConstant.IexternalLinks = {

    TERMS_OF_USE :"https://theclassroomdoor.com/terms-of-use/",
    CANCELLATION_POLICY :"https://theclassroomdoor.com/rescheduling-cancellation-policy/",
    VISIT_HELP_CENTER:"mailto:admin@theclassroomdoor.com",
    CONTACT_TCD:"mailto:admin@theclassroomdoor.com",
    PRIVACY_POLICY:"https://theclassroomdoor.com/privacy-policy/",
    CLIENT_SUPPORT :"mailto:admin@theclassroomdoor.com",
}