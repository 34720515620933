import React, { useCallback, useEffect, useState } from "react";
import { BookingPending, PendingType, PENDING_STATUS, useBookingPending } from "../../../../../pendings";
import { useHistory } from "react-router-dom";
import moment from "moment";
import defaultAvatar from "assets/images/default-avatar.png";
import { useDispatch, useSelector } from "react-redux";
import { getPendingBookings } from "store/actions/sessionsActions";
import { singleSlotBookingByTeacherType, TutorRescheduleRequest } from "./TutorRescheduleRequest";
import firebase from "firebase";
import { slotType } from "popups/booking/teacher/popups/SingleSlotBookingRequested";

export default function TutorRescheduleRequestContainer() {

    const bookingPending: BookingPending = useBookingPending();
    const history = useHistory();
    const dispatch = useDispatch()
    const [isShown, setIsShown] = useState(false);
    const [data, setData] = useState<singleSlotBookingByTeacherType>({
        id: "",
        slots: [],
        startTime: "",
        teacherName: "",
        teacherNote: "",
        sessionLength: 0,
        rescheduleRequestedBy: "",
        isRescheduleRequest: false,
        teacherPicUrl: defaultAvatar,
    });
    const [currentPendingItem, setCurrentPendingItem] = useState<PendingType | undefined>(undefined);

    const { userId } = useSelector((state: any) => state.auth.userAuthData);

    const updateBookingData = (bookingRequest: any) => {
        const slot: slotType = bookingRequest.slots.pop();

        setData({
            slots: [slot],
            id: bookingRequest.id,
            sessionLength: slot.sessionLength,
            teacherNote: bookingRequest.comment,
            teacherName: bookingRequest.teacherName,
            isRescheduleRequest: bookingRequest?.isRescheduleRequest,
            startTime: slot.startTime,
            teacherPicUrl: bookingRequest.teacherProfilePicture || defaultAvatar,
            rescheduleRequestedBy: bookingRequest?.rescheduleMeta?.rescheduledBy,
            originalStartTime: bookingRequest?.rescheduleMeta?.previousStartTime,
        });
        setIsShown(true);
        dispatch(getPendingBookings())
    }

    const handleOneBookingPendingItem = (pendingItem: PendingType) => {
        const bookingRequest = pendingItem.data;
        if (!bookingRequest) {
            return;
        }
        if (bookingRequest.slots.length === 1 && pendingItem.status === PENDING_STATUS.CREATED) {
            updateBookingData(bookingRequest);
            setCurrentPendingItem(pendingItem);
        }
    }

    useEffect(() => {
        const pendingList = (snapshot: firebase.database.DataSnapshot) => {
            const pendingItem: PendingType = snapshot.val();
            if (pendingItem) {
                handleOneBookingPendingItem(pendingItem);
            }
        }
        bookingPending.ref.on("child_added", pendingList);
        return () => bookingPending.ref.off("child_added", pendingList);
    }, [bookingPending]);

    const onClose = useCallback(() => {
        currentPendingItem && bookingPending.resolveItem(currentPendingItem.id);
        setIsShown(false);
    }, [currentPendingItem, isShown, data]);

    return <>
        {data.isRescheduleRequest && data.rescheduleRequestedBy !== userId &&
            <TutorRescheduleRequest {...{
                ...data,
                isShown,
                onClose,
                setIsShown
            }} />}

    </>
}
