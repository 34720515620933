import React from "react"
import interfaceConstant from "../../../utils/constant/interfaceConstant"

class RapidBookIcon extends React.PureComponent<interfaceConstant.SvgIconProps> {
    render(): JSX.Element {
        const { width, height, iconColor, ...otherProps } = this.props
        return (
            <svg width={width} height={height} {...otherProps}>
                <g>
                    <path d="M21.369 3c.443 0 .803.359.803.803v.114c0 .443-.36.803-.803.803h-.24c.006.041.013.083.013.127v1.13c1.327.14 2.578.539 3.701 1.146l.478-.831c.23-.4.74-.536 1.139-.308l1.895 1.093c.4.229.537.738.307 1.138L28 9.366c-.046.082-.108.151-.175.21 1.434 1.737 2.297 3.961 2.297 6.384 0 5.536-4.504 10.04-10.04 10.04-5.537 0-10.04-4.505-10.04-10.04 0-2.422.861-4.647 2.296-6.384-.068-.058-.129-.127-.177-.209l-.662-1.151c-.23-.4-.092-.91.306-1.138L13.7 5.985c.399-.23.907-.093 1.138.307l.478.832c1.122-.607 2.375-1.007 3.702-1.148V4.847c0-.043.006-.086.013-.127h-.24c-.444 0-.803-.36-.803-.803v-.114c0-.444.36-.803.802-.803zM10.545 23.08c.415 0 .75.336.75.75v.132c0 .414-.335.75-.75.75h-3.52c-.414 0-.75-.336-.75-.75v-.131c0-.415.336-.75.75-.75h3.52zm9.536-15.556c-4.651 0-8.435 3.785-8.435 8.435 0 4.652 3.784 8.435 8.435 8.435 4.65 0 8.434-3.783 8.434-8.435 0-4.65-3.784-8.434-8.434-8.435zM8.035 19.315c.414 0 .75.336.75.75v.132c0 .414-.336.75-.75.75H3.26c-.414 0-.75-.336-.75-.75v-.132c0-.414.336-.75.75-.75h4.775zm16.572-8.163c.502-.348.629-.22.28.28l-2.783 4.025c.04.162.068.328.068.502 0 1.155-.937 2.091-2.091 2.091-1.156 0-2.092-.936-2.092-2.091 0-1.155.936-2.091 2.092-2.091.174 0 .34.028.501.067zM8.035 15.55c.414 0 .75.336.75.75v.132c0 .414-.336.75-.75.75H.75c-.414 0-.75-.336-.75-.75V16.3c0-.414.336-.75.75-.75h7.285zm0-3.765c.414 0 .75.336.75.75v.132c0 .414-.336.75-.75.75H3.26c-.414 0-.75-.336-.75-.75v-.132c0-.414.336-.75.75-.75h4.775z" />
                </g>
            </svg>
        )
    }
}

export default RapidBookIcon
