/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useCallback } from "react";
import Modal from "components/modal_/Modal";
import StudentTutorAvatar from "components/student-tutor-avatar/StudentTutorAvatar"
import '../StdTutorAcceptRequest.scss';
import moment from "moment";
import Button from "components/button/Button";
import {Note} from "components/tips/Note";
import { useIsPhoneOrTablet } from "utils/hooks/useIsPhoneOrTablet";
import { useLocation } from "react-router-dom";
import { getWindowDimensions } from "utils/helper/window-dimesions";

interface TutorAcceptRescheduledRequest {
    studentPicUrl: string,
    teacherPicUrl: string,
    onGoto: () => void,
    teacherName: string,
    sessions: {
        startTime: string,
        endTime: string,
        sessionLength: string
    }[]
    isShown: boolean,
    onClose: () => void
}


export function TutorAcceptRescheduledRequest(props: TutorAcceptRescheduledRequest) {
    const isPhoneOrTablet = useIsPhoneOrTablet();
    const dimensions = getWindowDimensions() ||false;
    const platform = localStorage.getItem("platform") ||false;
    let teacherNameSplitted=props.teacherName.split(' ')

    return <div className="tutor-student-reschedule-modal">
        <Modal
        visible={props.isShown}
        onExit={props.onClose}
    >
        <div className="modal-tutor-accept-rescheduled-request">
            <div className="imgs_div">
                <StudentTutorAvatar
                    size="100"
                    student={
                        props.studentPicUrl
                    }
                    tutor={
                        props.teacherPicUrl
                    }
                />
            </div>

            <h2>{teacherNameSplitted[0] +
                                    " " +
                                    (teacherNameSplitted[1] !== undefined
                                        ? teacherNameSplitted[1].charAt(0) + "."
                                        : "")} Accepted Your Reschedule Request</h2>
            <h4> {props.sessions.map(session => <h4>New Time: {moment(session.startTime).format("dddd, MMMM DD, YYYY")} at {moment(session.startTime).format("hh:mm A")}</h4>)}{/*Sunday, February 6, 2019 at 2:00PM*/}</h4>
           <div className="text-center py-4">
           <Button className="" title="Go to Dashboard" clickAction={props.onGoto} />
           </div>
        </div>

    </Modal>
    </div>
}
