import React, { memo } from "react"
import LessonSignUp from "./payment/LessonSignUp";
import { Elements } from '@stripe/react-stripe-js';
import { stripe } from "../../../../../../utils/helper/stripe-utils";


interface User {
    password: string
    card: string
}

const LessonSubscribeFormWeb = ({ plan, dontRenew, promoCodeId, applyingPromo }: { plan: any, dontRenew: boolean, promoCodeId: string | undefined, applyingPromo?:boolean }): JSX.Element => {

    return (
        <>
            <h2 style={{ color: "white", paddingLeft: "10px" }}> Confirmation and Payment</h2>
            <Elements stripe={stripe}>
                <LessonSignUp {...{ plan, dontRenew, promoCodeId, applyingPromo }} />
            </Elements>
        </>
    )
}
export default memo(LessonSubscribeFormWeb);

