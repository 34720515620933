import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import moment from "moment"
import {
    getWeeksData,
    formatDate,
    getPreviousDate,
    getNextDate,
} from "../../../../utils/helper/date-utils"
import { setCurrentWeek, setRangeValues } from "../../../../store/actions/BookingActions"
import { GlobeIcon, forwardIcon, backwardIcon } from "../../../../components/icons/Icons"
import { defaultTimeZone } from "../../../../utils/helper/Timezone"
import { timeZoneSoftReturn } from "utils/helper/softTimeZone-utils"

const WeekNavigationPanel = () => {
    const dispatch = useDispatch()
    const currentWeek = useSelector((state: any) => state.booking.currentWeek)
    const { userAuthData } = useSelector((state: any) => state.auth)
    const {defaultTimeZone: userTimeZone } = userAuthData
    const studentTimeZone = timeZoneSoftReturn(userTimeZone || defaultTimeZone).name

    const changeAvailabilityWeek = (date: any) => {
        const rv = [
            { id: 0, value: 15 },
            { id: 1, value: 15 },
            { id: 2, value: 15 },
            { id: 3, value: 15 },
            { id: 4, value: 15 },
            { id: 5, value: 15 },
            { id: 6, value: 15 },
        ]
        const currentWeek = getWeeksData(date)
        dispatch(setCurrentWeek({ currentWeek: currentWeek }))
        dispatch(setRangeValues({ rangeValues: rv }))

    }
    const sixMonthsFromNow = () => {
        const d = new Date()
        d.setMonth(d.getMonth() + 6)
        return d.toLocaleDateString()
    }
    return (
        <div className="navigation-panel">
            <div className="nav-button nav-prev">
                <div
                    style={{ paddingRight: '4px', paddingTop: '3px' }}
                    onClick={() => {
                        if (moment(getPreviousDate(currentWeek[0], 1)).isBefore(new Date())) {
                            return
                        }
                        changeAvailabilityWeek(getPreviousDate(currentWeek[0], 1))
                    }}
                >
                    {backwardIcon({
                        style: { color: moment(getPreviousDate(currentWeek[0], 1)).isBefore(new Date()) ? "#dddee8" : "#262ffa", fontSize: "25px", cursor: "pointer" },
                    })}
                </div>
            </div>
            <div className="info-week">
                <h3>
                    {formatDate(currentWeek[0], "dddd, MMMM D, YYYY")} {" - "}
                    {formatDate(currentWeek[6], "dddd, MMMM D, YYYY")}
                </h3>

                <div className="timezone-info">
                    {GlobeIcon(null)}
                    <div className="timezone-text">
                        {` All times are in your computer's current time zone (${defaultTimeZone})`}
                    </div>
                </div>
            </div>
            <div className="nav-button nav-next">
                <div
                    style={{ paddingLeft: '4px', paddingTop: '3px' }}
                    onClick={() => {
                        if (moment(getPreviousDate(currentWeek[6], 1)).isSameOrAfter(sixMonthsFromNow())) {
                            return
                        }
                        changeAvailabilityWeek(getNextDate(currentWeek[6], 1))
                    }}
                >
                    {forwardIcon({
                        style: { color: moment(getPreviousDate(currentWeek[6], 1)).isSameOrAfter(sixMonthsFromNow()) ? "#dddee8" : "#262ffa", fontSize: "25px", cursor: "pointer" },
                    })}
                </div>
            </div>
        </div>
    )
}

export default WeekNavigationPanel
