import { createStudentThroughBackend, createTeacherThroughBackend } from "utils/services/userServices"
import firebase from "../../utils/config/firebase"
import { applicationConstant } from "../constant/applicationConstant"
import parseFirestoreResponse from "firestore-parser";

const userTypeKeys = applicationConstant.userTypeKeys
export const firestore = firebase.firestore()
export const storage = firebase.storage()
firestore.settings({
    experimentalAutoDetectLongPolling: true,
    ignoreUndefinedProperties: true
});
export const functions = firebase.functions();
export const database = firebase.database()
export const firebaseAuth = firebase.auth()
export const confirmPasswordReset: any = [firebaseAuth, firebaseAuth.confirmPasswordReset]
export const applyActionCode: any = [firebaseAuth, firebaseAuth.applyActionCode]
export const checkActionCode: any = [firebaseAuth, firebaseAuth.checkActionCode]
export const noOfEpicboardTabs = 1

export const createUserWithEmailAndPassword: any = [
    firebaseAuth,
    firebaseAuth.createUserWithEmailAndPassword,
]
export const sendPasswordResetEmail: any = [firebaseAuth, firebaseAuth.sendPasswordResetEmail]
export const signInWithEmailAndPassword: any = [
    firebaseAuth,
    firebaseAuth.signInWithEmailAndPassword,
]
export const signInWithCustomToken: any = [
    firebaseAuth,
    firebaseAuth.signInWithCustomToken
]
interface DataProps {
    fileName?: string | any
    file: File | any
    filePath: string
}

export const fileUpload = async ({ fileName, file, filePath }: DataProps) => {
    const task = storage.ref(`${filePath}${fileName}`).putString(file, "data_url")
    const url = task
        .then((snapshot) => snapshot.ref.getDownloadURL())
        .then((url) => {
            return url
        })
    return url
}

export const createDataFirestore = async (table: string, uid: string, data: any) => {
    data.creationTime = firebase.firestore.FieldValue.serverTimestamp();
    try {
        await firestore.collection(table).doc(uid).set(data);
    } catch (e) {
        console.error("Error while creating: ", e);
        //now try again
        await firestore.collection(table).doc(uid).set(data);
    }
}

export const updateDataFirestore = (table: string, uid: string, data: any) => {
    data.modifiedTime = firebase.firestore.FieldValue.serverTimestamp()
    firestore.collection(table).doc(uid).set(data).catch(e => {
        console.error("Error while updating: ", e);
        //now try again
        firestore.collection(table).doc(uid).set(data).catch(e => console.error)
    })
}

export const createUser = async (userData: any, userAuth: firebase.User) => {
    const { email, firstName, lastName, notificationSubscriptions, phoneNumber, createRedirectTo } = userData;
    const { userType = "STUDENT" } = userData.options;
    const userId = userAuth.uid;
    userAuth.sendEmailVerification()
        .then(data => console.log("Email Successfully sent: ", data))
        .catch(e => console.log("Email cannot be sent. Error: ", e));

    const userDocData = {
        email: email,
        firstName: firstName,
        lastName: lastName,
        signUpBy: "emailPassword",
        userType: userType,
        isRegistered: true,
        userId,
        devices: [],
        profilePic: {},
        notificationSubscriptions: notificationSubscriptions,
        phoneNumber: phoneNumber,
        acceptTermsAndConditionVersion: 0.1,
        acceptedTermsAndConditionTimeStamp: firebase.firestore.FieldValue.serverTimestamp(),
        createRedirectTo: createRedirectTo
    };

    const {
        expectedGraduationYear,
        studentCurrentGrade,
        userLookingFor,
        studentTutoringSubjects,
        studentAdvisingTopics,
    } = userData.options;

    const studentDocData = {
        expectedGraduationYear: expectedGraduationYear?.id,
        studentCurrentGrade,
        studentLookingFor: userLookingFor,
        studentAdvisingTopics,
        studentTutoringSubjects,
        userId,
    };

    try {
        await createDataFirestore("users", userId, userDocData)
        await createDataFirestore("students", userId, studentDocData);
    } catch (e) {
        //fall back to backend solution
        console.error("Error while creating student data in firestore: ", e);
        await createStudentThroughBackend(userId, userData)
    }
}

export const createTutor = async (profilePic: any, userData: any, userAuth: firebase.User) => {
    const { email, firstName, lastName, phoneNumber, timeZone, defaultTimezoneCountry, notificationSubscriptions } = userData.tutorInformation.options
    const userType = userTypeKeys.TEACHER
    const userId = userAuth.uid;

    userAuth.sendEmailVerification()
        .then(data => console.log("Email Successfully sent: ", data))
        .catch(e => console.log("Email cannot be sent. Error: ", e));

    const userDocData = {
        email: email,
        firstName: firstName,
        lastName: lastName,
        phoneNumber: phoneNumber,
        defaultTimeZone: timeZone,
        defaultTimezoneCountry,
        devices: [],
        signUpBy: "emailPassword",
        userType: userType,
        isRegistered: true,
        userId,
        profilePic: profilePic,
        acceptTermsAndConditionVersion: 0.1,
        acceptedTermsAndConditionTimeStamp: firebase.firestore.FieldValue.serverTimestamp(),
        notificationSubscriptions: notificationSubscriptions
    };

    const {
        teacherEducationQualification,
        gradeOptions,
        certification,
        issuing,
        issuingDate,
        expirationDate,
        credentialId,
        credentialUrl,
        biography,
        resumeFile,
    } = userData.tutorEducation.options

    const { subject, test, specialization } = userData.tutorSignUp.value

    const newOptions: Array<string> = []

    interface ItemProps {
        id: string
        name: string
    }

    subject.length > 0 && subject.map((item: ItemProps) => newOptions.push(item.id))
    test.length > 0 && test.map((item: ItemProps) => newOptions.push(item.id))
    specialization.length > 0 && specialization.map((item: ItemProps) => newOptions.push(item.id))

    const teacherDocData = {
        firstName: (firstName || "").toLowerCase(),
        lastName: (lastName || "").toLowerCase(),
        oneToOneSessionRate: userData.tutorRate.individualRate,
        subjects: newOptions,
        teacherBiography: biography,
        teacherCertificateDetails: {
            certificationName: certification,
            credentialId,
            credentialUrl,
            expirationDate,
            issueDate: issuingDate,
            issuingOrganization: issuing,
        },
        teacherEducationQualification,
        teacherResume: resumeFile,
        teacherSchoolDetails: gradeOptions,
        teacherType: userData.tutorSignUp.teacherType,
        userId,
        teacherWeeklyAvailability: userData.tutorAvailability.teacherWeeklyAvailability,
    };

    try {
        await createDataFirestore("users", userId, userDocData);
        await createDataFirestore("teachers", userId, teacherDocData);
    } catch (e) {
        //fallback to backend solution
        console.error("Error while creating teacher firestore data: ", e);
        await createTeacherThroughBackend(userId, userData, profilePic);
    }
}

export const getTutorInformation = async (id: string) => {
    const tutorData = await firestore.collection("teachers").doc(id).get()
    return tutorData.data()
}
export const getStudentInformation = async (id: string) => {
    const userData = await firestore.collection("students").doc(id).get()
    return userData.data()
}

export const getReviews = async (id: string) => {
    const userData = await firestore.collection("reviews").where("addresseeId", "==", id).where("isPrivate", "==", false).get()
    return userData
}
export const getUserInformation = async (id: string) => {
    const userData = await firestore.collection("users").doc(id).get()
    return userData.data()
}

export const getSubjectsByType = async (id: string) => {
    const listData = await firestore.collection("subjectLists").doc(id).get()
    return listData.data()
}
// export const getAllUsers = async () => {
//     const users: any[] = []

//     await firestore
//         .collection("users")
//         .get()
//         .then((docSnaps) => {
//             docSnaps.forEach(async (doc: any) => {
//                 users[doc.id] = doc.data()

//                 if (doc.data().userType === "TEACHER") {
//                     await firestore
//                         .collection("teachers")
//                         .doc(doc.data().userId)
//                         .get()
//                         .then((teacherDoc: any) => {
//                             users[doc.id].teacherType = teacherDoc.data().teacherType
//                         })
//                 }
//             })
//         })
//     return Object.values(users)
// }

const addModifiedTimeStamp = (docData: object) => ({
    ...docData,
    modifiedTime: firebase.firestore.FieldValue.serverTimestamp(),
})

export const updateUser = (userId: string, userData: object) => {
    const userDoc = firestore.collection("users").doc(userId)
    return userDoc.set(addModifiedTimeStamp(userData), { merge: true })
}
export const updateTutor = (userId: string, userData: object) => {
    const userDoc = firestore.collection("teachers").doc(userId)
    return userDoc.set(addModifiedTimeStamp(userData), { merge: true })
}
export const updateStudent = (userId: string, userData: object) => {
    const userDoc = firestore.collection("students").doc(userId)
    return userDoc.set(addModifiedTimeStamp(userData), { merge: true })
}

export const updateUserMetaReview = (userID: string, data: any): any => {
    firestore.collection("user-meta").doc(userID).update({ reviews: data }).catch(e => console.error("Error while updating user-meta for reviews: ", e))
}

export const getUserMetaInfo = async (id: string) => {
    const userData = await firestore.collection("user-meta").doc(id).get()
    return userData.data()
}

export const getFavorites = async (ids: string[]) => {
    // const allTeachers = await getAllUsers()
    // return allTeachers.filter((person) => ids.includes(person.userId))
}

export const createReview = async (review: any, userId: string, teacherId: string, sessionId: string) => {
    firestore
        .collection("reviews").doc(sessionId).set(review)
        .then(docRef => {
        })
        .catch(function (error) {
            console.error("Error adding document: ", error)
        })
}

/**
 * this function returns users connected to this user mentioned by userId
 * @param userId 
 */
export const getConnectedPeople = async (userId: string) => {
    const connectedPeople = await firestore.collection("user-meta").doc(userId).collection("connected-people").get();
    return connectedPeople.docs.map(doc => doc.data());
}

export const getSession = async (sessionId: string) => {
    const sessionSanpshot = await firestore.collection("epicboard-sessions").doc(sessionId).get();
    return sessionSanpshot.data();
}

export const getDoc = async (ref: firebase.firestore.DocumentReference) => {
    return await ref.get().then(snapshot => {
        return snapshot.data();
    }).catch(e => {
        return getDocThroughRest(ref.path)
    });
}

export const getCollection = async (ref: firebase.firestore.CollectionReference) => {
    return await ref.get().then(snapshot => {
        return snapshot.docs;
    }).catch(e => {
        return getCollectionThroughRest(ref.path)
    });
}

export const getDocThroughRest = async (path: string) => {
    const baseURL = "https://firestore.googleapis.com/v1/";
    const name = `projects/${process.env.REACT_APP_FIREBASE_PROJECT_ID}/databases/(default)/documents/`;
    const queryParams = `?key=${process.env.REACT_APP_FIREBASE_KEY}`;
    const token = await firebaseAuth.currentUser?.getIdToken();
    return await fetch(`${baseURL}${name}${path}${queryParams}`, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
    }).then(res => res.json())
        .then(res => parseFirestoreResponse(res))
        .then(res => {
            if (res && res.name && res.fields) {
                return res.fields;
            } else if (res && res.error && res.error.message) {
                throw res.error;
            }
            throw res;
        });
}
export const getCollectionThroughRest = async (path: string) => {
    const baseURL = "https://firestore.googleapis.com/v1/";
    const name = `projects/${process.env.REACT_APP_FIREBASE_PROJECT_ID}/databases/(default)/documents/`;
    const queryParams = `?key=${process.env.REACT_APP_FIREBASE_KEY}`;
    const token = await firebaseAuth.currentUser?.getIdToken();
    return await fetch(`${baseURL}${name}${path}${queryParams}`, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
    }).then(res => res.json())
        .then(({ documents }) => {
            if (!documents) {
                return [];
            }
            return documents.map((doc: any) => {
                const formatted = parseFirestoreResponse(doc);
                if (formatted && formatted.name && formatted.fields) {
                    return formatted.fields;
                }
            })
        });
}