import React, { useState, useEffect } from "react"
import RequestListItem from "./RequestListItem"
import { BOOKING_REQUEST_STATUS_CODES } from "../../../store/reducers/sessions-reducers/sessionReducer"
import { SERVER_URL } from "../../../utils/constant/api/api-config"
import { useDispatch, useSelector } from "react-redux"
import BeatLoader from "react-spinners/BeatLoader"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Collapse from "@material-ui/core/Collapse"
import Divider from "@material-ui/core/Divider"
import Avatar from "@material-ui/core/Avatar"
import Button from "../../../components/button/Button"
import ChangeTimeModal from "./ui-elements/ChangeTimeModal"
import NoResultsCard from "../../../components/dashboard/NoResultsCard"
import { applicationConstant } from "../../../utils/constant/applicationConstant"
import InterfaceConstant from "../../../utils/constant/interfaceConstant"
import { navigatetoUrl } from "../../../utils/helper/helper"
import { useHistory } from "react-router-dom"
import { useStripe } from "@stripe/react-stripe-js"
import { filterNotHeldBookings } from "../../../utils/helper/session-utils";
import { useTranslation } from "react-i18next";
import { BookingPaymentStatus } from "./components/BookingPaymentStatus";
import colors from "assets/colors";
import ShareaSaleBooking from "components/AffilateMarketing/ShareaSaleBooking"


const SessionCard = (): JSX.Element => {
    const history = useHistory();
    const { t } = useTranslation(["booking"]);
    const [expanded, setExpanded] = useState(-1)
    const dashboardLabel: InterfaceConstant.IdashboardLabel = applicationConstant.dashboardLabel
    const userType = useSelector((state: any) => state.auth.userAuthData.userType)
    const sessionData = useSelector((state: any) => state.session)
    const isLoading = sessionData.bookingRequestLoading
    const stripeObject = useStripe()

    useEffect(() => {
        if (
            sessionData.lastUpdatedSessionStatus &&
            sessionData.lastUpdatedSessionStatus.stripeClientSecret
        ) {
            stripeObject
                ?.confirmCardPayment(sessionData.lastUpdatedSessionStatus.stripeClientSecret)
                .then((result: any) => {
                    if (result.error) {
                        console.log("Error Occured", result.error)
                    }
                })
        }
    }, [sessionData.lastUpdatedSessionStatus])
    useEffect(() => {
        setExpanded(-1);
    }, [sessionData.bookings]);
    const handleExpand = (i: number): any => {
        setExpanded(expanded === i ? -1 : i)
    }
    const filteredBookings = filterNotHeldBookings(sessionData.bookings.filter(
        (item: any) =>
            item.status !== BOOKING_REQUEST_STATUS_CODES.REJECTED ||
            item.status !== BOOKING_REQUEST_STATUS_CODES.CANCELLED ||
            item.status !== BOOKING_REQUEST_STATUS_CODES.CONFIRMED,
    )).sort(({ creationTime: t1 }: any, { creationTime: t2 }: any) => t1._seconds > t2._seconds ? -1 : t1._seconds < t2._seconds ? 1 : 0);
    return isLoading ? (
        <div className="float_auto">
            <BeatLoader />
        </div>
    ) : (
        <>
            {filteredBookings.length ? (
                filteredBookings.map((item: any, i: number) => {
                    return (
                        <Card className="session-request-card" key={`${i}-session-req`}>
                            <ShareaSaleBooking props={item}/>
                            <div className="session-card">
                                <div className="session-userdata session-card-content" style={{ maxWidth: "25%" }}>
                                    <Avatar
                                        src={
                                            userType === "STUDENT"
                                                ? `${SERVER_URL}/userImage?userId=${item.teacherId}`
                                                : `${SERVER_URL}/userImage?userId=${item.studentId}`
                                        }
                                    />
                                    <div
                                        className="session-card-headings session-accept-reject-card"

                                        style={{ marginLeft: "15px" }}
                                    >
                                        <h6>{t('name')}</h6>
                                        <p style={{ color: colors.darkMango }}>
                                            {userType === "STUDENT"
                                                ? item.teacherName
                                                : item.studentName}
                                        </p>
                                    </div>
                                </div>
                                <div className="session-card-headings session-accept-reject-card session-card-content session-card-content-show" style={{ maxWidth: "35%" }}>
                                    <div className="ml-2">
                                        <h6>{t('subject')}</h6>
                                        <p>
                                            {item.subjects?.join(", ")}
                                        </p>
                                    </div>
                                </div>
                                <div className="session-card-headings session-accept-reject-card session-card-content session-card-content-show" style={{ maxWidth: "35%" }}>
                                    <div className="ml-4">
                                        <h6>{t('request')}</h6>
                                        <p>{`${item.isRescheduleRequest ? 'Reschedule ' : ''}${item.initialSlots.length} ${item.initialSlots.length < 2
                                            ? "Session"
                                            : "Sessions"
                                            }`}</p>
                                    </div>
                                </div>
                                <div className="session-card-headings session-accept-reject-card session-card-content session-card-content-show" style={{ maxWidth: "20%" }}>
                                    <div className="ml-4">
                                        <BookingPaymentStatus status={item.status} userType={userType} invoicePaymentUrl={item.invoicePaymentUrl} />
                                    </div>
                                </div>
                                <div className="session-card-content-hide">
                                    <div className="session-card-headings session-accept-reject-card session-card-content" style={{ maxWidth: "50%" }}>
                                        <h6>{t('subject')}</h6>
                                        <p>
                                            {item.subjects?.join(", ")}
                                        </p>
                                    </div>
                                    <div className="session-card-headings session-accept-reject-card session-card-content" style={{ maxWidth: "25%" }}>
                                        <h6>{t('request')}</h6>
                                        <p>{`${item.isRescheduleRequest ? 'Reschedule' : ''}  ${item.initialSlots.length} ${item.initialSlots.length < 2
                                            ? "Session"
                                            : "Sessions"
                                            }`}</p>
                                    </div>
                                </div>
                                <div className="session-card-content-hide">
                                    <div className="session-card-headings session-accept-reject-card session-card-content" style={{ maxWidth: "50%" }}>
                                        <BookingPaymentStatus status={item.status} userType={userType} invoicePaymentUrl={item.invoicePaymentUrl} />
                                    </div>
                                </div>
                                <div className="d-flex request-btn-absolute" style={{ alignItems: "center" }}>
                                    {expanded == i ? (
                                        <Button
                                            isOutlineStyle={true}
                                            title={t('buttons.close_request')}
                                            clickAction={(): void => handleExpand(i)}
                                            style={{
                                                background: "white",
                                                color: "#262ffa",
                                                marginRight: "-40px",
                                                width: "140px",
                                                height: "38px",
                                                fontSize: "14px",
                                                padding: "0 10px",
                                                fontFamily: "Montserrat-Medium",
                                            }}
                                        />
                                    ) : (
                                        <Button
                                            title={t('buttons.view_request')}
                                            clickAction={(): void => handleExpand(i)}
                                            style={{
                                                marginRight: "-40px",
                                                width: "140px",
                                                height: "38px",
                                                fontSize: "14px",
                                                padding: "0 20px",
                                                fontFamily: "Montserrat-Medium",
                                            }}
                                        />
                                    )}
                                </div>

                            </div>

                            <Collapse in={expanded === i} timeout="auto" unmountOnExit>
                                <Divider />
                                <CardContent>
                                    <RequestListItem
                                        {...item}
                                        buttonLoading={isLoading}
                                        userType={userType}
                                    />
                                </CardContent>
                            </Collapse>
                        </Card>
                    )
                })
            ) : userType === "STUDENT" ? (
                <NoResultsCard
                    titleText={dashboardLabel.NO_SESSIONS_CARD_TITLE}
                    subtitleText={dashboardLabel.NO_TUTORS_CARD_SUBTITLE_2}
                    buttonText={dashboardLabel.NO_TUTORS_CARD_BUTTON_TEXT}
                    buttonAction={() => navigatetoUrl(history, "/panel/search")}
                />
            ) : (
                <NoResultsCard
                    titleText={dashboardLabel.NO_SESSIONS_CARD_TITLE}
                    subtitleText={""}
                    noButton
                />
            )}
        </>
    )
}

export default SessionCard
