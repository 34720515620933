import colors from "assets/colors"
import React from "react"
import interfaceConstant from "../../../utils/constant/interfaceConstant"

class TcdSvgLogoMob extends React.PureComponent<interfaceConstant.SvgIconProps> {
    render(): JSX.Element {
        const { width, height, iconColor, ...otherProps } = this.props
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="49px" height="32px" viewBox="0 0 49 32" version="1.1">
            <title>Group-2</title>
            <g id="UI" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Onboarding_Welcome_Start" transform="translate(-32.000000, -54.000000)" fillRule="nonzero">
                    <g id="Group-2" transform="translate(32.000000, 54.000000)">
                        <path d="M46.3672415,0.443076923 C47.7791319,0.443076923 48.9234375,1.53425347 48.9234375,2.88001476 L48.9234375,24.3053669 C48.9234375,25.6511282 47.7791319,26.741611 46.3672415,26.741611 L37.4767558,26.741611 C29.7646583,26.741611 26.8734375,31.68 26.8734375,31.68 L26.8734375,5.84068958 C26.8734375,0.561698151 34.1025772,0.443076923 37.4767558,0.443076923 L46.3672415,0.443076923 Z M44.5855186,15.0314068 C45.3846471,15.0314068 46.0314915,14.4147151 46.0314915,13.6537357 C46.0314915,12.8920625 45.3846471,12.2753709 44.5855186,12.2753709 C43.7871152,12.2753709 43.1395456,12.8920625 43.1395456,13.6537357 C43.1395456,14.4147151 43.7871152,15.0314068 44.5855186,15.0314068 L44.5855186,15.0314068 Z" id="Fill-1" fill="#262FFA"/>
                        <path d="M22.5409193,5.5939422 L22.5409193,31.9757276 C22.5409193,31.9757276 19.5845679,26.9336612 11.7014583,26.9336612 L2.6129758,26.9336612 C1.16964174,26.9336612 -0.000148262358,25.8202846 -0.000148262358,24.4462702 L-0.000148262358,2.57040221 C-0.000148262358,1.19638778 1.16964174,0.0830111296 2.6129758,0.0830111296 L11.7014583,0.0830111296 C15.1500408,0.0830111296 22.5409193,0.204122711 22.5409193,5.5939422" id="Fill-3" fill={colors.darkMango}/>
                    </g>
                </g>
            </g>
        </svg>
        )
    }
}

export default TcdSvgLogoMob