import React from "react";
import Modal from "components/modal_/Modal";
import StudentTutorRejectionAvatar from "components/student-tutor-rejection-avatar/StudentTutorRejectionAvatar"
import '../StdTutorAcceptRequest.scss';
import Button from "components/button/Button";
// import { Modal } from "@material-ui/core";
interface TutorNotConfirmedRescheduledReq {
    studentPicUrl: string,
    teacherPicUrl: string,
    onGoto: () => void,
    teacherName: string,
    isShown: boolean,
    onClose: () => void
}

export function TutorNotConfirmedRescheduledReq(props: TutorNotConfirmedRescheduledReq) {
    let teacherNameSplitted=props.teacherName.split(' ')

    return <div className="tutor-student-reschedule-modal">
    <Modal
    visible={props.isShown}
    onExit={props.onClose}
>
        <div className="modal-teacher-not-confirm-reschedule-req"> 
            <div className="imgs_div">
                <StudentTutorRejectionAvatar
                    size="100"

                    student={
                        props.studentPicUrl
                    }
                    tutor={
                        props.teacherPicUrl
                    }
                />

            </div>

          <div  style={{padding:"15px"}}>
          <h2>Sorry, {teacherNameSplitted[0] +
                                    " " +
                                    (teacherNameSplitted[1] !== undefined
                                        ? teacherNameSplitted[1].charAt(0) + "."
                                        : "")} still hasn’t confirmed.</h2>
            <h4 className="cancel-reschedule-msg">Your reschedule request has been canceled.</h4>
            <h4>Request</h4>

          </div>
            <div className="tutor-reject-reschedule-btn">
                <Button className="outlined-button-bottom-margin"  title="Book With Another Tutor" isOutlineStyle={true} clickAction={props.onGoto}/>  
            </div>
            
        </div>

        </Modal>
        </div>
}

