import {
    authLogInConstant,
    authLogOutConstant,
    buttonConstant,
    favoritesConstant,
    PaymentConstant
} from "../constant"
import { updateFavorites } from "../../utils/services/tutorSearchServices"

let userAuthData: any = localStorage.getItem("user")
userAuthData = JSON.parse(userAuthData)
const initialState = userAuthData
    ? { loggedIn: true, userAuthData }
    : {
        loggedIn: false,
        isError: false,
        isloading: false,
        userAuthData: { userId: "", stripeCards: [] },
    }

const authReducer = (state = initialState, action: any): object => {
    switch (action.type) {
        case authLogInConstant.LOGIN_REQUEST:
            return {
                isloading: true,
            }
        case authLogInConstant.LOGIN_SUCCESS:

            localStorage.setItem("user", JSON.stringify(action.user))
            return {
                loggedIn: true,
                isError: false,
                isloading: false,
                userAuthData: action.user,
            }

        case authLogInConstant.LOGIN_FAILURE:
            return {
                loggedIn: false,
                isError: true,
                isloading: false,
                userAuthData: action.user,
            }
        case authLogOutConstant.LOGOUT_SUCCESS:
            return {
                loggedIn: false,
                isError: false,
                isloading: false,
                userAuthData: action.userAuthData,
            }
        case buttonConstant.BUTTON_LOADING:
            return {
                isloading: true,
            }
        case buttonConstant.BUTTON_LOADED:
            return {
                isloading: false,
            }
        case favoritesConstant.TOGGLE_FAVORITES:
            updateFavorites(state.userAuthData.userId, action.payload)
            return {
                ...state,
                userAuthData: {
                    ...state.userAuthData,
                    favorites: action.payload,
                },
            }
        case PaymentConstant.CARD_DETAILS_ADDED: {
            const { card } = action.payload.data
            const { message } = action.payload.data
            const { stripeCards = [], stripeCustomer } = state.userAuthData
            let newState = {}
            if (card) {
                newState = {
                    ...state,
                    userAuthData: {
                        ...state.userAuthData,
                        stripeCards: [...stripeCards, card],
                        stripeCustomer: {
                            ...stripeCustomer,
                            default_source: card.id
                        }
                    },
                }
            } else if (message.code === "card_declined") {
                newState = {
                    ...state,
                    userAuthData: {
                        ...state.userAuthData,
                        stripeCards: [...stripeCards],
                    },
                }
            }
            return newState
        }
        case PaymentConstant.DELETE_CARD_SUCCESS:
            return {
                ...state,
                userAuthData: {
                    ...state.userAuthData,
                    stripeCards: action.payload?.data?.data?.stripeCards ? action.payload.data.data.stripeCards : state.userAuthData.stripeCards
                }

            }
        case PaymentConstant.UPDATE_DEFAULT_SOURCE:
            const { stripeCustomer } = state.userAuthData
            return {
                ...state,
                userAuthData: {
                    ...state.userAuthData,
                    stripeCustomer: {
                        ...stripeCustomer,
                        default_source: action.payload
                    }
                }
            }
        default:
            return state
    }
}

export default authReducer
