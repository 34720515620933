import React from "react"
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import { menuIcon } from '../../components/icons/Icons';


const MenuHeader = ({ expand }: { expand: any }): JSX.Element => {

    return (
        <>
            <IconButton
                onClick={expand}
                className="menu-btn"
            >
                {menuIcon({ height: "50px", width: "50px"})}
            </IconButton>
        </>
    )
}

export default MenuHeader
