export const educationLevel = [    
    { id: "ALL", name: "All", value: 10 },
    { id: "UNDERGRADUATE_STUDENT", name: "Current Undergraduate Student", value: 2 },
    { id: "BACHELORS_DEGREE", name: "Bachelor's Degree", value: 4 },
    { id: "GRADUATE_STUDENT", name: "Current Graduate Student", value: 5 },
    { id: "GRADUATE_DEGREE", name: "Graduate Degree",value :7},
    { id: "CERTIFIED_TEACHER", name: "Certified Teacher",value :8},
    { id: "OTHER", name: "Other", value: 11 },
]

export const TeacherEducationQualifications = {
    ALL: "All",
    UNDERGRADUATE_STUDENT: "Undergraduate Student",
    BACHELORS_DEGREE: "Bachelor’s Degree",
    GRADUATE_STUDENT: "Graduate Student",
    MASTERS_DEGREE: "Master’s Degree",
}

export const TeacherEducationLevel = [
    { id: "ALL", name: "All" },
    { id: "UNDERGRADUATE_STUDENT", name: "Undergraduate Student" },
    { id: "BACHELORS_DEGREE", name: "Undergraduate Degree" },
    { id: "GRADUATE_STUDENT", name: "Current Graduate Student" },
    { id: "MASTERS_DEGREE", name: "Master’s Degree" },
]

type EducationFieldType = keyof typeof TeacherEducationQualifications

export const school = [
    { id: "PRE_K", name: "Pre-K" },
    { id: "KINDERGARTEN", name: "Kindergarten" },
    { id: "FIRST_GRADE", name: "1st Grade" },
    { id: "SECOND_GRADE", name: "2nd Grade" },
    { id: "THIRD_GRADE", name: "3rd Grade" },
    { id: "FOURTH_GRADE", name: "4th Grade" },
    { id: "FIFTH_GRADE", name: "5th Grade" },
    { id: "SIXTH_GRADE", name: "6th Grade" },
    { id: "SEVENTHTH_GRADE", name: "7th Grade" },
    { id: "EIGHTH_GRADE", name: "8th Grade" },
    { id: "NINTH_GRADE", name: "9th Grade" },
    { id: "TENTH_GRADE", name: "10th Grade" },
    { id: "ELEVENTH_GRADE", name: "11th Grade" },
    { id: "TWELFTH_GRADE", name: "12th Grade" },
    { id: "COLLEGE_LEVEL", name: "College Level" },
    { id: "GRADUATE_SCHOOL", name: "Graduate School" },
]

const yearList = []

const currentYear = new Date().getFullYear()
let maxYear = currentYear + 9
const minYear = currentYear - 82
let id = 0

while (maxYear > minYear) {
    yearList.push({ name: maxYear.toString(), id: id + 1 })
    maxYear = maxYear - 1
    id = id + 1
}

export const year = [...yearList]
