import React from "react"
import NotFound from "../../NotFound"
import PrivateRoute from "../../../routes/PrivateRoute"
import LessonsOverviewPage from "./LessonsOverviewPage"
import LessonOptionsPage from "./LessonOptionsPage"
import { Switch } from "react-router-dom"
import LessonPage from "./LessonPage"
import ModulePage from "./ModulePage"
import LessonSubscriptionConfirmedPage from "./LessonSubscriptionConfirmedPage"
import LessonSubscribePage from "./LessonSubscribePage"

interface Iprops {
    match: any
    history: any
    userAuthData: any
    dispatch: any
}

class LessonRoutes extends React.PureComponent<Iprops> {
    render() {
        return (
            <Switch>
                <PrivateRoute exact path={this.props.match.path} component={LessonsOverviewPage} />
                <PrivateRoute
                    path={`${this.props.match.path}/options`}
                    component={LessonOptionsPage}
                />
                <PrivateRoute
                    exact
                    path={`${this.props.match.path}/:lessonId`}
                    component={LessonPage}
                />
                <PrivateRoute
                    exact
                    path={`${this.props.match.path}/:lessonId/module/:moduleId`}
                    component={ModulePage}
                />
                <PrivateRoute
                    path={`${this.props.match.path}/subscribe/:subType/confirmed`}
                    component={LessonSubscriptionConfirmedPage}
                />
                <PrivateRoute
                    path={`${this.props.match.path}/subscribe/:subType`}
                    component={LessonSubscribePage}
                />
                <PrivateRoute path="*" exact component={NotFound} />
            </Switch>
        )
    }
}

export default LessonRoutes