import React from "react";
import { useSelector } from "react-redux";
import { StudentSessionReviewContainer } from "./student/StudentSessionReviewContainer";
import { TeacherSessionReviewContainer } from "./teacher/TeacherSessionReviewContainer";

export function ReviewPopupContainer(props: any) {
    const userType = useSelector((s: any) => s?.auth?.userAuthData?.userType);
    if (!userType) {
        return <></>;
    }
    return userType === "STUDENT" ? <StudentSessionReviewContainer /> : <TeacherSessionReviewContainer />
}
