import React from "react"
import { Icon } from "../icons/Icons"

interface RoundedButtonProps {
  onPress: Function
  iconProps: any
  buttonProps?: any
  active: boolean
  className?: string
}

const RoundedButton = ({ onPress, iconProps, buttonProps, active, className }: RoundedButtonProps): JSX.Element => (
    <button className={className ? `${className} rounded-button` : "rounded-button"} onClick={(e): void => onPress(e)} disabled={!active} {...buttonProps}>
        <Icon {...iconProps} />
    </button>
)

export default RoundedButton
