import soft from 'timezone-soft'
import { softTimeZones, timeZoneCountries, timeZoneCountriesType, timeZoneCountryListType, countries } from "../constant/softtimezones"

const allTimeZones = timeZoneCountries.reduce((prev: timeZoneCountryListType, curr: timeZoneCountriesType) => {
    return prev.concat(softTimeZones[curr]);
}, [])

export const timeZoneSoftReturn = (defaultTimeZone: any) => {
    //Asia/karachi
    let timezones: any = soft(defaultTimeZone)[0]
    return {
        id: defaultTimeZone,
        name: timezones.standard.name,
        standard: timezones.standard.name,
    }

}
export const timeZoneAlreadyAvailable = (defaultTimeZone: any) => {

    const timezonefiltered = allTimeZones.filter((timeZone: any) => timeZone.id === defaultTimeZone).shift()
    if (timezonefiltered) {
        return timezonefiltered
    }
    else {
        let timezoneStandard: any = soft(defaultTimeZone)[0]
        const timezonefiltered = allTimeZones.filter((timeZone: any) => timeZone.standard === timezoneStandard?.standard?.name).shift()
        if (timezonefiltered) {
            return timezonefiltered
        }
    }
    return false

}

export const extractDefaultTimezonCountry = (countryName: string) => {
    const filteredCountry = countries.filter((country: {
        id: timeZoneCountriesType,
        value: timeZoneCountriesType,
        name: timeZoneCountriesType
    }) => country.id === countryName).shift()
    if (filteredCountry) {
        return filteredCountry
    }
    return countries[0]
}