import React from "react"
import { Route, RouteProps } from "react-router-dom"

interface OnboardingRouteProps extends RouteProps {
    component: any
}

const OnboardingRoute = (props: OnboardingRouteProps): JSX.Element => {
    const { component: Component, ...rest } = props
    return (
        <Route
            {...rest}
            render={(routeProps) =>
              (
                    <Component {...routeProps} />
                ) 
            }
        />
    )
}
export default OnboardingRoute
