import React, { useEffect } from "react"
import { Toast, ToastBody } from "reactstrap"
import { useSelector } from "react-redux"

interface Props {
    message: string
    isopen: boolean
    isOpenAction: Function
    styleClass: string
}

const ToastNotification = ({ isopen, styleClass, isOpenAction, message }: Props): JSX.Element => {
    const { show } = useSelector((state: any) => state.toast)
    useEffect(() => {
        if (show) {
            setTimeout(() => {
                isOpenAction()
            }, 10000)
        }
    }, [show])

    return (
        <div className="toast-notification">
            <Toast isOpen={isopen} className={`${styleClass}`} delay="2000" autohide={true}>
                <div>
                    <ToastBody>{message}</ToastBody>
                </div>
                <div className="close-toast-container">
                    <span className="close-toast" onClick={() => isOpenAction()}>
                        X
                    </span>
                </div>
            </Toast>
        </div>
    )
}

export default ToastNotification
