import React, { FC, useCallback, useEffect, useState } from "react"

import ModalWrapper from "./modal-wrapper/ModalWrapper"
import Button from "../button/Button"

type props = {
    visible: boolean
    icon?: string
    IconComponent?: FC
    title?: string
    subtitle?: string
    buttons?: Array<Button>
    children?: any
    onExit: any
    showAlways?: boolean
}

interface Button {
    title: string
    clickAction: any
    isDisabled?: boolean
    isOutlineStyle?: boolean
    isloading?: boolean
}

const SimpleModal = ({
    visible,
    onExit,
    icon,
    IconComponent,
    title,
    subtitle,
    buttons,
    children,
    showAlways
}: props): JSX.Element => {

    const content = (
        <div className="modal-box">
            {icon && <img src={icon} alt="" />}
            {IconComponent && <IconComponent />}
            {title && <h2>{title}</h2>}
            {subtitle && <p>{subtitle}</p>}
            {children && <div>{children}</div>}
            {buttons && (
                <div className="modal-buttons">
                    {buttons.map((button: Button) => (
                        <div key={button.title} className="modal-button">
                            <Button
                                title={button.title}
                                clickAction={button.clickAction}
                                isDisabled={button.isDisabled}
                                isOutlineStyle={button.isOutlineStyle}
                                isloading={button.isloading}
                                className="capitalize"
                            />
                        </div>
                    ))}
                </div>
            )}
        </div>
    )

    return <ModalWrapper visible={visible} content={content} onExit={onExit} />
}

export default SimpleModal
