import React, { Fragment, useContext, useEffect, useState } from "react"
import { useSelector } from "react-redux"
import RatingsComponent from "../../../../components/ratings/RatingsComponent"
import {
    selectedTutorSelector,
    TutorSearchStoreContext,
} from "../../../../store/tutor-search/store"

import TutorReviewsModal from "./TutorReviewsModal"

const RatingBlock = (): JSX.Element => {
    const { state } = useContext(TutorSearchStoreContext)
    const tutorInfo = selectedTutorSelector(state)
    const { userAuthData } = useSelector((state: any) => state.auth)
    const [reviewsModalOpen, setReviewsModalOpen] = useState(false)
    const handleCloseModal = () => {
        setReviewsModalOpen(false)
    }

    return (
        <>
            <div className="rating-block">
                <div className="heading">Rating</div>
                <div className="ratings-component">
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <span className="rating-count">{tutorInfo.avgRating}</span>
                        <span className="outOf"> {" / 5"}</span>
                        <span style={{ marginLeft: "15px" }}>
                            <div className="rating">
                                <RatingsComponent
                                    obtainedRating={parseFloat( userAuthData.userType =='STUDENT'?tutorInfo.avgRating:userAuthData.avgRating)}
                                    iconSize="20px"
                                />
                            </div>
                        </span>
                    </div>
                    <div
                        className="open-ratings-link"
                        onClick={(): void => setReviewsModalOpen(true)}
                    >
                        View Ratings
                    </div>
                </div>
            </div>
            <TutorReviewsModal
                open={reviewsModalOpen}
                handleCloseModal={(): void => handleCloseModal()}
            />
        </>
    )
}
export default RatingBlock
