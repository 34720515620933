import React, { useEffect, useState } from "react"
import { Container, Row, Col } from "reactstrap"

import OnboardingBackground from "../../../components/on-boarding/onboarding-background/OnboardingBackground"
import ProgressBar from "../../../components/progress-bar/ProgressBar"
import { OnBoardingHeader } from "../../../components/on-boarding/OnboardingHeader"
import TutorReviewHeader from "./TutorReviewHeader"
import TutorReviewAvailability from "./TutorReviewAvailability"
import NavigationButtons from "../../../components/on-boarding/navigation-buttons/NavigationButtons"
import { setTeacherMyprofile, tutorSignupRequest } from "../../../store/actions/TutorSignUpActions"
import { useDispatch, useSelector } from "react-redux"
import { modalConstant } from "../../../store/constant"
import { applicationConstant } from "../../../utils/constant/applicationConstant"
import InterfaceConstant from "../../../utils/constant/interfaceConstant"
import Modal from "../../../components/modal_/Modal"
import SignUpsettingIcon from "../../../assets/images/signUpsetting.png"
import { navigatetoUrl, targetForRedirection } from "../../../utils/helper/helper"
import _ from "lodash"
import colors from "../../../assets/colors"
import { withStyles } from "@material-ui/core"
import Checkbox, { CheckboxProps } from "@material-ui/core/Checkbox"
import { useTranslation } from 'react-i18next';
import { useSnackbar } from "notistack"
import parsePhoneNumber, { isValidPhoneNumber } from "libphonenumber-js"
interface Iprops {
    history: any
}

const BlueCheckbox = withStyles({
    root: {
        color: colors.blue,
        display: "inline-block",
        "&$checked": {
            color: colors.blue,
        },
    },
    checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />)



const TutorReviewPage = (props: Iprops) => {
    const { t } = useTranslation();
    const [certifyTerms, setCertifyTerms] = useState(false)
    const [acceptedTerms, setAcceptedTerms] = useState(false)
    const [optInSMS, setOptInSMS] = useState(false); // by default sms is opt out
    const [certifyTermsError, setCertifyTermsError] = useState(false)
    const [acceptedTermsError, setAcceptedTermsError] = useState(false)
    const routeUrl: InterfaceConstant.IrouteUrl = applicationConstant.routeUrl
    const modalInfo = useSelector((state: any) => state.modal)
    const tutorInformation = useSelector((state: any) => state.tutorMyprofile)
    const tutorAvailability = useSelector((state: any) => state.tutorAvailability)
    const tutorRate = useSelector((state: any) => state.tutorRate)
    const tutorEducation = useSelector((state: any) => state.tutorEducation)
    const tutorSignUp = useSelector((state: any) => state.tutorSignUp)
    const tutorReview = useSelector((state: any) => state.tutorReview)
    const { enqueueSnackbar } = useSnackbar();

    const dispatch = useDispatch()

    const navigatePrevPage = () => {
        tutorSignUp.isloading
            ? alert("Please wait...")
            : navigatetoUrl(props.history, routeUrl.TUTOR_MY_PROFILE_PAGE)
    }
    const navigateNextPage = () => {
        if (!certifyTerms || !acceptedTerms) {
            !certifyTerms && setCertifyTermsError(true)
            !acceptedTerms && setAcceptedTermsError(true)
            return
        }
        if (optInSMS === true) {
            if (Object.keys(tutorInformation.options.phoneNumber).length > 0) {
                if (!isValidPhoneNumber(tutorInformation.options.phoneNumber.countryCode + tutorInformation.options.phoneNumber.number)) {
                    enqueueSnackbar("Please go back and enter a valid Phone Number.", {
                        variant: "error",
                        autoHideDuration: 10000
                    });
                    return;
                }
            } else {
                enqueueSnackbar("Please go back and enter a valid Phone Number.", {
                    variant: "error",
                    autoHideDuration: 10000
                });
                return;
            }
        } else if (optInSMS === false) {
            if ((Object.keys(tutorInformation.options.phoneNumber).length > 0 && tutorInformation.options.phoneNumber.number === "" && !tutorInformation.options.phoneNumber.countryCode.includes("0")) || (Object.keys(tutorInformation.options.phoneNumber).length === 0)) {
                //
            } else {
                if (!isValidPhoneNumber(tutorInformation.options.phoneNumber.countryCode + tutorInformation.options.phoneNumber.number)) {
                    enqueueSnackbar("Please go back and enter a valid Phone Number.", {
                        variant: "error",
                        autoHideDuration: 10000
                    });
                    return;
                }
            }
        }
        setCertifyTermsError(false)
        setAcceptedTermsError(false)
        const data = {
            tutorInformation,
            tutorAvailability,
            tutorRate,
            tutorEducation,
            tutorSignUp,
            tutorReview,
        }
        dispatch(tutorSignupRequest(data))
    }
    const onExitModal = (): void => {
        dispatch({ type: modalConstant.MODAL_HIDE })
        if (modalInfo.title !== "Error") {
            window.location.href = "/sign-in"
        }
    }

    useEffect(() => {
        dispatch(setTeacherMyprofile({
            notificationSubscriptions: {
                sms: optInSMS
            }
        }));
    }, [optInSMS])

    const checkBoxChange = (type: string) => {
        switch (type) {
            case "acceptedTerms":
                !acceptedTerms && setAcceptedTermsError(false);
                setAcceptedTerms(!acceptedTerms);
                break;
            case "certifyTerms":
                !certifyTerms && setCertifyTermsError(false);
                setCertifyTerms(!certifyTerms);
                break;
            case "optInSMS":
                setOptInSMS(!optInSMS);
                break;
        }
    }

    return (
        <div className="tutor-review">
            <ProgressBar at="100" theme="darkMango" />
            <Modal
                title={modalInfo.title}
                visible={modalInfo.show}
                icon={modalInfo.title !== "Error" ? SignUpsettingIcon : ""}
                subtitle={modalInfo.text}
                onExit={onExitModal}
            />
            <OnboardingBackground hiddenXS />
            <OnBoardingHeader subHeading={"Review & Submit"} heading="" />
            <TutorReviewHeader
                email={tutorInformation.options.email}
                individualRate={tutorRate.individualRate}
                image={tutorInformation.options.profilePicture}
                name={tutorInformation.options.firstName + " " + tutorInformation.options.lastName}
                type={tutorSignUp.teacherType}
            />
            <TutorReviewAvailability />
            <div className="tutor-review-content no-padding no-border">
                <Row>
                    <Col xs="12" className="no-padding">
                        <BlueCheckbox
                            checked={acceptedTerms}
                            onChange={() => checkBoxChange("acceptedTerms")}
                            name="acceptedTerms"
                        />
                        <span className="checkbox-label">
                            I agree to the{" "}
                            <a
                                className="navigation"
                                href="https://theclassroomdoor.com/terms-of-use/"
                                target={targetForRedirection()}
                                rel="noopener noreferrer"
                            >
                                Terms of Use
                            </a>
                            , and{" "}
                            <a
                                className="navigation"
                                href="https://theclassroomdoor.com/rescheduling-cancellation-policy/"
                                target={targetForRedirection()}
                                rel="noopener noreferrer"
                            >
                                Rescheduling and Cancelation Policy
                            </a>.
                        </span>
                        <small
                            style={{
                                display: acceptedTermsError ? "block" : "none",
                                color: "red",
                                marginLeft: 15,
                            }}
                        >
                            You need to accept this
                        </small>
                    </Col>
                    <Col xs="12" className=" no-padding">
                        <BlueCheckbox
                            checked={certifyTerms}
                            onChange={() => checkBoxChange("certifyTerms")}
                            name="certifyTerms"
                        />
                        <span className="checkbox-label">
                            {t('review-checkbox-2')}
                        </span>
                        <small
                            style={{
                                display: certifyTermsError ? "block" : "none",
                                color: "red",
                                marginLeft: 15,
                            }}
                        >
                            You need to accept this
                        </small>
                    </Col>
                    <Col xs="12" className=" no-padding">
                        <BlueCheckbox
                            checked={optInSMS}
                            onChange={() => checkBoxChange("optInSMS")}
                            name="optInSMS"
                        />
                        <span className="checkbox-label checkbox-label-bold">
                            I agree to receive SMS/Text Messages for notifications about my account – this is highly recommended to ensure you remain up-to-date on your pending requests, cancelations, etc. Local rates may apply.
                        </span>
                    </Col>
                </Row>
            </div>
            <div className="tutor-review-content no-border nav-btn-container">
                <NavigationButtons
                    previousClick={navigatePrevPage}
                    nextClick={navigateNextPage}
                    nextLoading={tutorSignUp.isloading}
                    nextTitle="Submit"
                />
            </div>
        </div>
    )
}

export default TutorReviewPage
