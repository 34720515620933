import React from "react"

import ModalWrapper from "./ModalWrapper/ModalWrapper"
import Button from "./ModalWrapper/button/Button"

type props = {
    visible: boolean
    icon?: string
    title?: string
    subtitle?: string
    buttons?: Array<Button>
    footerButtons?: Array<Button>
    children?: any
    onExit: any
}

interface Button {
    title: string
    clickAction: any
    isDisabled?: boolean
    isOutlineStyle?: boolean
    isloading?: boolean,
    className?: string
}

const Modal = ({
    visible,
    onExit,
    icon,
    title,
    subtitle,
    buttons,
    footerButtons,
    children,
}: props): JSX.Element => {
    const content = (
        <div className="modal-box">
            {icon && <img src={icon} alt="" />}
            {title && <h2>{title}</h2>}
            {subtitle && <p>{subtitle}</p>}
            {children && <div>{children}</div>}
            {buttons && (
                <div className="modal-buttons">
                    {buttons.map((button: Button) => (
                        <div key={button.title} className="modal-button">
                            <Button
                                title={button.title}
                                clickAction={button.clickAction}
                                isDisabled={button.isDisabled}
                                isOutlineStyle={button.isOutlineStyle}
                                isloading={button.isloading}
                                className={`capitalize ${button.className}`}
                            />
                        </div>
                    ))}
                </div>
            )}
             
        <div className="modal-custom-footer">
            {footerButtons && (
                <div className="modal-footer-buttons">
                    {footerButtons.map((button: Button) => (
                        <div key={button.title} className="modal-button">
                            <Button
                                title={button.title}
                                clickAction={button.clickAction}
                                isDisabled={button.isDisabled}
                                isOutlineStyle={button.isOutlineStyle}
                                isloading={button.isloading}
                                className={`capitalize ${button.className}`}
                            />
                        </div>
                    ))}
                </div>
            )}
        </div>
        </div>
    )


    return <ModalWrapper visible={visible} content={content} onExit={onExit} />
}

export default Modal