import React from "react"
import "./underline-tabs.scss"

interface Props {
    labels: string[]
    selectedLabel: string
    onLabelClick(label: any): void
    expandFull?: boolean
}

export default class UnderLinedTabs extends React.PureComponent<Props> {
    handleTabSwitch = (label: string): void => {
        const { onLabelClick } = this.props
        onLabelClick(label)
    }
    render(): JSX.Element {
        const { labels, selectedLabel, expandFull } = this.props
        return (
            <div className="tabs-container" key={labels.toString()}>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        alignItems: "center",
                    }}
                >
                    {labels.map((label, index) => {
                        const isSelected = selectedLabel === label
                        return (
                            <div className="sort-label-container" key={`${label}-${index}`}>
                                <div
                                    className={`${isSelected ? "sort-active-label" : "sort-label"}`}
                                    onClick={(): void => this.handleTabSwitch(label)}
                                >
                                    {label}
                                </div>
                                <div
                                    className={`${isSelected ? "highlight-active" : "highlight-inactive"
                                        }`}
                                >
                                    <div></div>
                                </div>
                            </div>
                        )
                    })}

                    {
                        expandFull &&
                        <div className="sort-label-container" style={{ flexGrow: 1 }} key={'expandFull-sort-labels'}>
                            <div
                                className="sort-label"
                                onClick={(): void => { }}
                            >

                            </div>
                            <div
                                className="highlight-inactive"
                            >
                                <div></div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        )
    }
}
