import React from "react"
import interfaceConstant from "../../../utils/constant/interfaceConstant"

class ExitSvgIcon extends React.PureComponent<interfaceConstant.SvgIconProps> {
    render(): JSX.Element {
        const { width, height, iconColor, ...otherProps } = this.props
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" {...otherProps}>
                <g fill="none" fillRule="evenodd">
                    <g>
                        <path d="M0 0H23.998V23.998H0z" />
                        <path d="M0 0H23.998V23.998H0z" />
                        <path fill={iconColor} d="M12.997 3c1.059 0 1.92.897 1.92 2v2c0 .368-.287.666-.64.666h-.64c-.353 0-.64-.298-.64-.666V5.1c0-.055-.042-.1-.096-.1H5.015c-.053 0-.096.045-.096.1v12.8c0 .055.043.1.096.1h7.886c.054 0 .096-.045.096-.1V16c0-.368.287-.667.64-.667h.64c.353 0 .64.299.64.667v2c0 1.103-.861 2-1.92 2H4.92C3.861 20 3 19.103 3 18V5c0-1.103.861-2 1.92-2zm4.053 5.035c.187-.071.402-.032.546.1l3.058 2.818c.35.325.35.852.001 1.176l-3.058 2.82c-.097.087-.225.134-.355.134-.065 0-.13-.011-.192-.035-.187-.071-.309-.24-.309-.426v-1.54h-5.073c-.369 0-.668-.276-.668-.616v-1.848c0-.34.3-.616.668-.616h5.073v-1.54c0-.187.122-.355.309-.427z" />
                    </g>
                </g>
            </svg>
        )
    }
}

export default ExitSvgIcon
