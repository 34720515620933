import React, { useState, useRef, useCallback } from "react"
import Webcam from "react-webcam"
import Button from "../button/Button"
import { NavigateIcon } from "../icons/Icons"

interface Props {
    onCapture: Function
    onClear: Function
    onClose: Function
}

export const Camera = ({ onCapture, onClear, onClose }: Props): JSX.Element => {
    const [backdropOpen, setBackdropOpen] = useState(false)
    const webcamRef: any = useRef(null)
    const [imgSrc, setImgSrc]: any = useState(null)

    const capture = useCallback(() => {
        const imageSrc = webcamRef.current.getScreenshot()
        setImgSrc(imageSrc)
        onCapture(imageSrc)
    }, [webcamRef, setImgSrc])

    const clear = (): void => {
        setImgSrc(null)
        onClear()
    }
    return (
        <>
            {backdropOpen && (
                <div className="backdrop">
                    <div className="icon" onClick={(): void => onClose(false)}>
                        <NavigateIcon className="nav-icon"/>
                    </div>
                    <div className="description">
                        <h2>Allow the camera to be used.</h2>
                        <p>
                            To take pictures, click "OK" to allow the TCD to access your device's
                            camera.
                        </p>
                    </div>
                </div>
            )}
            {imgSrc ? (
                <img src={imgSrc} />
            ) : (
                <Webcam
                    audio={false}
                    ref={webcamRef}
                    screenshotFormat="image/jpeg"
                    onUserMediaError={() => setBackdropOpen(true)}
                    onUserMedia={(e: any) => setBackdropOpen(false)}
                />
            )}
            {!backdropOpen && (
                <div className="button-wrapper take-picture-button-wrapper">
                    <Button
                        title={!imgSrc ? "Take a picture" : "Try Again"}
                        clickAction={!imgSrc ? capture : clear}
                    ></Button>
                    <Button
                        title={!imgSrc ? "Close" : "Use this"}
                        clickAction={() => onClose(false)}
                        isOutlineStyle
                    ></Button>
                </div>
            )}
        </>
    )
}
