import { tutorConstant } from "../constant"

const initialState = {
    teacher: {
        firstName: ""
    },
    isloading: false
}

const tutorReducer = (state = initialState, action: any): object => {
    switch (action.type) {
        case tutorConstant.REQUEST_TUTOR:
            return {
                ...state,
                teacher: {},
                isloading: true,
                isError: false,
            }
        case tutorConstant.REQUEST_TUTOR_FAILURE:
            return {
                ...state,
                isloading: false,
                isError: true,
            }

        case tutorConstant.REQUEST_TUTOR_SUCCESS:
            return {
                ...state,
                teacher: { ...state.teacher, ...action.payload },
                isloading: false,
                filtersUpdated: false,
            }

        default:
            return state
    }
}

export default tutorReducer
