import React, { Fragment, useState, useEffect, useContext, useMemo } from "react"
import { Container, Row, Col } from "reactstrap"
import { applicationConstant } from "../../../../utils/constant/applicationConstant"
import InterfaceConstant from "../../../../utils/constant/interfaceConstant"
import * as _ from "lodash"
import BookingAvailabilityComponent from "./BookingAvailabilityComponent"
import WeekNavigationPanel from "./WeekNavigationPanel"
import RoundedButton from "../../../../components/rounded-button/RoundedButton"
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons"
import { getWeeksData } from "../../../../utils/helper/date-utils"
import {
    setCurrentWeek,
    setSubject,
    setStudentComments,
    setNonAvailableSlots,
} from "../../../../store/actions/BookingActions"
import { useDispatch, useSelector } from "react-redux"
import { InputBox } from "../../../../components/input-field/InputFields"
import Button from "../../../../components/button/Button"
import { modalConstant } from "../../../../store/constant"
import Modal from "../../../../components/modal_/Modal"
import { navigatetoUrl } from "../../../../utils/helper/helper"
import { useHistory } from "react-router-dom"
import {
    TutorSearchStoreContext,
    selectedTutorSelector,
} from "../../../../store/tutor-search/store"
import subjectsAdvisingList from "../../../../utils/constant/subjectsAndAdvisingList"
import { getNonAvailableSlots } from "../../../../utils/services/manageBookingServices"
import MultiSelectDropDown from "components/dropdown/MultiSelectDropdown"
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core"
import { useMediaQuery } from "react-responsive";
import { string } from "yup"
interface Option {
    name: string
    id: string | number
}

const BookSessionPage = (): JSX.Element => {
    const dispatch = useDispatch()
    const history = useHistory()
    const { state } = useContext(TutorSearchStoreContext)

    const selectedSubject = useSelector((st: any) => st.booking.options.subject)
    const sessionRequests = useSelector((st: any) => st.booking.options.sessionRequests)
    const modalInfo = useSelector((st: any) => st.modal)
    const currentWeek = useSelector((st: any) => st.booking.currentWeek)
    const startDateForAvailability = useSelector((st: any) => st.booking.startDateForAvailability)
    const nonAvailableSlots = useSelector((st: any) => st.booking.nonAvailableSlots)
    const teacherId = selectedTutorSelector(state).userId
    const { userId } = useSelector((state: any) => state.auth.userAuthData)
    const isRapidBooking = useSelector((state: any) => state.booking.options.rapidBook);
    const [showAlert, setShowAlert] = useState<boolean>(false);
    const isUnder768 = useMediaQuery({
        query: '(max-device-width: 768px)'
    });
    const isUnder414 = useMediaQuery({
        query: '(max-device-width: 414px)'
    });
    useEffect(() => {
        dispatch(setCurrentWeek({ currentWeek: getWeeksData() }))
    }, [])

    useEffect(() => {
        if (!selectedTutorSelector(state).firstName) {
            navigatetoUrl(history, "/panel/search")
        }
    }, []);
    const [noteState, setNote] = useMemo(() => {
        const st = {
            note: "",
        };
        return [
            st,
            (value: string) => {
                st.note = value
            }
        ];
    }, []);

    useEffect(() => {
        getNonAvailableSlots([
            teacherId, userId
        ]).then((slots: Array<any>) => dispatch(setNonAvailableSlots(slots))).catch(console.error)
    }, [startDateForAvailability])

    const bookSession: InterfaceConstant.ItutorSearchLabel = applicationConstant.tutorSearch
    const bookingLabel: InterfaceConstant.IbookingLabel = applicationConstant.bookingLabel
    const [subjectList, setSubjectLists] = useState<{ id: string; name: string }[]>([])
    const [gradeInputProps, setGradeInputProps] = useState<Option[]>(selectedSubject)
    useEffect(() => {
        dispatch(
            setSubject({
                subject: gradeInputProps,
            }),
        )
    }, [gradeInputProps])

    useEffect(() => {
        const idFilter = selectedTutorSelector(state).subjects

        const filtered = subjectsAdvisingList.filter((item: any) => {
            if (idFilter.includes(item.id)) {
                return item
            }
        })
        setSubjectLists(filtered)
    }, [])

    const capitalizeName = (name: string): string => {
        if (name && name.length > 0) {
            return name.charAt(0).toUpperCase() + name.substring(1);
        } else {
            return "";
        }
    }

    const handleClick = (): void => {
        let modalInfo = {}
        if (selectedSubject.length === 0) {
            modalInfo = {
                title: "Subject Not Selected",
                icon: "",
                text: "You need to select at least one subject",
            }
            dispatch({ type: modalConstant.MODAL_SHOW, modalInfo })
        } else if (sessionRequests.length === 0) {
            modalInfo = {
                title: "No Option",
                icon: "",
                text: "You need to select at least one option",
            }
            dispatch({ type: modalConstant.MODAL_SHOW, modalInfo })
        } else if (!noteState.note || noteState.note.length < 1) {
            setShowAlert(true);

        } else {
            navigatetoUrl(
                history,
                /* routeUrl.RESUME_AND_CONFIRM_PAGE */ "/panel/search/resume-and-confirm",
            )
        }
    }
    const onExitModal = (): void => {
        dispatch({ type: modalConstant.MODAL_HIDE })
    }
    const BookHeader = (): JSX.Element => {
        return (
            <Container>
                <Row>
                    <Col xs="12">
                        <Row>
                            <Col>
                                <div className="book-header">
                                    <RoundedButton
                                        onPress={() => {
                                            navigatetoUrl(history, "/panel/search/tutor-profile")
                                        }}
                                        iconProps={{
                                            icon: faChevronLeft,
                                            style: {
                                                color: "#262ffa",
                                                alignSelf: "center",
                                                marginRight: "2px",
                                            },
                                        }}
                                        className="book-back-button"
                                        active={true}
                                    />
                                    <h2 className="book-title">
                                        Request sessions with {" "}
                                        <span className="text-capitalize">
                                            {selectedTutorSelector(state).firstName}{" "}
                                            {selectedTutorSelector(state).lastName[0]}
                                        </span>
                                        <img
                                            src={selectedTutorSelector(state).profilePic.downloadURL}
                                            alt=""
                                            className="book-avatar"
                                        />
                                    </h2>
                                    <p className="book-subtitle">Sessions are confirmed once the tutor/advisor accepts your request within 24 hours!</p>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12" className="subject-selection">
                                <label className="book-select-subject">
                                    Select Subjects
                                </label>
                                <MultiSelectDropDown
                                    title=""
                                    placeholder={gradeInputProps.length > 0 ? "You can select multiple subjects" : "Subject list"}
                                    optionsList={subjectList}
                                    error=""
                                    subjectvalue={setGradeInputProps}
                                    selectedOption={gradeInputProps}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        )
    }
    const BookFooter = (): JSX.Element => {
        return (
            <>
                <Modal
                    title={modalInfo.title}
                    visible={modalInfo.show}
                    icon={modalInfo.icon}
                    subtitle={modalInfo.text}
                    onExit={onExitModal}
                />
                <div className="book-contact">
                    <Container>
                        <Row>
                            <Col xs="12">
                                <h2>{bookingLabel.CONTACT_TITLE}</h2>
                                <h3>
                                    {bookingLabel.CONTACT_SUBTITLE.replace("John", capitalizeName(selectedTutorSelector(state).firstName))}
                                </h3>
                            </Col>
                            <Col xs="12" md="12" lg="8" xl="9">
                                <InputBox
                                    key="student_note"
                                    title={""}
                                    placeholder={bookingLabel.CONTACT_PLACEHOLDER.replace("John", capitalizeName(selectedTutorSelector(state).firstName))}
                                    inputProps={{
                                        onChange: (e: any) => {
                                            setNote(e.target.value);
                                        }
                                    }}
                                    type="text"
                                    iconProps={""}
                                    error={false}
                                />
                            </Col>
                            <Col xs="12" md="12" lg="4" xl="3" className="actions">
                                <Button
                                    title={bookingLabel.CONTACT_BUTTON}
                                    clickAction={() => {
                                        dispatch(
                                            setStudentComments({
                                                studentComment: noteState.note
                                            }),
                                        );
                                        handleClick();
                                    }}
                                    isDisabled={false}
                                    isloading={false}
                                />
                                <p>Note: You will not be charged yet</p>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </>
        )
    }
    return (
        <Fragment>
            <div className="book-session">
                <BookHeader />
                <Container>
                    {!selectedSubject.length ? (
                        <div
                            style={{
                                height: "50vh",
                                justifyContent: "center",
                                alignItems: "center",
                                display: "flex",
                            }}
                        >

                        </div>
                    ) : (
                        <>
                            {!isUnder768 && <Row>
                                <Col xs="12">
                                    <WeekNavigationPanel />
                                </Col>
                            </Row>}
                            <Row>
                                <Col xs="12" md="12" lg="12">
                                    <BookingAvailabilityComponent
                                        availability = {
                                            selectedTutorSelector(state).teacherWeeklyAvailability
                                        }
                                        timeZone = {
                                            selectedTutorSelector(state).defaultTimeZone
                                        }
                                        nonAvailableSlots={nonAvailableSlots}
                                        isRapidBooking={isRapidBooking}
                                    />
                                </Col>
                            </Row>
                        </>
                    )}
                </Container>
                <div style={{ position: "static", bottom: 0, width: "100%" }}>
                    <BookFooter />
                </div>
                <Dialog open={showAlert} onClose={() => setShowAlert(false)} >
                    <DialogTitle className="text-warning">Information</DialogTitle>
                    <DialogContent className="p-4">
                        <p>Do you want to share what you want to learn? This will help your tutor/advisor prepare for your lesson!</p>

                    </DialogContent>
                    <DialogActions className="book-sessions-modal-btn-container" style={{ justifyContent: "center", padding: "15px 30px" }}>
                        <Button

                            title={'Yes, Please'}
                            clickAction={() => setShowAlert(false)}

                        />
                        <Button
                            isOutlineStyle
                            title={"Maybe Next Time"}
                            clickAction={(e: any) => {
                                setShowAlert(false);
                                navigatetoUrl(
                                    history,
                                /* routeUrl.RESUME_AND_CONFIRM_PAGE */ "/panel/search/resume-and-confirm",
                                )
                            }}

                        />
                    </DialogActions>

                </Dialog>
            </div>
        </Fragment>
    )
}

export default BookSessionPage