import React from "react"
import interfaceConstant from "../../../utils/constant/interfaceConstant"

class DownloadSvgIcon extends React.PureComponent<interfaceConstant.SvgIconProps> {
    render() {
        const { width, height, iconColor, ...otherProps } = this.props
        return (
            <svg width={width} height={height}  {...otherProps}>
                <g id="Icons-/-Base-/-Bas-44" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <rect id="Rectangle" x="4.4408921e-14" y="-3.59356989e-12" width="23.9975009" height="23.9975009"></rect>
                    <rect id="Rectangle" x="4.4408921e-14" y="-3.59356989e-12" width="23.9975009" height="23.9975009"></rect>
                    <path d="M14.0767548,4.00028452 C14.5760624,4.00453535 15.445577,4.06963075 15.445577,5.00870768 C15.445577,5.99995556 14.4356893,5.99995556 13.9014657,5.99995556 L6.01520095,5.99995556 C5.96226953,5.99995556 5.91930806,6.04453333 5.91930806,6.09987778 L5.91930806,6.09987778 L5.91930806,18.9001222 C5.91930806,18.9550889 5.96226953,19.0000444 6.01520095,19.0000444 L6.01520095,19.0000444 L14.0671143,19.0001762 C14.5346366,19.0026793 15.445577,19.0527417 15.445577,20.0539895 C15.445577,20.9466683 14.4392451,21 13.9971773,21 L13.9971773,21 L5.91930806,21 C4.86104206,21 4,20.1027778 4,19.0000444 L4,19.0000444 L4,5.99995556 C4,4.89722222 4.86104206,4 5.91930806,4 L5.91930806,4 Z M13.6744696,9 C13.7392046,9 13.8045074,9.01134418 13.8662139,9.0350797 C14.0534155,9.10646077 14.1756927,9.27522726 14.1756927,9.46162087 L14.1756927,9.46162087 L14.1756927,11.0016369 L19.2486846,11.0016369 C19.6174092,11.0016369 19.9166667,11.2773878 19.9166667,11.6175387 L19.9166667,11.6175387 L19.9166667,13.4654183 C19.9166667,13.8055692 19.6175985,14.0813201 19.2486846,14.0813201 L19.2486846,14.0813201 L14.175882,14.0813201 L14.175882,15.6213361 C14.175882,15.8079043 14.053794,15.9766708 13.8667818,16.0480518 C13.6793909,16.119782 13.4641754,16.0799901 13.3205091,15.9482231 L13.3205091,15.9482231 L10.2630098,13.1301542 C9.91245647,12.8050125 9.91264576,12.2779445 10.2616849,11.954199 L10.2616849,11.954199 L13.3201305,9.13508301 C13.4162866,9.04694745 13.5442424,9 13.6744696,9 Z" id="Combined-Shape" fill={iconColor} transform="translate(11.958333, 12.500000) rotate(-90.000000) translate(-11.958333, -12.500000) "></path>
                </g>
            </svg>
        )
    }
}

export default DownloadSvgIcon
