import { INTERVAL, NO_OF_SLOTS } from "pages/onboarding/tutor-availability/TutorAvailabilityPage";
import React from "react"
import { allDayTime } from "utils/constant/applicationConstant";

interface Iprops {
    updateSlots: Function
    value: number
    selectedValue: any,
    noOfSlots?: number
}

export const convertNumberToDayTime = (number: number) => {
    const timeInteger = number % 12;
    const hours = Math.floor(timeInteger) || 12;
    const mins = (timeInteger % 1) * 60 || "";
    return `${hours}${mins ? ":" : ""}${mins} ${number < 12 || number === 24 ? " AM" : " PM"}`;
}

export const getSlotIndex = (selectedValue: any[], id: number, slot: number) => {
    return selectedValue[id].findIndex((value: number) => value === slot);
}


export const getLastContinuousSlot = (selectedValue: any[], id: number, index: number, start: number, interval: number, noOfSlots: number) => {
    let i = start;
    for (let j = index; j < noOfSlots && getSlotIndex(selectedValue, id, j) !== -1; i = i + interval, j++) {
    }
    return i;
}


const TabSelection = ({ value, updateSlots, selectedValue, noOfSlots = 48 }: Iprops): JSX.Element => {

    const toggleSlot = (selectedSlot: number) => {
        const slots: any = selectedValue;
        const index = getSlotIndex(selectedValue, value, selectedSlot);
        if (index === -1) {
            slots[value].push(selectedSlot);
            updateSlots(slots);
        } else {
            slots[value].splice(index, 1);
            updateSlots(slots);
        }
    }


    const renderSlots = ({ noOfSlots, interval, start = 0 }: { noOfSlots: number, interval: number, start?: number }) => {
        let slots: any[] = [];
        let prevSlotSelected = -1;
        for (let i = start, j = 0; j < noOfSlots; i = i + interval, j++) {
            const index = getSlotIndex(selectedValue, value, j);
            const nextIndex = getSlotIndex(selectedValue, value, j + 1);
            let endTime;
            if (index !== -1) {
                endTime = convertNumberToDayTime(getLastContinuousSlot(selectedValue, value, j, i, interval, noOfSlots));
            }
            const isFirstSlot = prevSlotSelected === -1 && index !== -1;
            const isLastSlot = index !== -1 && nextIndex === -1;
            const isOnlySlot = isFirstSlot && isLastSlot;
            slots.push(<>
                {index === -1 ?
                    <>
                        <div
                            id={`slot-${j}`}
                            className={`tabs`}
                            onClick={() => toggleSlot(j)}
                        >
                            <p>{convertNumberToDayTime(i)}</p>
                        </div>
                        <div className="tab-mobile" onClick={() => toggleSlot(j)}>
                            <p>{convertNumberToDayTime(i)}</p>
                        </div>
                    </>
                    :
                    <span className={`selected ${isOnlySlot ? "both" : ""} ${isFirstSlot && !isOnlySlot ? "first-slot" : ""} ${isLastSlot && !isOnlySlot ? "last-slot" : ""}`}>
                        <>
                            <div
                                id={`slot-${j}`}
                                className={`tabs active`}
                                onClick={() => toggleSlot(j)}
                            >
                                <p>{prevSlotSelected === -1 && (selectedValue[value].length === NO_OF_SLOTS ? allDayTime : `${convertNumberToDayTime(i)}${endTime && " - " + endTime}`)}</p>
                            </div>
                            <div className="tab-mobile active" onClick={() => toggleSlot(j)}>
                                <p>{prevSlotSelected === -1 && (selectedValue[value].length === NO_OF_SLOTS ? allDayTime : `${convertNumberToDayTime(i)}${endTime && " - " + endTime}`)}</p>
                            </div>
                        </>
                    </span>

                }

            </>)
            prevSlotSelected = index;
        }
        return slots;
    }

    return (
        <div className="slot-container">
            <div className="tab-view">
                {renderSlots({ noOfSlots: noOfSlots, interval: INTERVAL })}
            </div>
        </div>
    )
}

export default TabSelection
