import { isWithinInterval, parseISO, addMinutes, differenceInMinutes, isAfter, areIntervalsOverlapping, setISOWeek, getISOWeek, Interval } from "date-fns";
import moment from "moment";
import { RapidBookingType } from "utils/hooks/useSelectedTutorRapidBookingInfo";
import { slotType, slotWithAvailability } from "./BookingSlotTabTypes";
import { AVAILABILITY_SLOT_LENGTH, MIN_THRESHOLD } from "utils/constant/BookingConstant";

export const isIntervalWithinAnyInterval = (inter: Interval, arrayOfIntervals: Interval[]): boolean => {
    return arrayOfIntervals.reduce((isAvailable: boolean, interval: Interval) => {
        try {
            if (isAvailable) {
                return true;
            }

            if (isWithinInterval(inter.start, interval) && isWithinInterval(inter.end, interval)) {
                return true;
            }
        // eslint-disable-next-line no-empty
        } catch (e) {}

        return false;
    }, false);
}

export const getCurrentDateWithTimezone = (timezone: string): number => {
    return moment.tz(moment().format("YYYY-MM-DD") + 'T00:00:00', timezone).utcOffset();
}

export const getOffsetBetweenTimezonesForDate = (timezone1: string, timezone2: string, slotInterval: number): number => {
    const timezoneOffset1 = getCurrentDateWithTimezone(timezone1);
    const timezoneOffset2 = getCurrentDateWithTimezone(timezone2);
    const offset = (timezoneOffset2 - timezoneOffset1) / slotInterval;
    return offset;
}

export const isSlotAvailable = (slot: string, slotInterval: number, availability: Date[][][], nonAvailableSlots: slotType[] = [], isRapidBooking = false, rapidBookingInfo: RapidBookingType): boolean => {
    const slotDateTime = parseISO(slot);
    const slotEndDateTime = addMinutes(slotDateTime, slotInterval - 1)

    // We do not want to include any times that have already passed
    const diffInMinutes = differenceInMinutes(slotDateTime, new Date());
    if (diffInMinutes < MIN_THRESHOLD) {
        return false;
    }

    // If the tutor has rapid booking enabled, we do not want to show any slots that are after the off time
    if (isRapidBooking && isAfter(slotDateTime, rapidBookingInfo.offTime)) {
        return false;
    }

    // Remove any slots that are not available
    const isAvailableOnTime = nonAvailableSlots.reduce((acc: boolean, curr: slotType) => {
        const intervalsOverlap = areIntervalsOverlapping(
            { 
                start: parseISO(curr.startTime), 
                end: parseISO(curr.endTime) 
            }, { 
                start: slotDateTime, 
                end: slotEndDateTime 
            });
        return acc && !intervalsOverlap
    }, true);

    const filterAvailability = availability.filter(interval => {
        return interval[0][0] !== null && interval[0][1] !== null;
    });

    const allAvailability = filterAvailability.reduce((result, dayOfIntervals) => {
        result.push(...dayOfIntervals);
        return result;
    }, []);

    const isAvailableOnRange = allAvailability.map((interval) => {
        const [f] = interval; 
        const availableIntervals = [0, 1].map((offset: number) => {
            const start = setISOWeek(f, getISOWeek(slotDateTime) + offset);
            const end = addMinutes(start, AVAILABILITY_SLOT_LENGTH)
            const first = {
                start,
                end
            };
            const start2 = setISOWeek(f, getISOWeek(slotDateTime) - offset);
            const end2 = addMinutes(start2, AVAILABILITY_SLOT_LENGTH)
            const second = {
                start: start2,
                end: end2
            }
            return [first, second];
        }).reduce((intervals, twoIntervals) => {
            intervals.push(...twoIntervals);
            return intervals;
        }, []);

        return isIntervalWithinAnyInterval({ start: slotDateTime, end: slotEndDateTime }, availableIntervals);
    }).includes(true);

    if (isRapidBooking === true) {
        return isAvailableOnTime;
    }

    const isAvailable = isAvailableOnTime && isAvailableOnRange;
    return isAvailable;
}

export const dateSlots = async (date: Date, noOfSlots: number, slotInterval: number, nonAvailableSlots: slotType[], availability: Date[][][], isRapidBooking = false, rapidBookingInfo: RapidBookingType): Promise<slotWithAvailability[]> => {
    return Promise.all([
        ...Array(noOfSlots)
    ].map((c, i) => {
        const slot = moment.utc(date).add(i * slotInterval, 'minutes').toISOString();
        return new Promise<slotWithAvailability>((resolve) => {
            setImmediate(() => {
                resolve({
                    time: slot,
                    isAvailable: isSlotAvailable(slot, slotInterval, availability, nonAvailableSlots, isRapidBooking, rapidBookingInfo),
                    message: '',
                });
            });
        });
    }));
}