import moment, { Moment } from "moment"

const PRE_ACTIVATION_BUFFER_WINDOW_MINUTES = 30 // Session will be activated to Join this much time before the Start Time

export const todaysDate = moment()
export const weekDayNameList = moment.weekdays()
export const weekDayShortNameList = moment.weekdaysShort()

export const getPreviousDate = (date: Date, numberOfDays: number) => {
    return moment(date).subtract(numberOfDays, "days")
}

export const getNextDate = (date: Date, numberOfDays: number) => {
    return moment(date).add(numberOfDays, "days")
}

export const getWeeksData = (fromDate = moment(), numberOfWeeks = 1) => {
    const startDate = moment(fromDate).startOf("week")
    const totalDays = numberOfWeeks * 7
    return [...Array(totalDays)].map((_item, dayNumber) => {
        return moment(startDate).add(dayNumber, "days")
    })
}

export const getNextSevenDays = (fromDate = moment()) => {
    const totalDays = 7
    return [...Array(totalDays)].map((_item, dayNumber) => {
        return moment(fromDate).add(dayNumber, "days")
    })
}

const calculateSessionActivationTime = (startTimeString: string) => {
    const activationTime = new Date(startTimeString)
    activationTime.setMinutes(
        activationTime.getMinutes() - PRE_ACTIVATION_BUFFER_WINDOW_MINUTES, // Session is actived PRE_ACTIVATION_BUFFER_WINDOW_MINUTES prior to the session startTime.
    )
    return activationTime
}

const calculateSessionExpiryTime = (startTimeString: string, sessionDuration: number) => {
    const expiryTime = new Date(startTimeString)
    expiryTime.setHours(expiryTime.getHours() + sessionDuration)
    return expiryTime
}

export const getSessionActivationDetails = (
    sessionStartTime: string,
    sessionDuration: number,
): object => {
    const currentTimeObject = new Date()
    const sessionActivationTimeObject = calculateSessionActivationTime(sessionStartTime)
    const sessionExpiryTimeObject = calculateSessionExpiryTime(sessionStartTime, sessionDuration)

    // Check if session has already passed or not
    const hasSessionPassed = sessionExpiryTimeObject < currentTimeObject

    // If scheduled session has not passed check if it is activated or not
    const isSessionActive = !hasSessionPassed
        ? sessionActivationTimeObject <= currentTimeObject
        : false
    return { hasSessionPassed, isSessionActive }
}

export const formatDate = (date: Date | string | number, dateFormat: any) =>
    moment(date).format(dateFormat);


export const getTimestamp = () => moment.utc().valueOf()

export const isToday = (date: Date) => moment(date).isSame(new Date(), "day")

export const isSameWeek = (date: Date) => moment(date).isSame(new Date(), "week")

export const DateFormats = {
    DEFAULT_DATE: "YYYY-MM-DD",
    SEMILONG_DATE: "ddd MMMM DD YYYY",
    LONG_DATE: "dddd, MMMM D, YYYY",
    TIME_IN_MERIDIAN: "hh:mm A",
    SHORT_DATE: "DD/MM/YYYY",
    EUA_DATE: "MM/DD/YYYY",
    FULL_DATE_TIME: "YYYY-MM-DD HH:mm",
    TIME_IN_SECONDS: "hh:mm:ss",
    TIME_IN_24: "HH:mm",
    TIME_IN_SECONDS_24: "HH:mm:ss",
}

export const timestampToTimeString = (timestamp: any) => {
    const time: any = moment(timestamp)

    const timeFormat = isToday(time)
        ? time.format("HH:mm")
        : isSameWeek(time)
            ? time.format("ddd, HH:mm")
            : time.format("MMM, D HH:mm")

    return timeFormat
}

export const minutesToHrMinString = (minutes: number): any => {
    const hrs = Math.floor(minutes / 60)
    const min = minutes % 60
    if (hrs > 0) {
        if (hrs > 1) {
            if (min) {
                return `${hrs} Hours ${min} Mins`
            } else {
                return `${hrs} Hours`
            }
        } else {
            if (min) {
                return `${hrs} Hour ${min} Mins`
            } else {
                return `${hrs} Hour`
            }
        }
    } else {
        return `${min} Mins`
    }
}

export const timeOptions = (interval: any) => {
    const noOfItems = (60 / interval) * 24
    return [...Array(noOfItems)].map((t, i) =>
        moment(new Date())
            .add(interval * i, "minutes")
            .format(DateFormats.TIME_IN_MERIDIAN),
    )
}

export const quarterTimeOptions = (): any => {
    const interval = 15
    const noOfItems = (60 / interval) * 24
    return [...Array(noOfItems)].map((t, i) => {
        const nextInterval = moment()
            .startOf("day")
            .add(interval * i, "minutes")
        const nextIntervalRounded = nextInterval.minutes(
            Math.floor(nextInterval.minutes() / 15) * 15,
        )
        return nextIntervalRounded.format(DateFormats.TIME_IN_MERIDIAN)
    })
}

export const timestampToDateFormate = (timestamp: any) => {

    if (!timestamp) {
        return;
    }
    const time: any = moment(timestamp)

    const timeFormat = isToday(time)
        ? time.format("HH:mm")
        : isSameWeek(time)
            ? time.format("ddd")
            : formatDate(time, DateFormats.EUA_DATE)

    return timeFormat
}

export const isBetweenInterval = (startTime: any, endTime: any, time = getTimestamp()): any => {
    const midTime = moment(
        formatDate(time, DateFormats.TIME_IN_SECONDS_24),
        DateFormats.TIME_IN_SECONDS_24,
    )
    return (
        midTime.isBetween(
            moment(startTime, DateFormats.TIME_IN_SECONDS_24),
            moment(endTime, DateFormats.TIME_IN_SECONDS_24),
        ) ||
        midTime.isSame(moment(startTime, DateFormats.TIME_IN_SECONDS_24)) ||
        midTime.isSame(moment(endTime, DateFormats.TIME_IN_SECONDS_24))
    )
}

export const dateTimeDifferenceInSeconds = (startTime: any, endTime = moment()) => {
    const duration = moment.duration(moment(startTime).diff(endTime))
    const seconds = duration.asSeconds()
    return seconds
}

export const formatSessionTime = (startTime: string) => {
    return `${formatDate(
        startTime,
        DateFormats.LONG_DATE,
    )} at ${formatDate(startTime, DateFormats.TIME_IN_MERIDIAN)}`;
}