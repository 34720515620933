import firebase from "../../utils/config/firebase"

export const getTutors = (filtersData?: any, orderBy?: any, pagination?: any, openSearch = false) => {
    const teachersDataResponse = firebase.functions().httpsCallable("teachers")
    return teachersDataResponse({
        actionType: openSearch ? "GET_TEACHERS_N_OPEN_SEARCH" : "GET_TEACHERS_N",
        filters: filtersData,
        orderBy,
        pagination
    })
        .then((result) => {
            return result.data
        })
        .catch((err) => {
            console.log("Error fetching Data: ", err)
        })
}

export const getTutor = (teacherId?: string) => {
    const teachersDataResponse = firebase.functions().httpsCallable("teachers")
    return teachersDataResponse({
        actionType: "GET_TEACHER_BY_ID",
        teacherId: teacherId,
    })
        .then((result) => {
            return result.data
        })
        .catch((err) => {
            console.log("Error fetching Data: ", err)
        })
}

export const updateFavorites = (studentID: string, data: any): any => {
    firebase.firestore().collection("user-meta").doc(studentID).update({ favorites: data }).catch(e => console.error("error while updating user-meta for favorites: ", e))
}