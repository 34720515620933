type TopicType = { id: string; name: string }

export type SubjectType = TopicType

export const UserTypes = {
    STUDENT: "Student",
    TEACHER: "Tutor/Advisor",
}

export type UserStateType = keyof typeof UserTypes

export interface TeacherSchoolDetail {
    id: string
    schoolName: string
    degreeName: string
    graduationYear: string
}
export type TeacherSchoolDetailsStateType = TeacherSchoolDetail[]

export type TeacherTutoringStandardizedTestsStateType = TopicType
export type TeacherTutoringSubjectStateType = TopicType

export enum TeacherEducationQualificationTypes {
    GRADUATE_DEGREE = "Graduate Degree",
    CURRENT_GRADUATE_STUDENT = "Current Graduate Student",
    UNDERGRADUATE_DEGREE = "Undergraduate Degree",
    CURRENT_UNDERGRADUATE_STUDENT = "Current Undergraduate Student",
    OTHER = "Other",
}

export type TeacherEducationQualificationOptionType = keyof typeof TeacherEducationQualificationTypes

export type TeacherEducationQualificationStateType = {
    id: TeacherEducationQualificationOptionType
    name: string
}

export interface TeacherCertificateDetailsStateType {
    certificationName: string
    issuingOrganization: string
    issueDate: string
    expirationDate: string
    credentialId: string
    credentialUrl: string
}

export type TeacherWeeklyAvailabilityStateType = { [key: number]: number[] }

export type TeacherCounsellingSubjectStateType = TopicType

export type TeacherResumeStateType = {
    type: string
    uri: string
    name: string
    size: number
    documentName: string
    offlinePath: string
}

export type TeacherBiographyStateType = string

export type TeacherPolicyPreferencesStateType = {
    freeCancellationBeforeHours: string
    chargesAfterFreeCancellationHours: string
    advanceScheduledSessionHours: string
    freeCancellationOfAdvanceScheduledSessionHours: string
    chargesAfterFreeAdvanceScheduledSessionCancellationHours: string
    groupSessionRate: string
    oneToOneSessionRate: number
}

export type TutorDetailType = {
    firstName: string
    lastName: string
    userId: string
    teacherResume: TeacherResumeStateType
    oneToOneSessionRate: number
    groupSessionRate: number
    teacherCounsellingSubjects: TeacherCounsellingSubjectStateType[]
    teacherCertificateDetails: TeacherCertificateDetailsStateType
    teacherBiography: TeacherBiographyStateType
    teacherTutoringStandardizedTests: TeacherTutoringStandardizedTestsStateType[]
    teacherTutoringSubjects: TeacherTutoringSubjectStateType[]
    teacherEducationQualification: TeacherEducationQualificationStateType
    teacherWeeklyAvailability: TeacherWeeklyAvailabilityStateType
    teacherSchoolDetails: TeacherSchoolDetailsStateType
    freeCancellationOfAdvanceScheduledSessionHours: string
    freeCancellationBeforeHours: number
    minutesTutoring: number
    teacherType: string
    subjects: Array<string>
    profilePic: {
        storagePath: string
        downloadURL: string
    }
    avgRating: string
    gender: string
    hoursTutored: string
    backgroundCheckVerified: boolean
    totalReviews: number
    reviews: Array<any>
    defaultTimeZone: "",
    rapidBooking?: {
        status: boolean,
        modifiedAt: string,
        minutes: number,
        turnedOff: "manually" | "automatically"
    },
    rapidBookingModifiedAt?: string
}

export const GenderTypes: { [key: string]: string } = {
    ALL: "All",
    MALE: "Male",
    FEMALE: "Female",
    TRANSGENDER: "Transgender",
    GENDER_QUEER: "Genderqueer",
    QUESTIONING: "Questioning",
    RATHER_NOT_SAY: "Rather not say",
    OTHER: "Other",
}
export const TeacherTypes: Record<any, any> = {
    TUTORING_AND_ADVISING: "All",
    TUTORING: "Tutoring",
    ADVISING: "Advising",
}

export type TeacherStateType = keyof typeof TeacherTypes

export type TeacherProfileDetailsStateType = {
    firstName: string
    lastName: string
    gender: keyof typeof GenderTypes
    email: string
    phoneNumber: string
    teacherType: TeacherStateType
}

export type TeacherTypesKeys = "TUTORING" | "ADVISING" | "TUTORING_AND_ADVISING"
