import React from "react"
import { exit } from "../../../../components/icons/Icons"

type props = {
    style?: any
    content: any
    visible: boolean
    onExit: any,
    customFooter?: any
}

const ModalWrapper = ({ style, content, visible, onExit, customFooter }: props): JSX.Element => {
    return (
        <div className="modal-wrapper" style={{ visibility: visible ? "visible" : "hidden" }}>
            <div className="modal-sub-wrapper" style={style}>
                <div className="modal-wrapper-exit" onClick={onExit}>
                    <img alt="" src={exit} />
                </div>
                {content}
                {customFooter}
            </div>
        </div>
    )
}

export default ModalWrapper