import React from "react"
import { CardView } from "../card/CardView"
import Button from "../button/Button"
import NoSessionImg from "../../assets/images/no-session.svg"

interface Iprops {
    titleText: string
    subtitleText: string
    buttonText?: string
    buttonAction?: any
    noButton?: boolean
    noTeacher?:boolean
}

const NoResultsCard = ({
    titleText,
    subtitleText,
    buttonText,
    buttonAction,
    noButton,
    noTeacher
}: Iprops): JSX.Element => {
    return (
        <div className="no-results-card-container">
            <CardView>
                <div className={`no-results-card ${noButton && ""}`}>
                    <p className="title-text">{titleText}</p>
                    {noTeacher && <img className="pt-2 pb-4" src={NoSessionImg} alt=""/>}
                    <p className="subtitle-text">{subtitleText}</p>
                    
                </div>
            </CardView>
            {!noButton && (<Button isOutlineStyle title={buttonText} clickAction={buttonAction} />)}
        </div>
    )
}

export default NoResultsCard
