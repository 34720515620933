import {PaymentConstant} from "../constant";

export const addCardDetails = (props: any) => {
    return {type: PaymentConstant.ADD_CARD_DETAILS, payload: props};
}

export const addCardSuccess = (props: any) => {
    return {type: PaymentConstant.CARD_DETAILS_ADDED, payload: props};
}
export const fetchPlans = () => {
    return {type: PaymentConstant.REQUEST_GET_LESSONS_PLANS};
}
export const findPlansByName = (props: any) => {
    return {
        type: PaymentConstant.FIND_PLANS_BY_PLAN_NAME,
        payload: props,
    }
}
export const findPlanSuccess = (props: any) => {
    return {
        type: PaymentConstant.FIND_PLAN_SUCCESS,
        payload: props,
    }
}

export const paymentRequest = () => {
    return {type: PaymentConstant.IS_LOADING};
}
export const fetchedPlans = (props: any) => {
    return {type: PaymentConstant.GET_LESSONS_PLANS, payload: props};
}
export const paymentMethod = (props: any) => {
    return {type: PaymentConstant.CALL_PAYMENT_METHOD, payload: props};
}

export const deleteCard = (props: any) => {
    return {type: PaymentConstant.DELETE_CARD, payload: props};
}
