import { put, all, takeLatest } from "redux-saga/effects"
import {
    tutorSearchConstant
} from "../constant"
import {
    getTutors
} from "../../utils/services/tutorSearchServices"

function* fetchTutors(props: any) {
    const { filters, orderBy, pagination, openSearch = false } = props.payload;
    try {
        const response = yield getTutors(filters, orderBy, pagination, openSearch)
        yield put({ type: tutorSearchConstant.REQUEST_TUTORS_SUCCESS, payload: { ...response, limit: pagination.limit } })
    } catch (error) {
        yield put({ type: tutorSearchConstant.REQUEST_TUTORS_FAILURE, payload: error })

        console.log(error, "error")
    }
}

export default all([
    takeLatest(tutorSearchConstant.REQUEST_TUTORS, fetchTutors),
])
