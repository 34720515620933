import React, { useState } from "react"
import * as yearList from "../../../utils/constant/yearList"
import { Row, Col } from "reactstrap"
import { useSelector } from "react-redux"
import { navigatetoUrl } from "../../../utils/helper/helper"
import { useHistory } from "react-router-dom"
import { convertNumberToDayTime, getLastContinuousSlot, getSlotIndex } from "components/tab-selection/TabSelection"
import { INTERVAL, NO_OF_SLOTS } from "../tutor-availability/TutorAvailabilityPage"
import DownArrowSvgIcon from "assets/images/svgIcons/DownArrowSvgIcon"
import colors from "assets/colors"
import PencilSvgIcon from "assets/images/svgIcons/PencilSvgIcon"


const TutorReviewAvailability = (): JSX.Element => {

    const [showSlots, setShowSlots] = useState<any>({});
    const history = useHistory();

    const tutorAvailability = useSelector(
        (state: any) => state.tutorAvailability.teacherWeeklyAvailability,
    )

    const getSelectedSlotsofADay = ({ id, noOfSlots, interval, start = 0 }: { id: number, noOfSlots: number, interval: number, start?: number }) => {
        let slots: any[] = [];
        let prevSlotSelected = -1;
        let noSlot = true;
        for (let i = start, j = 0; j < noOfSlots; i = i + interval, j++) {
            const index = getSlotIndex(tutorAvailability, id, j);
            let endTime;
            let allSlots = false;
            if (index !== -1) {
                noSlot = false;
                const lastContinuousSlot = getLastContinuousSlot(tutorAvailability, id, j, i, interval, noOfSlots);
                allSlots = (lastContinuousSlot - i) === (noOfSlots * interval);
                endTime = convertNumberToDayTime(lastContinuousSlot);
            }
            const isFirstSlot = prevSlotSelected === -1 && index !== -1;
            index !== -1 && isFirstSlot && slots.push(<>
                <span className={`selected slot both ${allSlots ? "all-slots" : ""}`} >
                    <div
                        id={`slot-${j}`}
                        className={`tabs active`}
                    >
                        <p>{allSlots ? "All slots" : `${convertNumberToDayTime(i)} - ${endTime}`}</p>
                    </div>
                </span>
                <span className={`slot-mobile`} >
                    <p>{`${allSlots ? "12 AM - 11:59 PM" : `${convertNumberToDayTime(i)} - ${endTime}`}`}</p>
                </span>
            </>)
            prevSlotSelected = index;
        }
        noSlot && slots.push(
            <i className="not-available">Not available</i>
        )
        return slots;
    }

    return (
        <>
            <div className="tutor-review-content">
                <Row className="availability">
                    <Col xs="12" sm="12" md="12" lg="5" xl="5" className="head">
                        <div className="info_edit_block">
                            <p>Availability</p>
                            <span
                                className="edit_button"
                                onClick={() => navigatetoUrl(history, "/tutor-availability")}
                            >
                                <PencilSvgIcon width="20" height="20" iconColor={colors.whiteSolid} />
                            </span>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs="12" className="tutor-review-availability">
                        {yearList.weekDay.map((item: any) => (
                            <div className="day" key={item.id}>
                                <p >{item.name}</p>
                                <div className="selected-slots"> {getSelectedSlotsofADay({ id: item.id, noOfSlots: NO_OF_SLOTS, interval: INTERVAL })} </div>
                            </div>
                        ))}
                    </Col>
                    <Col xs="12" className="tutor-review-availability-mobile">
                        {yearList.weekDay.map((item: any) => (
                            <div className="day" key={item.id}>
                                <p className={`day-title ${tutorAvailability[item.id].length > 0 ? 'selected' : ''} item`}>{item.name}</p>
                                <div
                                    className="slots-accordion"
                                    onClick={() => {
                                        setShowSlots({
                                            ...showSlots,
                                            [item.id]: !showSlots[item.id]
                                        });
                                    }}
                                >
                                    {tutorAvailability[item.id].length > 0 ?
                                        <>
                                            <p className="no-of-slots">
                                                {(tutorAvailability[item.id].length === NO_OF_SLOTS ?
                                                    "All Slots" : `
                                                     ${getSelectedSlotsofADay({ id: item.id, noOfSlots: NO_OF_SLOTS, interval: INTERVAL }).length}  ${getSelectedSlotsofADay({ id: item.id, noOfSlots: NO_OF_SLOTS, interval: INTERVAL }).length === 1 ? "Slot" : "Slots"}`
                                                )}
                                            </p>
                                            <DownArrowSvgIcon iconColor={colors.darkMango} width="19" height="19" />
                                            {showSlots[item.id] && getSelectedSlotsofADay({ id: item.id, noOfSlots: NO_OF_SLOTS, interval: INTERVAL })}
                                        </>
                                        :
                                        <i>Not Available</i>
                                    }
                                </div>
                            </div>
                        ))}
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default TutorReviewAvailability
