import React from "react"
import { useDispatch, useSelector } from "react-redux"
import ForgotPasswordForm from "./ForgotPasswordForm"
import { toastConstant } from "../../../store/constant"
import InterfaceConstant from "../../../utils/constant/interfaceConstant"
import { applicationConstant } from "../../../utils/constant/applicationConstant"
import OnBoardingCard from "../../../components/card/OnBoardingCard"
import ToastNotification from "../../../components/modal/ToastNotification"
import OnboardingBackground from "../../../components/on-boarding/onboarding-background/OnboardingBackground"
import Modal from "../../../components/modal_/Modal"
interface Iprops {
    history: any
}
const forgotPasswordLabel: InterfaceConstant.IappLabels = applicationConstant.appLabels
const ForgotPasswordPage = ({ history }: Iprops): JSX.Element => {
    const dispatch = useDispatch()
    const { message, show, isSuccess } = useSelector((state: any) => state.toast)
    const modalInfo = useSelector((state: any) => state.modal)
    const renderForgotPasswordForm = (): JSX.Element => {
        const windowUrl = window.location.search
        const params: any = new URLSearchParams(windowUrl)
        const code: string = params.get("oobCode")
        return <ForgotPasswordForm code={code} history={history} />
    }

    const onExitModal = (): void => {
        window.location.href = "/home"
    }

    return (
        <>
            <OnboardingBackground hiddenXS />
            <div className="login-flow forgot-password mobile-bg">
                <div className="login-flow-container">
                    <Modal
                        title={modalInfo.title}
                        visible={modalInfo.show}
                        icon={modalInfo.icon}
                        subtitle={modalInfo.text}
                        onExit={onExitModal}
                    />
                    <ToastNotification
                        message={message}
                        isopen={show}
                        isOpenAction={() => dispatch({ type: toastConstant.TOAST_HIDE })}
                        styleClass={isSuccess ? "success-toast" : "error-toast"}
                    />
                    <OnBoardingCard
                        title={forgotPasswordLabel.RESET_PASSWORD_PAGE_TITLE}
                        naviagtionDetails={forgotPasswordLabel.GLOBAL_NAVIGATION_DETAILS_SIGN_UP}
                        naviagtionLink={forgotPasswordLabel.GLOBAL_NAVIGATION_LINK__SIGN_UP}
                        naviagtionText={forgotPasswordLabel.GLOBAL_NAVIGATION_TEXT__SIGN_UP}
                        renderForm={renderForgotPasswordForm()}
                    />
                </div>
            </div>
        </>
    )
}

export default ForgotPasswordPage
