import React, { useCallback, useEffect, useMemo, useState } from "react"
import firebase from "firebase";
import moment from "moment";
import { BOOKING_EXTENSION_STATUSES } from "../constant/BookingConstant";
import { AvailableTimeExtensionsPopup, TimeExtensionRequestedPopup } from "../../components/TimeExtensionPopups";
import { unRead } from "../../components/icons/Icons"
import { OptionsObject, SnackbarMessage, useSnackbar } from "notistack";
import { targetForRedirection } from "utils/helper/helper";
export type UseSessionExtensionParams = {
    sessionId: string,
    bookingId: string,
    userType: string,
    services: {
        auth: firebase.auth.Auth,
        realtime: firebase.database.Database,
        functions: firebase.functions.Functions,
    },
}

export type ExtensionDataType = {
    extensionTime: number;
    bookingId: string;
    sessionId: string;
    status: string;
    teacherName: string;
    studentName: string;
    invoiceHostedUrl?: string;
    previousValue?: ExtensionDataType
}

export type sessionType = {
    status: number;
    id: string;
    bookingId: string;
    roomId: string;
    teacherId: string;
    teacherName: string;
    studentId: string;
    studentName: string;
    sessionType: string;
    teacherGroupSessionRate: number;
    teacherHourlyRate: number;
    subjects: string[];
    bookingRequestSlotId: string;
    startTime: string;
    sessionLength: number;
    extensionTime?: number;
    attendance?: string[];
    reviewPosted?: boolean;
    creationTime: string;
    modifiedTime: string;
    createdBy?: string;
    modifiedBy?: string;
}

export default function useSessionExtension({
    services,
    sessionId,
    bookingId,
    userType,
}: UseSessionExtensionParams) {
    const isStudent = userType.toUpperCase() === "STUDENT";
    const isTeacher = userType.toUpperCase() === "TEACHER";
    const realtimeBookingExtensionPath = `booking-extension-requests/${bookingId}/${sessionId}`;

    const [extensionData, setExtensionData] = useState<ExtensionDataType>({
        extensionTime: 0,
        bookingId: bookingId,
        sessionId: sessionId,
        status: "",
        teacherName: "",
        studentName: "",
    });

    const [isLoading, setIsLoading] = useState(false);
    const [sessionData, setSessionData] = useState<sessionType>();
    const [maxExtensionTime, setMaxExtensionTime] = useState(0);
    const [showReminderPopup, setShowReminderPopup] = useState(false);
    const [showPopupToTeacher, setShowPopupToTeacher] = useState(false);
    const [paidExtensionTime, setPaidExtensionTime] = useState(0); // in minutes
    const [showUnread, setShowUnread] = useState(false);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();


    const allowedExtensionTimes = useMemo(() => [15, 30, 45, 60], []); //in minutes
    const allowedTimers = useMemo(() => [10], []); //in minutes
    const availableExtensionTimes = useMemo(() => {
        return allowedExtensionTimes.filter(t => t <= maxExtensionTime);
    }, [maxExtensionTime, allowedExtensionTimes]);
    const realtimeExtensionRef = useMemo(() => {
        return services.realtime.ref(realtimeBookingExtensionPath);
    }, [realtimeBookingExtensionPath]);

    const endTimeMoment = useMemo(() => {
        return moment(sessionData?.startTime).add(sessionData?.sessionLength, "minutes").add(paidExtensionTime || 0, "minutes");
    }, [paidExtensionTime, sessionData?.startTime, sessionData?.sessionLength]);

    useEffect(() => {
        setShowUnread(!extensionData.status && !availableExtensionTimes && moment(endTimeMoment).diff(new Date(), "minutes") <= 10);
    }, [extensionData, availableExtensionTimes, endTimeMoment]);

    useEffect(() => {
        if (showReminderPopup) {
            window.location.hash = 'showExtensionPopup';
            window.location.hash = ''
        }
    }, [showReminderPopup])

    useEffect(() => {
        if (showPopupToTeacher) {
            window.location.hash = 'showExtensionConfirmationPopup';
            window.location.hash = '';
        }
    }, [showPopupToTeacher])

    /**
     * retreive session data
     */
    useEffect(() => {
        setIsLoading(true);
        services.functions.httpsCallable("sessions")({
            actionType: "GET_SESSION",
            sessionId
        }).then(({ data }) => {
            setIsLoading(false);
            if (data.status === "success") {
                setSessionData(data.data as sessionType);
            } else {
                throw new Error(data.message || data.code || `Unknow Error while retrieving session (id: ${sessionId})`);
            }
        }).catch(e => {
            setIsLoading(false);
            console.error(e);
        });
    }, [sessionId, services.auth?.currentUser?.uid, extensionData.status === BOOKING_EXTENSION_STATUSES.CONFIRMED]);

    const realtimExtensionListener = useCallback(async (snapshot: firebase.database.DataSnapshot) => {
        if (!snapshot.exists()) {
            return;
        }
        const data: ExtensionDataType = snapshot.val();
        setExtensionData(data);
    }, []);


    /**
     * listen for booking extension request changes
     */
    useEffect(() => {
        realtimeExtensionRef.on("value", realtimExtensionListener); // listen
        return () => {
            realtimeExtensionRef.off("value", realtimExtensionListener);
        }
    }, [services.auth?.currentUser?.uid, realtimeExtensionRef, realtimExtensionListener]);
    /**
     * always fetch the latest data from realtime
     * this is just an extra step to make sure
     * we always get the latest value
     */
    useEffect(() => {
        realtimeExtensionRef.once("value").then(realtimExtensionListener).catch(e => console.error(`Error while getting latest data for extension request: `, e)); // contantly listen
    }, [services.auth?.currentUser?.uid, realtimeExtensionRef, realtimExtensionListener, sessionData]);


    /**
     * Retrieve teachers availablility after this
     * session
     */
    useEffect(() => {
        setIsLoading(true);
        services.functions.httpsCallable("booking")({
            actionType: "GET_BOOKING_EXTENSION_TIME",
            bookingId,
            sessionId
        }).then(({ data }) => {
            setIsLoading(false);
            if (data.status === "success") {
                setMaxExtensionTime(data.data.maxExtensionTime);
            } else {
                throw new Error(data.message || data.code || `Unknown Error while fetch max extension time`);
            }
        }).catch(e => {
            setIsLoading(false);
            console.error(e);
        })
    }, [bookingId, sessionId, sessionData]);

    /**
     * setup timers to remind student about
     * the session expiration if it is not already extended
     * or expired or teacher is not available
     */
    useEffect(() => {
        if (!sessionData) {
            return;
        }
        //if user is not student
        if (!isStudent) {
            return;
        }
        //if session is already extended
        if (paidExtensionTime > 0) {
            return;
        }
        //if extension time is not available
        if (maxExtensionTime < 15) {
            return;
        }

        //if session is already expired
        const endTime = moment(endTimeMoment);
        const minutesLeft = endTime.diff(moment(), "minutes");
        if (minutesLeft <= 0) {
            return;
        }
        //now our logic to setup timers for reminders
        //if time left is less than the max allowed timer
        //then show once immediately and handle the rest accordingly
        let applicableTimers = allowedTimers.filter(t => t <= minutesLeft);
        const maxTimer = Math.max(...allowedTimers);
        if (maxTimer > minutesLeft) {
            applicableTimers.push(maxTimer);
        }
        const timers = applicableTimers
            .map((minutesBeforeEndTime) => {
                const time = moment(endTime).subtract(minutesBeforeEndTime, "minutes").diff(moment(), "milliseconds");
                return setTimeout(() => {
                    setShowReminderPopup(true);
                    setShowUnread(true);
                }, time);
            });
        return () => {
            //clear all the timers
            timers.map(t => clearTimeout(t));
        };
    }, [isStudent, paidExtensionTime, endTimeMoment, maxExtensionTime, sessionData]);


    /**
     * when extension is requested,
     * show requested popup to teacher to confirm
     */
    useEffect(() => {
        //is user is not teacher
        if (!isTeacher) {
            return;
        }
        //if newly requested then show popup
        if (extensionData.status === BOOKING_EXTENSION_STATUSES.REQUESTED) {
            setShowPopupToTeacher(true);
        }
    }, [isTeacher, extensionData]);

    /**
     * when extension request is rejected
     */
    useEffect(() => {
        //is user is not student
        if (!isStudent) {
            return;
        }
        if (extensionData.status === BOOKING_EXTENSION_STATUSES.CANCELLED) {
            enqueueSessionSnackbar("Sorry! your extension request is rejected.", {
                variant: "error",
                autoHideDuration: 10000
            });
        }
    }, [isStudent, extensionData]);

    /**
     * when extension request is accepted
     */
    useEffect(() => {
        //is user is not student
        if (!isStudent) {
            return;
        }
        if (extensionData.status === BOOKING_EXTENSION_STATUSES.ACCEPTED) {
            enqueueSessionSnackbar(`Your extension request for ${extensionData.extensionTime} mins is accepted. Its payment is under process.`, {
                variant: "info",
                autoHideDuration: 10000
            });
        }
    }, [isStudent, extensionData]);


    /**
     * when payment for extension request requires Action
     */
    useEffect(() => {
        //is user is not student
        if (!isStudent) {
            return;
        }
        if (extensionData.status === BOOKING_EXTENSION_STATUSES.PAYMENT_ACTION_REQUIRED) {
            enqueueSessionSnackbar(<p>{`Payment for ${extensionData.extensionTime} mins extension requires your action. `}<a href="" target={targetForRedirection()}>Pay Now!</a></p>, {
                variant: "error",
                persist: true,
            });
        }
    }, [isStudent, extensionData]);

    /**
     * when payment for extension request fails
     */
    useEffect(() => {
        //is user is not teacher
        if (!isStudent) {
            return;
        }
        if (extensionData.status === BOOKING_EXTENSION_STATUSES.PAYMENT_FAILED) {
            enqueueSessionSnackbar(<p>{`Payment for ${extensionData.extensionTime} mins extension failed. `}<a href={extensionData.invoiceHostedUrl} target={targetForRedirection()}>Pay Now!</a></p>, {
                variant: "error",
                persist: true,
            });
        }
    }, [isStudent, extensionData]);


    /**
     * when extension request payment is successful
     */
    useEffect(() => {
        if (extensionData.status === BOOKING_EXTENSION_STATUSES.CONFIRMED) {
            enqueueSessionSnackbar(`Session is extended for ${extensionData.extensionTime} mins successfully`, {
                variant: "success",
                autoHideDuration: 10000
            });
            setPaidExtensionTime(extensionData.extensionTime);
        }
    }, [extensionData.status]);

    /**
     * Set paid Extension Time
     */
    useEffect(() => {
        if (sessionData?.extensionTime || (extensionData.extensionTime && extensionData.status === BOOKING_EXTENSION_STATUSES.CONFIRMED)) {
            setPaidExtensionTime(sessionData?.extensionTime || extensionData.extensionTime);
        }
    }, [sessionData?.extensionTime, extensionData]);


    /**
     * Inform user about the time left
     */
    useEffect(() => {
        if (!sessionData) {
            return;
        }
        const timesLeft = [15, 10, 5, 3, 1];
        const endTime = moment(endTimeMoment);
        const maxTimeLeft = moment(endTime).diff(new Date(), "minutes");
        const timers = timesLeft.filter(t => t <= maxTimeLeft).map(time => {
            return setTimeout(() => {
                enqueueSessionSnackbar(`Less than ${time} mins left`, {
                    variant: "info",
                    autoHideDuration: 10000,
                });
            }, moment(endTime).subtract(time, "minutes").diff(new Date(), "milliseconds"));
        });
        return () => {
            timers.map(t => clearTimeout(t));
        }
    }, [sessionData, extensionData, endTimeMoment]);

    const enqueueSessionSnackbar = useCallback((message: SnackbarMessage, options?: OptionsObject) => {
        enqueueSnackbar(message, options);
        window.location.hash = `snackbar=${message}`;
        window.location.hash = '';
    }, [window.location]);


    const ExtensionComponent = useMemo(() => {
        return (props: any) => {
            return <>
                {showUnread &&
                    <div >
                        <img className="popUp" src={unRead} alt=" Do you want to Extended your Session ?" onClick={() => setShowReminderPopup(true)} />
                    </div>
                }
                <AvailableTimeExtensionsPopup {...{
                    show: showReminderPopup,
                    teacherName: sessionData?.teacherName || "",
                    message: "Less than 10 mins left",
                    availableExtensions: availableExtensionTimes,
                    onChoose: (selectedExtensionTime: number) => {
                        setShowReminderPopup(false);
                        setIsLoading(true);
                        services.functions.httpsCallable("booking")({
                            actionType: "CREATE_BOOKING_EXTENSION_REQUEST",
                            bookingId,
                            sessionId,
                            extensionTime: selectedExtensionTime
                        }).then(({ data }) => {
                            setIsLoading(false);
                            if (data.status !== "success") {
                                throw new Error(data.message || data.code || 'Unknown Error');
                            }
                        }).catch(e => {
                            setIsLoading(false);
                            console.error(`Error while updating booking extension status: `, e);
                            setShowReminderPopup(!paidExtensionTime); //show to student again
                        });
                    },
                    onClose: () => {
                        setShowReminderPopup(false);
                    }
                }} />
                <TimeExtensionRequestedPopup {...{
                    show: showPopupToTeacher,
                    studentName: sessionData?.studentName || "",
                    requestedMinutes: extensionData.extensionTime,
                    onClose: () => {
                        setShowPopupToTeacher(false);
                    },
                    onAccept: () => {
                        setShowPopupToTeacher(false);
                        setIsLoading(true);
                        services.functions.httpsCallable("booking")({
                            actionType: "UPDATE_BOOKING_EXTENSION_REQUEST",
                            bookingId,
                            sessionId,
                            status: BOOKING_EXTENSION_STATUSES.ACCEPTED
                        }).then(({ data }) => {
                            setIsLoading(false);
                            if (data.status !== "success") {
                                throw new Error(data.message || data.code || 'Unknown Error');
                            }
                        }).catch(e => {
                            setIsLoading(false);
                            console.error(`Error while updating booking extension status: `, e);
                            setShowPopupToTeacher(!paidExtensionTime); //show to tutor again
                        });
                    },
                    onReject: () => {
                        setShowPopupToTeacher(false);
                        setShowPopupToTeacher(false);
                        setIsLoading(true);
                        services.functions.httpsCallable("booking")({
                            actionType: "UPDATE_BOOKING_EXTENSION_REQUEST",
                            bookingId,
                            sessionId,
                            status: BOOKING_EXTENSION_STATUSES.CANCELLED
                        }).then(({ data }) => {
                            setIsLoading(false);
                            if (data.status !== "success") {
                                throw new Error(data.message || data.code || 'Unknown Error');
                            }
                        }).catch(e => {
                            setIsLoading(false);
                        });
                    }
                }} />
            </>
        }
    }, [
        showReminderPopup,
        showPopupToTeacher,
        sessionData?.studentName,
        sessionData?.teacherName,
        extensionData,
        availableExtensionTimes,
        bookingId,
        sessionId,
        isTeacher,
        isStudent,
        showUnread,
        paidExtensionTime
    ]);

    return {
        ExtensionComponent,
        sessionData,
        extensionData,
        extensionTime: paidExtensionTime,
        isLoading
    };
}
