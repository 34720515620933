import React from "react"
import Button from "../../../../components/button/Button"
import { useHistory } from "react-router-dom"
import LoaderSpinner from "../../../../components/loader/LoaderSpinner"

const SubscriptionItems = ({ products }: { products: any | undefined }): JSX.Element => {
    let history = useHistory();
    return (
        <>
        
            {(products.length > 0) ?
            
                products.map((item: any) =>
                    <div className="subscription-option-cards">
                        {item.prices.map((subItem: any, index:number) =>
                            <div className="subscription-card-holder">
                                <div className="card-view">
                                    <p className="subscription-card-title">{subItem.nickname}</p>
                                    <p className="subscription-card-price">${subItem.unit_amount_decimal ? subItem.unit_amount / 100 : subItem.unit_amount}/{subItem.metadata?.period}</p>
                                    <p className="subscription-card-text">
                                        {subItem.metadata.description}
                                    </p>
                                    <Button
                                        title={"Subscribe"}
                                        clickAction={() => {
                                            let urlName = subItem.id
                                            history.push({ pathname: "subscribe/" + urlName, state: subItem })
                                        }}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                ) : <LoaderSpinner />}
        </>
    )
}

export default SubscriptionItems