import React from "react"
import interfaceConstant from "../../../utils/constant/interfaceConstant"

class CalendarSvgIcon extends React.PureComponent<interfaceConstant.SvgIconProps> {
    render(): JSX.Element {
        const { width, height, iconColor, ...otherProps } = this.props
        return (
            <svg viewBox="0 0 24 24" {...otherProps} style={{overflow:"initial",width:"28px",height:"26px",position:"relative",right:"3px",top:"-3px"}}>
                <path
                    d="M19.25 0c.525 0 .875.347.875.867v.866h3.5C26.075 1.733 28 3.64 28 6.067v15.6C28 24.093 26.075 26 23.625 26H4.375C1.925 26 0 24.093 0 21.667v-15.6C0 3.64 1.925 1.733 4.375 1.733h3.5V.867c0-.52.35-.867.875-.867s.875.347.875.867v.866h8.75V.867c0-.52.35-.867.875-.867zm7 10.4H1.75v11.267c0 1.386 1.225 2.6 2.625 2.6h19.25c1.4 0 2.625-1.214 2.625-2.6V10.4zm-20.8 9.05c.6-.6 1.5-.6 2.1 0 .3.3.45.75.45 1.05 0 .3-.15.75-.45 1.05-.3.3-.6.45-1.05.45-.45 0-.75-.15-1.05-.45-.3-.3-.45-.75-.45-1.05 0-.45.15-.75.45-1.05zm5 0c.6-.6 1.5-.6 2.1 0 .3.3.45.75.45 1.05 0 .3-.15.75-.45 1.05-.3.3-.6.45-1.05.45-.45 0-.75-.15-1.05-.45-.3-.3-.45-.75-.45-1.05 0-.3.15-.75.45-1.05zm5 0c.6-.6 1.5-.6 2.1 0 .3.3.45.6.45 1.05 0 .45-.15.75-.45 1.05-.3.3-.6.45-1.05.45-.45 0-.75-.15-1.05-.45-.3-.3-.45-.75-.45-1.05 0-.3.15-.75.45-1.05zm5 0c.6-.6 1.5-.6 2.1 0 .3.3.45.75.45 1.05 0 .3-.15.75-.45 1.05-.3.3-.6.45-1.05.45-.45 0-.75-.15-1.05-.45-.3-.3-.45-.75-.45-1.05 0-.45.15-.75.45-1.05zm-15-5c.6-.6 1.5-.6 2.1 0 .3.3.45.75.45 1.05 0 .3-.15.75-.45 1.05-.3.3-.6.45-1.05.45-.45 0-.75-.15-1.05-.45-.3-.3-.45-.6-.45-1.05 0-.45.15-.75.45-1.05zm5 0c.6-.6 1.5-.6 2.1 0 .3.3.45.75.45 1.05 0 .3-.15.75-.45 1.05-.3.3-.6.45-1.05.45-.45 0-.75-.15-1.05-.45-.3-.3-.45-.75-.45-1.05 0-.3.15-.75.45-1.05zm5 0c.6-.6 1.5-.6 2.1 0 .3.3.45.75.45 1.05 0 .45-.15.75-.45 1.05-.3.3-.6.45-1.05.45-.45 0-.75-.15-1.05-.45-.3-.3-.45-.75-.45-1.05 0-.3.15-.75.45-1.05zm5 0c.6-.6 1.5-.6 2.1 0 .3.3.45.75.45 1.05 0 .45-.15.75-.45 1.05-.3.3-.6.45-1.05.45-.45 0-.75-.15-1.05-.45-.3-.3-.45-.75-.45-1.05 0-.3.15-.75.45-1.05zM7.875 3.467h-3.5c-1.4 0-2.625 1.213-2.625 2.6v2.6h24.5v-2.6c0-1.387-1.225-2.6-2.625-2.6h-3.5v.866c0 .52-.35.867-.875.867s-.875-.347-.875-.867v-.866h-8.75v.866c0 .52-.35.867-.875.867s-.875-.347-.875-.867v-.866z"
                    fill={iconColor}
                    fillRule="evenodd"
                />
            </svg>
        )
    }
}

export default CalendarSvgIcon
