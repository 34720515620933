import React from "react"
import { useHistory } from "react-router-dom"
import Modal from "../modal_/Modal"
import exitLessonIcon from "../../assets/images/exitLessonIcon.svg"
import { useTranslation } from 'react-i18next';


const LessonExitModal = ({ isExiting, setExiting }: { isExiting: boolean, setExiting: any }): JSX.Element => {
    let history = useHistory()
    const { t } = useTranslation();
    return (
        <>
            <Modal
                icon={exitLessonIcon}
                visible={isExiting}
                title={t('lesson-quit-modal-title')}
                subtitle={t('lesson-quit-modal-subtitle')}
                onExit={() => {
                    setExiting(false)
                }}
                buttons={[
                    {
                        title: t('lesson-quit-modal-back-btn'),
                        clickAction: () => {
                            setExiting(false)
                        },
                        isOutlineStyle: true,
                    },
                    {
                        title: t('lesson-quit-modal-quit-btn'),
                        clickAction: () => {
                            history.push("/panel/lessons")
                        },
                    },
                ]}
                showAlways={true}
            />
        </>
    )
}

export default LessonExitModal
