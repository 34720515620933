import React from "react"
import { send } from "../icons/Icons"
import ChatUsers from "./ChatUsers"
import ChatUserProfile from "./ChatUserProfile"
import CameraSvgIcon from "../../assets/images/svgIcons/CameraSvgIcon"
import _ from "lodash"
import MessageBlock from "./MessageBlock"
import ChatWindow from "../chat-window/ChatWindow"
import { useSelector } from "react-redux"

interface Iprops {
    chatId: string
}
const ChatView = ({ chatId }: Iprops) => {
    const { presenterIds } = useSelector((state: any) => state.epicBoardReducer.epicBoard)
    const renderChatBlock = (props: any, isSender: boolean) => {
        return <MessageBlock isSender={isSender} messageObject={props}></MessageBlock>
    }

    const renderToolBar = (
        props: any,
        textMessage: any,
        handleFileUpload: any,
        handleMessage: any,
        onSend: any,
    ): JSX.Element => {
        return (
            <div className="chat-input-area">
                <label htmlFor="upload-image-message">
                    <div className="camera-icon-block">
                        <CameraSvgIcon />
                        <input
                            type="file"
                            accept="image/*"
                            id="upload-image-message"
                            style={{ display: "none" }}
                            onChange={handleFileUpload}
                        />
                    </div>
                </label>
                <div className="chat-input">
                    <input
                        {...props}
                        type="text"
                        className="form-control"
                        placeholder="Type a message"
                        composerHeight={345}
                        maxComposerHeight={500}
                        isTyping={true}
                        onChange={(e) => {
                            handleMessage(e)
                        }}
                        value={textMessage}
                        onKeyDown={(event) => {
                            if (event.key === "Enter") {
                                onSend()
                            }
                        }}
                    />
                    <img
                        alt=""
                        style={{ cursor: "pointer", width: "30px" }}
                        onClick={() => onSend()}
                        src={send}
                    ></img>
                </div>
            </div>
        )
    }

    return (
        <div id="chat-view" className="chat-view">
            <ChatUserProfile presenterIds={presenterIds} />
            <ChatUsers presenterIds={presenterIds} />
            <div id="chat-messages" style={{ overflow: "auto", height: "100%" }}>
                <div style={{ height: "calc(100% - 36px)" }}>
                    <ChatWindow
                        renderBubble={renderChatBlock}
                        renderBottomToolBar={renderToolBar}
                        chatId={chatId}
                        chatType="room-chats"
                    />
                </div>
            </div>
        </div>
    )
}
export default ChatView
