import React from "react"
import { LinkRoundedIcon } from "../icons/Icons"
import DefaultAvatar from "../../assets/images/default-avatar.png";
interface Iprops {
    student: string
    tutor: string
    size: string
}
const StudentTutorAvatar = ({ student, tutor, size }: Iprops) => {
    return (
        <div className="avatars d-flex">
            <img
            onError={(e:any)=>{e.target.src=DefaultAvatar}}
                src={tutor} 
                className="img-rounded"
                style={{ width: size+"px", height: size+"px", objectFit: "cover" }}
            />
            <p className="icon">
                <LinkRoundedIcon />
            </p>
            <img
             onError={(e:any)=>{e.target.src=DefaultAvatar}}
                src={student}
                className="img-rounded"
                style={{ width: size+"px", height: size+"px" }}
            />
        </div>
    )
}

export default StudentTutorAvatar
