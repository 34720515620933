import React from "react";
import Modal from "components/modal_/Modal";
import StudentTutorRejectionAvatar from "components/student-tutor-rejection-avatar/StudentTutorRejectionAvatar"
import '../StdTutorAcceptRequest.scss';
import Button from "components/button/Button";

interface TutorRejectRescheduleRequest {
    isShown: boolean
    onClose: () => void,
    teacherName: string,
    onSearch: () => void
    rescheduleDate:string,
    rescheduleTime:string,
    studentProfilePicture: string,
    teacherProfilePicture: string,
}
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function TutorRejectRescheduleRequest(props: TutorRejectRescheduleRequest) {
    let teacherNameSplitted=props.teacherName.split(' ')
    return <Modal
        visible={props.isShown}
        onExit={props.onClose}
    >
        <div className="modal-tutor-reject-reschedule">
            <div className="imgs_div">
                <StudentTutorRejectionAvatar
                    size="100"
                    student={
                        props.studentProfilePicture
                    }
                    tutor={
                        props.teacherProfilePicture
                    }
                />
            </div>
            <h2>{teacherNameSplitted[0] +
                                    " " +
                                    (teacherNameSplitted[1] !== undefined
                                        ? teacherNameSplitted[1].charAt(0) + "."
                                        : "")}  Rejected Your Reschedule Request</h2>
            {<h4> Request:{props.rescheduleDate} at {props.rescheduleTime}</h4> }
            <div className="tutor-reject-reschedule-btn">
                <Button className="outlined-button-bottom-margin" title="Book With Another Tutor" clickAction={props.onSearch} isOutlineStyle={false} />
            </div>
        </div>
    </Modal>
}
