import React, { useState } from "react"

import { SERVER_URL } from "../../../utils/constant/api/api-config"
import { selectSubject } from "../../../utils/helper/session-utils"
import {
    DateFormats,
    formatDate,
    isBetweenInterval,
    isToday,
} from "../../../utils/helper/date-utils"

import moment from "moment"
import { joinEpicboard } from "../../../utils/services/epicRoomServices"
import { useDispatch, useSelector } from "react-redux"
import {
    epicBoardUpdate,
    epicBoardUpdateSessionInfo,
    epicBoardUpdateTutor,
} from "../../../store/actions/epicBoardActions"
import { Avatar, Grid } from "@material-ui/core"
import { useHistory } from "react-router-dom"
import Button from "../../button/Button"
import { EPICBOARD_SESSION_STATUS_CODES } from "store/constant"
import StudentAvatar from '../../student-avatar/StudentAvatar'

type relativeTime = "past" | "present" | "future"

interface Iprops {
    studentId: string
    studentName: string
    startTime: string
    subjects: any[],
    status: number
}
interface VendorData {
    newbookingId:any,
    userType :string,
    teacherName:string,
    studentName :string
}
const StudentLastDetailCard = ({
    studentId,
    studentName,
    startTime,
    subjects,
    status
}: Iprops): JSX.Element => {
    return (
        <div className="student-detail-card-container">
            <Grid container>
            <Grid item md={1} xs={1}>
                    <div className="student-card-profile-pic">
                        <StudentAvatar student={`${SERVER_URL}/userImage?userId=${studentId}` } />
                    </div>
                </Grid>

                <Grid item md={4} xs={11}>
                    <div className="student-name-subject">
                        <span className="session-student-name">{studentName}</span>
                        <br />{" "}
                        <span className="session-subject" style={{paddingRight:"20px",display:"block"}}>
                            {subjects?.map((subject: any) => (
                                <span >{subjects.length === 1? subject:subject+", "}</span>
                            ))}
                        </span>
                    </div>
                </Grid>
                <Grid item md={4} xs={10}>
                    <p className="session-label">{"Last Session"}</p>
                    {(
                        <div className="student-session-datetime">{`${formatDate(
                            startTime,
                            DateFormats.LONG_DATE,
                        )} at ${formatDate(startTime, DateFormats.TIME_IN_MERIDIAN)}`} {status === EPICBOARD_SESSION_STATUS_CODES.CANCELLED && "(Canceled)"}</div>
                    )}
                </Grid>
                <Grid item md={3} xs={10}>
                </Grid>
            </Grid>
        </div>
    )
}

export default StudentLastDetailCard;
