import { authLogInConstant, authLogOutConstant, messageCenterConstant } from "../constant"

const initialState = {
    connectedPeople: [],
    unreadCount: 0,
    isLoading: false,
}

const messageCenterReducer = (state = initialState, action: any): object => {
    switch (action.type) {
        case messageCenterConstant.GET_CONNECTED_PEOPLE:
            return {
                ...state,
                isLoading: true,
            }
        case messageCenterConstant.GET_CONNECTED_PEOPLE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                connectedPeople: action.payload
            }
        case messageCenterConstant.SET_UNREAD_COUNT:
            return {
                ...state,
                unreadCount: action.payload
            }
        case messageCenterConstant.REORDER_CHATS:
            return {
                ...state,
                connectedPeople: [action.payload, ...state?.connectedPeople?.filter((e: any) => e?.id !== action.payload?.id)]
            }
        case messageCenterConstant.SORT_CHATS:
            return {
                ...state,
                connectedPeople: [...state.connectedPeople.sort((a: any, b: any) => {
                    if ((a?.lastMessage?.createdAt || a?.meta?.createdAt) > (b?.lastMessage?.createdAt || b?.meta?.createdAt)) return -1;
                    if ((a?.lastMessage?.createdAt || a?.meta?.createdAt) < (b?.lastMessage?.createdAt || b?.meta?.createdAt)) return 1;
                    return 0;
                })]
            }
        case messageCenterConstant.UPDATE_CHATS:
            return {
                ...state,
                connectedPeople: action.payload
            }
        case authLogOutConstant.LOGOUT_SUCCESS:
            return initialState
        default:
            return state
    }
}

export default messageCenterReducer
