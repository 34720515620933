import { moduleConstant } from "../constant"

const initialState = {
    isError: false,
    isLoading: false,
    errorMessage: "",
    modules: [],
    moduleIndex: -1
}

const moduleReducer = (state = initialState, action: any): object => {
    switch (action.type) {
        case moduleConstant.MODULE_REQUEST:
            return {
                ...state,
                isLoading: true,
            }
        case moduleConstant.MODULE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                modules: [...action.payload]
            }
        default:
            return state
    }
}

export default moduleReducer
