import momentTZ from "moment-timezone"
import moment from "moment"

const DefaultTimeZone = momentTZ.tz.guess()
export const timeZonesList = momentTZ.tz.names()

export const abbrs = [
    { id: "EST", name: "Eastern Standard Time" },
    { id: "EDT", name: "Eastern Daylight Time" },
    { id: "CST", name: "Central Standard Time" },
    { id: "CDT", name: "Central Daylight Time" },
    { id: "MST", name: "Mountain Standard Time" },
    { id: "MDT", name: "Mountain Daylight Time" },
    { id: "PST", name: "Pacific Standard Time" },
    { id: "PDT", name: "Pacific Daylight Time" },
    { id: "IST", name: "Indian Standard Time" },
    { id: "GMT", name: "Greenwich Mean Time" },
]

export const timeZones = () => {
    let currentTimezone = null
    timeZonesList.forEach(function (elem, index) {
        const id = index
        let name = `${momentTZ.tz(elem).format("zz")} - ${elem}`

        if (elem === DefaultTimeZone) {
            name = `You're in ${name}`
            currentTimezone = { id, name }
        }
        this[index] = { id, name }
    }, timeZonesList)

    return {
        timeZonesList,
        currentTimezone,
    }
}

// export const defaultTimeZone = moment.tz.zone(DefaultTimeZone).abbr(Date.now())
export const defaultTimeZone = moment.tz.guess();;
