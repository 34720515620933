import { TutorEducationConstant } from "../../constant"

const initialState = {
    isError: false,
    isLoading: false,
    errorMessage: "",
    options: {
        gradeOptions: [
            {
                id: 0,
                schoolName: "",
                degreeName: "",
                graduationYear: "",
            },
        ],
        teacherEducationQualification: { value: { name: undefined, id: undefined } },
        certification: "",
        issuing: "",
        issuingDate: "",
        expirationDate: "",
        credentialId: "",
        credentialUrl: "",
        biography: "",
        resumeFile: "",
    },
}

const tutorEducationReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case TutorEducationConstant.SET_TUTOR_EDUCATION:
            return {
                options: {
                    ...state.options,
                    ...action.props,
                },
            }
        case TutorEducationConstant.CLEAR_TUTOR_EDUCATION: 
            return initialState
        default:
            return state
    }
}

export default tutorEducationReducer
