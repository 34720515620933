import { promoCodeDetailsType } from "pages/home/booking/components/PromoCode"
import React from "react"


const LessonSubscribeSubtotal = ({ subscriptionType, bookingLabel, promoCodeDetails, setPromoCodeDetails, price }: { subscriptionType: any, bookingLabel: any, promoCodeDetails: promoCodeDetailsType | undefined, setPromoCodeDetails: (a: promoCodeDetailsType | undefined) => void, price: number }): JSX.Element => {
    return (
        <div className="subtotal wrap">
            <div className="d-flex">
                <p className="width100">{subscriptionType.term}</p>
                <p className={'plan-access width100'} style={{ textAlign: "right" }}>
                    <span className={'plan-access margen-right'}>{bookingLabel.RESUME_SUBTOTAL}</span>${price}
                </p>
            </div>
            {promoCodeDetails?.id &&
                <>
                    <p className="promo-details">
                        Coupon <b>"{promoCodeDetails?.promoCode}"</b> Applied
                    </p>
                    <a href="javascript:;" onClick={() => setPromoCodeDetails(undefined)}>Remove</a>
                </>
            }
        </div>
    )
}

export default LessonSubscribeSubtotal