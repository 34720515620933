import colors from "assets/colors"
import React from "react"
import interfaceConstant from "../../../utils/constant/interfaceConstant"

class AddFilledSvgIcon extends React.PureComponent<interfaceConstant.SvgIconProps> {
    render(): JSX.Element {
        const { width, height, iconColor = colors.darkMango, ...otherProps } = this.props
        return (
            <svg width={width} height={height} viewBox="0 0 40 40" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <title>Group 6</title>
                <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="Tutors-and-Advisors---Breakpoints" transform="translate(-443.000000, -685.000000)">
                        <g id="Group-Copy-2" transform="translate(207.000000, 485.000000)">
                            <g id="Group-4" transform="translate(168.000000, 141.000000)">
                                <g id="Group-6" transform="translate(68.000000, 59.000000)">
                                    <circle id="Oval" fill={iconColor} cx="20" cy="20" r="20"></circle>
                                    <path d="M15.6064428,15.6141675 C16.118737,15.0915738 16.9493301,15.0915738 17.4616243,15.6141675 L17.4616243,15.6141675 L20.4074074,18.6638521 L23.3531906,15.6141675 C23.8654847,15.0915738 24.6960778,15.0915738 25.208372,15.6141675 C25.7206661,16.1367612 25.7206661,16.9840532 25.208372,17.5066469 L25.208372,17.5066469 L22.3962222,20.4072222 L25.208372,23.308168 C25.7206661,23.8307617 25.7206661,24.6780536 25.208372,25.2006473 C24.6960778,25.723241 23.8654847,25.723241 23.3531906,25.2006473 L20.4074074,22.1509628 L17.4616243,25.2006473 C16.9493301,25.723241 16.118737,25.723241 15.6064428,25.2006473 C15.0941487,24.6780536 15.0941487,23.8307617 15.6064428,23.308168 L18.4182222,20.4072222 L15.6064428,17.5066469 C15.1283016,17.0188927 15.0964255,16.2482904 15.5108146,15.7228231 Z" id="Combined-Shape" fill="#FFFFFF" transform="translate(20.407407, 20.407407) rotate(-315.000000) translate(-20.407407, -20.407407) "></path>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        )
    }
}

export default AddFilledSvgIcon
