type initialStateType = {
    upcomingSessions: []
}

const initialState: initialStateType = {
    upcomingSessions: []
}


const reschedulingReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case "UPCOMING_SESSION_FETCHED": {
            return {
                ...state,
                upcomingSessions: action.payload.data
            }

        }
        default:
            return state
    }
}

export default reschedulingReducer
