import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { fetchTutors } from "../../../../store/actions/tutorSearchActions"
import { useTranslation } from "react-i18next"
import NameFilter from "./components/NameFilter"
import GoalsAndObjectiveFilter from "./components/GoalsAndObjectiveFilter"
import FilterHeader from "./components/FilterHeader"
import SearchButton from "./components/SearchButton"
import RangeSlider from "../../../../components/slider/RangeSlider"
import DaySelectFilter from "./components/DaySelectFilter"
import SubjectsFilter from "./components/SubjectsFilter"
import { allSubjecAndSpecialitytList, allSubjectList, getCounsellingSubjectList } from "../../../../../src/utils/constant/subjectsSpecialitiesTestsLists"
import EducationFilter from "./components/EducationFilter"
import BackgroundCheckFilter from "./components/BackgroundCheckFilter"
import MyFavouritesFilter from "./components/MyFavouritesFilter"
import RapidBookingFilter from "./components/RapidBookingFilter"
import TimeFilter from "./components/TimeFilter"
import { setRapidBooking, setSubject } from "store/actions/BookingActions"
import moment from "moment";
import BackArrowSvgIcon from "assets/images/svgIcons/BackArrowSvgIcon"
import { tutorSearchConstant } from "store/constant"
import { defaultFilterValues } from "store/reducers/tutorSearchReducer"
import { useDebounce } from 'use-debounce';
import { useMediaQuery } from "react-responsive"

interface Option {
    name: string
    id: string | number
}

interface Props {
    activeSearchTab: any,
    filteredSubjects: Array<Option>,
    sortOrder: string,
    lastFilter: any,
    setLastFilter: any,
    type?: any,
    setIsFilter: (param: boolean) => void,
    isFilter: boolean
    rapidBook: boolean,
    setRapidBook: any,
    rapidBookToggle: boolean
    openSearch: boolean,
}

const currentTimeZone = moment.tz.guess();

const defaultOrder = {
    fieldName: "oneToOneSessionRate",
    direction: "asc"
}

const tutorTypes = {
    TUTORING: "Tutoring",
    ADVISING: "Advising",
    ALL: "Both"
}

const padZero = (number: number) => {
    return /^\d$/.test(number.toString()) ? "0" + number : number
}

export const filterInputNames = {
    TUTOR_NAME: "tutorName",
    GOALS_OBJECTIVES: "goalsObjectives",
    RATE_RANGE: "rateRange",
    TIME_RANGE: "timeRange",
    SUBJECTS: "subjects",
    EDUCATION: "education",
    BACKGROUND_CHECK: "backgroundCheck",
    RAPID_BOOK: "rapidBook",
    MY_FAVOURITES: "myFavourites",
    WEEKDAYS: "weekdays"
}

const TutorSearchFiltersPanel = (props: Props): JSX.Element => {
    const { activeSearchTab, sortOrder, lastFilter, setLastFilter, type, rapidBook, setRapidBook, isFilter, rapidBookToggle, filteredSubjects, openSearch = false } = props
    const [tutorName, setTutorName] = useState<string>(defaultFilterValues.TUTOR_NAME)
    const [goalsObjectives, setGoalsObjectives] = useState<string>(type === "advisors" ? "Advising" : type === "tutors" ? "Tutoring" : "Both")
    const [rateRange, setRateRange] = useState(defaultFilterValues.RATE_FULL_RANGE)
    const [rateFullRange, setRateFullRange] = useState(defaultFilterValues.RATE_FULL_RANGE)
    const [timeRange, setTimeRange] = useState(defaultFilterValues.TIME_FULL_RANGE)

    const [subjects, setSubjects] = useState<Array<Option>>(filteredSubjects ? filteredSubjects : defaultFilterValues.SUBJECTS)

    const [education, setEducation] = useState(defaultFilterValues.EDUCATION)
    const [backgroundCheck, setBackgroundCheck] = useState(defaultFilterValues.BACKGROUND_CHECK)
    const [myFavourites, setMyFavourites] = useState(defaultFilterValues.MY_FAVOURITES)
    const [weekdays, setWeekdays] = useState(defaultFilterValues.WEEKDAYS.map((weekday) => {
        return { ...weekday }
    }))
    const [subjectList, setSubjectList] = useState([...allSubjecAndSpecialitytList()])
    const { userId } = useSelector((state: any) => state.auth.userAuthData)
    const teachersStore = useSelector((state: any) => state.tutorSearch)
    const { filtersUpdated, filterInputValues } = teachersStore;
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const [debouncedName] = useDebounce(tutorName, 1000);
    const [debouncedRate] = useDebounce(rateRange, 1000);
    const [debouncedTime] = useDebounce(timeRange, 1000);
    const [debouncedWeekDays] = useDebounce(weekdays, 1000);
    const [debouncedLastFilter] = useDebounce(lastFilter, 1000);
    const isPhoneScreen = useMediaQuery({ query: '(max-device-width: 767px)' });

    useEffect(() => {
        !isPhoneScreen && handleFilterSearch();
    }, [debouncedName,
        goalsObjectives,
        debouncedRate,
        debouncedTime,
        subjects,
        education,
        backgroundCheck,
        rapidBook,
        myFavourites,
        debouncedWeekDays, isPhoneScreen]);

    useEffect(() => {
        if (rapidBook) {
            setWeekdays(defaultFilterValues.WEEKDAYS.map((weekday) => {
                return { ...weekday }
            }))
            setTimeRange(defaultFilterValues.TIME_FULL_RANGE)
        }

    }, [rapidBook])

    useEffect(() => {
        handleFilterSearch();
    }, [rapidBookToggle])

    useEffect(() => {
        if (type) {
            handleFilterSearch();
        } else {
            setTutorName(filterInputValues.tutorName);
            setGoalsObjectives(filterInputValues.goalsObjectives);
            setRateRange(filterInputValues.rateRange);
            setTimeRange(filterInputValues.timeRange);
            setSubjects(filterInputValues.subjects);
            setEducation(filterInputValues.education);
            setBackgroundCheck(filterInputValues.backgroundCheck);
            setRapidBook(filterInputValues.rapidBook);
            setMyFavourites(filterInputValues.myFavourites);
            setWeekdays(filterInputValues.weekdays);
        }
    }, []);

    useEffect(() => {
        if (filtersUpdated) {
            let addOneToOneOrderBy = false;
            if (activeSearchTab === "rating") {
                lastFilter.forEach((filter: any) => {
                    if (filter.fieldName === "oneToOneSessionRate") {
                        addOneToOneOrderBy = true;
                        return;
                    }
                });
            }
            const orderBy = [];
            addOneToOneOrderBy && orderBy.push(defaultOrder);
            orderBy.push({
                fieldName: activeSearchTab,
                direction: sortOrder
            });
            const filterInputValues = {
                [filterInputNames.TUTOR_NAME]: tutorName,
                [filterInputNames.GOALS_OBJECTIVES]: goalsObjectives,
                [filterInputNames.RATE_RANGE]: rateRange,
                [filterInputNames.TIME_RANGE]: timeRange,
                [filterInputNames.SUBJECTS]: subjects,
                [filterInputNames.EDUCATION]: education,
                [filterInputNames.BACKGROUND_CHECK]: backgroundCheck,
                [filterInputNames.RAPID_BOOK]: rapidBook,
                [filterInputNames.MY_FAVOURITES]: myFavourites,
                [filterInputNames.WEEKDAYS]: weekdays,
            }
            if (!teachersStore.isloading) {
                dispatch(fetchTutors({
                    filters: lastFilter,
                    orderBy: orderBy,
                    pagination: { limit: 20, docId: undefined },
                    filterInputValues: filterInputValues,
                    openSearch
                }))
            }
        }
    }, [activeSearchTab, sortOrder, debouncedLastFilter])

    useEffect(() => {
        setSubjectList(goalsObjectives == tutorTypes.ALL ? [...allSubjecAndSpecialitytList()] : goalsObjectives == tutorTypes.TUTORING ? [...allSubjectList()] : [...getCounsellingSubjectList()])
    }, [goalsObjectives])


    const clearAll = () => {
        dispatch({ type: tutorSearchConstant.FILTERS_UPDATED });
        setDefaultFilters();
        setLastFilter([]);
    }

    const setDefaultFilters = () => {
        setTutorName(defaultFilterValues.TUTOR_NAME)
        setGoalsObjectives(defaultFilterValues.GOALS_OBJECTIVES)
        setSubjects(defaultFilterValues.SUBJECTS)
        setRateRange(defaultFilterValues.RATE_FULL_RANGE)
        setWeekdays(defaultFilterValues.WEEKDAYS.map((weekday) => {
            return { ...weekday }
        }))
        setTimeRange(defaultFilterValues.TIME_FULL_RANGE)
        setRapidBook(defaultFilterValues.RAPID_BOOK)
        setEducation(defaultFilterValues.EDUCATION)
        setBackgroundCheck(defaultFilterValues.BACKGROUND_CHECK)
        setMyFavourites(defaultFilterValues.MY_FAVOURITES)
        setRateFullRange(defaultFilterValues.RATE_FULL_RANGE)
    }

    const setSubjectsList = (changedSubjects: Array<Option>) => {
        const newSubjects = [...changedSubjects]
        setSubjects(newSubjects)
    }

    const setSelectedEducation = (selectedEducation: any) => {
        selectedEducation.value.id === "UNDERGRADUATE_STUDENT" ? setRateFullRange(defaultFilterValues.CURR_UNG_RATE_FULL_RANGE) : setRateFullRange(defaultFilterValues.RATE_FULL_RANGE)
        setEducation(selectedEducation.value)
    }

    const handleFilterSearch = () => {
        dispatch({ type: tutorSearchConstant.FILTERS_UPDATED });
        let filters: any = [];
        props.setIsFilter(false);
        filters = setNameFilter(filters);
        filters = setTutorTypeFilter(filters);
        filters = setSubjectsFilter(filters);
        filters = setRateRangeFilter(filters);
        filters = setRapidBookFilter(filters);
        filters = setEducationFilter(filters);
        filters = setMyFavouritesFilter(filters);
        filters = setBackgroundCheckFilter(filters);
        filters = setAvailibilityFilter(filters);
        setLastFilter(filters);
        dispatch(
            setSubject({
                subject: subjects,
            }),
        )
        dispatch(
            setRapidBooking({
                rapidBook: rapidBook
            })
        )
    }

    const setNameFilter = (filters: any) => {
        const trimedName = tutorName.trim().toLowerCase()
        const firstName = trimedName.substr(0, trimedName.indexOf(' '))
        const lastName = trimedName.substr(trimedName.indexOf(' ') + 1).trim()
        if (trimedName) {
            filters.push({
                fieldName: "firstName",
                operator: "==",
                value: firstName ? firstName : trimedName
            })
        }
        if (lastName && trimedName != lastName) {
            filters.push({
                fieldName: "lastName",
                operator: "==",
                value: lastName
            })
        }
        return filters
    }

    const setTutorTypeFilter = (filters: any) => {
        switch (goalsObjectives) {
            case tutorTypes.ADVISING:
                filters.push({
                    fieldName: "isAdvisor",
                    operator: "==",
                    value: true
                })
                break;
            case tutorTypes.TUTORING:
                filters.push({
                    fieldName: "isTeacher",
                    operator: "==",
                    value: true
                })
                break;
            default:
                break;
        }
        return filters
    }

    const setAvailibilityFilter = (filters: any) => {
        const selectedWeekdays = weekdays.filter(el => el.selected === true);
        if (!((selectedWeekdays.length === 0 || selectedWeekdays.length === 7) && timeRange[0] === defaultFilterValues.TIME_FULL_RANGE[0] && timeRange[1] === defaultFilterValues.TIME_FULL_RANGE[1])) {

            filters.push({
                fieldName: "teacherWeeklyAvailability",
                operator: "==",
                value: {
                    days: selectedWeekdays.length === 0 ? [0, 1, 2, 3, 4, 5, 6] : selectedWeekdays.map((selectedWeekday: any) => {
                        return selectedWeekday.index
                    }),
                    range: [new Date("2020-02-01T" + padZero(timeRange[0]) + ":00:00").toISOString(), new Date("2020-02-01T" + padZero((timeRange[1] || 1) - 1) + ":59:59").toISOString()],
                    timeZone: currentTimeZone
                }
            })
        }
        return filters
    }

    const setRateRangeFilter = (filters: any) => {
        if (rateRange[0] > rateFullRange[0]) {
            filters.push({
                fieldName: "oneToOneSessionRate",
                operator: ">=",
                value: rateRange[0]
            })
        }
        if (rateRange[1] < rateFullRange[1]) {
            filters.push({
                fieldName: "oneToOneSessionRate",
                operator: "<=",
                value: rateRange[1]
            })
        }
        return filters
    }

    const setRapidBookFilter = (filters: any) => {
        if (rapidBook) {
            filters.push({
                fieldName: "rapidBooking.status",
                value: rapidBook
            })

        }
        return filters
    }

    const setEducationFilter = (filters: any) => {
        if (education.id !== "ALL") {
            filters.push({
                fieldName: "teacherEducationQualification.value",
                operator: "==",
                value: education.value
            })

        }
        return filters
    }

    const setSubjectsFilter = (filters: any) => {
        const filterIds = subjects.map((subject) => {
            return subject.id
        })
        if (filterIds.length > 0) {
            filters.push({
                fieldName: "subjects",
                operator: "array-contains-any",
                value: filterIds
            })
        }
        return filters
    }

    const setBackgroundCheckFilter = (filters: any) => {
        if (backgroundCheck) {
            filters.push({
                fieldName: "backgroundCheck",
                operator: "==",
                value: backgroundCheck
            })
        }
        return filters
    }

    const setMyFavouritesFilter = (filters: any) => {
        if (myFavourites) {
            filters.push({
                fieldName: "myFavoritesOnly",
                value: userId
            })
        }
        return filters
    }
    return (
        <>
            <div
                className="sidenav filters-panel-container"
                id="mySidenav"
                style={{
                    flex: 3,
                    transition: "0.5 ease-in-out"
                }}
            >
                <div className="search_inner">
                    <div className="content-container">
                        <span className="filter_search_icon" onClick={() => props.setIsFilter(false)}>
                            <BackArrowSvgIcon iconColor={"#fff"} width="32" height="32" />
                        </span>
                        <label className="clear-label" onClick={() => clearAll()} >{t("clear-all")}</label>
                        <span className="clear"></span>
                    </div>
                </div>

                <div className="filters-panel">
                    <FilterHeader clearAll={clearAll} />
                    <RapidBookingFilter options={["Now", "Later"]} rapidBook={rapidBook} setRapidBook={setRapidBook} />
                    <GoalsAndObjectiveFilter tutorTypes={tutorTypes} goalsObjectives={goalsObjectives} setGoalsObjectives={setGoalsObjectives} />
                    <SubjectsFilter subjects={subjects} subjectsList={subjectList} setSubjectsList={setSubjectsList} />
                    {!rapidBook &&
                        <>
                            <DaySelectFilter weekdays={weekdays} setWeekdays={setWeekdays} />
                            <TimeFilter range={timeRange} onRangeChange={setTimeRange} min={defaultFilterValues.TIME_FULL_RANGE[0]} max={defaultFilterValues.TIME_FULL_RANGE[1]} format12Hours={true} />
                        </>
                    }
                    <RangeSlider range={rateRange} onRangeChange={setRateRange} title={t("hourly-rating")} min={rateFullRange[0]} max={rateFullRange[1]} minPrefix={"$"} minSuffix={""} maxPrefix={"$"} maxSuffix={"+"} symbol={"$"} />
                    <NameFilter tutorName={tutorName} setTutorName={setTutorName} handleFilterSearch={handleFilterSearch} />
                    <EducationFilter education={education} setSelectedEducation={setSelectedEducation} />
                    { !props.openSearch && <MyFavouritesFilter myFavourites={myFavourites} setMyFavourites={setMyFavourites} /> }
                    <BackgroundCheckFilter backgroundCheck={backgroundCheck} setBackgroundCheck={setBackgroundCheck} />
                </div>
                <SearchButton isFilter={isFilter} handleFilterSearch={handleFilterSearch} isloading={teachersStore.isloading} />
            </div>
        </>
    )
}
export default TutorSearchFiltersPanel
