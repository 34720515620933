import { SessionPending } from "pendings/SessionPending";
import React, { useMemo } from "react";
import { BookingPending } from "../BookingPending";
import { ReviewPending } from "../ReviewPending";
import { PendingContext } from "./PendingContext";

/**
 * 
 * @param param0.context a React.Context object
 * @param param0.children React sub components
 */
export function PendingProvider({ context, userId, children }: { context: React.Context<any>, userId: string, children: any }) {
    const contextValue = useMemo(() => {
        return userId !== undefined ? {
            booking: new BookingPending(userId),
            session: new SessionPending(userId),
            review: new ReviewPending(userId)
        } : {
                booking: undefined,
                session: undefined,
                review: undefined
            }
    }, [userId]);
    const Context = context || PendingContext;
    return <Context.Provider value={contextValue} > {children} </Context.Provider>
}