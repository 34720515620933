import React, { useEffect, useState } from "react"
import Button from "components/button/Button"
import { InputBox } from "components/input-field/InputFields"
import {
    Grid,
} from "@material-ui/core"
import { useTranslation } from "react-i18next";
import { verifyPromoCodeDetails } from "utils/services/paymentServices";

export type promoCodeDetailsType = {
    amount_off: number | null,
    id: string,
    percent_off: number | null,
    promoCode: string
}

const PromoCode = ({ setPromoCodeDetails, promoCodeDetails, setApplying, product }: { setPromoCodeDetails: (a: promoCodeDetailsType) => void, promoCodeDetails: promoCodeDetailsType | undefined, setApplying: (a: boolean) => void, product: string }): JSX.Element => {

    const [promoCode, setPromoCode] = useState<string>("");
    const [applyingPromo, setApplyingPromo] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);


    const { t } = useTranslation(["booking"]);

    useEffect(() => {
        setApplying(applyingPromo);
    }, [applyingPromo])

    const setPromoCodeValue = (e: any) => {
        setPromoCode(e.target.value);
    }

    const checkEnter = (e: any) => {
        if (e.key === "Enter") {
            applyPromo();
        }
    }

    const applyPromo = async () => {
        if (!applyingPromo) {
            setApplyingPromo(true);
            const promoCodeDetails = await verifyPromoCodeDetails(promoCode, product);
            setApplyingPromo(false);
            if (promoCodeDetails.status === "success") {
                setPromoCodeDetails({ ...promoCodeDetails.data, promoCode: promoCode });
                setError(false);
            } else {
                setError(true);
            }
        }
    }

    return (
        <Grid container className="promo-code d-flex">
            <Grid item xs={12} className="promo-code-title">
                <p>{t("promo_code.do_you_have")}</p>
            </Grid>
            <Grid item xs={12}>
                <Grid container >
                    <Grid item xs={12} sm={8}>
                        <InputBox
                            type="text"
                            className={"resume-page-input"}
                            title=""
                            placeholder={t("promo_code.promo_code")}
                            inputProps={{
                                name: "promoCode",
                                value: promoCode,
                                onChange: setPromoCodeValue,
                                onKeyDown: checkEnter
                            }}
                            error={error && "Invalid Promo Code"}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} className="promo-button-container">
                        <Button
                            title={t("promo_code.apply")}
                            clickAction={applyPromo}
                            isDisabled={applyingPromo || promoCodeDetails?.id ? true : false || !promoCode}
                            isloading={applyingPromo}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>

    )
}


export default PromoCode