import { createStore, applyMiddleware } from "redux"
import logger from 'redux-logger'
import createSagaMiddleware from "redux-saga"
import rootReeducer from "./reducers/rootReducer"
import rootSaga from "./sagas/rootSaga"
import { composeWithDevTools } from "redux-devtools-extension"
const sagaMiddleware = createSagaMiddleware()

var middlewares = [];

if (process.env.NODE_ENV === 'development') {
    middlewares.push(logger);
}
middlewares.push(sagaMiddleware);

const store = createStore(rootReeducer, composeWithDevTools(applyMiddleware(...middlewares)))

sagaMiddleware.run(rootSaga)

export default store
