import React, { useState, useEffect, useCallback } from "react"
import Card from "@material-ui/core/Card"
import Avatar from "@material-ui/core/Avatar"
import { Delete } from "@material-ui/icons";
import { SERVER_URL } from "../../../utils/constant/api/api-config"
import { useDispatch, useSelector } from "react-redux"
import _ from "lodash"
import {
    formatDate,
    DateFormats
} from "../../../utils/helper/date-utils"
import Button from "../../../components/button/Button"
import { withRouter } from "react-router-dom";
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip } from "@material-ui/core";
import { showToast } from "store/actions/toastAction";
import { getUpcomingSessions } from "store/actions/sessionsActions";
import useSessionAndZoom from "utils/hooks/useSessionAndZoom";
import { InputBox } from "components/input-field/InputFields";
import { isSessionReschedulable } from "utils/helper/session-utils";
import StdTutorRescheduleSession from "popups/booking/student/popups/Rescheduling/StdTutorRescheduleSession"
import defaultAvatar from "../../../assets/images/default-avatar.png"


interface Iprops {
    id: string
    teacherHourlyRate: number
    studentName: string
    bookingRequestThreadObjectId: string
    bookingRequestSlotId: string
    teacherId: string
    creationTime: object
    roomId: string
    studentId: string
    sessionLength: number,
    extensionTime?: number
    startTime: string
    teacherName: string
    modifiedTime: object
    status: number
    bookingId: string
    sessionType: string
    teacherGroupSessionRate: number
    subjects: any
    navigatetoEpicboard: Function
}


const UpcomingSessions = (props: any) => {

    const [showAlert, setShowAlert] = useState(false);
    const [isCancelling, setIsCancelling] = useState(false);
    const [showReschedulePopup, setShowReschedulePopup] = useState(false);

    const { isSuccess } = useSelector((state: any) => state.session.rescheduleData);
    const { userType, userId } = useSelector((state: any) => state.auth.userAuthData);

    const dispatch = useDispatch();

    const {
        teacherId,
        studentId,
        teacherName,
        studentName,
        startTime,
        sessionLength,
        extensionTime,
        bookingId,
        modifiedTime,
        id: sessionId
    } = props;

    const {
        cancelSession,
        setDataAndEnterZoom,
        loading, setLoading,
        showDeleteButton,
        isJoinEnabled,
        cancellationReason,
        setCancellationReason
    } = useSessionAndZoom({
        teacherName,
        studentName,
        startTime,
        sessionLength,
        extensionTime,
        sessionId,
        bookingId,
        teacherId,
        studentId
    })

    const onCancelSession = useCallback(async () => {
        setIsCancelling(true);
        const isSuccess = await cancelSession();
        if (isSuccess) {
            dispatch(showToast({
                show: true,
                message: "Session Canceled Successfully",
                isSuccess: true
            }));
            dispatch(getUpcomingSessions(userId))
        }
    }, [sessionId, userId, cancelSession]);


    const EnterZoom = useCallback(async () => {
        return await setDataAndEnterZoom();
    }, [setDataAndEnterZoom]);

    useEffect(() => {
        if (isSuccess) {
            setShowReschedulePopup(false);
        }
    }, [isSuccess]);
    useEffect(() => {
        setIsCancelling(false);
    }, [modifiedTime]);

    return (
        <>
            <Card className="session-request-card" style={{ marginBottom: "30px" }}>
                <div className="session-card upcoming-card">
                    <div className="session-userdata">
                        <Avatar
                            src={
                                userType === "STUDENT"
                                    ? `${SERVER_URL}/userImage?userId=${teacherId}`
                                    : `${SERVER_URL}/userImage?userId=${studentId}`
                            }
                        />
                        <div className="session-upcoming-card-user" style={{ marginLeft: "15px" }}>
                            <p style={{ color: "#F28334", marginBottom: "5px" }} >
                                {userType === "STUDENT" ? teacherName : studentName}
                            </p>
                            <h6>{props.subjects.join(", ")}</h6>
                        </div>
                    </div>
                    <div className="session-card-headings upcoming">
                        <h6 style={{ fontSize: "18px", color: "#2d3234" }}>Upcoming Sessions</h6>
                        {!isJoinEnabled ? (
                            <p style={{ fontSize: "14px", color: "#686c80" }}>{`${formatDate(startTime, DateFormats.LONG_DATE)} at ${formatDate(
                                startTime,
                                DateFormats.TIME_IN_MERIDIAN,
                            )}`}</p>
                        ) : (
                            <p className="now-label" style={{ color: "#36d174", fontSize: "14px" }}>Now!</p>
                        )}
                    </div>

                    {showDeleteButton === true ? <Tooltip title="Cancel This Session" className="cancel-booked-session">
                        <IconButton aria-label="delete" onClick={() => !(loading || isCancelling) && setShowAlert(true)} disabled={isCancelling}>
                            <Delete />
                        </IconButton>
                    </Tooltip> : <></>
                    }
                    {isSessionReschedulable(userType, startTime) ?
                        <Button
                            title="Reschedule"
                            clickAction={() => {
                                setShowReschedulePopup(true)
                            }}
                            isOutlineStyle
                            className="reschedule-button"
                            isloading={false}
                        />
                        :
                        <Button
                            title="Join Now"
                            clickAction={() => {
                                EnterZoom().catch(console.error);
                            }}
                            className="join-button"
                            isloading={loading}
                            isDisabled={!isJoinEnabled}
                        />
                    }
                </div>
                <Dialog className="upcoming-session-dialog" open={showAlert} onClose={() => setShowAlert(false)} >
                    <DialogTitle className="text-warning">Warning</DialogTitle>
                    <DialogContent className="p-4">
                        <p>You are about to cancel an already booked session.</p>
                        <p>Are you sure, you want to cancel it?</p>
                        {userType === "TEACHER" &&
                            <>
                                <InputBox
                                    type={"textarea"}
                                    title={"Reason:"}
                                    placeholder=""
                                    inputProps={{
                                        minlength: 25,
                                        maxlength: 250,
                                        value: cancellationReason,
                                        onChange: (e: any) => setCancellationReason(e.target.value)
                                    }}
                                    error={cancellationReason.length < 25 ? "Should be at least 25 characters" : null}
                                />
                            </>
                        }
                    </DialogContent>
                    <DialogActions className="Cancel-modal-btn" style={{ justifyContent: "center", padding: "15px 30px" }}>
                        <Button
                            title={'No'}
                            clickAction={() => setShowAlert(false)}

                        />
                        <Button
                            title={"Yes, Cancel it"}
                            clickAction={(e: any) => {
                                setShowAlert(false);
                                onCancelSession().catch(console.error);
                            }}
                            isDisabled={userType === "TEACHER" && cancellationReason.length < 25}
                        />
                    </DialogActions>

                </Dialog>
            </Card>
            {showReschedulePopup &&
                <StdTutorRescheduleSession
                    show={showReschedulePopup}
                    studentPicUrl={`${SERVER_URL}/userImage?userId=${studentId}` || defaultAvatar}
                    teacherPicUrl={`${SERVER_URL}/userImage?userId=${teacherId}` || defaultAvatar}
                    close={() => setShowReschedulePopup(false)}
                    slot={props}
                    teacherId={teacherId}
                    studentId={studentId}
                    sessionId={sessionId}
                />
            }
        </>
    )
}

export default withRouter(UpcomingSessions)
