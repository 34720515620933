import firebase from "../utils/config/firebase";

export enum PENDING_TYPE {
    BOOKING = "booking",
    SESSION = "session",
    REVIEW = "review"
}

export enum PENDING_STATUS {
    CREATED = "created",
    UPDATED = "updated",
    RESOLVED = "resolved"
}

export interface PendingType {
    id: string,
    status: PENDING_STATUS,
    type: PENDING_TYPE,
    data: any,
    userIds: string[],
    resolvedBy?: string
}

export class Pending {

    database: firebase.database.Reference;
    ref: firebase.database.Reference;
    userId: string;
    /**
     * 
     * @param userId 
     */
    constructor(userId: string) {
        this.userId = userId;
        this.database = firebase.database().ref('pendings');
        this.ref = this.database;
    }


}