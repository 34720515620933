import React, { useEffect, useState } from "react"
import { Modal } from "@material-ui/core"
import Button from "../button/Button"
import { useTranslation } from "react-i18next"

type IProps = {
    show: boolean,
    requestedMinutes: number,
    studentName: string,
    onClose: () => void,
    onAccept: () => void,
    onReject: () => void,
}

export function TimeExtensionRequestedPopup({
    show,
    studentName,
    requestedMinutes,
    onClose,
    onAccept,
    onReject,
}: IProps) {
    const { t } = useTranslation()
    const [open, setOpen] = useState(!!show)
    useEffect(() => {
        setOpen(show)
    }, [show])
    return (
        <Modal
            open={open}
            onClose={(): void => {
                setOpen(false)
                onClose()
            }}
            className="add-card extension-modal custom-modal"
        >
            <div className="custom-modal-body extention-modal-body">
                <div className="custom-modal-content privacy-modal">
                    <br />
                    <br />
                    <h3>{t("your-session-is-about-to-expire")}</h3>
                    <h4 className="Color-class-mango"> {t("less-than-10-min-left")}</h4>
                    <h2>
                        {" "}
                        {studentName} {t("would-like-1")} {requestedMinutes} {t("would-like-2")}
                    </h2>
                    <h2>{t("extension-to-this-session")}</h2>
                    <br />
                    <div className="Center-Buttons">
                        <Button
                            className="button"
                            clickAction={(): void => {
                                setOpen(false)
                                onReject()
                            }}
                            title=" No Thanks ! "
                            isOutlineStyle
                        ></Button>

                        <Button
                            className="button"
                            clickAction={(): void => {
                                setOpen(false)
                                onAccept()
                            }}
                            title="Accept extension"
                        ></Button>
                    </div>
                    <br />
                    <p>{t("tutor-hourly-rate")}</p>
                    <br />
                    <br />
                </div>
            </div>
        </Modal>
    )
}
