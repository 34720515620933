
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import translationEng from "./locales/en/translation.json";
import bookingEng from "./locales/en/booking.json";

i18n.use(LanguageDetector).init({

    fallbackLng: "en",
    debug: true,

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    // keySeparator: false, // we use content as keys

    interpolation: {
        escapeValue: false, // not needed for react!!
        formatSeparator: ","
    },

    react: {
        wait: true
    },
    // we init with resources
    resources: {
        en: {
            translations: translationEng,
            booking: bookingEng
        },
    },
}).catch(console.error);

export default i18n;