import React, { useState } from "react";
import Card from '@material-ui/core/Card';
import { InputBox } from "../../../components/input-field/InputFields";
import moment from 'moment';
import Avatar from '@material-ui/core/Avatar';
import { faSearch } from "@fortawesome/free-solid-svg-icons"
import { SERVER_URL } from "../../../utils/constant/api/api-config";
import { KeyboardDatePicker } from "@material-ui/pickers";
import colors from "../../../assets/colors/index";
import { createTheme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from "react-redux";
import { formatDate, DateFormats } from "../../../utils/helper/date-utils";
import { getSessionLength } from "../../../utils/helper/session-utils";

interface Iprops {
    id: string,
    teacherHourlyRate: number,
    studentName: string,
    bookingRequestThreadObjectId: string,
    bookingRequestSlotId: string,
    teacherId: string,
    creationTime: object,
    roomId: string,
    studentId: string,
    sessionLength: number,
    extensionTime?: number,
    startTime: string,
    teacherName: string,
    modifiedTime: object,
    status: number,
    bookingId: string,
    sessionType: string,
    teacherGroupSessionRate: number,
    subjects: Array<any>,
    usertype: string

}

interface Sprops {
    sessiondata: any
}

interface SHeadProps {
    handleTextChange: any,
    handleDateChange?: any
}

const customTheme = createTheme({
    palette: {
        primary: {
            main: colors.darkMango
        }
    },
    typography: {
        fontFamily: "Montserrat-Medium",
        fontSize: 14
    }
})

const useStylesInput = makeStyles({
    root: {
        border: "0px",
        height: "65px",
        boxShadow: `0 10px 10px 0 ${colors.blackRussianOp}`,
        width: "100%",
        padding: "10px",
        color: colors.battleshipGrey,
        borderRadius: "5px"
    }
})

const PastSessionsHead = (props: SHeadProps): JSX.Element => {
    const [date, setDate] = useState("")
    const inputClasses = useStylesInput();
    const handleTextChange = props.handleTextChange;
    const handleDateChange = props.handleDateChange;

    const handleDateUpdate = (date: any) => {
        const formattedDate = moment(date).isValid() ? formatDate(date, DateFormats.SHORT_DATE) : "";
        setDate(date);
        handleDateChange && handleDateChange(formattedDate);
    }
    return <div className="session-past-heading">
        <div className="past-content-mob-hide">
            <h4>History</h4>
            <div className="past-filters">
                <KeyboardDatePicker
                    autoOk
                    clearable
                    inputVariant="standard"
                    format={`${DateFormats.SHORT_DATE}`}
                    variant="inline"
                    className="datepicker-root-past"
                    value={date}
                    placeholder="Select a date"
                    InputProps={{ classes: { root: inputClasses.root }, disableUnderline: true }}
                    InputAdornmentProps={{ position: "end" }}
                    InputLabelProps={{ disableAnimation: true, shrink: false }}
                    helperText={''}
                    maxDate={moment()}
                    onChange={(e: any) => handleDateUpdate(e)}
                />
                <InputBox
                    title=""
                    placeholder="Search"
                    type="text"
                    inputProps={{
                        onChange: (e: any) => handleTextChange(e),
                        name: "subjectFilter"
                    }}
                    className="past-date-picker"
                    iconProps={{ icon: faSearch, style: { color: colors.darkMango } }}
                    error={null}
                />
            </div>
        </div>
        <div className="past-content-mob-show">
            <div className="d-flex  past-content-div-mob-show">
                <div className="past-title-w"><h4>History</h4></div>
                <div className="past-date-w" >
                    <KeyboardDatePicker
                        autoOk
                        clearable
                        inputVariant="standard"
                        format={`${DateFormats.SHORT_DATE}`}
                        variant="inline"
                        className="datepicker-root-past"
                        value={date}
                        placeholder="Select a date"
                        InputProps={{ classes: { root: inputClasses.root }, disableUnderline: true }}
                        InputAdornmentProps={{ position: "end" }}
                        InputLabelProps={{ disableAnimation: true, shrink: false }}
                        helperText={''}
                        maxDate={moment()}
                        onChange={(e: any) => handleDateUpdate(e)}
                    />
                </div>
                <div className="past-search-w">
                    <InputBox
                        title=""
                        placeholder="Search"
                        type="text"
                        inputProps={{
                            onChange: (e: any) => handleTextChange(e),
                            name: "subjectFilter"
                        }}
                        className="past-date-picker"
                        iconProps={{ icon: faSearch, style: { color: colors.darkMango } }}
                        error={null}
                    />
                </div>

            </div>
        </div>
    </div>
}

const statuses: any = {
    4: "Completed",
    6: "Canceled",
    7: "Couldn't Accept",
    8: "Expired",
    9: "Expired",
    10: "Couldn't Accept",
    11: "Couldn't Accept",
}

const PastSessionsList = (props: Iprops): JSX.Element => {
    const { usertype, startTime, teacherId, studentId, studentName, teacherName, subjects, status } = props;
    const sessionLength = getSessionLength(props);

    return <Card className="session-request-card">
        <div className="session-card past past-session-card-content">
            <div className="session-card-headings past">
                <h6 className="session-card-past-headings">{`${formatDate(startTime, DateFormats.LONG_DATE)}`}</h6>
                <p className="past-p-14">{`${formatDate(startTime, DateFormats.TIME_IN_MERIDIAN)} ${sessionLength} Mins`}</p>
            </div>
            <div className="session-userdata past">
                <Avatar src={usertype === "STUDENT" ? `${SERVER_URL}/userImage?userId=${teacherId}` : `${SERVER_URL}/userImage?userId=${studentId}`} />
                <div className="session-card-headings " style={{ marginLeft: "15px" }}>
                    <h6 className="session-card-past-headings">Name</h6>
                    <p className="session-card-past-p" style={{ color: colors.darkMango, fontSize: "18px" }}>{usertype === "STUDENT" ? teacherName : studentName}</p>
                </div>
            </div>
            <div className="session-card-headings past subject">
                <h6 className="session-card-past-headings">Subject</h6>
                <p className="session-card-past-p">{subjects.join(", ")}</p>
            </div>
            <div className="session-card-headings past subject">
                <h6 className="session-card-past-headings">Status</h6>
                <p className={`session-card-past-p ${status === 4 ? 'completed' : 'canceled'}`}>{statuses[status]}</p>
            </div>
        </div>
    </Card>
}

const PastSessions = (props: Sprops): JSX.Element => {
    const [data, setData] = useState(props.sessiondata);
    const userType = useSelector((state: any) => state.auth.userAuthData.userType);
    const sortedArray = data.slice().sort((a: any, b: any) => {
        return moment(b.startTime).diff(a.startTime);
    })
    const handleDateChange = (date: any) => {
        let filteredList = [];
        if (date !== "") {
            const oldList = props.sessiondata;
            filteredList = oldList.filter((item: any) => {
                const filter = date;
                const itemDate = formatDate(item.startTime, DateFormats.SHORT_DATE)
                if (filter === itemDate) {
                    return item;
                }
            })
        }
        else {
            filteredList = props.sessiondata;
        }
        setData(filteredList);
    }

    const handleTextSearch = (query: any) => {
        let filteredList = [];
        // const nameList = userType === "Student" ? data.map((item:any)=>item.teacherName) : data.map((item:any)=>item.teacherName);
        if (query.target.value !== "") {
            const oldList = props.sessiondata;

            filteredList = oldList.filter((item: any) => {
                const nameLC = userType == "STUDENT" ? item.teacherName.toLowerCase() : item.studentName.toLowerCase();
                const filter = query.target.value.toLowerCase();
                if (nameLC.includes(filter)) {
                    return item;
                }
            });
        }
        else {
            filteredList = props.sessiondata
        }

        setData(filteredList)
    }

    return <>
        <PastSessionsHead handleTextChange={handleTextSearch} handleDateChange={handleDateChange} />
        {sortedArray.map((item: any) => {
            return <PastSessionsList {...item} usertype={userType} key={`session-${item.id}`} />
        })}
    </>
}

export default PastSessions;