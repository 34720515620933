import React, { useCallback, useEffect, useState } from "react"
import { Grid, Tooltip } from "@material-ui/core"
import SwitchComponent from "components/switch/LargeSwitchComponent"
import {
    getRapidBookingAvailability,
    updateRapidBookingAvailability,
} from "store/actions/rapidBookingActions"
import { useDispatch, useSelector } from "react-redux"
import InfoIcon from "@material-ui/icons/InfoOutlined"
import AlarmIcon from "assets/images/svgIcons/RapidBookIcon";
import { getPendingBookings } from "store/actions/sessionsActions"
import { TutorStdRapidBookPending } from "popups/booking/teacher/popups/TutorStdRapidBookPending";
import {TutorStdBookingRequestsCancellation} from "popups/booking/teacher/popups/TutorStdBookingRequestsCancellation";
import {getCancelActionCreator} from "../../pages/home/my-session/FinalBookingAction";
import { BOOKING_REQUEST_STATUS_CODES } from "store/reducers/sessions-reducers/sessionReducer";
export const RapidbookComponent = ({ userId }: any) => {
    const dispatch = useDispatch()
    const { isAvailable: isAvailableForRapidBooking }: { isAvailable: boolean } = useSelector(
        (state: any) => state.rapidBooking,
    ) || {};
    const {bookings} :any = useSelector( (state: any) => state.session)
    const [rapidBookingTurnOff,setRapidBookingTurnOff] =useState(false); 
    const [pendingRapidBookSession,setPendingRapidBookingSession]=useState<any[]>();
    const [cancelSessionConfirmation,setCancelSessionConfirmation]=useState(false);
    const [viewConfirmation,setViewConfirmation]=useState(false);
    useEffect(() => {
        if(viewConfirmation){
            setCancelSessionConfirmation(true);
        }
    }, [viewConfirmation])
    useEffect(() => {
        userId && dispatch(getRapidBookingAvailability(userId)) && dispatch(getPendingBookings())
    }, [userId])

    const onToggleRapidBooking = useCallback(
        (flag: boolean) => {
            userId && dispatch(updateRapidBookingAvailability(userId, flag))
        },
        [userId],
    )
    const checkforRapidBookSession = (flag :boolean) => {
        if(bookings?.length>0 && !flag)
        {
            //todo :define all type of booking and use it here
            let rapidBookingsArray:any = [];
            for(let i=0; i<bookings.length;i++){
                if(bookings[i].rapidBooking  && (bookings[i].status === BOOKING_REQUEST_STATUS_CODES.WAITING_FOR_TEACHER_CONFIRMATION))
                {
                    rapidBookingsArray.push(bookings[i]);
                }
            }
            if(rapidBookingsArray.length>0){
                setPendingRapidBookingSession(rapidBookingsArray);
                setRapidBookingTurnOff(true)
                return;
            }
            onToggleRapidBooking(flag);
        }
        onToggleRapidBooking(flag);
    }
    const CancelSessionConfirmation=()=>{
        setViewConfirmation(false);
        onToggleRapidBooking(false);
    }
    const CrossSessionConfirmation=()=>{
        setViewConfirmation(false);
    }

    const TurnOffRapidBook =()=>{
        onToggleRapidBooking(false);
        setRapidBookingTurnOff(false);
    }
    const OnCancelRequest =()=>{
        setRapidBookingTurnOff(false);
        setViewConfirmation(true); 
    }
    const cancelSessions =()=>{
        setViewConfirmation(false);
        onToggleRapidBooking(false);
        if(pendingRapidBookSession){
        for(let i=0; i<pendingRapidBookSession.length;i++){
            if(pendingRapidBookSession[i].rapidBooking  && ( pendingRapidBookSession[i].status === BOOKING_REQUEST_STATUS_CODES.WAITING_FOR_TEACHER_CONFIRMATION 
                || pendingRapidBookSession[i].status === BOOKING_REQUEST_STATUS_CODES.WAITING_FOR_STUDENT_CONFIRMATION) )
             {
                dispatch(
                    getCancelActionCreator()(pendingRapidBookSession[i].id, {
                        slots:pendingRapidBookSession[i].slots,
                        comment: "",
                    }))
             }
           }
        }
    }
    return (
        <>
        <Grid container className="rapid-booking">
            <div className="separator"></div>
            <Grid item xs={12} className="rapidbook-toggle-section">
                <span className="title">
                    Are you Available <span className="now">NOW</span>? Turn on “Rapid Book”!
                </span>
                <div className="rapid-book">
                    <SwitchComponent
                        size="medium"
                        active={isAvailableForRapidBooking}
                        onTap={checkforRapidBookSession}
                    />
                    <AlarmIcon className="clock" iconColor="#262ffa"></AlarmIcon>
                    <div className="title">Rapid book</div>
                    <Tooltip title="Available now? Enable rapid booking for real-time booking.">
                        <InfoIcon className="info"></InfoIcon>
                    </Tooltip>
                    <div className="warning">(You need to refresh every 3 hours)</div>
                </div>
            </Grid>
            <div className="separator"></div>
        </Grid>
        { rapidBookingTurnOff ? (<div> <TutorStdRapidBookPending OnCancelRequest={OnCancelRequest} TurnOffRapidBook={TurnOffRapidBook} onClose={ setRapidBookingTurnOff}pendingRapidBookSession={pendingRapidBookSession} /></div> ) : (<></>)}
        { viewConfirmation ? (<div> <TutorStdBookingRequestsCancellation  closePopup={setViewConfirmation}cancelSessions={cancelSessions} onCross={CrossSessionConfirmation} onClose={CancelSessionConfirmation} pendingRapidBookSession={pendingRapidBookSession} /></div> ) : (<></>)}
        </>
    )
}

