import React, { useEffect, useState } from "react";
import { RapidBookingTurnOff } from "./RapidBookingTurnOff";
import firebase from "firebase";
import { useDispatch, useSelector } from "react-redux";
import { updateRapidBookingAvailability } from "store/actions/rapidBookingActions";


export default function RapidBookingTurnOffContainer() {

    const [popUpView, setPopUpView] = useState<boolean>(false);
    const { userAuthData } = useSelector((state: any) => state.auth)
    const [id, setId] = useState<string>(userAuthData.id)
    const dispatch = useDispatch()

    useEffect(() => {
        const rapidbook = firebase.database().ref(`/rapid-book-turned-off-notifications/${id}`)

        const onChange = (snapshot: firebase.database.DataSnapshot) => {
            if (!snapshot.exists()) {
                return;
            }
            const rapidBooking = snapshot.val();
            if (rapidBooking.status === false && rapidBooking.turnedOff === "automatically" ) {
                setPopUpView(true);
            }
        };
        rapidbook.ref.on("value", onChange);
        return () => {
            rapidbook.ref.off("value", onChange);
        };
    }, [id]);

    const onClose = () => {
        setPopUpView(false);
        dispatch(updateRapidBookingAvailability(id, false));
    }
    const turnOnRapidBook = () => {
        setPopUpView(false);
        dispatch(updateRapidBookingAvailability(id, true))
    }

    return <RapidBookingTurnOff turnOnRapidBook={turnOnRapidBook} isShown={popUpView} onClose={onClose} />
}