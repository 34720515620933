import React, { useEffect, useState } from "react"
import { Grid } from "@material-ui/core"
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux"
import AddPaymentModal from "components/add-card/AddPaymentModal"
import { addCardDetails, deleteCard } from "store/actions/PaymentActions";
import Button from "components/button/Button";
import Modal from "components/modal_/Modal";
import { PaymentConstant } from "store/constant";
import { InfoIcon } from "components/icons/Icons";



const PaymentMethods = (): JSX.Element => {
    const [toBeDeletedCard, setToBeDeletedCard] = useState({ cardId: "", last4: "", brand: "" })
    const { stripeCards, stripeCustomer, userId } = useSelector((state: any) => state.auth.userAuthData)
    const { isLoading, openSnackbar, message, type, showAddCardModal, showDeleteCardModal, showDeleteCardResponse } = useSelector((state: any) => state.payment)
    const dispatch = useDispatch()
    const { t } = useTranslation();
    const addStudentCard = (data: any): any => {
        dispatch(addCardDetails(data))
    }
    const openDeleteCardModal = ({ cardId, last4, brand }: { cardId: string, last4: string, brand: string }) => {
        dispatch({ type: PaymentConstant.TOGGLE_DELETE_CARD_MODAL, payload: { showDeleteCardModal: true } })
        setToBeDeletedCard({ cardId, last4, brand })
    }
    const deleteMyCard = () => {
        dispatch(deleteCard({ customerId: stripeCustomer.id, cardId: toBeDeletedCard.cardId, userId }))
    }
    return (
        <>
            <AddPaymentModal
                open={showAddCardModal}
                close={() => dispatch({ type: PaymentConstant.TOGGLE_ADD_CARD_MODAL, payload: { showAddCardModal: false } })}
                clickAction={(token: any): void =>
                    addStudentCard({ token, customerId: stripeCustomer.id })
                }
                isLoading={isLoading}
            />
            <Modal
                visible={showDeleteCardModal}
                onExit={() => dispatch({ type: PaymentConstant.TOGGLE_DELETE_CARD_MODAL, payload: { showDeleteCardModal: false } })}

                buttons={
                    [{ title: t("keep-card"), clickAction: () => dispatch({ type: PaymentConstant.TOGGLE_DELETE_CARD_MODAL, payload: { showDeleteCardModal: false } }), isOutlineStyle: true }, { title: t("delete-card"), clickAction: deleteMyCard, isloading: isLoading, isDisabled: isLoading }]
                }
            >
                <div className="p-2">
                    <h2>{t("delete-card--confirmation", { brand: toBeDeletedCard.brand, card: toBeDeletedCard.last4 })}</h2>
                </div>
            </Modal>
            <Modal
                visible={showDeleteCardResponse}
                onExit={() => dispatch({ type: PaymentConstant.HIDE_DELETE_CARD_RESPONSE_MODAL })}
            >
                <div className="p-2">
                    <h2>Information</h2>
                    <p>{message}</p>
                </div>
            </Modal>
            <Grid container className="payment-method-header">

                <Grid item xl={5} lg={5} md={5} sm={8} xs={8} className="margin-auto">
                    <p className="margin-auto">{t("student-cards")}</p>
                </Grid>
                <Grid item xl={3} lg={3} md={3} sm={4} xs={4} className="margin-auto">
                    <p className="margin-auto">{t("expires")}</p>
                </Grid>
                <Grid item xl={4} lg={4} md={4} sm={4} xs={4} className="margin-auto">
                </Grid>
            </Grid>
            {stripeCards && [...stripeCards]?.reverse()?.map((card: any) => {
                return (
                    <>
                        <Grid container className="payment-method-tile" >
                            <Grid item xl={5} lg={5} md={5} sm={8} xs={8} >
                                <p className="margin-auto"><span className="card-type">{card.brand}</span>  {t("ending-in")} {card.last4}</p>
                            </Grid>
                            <Grid item xl={3} lg={3} md={3} sm={4} xs={4}>
                                <p className="margin-auto">{card.exp_month}-{card.exp_year}</p>
                            </Grid>
                            <Grid item xl={4} lg={4} md={4} sm={12} xs={12} className="delete-card-col">
                                <Button className="button-delete-card" title={t("delete-card")} clickAction={() => openDeleteCardModal({ cardId: card.id, last4: card.last4, brand: card.brand })} />
                            </Grid>
                        </Grid>
                    </>
                )
            })}
        </>
    )
}

export default PaymentMethods
