import { faChevronLeft } from "@fortawesome/free-solid-svg-icons"
import React, { Fragment } from "react"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import colors from "../../../../assets/colors"
import RatingAppleIcon from "../../../../components/rating-apple-icon/RatingAppleIcon"
import RatingsComponent from "../../../../components/ratings/RatingsComponent"
import RoundedButton from "../../../../components/rounded-button/RoundedButton"
import { navigatetoUrl } from "../../../../utils/helper/helper"
// import useAvgRating from "../../../../utils/hooks/useAvgRating"

interface Props {
    firstName: string
    lastName: string
    imageURL: string
    rating: number
    profileView? :Function
}
const TutorImageBlock = (props: Props): JSX.Element => {
    const history = useHistory()
    const { firstName, lastName, imageURL, rating } = props

    return (
        <Fragment>
                <RoundedButton
                    onPress={() => {
                        props.profileView? props.profileView(false):navigatetoUrl(history, "/panel/search")
                        
                    }}
                    iconProps={{
                        icon: faChevronLeft,
                        style: {
                            color: "#262ffa",
                            alignSelf: "center",
                            marginRight: "2px",
                        },
                    }}
                    className="book-back-button profile"
                    active={true}
                />
            <div className="user-block">
                <div className="user-block-inner-div">
                <img src={imageURL} alt={firstName}  />
                <div className="pl-3">
                <div className="content">
                    <div className="label">{`${firstName} ${lastName.substring(0,1)}.`}</div>
                    <div className="icons">
                        <RatingsComponent
                            obtainedRating={rating}
                            iconSize={"23px"}
                            fillColor={colors.darkMango}
                        />
                    </div>
                </div>
                </div>
            </div>
            </div>
        
        </Fragment>
    )
}
export default TutorImageBlock
