import { StudentDashboardConstants } from "../constant"

export const getStudentUpcomingSessions = (props: any) => {
    return { type: StudentDashboardConstants.GET_STUDENT_UPCOMING_SESSIONS, payload: props }
}

export const setStudentUpcomingSessions = (props: any) => {
    return { type: StudentDashboardConstants.SET_STUDENT_UPCOMING_SESSIONS, payload: props }
}

export const getStudentTutors = (props: any) => {
    return { type: StudentDashboardConstants.GET_STUDENT_TUTORS, payload: props }
}

export const setStudentTutors = (props: any) => {
    return { type: StudentDashboardConstants.SET_STUDENT_TUTORS, payload: props }
}

export const getStudentAdvisors = (props: any) => {
    return { type: StudentDashboardConstants.GET_STUDENT_ADVISORS, payload: props }
}

export const setStudentAdvisors = (props: any) => {
    return { type: StudentDashboardConstants.SET_STUDENT_ADVISORS, payload: props }
}
