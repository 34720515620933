import React, { useState } from "react"
import MiniCard from "../../../../components/mini-card/MiniCard"
import { Grid } from "@material-ui/core"
import Button from "../../../../components/button/Button"
import { plusIcon } from "../../../../components/icons/Icons"
import { useSelector, useDispatch } from "react-redux"
import { applicationConstant } from "../../../../utils/constant/applicationConstant"
import InterfaceConstant from "../../../../utils/constant/interfaceConstant"
import { selectteacherType } from "../../../../store/actions/TutorSignUpActions"

import standardizedTestList from "../../../../utils/constant/standardizedTestList"
import Modal from "../../../../components/modal_/Modal"
import MultiSelectDropDown from "../../../../components/dropdown/MultiSelectDropdown"


export type dropdownListType = {
    id: string
    name: string
}

const TutorAdvisorTests = () => {
    const dispatch = useDispatch()
    const tutorAdvisorLabel: InterfaceConstant.ItutorAdvisorLabel =
        applicationConstant.tutorAdvisorLabel
    const tutorAdvisorErrorLable: InterfaceConstant.IappErrorLabels =
        applicationConstant.appErrorLabels
    const tutorInfo = useSelector((state: any) => state.tutorSignUp)
    const [modal, showModal] = useState(false)
  
    const [isShowError, setShowError] = useState(false)


    const handledropdownValue = (test: object, subject: object,  specialization: object): void => {
        dispatch(
            selectteacherType({
                teacherType: tutorInfo.teacherType,
                value: {
                    subject: subject,
                    test: test,
                    specialization: specialization,
                },
            }),
        )
    }

    const SetTestValue = (newOptionsState: dropdownListType[]): void => {
        handledropdownValue(newOptionsState, tutorInfo.value.subject, tutorInfo.value.specialization)
    }

    const removeTest = (id: string): void => {
        const filtered = tutorInfo.value.test.filter((e: dropdownListType) => e.id !== id)
        SetTestValue(filtered)
    }

    const ModalContent = (): JSX.Element => {
        return (
            <div className="subjects-tests-modal">
                <h3>Add Standardized Test</h3>
                <MultiSelectDropDown
                    title={tutorAdvisorLabel.TEST_DROPDOWN_TITLE}
                    placeholder={tutorAdvisorLabel.TEST_DROPDOWN_PLACEHOLDER}
                    optionsList={standardizedTestList}
                    error={
                        isShowError && tutorInfo.value.test.length === 0
                            ? tutorAdvisorErrorLable.GLOBAL_DROPDOWN_ERROR
                            : ""
                    }
                  
                    subjectvalue={SetTestValue}
                    selectedOption={tutorInfo.value.test}
                />
                <Button type="button" title="Add Test" clickAction={():void => showModal(false)} />
            </div>
        )
    }
    return (
        <>
            <Grid item xs={12} md={4}>
                <h3>Standardized Tests</h3>

                {tutorInfo.value.test && tutorInfo.value.test.map((e: dropdownListType, index: number) => (
                    <MiniCard key={index} title={e.name} action={(): void => removeTest(e.id)} tooltip="Remove Test"/>
                ))}

                <div className="actions">
                    <Button type="button" title="" rightSideIcon={plusIcon} clickAction={():void => showModal(true)} />
                    Add Test
                </div>
            </Grid>

            <Modal
                visible={modal}
                onExit={(): void => showModal(false)}
                title=""
                children={ModalContent()}
            />
        </>
    )
}

export default TutorAdvisorTests
