import { selectStartDateForAvailability } from "../../../utils/helper/session-utils"
import { BookingSessionsConstant } from "../../constant"

const initialState = {
    isError: false,
    isLoading: false,
    errorMessage: "",
    currentWeek: [],
    startDateForAvailability: '',
    initialRequest: [],
    selectedSessions: [],
    teacherData: "",
    rangeValues: [
        { id: 0, value: 15 },
        { id: 1, value: 15 },
        { id: 2, value: 15 },
        { id: 3, value: 15 },
        { id: 4, value: 15 },
        { id: 5, value: 15 },
        { id: 6, value: 15 },
    ],
    options: {
        subject: [],
        studentComment: "",
        sessionRequests: [],
        activeSlots: [],
        rapidBook: false
    },
    nonAvailableSlots: [],
}

const bookingReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case BookingSessionsConstant.SET_BOOKING_INFO:
            return {
                options: {
                    ...state.options,
                    ...action.props,
                },
            }

        case BookingSessionsConstant.SET_TEACHER_INFO:
            return {
                ...state,
                teacherData: action.props.teacherData,
            }

        case BookingSessionsConstant.SET_WEEK_INFO:
            return {
                ...state,
                currentWeek: action.props.currentWeek,
                startDateForAvailability: selectStartDateForAvailability(
                    state.startDateForAvailability,
                    action.props.currentWeek,
                ),
            }

        case BookingSessionsConstant.SET_SELECTED_SESSIONS:
            return {
                ...state,
                selectedSessions: action.props.selectedSessions,
            }
        case BookingSessionsConstant.SET_RANGE_VALUES:
            return {
                ...state,
                rangeValues: action.props.rangeValues,
            }
        case BookingSessionsConstant.SET_SUBJECT:
            return {
                ...state,
                options: {
                    ...state.options,
                    subject: action.props.subject,
                },
            }
        case BookingSessionsConstant.SET_RAPID_BOOK:
            return {
                ...state,
                options: {
                    ...state.options,
                    rapidBook: action.props.rapidBook
                }
            }
        case BookingSessionsConstant.SET_SESSION_REQUEST:
            return {
                ...state,
                options: {
                    ...state.options,
                    sessionRequests: action.props.sessionRequests,
                },
            }
        case BookingSessionsConstant.SET_STUDENT_COMMENTS:
            return {
                ...state,
                options: {
                    ...state.options,
                    studentComment: action.props.studentComment,
                },
            }
        case BookingSessionsConstant.SET_NON_AVAILABLE_SLOTS:
            return {
                ...state,
                nonAvailableSlots: action.props,
            }

        case BookingSessionsConstant.SET_ACTIVE_SLOTS:
            return {
                ...state,
                options: {
                    ...state.options,
                    activeSlots: action.props.activeSlots
                }
            }
        default:
            return state
    }
}

export default bookingReducer
