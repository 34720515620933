import React from "react";
import Modal from "components/modal_/Modal";
import './singleslotbookingrequested.scss';

export type slotType = {
    endTime: string;
    id: string;
    sessionLength: number;
    startTime: string;
    status: string;
}

export interface singleSlotBookingByStudentType {
    id: string
    studentPicUrl: string,
    studentName: string,
    startTime: string,
    sessionLength: number,
    studentNote: string,
    originalStartTime?: string
    slots: slotType[],
    isRescheduleRequest?: boolean,
    rescheduleRequestedBy?: string,
}

export type singleSlotPopupHandlersType= {
    isShown: boolean,
    setIsShown?: React.Dispatch<React.SetStateAction<boolean>>,
    onClose: () => void,
    onGotoMySession?: () => void,
}

export type oneBookingPopupType = singleSlotBookingByStudentType & singleSlotPopupHandlersType
export function SingleSlotBookingRequested(props: oneBookingPopupType) {
    return <Modal
        visible={props.isShown}
        onExit={props.onClose}
    >
        <div className="model_singleslotbookingrequested">
            <img src={props.studentPicUrl} alt="tutor images" />
            <h3>You have a new booking from {props.studentName}!</h3>
            <h4>{props.startTime}</h4> {/*March 7, 2020 10:00 AM*/}
            <h5>{props.sessionLength} MIN</h5>
            <div className="grey_div">
                <h4>Notes from {props.studentName}:</h4>
                <p>“{props.studentNote}”</p>
            </div>

            <a style={{ textDecoration: "none" }} href="javascript:;" className="btnGoToSession" onClick={props.onGotoMySession}>Go to My Sessions</a>
        </div>

    </Modal>
} 