import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux";
import { updateAffiliate } from "utils/services/userServices";

const ShareaSale = () => {
    const userAuthData =useSelector((state: any) => state.auth.userAuthData);
    const affiliate = userAuthData?.affiliate;
    const subId = userAuthData.subscription?.id;
    const netPrice:number =userAuthData?.subscription?.unit_amount/100 || 0;
    const [newSale,setNewSale]=useState<boolean>(false)

    useEffect(()=>{
        if(document.cookie.includes("clickId") && !affiliate?.shareaSale){
            const affiliate ={shareaSale :true};
           updateAffiliate({affiliate:affiliate},userAuthData.userId).then((data)=>{
               console.log(data);
               setNewSale(true);
           })
        }
    },[userAuthData])
    return (
        <div>
            {newSale &&
            <img src={`https://www.shareasale.com/sale.cfm?tracking=${subId}&amount=${netPrice}&merchantID=121532&transtype=sale`}width="1" height="1"></img>
            }
            </div>
    )
}
export default ShareaSale
