import React from "react"
import { Container } from "@material-ui/core"
import { useTranslation } from 'react-i18next';
import BackArrowSvgIcon from "../../assets/images/svgIcons/BackArrowSvgIcon"
import ForwardArrowSvgIcon from "../../assets/images/svgIcons/ForwardArrowSvgIcon"
import colors from "../../assets/colors"


const LessonNavigation = ({ previous, next, page, index,total }: { previous: any, next: any, page: string, total: number,index: number}): JSX.Element => {
    const { t } = useTranslation();
     
    function ShowPrevious() {
        if (index == 0) {
            return false
        }else{
            return true

        }
    }  function ShowNext() {
        
        if (index == total - 1) {
            return false
        }else{
            return true

        }
    }
  
    return (
        <>
            <div className="display-flex lesson-navigation-container">
                {ShowPrevious() &&
                <div className="display-flex previous" onClick={previous}>
                    <span className="back-arrow">
                        <BackArrowSvgIcon iconColor={colors.vividBlue} width="32" height="32" />
                    </span>
                    <u>{t('previous')}</u>
                    <u className="lesson">&nbsp;{t(page)}</u>
                </div>}
                {ShowNext() &&
                    <div className="display-flex next" onClick={next}>
                        <u>{t('next')}</u>
                        <u className="lesson">&nbsp;{t(page)}</u>
                        <span className="forward-arrow">
                            <ForwardArrowSvgIcon iconColor={colors.vividBlue} width="32" height="32" />
                        </span>
                    </div>}
            </div>
        </>
    )
}

export default LessonNavigation