import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux";
import { messageCenterConstant } from "store/constant";
import { Chat, ChatEvents, CountEvents, useMessageCenter } from "tcd-message-center"



const MessageCenterListener = (): JSX.Element => {

    const { connectedPeople } = useSelector((state: any) => state.messageCenter)

    const messageCenter = useMessageCenter();
    const dispatch = useDispatch();

    useEffect(() => {
        if (!messageCenter) {
            return;
        }
        dispatch({ type: messageCenterConstant.SET_UNREAD_COUNT, payload: messageCenter.unreadCount });
        const countListener = (count: number) => {
            dispatch({ type: messageCenterConstant.SET_UNREAD_COUNT, payload: count })
        };
        messageCenter.on(CountEvents.Unread, countListener);

        const newChatListner = (userChatIds: string[]) => {
            updateChats();
        }
        messageCenter.on(ChatEvents.CREATED, newChatListner);
        return () => {
            messageCenter.off(CountEvents.Unread, countListener);
            messageCenter.off(ChatEvents.CREATED, newChatListner);
        }

    }, [messageCenter]);

    const updateChats = () => {
        if (!messageCenter) {
            return;
        }
        messageCenter.getChats(10, 0).then((chats: Array<Chat>) => {
            const newChats = chats.filter(((newChat: Chat) => !connectedPeople.some((existingChat: Chat) => newChat.id === existingChat.id)));
            const allChats = [...connectedPeople, ...newChats];
            dispatch({ type: messageCenterConstant.UPDATE_CHATS, payload: allChats });
        });
    }

    return (
        <>
        </>
    )
}

export default MessageCenterListener;
