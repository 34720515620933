import React from "react"
import TutorSearchStoreProvider from "../../../store/tutor-search/store"
import TutorSearch from "./TutorSearch"
import LogoWithText from "../../../assets/images/svgIcons/LogoWithText"

export const defaultFilters = []

const TutorSearchPageOpen = (props: any): JSX.Element => {
    return (
        <TutorSearchStoreProvider>
            <div className="header">
                <div className="navbar-logo">
                    <a href="https://theclassroomdoor.com/">   <LogoWithText /></a>
                </div>
            </div>
            <TutorSearch location={props.location} openSearch={true}></TutorSearch>
        </TutorSearchStoreProvider>
    )
}

export default TutorSearchPageOpen
