import React, { useEffect, useMemo, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { availabilityStateType } from "../../../../store/tutor-search/store"
import moment from "moment"
import colors from "../../../../assets/colors"
import BookingSlotTab from "./ui-elements/BookingSlotTab"
import { setSessionRequest, setActiveSlot } from "../../../../store/actions/BookingActions"
import { parseISO, addMinutes, startOfDay, isWithinInterval, isSameDay } from "date-fns";
import AlarmIcon from '@material-ui/icons/Alarm';
import InfoIcon from '@material-ui/icons/Info';
import { BOOKING_SLOT_INTERVAL_LENGTHS } from "utils/constant/BookingConstant";
import { useMediaQuery } from 'react-responsive';
import DatePicker from "./ui-elements/DatePicker";
import { Col, Row } from "reactstrap";
import { useTranslation } from 'react-i18next';

export const BOOKING_SLOT_INTERVAL = BOOKING_SLOT_INTERVAL_LENGTHS; // 30 for normal and 15 for rapid booking

interface Props {
    availability: availabilityStateType;
    nonAvailableSlots: any;
    timeZone: string,
    isRapidBooking?: boolean
}

interface SlotType {
    id: string,
    startTime: string,
    endTime: string,
    date: string
}

const calculateEndTime = (startTime: string, slotInterval: number) =>
    moment(startTime).add(slotInterval, "minutes").format()


function normalizeSlots(slots: SlotType[]) {
    const mergedSlots = slots.map((slot: SlotType) => {
        return slots.reduce((slot: SlotType, currSlot: SlotType) => {
            const mergedSlot = mergeSlot(slot, currSlot) || slot;
            return {
                ...mergedSlot,
            };
        }, slot);
    });
    return mergedSlots.reduce((result: any, slot: SlotType) => {
        if (result.ids.includes(slot.id)) {
            return result;
        }

        result.slots.push(slot);
        result.ids.push(slot.id);
        return result;
    }, { slots: [], ids: [] }).slots;
}
function mergeSlot(slot1: SlotType, slot2: SlotType) {
    const endTime1 = moment(slot1.endTime);
    const endTime2 = moment(slot2.endTime);
    const startTime1 = moment(slot1.startTime);
    const startTime2 = moment(slot2.startTime);
    if (startTime1.isSame(endTime2)) {
        return {
            ...slot2,
            endTime: slot1.endTime
        }
    }
    if (startTime2.isSame(endTime1)) {
        return {
            ...slot1,
            endTime: slot2.endTime
        }
    }
    if (startTime1.isBetween(startTime2, endTime2)) {
        return {
            ...slot2,
            endTime: endTime1.isAfter(endTime2) ? slot1.endTime : slot2.endTime
        }
    }
    if (startTime2.isBetween(startTime1, endTime1)) {
        return {
            ...slot1,
            endTime: endTime2.isAfter(endTime1) ? slot2.endTime : slot1.endTime
        }
    }
    if (endTime1.isSame(endTime2)) {
        return {
            ...slot1,
            startTime: startTime2.isBefore(startTime1) ? slot2.startTime : slot1.startTime,
            id: startTime2.isBefore(startTime1) ? slot2.startTime : slot1.startTime
        }
    }
    if (startTime1.isSame(startTime2)) {
        return {
            ...slot1,
            endTime: endTime2.isAfter(endTime1) ? slot2.endTime : slot1.endTime
        }
    }
    return null;
}

const BookingAvailabilityComponent = (props: Props): JSX.Element => {
    const { t } = useTranslation();
    const dispatch = useDispatch()
    const { nonAvailableSlots, availability, timeZone, isRapidBooking = false } = props

    // Current week will be Sunday - Saturday (array of size 7) in the BROWSER'S time zone
    // (NOT the tutor's or student's time zone - this is VERY IMPORTANT)
    const currentWeek = useSelector((state: any) => state.booking.currentWeek)

    // Selected sessions starts empty
    const selectedSessions = useSelector((state: any) => state.booking.options.sessionRequests)
    
    // Selected slots starts empty
    const selectedSlots = useSelector((state: any) => state.booking.options.activeSlots)

    const SLOT_INTERVAL = useMemo(() => BOOKING_SLOT_INTERVAL[+isRapidBooking], [isRapidBooking]);
    
    // Selected date is the beginning of the day (midnight)
    const [selectedDate, setSelectedDate] = useState(startOfDay(new Date())); 
    
    // Will match smaller iPad or iPad in Portrate (roughly)
    const isUnder768 = useMediaQuery({
        query: '(max-device-width: 768px)'
    });
    // Used to handle phones (roughly)
    const isUnder414 = useMediaQuery({
        query: '(max-device-width: 414px)'
    });

    useEffect(() => {
        const slots: SlotType[] = selectedSessions.map((timeString: string) => {
            const startTimeM = moment(timeString);
            const endTime = calculateEndTime(timeString, SLOT_INTERVAL)
            const date = startTimeM.format("YYYY-MM-DD");
            return { startTime: timeString, endTime, id: timeString, date };
        });
        let normalizedSlots: SlotType[] = slots;
        slots.map(slot => {
            normalizedSlots = normalizeSlots(normalizedSlots);
        });
        dispatch(setActiveSlot({ activeSlots: normalizedSlots }));
    }, [selectedSessions]);
    const handleCheckbox = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const updatedActiveSlots = [...selectedSlots]
        const updatedSelectedSlots = [...selectedSessions]
        const currTimeId = e.target.id
        const currTimeIdM = moment(currTimeId).format("YYYY-MM-DD")

        if (updatedSelectedSlots.find((sess: string) => sess === currTimeId)) {
            const existingSlot = updatedActiveSlots.find(
                (i: any) =>
                    i.date === currTimeIdM &&
                    (i.startTime === currTimeId || i.endTime === calculateEndTime(currTimeId, SLOT_INTERVAL)),
            )
            if (!existingSlot) {
                const midSlot = [...updatedActiveSlots].find((slot: any) =>
                    moment(currTimeId).isBetween(moment(slot.startTime), moment(slot.endTime)),
                )
                console.log(midSlot)
                const firstSlot = {
                    ...midSlot,
                    endTime: currTimeId,
                }
                const secondSlot = {
                    ...midSlot,
                    startTime: calculateEndTime(currTimeId, SLOT_INTERVAL),
                    id: calculateEndTime(currTimeId, SLOT_INTERVAL),
                }
                const updatedSlots = [...updatedActiveSlots].filter(
                    (slot: any) => slot.id !== midSlot.id,
                )
                const newUpdatedSlots = [...updatedSlots, firstSlot, secondSlot]
                dispatch(
                    setSessionRequest({
                        sessionRequests: updatedSelectedSlots.filter(
                            (sess: string) => sess !== currTimeId,
                        ),
                    }),
                )
                return
            }
            if (existingSlot.endTime === calculateEndTime(currTimeId, SLOT_INTERVAL)) {
                const changedSlot = { ...existingSlot, endTime: currTimeId }
                const updatedSlots = [...updatedActiveSlots].filter(
                    (slot: any) => slot.id !== changedSlot.id,
                )
                updatedSlots.push(changedSlot)
                dispatch(
                    setSessionRequest({
                        sessionRequests: updatedSelectedSlots.filter(
                            (sess: string) => sess !== currTimeId,
                        ),
                    }),
                )
                return
            }
            if (existingSlot.startTime === currTimeId) {
                const changedSlot = {
                    ...existingSlot,
                    startTime: calculateEndTime(currTimeId, SLOT_INTERVAL),
                    id: calculateEndTime(currTimeId, SLOT_INTERVAL),
                }
                const updatedSlots = [...updatedActiveSlots].filter(
                    (slot: any) => slot.id !== existingSlot.id,
                )
                updatedSlots.push(changedSlot)

                dispatch(
                    setSessionRequest({
                        sessionRequests: updatedSelectedSlots.filter(
                            (sess: string) => sess !== currTimeId,
                        ),
                    }),
                )
                return
            }
            if (
                existingSlot.startTime === currTimeId &&
                existingSlot.endTime === calculateEndTime(currTimeId, SLOT_INTERVAL)
            ) {
                const updatedSlots = [...updatedActiveSlots].filter(
                    (slot: any) => slot.id !== existingSlot.id,
                )

                dispatch(setActiveSlot({ activeSlots: updatedSlots }))
                return
            }
        } else {
            dispatch(setSessionRequest({ sessionRequests: [...selectedSessions, currTimeId] }))
        }

        if (updatedActiveSlots.find((i: any) => i.date === currTimeIdM)) {
            const newSlot = {
                id: currTimeId,
                startTime: currTimeId,
                endTime: calculateEndTime(currTimeId, SLOT_INTERVAL),
                date: currTimeIdM,
            }
            const mergableSlot = updatedActiveSlots.filter(
                (slot: any) =>
                    slot.date === newSlot.date &&
                    (slot.startTime === newSlot.endTime || slot.endTime === newSlot.startTime),
            )
            if (mergableSlot.length === 1) {
                const slotToMerge = mergableSlot[0]
                if (slotToMerge.endTime === newSlot.startTime) {
                    const updatedSlots = updatedActiveSlots.filter(
                        (i: any) => i.id !== slotToMerge.id,
                    )
                    updatedSlots.push({ ...slotToMerge, endTime: newSlot.endTime })
                    // dispatch(setActiveSlot({ activeSlots: updatedSlots }))
                } else {
                    const updatedSlots = updatedActiveSlots.filter(
                        (i: any) => i.id !== slotToMerge.id,
                    )
                    updatedSlots.push({ ...newSlot, endTime: slotToMerge.endTime })
                    // dispatch(setActiveSlot({ activeSlots: updatedSlots }))
                }
            } else if (mergableSlot.length === 2) {
                const firstSlot = mergableSlot[0]
                const secondSlot = mergableSlot[1]
                const currentActiveSlots = [...updatedActiveSlots]

                const updatedSlots = currentActiveSlots.filter((i: any) => {
                    if (i.id === secondSlot.id) {
                        return false
                    }
                    if (i.id === firstSlot.id) {
                        return false
                    }
                    return true
                })
                updatedSlots.push({ ...firstSlot, endTime: secondSlot.endTime })
            } else {
                // Do nothing
            }
        }
        if (!updatedActiveSlots.find((i: any) => i.date === currTimeIdM)) {
            updatedActiveSlots.push({
                id: currTimeId,
                startTime: currTimeId,
                endTime: calculateEndTime(currTimeId, SLOT_INTERVAL),
                date: currTimeIdM,
            })
        }
    }

    const getTotalLength = (): number =>
        selectedSlots.reduce((acc: number, currentVal: { startTime: string, endTime: string }) => {
            const diff = moment
                .duration(moment(new Date(currentVal.endTime)).diff(new Date(currentVal.startTime)))
                .asHours()
            return acc + diff
        }, 0)

    return (
        isUnder768 ?
            /* Medium or small view: Small iPad and phones */
            <Row>
                <Col xs={12}>
                    <div className="mobile-book-slots">
                        <div
                            className="section-header"
                        >
                            {/* <div>Time Range</div> */}
                            {isRapidBooking && <div className="rapid-book-meta">
                                <AlarmIcon className="rapid-book-clock"></AlarmIcon>
                                <p className="rapid-book-title">Rapid book</p>
                                <InfoIcon className="rapid-book-info" titleAccess={t('rapid-book-info')}></InfoIcon>
                            </div>}
                            <div className="multiple-label">
                                {selectedSlots.length > 1 ? "Multiple sessions booked:" : null}{" "}
                                <span className="length-label">{getTotalLength()}h total selected</span>
                            </div>
                        </div>
                        <DatePicker
                            getSelectedDay={(d: Date) => {
                                setSelectedDate(d);
                            }}
                            endDate={200}
                            selectDate={selectedDate}
                            labelFormat={"yyyy MMMM"}
                            color={"$darkMango"}
                        />
                        <div className="tabs" key={selectedDate.toISOString()}>
                            <BookingSlotTab
                                date={selectedDate}
                                nonAvailableSlots={nonAvailableSlots}
                                onChange={handleCheckbox}
                                selectedSessions={selectedSessions}
                                selectedSlots={selectedSlots}
                                availability={availability}
                                teacherTimeZone={timeZone}
                                isRapidBooking={isRapidBooking}
                                day={moment(selectedDate).day()}
                            />
                        </div>
                    </div>
                </Col>
            </Row>
        :
            // Larger view: Full size browser on computer and landscape iPad or large iPad
            <div className="book-slots">
                { /* Render the column showing the day of the week (SUN, MON, etc.) and day of month (1st, 2nd, etc.) */ }
                <div style={{ width: "140px" }}>
                    <div className="section-content">
                        { /* currentWeek should be an array of size 7 representing Sun - Sat of the BROWSER'S time zone. */ }
                        {currentWeek[0] &&
                            moment.weekdaysShort().map((day: string, index: number) => {
                                const date = currentWeek ? currentWeek[index].format("Do") : currentWeek
                                if (isRapidBooking === true) {
                                    const currentDate = new Date();
                                    const limitDate = addMinutes(currentDate, 12 * 60);
                                    const slotTabDate = moment(currentWeek[index]).toDate();
                                    const cdf = moment(currentDate).format("YYYY-MM-DD");
                                    const ldf = moment(limitDate).format("YYYY-MM-DD");
                                    const stdf = moment(slotTabDate).format("YYYY-MM-DD");
                                    if (stdf !== cdf && stdf !== ldf) {
                                        return <></>;
                                    }
                                }
                                return (
                                    <div className="line" key={index}>
                                        <div className="slot-title">
                                            <div className="day">{day}</div>
                                            <div
                                                className="date-title"
                                                style={{ color: colors.darkMango, textAlign: "right" }}
                                            >
                                                {date}
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                    </div>
                </div>
                <div style={{ width: "calc(100% - 140px)" }}>
                    <div
                        className="section-header"
                    >
                        {isRapidBooking && <div className="rapid-book-meta">
                            <AlarmIcon className="rapid-book-clock"></AlarmIcon>
                            <p className="rapid-book-title">Rapid book</p>
                            <InfoIcon className="rapid-book-info" titleAccess={t('rapid-book-info')}></InfoIcon>
                        </div>}
                        <div className="multiple-label">
                            {selectedSlots.length > 1 ? "Multiple sessions booked:" : null}{" "}
                            <span className="length-label">{getTotalLength()}h total selected</span>
                        </div>
                    </div>
                    <div className="section-content-tabs">
                        {currentWeek[0] &&
                            moment.weekdaysShort().map((day: string, index: number) => {
                                if (isRapidBooking === true) {
                                    const currentDate = new Date();
                                    const limitDate = addMinutes(currentDate, 12 * 60);
                                    const slotTabDate = moment(currentWeek[index]).toDate();
                                    const cdf = moment(currentDate).format("YYYY-MM-DD");
                                    const ldf = moment(limitDate).format("YYYY-MM-DD");
                                    const stdf = moment(slotTabDate).format("YYYY-MM-DD");
                                    if (stdf !== cdf && stdf !== ldf) {
                                        return <></>;
                                    }
                                }

                                return (
                                    <div className="tabs" key={day}>
                                        <BookingSlotTab
                                            key={currentWeek[index]}
                                            date={currentWeek[index]}
                                            nonAvailableSlots={nonAvailableSlots}
                                            onChange={handleCheckbox}
                                            selectedSessions={selectedSessions}
                                            selectedSlots={selectedSlots}
                                            availability={availability}
                                            teacherTimeZone={timeZone}
                                            isRapidBooking={isRapidBooking}
                                            day={index}
                                        />
                                    </div>
                                )
                            })}
                    </div>
                </div>
            </div>
    )
}

export default BookingAvailabilityComponent
