import React from "react"
import { useTranslation } from 'react-i18next';
import LessonsCard from "../../../../components/lesson/LessonsCard"



const LessonSubscribeHeader = (): JSX.Element => {
    const { t } = useTranslation();
    return (
        <>
            <h2 style={{ paddingLeft: 0 }} className="checkout-header">
                {t("checkout")}
            </h2>
            <LessonsCard />
            <div className="header d-flex"></div>
        </>
    )
}

export default LessonSubscribeHeader

