import React from "react";
import Modal from "components/modal_/Modal";
import StudentTutorRejectionAvatar from "components/student-tutor-rejection-avatar/StudentTutorRejectionAvatar"
import './teachersection.scss';
import Button from "components/button/Button";

interface TutorStdRejectTimeSuggestions {
    studentPicUrl: string,
    teacherPicUrl: string,
    studentName: string,
    onGoto: () => void,
    isShown: boolean
    onClose: () => void
}
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function TutorStdRejectTimeSuggestion(props: TutorStdRejectTimeSuggestions) {
    return <Modal
        visible={props.isShown}
        onExit={props.onClose}
    >
        <div className="model_tutorstdacceptmysuggestion">
            <div className="imgs_div">
                <StudentTutorRejectionAvatar
                    size="100"
                    student={
                        props.studentPicUrl
                    }
                    tutor={
                        props.teacherPicUrl
                    }
                />
            </div>
            <h2>Unfortunately, {props.studentName} couldn't accept your request(s)</h2>
            {/* <h4>Test about the account credit.</h4> */}
            <div className="btn_stdwhentutoracceptsomeofmyreuests">
                {/* <Button className="" title="Search Tutors" clickAction={props.onSearch} isOutlineStyle={false}/> */}
                <Button className="" title="Go to Dashboard" clickAction={props.onGoto} isOutlineStyle={true}/>
            </div>
        </div>
    </Modal>
}
