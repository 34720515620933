import { authSignUpConstant, LogoutConstant } from "../constant"

const initialState = {
    isError: false,
    isloading: false,
    errorMessage: "",
    options: { userLookingFor: "", studentAdvisingTopics: "", studentTutoringSubjects: "" },
}

const signupReducer = (state = initialState, action: any): object => {
    switch (action.type) {
        case authSignUpConstant.SIGNUP_REQUEST:
            return {
                isloading: true,
                options: { ...state.options },
            }
        case authSignUpConstant.SIGNUP_SUCCESS:
            return {
                isError: false,
                isloading: false,
                options: { ...state.options },
            }
        case authSignUpConstant.SIGNUP_FAILURE:
            return {
                isError: true,
                isloading: false,
                options: { ...state.options },
            }
        case authSignUpConstant.SIGNUP_INFORMATION:
            return {
                options: {
                    ...state.options,
                    ...action.options,
                },
            }
        case LogoutConstant.CLEAR_DATA:
            return initialState
        default:
            return state
    }
}

export default signupReducer
