import React from "react"
import NotFound from "../../NotFound"
import PrivateRoute from "../../../routes/PrivateRoute"

import TutorSearchPage from "./TutorSearchPage"
import TutorSearchPageOpen from "./TutorSearchPageOpen"
import TutorProfilePage from "../tutor-profile/TutorProfilePage"
import { Switch, Route } from "react-router-dom"
import BookSessionPage from "../booking/book-session/BookSessionPage"
import ResumeAndConfirmPage from "../booking/resume-confirm/ResumeAndConfirmPage"
import BookingConfirmedPage from "../booking/booking-confirmed/BookingConfirmedPage"

interface Iprops {
    match: any
    history: any
    userAuthData: any
    dispatch: any
}

class TutorSearchRoute extends React.PureComponent<Iprops> {
    render() {
        return (
            <Switch>
                <Route exact path="/search" component={TutorSearchPageOpen} />
                <Route exact path={this.props.match.path} component={TutorSearchPage} />
                <PrivateRoute
                    path={`${this.props.match.path}/tutor-profile`}
                    component={TutorProfilePage}
                />
                <PrivateRoute
                    path={`${this.props.match.path}/tutor-profile?*`}
                    component={TutorProfilePage}
                />
                <PrivateRoute
                    path={`${this.props.match.path}/book-session`}
                    component={BookSessionPage}
                />
                <PrivateRoute
                    path={`${this.props.match.path}/resume-and-confirm`}
                    component={ResumeAndConfirmPage}
                />
                <PrivateRoute
                    path={`${this.props.match.path}/booking-confirmed`}
                    component={BookingConfirmedPage}
                />
                <PrivateRoute path="*" exact component={NotFound} />
            </Switch>
        )
    }
}

export default TutorSearchRoute
