import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { listUsers, getUsersAlldata } from "./apis";
import { Table } from "./../Table";
import { User } from "./types";
import { Column, CellProps } from "react-table";

import PositionedMenu from "../../components/Table/components/menu/menu";
import { object } from "yup";

const UTable = () => {
    const {
        data: sData,
        isLoading,
        error,
        isError,
        refetch,
        isFetching,
    } = useQuery("list-all-users", getUsersAlldata, {
        refetchOnWindowFocus: false,
    });
    const [data, setsData] = useState<User[]>(sData);
    useEffect(() => {
        setsData(sData);
    }, [sData]);

    const updateData = async () => {
        await refetch();
        return true;
    };
    const columns: Column<User>[] = useMemo(
        () => [
            {
                Header: "Personal Info",
                Footer: "Personal Info",
                columns: [
                    {
                        Header: "First Name",
                        accessor: "firstName",
                        aggregate: "count",
                        Aggregated: ({ cell: { value } }: CellProps<User>) =>
                            `${value} Names`,
                    },
                    {
                        Header: "Last Name",
                        accessor: "lastName",
                        aggregate: "count",
                        // filter: 'text',
                        Aggregated: ({ cell: { value } }: CellProps<User>) =>
                            `${value} Names`,
                    },
                ],
            },
            {
                Header: "Other Info",
                Footer: "Other Info",
                columns: [
                    {
                        Header: "ID",
                        accessor: "id",
                        aggregate: "count",
                        Aggregated: ({ cell: { value } }: CellProps<User>) =>
                            `${value} IDs`,
                    },
                    {
                        Header: "Email",
                        accessor: "email",
                        aggregate: "count",
                        Aggregated: ({ cell: { value } }: CellProps<User>) =>
                            `${value} Emails`,
                    },
                    {
                        Header: "Type",
                        accessor: "type",
                    },
                    {
                        Header: "Time Zone",
                        accessor: "defaultTimeZone",
                        Cell: ({ value }: { value: any }) => value?.toString() || "",
                    },
                    {
                        Header: "Verified",
                        accessor: "verified",
                        Cell: ({ value }: { value: boolean }) => value?.toString() || "",
                    },
                ],
            },
            {
                Header: "Time Details",
                Footer: "Time Details",
                columns: [
                    {
                        Header: "Created On",
                        accessor: "createdAt",
                        Cell: ({ value }: { value: Date }) => value?.toString() || "",
                    },
                ],
            },
            {
                Header: "Options",
                Footer: "Options",
                columns: [
                    {
                        width: 300,
                        Header: "options",
                        accessor: "options",
                        Cell: ({ cell }: any) => (
                            <PositionedMenu data={cell.row.values} updateData={updateData} />
                        ),
                    },
                ],
            },
        ],
        []
    );
    return (
        <>
            {!isLoading && (
                <Table<User> updateData={updateData} columns={columns} data={data || []} name="Users" />
            )}
        </>
    );
};
export const UsersTable = memo(UTable, () => true);
