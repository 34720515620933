import React from "react"
import { useSelector } from "react-redux"
import { Route, Redirect, RouteProps, useLocation } from "react-router-dom"
import withTokenLogin from "./withTokenLogin";
interface AdminRouteProps extends RouteProps {
    component: any
}

const AdminRoute = (props: AdminRouteProps): JSX.Element => {
    const isLoggedIn = useSelector((state: any) => state?.auth?.loggedIn);
    const isAdmin = useSelector((state: any) => state?.auth?.userAuthData?.userType === "ADMIN");
    const { component: Component, ...rest } = props
    return (
        <Route
            {...rest}
            render={(routeProps) =>
                (isLoggedIn === true && isAdmin === true) ? (
                    <>
                        <Component {...routeProps} />
                    </>
                ) : (
                    <>
                        <Redirect
                            to={{
                                pathname: '/dashboard',
                                state: { from: routeProps.location },
                            }}
                        />
                    </>
                )
            }
        />
    )
}

export default withTokenLogin(AdminRoute);
