import colors from "assets/colors"
import React from "react"
import interfaceConstant from "../../../utils/constant/interfaceConstant"

class InfoSvgIcon extends React.PureComponent<interfaceConstant.SvgIconProps> {
    render(): JSX.Element {
        const { iconColor, color,...otherProps } = this.props
        return (
            <svg width="24px" height="24px" viewBox="0 0 24 24" {...otherProps}>
                <g
                    id="Icons-/-Base-/-Bas-18"
                    stroke="none"
                    strokeWidth="1"
                    fill={iconColor}
                    fillRule="evenodd"
                >
                    <path
                        d="M12,1 C18.05,1 23,5.95 23,12 C23,18.05 18.05,23 12,23 C5.95,23 1,18.05 1,12 C1,5.95 5.95,1 12,1 Z M12,3.75 C7.4625,3.75 3.75,7.4625 3.75,12 C3.75,16.5375 7.4625,20.25 12,20.25 C16.5375,20.25 20.25,16.5375 20.25,12 C20.25,7.4625 16.5375,3.75 12,3.75 Z M12,9.25 C12.825,9.25 13.375,9.8 13.375,10.625 L13.375,10.625 L13.375,17.5 C13.375,18.325 12.825,18.875 12,18.875 C11.175,18.875 10.625,18.325 10.625,17.5 L10.625,17.5 L10.625,10.625 C10.625,9.8 11.175,9.25 12,9.25 Z M12,5.125 C12.7593915,5.125 13.375,5.74060847 13.375,6.5 C13.375,7.25939153 12.7593915,7.875 12,7.875 C11.2406085,7.875 10.625,7.25939153 10.625,6.5 C10.625,5.74060847 11.2406085,5.125 12,5.125 Z"
                        id="Combined-Shape"
                        fill={color?color:colors.darkMango}
                    ></path>
                </g>
            </svg>
        )
    }
}

export default InfoSvgIcon
