import {
    lessonsConstant,
} from "../constant"

export const fetchLessons = () => {
    return { type: lessonsConstant.LESSONS_REQUEST }
}
export const fetchSuccessLessons = (props: any) => {
    return {
        type: lessonsConstant.LESSONS_SUCCESS,
        payload: props,
    }
}
export const findLessonById = (props: any) => {
    return {
        type: lessonsConstant.LESSONS_FIND_REQUEST,
        payload: props
    }
}
export const findLessonSuccess = (props: any) => {
    return {
        type: lessonsConstant.FIND_LESSONS_SUCCESS,
        payload: props,
    }
}
export const navigateToLesson = (props: any) => {
    return {
        type: lessonsConstant.NAVIGATE_TO_LESSON,
        payload: props,
    }
}
