/* eslint-disable @typescript-eslint/no-empty-function */
import React from "react";
import Modal from "components/modal_/Modal";
import 'popups/booking/student/popups/StdTutorAcceptRequest.scss';
import Button from "components/button/Button";
import CancelIcon from '@material-ui/icons/Cancel';
import moment from "moment";
interface SlotType {
    id: string,
    startTime: string,
    endTime: string,
    sessionLength: string
}
interface StdWhenTutorAcceptRequests {
    studentPicUrl: string,
    onGoto: () => void,
    studentName: string,
    sessions: SlotType[],
    initialSessions: SlotType[],
    isShown: boolean,
    onClose: () => void
}

export function TutorWhenStdAcceptRequest(props: StdWhenTutorAcceptRequests) {

    return <Modal
        visible={props.isShown}
        onExit={props.onClose}
    >
        <div className="model_std_when_tutor_accept_Request">

            <div className="imgs_div">
                <img src={props.studentPicUrl} alt="imager_url" className="img_icons img_icon1" />
            </div>
            <h2>{props.studentName} couldn’t accept your booking request(s) for:</h2>
            {props.sessions.map((session: SlotType) => {
                const initialSession = props.initialSessions.filter(s => s.id === session.id).pop() as SlotType;
                const startTime = moment(session.startTime);
                const endTime = moment(session.endTime);
                const initialStartTime = moment(initialSession.startTime);
                const initialEndTime = moment(initialSession.endTime);
                return <form method="" action="" className="" onSubmit={e => e.preventDefault()}>
                    <h3><CancelIcon></CancelIcon> {initialStartTime.format("dddd, MMMM DD, YYYY")} from {initialStartTime.format("hh:mm A")} to {initialEndTime.format("hh:mm A")}</h3>
                    <h4>{props.studentName} suggested this time instead:</h4>

                    <div className="form-group">
                        {/* <label className="radio"> */}
                            {/* <input type="radio" name="rdo" id="opt1" className="hidden" /> */}
                            <span className="label"></span>{startTime.format("dddd, MMMM DD, YYYY")} from {startTime.format("hh:mm A")} to {endTime.format("hh:mm A")}
                        {/* </label> */}
                    </div>
                </form>
            })}
            <Button className="" title="Go to Sessions" clickAction={props.onGoto} isOutlineStyle={true}/>
        </div>

    </Modal>
}
