import React, { useEffect, useState } from "react"
import SignupForm from "./SignUpForm"
import SignUpsettingIcon from "../../../assets/images/signUpsetting.png"
import { modalConstant } from "../../../store/constant"
import { useDispatch, useSelector } from "react-redux"
import { applicationConstant } from "../../../utils/constant/applicationConstant"
import InterfaceConstant from "../../../utils/constant/interfaceConstant"
import Modal from "../../../components/modal_/Modal"
import ProgressBar from "../../../components/progress-bar/ProgressBar"
import RoundedButton from "components/rounded-button/RoundedButton"
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons"
import { navigatetoUrl } from "utils/helper/helper"
import { useHistory, useLocation } from "react-router-dom"
import Col from "reactstrap/es/Col"
import Row from "reactstrap/es/Row"
import ColoredTCDlogo from "../../../assets/images/colored-TCD-logo.png"
import ColoredInspiredlogo from "../../../assets/images/inspired-logo.png"

const singUpLabel: InterfaceConstant.IappLabels = applicationConstant.appLabels

const SignUp = (): JSX.Element => {
    const [isInspired, setIsInspired] = useState(false)
    const [canGoBack, setCanGoBack] = useState(true)
    const modalInfo = useSelector((state: any) => state.modal)
    const dispatch = useDispatch()
    const history = useHistory()
    const location = useLocation()

    const urlParams = new URLSearchParams(location.search)
    const redirectTo = urlParams.get("redirectTo")

    useEffect(() => {
        if (location.pathname.includes("inspired")) {
            setIsInspired(true)
            setCanGoBack(false)
        }
        if (location.search.toLowerCase().includes("website")) {
            setCanGoBack(false)
        }
        if (location.search.toLowerCase().includes("redirectto")) {
            setCanGoBack(false)
        }
    }, [])

    const onExitModal = (): void => {
        dispatch({ type: modalConstant.MODAL_HIDE })
        if (modalInfo.title !== "Error") {
            window.location.href = "/sign-in" + (redirectTo ? "?redirectTo=" + redirectTo : "")
        }
    }

    return (
        <>
            <ProgressBar at="100" theme="darkMango" />
            <div className="sign-up">
                <Modal
                    title={modalInfo.title}
                    visible={modalInfo.show}
                    icon={modalInfo.title !== "Error" ? SignUpsettingIcon : ""}
                    subtitle={modalInfo.text}
                    onExit={onExitModal}
                />
                {isInspired ? (
                    <>
                        <Row className="inspired-logo-bar">
                            <img src={ColoredTCDlogo} className="classroom-logo" />
                            <img src={ColoredInspiredlogo} className="inspired-logo" />
                        </Row>
                        <Row className="title-container">
                            <div className="signup-title inspired-signup-title">
                                Welcome Inspired students!
                            </div>
                            <p className="signup-subtitle">
                                Register for your free account and use the promo code -{" "}
                                <span className="promo-code">INSPIRED10</span> - to get 10% off all
                                Tutoring, Advising, and the College Admissions Guide
                            </p>
                        </Row>
                    </>
                ) : (
                    <Row className="title-container">
                        <div className="signup-title">{singUpLabel.SIGN_UP_SUB_TITLE}</div>
                    </Row>
                )}
                <div className="sign-up-continer">
                    <Row>
                        {canGoBack && (
                            <Col md="1" xs="12">
                                <div className="signup-back-btn">
                                    <RoundedButton
                                        onPress={() => {
                                            navigatetoUrl(history, "/looking-for")
                                        }}
                                        iconProps={{
                                            icon: faChevronLeft,
                                            style: {
                                                color: "#262ffa",
                                                alignSelf: "center",
                                                marginRight: "2px",
                                            },
                                        }}
                                        className="book-back-button"
                                        active={true}
                                    />
                                </div>
                            </Col>
                        )}
                        <Col md={!canGoBack ? "12" : "11"} xs="12">
                            <p className="switch-login-link" style={{ paddingTop: "30px" }}>
                                {" "}
                                Already have an account?{" "}
                                <u
                                    className="link"
                                    onClick={() => {
                                        navigatetoUrl(history, `/sign-in?redirectTo=${redirectTo}`)
                                    }}
                                >
                                    {" "}
                                    Sign In{" "}
                                </u>
                            </p>
                        </Col>

                        <Col md="12">
                            <SignupForm />
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    )
}

export default SignUp
