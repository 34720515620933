import React from "react"
import interfaceConstant from "../../../utils/constant/interfaceConstant"

class LessonsSvgIcon extends React.PureComponent<interfaceConstant.SvgIconProps> {
    render(): JSX.Element {
        const { width, height, iconColor, ...otherProps } = this.props
        return (
            <svg width={width} height={height} {...otherProps}>
                <title>Lessons</title>
                <g id="Page-1" stroke="none" strokeWidth="1" fillRule="evenodd">
                    <g
                        id="Menu-Expanded"
                        transform="translate(-37.000000, -1076.000000)"
                    >
                        <g id="Group-12" transform="translate(0.000000, 400.000000)">
                            <g id="Group-3" transform="translate(0.000000, 75.000000)">
                                <g id="Group" transform="translate(36.500000, 599.000000)">
                                    <path
                                        d="M6.34252293,16.5 C3.68421051,16.5 2.03174603,17.8765748 2.03174603,20.0923141 L2.03174603,27.2769422 L3.46867166,27.2769422 L3.46867166,30.1507935 L9.21637419,30.1507935 L9.21637419,27.2769422 L10.6532998,27.2769422 L10.6532998,20.0923141 C10.6532998,17.8765748 9.00083534,16.5 6.34252293,16.5 Z M8.77944903,25.0280916 L7.59252328,25.0280916 L7.59252328,27.4019431 L5.21867178,27.4019431 L5.21867178,25.0280916 L4.03174603,25.0280916 L4.03174603,20.2803886 C4.03174603,18.8091942 5.32252778,18.5 6.40559753,18.5 C7.48866728,18.5 8.77944903,18.8091942 8.77944903,20.2803886 L8.77944903,25.0280916 Z M29.333333,20.2431076 L29.333333,22 L12.0902255,22 L12.0902255,20.2431076 L26,20.2431076 L26,4.43692563 L7.46952152,4.43692563 L7.46952152,5.87385126 L5.62406011,5.87385126 L5.62406011,2.5 L27.8964074,2.5 L27.8964074,20.2431076 L29.333333,20.2431076 Z M23.5856305,18.0877191 L23.5856305,19.5246448 L19.2748536,19.5246448 L19.2748536,18.0877191 L23.5856305,18.0877191 Z"
                                        id="Shape"
                                    ></path>
                                    <g transform="translate(1.031746, 7.310777)">
                                        <path
                                            d="M10.7769422,-1.50990331e-13 L10.7769422,1.79615704 L8.08270668,1.79615704 L8.08270668,4.12307848 C8.38450008,4.64350362 8.53967552,5.23596345 8.53174594,5.83751038 C8.58082518,6.68525413 8.26545203,7.5135931 7.66500294,8.1140422 C7.06455384,8.71449129 6.23621487,9.02986444 5.38847112,8.9807852 C4.54072737,9.02986444 3.7123884,8.71449129 3.1119393,8.1140422 C2.5114902,7.5135931 2.19611706,6.68525413 2.2451963,5.83751038 C2.23726671,5.23596345 2.39244215,4.64350362 2.69423556,4.12307848 L2.69423556,4.12307848 L2.69423556,1.79615704 L-1.687539e-13,1.79615704 L-1.687539e-13,-1.50990331e-13 L10.7769422,-1.50990331e-13 Z M6.67811187,5.38847112 L4.09883036,5.38847112 C4.06106212,5.53517628 4.04175385,5.68602215 4.04135334,5.83751038 C3.99527084,6.20700415 4.12230569,6.57708442 4.38560138,6.84038011 C4.64889707,7.1036758 5.01897735,7.23071066 5.38847112,7.18462816 C5.75796489,7.23071066 6.12804516,7.1036758 6.39134085,6.84038011 C6.65463654,6.57708442 6.7816714,6.20700415 6.7355889,5.83751038 C6.73518838,5.68602215 6.71588011,5.53517628 6.67811187,5.38847112 L6.67811187,5.38847112 Z"
                                            id="Combined-Shape"
                                        ></path>
                                        <polygon
                                            id="Path"
                                            points="6.28654964 3.59231408 4.4903926 3.59231408 4.4903926 1.79615704 6.28654964 1.79615704"
                                        ></polygon>
                                        <path
                                            d="M10.7769422,3.59231408 C10.7769422,4.08830915 10.3748588,4.4903926 9.87886372,4.4903926 C9.38286865,4.4903926 8.9807852,4.08830915 8.9807852,3.59231408 C8.9807852,3.09631901 9.38286865,2.69423556 9.87886372,2.69423556 C10.3748588,2.69423556 10.7769422,3.09631901 10.7769422,3.59231408 Z"
                                            id="Path"
                                        ></path>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        )
    }
}

export default LessonsSvgIcon
