import { useEffect } from "react"

export const useOutsideAlerter = (ref: any, handleAction: Function): void => {
  useEffect(() => {
      const handleClickOutside = (event: any) => {
          if (ref.current && !ref.current.contains(event.target)) {
              handleAction(false)
          }
      }
      document.addEventListener("mousedown", handleClickOutside)
      return (): void => {
          document.removeEventListener("mousedown", handleClickOutside)
      }
  }, [ref])
}