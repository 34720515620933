import React from "react"
import { Icon } from "../icons/Icons"
import "react-datepicker/dist/react-datepicker.css"
import Tooltip from "@material-ui/core/Tooltip"
import InfoSvgIcon from "../../assets/images/svgIcons/InfoSvgIcon"
import InputMask from "react-input-mask"
import { DatePicker } from "@material-ui/pickers"

interface InputBoxProps {
    title: string
    inputProps: any
    tooltip?: any
    error: any
    placeholder: any
    type: string
    iconProps?: any
    isAlert?: boolean
    success?: string | undefined
    className?: string
    mask?: any
    textAreaRows?: string | undefined
}

export const Label = (title: string): JSX.Element => {
    return (
        <label className="input-label" htmlFor={title}>
            {title}
        </label>
    )
}

export const InputField = (
    inputProps: any,
    placeholder: string,
    type: string,
    alert: string | null,
    mask: string | null,
    textAreaRows: string | undefined,
): JSX.Element => {
    switch (type) {
        case "textarea":
            return (
                <textarea
                    rows={textAreaRows ? textAreaRows : "5"}
                    {...inputProps}
                    placeholder={placeholder}
                    style={{ borderRadius: alert ? "7px 0px 0px 7px" : "7px" }}
                ></textarea>
            )
        case "masked-input":
            return <InputMask {...inputProps} placeholder={placeholder} mask={mask} maskChar=" " />
        case "date":
            return (
                <DatePicker
                    {...inputProps}
                    showPopperArrow={false}
                    popperClassName={`custom-calendar-popper ${
                        inputProps.popperClass ? inputProps.popperClass : ""
                    }`}
                    popperModifiers={{
                        offset: {
                            enabled: true,
                            offset: "-5px, 10px",
                        },
                        preventOverflow: {
                            enabled: true,
                            escapeWithReference: false,
                            boundariesElement: "viewport",
                        },
                    }}
                />
            )

        default:
            return (
                <input
                    {...inputProps}
                    type={type}
                    placeholder={placeholder}
                    style={{ borderRadius: alert ? "7px 0px 0px 7px" : "7px" }}
                />
            )
    }
}

const returnStyleClassOfAlertLabel = (error: string | null): string => {
    return error ? "error-label" : "success-label"
}
export const InputAlertLabel = (error: string | null, success: string | undefined): JSX.Element => {
    const alertLabel = error ? error : success ? success : ""
    return <div className={returnStyleClassOfAlertLabel(error)}>{alertLabel}</div>
}

const returnStyleClassOfAlertBox = (error: string | null): string => {
    return error ? "error-box" : "success-box"
}
export const InputBox = ({
    title,
    inputProps,
    tooltip,
    error,
    placeholder,
    type,
    iconProps,
    success,
    className,
    mask,
    textAreaRows,
}: InputBoxProps): JSX.Element => {
    return (
        <div className={`custom-input ${className ? className : ""}`}>
           {title && Label(title)}
            <div className="input-container">
                {InputField(inputProps, placeholder, type, error, mask, textAreaRows)}
                {iconProps && <Icon className="input-icon" {...iconProps} />}
                {error || success ? <i className={returnStyleClassOfAlertBox(error)}></i> : null}
                {tooltip && (
                    <Tooltip title={tooltip} className="input-tooltip">
                        <InfoSvgIcon /> 
                    </Tooltip>
                )}
            </div>
            {InputAlertLabel(error, success)}
        </div>
    )
}
