/* STANDARTIZED TESTS LIST */

const standardizedTestList = [

    { id :'89c8814a-5cd3-11ec-bf63-0242ac130002',name :'DAT'},
    { id :'89c8838e-5cd3-11ec-bf63-0242ac130002',name :'DAT: Survey of Natural Sciences'},
    { id :'89c884a6-5cd3-11ec-bf63-0242ac130002',name :'DAT: Perceptual Ability Test'},
    { id :'89c887d0-5cd3-11ec-bf63-0242ac130002',name :'DAT: Reading Comprehension'},
    { id :'89c888d4-5cd3-11ec-bf63-0242ac130002',name :'DAT: Quantitative Reasoning '},
    { id: 'ec946bc6-6c4b-4df9-8340-c72306a8dac2', name: 'AABL' },

    { id: 'a5c7fb93-b335-4595-83b9-a2ef317a7db9', name: 'ACT' },

    { id: '13fb7370-fb4e-43b1-897e-b3c8c7772644', name: 'ACT (Math)' },

    { id: 'd5292f21-5e43-49b2-bb33-c96cd58d4d5d', name: 'ACT (Verbal)' },

 
    { id: '7ac4179d-7ec9-478b-b6f0-5933f4cf4a2c', name: 'AP Art History' },

    { id: '6fe751d5-760d-49a4-b732-a58928befcd8', name: 'AP Biology' },

    { id: '048a7f3d-ec18-4586-98a6-564952e4593d', name: 'AP Calculus' },

    { id: 'f5e34a2c-00ed-4860-8596-1f1db659cb62', name: 'AP Calculus AB' },

    { id: '58bddc06-03a7-48b3-bd8b-946f92e23c76', name: 'AP Calculus BC' },

    { id: 'd24d2eee-08a8-46b8-a759-5e023d04469c', name: 'AP Chemistry' },

    {

        id: '5682e0db-4454-42e6-9d07-443feae9c2d6',

        name: 'AP Chinese Language & Culture',

    },

    {

        id: 'f6708e06-2324-492e-a48a-179ca166510f',

        name: 'AP Comparative Government & Politics',

    },

    {

        id: '196c324d-566f-48f8-bcca-7edf0b46f103',

        name: 'AP Computer Science A',

    },

    {

        id: '89e86abd-ab00-4138-b428-8f7e0c87e5e8',

        name: 'AP Computer Science Principles',

    },

    { id: '0fc89969-55dc-42d3-81d1-be895a0243a0', name: 'AP English' },

    {

        id: 'b5dd6a19-82e2-44f3-9285-e2515c6e6062',

        name: 'AP English Language and Composition',

    },

    {

        id: '6baa6f7f-2142-4b04-8592-8d63df1fec77',

        name: 'AP English Literature and Composition',

    },

    {

        id: '44616ca6-c79d-45a1-a0a4-7f128c17b0fc',

        name: 'AP Environmental Science',

    },

    { id: 'c1f92b44-a9dc-43be-b016-f90e292907cb', name: 'AP European History' },

    {

        id: 'c7eea89d-c29d-4e6a-af4d-18a1caf272a1',

        name: 'AP French Language & Culture',

    },

    {

        id: 'ce5874eb-a213-4670-b931-e0da8a6ccb43',

        name: 'AP German Language & Culture',

    },

    { id: '3b7fea9d-682a-4f72-9a31-38f179f16cf3', name: 'AP Human Geography' },

    {

        id: 'c1e69986-505f-4a8a-aed4-5cb366a3dc19',

        name: 'AP Italian Language & Culture',

    },

    {

        id: '4d04162e-8a3b-4848-a1ff-42a0ab9e682f',

        name: 'AP Japanese Language and Culture',

    },

    { id: 'c4705c3a-0085-448c-9f46-f2fc2d98199d', name: 'AP Latin' },

    { id: 'b7410ff2-acaf-498d-948f-86f6067f689d', name: 'AP Macroeconomics' },

    { id: '53391627-71f5-492a-b13d-b7eb1376f237', name: 'AP Microeconomics' },

    { id: '2808c7f8-ec08-4d4f-9dcd-c9fd9a5c1f02', name: 'AP Music Theory' },

    {

        id: 'af7691d0-8fb5-47f5-a575-f38382266ec9',

        name: 'AP Physics 1: Algebra-Based',

    },

    {

        id: '893ee031-9283-462b-a58a-15d3912c878f',

        name: 'AP Physics 2: Algebra-Based',

    },

    {

        id: '99acaf36-38e1-4506-bf50-26361dc10d00',

        name: 'AP Physics C: Electricity & Magnetism',

    },

    {

        id: '2bf353ee-73ba-4206-b951-cd901f534945',

        name: 'AP Physics C: Mechanics',

    },

    { id: 'ccc4d225-5402-484e-876c-afc1ff7503ea', name: 'AP Psychology' },

    { id: '31bbd482-29ca-43e8-8c9c-37d117439745', name: 'AP Research' },

    { id: '8299554a-fa5b-4633-a061-6e93e6a5d69d', name: 'AP Seminar' },

    { id: '1fb1dc75-d543-4b4a-a30a-814f94d54e37', name: 'AP Spanish' },

    {

        id: '27b4d427-eb0b-41e0-a15c-0e129913d94c',

        name: 'AP Spanish Language & Culture',

    },

    {

        id: 'b9455968-b1e9-4faf-abde-e6f14992e37e',

        name: 'AP Spanish Literature & Culture',

    },

    { id: 'cd7ea51f-c065-4b93-9034-ea2f999c81b3', name: 'AP Statistics' },

    {

        id: '23ec693a-a4fc-4e7d-bba1-5b52c7628179',

        name: 'AP Studio Art: 2-D Design',

    },

    {

        id: '9161354c-2328-4e69-8259-774c52699d0d',

        name: 'AP Studio Art: 3-D Design',

    },

    {

        id: '6a1f3949-ccaa-40b6-bde3-bc6b30d8f0be',

        name: 'AP Studio Art: Drawing',

    },

    {

        id: 'cf2c36a1-e168-4989-af92-8cc82c80c3ae',

        name: 'AP U.S. Government & Politics',

    },

    { id: 'e6ac676d-286a-42fd-a107-030c28f19fdc', name: 'AP U.S. History' },

    { id: '566c86b1-ea2c-4da2-8fcf-e48a5c24559a', name: 'AP World History' },

    { id: 'c1b71d71-6255-4e0e-a73f-ac9b96c63c7c', name: 'ASVAB' },

    { id: 'aeb3af08-9a23-45bb-8587-34cb8f6c97e8', name: 'CTP' },

    { id: '3b9ea963-5fb8-43a9-85ba-d01d372d0674', name: 'ECAA' },

    { id: 'cf2d450b-b4ac-4575-8fbc-c8621a52b003', name: 'ELAT' },

    { id: 'c22400f6-f2d7-420b-8fc1-c7930df53d84', name: 'ERB' },

    { id: '39720a1e-b154-45b6-aaad-c7ef252dfa20', name: 'GMAT' },

    { id: '8c62728f-dd4c-494b-8451-98ee7ce21dd5', name: 'GRE' },

    { id: '39911b45-abc9-438c-9653-3c59c133582a', name: 'GRE (Math)' },

    { id: 'c7327cf4-c85e-4451-9f80-b30bc1632224', name: 'GRE (Verbal)' },

{ id: '68061d5a-dec4-4fa8-a5fa-99c2c7d2db8c', name: 'High School Regents: English Language Arts' },
{ id: 'b9e06a42-d048-498f-a5d3-2ea619b5c84b', name: 'High School Regents: Algebra 1' },
{ id: '8831a172-cdfc-4f56-aba7-341395f2c096', name: 'High School Regents: Algebra 2' },
{ id: '4b90d160-5949-4439-974a-4cb1bdbaf0a8', name: 'High School Regents: Geometry' },
{ id: '2580baf5-e7bd-47a5-a665-cfd603fdcb21', name: 'High School Regents: US History and Government' },
{ id: 'f82d91f5-362d-4cc2-9213-c02fa6a12722', name: 'High School Regents: Global History and Geography' },
{ id: '4dfcbea4-d98f-41bf-b770-5b6a2dbf26d7', name: 'High School Regents: Chemistry' },
{ id: '5bdd01b6-b554-4c56-9b00-ed045335b5cf', name: 'High School Regents: Earth Science' },
{ id: 'eb7a465b-b856-4835-840e-6723ef177013', name: 'High School Regents: Living Environment' },
{ id: '21457813-4659-4963-9ed5-96339b9e6e9f', name: 'High School Regents: Physics' },
{ id: '9e789308-a74c-4d02-81a3-8ec2c9e7cc90', name: 'High School Regents: French' },
{ id: '68972625-4ec2-4416-ac40-4757e7a958d9', name: 'High School Regents: German' },
{ id: '74442c76-b37d-4d4d-a3eb-c7eeac733dcf', name: 'High School Regents: Hebrew' },
{ id: '470fc428-42ca-4ef2-befe-08af5c61be3b', name: 'High School Regents: Italian' },
{ id: '62984b8b-47cc-40fd-ae79-df8b8f8984ae', name: 'High School Regents: Latin' },
{ id: '38088b0a-f58f-4328-9eb2-ad8b26d27b49', name: 'High School Regents: Spanish' },

    {

        id: '55723128-9092-472e-8b6e-c885dcc8497b',

        name: 'History Aptitude Test',

    },

    { id: 'a1492892-020d-4436-8e70-a548f4b53105', name: 'Hunter Test' },

    { id: '2e0f60c0-34df-419b-9208-c8cd7c7c1c91', name: 'IB Exams' },

    { id: 'bfed0226-fe3c-486b-841b-b8c3a4c0181e', name: 'IB Extended Essay' },

    { id: '6780ffe3-31e3-466c-9b88-28252837fe89', name: 'IB French' },

    { id: '74273d89-2aae-45b8-892d-2f836c25ae77', name: 'IB History' },

    {

        id: '75f6daa2-54d0-41a7-8c09-a550d05a4bf7',

        name: 'IB Theory of Knowledge',

    },

{ id: '841c2336-ccf2-43c3-a555-361cb76a7542', name: 'ISEE: Essay' },

    { id: 'e9e84671-6093-4221-8423-c3dce48e710a', name: 'ISEE (Lower Level)' },

    { id: '8a425fc2-225b-4c3a-b1cb-dedf30cd7857', name: 'ISEE (Math)' },

    { id: 'bc1d588c-288a-49b7-ac6e-00f1135a4876', name: 'ISEE (Middle Level)' },

    { id: 'bd7abfd1-a3b5-4e02-a672-a3b16c94390b', name: 'ISEE (Upper Level)' },

    { id: '7f766cda-8756-49ac-8609-c84023607fdc', name: 'ISEE (Verbal)' },
	


    { id: '28453544-5d08-4522-9769-430367674499', name: 'KRT' },

    { id: '7365e55f-6637-4798-bd90-9edf97e40a0d', name: 'LNAT' },

    { id: '7329fe3a-0b52-4581-9233-d9f31d9474de', name: 'LSAT' },
    { id: '89c889ba-5cd3-11ec-bf63-0242ac130002', name: 'LSAT: Logical Reasoning' },
    { id: '89c88c62-5cd3-11ec-bf63-0242ac130002', name: 'LSAT: Analytical Reasoning' },
    { id: '89c88d70-5cd3-11ec-bf63-0242ac130002', name: 'LSAT: Reading Comprehension' },
    { id: '89c88e56-5cd3-11ec-bf63-0242ac130002', name: 'LSAT: Writing' },

    { id: '32004191-3069-4722-a239-9244234eaaf0', name: 'MCAT' },
    { id: '89c88f32-5cd3-11ec-bf63-0242ac130002', name: 'MCAT: Biological and Biochemical Foundations of Living Systems' },
    { id: '89c89018-5cd3-11ec-bf63-0242ac130002', name: 'MCAT: Chemical and Physical Foundations of Biological Systems' },
    { id: '89c890f4-5cd3-11ec-bf63-0242ac130002', name: 'MCAT: Psychological, Social, and Biological Foundations of Behavior' },
    { id: '89c89216-5cd3-11ec-bf63-0242ac130002', name: 'MCAT: Critical Analysis and Reasoning Skills' },

    { id: 'd074d339-9bb1-4dfb-8eea-186619b62d86', name: 'National Latin Exam' },

    { id: '1001af2f-4893-4353-b4d1-37fa31bad5c9', name: 'NNAT' },

    {

        id: '15c5b42f-8a18-40cf-9ff3-72b93859f137',

        name: 'NY State Regents ELA',

    },

    {

        id: '1b34b893-45d6-4f84-ad35-4bcc190eae27',

        name: 'NY State Regents ELA and Math',

    },

    {

        id: '05f28dbe-5907-4a21-9965-6c3e1f432c7e',

        name: 'NY State Regents ELA and Math (3rd - 4th grade)',

    },

    {

        id: '70b9c8be-4aec-4b2e-b77b-1367e9ab2ddb',

        name: 'NY State Regents ELA and Math (3rd - 5th grades)',

    },

    { id: '1ce77051-88e0-4285-ad7a-aee0a08e9cb0', name: 'OLSAT' },

    { id: '89af00e9-1d06-4db6-90a6-48d2b31289b9', name: 'PSAT' },
	
{ id: 'd875995c-f3d5-43e7-a315-08491974eeee', name: 'PSAT: Verbal' },
{ id: 'e8fdc9a3-1072-4e80-958e-40ea945d7266', name: 'PSAT: Math' },
{ id: 'e5dda387-3039-4efa-8364-82ecda018772', name: 'PSAT: Writing' },


    { id: '6d389db8-e560-4d00-b909-5671c9b32675', name: 'SAT' },

    { id: '09454b0a-db6b-4776-bb46-c993232757dd', name: 'SAT (Math)' },

    { id: 'dd813dee-3553-44c0-b8fb-42423a03cd7d', name: 'SAT (Verbal)' },

    { id: 'de462f14-dcf9-43eb-8d8f-a12b63ccb740', name: 'SAT Biology' },

    { id: '588bf7f4-66c0-4acf-9721-3494daaea667', name: 'SAT Chemistry' },

    { id: 'a3a63212-6726-4e5e-b0f0-70dc9f3d59a2', name: 'SAT French' },

    { id: '0fdccceb-1511-477a-81c4-69dbbb7480df', name: 'SAT Italian' },

    { id: '22abec22-c85c-491c-8433-f8c013f27e4d', name: 'SAT Latin' },

    { id: '70e159f4-b67c-402e-999e-4b1dded2aa33', name: 'SAT Literature' },

    { id: '8e35fcc7-76be-4b37-8aaf-1064ec66f8ba', name: 'SAT Math 1 & 2' },

    { id: '9e17b265-d714-4bc2-a8ec-49d46aa10976', name: 'SAT Physics' },

    { id: 'dee20196-f043-4cce-a6b2-7ded32d17d6b', name: 'SAT Spanish' },

    { id: '4df21200-864d-453d-b97e-574799de9083', name: 'SAT US History' },

    { id: '7af67a45-5d48-4ce3-a3a0-6a45bdabbb67', name: 'SAT World History' },
	
		
    { id: '5f78f5a2-3f8e-4a40-b311-1a892cec6268', name: 'SAT II: Biology E/M' },

    { id: 'daad5a6f-13f0-422b-abc0-7aeb3e0afcc5', name: 'SAT II: Chemistry' },

    {

        id: 'ce8e0ae6-6f91-4123-ba81-a972c40cb448',

        name: 'SAT II: Chinese with Listening',

    },

    { id: 'd69dcbc1-5e42-40ee-8bf9-37f2876712b7', name: 'SAT II: English' },

    { id: 'ed031a54-8ee4-40cc-9a1f-40094cdcbbab', name: 'SAT II: French' },

    {

        id: 'dedb9ded-8a08-4413-9167-412f6fb9bfe0',

        name: 'SAT II: French with Listening',

    },

    { id: 'dc565020-0bc9-4a77-b9be-2cc76f22087e', name: 'SAT II: German' },

    {

        id: '7eca9256-31e4-4f3f-be85-b3409bf65e92',

        name: 'SAT II: German with Listening',

    },

    { id: '7a13ff7b-205e-4dea-8e0b-11dfbaa385d7', name: 'SAT II: Italian' },

    {

        id: 'a7f932e9-001c-46dd-8bb5-d36338c27227',

        name: 'SAT II: Japanese with Listening',

    },

    {

        id: '6284d14e-fb7f-4071-bfd6-ff1650c76219',

        name: 'SAT II: Korean with Listening',

    },

    { id: '380d2de8-a7cd-471b-b2db-69c591f26c99', name: 'SAT II: Latin' },

    {

        id: '909e1bdc-a021-4717-bd1b-90f81b36b3fe',

        name: 'SAT II: Math Level 1',

    },

    {

        id: '15d49b25-10c5-47b1-acc0-fff94028e470',

        name: 'SAT II: Math Level 2',

    },

    {

        id: '1b5f2d5e-e70a-4f46-b4a8-9702a6fec954',

        name: 'SAT II: Modern Hebrew',

    },

    { id: '316aec59-6f80-4beb-8a80-834085628a69', name: 'SAT II: Physics' },

    { id: '23731c77-6faf-4863-92f9-c5c1086bca66', name: 'SAT II: Spanish' },

    {

        id: '1ec9305b-9038-4694-a285-c6d58aee7f47',

        name: 'SAT II: Spanish with Listening',

    },

    {

        id: '47665036-7061-43ba-88e2-62e6bfbc928d',

        name: 'SAT II: U.S. History',

    },

    {

        id: '39d6fb3a-e2d1-49b7-b861-a8c6cb402e0c',

        name: 'SAT II: World History',

    },
    
	{ id: '8516fdca-1648-49e4-80b3-ed07cfd0e5ab', name: 'SB' },

    { id: '64fc67c4-5175-45a2-862f-9335cf6055f5', name: 'SCAT' },

    { id: '60c5f36c-38ae-4e85-9273-3b4f6102bba0', name: 'SHSAT' },


    { id: 'b14c222a-ee2e-499e-b0a1-f99d6ec53cff', name: 'SSAT' },

    { id: '9a266ad8-3c36-4094-9369-26244c94f9d1', name: 'SSAT (Math)' },

    { id: '48e0d5d1-b875-4511-beec-f213395f57a6', name: 'SSAT (Verbal)' },
	
{ id: 'eb06d12c-527f-4607-ade0-fd9173cec627', name: 'SSAT (Essay)' },

    { id: '33a2f4f5-7116-4786-bdf0-a8f4ba7d62b8', name: 'Stanford Binet' },

    { id: 'f675816f-7608-4c13-b74f-d3a34e60819b', name: 'TACHS' },

    {

        id: 'f819b2fb-73f4-42bd-b334-8e0d6c512d76',

        name: 'Test Prep: ACT (All Sections)',

    },
    {

        id: '9b2a8486-2c2b-4522-9c82-16e856ee4026',

        name: 'Test Prep: ACT Science',

    },

    { id: '5732e39a-f70c-4061-9d2a-6e1aebb16a64', name: 'Test Prep: AFOQT' },

    { id: '11e41725-3d48-4b48-92bb-4221158a810c', name: 'Test Prep: ASVAB' },

    { id: 'fa2f59f1-7d62-4ee2-a4c3-2aab778b53ca', name: 'Test Prep: Bar Exam' },

    { id: 'a7894602-7403-4b6a-bc49-5fea15f0ba04', name: 'Test Prep: CBEST' },

    { id: '1db1c402-2831-4fd8-9688-07df182d2918', name: 'Test Prep: CFA' },

    { id: 'b144cb02-afb7-4b06-a165-d1ffa1d23f4f', name: 'Test Prep: CLAST' },

    {

        id: 'b3adcb84-eba8-4219-8086-1a2ad20975c8',

        name: 'Test Prep: COOP/HSPT',

    },

    {

        id: 'e588dc28-4873-4d5d-9345-ee0685c70171',

        name: 'Test Prep: Common Core',

    },

    { id: '67bff7cc-275d-45ab-9c4c-90233eba5c12', name: 'Test Prep: GED' },

    { id: '35f25ed1-5a07-4412-801e-cfd8783dae95', name: 'Test Prep: GMAT' },

    { id: 'bb41295d-1ce3-4fb1-9495-125c4bdc3da3', name: 'Test Prep: GRE' },

    { id: 'beada02e-9b7c-476c-bdba-11274abb47ed', name: 'Test Prep: IELTS' },

    { id: 'd922ea8e-b08c-4aac-97e0-829ea52af860', name: 'Test Prep: ISEE' },

    { id: '1f35f1ef-a39f-44fc-95a3-69ad46a663f1', name: 'Test Prep: LSAT' },

    { id: 'e23ff780-d02b-4f2c-93da-ac00646119cd', name: 'Test Prep: MCAT' },

    { id: '7e2bd503-5d4d-4c97-8100-d763db95cb2e', name: 'Test Prep: MCSA' },

    { id: 'b62767ad-494d-4d51-b00b-d1fcd057780c', name: 'Test Prep: MTLE' },

    { id: '63fcfbbc-7a19-420f-b253-11f486bf70e4', name: 'Test Prep: NCLEX' },

    {

        id: 'c8a55685-a110-4500-8e30-22f50b9789a5',

        name: 'Test Prep: Nursing Exams',

    },


    { id: '70513748-beee-434a-b6b1-2d1054a18d60', name: 'Test Prep: Praxis' },

    { id: '8f93454e-52ce-4b42-9441-4209cd3c0ff7', name: 'Test Prep: Regents' },

    {

        id: 'ca9b686a-f745-4aaa-ab4d-1da9d050eef3',

        name: 'Test Prep: SAT (All Sections)',

    },

    { id: '5d7181f5-3aee-4351-95f8-e062fc494dbf', name: 'Test Prep: SAT Math' },

    {

        id: 'c64384a0-f8e2-4d89-89f2-7b279b763cac',

        name: 'Test Prep: SAT Reading',

    },

    {

        id: 'd4969ea3-592c-4ab6-85b5-ab16bb8d66de',

        name: 'Test Prep: SAT Writing',

    },

    { id: 'cb748213-e7a8-40eb-90e9-52028910dbc9', name: 'Test Prep: SOL' },

    { id: 'c5efd741-ee46-43a1-a1de-3fa4aeb44a29', name: 'Test Prep: SSAT' },

    { id: 'ad53a112-3ed8-4c0c-90a4-2a1c4ce80b00', name: 'Test Prep: STAAR' },

    {

        id: 'ec6c91a4-0dec-46c8-99f8-5e3b2ebdc735',

        name: 'Test Prep: Series 63',

    },

    { id: '02c97c4c-0a67-4190-8d37-92854bf2081c', name: 'Test Prep: Series 7' },

    { id: '07784f19-878f-4dcb-876e-4a58ccd3e469', name: 'Test Prep: TAKS' },

    { id: '6ee7571e-d95a-4761-a994-493f1bafb064', name: 'Test Prep: TEAS' },

    { id: '7d1c8bc4-c2c7-4e1f-bd7c-d7efd2c7c665', name: 'Test Prep: TOEFL' },

    { id: '1f0cd397-d698-454b-a9fd-b2bffb2f3abd', name: 'Test Prep: USCIS' },

    { id: 'd39dc5a0-36de-4d4b-8f88-852ceef0436d', name: 'Test Prep: USMLE' },

    { id: '4d3b342b-e88c-49a1-aaa3-a49fdb735f6a', name: 'TSA' },

    { id: '5ca276de-0359-4028-835f-c1b11bea8003', name: 'WISC' },

    { id: 'f58497e5-31f1-459a-b952-77dd888d58c0', name: 'WPPSI' },

];
export default standardizedTestList
