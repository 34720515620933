/* eslint-disable @typescript-eslint/no-empty-function */
import React from "react";
import Modal from "components/modal_/Modal";
import '../StdTutorAcceptRequest.scss';
import Button from "components/button/Button";
import reviewIcon from "assets/images/reviewSubmited.svg"

interface RescheduleRequestSent {
    onGoto: () => void,
    isShown: boolean,
    onClose: () => void,
    rescheduleDate: string,
    rescheduleTime: string
}

export function RescheduleRequestSent(props: RescheduleRequestSent) {

    return <div className="tutor-student-reschedule-modal">
        <Modal

            visible={props.isShown}
            onExit={props.onClose}

        >
            <div className="modal-reschedule-request-sent">

                <div className="imgs_div">
                    <img src={reviewIcon} alt="imager_url" className="img_icons img_icon1" />
                </div>
                <h2>Request Successfully Sent</h2>
                <div className="grey_div">
                    <h4>{props.rescheduleDate} at {props.rescheduleTime}</h4>
                </div>
                <div className="tutor-student-reschedule-btn py-2">
                    <Button className="" title="Go to Dashboard" clickAction={props.onGoto} isOutlineStyle={true} />
                </div>
            </div>

        </Modal>
    </div>
}
