import React from "react"
import { applicationConstant } from "../../../utils/constant/applicationConstant"
import InterfaceConstant from "../../../utils/constant/interfaceConstant"

const appLabel: InterfaceConstant.IappLabels = applicationConstant.appLabels

const OnBoardingUpdateSettingNote = (): JSX.Element => {
    return (
        <div className="on-boarding-update-setting-note">{appLabel.GLOBAL_UPDATE_SETTING_NOTE}</div>
    )
}

export default OnBoardingUpdateSettingNote
