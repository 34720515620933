import React from "react"
import { useTranslation } from 'react-i18next';
import MultiSelectDropDown from "../../../../../components/dropdown/MultiSelectDropdown"



const SubjectsFilter = ({ subjectsList, setSubjectsList, subjects }: { subjectsList: any, setSubjectsList: any, subjects: any }): JSX.Element => {
    const { t } = useTranslation();
    return (
        <div className="tutor-search-filter">
            <MultiSelectDropDown
                title={t("search-by")}
                title2={t("subject")}
                placeholder={t("tutoring-advising-subject-placeholder")}
                optionsList={subjectsList}
                error=""
                subjectvalue={setSubjectsList}
                selectedOption={subjects}
            />
        </div>
    )
}

export default SubjectsFilter

