import { StepHandlerType, StepsAndHandlersMapType } from "../../../../../../utils/hooks/useStepwiseExecution";
import firebase from "../../../../../../utils/config/firebase";
import {
    createStudentThroughBackendonBoarding,
    verifyEmailAddressThroughBackend,
    getStripeCustomerThroughBackend
} from "../../../../../../utils/services/userServices";
import { SharedState } from "./AllStepsAndHandlers";
const firebaseAuth = firebase.auth();


export const createNewUser: StepHandlerType = async (data: any, sharedState: SharedState, setSharedState) => {
    const { email, password }: { email: string, password: string } = sharedState.formData;
    try {
        const userAuth = await firebaseAuth.createUserWithEmailAndPassword(email, password);
        return {
            type: "success",
            data: userAuth.user
        };
    } catch (e) {
        if (e.code === "auth/email-already-in-use") {
            return {
                type: "error",
                code: "auth/email-already-in-use",
                message: "An account already exists with this email address"
            }
        }
        throw {
            type: "error",
            message: e.message,
            code: e.code
        };
    }
};
export const createNewUserMetaData: StepHandlerType = async (data: any, sharedState: SharedState, setSharedState) => {
    if (data.type !== "success") {
        return data;
    }
    try {
        const { uid } = data.data;
        const { email, firstName, lastName } = sharedState.formData;
        const isDataCreated = await createStudentThroughBackendonBoarding(uid, { email, firstName, lastName });
        if (isDataCreated === true) {
            return {
                type: "success",
                data: data.data
            }
        }
        throw {
            type: "error",
            message: "Unable to create user meta data. Please try again"
        }
    } catch (e) {
        throw {
            type: "error",
            message: e.message
        }
    }
};
export const signInWithCredentials: StepHandlerType = async (data: any, sharedState: SharedState, setSharedState) => {
    const { email, password }: { email: string, password: string } = sharedState.formData;
    try {
        const userAuth = await firebaseAuth.signInWithEmailAndPassword(email, password);
        setSharedState((ps: any) => {
            return {
                ...ps,
                authUser: userAuth.user
            };
        });
        return {
            type: "success",
            data: userAuth.user
        };
    } catch (e) {
        throw {
            type: "error",
            code: e.code,
            message: e.message
        }
    }
};

export const verifyEmailAddress: StepHandlerType = async (data: any, sharedState: SharedState, setSharedState) => {
    try {
        const { emailVerified } = sharedState.authUser as firebase.User;
        let isVerified = emailVerified;
        if (emailVerified !== true) {
            //send call to verify the email address
            isVerified = await verifyEmailAddressThroughBackend();
        }
        console.log("Is Email Veried: ", isVerified);
        return {
            type: "success",
            data: sharedState.authUser
        }
    } catch (e) {
        throw {
            type: "error",
            code: e.code,
            message: e.message
        }
    }
};


export const getStripeCustomer: StepHandlerType = async (data: any, sharedState: SharedState, setSharedState) => {
    try {
        const response = await getStripeCustomerThroughBackend();
        const customer = response.data;
        setSharedState((ps: any) => {
            return {
                ...ps,
                customer
            };
        });
        return {
            type: "success",
            data: customer
        }
    } catch (e) {
        throw {
            type: "error",
            code: e.code,
            message: e.message
        }
    }
};

/**
 * all these functions depend on each, so you can just
 * random change their order unless you are sure
 * what you are doing
 */
export const stepsAndHandlers = [
    [
        createNewUser,
        createNewUserMetaData
    ], // first step, create new user and its metadata
    [
        signInWithCredentials
    ], //second step, sign in
    [
        verifyEmailAddress
    ], // third step, verify the email address
    [
        getStripeCustomer
    ], //fourth step, get or create stripe customer
] as StepsAndHandlersMapType;

/**
 * this object contains messages of the above mentioned
 * steps
 */
export const stepsAndMessages = [
    "Creating new user and its meta data ...",
    "Signing in ...",
    "Verifying email address ...",
    "Retrieving customer info ..."
];