import firebase from "firebase"
import { getDoc, getUserMetaInfo } from "../helper/firebase"
import { getCurrentPeriodExpiryStatus } from "./subscriptionServices"
import { teachersActionTypes, usersActionTypes } from "./callableActions"
import { applicationConstant } from "utils/constant/applicationConstant";
import { MAX_RAPID_BOOKING_TIME } from "utils/constant/BookingConstant";
const { firestore, auth } = firebase

export const getRapidBookingAvailabilityService = async (userId: string): Promise<boolean> => {
    const teacherDoc = await firestore().collection("teachers").doc(userId).get();
    return teacherDoc.get("rapidBooking");
};
export const getStripeCustomerThroughBackend = async () => {
    const usersCallables = firebase.functions().httpsCallable("users")
    const response = await usersCallables({ actionType: "GET_STRIPE_CUSTOMER" }).then(({ data }) => data);
    return response;
}

export const verifyEmailAddressThroughBackend = async () => {
    const usersCallables = firebase.functions().httpsCallable("users")
    const response = await usersCallables({ actionType: "VERIFY_EMAIL_ADDRESS" }).then(({ data }) => data).catch(console.error);
    return response.status === "success";
}
export const updateRapidBookingAvailabilityService = async (userId: string, isAvailable: boolean) => {
    const rapidBooking: {
        "rapidBooking.modifiedAt": string;
        "rapidBooking.minutes": number;
        "rapidBooking.status": boolean;
        "rapidBooking.turnedOff"?: "automatically" | "manually";
    } = {
        "rapidBooking.modifiedAt": (new Date()).toISOString(),//ISO string e.g 2021-04-10T07:17:17.217Z it is independent of timezone,
        "rapidBooking.minutes": MAX_RAPID_BOOKING_TIME,
        "rapidBooking.status": isAvailable,
        "rapidBooking.turnedOff":"manually"
    };
    await firestore().collection("teachers").doc(userId).update({
        ...rapidBooking
    });
    return isAvailable;
};
export const updateWalkThroughService = async (userId: string, isWatched: boolean) => {
    return await firestore().collection("users").doc(userId).set({
        webWalkThrough: isWatched,
    }, { merge: true });

};
export const updateWalkThroughMobileService = async (userId: string, isWatched: boolean) => {
    return await firestore().collection("users").doc(userId).set({
        webWalkThroughMobile: isWatched,
    }, { merge: true });

};
export const updateDefaultTimeZone = async (userId: string, timeZone: string) => {
    return await firestore().collection("users").doc(userId).set({
        defaultTimeZone: timeZone,
    }, { merge: true });

};

export const getUserWithDetails = async (userId: any): Promise<any> => {
    const userDoc = firestore().collection("users").doc(userId);
    const user: any = await getDoc(userDoc);
    const userMetaDoc = firestore().collection("user-meta").doc(userId)
    const userMetaData = await getDoc(userMetaDoc);
    const lessonSubscriptionExpiryStatus = await getCurrentPeriodExpiryStatus();
    if (user.userType === "STUDENT") {
        const studentDetails: any = await getDoc(firestore().collection("students").doc(userId));
        return {
            ...studentDetails,
            ...userMetaData,
            ...user,
            lessonSubscriptionExpiryStatus
        }
    } else {
        const teacherDetails: any = await getDoc(firestore().collection("teachers").doc(userId))
        return {
            ...teacherDetails,
            ...userMetaData,
            ...user
        }
    }
}

export const getUserProfilePicture = async (userId: string) => {
    const profeileImageUrl = (await firestore().collection("users").doc(userId).get()).data()
        ?.profilePic.downloadURL
    return profeileImageUrl
}
export const updateAffiliate = async (affilate:any,userId:string) => {
    return await firebase.firestore().collection("user-meta").doc(userId).update(affilate).catch(e => console.error("error while updating user-meta for Affilate: ", e))
}
export const getUserFullName = async (userId: string) => {
    const userdata: any = (await firestore().collection("users").doc(userId).get()).data();
    const name = userdata.firstName + " " + userdata.lastName;
    return name;
}
export const onAuthStateChanged = () => {
    auth().onAuthStateChanged((user: any) => {
        const userdata: any = JSON.parse(JSON.stringify(user))
        return userdata
    })
}
export const getTeacherBookingData = async (teacherId: any) => {
    const bookingRequestCallable = firebase.functions().httpsCallable("teachers")
    const sdata = bookingRequestCallable({
        actionType: teachersActionTypes.GET_TEACHER_BY_ID,
        teacherId,
    })
        .then((data) => data)
        .catch((err) => {
        })
    return sdata;
}
export const getCustomToken = async (userId: any) => {
    const bookingRequestCallable = firebase.functions().httpsCallable("users")
    const sdata = bookingRequestCallable({
        actionType: usersActionTypes.GET_USER_CUSTOM_TOKEN,
        userId,
    })
        .then((result) => result)
        .catch((err) => {
        })
    return sdata;
}

interface userStudentDataType {
    email: string,
    firstName: string,
    lastName: string,
    options: {
        userType: string,
        expectedGraduationYear: any,
        studentCurrentGrade: any,
        userLookingFor: any,
        studentTutoringSubjects: any,
        studentAdvisingTopics: any,
    },

}

/**
 * this is a fallback function which handles the creation of the new student
 * when the actual front end stolution gives up
 * @param userId
 * @param userData
 */
export const createStudentThroughBackend = async (userId: string, userData: userStudentDataType) => {
    const { email, firstName, lastName } = userData;
    const { userType } = userData.options;

    const userDocData = {
        email: email,
        firstName: firstName,
        lastName: lastName,
        signUpBy: "emailPassword",
        userType: userType,
        isRegistered: true,
        userId,
        devices: [],
        profilePic: {},
        acceptTermsAndConditionVersion: 0.1,
        acceptedTermsAndConditionTimeStamp: new Date().toISOString()
    };

    const {
        expectedGraduationYear,
        studentCurrentGrade,
        userLookingFor,
        studentTutoringSubjects,
        studentAdvisingTopics,
    } = userData.options;

    const studentDocData = {
        expectedGraduationYear: expectedGraduationYear.id,
        studentCurrentGrade,
        studentLookingFor: userLookingFor,
        studentAdvisingTopics,
        studentTutoringSubjects,
        userId
    };

    const usersCallables = firebase.functions().httpsCallable("users")
    const createdData = await usersCallables({
        actionType: usersActionTypes.CREATE_STUDENT_THROUGH_BACKEND,
        userId,
        userDocData,
        studentDocData
    }).then(({ data }) => data).then(data => data.status === "success");
    return createdData;
};

interface userTeacherDataType {
    tutorInformation: {
        options: {
            email: string,
            firstName: string,
            lastName: string,
            phoneNumber: string,
            timeZone: any,
            country: string,
        }
    },
    tutorEducation: {
        options: {
            teacherEducationQualification: any,
            gradeOptions: any,
            certification: any,
            issuing: any,
            issuingDate: any,
            expirationDate: any,
            credentialId: any,
            credentialUrl: any,
            biography: any,
            resumeFile: any,
        }
    },
    tutorRate: {
        individualRate: any
    },
    tutorSignUp: {
        teacherType: any,
        value: any
    },
    tutorAvailability: {
        teacherWeeklyAvailability: any
    },
}

/**
 * this is a fallback function which handles the creation of the new teacher
 * when the actual front end stolution gives up
 * @param userId
 * @param userData
 */
export const createTeacherThroughBackend = async (userId: string, userData: userTeacherDataType, profilePic = {}) => {
    const { email, firstName, lastName, phoneNumber, timeZone, country } = userData.tutorInformation.options
    const userTypeKeys = applicationConstant.userTypeKeys;
    const userType = userTypeKeys.TEACHER;

    const userDocData = {
        email: email,
        firstName: firstName,
        lastName: lastName,
        phoneNumber: phoneNumber,
        defaultTimeZone: timeZone,
        defaultTimezoneCountry: country,
        devices: [],
        signUpBy: "emailPassword",
        userType: userType,
        isRegistered: true,
        userId: userId,
        profilePic: profilePic,
        acceptTermsAndConditionVersion: 0.1,
        acceptedTermsAndConditionTimeStamp: new Date().toISOString(),
    };

    const {
        teacherEducationQualification,
        gradeOptions,
        certification,
        issuing,
        issuingDate,
        expirationDate,
        credentialId,
        credentialUrl,
        biography,
        resumeFile,
    } = userData.tutorEducation.options

    const { subject, test, specialization } = userData.tutorSignUp.value

    const newOptions: Array<string> = []

    interface ItemProps {
        id: string
        name: string
    }

    subject.length > 0 && subject.map((item: ItemProps) => newOptions.push(item.id))
    test.length > 0 && test.map((item: ItemProps) => newOptions.push(item.id))
    specialization.length > 0 && specialization.map((item: ItemProps) => newOptions.push(item.id))
    const teacherDocData = {
        firstName: (firstName || "").toLowerCase(),
        lastName: (lastName || "").toLowerCase(),
        oneToOneSessionRate: userData.tutorRate.individualRate,
        subjects: newOptions,
        teacherBiography: biography,
        teacherCertificateDetails: {
            certificationName: certification,
            credentialId,
            credentialUrl,
            expirationDate,
            issueDate: issuingDate,
            issuingOrganization: issuing,
        },
        teacherEducationQualification,
        teacherResume: resumeFile,
        teacherSchoolDetails: gradeOptions,
        teacherType: userData.tutorSignUp.teacherType,
        userId,
        teacherWeeklyAvailability: userData.tutorAvailability.teacherWeeklyAvailability,
    };

    const usersCallables = firebase.functions().httpsCallable("users")
    const createdData = await usersCallables({
        actionType: usersActionTypes.CREATE_TEACHER_THROUGH_BACKEND,
        userId,
        userDocData,
        teacherDocData
    }).then(({ data }) => data).then(data => data.status === "success");
    return createdData;
}

export const sendEmailToAdmin = async (subject: string, html: string): Promise<any> => {
    const sendEmail = firebase.functions().httpsCallable("emailTemplates")
    const result: any = await sendEmail({
        actionType: usersActionTypes.SEND_EMAIL_TO_ADMIN,
        subject,
        html
    })
    return result

}
export const createStudentThroughBackendonBoarding = async (userId: string, userData: { email: string, firstName: string, lastName: string }) => {
    const { email, firstName, lastName } = userData;
    const userType = "STUDENT";

    const userDocData = {
        email: email,
        firstName: firstName,
        lastName: lastName,
        signUpBy: "emailPassword",
        userType: userType,
        isRegistered: true,
        registeredThroughWebsite: true,
        userId,
        devices: [],
        profilePic: {},
        acceptTermsAndConditionVersion: 0.1,
        acceptedTermsAndConditionTimeStamp: new Date().toISOString()
    };

    const studentDocData = {
        expectedGraduationYear: "",
        studentAdvisingTopics: "",
        studentCurrentGrade: "",
        studentLookingFor: "TUTORING",
        studentTutoringSubjects: "",
        userId
    };

    const usersCallables = firebase.functions().httpsCallable("users")
    const createdData = await usersCallables({
        actionType: "CREATE_STUDENT_THROUGH_BACKEND",
        userId,
        userDocData,
        studentDocData
    }).then(({ data }) => data).then(data => data.status === "success");
    return createdData;
};

export const getUpdatedUserMeta = async (uid: string) => {
    const userMeta = await getUserMetaInfo(uid);
    return userMeta;
}
