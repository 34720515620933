import React, { PureComponent } from "react"
import { Link } from "react-router-dom"
import { CardView } from "./CardView"
interface Iprops {
    title: string
    naviagtionDetails: string
    description?: string | undefined
    naviagtionLink: string
    naviagtionText: string
    renderForm: any
}

class OnBoardingCard extends PureComponent<Iprops> {
    renderTitle = () => {
        const { title } = this.props
        return <div className="title">{title}</div>
    }

    renderDescription = () => {
        const { description, naviagtionDetails, naviagtionLink, naviagtionText } = this.props
        return (
            <React.Fragment>
                {description ? (
                    <div className="description navigation-description">{description}</div>
                ) : null}

                <div className="navigation-details">
                    {naviagtionDetails}
                    <Link to={naviagtionLink}>
                        <u>{naviagtionText}</u>
                    </Link>
                </div>
            </React.Fragment>
        )
    }

    renderRight = (): JSX.Element => {
        const { renderForm } = this.props
        return <div className="right-side-card-view">{renderForm}</div>
    }

    renderLeft = () => {
        const { title } = this.props
        return (
            <div className="left-side-card-view">
                <div className="title">{title}</div>
                {this.renderDescription()}
            </div>
        )
    }

    render(): JSX.Element {
        return (
            <React.Fragment>
                <div className="mobile-title">{this.renderTitle()}</div>
                <CardView>
                    <div className="card-style-page-view">
                        {this.renderLeft()}
                        {this.renderRight()}
                    </div>
                </CardView>
                <div className="mobile-description">{this.renderDescription()}</div>
            </React.Fragment>
        )
    }
}

export default OnBoardingCard
