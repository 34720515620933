import firebase from "firebase"
import { Observable } from "rxjs"
import { SessionConstant } from "store/constant"
import { rescheduleSessionPayloadType } from "store/types/sessionTypes"

enum ActionTypes {
    GET_BOOKING_REQUESTS = "GET_BOOKING_REQUESTS",
    UPDATE_BOOKING_REQUEST = "UPDATE_BOOKING_REQUEST",
    GET_PENDING_BOOKINGS_N = "GET_PENDING_BOOKINGS_N",
    GET_ALL_SESSIONS = "GET_ALL_SESSIONS",
    GET_PAST_SESSIONS = "GET_PAST_SESSIONS",
    GET_UPCOMING_SESSIONS = "GET_UPCOMING_SESSIONS",
    GET_END_TIME = "GET_END_TIME",
    UPDATE_BOOKING_SLOTS_N = "UPDATE_BOOKING_SLOTS_N",
    ACCEPT_BOOKING_REQUEST_N = "ACCEPT_BOOKING_REQUEST_N",
    CANCEL_BOOKING_REQUEST_N = "CANCEL_BOOKING_REQUEST_N",
    CANCEL_BOOKED_SESSION = "CANCEL_THE_SESSION",
}

export const getBookings = () => {
    const getBookings = firebase.functions().httpsCallable("booking")
    return getBookings({ actionType: ActionTypes.GET_BOOKING_REQUESTS })
        .then((result: any) => result)
        .catch((err: any) => err)
}

export const getPendingBookings = () => {
    const getBookings = firebase.functions().httpsCallable("booking")
    return getBookings({ actionType: ActionTypes.GET_PENDING_BOOKINGS_N })
        .then(({ data }: any) => data).then(data => {
            if (data.status !== "success") {
                throw new Error(data.message)
            }
            return data.data;
        })
        .catch((err: any) => err)
}

export const getUpcomingBookings = () => {
    const upcomingBookings = firebase.functions().httpsCallable("sessions")
    return upcomingBookings({ actionType: ActionTypes.GET_ALL_SESSIONS, limit: 100 })
        .then((result: any) => result)
        .catch((err: any) => err)
}

export const getUpcomingSessionsService = (userIds: string[]) => {
    const upcomingBookings = firebase.functions().httpsCallable("sessions")
    return upcomingBookings({ actionType: SessionConstant.GET_UPCOMING_SESSIONS_N, userIds })
        .then((result: any) => result)
        .catch((err: any) => err)
}

export const cancelTheBookedSessionService = (sessionId: string, cancellationReason: string) => {
    const sessions = firebase.functions().httpsCallable("sessions")
    return sessions({ actionType: ActionTypes.CANCEL_BOOKED_SESSION, sessionId, cancellationReason })
        .then((result: any) => result)
        .catch((err: any) => err)
}

export const getPastSessions = () => {
    const pastSessions = firebase.functions().httpsCallable("sessions")
    return pastSessions({ actionType: ActionTypes.GET_PAST_SESSIONS })
        .then((result: any) => result)
        .catch((err: any) => err)
}

export const updateBookingRequest = (bookingId: any, details: any): any => {
    const bookings = firebase.functions().httpsCallable("booking")
    return bookings({ actionType: ActionTypes.UPDATE_BOOKING_REQUEST, bookingId, ...details })
        .then((result: any) => {
            return result
        })
        .catch((err: any) => {
            console.log("Error updating the booking :", err.message)
        })
}

export const updateBookingSlotsService = (bookingId: any, data: any): any => {
    const bookings = firebase.functions().httpsCallable("booking")
    return bookings({
        actionType: ActionTypes.UPDATE_BOOKING_SLOTS_N,
        bookingId,
        ...data
    }).then(({ data }: any) => {
        return data;
    }).catch((err: any) => {
        console.log("Error updating the booking slots:", err.message)
    })
}

export const acceptBookingRequestService = (bookingId: any, data: any): any => {
    const bookings = firebase.functions().httpsCallable("booking")
    return bookings({
        actionType: ActionTypes.ACCEPT_BOOKING_REQUEST_N,
        bookingId,
        ...data
    }).then(({ data }: any) => {
        return data;
    }).catch((err: any) => {
        console.log("Error accept the booking request:", err.message)
    })
}
export const cancelBookingRequestService = (bookingId: any, data: any): any => {
    const bookings = firebase.functions().httpsCallable("booking")
    return bookings({
        actionType: ActionTypes.CANCEL_BOOKING_REQUEST_N,
        bookingId,
        ...data
    }).then(({ data }: any) => {
        return data;
    }).catch((err: any) => {
        console.log("Error canceling the booking request:", err.message)
    })
}

export const rescheduleSessionRequestService = (payload: rescheduleSessionPayloadType) => {
    const sessions = firebase.functions().httpsCallable("sessions")
    return sessions({
        actionType: SessionConstant.RESCHEDULE_SESSION,
        ...payload
    }).then(({ data }: any) => {
        return data;
    }).catch((err: any) => {
        console.log("Error rescheduling the session request:", err.message)
    })
}

export const bookingExtensionMeta$ = (bookingId: string, sessionId: string) => {
    return new Observable((observer) => {
        const ref = firebase
            .database()
            .ref(`booking-request`)
            .child(bookingId)
            .child(sessionId)
            .child("extensionRequest")
        ref.on("value", (snapshot: any) => {
            const snapshotVal = snapshot.val()
            observer.next(snapshotVal)
        }, (e) => {
            console.error(e);
        })
    })
}
export const bookingExtensionAcceptanceMeta$ = (bookingId: string, sessionId: string) => {
    return new Observable((observer) => {
        const ref = firebase
            .database()
            .ref(`booking-request`)
            .child(bookingId)
            .child(sessionId)
            .child("Acceptancestatus")
        ref.on("value", (snapshot: any) => {
            const snapshotVal = snapshot.val()
            observer.next(snapshotVal)
        }, (e) => {
            console.error(e);
        })
    })
}
export const setBookingExtension = (bookingId: string, extensionTime: any, sessionId: string) => {
    return firebase
        .database()
        .ref(`booking-request`)
        .child(bookingId)
        .child(sessionId)
        .child("extensionRequest")
        .set(extensionTime)
        .catch(function (error) {
            console.error("Error saving message to Database:", error)
        })
}
export const setBookingExtensionProcessStatus = (bookingId: string, extensionTime: any, sessionId: string) => {
    return firebase
        .database()
        .ref(`booking-request`)
        .child(bookingId)
        .child(sessionId)
        .child("extensionRequest")
        .set(extensionTime)
        .catch(function (error) {
            console.error("Error saving message to Database:", error)
        })
}
export const setBookingExtensionAcceptance = (bookingId: string, Acceptancestatus: any, sessionId: string) => {
    return firebase
        .database()
        .ref(`booking-request`)
        .child(bookingId)
        .child(sessionId)
        .child("Acceptancestatus")
        .set(Acceptancestatus)
        .catch(function (error) {
            console.error("Error saving message to Database:", error)
        })
}
export const getsessionEndTime = (vendorId: string) => {
    const upcomingBookings = firebase.functions().httpsCallable("sessions")
    return upcomingBookings({ actionType: ActionTypes.GET_END_TIME, vendorId })
        .then((result: any) => result)
        .catch((err: any) => err)
}
