import { combineReducers } from "redux"

export type Action<T> = {
    type: string
    payload: T
}

export function createActionCreator<T>(type: string): any {
    const action = (payload: T): Action<T> => ({ type, payload })
    return {
        type,
        action,
    }
}

export { combineReducers }
