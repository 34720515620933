import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { firebaseAuth } from "../utils/helper/firebase";
import { getUserWithDetails } from "../utils/services/userServices";
import { logOutRequest, loginSuccess } from "../store/actions/userActions";
import LoaderSvgIcon from "../assets/images/svgIcons/LoaderSvgIcon"
import { useHistory, useLocation} from "react-router";

/**
 * it attaches the login with token functionality to a
 * component
 * @param Component 
 * @param otherData 
 */
function withTokenLogin<T extends object>(Component: React.FC<T>, otherData: any = {}): React.FC<T> {
    return (props: any) => {
        const loggedInUserId = useSelector((state: any) => state?.auth?.userAuthData?.userId);
        const dispatch = useDispatch();
        const [processing, setProcessing] = useState(false);
        const tokenParam = "token";
        const location = useLocation();
        const history = useHistory();

        const getQueryParam = useCallback((name: string) => {
            const urlParams = new URLSearchParams(location.search);
            return urlParams.get(name);
        }, [location.search]);
        const token = getQueryParam(tokenParam);
        const remaingSearchPath = useMemo(() => {
            const urlParams = new URLSearchParams(location.search);
            urlParams.delete(tokenParam);
            return `?${urlParams.toString()}`;
        }, [location.search, tokenParam]);

        useEffect(() => {
            if (token) {
                setProcessing(true);
                firebaseAuth.signInWithCustomToken(token).then(async (d) => {
                    if (d?.user?.uid && loggedInUserId !== d?.user?.uid) {
                        dispatch(logOutRequest());
                    }
                    const userDetails = await getUserWithDetails(d?.user?.uid);
                    dispatch(loginSuccess(userDetails));
                    history.push(`${location.pathname}${remaingSearchPath}`);
                    setProcessing(false);
                }).catch(e => {
                    console.log("Error while trying to login through custom toke: ", e);
                    setProcessing(false);
                });
            } else {
                setProcessing(false);
            }
        }, [token, history, remaingSearchPath]);



        return (!processing && !token) ? <Component {...props}></Component> : (<div className="backDrop">
            <div className="wrapper">
                <LoaderSvgIcon />
            </div>
        </div>);
    }
}

export default withTokenLogin;