import React, { useCallback, useEffect, useState } from "react"
import SortAscendSvgIcon from "../../../assets/images/svgIcons/SortAscendSvgIcon"
import SortDescendSvgIcon from "../../../assets/images/svgIcons/SortDescendSvgIcon"
import TutorSearchList from "./ui-elements/TutorSearchList"
import UnderLinedTabs from "../../../components/tabs/underline-tabs/UnderlinedTabs"
import TutorSearchFiltersPanel from "./ui-elements/TutorSearchFiltersPanel"
import { useDispatch, useSelector } from "react-redux"
import colors from "../../../assets/colors"
import { useHistory } from "react-router"
import { fetchTutors } from "../../../store/actions/tutorSearchActions"
import { tutorSearchConstant } from "store/constant"
import Button from "components/button/ButtonUpdated"
import { allSubjecAndSpecialitytList } from "../../../../src/utils/constant/subjectsSpecialitiesTestsLists"
import { defaultFilterValues } from "store/reducers/tutorSearchReducer"


const SortOrderKeys = {
    ASCENDING: "asc",
    DESCENDING: "desc",
}

const tabs = {
    "Hourly Rate": "oneToOneSessionRate",
    "Rating": "rating"
}

const defaultOrder = {
    fieldName: "oneToOneSessionRate",
    direction: "asc"
}

export const defaultFilters = []

type tabType = keyof typeof tabs;
interface Option {
    name: string
    id: string | number
}


const TutorSearch = (props: any): JSX.Element => {
    const teachersStore = useSelector((state: any) => state.tutorSearch)
    const { filtersUpdated, filterInputValues } = teachersStore;
    const [activeSearchTab, setActiveSearchTab] = useState<tabType>("Hourly Rate")
    const [sortOrder, setSortOrder] = useState(SortOrderKeys.ASCENDING)
    const [rapidBookToggle, setRapidBookToggle] = useState<boolean>(false);
    const [subjectList] = useState([...allSubjecAndSpecialitytList()])
    const [subjects, setSubjects] = useState<Array<Option>>(defaultFilterValues.SUBJECTS)
    const [tutorName, setTutorName] = useState<string>(defaultFilterValues.TUTOR_NAME)
    const [rateRange, setRateRange] = useState(defaultFilterValues.RATE_FULL_RANGE)
    const [timeRange, setTimeRange] = useState(defaultFilterValues.TIME_FULL_RANGE)
    const [education, setEducation] = useState(defaultFilterValues.EDUCATION)
    const [backgroundCheck, setBackgroundCheck] = useState(defaultFilterValues.BACKGROUND_CHECK)
    const [myFavourites, setMyFavourites] = useState(defaultFilterValues.MY_FAVOURITES)
    const [weekdays, setWeekdays] = useState(defaultFilterValues.WEEKDAYS.map((weekday) => {
        return { ...weekday }
    }))

    let defaultRapidBook = defaultFilterValues.RAPID_BOOK
    const localFilters: any = []

    // use URL param filters

    const urlParams = new URLSearchParams(props?.location?.search)

    const type = urlParams.get("type")
    const [goalsObjectives, setGoalsObjectives] = useState<string>(type === "advisors" ? "Advising" : type === "tutors" ? "Tutoring" : "Both")

    console.log("defaultFilterValues", defaultFilterValues)
    let tutorType = "Both"
    if (type === 'advisors' || props?.location?.state?.params?.type === 'advisors') {
        localFilters.push({
            fieldName: "isAdvisor",
            operator: "==",
            value: true
        })
        tutorType = "Advising";
    }
    if (type === 'tutors' || props?.location?.state?.params?.type === 'tutors') {
        localFilters.push({
            fieldName: "isTeacher",
            operator: "==",
            value: true
        })
        tutorType = "Tutoring";

    }

    const rapidBookFromURL = urlParams.get("rapidBook")
    if (rapidBookFromURL == "true") {
        localFilters.push({
            fieldName: "rapidBooking.status",
            value: true
        })
        defaultRapidBook = true
    }

    const subjectsFromURL = urlParams.get("subjects")
    const subjectsFromURLArray: Array<Option> = [];
    if (subjectsFromURL) {
        const subjectsFromURLArrayString = subjectsFromURL.split(",");
        for (let index = 0; index < subjectsFromURLArrayString.length; index++) {
            const subjectId = subjectsFromURLArrayString[index];
            const option: Option = subjectList.find(x => x.id == subjectId) as Option
            subjectsFromURLArray.push(option);
        }

        const filterIds = subjectsFromURLArray.map((subject) => {
            return subject.id
        })
        if (filterIds.length > 0) {
            localFilters.push({
                fieldName: "subjects",
                operator: "array-contains-any",
                value: filterIds
            })
        }
    }

    // end use URL param filters

    const [lastFilter, setLastFilter] = useState([...defaultFilters, ...localFilters])
    const [isFilter, setIsFilter] = useState(false)
    const [rapidBook, setRapidBook] = useState(false);
    const orderBy = [...teachersStore.orderBy];
    const history = useHistory()
    const dispatch = useDispatch()

    useEffect(() => {
        setSubjects(subjectsFromURLArray)
        setRapidBook(defaultRapidBook)
        setGoalsObjectives(tutorType)

        // defaults 
        setTutorName(filterInputValues.tutorName);
        setRateRange(filterInputValues.rateRange);
        setTimeRange(filterInputValues.timeRange);
        setEducation(filterInputValues.education);
        setBackgroundCheck(filterInputValues.backgroundCheck);
        setMyFavourites(filterInputValues.myFavourites);
        setWeekdays(filterInputValues.weekdays);
        // end defaults 

        const lastOrderBy = orderBy?.pop();
        setSortOrder(lastOrderBy.direction);
        setActiveSearchTab(lastOrderBy.fieldName === tabs["Hourly Rate"] ? "Hourly Rate" : "Rating");
    }, []);

    const setTabOrder = useCallback((value: string) => {
        setSortOrder(value);
        dispatch({ type: tutorSearchConstant.FILTERS_UPDATED });
    }, []);

    const tabChanged = (label: any) => {
        setActiveSearchTab(label)
        if (label === "Hourly Rate") {
            setTabOrder(SortOrderKeys.ASCENDING)
        } else {
            setTabOrder(SortOrderKeys.DESCENDING)
        }
    }

    const scrollCheck = (event: any) => {
        const bottom = event.target.scrollTop + event.target.clientHeight >= event.target.scrollHeight - 100;

        if (bottom && !teachersStore.isloading && teachersStore.showMore) {
            let addOneToOneOrderBy = false;
            if (tabs[activeSearchTab] === "rating") {
                lastFilter.forEach((filter: any) => {
                    if (filter.fieldName === "oneToOneSessionRate") {
                        addOneToOneOrderBy = true;
                        return;
                    }
                });
            }
            const orderBy = [];
            addOneToOneOrderBy && orderBy.push(defaultOrder);
            orderBy.push({
                fieldName: tabs[activeSearchTab],
                direction: sortOrder
            });

            dispatch(fetchTutors({
                filters: lastFilter,
                orderBy: orderBy,
                pagination: { limit: 20, docId: teachersStore.nextId }
            }))
        }
    }

    return (
        <div className={`navigator-page ${isFilter ? "show-main-filter" : "hide-main-filter"}`}>
            <div className="toggle_filter_search">
                <div className="content-container">
                    <Button title="Search Tutors & Advisors" clickAction={() => setIsFilter(true)} />
                    <span className="clear"></span>
                </div>
            </div>
            <TutorSearchFiltersPanel
                isFilter={isFilter}
                filteredSubjects={subjectsFromURLArray}
                setIsFilter={setIsFilter}
                activeSearchTab={tabs[activeSearchTab]}
                sortOrder={sortOrder}
                lastFilter={lastFilter}
                setLastFilter={setLastFilter}
                type={type}
                rapidBook={rapidBook}
                rapidBookToggle={rapidBookToggle}
                setRapidBook={setRapidBook} 
                openSearch={props.openSearch}/>

            <div className="search-results-panel" >
                <div className="content-container">
                    <div>
                        <div>
                            <UnderLinedTabs
                                labels={Object.keys(tabs)}
                                selectedLabel={activeSearchTab}
                                onLabelClick={tabChanged}
                            />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                marginTop: "1.5rem",
                                marginBottom: "1.2rem",
                            }}
                        >
                            <span className="small-bold-label">
                                Total:{" "}
                                {teachersStore.isloading
                                    ? "..."
                                    : teachersStore.showMore ?
                                        teachersStore.teachers.length + "+" :
                                        teachersStore.teachers.length
                                }
                            </span>
                            <span className="icon-wrapper">
                                {sortOrder === SortOrderKeys.ASCENDING ? (
                                    <span
                                        onClick={() => setTabOrder(SortOrderKeys.DESCENDING)}
                                    >
                                        <SortAscendSvgIcon iconColor={colors.darkMango} />
                                    </span>
                                ) : (
                                    <span
                                        onClick={() => setTabOrder(SortOrderKeys.ASCENDING)}
                                    >
                                        <SortDescendSvgIcon iconColor={colors.darkMango} />
                                    </span>
                                )}
                            </span>
                        </div>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            flexGrow: 1,
                            overflow: "scroll",
                            justifyContent: "center",
                            margin: "0 -15px"
                        }}
                        onScroll={scrollCheck}
                    >
                        <TutorSearchList
                            data={teachersStore.teachers}
                            filteredSubjects={teachersStore.filterInputValues.subjects}
                            history={history}
                            isLoading={teachersStore.isloading}
                            setRapidBook={setRapidBook}
                            setRapidBookToggle={setRapidBookToggle}
                            rapidBookToggle={rapidBookToggle}
                            rapidBook={rapidBook}
                            isError={teachersStore.isError}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TutorSearch
