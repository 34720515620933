import React from "react"
import ReactMarkdown from "react-markdown"
import { Grid, Container } from "@material-ui/core"
import { useTranslation } from 'react-i18next';



const LessonPortrait = ({ portraitOfAnApplicant }: { portraitOfAnApplicant: string | undefined }): JSX.Element => {
    const { t, i18n } = useTranslation();
    return (
        <>
            {portraitOfAnApplicant ? (
                <>
                    <div className="lesson-portrait">
                        <p className="portrait-heading">{t('portrait-of-an-applicant')}</p>
                        <ReactMarkdown className="text" source={portraitOfAnApplicant} />
                    </div>
                    <hr className="lesson-video-break" />
                </>
            ) : (
                    <></>
                )
            }
        </>
    )
}

export default LessonPortrait