import { BookingSessionsConstant } from "../constant"

export const setCurrentWeek = (props: any): object => {
    return { type: BookingSessionsConstant.SET_WEEK_INFO, props }
}

/* to existing requests for this teacher */
export const setSelectedSessions = (props: any): object => {
    return { type: BookingSessionsConstant.SET_SELECTED_SESSIONS, props }
}

/* to current request */
export const setSessionRequest = (props: any): object => {
    return { type: BookingSessionsConstant.SET_SESSION_REQUEST, props }
}

export const setSubject = (props: any): object => {
    return { type: BookingSessionsConstant.SET_SUBJECT, props }
}

export const setTeacherInfo = (props: any): object => {
    return { type: BookingSessionsConstant.SET_TEACHER_INFO, props }
}

export const setStudentComments = (props: any): object => {
    return { type: BookingSessionsConstant.SET_STUDENT_COMMENTS, props }
}

export const setRangeValues = (props: any): object => {
    return { type: BookingSessionsConstant.SET_RANGE_VALUES, props }
}

export const setNonAvailableSlots = (props: any): object => {
    return { type: BookingSessionsConstant.SET_NON_AVAILABLE_SLOTS, props }
}

export const setActiveSlot = (props: any): object => {
    return { type: BookingSessionsConstant.SET_ACTIVE_SLOTS, props }
}

export const setRapidBooking = (props: any): object => {
    return { type: BookingSessionsConstant.SET_RAPID_BOOK, props }
}
