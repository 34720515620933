import { PRODUCTS } from "utils/constant/promoCodeConstant"

export const constructProductName = (priceItem: {
    metadata: {
        type: string,
        subtype: string,
    },
}) => {
    let productName = priceItem.metadata?.type || PRODUCTS.LESSON
    if (priceItem.metadata?.subtype) {
        productName += `.${priceItem.metadata.subtype}`
    }
    return productName
}
