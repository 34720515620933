import React from "react"
import ResetPasswordForm from "./ResetPasswordForm"
import { toastConstant } from "../../../store/constant"
import { applicationConstant } from "../../../utils/constant/applicationConstant"
import InterfaceConstant from "../../../utils/constant/interfaceConstant"
import OnBoardingCard from "../../../components/card/OnBoardingCard"
import { useDispatch, useSelector } from "react-redux"
import ToastNotification from "../../../components/modal/ToastNotification"
import OnboardingBackground from "../../../components/on-boarding/onboarding-background/OnboardingBackground"

const resetPasswordLabel: InterfaceConstant.IresetPasswordLabel =
    applicationConstant.resetPasswordLabel
const resetPasswordappLabel: InterfaceConstant.IappLabels = applicationConstant.appLabels
interface Iprops {
    history: any
}

const ResetPasswordPage = ({ history }: Iprops): JSX.Element => {
    const { message, show, isSuccess } = useSelector((state: any) => state.toast)
    const dispatch = useDispatch()

    const renderResetPasswordForm = (): JSX.Element => {
        return <ResetPasswordForm isSuccess={isSuccess} history={history} />
    }

    return (
        <>
            <OnboardingBackground hiddenXS />
            <div className="login-flow mobile-bg">
                <div className="login-flow-container">
                    <ToastNotification
                        message={message}
                        isopen={show && !isSuccess ? true : false}
                        isOpenAction={() => dispatch({ type: toastConstant.TOAST_HIDE })}
                        styleClass={isSuccess ? "success-toast" : "error-toast"}
                    />
                    <OnBoardingCard
                        title={resetPasswordappLabel.RESET_PASSWORD_PAGE_TITLE}
                        description={resetPasswordLabel.DESCRIPTION}
                        naviagtionDetails={resetPasswordLabel.NAVIGATIONDETAILS}
                        naviagtionLink={resetPasswordLabel.NAVIGATIONLINK}
                        naviagtionText={resetPasswordLabel.NAVIGATIONTEXT}
                        renderForm={renderResetPasswordForm()}
                    />
                </div>
            </div>
        </>
    )
}

export default ResetPasswordPage
